import React, { Component } from 'react'
import DTASelect from '../../../../utils/components/dtaSelect';
import {
    getConsumerAppealsList,
} from "../../../../redux/concernsAppeals/concernsAppealsAction"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../../redux/rootReducer"
import * as TextProps from "../../../../utils/constants/text"
import * as helperFunction from "../../../../utils/components/helperFunctions/helperFunctions"
import { consumerConst } from "../../../../pages/consumer/consumerText"
import DTAInput from '../../../../utils/components/dtaInput';
import DTATextArea from '../../../../utils/components/dtaTextArea';
import DTADate from '../../../../utils/components/dtaDate';
import Radio from '../../../../utils/components/radio';


const selectedLanguage =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
class Appeals extends Component {
    constructor(props) {
        super(...arguments);
    }

    render() {
        return (
            <div className='pad-all--double'>
                <div className="pure-u-1">
                    <div>
                        <span style={{ display: 'flex', fontSize: '2rem', marginBottom: '20px' }}><b>{consumerConst(selectedLanguage).appealsHeaderText}</b></span>
                    </div>
                    <div style={{ display: 'flex', borderBottom: '1px solid grey', marginBottom: '38px', width: "100%" }}>

                    </div>
                </div>

                {/* Form */}
                <form id="customer-Appeals"  >


                    {/* client */}
                    <div className="pure-u-1">
                        <h3 style={{ margin: "30px 0px 30px 0px" }}>{consumerConst(selectedLanguage).clientSubLebel}</h3>

                        <label className="dta-form__label" for="appealsClientName">{consumerConst(selectedLanguage).ClientNameLabel}</label>
                        <DTAInput
                            id={"appealsClientName"}
                            className="dta-form__control dta-select--expand-width"
                            value={this.props.appeals.appealsAnonymusUserName}
                            type="specialTextType"
                            disabled />

                        <label className="dta-form__label" for="appealsClientPhoneNumber">{consumerConst(selectedLanguage).ClientPhoneNumberLabel}</label>
                        {this.props.appeals.clientPhoneNumberError && (
                            <div>
                                <div className="peat-case-error-msg">
                                    <span className="dta-form__error-message">
                                        {consumerConst(selectedLanguage).clientPhoneNumberErrMsg}
                                    </span>
                                </div>
                            </div>
                        )}
                        <DTAInput
                            id={"appealsClientPhoneNumber"}
                            className="dta-form__control dta-select--expand-width"
                            type="tel"
                            value={this.props.appeals.appealsClientPhoneNumber ? this.props.appeals.appealsClientPhoneNumber : this.props.appeals.appealsAnonymusUserPhoneNumber}
                            onChange={this.props.handleAppeals.bind(this, 'clientPhoneNumber')}
                        />

                        <label className="dta-form__label" for="appealsClientEmailId">{consumerConst(selectedLanguage).ClientEmailAddressLabel}</label>
                        {this.props.appeals.clientEmailAddressErrorMsg && (
                            <div>
                                <div className="peat-case-error-msg">
                                    <span className="dta-form__error-message">
                                        {consumerConst(selectedLanguage).clientEmailAddressErrMsg}
                                    </span>
                                </div>
                            </div>
                        )}
                        <DTAInput
                            id={"appealsClientEmailId"}
                            className="dta-form__control dta-select--expand-width"
                            type="email"
                            value={this.props.appeals.appealsClientEmailAddress ? this.props.appeals.appealsClientEmailAddress : this.props.appeals.appealsAnonymusUserEmail}
                            onChange={this.props.handleAppeals.bind(this, 'clientEmailAddress')}
                        />

                        <label className="dta-form__label" for="appealsAgencyId">{consumerConst(selectedLanguage).ClientIdLabel}</label>
                        <DTAInput
                            id={"appealsAgencyId"}
                            className="dta-form__control dta-select--expand-width"
                            value={this.props.appeals.appealsAgencyId}
                            disabled />
                    </div>


                    <div style={{ marginTop: "30px" }}>

                        {/* AppealsType */}

                        <label className="dta-form__label" for="appealsType">{consumerConst(selectedLanguage).AppealsType}</label>
                        <DTAInput
                            id={"appealsType"}
                            name={"Appeals"}
                            className="dta-form__control dta-select--expand-width"
                            defaultValue={""}
                            value={this.props.appeals ? this.props.appeals.appealsInputDisplayValue : ""}
                            onChange={this.props.handleAppeals.bind(this, 'type')}
                            disabled
                        />

                        {/* Reason */}

                        <label className="dta-form__label" for="appealsReason">{consumerConst(selectedLanguage).ReasonLabelText}</label>
                        {this.props.appeals.reasonError && (
                            <div>
                                <div className="peat-case-error-msg">
                                    <span className="dta-form__error-message">
                                        {consumerConst(selectedLanguage).reasonErrorMsg}
                                    </span>
                                </div>
                            </div>
                        )}
                        <DTASelect
                            className="dta-form__control dta-select--expand-width"
                            id={"appealsReason"}
                            options={Object.values(consumerConst(selectedLanguage).appealsReasonOptions).sort()}
                            value={this.props.appeals.reason}
                            onChange={this.props.handleAppeals.bind(this, 'reason')}
                            error={this.props.appeals.reasonError}
                        />

                        {/* Program */}

                        <label className="dta-form__label" for="appealsProgram">{consumerConst(selectedLanguage).ProgramLabelText}</label>
                        {this.props.appeals.programError && (
                            <div>
                                <div className="peat-case-error-msg">
                                    <span className="dta-form__error-message">
                                        {consumerConst(selectedLanguage).programErrorMsg}
                                    </span>
                                </div>
                            </div>
                        )}
                        <DTASelect
                            id={"appealsProgram"}
                            className="dta-form__control dta-select--expand-width"
                            options={consumerConst(selectedLanguage).appealsProgramOptions}
                            value={this.props.appeals.program}
                            onChange={this.props.handleAppeals.bind(this, 'program')}
                            error={this.props.appeals ? this.props.appeals.programError : null}
                        />


                        {/* Date */}

                        <label className="dta-form__label" for="appealsData">{consumerConst(selectedLanguage).DateSelectorLabel}</label>
                        {this.props.appeals.errorMessage && (
                            <div className="peat-case-error-msg">
                                <span className="dta-form__error-message">
                                    {consumerConst(selectedLanguage).errorMessage}
                                </span>
                            </div>
                        )}
                        <DTADate
                            id={"appealsData"}
                            value={this.props.appeals.currentDateTime}
                            onChange={this.props.handleAppeals.bind(this, 'date')}
                            error={this.props.appeals.errorMessage}
                        />
                    </div>

                    {/* Authorized Representative */}

                    <div className="pure-u-1">
                        <h3 style={{ margin: "50px 0px 30px 0px", }}>{consumerConst(selectedLanguage).appealsSubHeaderText}</h3>
                        <label className="dta-form__label" for="appealsAuthName">{consumerConst(selectedLanguage).authorizedClientNameLabel}</label>
                        <DTAInput
                            id={"appealsAuthName"}
                            type="specialTextType"
                            className="dta-form__control dta-select--expand-width"
                            value={this.props.appeals.authRepName}
                            onChange={this.props.handleAppeals.bind(this, 'appealsAuthName')}
                            maxLength={40}
                        />

                        <label className="dta-form__label" for="appealsAuthPhone">{consumerConst(selectedLanguage).authorizedClientPhoneNumberLabel}</label>
                        {this.props.appeals.appealsAuthRepPhoneNumbError && (
                            <div>
                                <div className="peat-case-error-msg">
                                    <span className="dta-form__error-message">
                                        {consumerConst(selectedLanguage).phoneNumberFormateErrMsg}
                                    </span>
                                </div>
                            </div>
                        )}
                        
                        <DTAInput
                            id={"appealsAuthPhone"}
                            className="dta-form__control dta-select--expand-width"
                            type="tel"
                            value={this.props.appeals.appealsAuthRepPhone}
                            onChange={this.props.handleAppeals.bind(this, 'appealsAuthPhoneNo')}
                        />

                        <label className="dta-form__label" for="appealsAuthEmail">{consumerConst(selectedLanguage).authorizedClientEmailAddressLabel}</label>
                        {this.props.appeals.authRepEmailError && (
                            <div>
                                <div className="peat-case-error-msg">
                                    <span className="dta-form__error-message">
                                        {consumerConst(selectedLanguage).emailFormateErrMsg}
                                    </span>
                                </div>
                            </div>
                        )}
                        <DTAInput
                            id={"appealsAuthEmail"}
                            className="dta-form__control dta-select--expand-width"
                            type="email"
                            value={this.props.appeals.authRepAppealsEmail}
                            onChange={this.props.handleAppeals.bind(this, 'appealsAuthEmail')}
                            maxLength={350}
                        />



                        {/* LegalRep */}
                        <h3 style={{ margin: "50px 0px 30px 0px", }}>{consumerConst(selectedLanguage).legalRepHeading}</h3>

                        <label className="dta-form__label" for="appealsLegalRep" style={{ margin: "30px 0px 20px 0px" }}>{consumerConst(selectedLanguage).LegalRepresentatiseLabel}</label>
                        <Radio
                            id={"appealsLegalRep"}
                            name="memberSearch-radio-toggle"
                            className="dta-form__option-list"
                            optionClass="dta-form__option dta-form__option--width-1-of-2"
                            options={["Yes", "No"]}
                            onChange={this.props.handleAppeals.bind(this, 'legalRep')}
                        />

                        {this.props.appeals.showInput && (
                            <div style={{marginTop:"20px"}}>
                                <label className="dta-form__label" for="appealsLegalRepName">{consumerConst(selectedLanguage).ClientNameLabel}</label>
                                {this.props.appeals.nameError && (
                                    <div>
                                        <div className="peat-case-error-msg">
                                            <span className="dta-form__error-message">
                                                {consumerConst(selectedLanguage).nameErrorMsg}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <DTAInput
                                    id={"appealsLegalRepName"}
                                    className="dta-form__control dta-select--expand-width"
                                    value={this.props.appeals.LegalRepresentativeName}
                                    onChange={this.props.handleAppeals.bind(this, 'legalRepName')}
                                    error={this.props.appeals.nameError}
                                    type="specialTextType"
                                    maxLength={40}
                                />


                                <label className="dta-form__label" for="appealsLegalRepPhone">{consumerConst(selectedLanguage).ClientPhoneNumberLabel}</label>
                                {this.props.appeals.LegalRepresentativePhoneError && (
                                    <div>
                                        <div className="peat-case-error-msg">
                                            <span className="dta-form__error-message">
                                                {consumerConst(selectedLanguage).legalRepPhoneNumberFormateErrMsg}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {this.props.appeals.phoneNumberError && (
                                    <div>
                                        <div className="peat-case-error-msg">
                                            <span className="dta-form__error-message">
                                                {consumerConst(selectedLanguage).phoneNumberErrorMsg}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <DTAInput
                                    id={"appealsLegalRepPhone"}
                                    className="dta-form__control dta-select--expand-width"
                                    value={this.props.appeals.LegalRepresentativePhone}
                                    onChange={this.props.handleAppeals.bind(this, 'legalRepPhone')}
                                    error={this.props.appeals.phoneNumberError}
                                    type="tel"
                                    maxLength={20}
                                />
                                <label className="dta-form__label" for="appealsLegalRepEmail">{consumerConst(selectedLanguage).ClientEmailAddressLabel}</label>
                                {this.props.appeals.emailAddressError && (
                                    <div>
                                        <div className="peat-case-error-msg">
                                            <span className="dta-form__error-message">
                                                {consumerConst(selectedLanguage).emailAddressErrorMsg}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {this.props.appeals.legalRepresentativeEmailErr && (
                                    <div>
                                        <div className="peat-case-error-msg">
                                            <span className="dta-form__error-message">
                                                {consumerConst(selectedLanguage).legalRepEmailFormateErrMsg}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <DTAInput
                                    id={"appealsLegalRepEmail"}
                                    className="dta-form__control dta-select--expand-width"
                                    value={this.props.appeals.LegalRepresentativeEmail}
                                    onChange={this.props.handleAppeals.bind(this, 'legalRepEmail')}
                                    error={this.props.appeals.emailAddressError}
                                    type="email"
                                    maxLength={350}
                                />
                            </div>
                        )}

                        {/* <label className="dta-form__label" style={{ margin: "30px 0px 20px 0px" }}>{consumerConst(selectedLanguage).RequestBenefitLabel}</label> */}
                        <p style={{ fontSize: "16px", marginTop: "30px" }}>
                            {consumerConst(selectedLanguage).RequestBenefitLabel}
                        </p>
                        <p style={{ fontSize: "16px", marginBottom: "30px" }}>
                            {consumerConst(selectedLanguage).RequestBenefitLabel2}
                        </p>
                        <Radio
                            id={"appealsRequestBenfit"}
                            className="dta-form__option-list"
                            optionClass="dta-form__option dta-form__option--width-1-of-2"
                            options={["Yes", "No"]}
                            onChange={this.props.handleAppeals.bind(this, 'benfit')}

                        />


                        <label className="dta-form__label" style={{ marginTop: "30px" }} for="appealsComments">{consumerConst(selectedLanguage).CommentsLabel}</label>
                        <DTATextArea
                            id={"appealsComments"}
                            className="dta-form__control"
                            placeholder={consumerConst(selectedLanguage).commentsPlaceHolder}
                            maxLength={4500}
                            value={this.props.appeals.comments || ''}
                            onChange={this.props.handleAppeals.bind(this, 'comments')}
                        />

                    </div>
                </form>
            </div>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getConsumerAppealsList: getConsumerAppealsList,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Appeals);