import {setAuthRepResponseBodyData} from "../recertification/recertificationActions";
//Action
import axios from "axios";
import { formatDate } from "../../utils/components/utilities/formatters";
import * as TextProps from "../../utils/constants/text";
import * as roleProps from "../../utils/constants/rolesConstants";
import { getAudit } from "../../utils/constants/audit";
var config = require("../../utils/constants/appConfigConstants");

var messageData = {
  consumerHome: "Consumer Home",
  dtaLinkText: "Link your DHR",
  dtaLinkHelperText: "Link your DHR using",
  dta_id: "DHR ID",
  ssn: "SSN",
  ebt_card: "E.B.T. Card number",
  dta_pin: "DHR PIN",
  ebt_card_balance: "E.B.T. card balance",
  primary_language: "Primary Language",
  income_verification: "Submit Income Verification letter",
  doiQualifyText: "Do I Quality?",
  dummyText:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  homeHeading: "ENGLISH LANGUAGE",
  loginText: "Login/Signup",
  mainHeading: "Consumer Login",
};

const actionTypes = {
  getConsumerHomeRequested: "CONSUMER_HOME_REQUESTED",
  getConsumerHomeRejected: "CONSUMER_HOME_REJECTED",
  getConsumerHomeFulfilled: "CONSUMER_HOME_FULFILLED",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
  getInterimDataFulfilled: "GET_INTERIM_DATA_FULFILLED",
  getCovidRecertDataFulfilled: "GET_COVIDRECERT_DATA_FULFILLED",
  getTrackApplicationDataFulfilled: "GET_TRACK_APPLICATION_DATA_FULFILLED",
  getRecertDataFulfilled: "GET_RECERT_DATA_FULFILLED",
  hidePopup: "HIDE_POPUP",
  setContactInfo: "SET_CONTACT_INFO",
  setHouseholdMembers: "SET_HOUSEHOLD_MEMBERS",
  applyAddressStorageAction: "APPLY_ADDRESS_STRG",
};

// if service failure to getting data
export function serviceFailedAction() {
  return {
    type: actionTypes.serviceFailedAction,
  };
}

//Get MultiLanguage Functional Actions
export function getInterimReportData(agencyID, programCode, auditData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var url = config.TigerContextURL + "/interimreport/";
      var options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(auditData),
          "x-auth-string": "MjFkYjJjNWQtM2VjNi00MmY3LWFiMjQtZDRjYjMzYTAwMmRh",
          mode: "no-cors",
          "Access-Control-Allow-Origin": config.allowed_domain,
          crossdomain: TextProps.VALUE_FALSE,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .get(url + agencyID + "/" + programCode, options)
        .then((response) => {
          let householdTemp = [];
          let earnedIncomeTemp = [];
          let unearnedIncomeTemp = [];
          let childExpTemp = [];
          if (response.data.householdMembers.length > 0) {
            response.data.householdMembers.map((mem,i) => {
              if(response.data.additionalProperties && response.data.additionalProperties.householdMembers != undefined){
                let ele = response.data.additionalProperties.householdMembers.membersList[i];
                ele.dateOfBirth = formatDate(ele.dateOfBirth);
                householdTemp.push(ele);
              }else{
                mem.dateOfBirth = formatDate(mem.dateOfBirth);
                householdTemp.push(mem);
              }
            });
          }
          if (response.data.earnedIncome.length > 0) {
            response.data.earnedIncome.map((mem) => {
              mem.dateOfBirth = formatDate(mem.dateOfBirth);
              earnedIncomeTemp.push(mem);
            });
          }
          if (response.data.unearnedIncome.length > 0) {
            response.data.unearnedIncome.map((mem) => {
              mem.dateOfBirth = formatDate(mem.dateOfBirth);
              unearnedIncomeTemp.push(mem);
            });
          }
          if (response.data.childSupportExpense.length > 0) {
            response.data.childSupportExpense.map((mem) => {
              mem.dateOfBirth = formatDate(mem.dateOfBirth);
              childExpTemp.push(mem);
            });
          }
          if(response.data.utilityExpense) {
            response.data.utilityExpense["dataChangeCode"] = "NOCHNG";
          }

          response.data.householdMembers = householdTemp;
          response.data.earnedIncome = earnedIncomeTemp;
          response.data.unearnedIncome = unearnedIncomeTemp;
          response.data.childSupportExpense = childExpTemp;
          dispatch(getInterimReportDataAction(response.data));
          resolve(TextProps.VALUE_TRUE);
        })
        .catch((error) => {
          dispatch(serviceFailedAction());
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}

export function getRecertData(agencyID, programCode, auditData, recertType) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const applicationType = recertType === "REEVAL" ? "/redetermination/" : recertType === "IR" ? "/interimreport/"  : "/recertification/";
      var url = config.TigerContextURL + applicationType;
      var options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(auditData),
          "x-auth-string": "MjFkYjJjNWQtM2VjNi00MmY3LWFiMjQtZDRjYjMzYTAwMmRh",
          mode: "no-cors",
          "Access-Control-Allow-Origin": config.allowed_domain,
          crossdomain: TextProps.VALUE_FALSE,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
      .get(url + agencyID + "/" + (recertType === "REEVAL" ? "TANF": programCode), options)
        .then((response) => {
          let householdTemp = [];
          let nonCitizenTemp = [];
          // if (response.data.householdMembers[0] !== undefined) {
          //   response.data.householdMembers.map((mem) => {
          //     mem.dateOfBirth = formatDate(mem.dateOfBirth);
          //     householdTemp.push(mem);
          //   });
          // }
          if(recertType !== "REEVAL"){
            if (
              response.data.noncitizenStatus !== undefined &&
              response.data.noncitizenStatus !== null
            ) {
              response.data.noncitizenStatus.map((mem) => {
                mem.dateOfBirth = formatDate(mem.dateOfBirth);
                nonCitizenTemp.push(mem);
              });
            } else if (
              response.data.noncitizenStatus !== null &&
              response.data.noncitizenStatus.dateOfBirth !== undefined
            ) {
              response.data.noncitizenStatus.dateOfBirth = formatDate(
                response.data.noncitizenStatus.dateOfBirth
              );
              nonCitizenTemp.push(response.data.noncitizenStatus);
            }
  
            // response.data.householdMembers = householdTemp;
            response.data.noncitizenStatus = nonCitizenTemp;
  
            dispatch(setAuthRepResponseBodyData(response.data.authorizedRepresentatives))
          }
          if (
            response.data.additionalProperties &&
            response.data.additionalProperties.contactInfoScreen
          ) {
            dispatch(
              setContactInfoObj(
                response.data.additionalProperties.contactInfoScreen
              )
            );
            if(response.data.additionalProperties.contactInfoScreen.validatedAddrsStored && response.data.additionalProperties.contactInfoScreen.validatedAddrsStored.length){
              dispatch(
                applyAddressStorageAPI(
                  response.data.additionalProperties.contactInfoScreen.validatedAddrsStored
                )
              );
            }
          }
          if (
            response.data.additionalProperties &&
            response.data.additionalProperties.householdMembers
          ) {
            for (let memberListValue of response.data.additionalProperties.householdMembers
              .membersList) {
                if(memberListValue.dataChangeCode && memberListValue.dataChangeCode !== "NOCHNG"){
                  if(memberListValue.dataChangeCode === "REMOVE"){
                    memberListValue.dataChangeCode = "removed"
                  }
                }
            }
            dispatch(
              setHouseholdMembers(
                response.data.additionalProperties.householdMembers
              )
            );
          }
          dispatch(getRecretDataAction(response.data));
          resolve(TextProps.VALUE_TRUE);
        })
        .catch((error) => {
          dispatch(serviceFailedAction());
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}

function setContactInfoObj(contactInfoObj) {
  return {
    type: actionTypes.setContactInfo,
    payload: contactInfoObj,
  };
}
function setHouseholdMembers(contactInfoObj) {
  return {
    type: actionTypes.setHouseholdMembers,
    payload: contactInfoObj,
  };
}
function applyAddressStorageAPI(responseData) {
  return {
    type: actionTypes.applyAddressStorageAction,
    payload: responseData,
  };
}

export function getTrackApplication(auditData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var url = config.TigerContextURL + "/applicationStatus/";
      var options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: getAudit(),
          "x-auth-string": "MjFkYjJjNWQtM2VjNi00MmY3LWFiMjQtZDRjYjMzYTAwMmRh",
          mode: "no-cors",
          "Access-Control-Allow-Origin": config.allowed_domain,
          crossdomain: TextProps.VALUE_FALSE,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      const userInfo =  JSON.parse(sessionStorage.getItem("userInfo"));
      let providerFlag = TextProps.VALUE_FALSE;
      userInfo.roleList.forEach((role) => {
        if (
          role.roleName === roleProps.PROVIDER ||
          role.roleName === roleProps.PROVIDER_ROLE_SPVUSR ||
          role.roleName === roleProps.PROVIDER_ROLE_DCPVSG
        ) {
          providerFlag = TextProps.VALUE_TRUE;
        }
      });
      let path = userInfo ? userInfo.email : "";
      if(providerFlag){
        const selectedConsumerEmail =  sessionStorage.getItem("selectedConsumerEmail");
        path = selectedConsumerEmail ? selectedConsumerEmail : "";
      }
      axios
        .get(url + path, options )
        .then((response) => {
          dispatch(getTrackApplicationDataAction(response.data.applications))
        })
        .catch((error) => {
          console.log("Application Status Error", error)
        });
    });
  };
}

//Get Covid Recert Functional Actions
export function getCovidRecertData(agencyID, programCode, auditData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      var url = config.TigerContextURL + "/covid19recert/";
      var options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          audit: JSON.stringify(auditData),
          "x-auth-string": "MjFkYjJjNWQtM2VjNi00MmY3LWFiMjQtZDRjYjMzYTAwMmRh",
          mode: "no-cors",
          "Access-Control-Allow-Origin": config.allowed_domain,
          crossdomain: TextProps.VALUE_FALSE,
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .get(url + agencyID + "/" + programCode, options)
        .then((response) => {
          let householdTemp = [];
          let earnedIncomeTemp = [];
          let unearnedIncomeTemp = [];
          let childExpTemp = [];
          if (response.data.householdMembers.length > 0) {
            response.data.householdMembers.map((mem) => {
              mem.dateOfBirth = formatDate(mem.dateOfBirth);
              householdTemp.push(mem);
            });
          }
          if (response.data.earnedIncome.length > 0) {
            response.data.earnedIncome.map((mem) => {
              mem.dateOfBirth = formatDate(mem.dateOfBirth);
              earnedIncomeTemp.push(mem);
            });
          }
          if (response.data.unearnedIncome.length > 0) {
            response.data.unearnedIncome.map((mem) => {
              mem.dateOfBirth = formatDate(mem.dateOfBirth);
              unearnedIncomeTemp.push(mem);
            });
          }
          if (response.data.childSupportExpense.length > 0) {
            response.data.childSupportExpense.map((mem) => {
              mem.dateOfBirth = formatDate(mem.dateOfBirth);
              childExpTemp.push(mem);
            });
          }

          response.data.householdMembers = householdTemp;
          response.data.earnedIncome = earnedIncomeTemp;
          response.data.unearnedIncome = unearnedIncomeTemp;
          response.data.childSupportExpense = childExpTemp;
          dispatch(getCovidRecertDataAction(response.data));
          resolve(TextProps.VALUE_TRUE);
        })
        .catch((error) => {
          dispatch(serviceFailedAction());
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}

export function getConsumerHomeContents() {
  return (dispatch) => {
    dispatch(getConsumerHomeRequestedAction());
    const consumerHomeConstants = messageData;
    dispatch(getConsumerHomeFulfilledAction(consumerHomeConstants));
  };
}

//Action Dispatch
function getConsumerHomeRequestedAction() {
  return {
    type: actionTypes.getConsumerHomeRequested,
  };
}

function getTrackApplicationDataAction(trackData) {
  return {
    type: actionTypes.getTrackApplicationDataFulfilled,
    payload: trackData,
  };
}

function getConsumerHomeFulfilledAction(consumerHomeConstants) {
  return {
    type: actionTypes.getConsumerHomeFulfilled,
    payload: consumerHomeConstants,
  };
}

function getInterimReportDataAction(interimData) {
  return {
    type: actionTypes.getInterimDataFulfilled,
    payload: interimData,
  };
}

function getCovidRecertDataAction(covidRecertData) {
  return {
    type: actionTypes.getCovidRecertDataFulfilled,
    payload: covidRecertData,
  };
}

function getRecretDataAction(recertData) {
  return {
    type: actionTypes.getRecertDataFulfilled,
    payload: recertData,
  };
}

export function hidePopupAction() {
  return {
    type: actionTypes.hidePopup,
  };
}
