import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import { pebtCaseInfoPagelanguageLabels } from "../applyPebtText";
// Redux implementations
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import DTAInput from "../../../utils/components/dtaInput";
import pEBT from "../../../utils/resources/images/PEBT.png";
import { submitPEBTCaseNumber } from "../../../redux/applyPebt/applyPebtActions";
import PdfEn from "../../../utils/resources/pdfs/P-EBTCardPrintInstructionsEN.pdf";
import PdfEs from "../../../utils/resources/pdfs/P-EBTCardPrintInstructionsES.pdf";
import PdfZh from "../../../utils/resources/pdfs/P-EBTCardPrintInstructionsZH.pdf";
import PdfBr from "../../../utils/resources/pdfs/P-EBTCardPrintInstructionsBR.pdf";
import PdfVi from "../../../utils/resources/pdfs/P-EBTCardPrintInstructionsVI.pdf";
import * as formatters from "../../../utils/components/utilities/formatters";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import * as languageConsts from "../../../utils/constants/types";
import * as TextProps from "../../../utils/constants/text";
import * as routeProps from "../../../utils/constants/routesConstants";
import { screeningStaticLabels } from "../../screening/screeningText";
import SidebarNavigation from "../../../utils/components/sidebar/sidebarNavigation";
import sections from "../applyPebtMapData";
import LanguageSelect from "../../../redux/language/languageSelect";
//end code for google analytics
const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

var pdf = PdfEn;
switch (language) {
  case languageConsts.ENGLISH:
    pdf = PdfEn;
    break;
  case languageConsts.SPANISH:
    pdf = PdfEs;
    break;
  case languageConsts.PORTUGUESE:
    pdf = PdfBr;
    break;
  case languageConsts.CHINESE:
    pdf = PdfZh;
    break;
  case languageConsts.VIETNAMESE:
    pdf = PdfVi;
    break;
  default:
    pdf = PdfEn;
    break;
}
class PebtCaseInfo extends Component {
  constructor(props) {
    super();
    enableUniqueIds(this);
    this.state = {
      pebtCaseNumber: "",
      sections: sections,
      isPEBTNumber: "",
      isNotPEBTNumber: "",
      pebtError: TextProps.VALUE_FALSE,
      isloading: TextProps.VALUE_TRUE,
      isDisabled: TextProps.VALUE_FALSE,
      directionClass: "forward",
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("pebtData") !== null) {
      sessionStorage.removeItem("pebtData");
      window.location.reload();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activatePEBT !== undefined) {
      if (nextProps.activatePEBT.pebtData !== undefined) {
        this.setState({ isDisabled: TextProps.VALUE_FALSE });
        if (nextProps.activatePEBT.pebtData.caseNumber) {
          var caseNumber = nextProps.activatePEBT.pebtData.caseNumber;
          this.setState({ isPEBTNumber: caseNumber, isNotPEBTNumber: "" });
          sessionStorage.setItem(
            "pebtData",
            nextProps.activatePEBT.pebtData.caseNumber
          );
        }
        if (nextProps.activatePEBT.pebtData === "No Case Information") {
          var notCaseNumber = nextProps.activatePEBT.pebtData;
          this.setState({ isPEBTNumber: "", isNotPEBTNumber: notCaseNumber });
          sessionStorage.setItem("pebtData", nextProps.activatePEBT.pebtData);
        }
      }
    }
  }

  getPEBTCaseNumber = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    this.setState({
      pebtError: TextProps.VALUE_TRUE,
      isDisabled: TextProps.VALUE_TRUE,
    });
    this.pebtValidations();
  };

  pebtValidations = () => {
    const pebtCaseNumber = this.state.pebtCaseNumber;
    if (pebtCaseNumber.length !== 18) {
      this.setState({
        pebtError: TextProps.VALUE_TRUE,
        isDisabled: TextProps.VALUE_FALSE,
      });
    } else {
      this.setState({ pebtError: TextProps.VALUE_FALSE });
      this.props.submitPEBTCaseNumber(pebtCaseNumber, language);
    }
  };
  onSecondaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    window.location.assign(routeProps.PEBT_PATH);
  };
  render() {
    const section = this.state.sections[0];
    const { isPEBTNumber, isNotPEBTNumber, pebtError } = this.state;
    const { pebtData } = this.props.activatePEBT;
    const caseNumber = pebtData && pebtData.caseNumber;
    const ebtBalance = pebtData && pebtData.ebtBalance;
    const ebtBalanceDate = pebtData && pebtData.ebtBalanceDate;
    const ebtBalanceError = pebtData && pebtData.ebtBalanceError;
    let ebtErrLabel = "";
    if (ebtBalanceError) {
      ebtErrLabel = (
        <div className="pebt-case-error-msg pure-u--5">
          <b>
            <span className="dta-form__error-message pebt-case-info">
              {pebtCaseInfoPagelanguageLabels(language).ebtBalanceError}
            </span>
          </b>
        </div>
      );
    } else {
      ebtErrLabel = (
        <p>
          <h3>
            {pebtCaseInfoPagelanguageLabels(language).asOf}{" "}
            {formatters.date(
              formatters.convertStringDateToDate(ebtBalanceDate)
            )}
            , {pebtCaseInfoPagelanguageLabels(language).pebtBalanceAmountIs}{" "}
            {formatters.formatMoney(ebtBalance)}
          </h3>
        </p>
      );
    }

    return (
      <div key={this.nextUniqueId()} className={`full-height apply`}>
        <SidebarNavigation
          pebt={this.state.pebt}
          sections={this.state.sections}
          activeSectionId={this.state.sections[0].id}
          inProgressSectionId={this.state.sections[0].id}
          onSectionClick={() => {}}
          // onSaveProgress={this.presentSaveProgressModal}
          canSaveProgress={TextProps.VALUE_TRUE}
          pebtCaseNumber={isPEBTNumber}
          skipToAnchor="#content"
        />
        <main
          className={`apply__content-container ${
            isPEBTNumber ? "case-info-success" : "case-info"
          }`}
          id="content"
          tabIndex="-1"
        >
          <div className={`slide-animation ${this.state.directionClass}`}>
            <div className="apply__content pebt-case-container">
              <div className="apply__header pad-all--one-five apply_tfdc">
                <div className="pebt-case-content pad-top pebt-title">
                  <h1 className="pebt-page-heading">
                    {pebtCaseInfoPagelanguageLabels(language).title}
                  </h1>
                  <div className="pure-g pebt-sub-heading">
                    <div className="pebt-case-header pure-u-2-3">
                      <p className="pebt-heading">
                        {" "}
                        {pebtCaseInfoPagelanguageLabels(language).subtitle}
                      </p>
                    </div>
                    <div className="pebt-case-logo pure-u-1-3">
                      <img
                        src={pEBT}
                        className="apply__img-getting-started"
                        alt="PEBT Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pad-all--one-five">
                <p>{pebtCaseInfoPagelanguageLabels(language).warn1}</p>
                <br />
                <form onSubmit={this.getPEBTCaseNumber}>
                  <label>
                    {pebtCaseInfoPagelanguageLabels(language).label1}:
                    {pebtError && (
                      <div className="pebt-case-error-msg">
                        <span className="dta-form__error-message">
                          {
                            pebtCaseInfoPagelanguageLabels(language)
                              .validCardError
                          }
                        </span>
                      </div>
                    )}
                    <div className="pebt-case-number">
                      <div className="pure-g">
                        <div className="pure-u-1-1">
                          <DTAInput
                            className="dta-form__control"
                            id="pebtCaseNumber"
                            type="pebt"
                            onChange={(val) =>
                              this.setState({ pebtCaseNumber: val })
                            }
                            required={TextProps.VALUE_TRUE}
                          />
                        </div>
                        {/* <div className="pure-u-1-3 pad-left">
                                        <button className="dta-button dta-button--primary" type="submit"
                                            disabled={this.state.isDisabled}
                                            onClick={this.getPEBTCaseNumber}>{pebtCaseInfoPagelanguageLabels(language).submit}</button>
                                    </div> */}
                      </div>
                    </div>
                  </label>
                  <div className="apply__footer pad-all--double">
                    {/* Generally, clicking this secondary action button would route user to previous section,
                        or back to homepage / where they came from if applicable */}
                    <button
                      type="button"
                      className="dta-button dta-button--outline-primary dta-button--large"
                      onClick={this.onSecondaryActionClick}
                    >
                      {screeningStaticLabels(language).goBack}
                    </button>
                    {/* Generally, clicking this primary action button would perform any necessary
                        validation and route user to next section */}

                    <button
                      type="submit"
                      className="dta-button dta-button--primary dta-button--large"
                      // form={"pebt-case-info"}
                      // onClick={this.getPEBTCaseNumber}
                      id="pebt"
                    >
                      {pebtCaseInfoPagelanguageLabels(language).submit}
                    </button>
                  </div>
                </form>
              </div>
              {isNotPEBTNumber && !pebtError ? (
                <div className="pebt-case-error-msg pure-u--5">
                  <b>
                    <span className="dta-form__error-message pebt-case-info">
                      {pebtCaseInfoPagelanguageLabels(language).noCaseInfo}
                    </span>
                  </b>
                </div>
              ) : null}
              {isPEBTNumber && !pebtError ? (
                <div className="pebt-case-success">
                  <div className="pebt-case-success-header pad-all--single">
                    <p>
                      <h3>
                        {pebtCaseInfoPagelanguageLabels(language).yourCase}{" "}
                        {caseNumber}
                      </h3>
                    </p>
                    {pebtCaseInfoPagelanguageLabels(language).saveNumber}
                    {ebtErrLabel}
                  </div>
                  <div className="pebt-case-divider"></div>
                  <div className="pebt-case-success-content pad-all--single">
                    <h4>
                      {pebtCaseInfoPagelanguageLabels(language).additionalInfo}
                    </h4>
                    <div>
                      <ul>
                        <li>
                          {pebtCaseInfoPagelanguageLabels(language).childNumber}
                          <a href={pdf} target="_blank">
                            click here
                          </a>{" "}
                          {pebtCaseInfoPagelanguageLabels(language).learnPIN}
                        </li>
                        <li>
                          {pebtCaseInfoPagelanguageLabels(language).forInfo}{" "}
                          <a href="https://www.map-ebt.org" target="_blank">
                            map-ebt.org
                          </a>
                          . {pebtCaseInfoPagelanguageLabels(language).callDTA}
                        </li>
                        <li>
                          {pebtCaseInfoPagelanguageLabels(language).saveCard}
                        </li>
                        <li>
                          {pebtCaseInfoPagelanguageLabels(language).noBenefits}{" "}
                          <a
                            href="https://dtaconnect.eohhs.mass.gov/"
                            target="_blank"
                          >
                            DTAConnect.com
                          </a>{" "}
                          {pebtCaseInfoPagelanguageLabels(language).learnMore}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <LanguageSelect className="language-select--dark" />
        </main>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitPEBTCaseNumber: submitPEBTCaseNumber,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PebtCaseInfo);
