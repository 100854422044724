import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../../redux/rootReducer";

import * as NotificationProps from "../../../constants/notification";
import { getDTANotificationData } from "../../../../redux/consumer/consumerDocumentsActions";
import { date } from "../../utilities/formatters";
import { onLogOut } from "../../../helper/accountNavigationActions";
import * as TextProps from "../../../../utils/constants/text";
import * as formatters from '../../../../utils/components/utilities/formatters.js';


/*
  Notification ListItem contents
  --------------------------

  # Purpose:
  To display a notice in a list

  # Props:
  [NotificationProps.NAME]: Required, string name of the notice
  [NotificationProps.URL]: Required, string url pointing to the file of the notice
  [NotificationProps.STATUS]: Required, status of the notice, one of STATUS_UNREAD or STATUS_READ
  [NotificationProps.DATE]: Required, Date of when the notice was posted

  # Example:
  <NotificationContents {...props} />
 */

class NotificationContents extends Component {
  constructor(props) {
    super(...arguments);
    this.handleListClickAction = this.handleListClickAction.bind(this);
  }

  static propTypes = {
    [NotificationProps.NAME]: PropTypes.string.isRequired,
    // REMOVE THE COMMENTED ITEMS ONCE NOTIFICATION STATUS IS ENABLED
    [NotificationProps.DATE]: PropTypes.instanceOf(Date),
  };

  handleListClickAction = (event) => {
    event.preventDefault();
    this.props.consumer.showDocumentLoading = TextProps.VALUE_TRUE;
    const agencyID = this.props.agencyID;
    const noticeID = this.props.id;
    if (agencyID !== "" && noticeID !== "") {
      this.props.onNotificationItemClick(noticeID, agencyID).catch((error) => {
        if (error !== undefined && error.response !== undefined) {
          if (
            error.response.status !== undefined &&
            error.response.status === 403
          ) {
            this._handleApplyLogoutSession();
          }
        }
      });
    }
  };

  _handleApplyLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.authToken = this.props.consumer.mfaDetails.authToken;
    this.props.onLogOut(paramsLogout);
  };

  render() {
    return (
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-1-2">
          <a
            onClick={this.handleListClickAction.bind(this)}
            href="#"
            className="text--bold text--medium"
            rel="noopener noreferrer"
          >
            {this.props[NotificationProps.NAME]}
          </a>
        </div>
        <div className="show-mobile show-small pure-u-1 pad-top-half" />
        <div className="pure-u-1 pure-u-md-1-2">
          <div className="pure-u-1-2"></div>
          <div className="text--right pure-u-1-2">
          {formatters.formatDateBySlash(this.props[NotificationProps.DATE], {
              humanize: TextProps.VALUE_TRUE,
            })}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onNotificationItemClick: getDTANotificationData,
      onLogOut: onLogOut,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContents);
