import axios from "axios";
import {retriveSections} from './reportChangeMapData';
import * as TextProps from "../../utils/constants/text";
import { getAudit } from "../../utils/constants/audit";
import * as roleProps from "../../utils/constants/rolesConstants";
const config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
	fetchSectionQuestions: 'FETCH_SECTION_QUESTIONS',
  addHouseholdInfoIntoSections: 'ADD_HOUSEHOLD_INFO_INTO_SECTIONS',
  setHouseholdMembers: "RC_SET_HOUSEHOLD_MEMBERS",
  addHouseholdToList:"ADD_HOUSEHOLD_TO_LIST",
  addHouseholdSection:"ADD_HOUSEHOLD_MEMBER_SECTION",
  updateHouseholdLabelChange:"UPDATE_RC_HOUSEHOLD_LABEL_CHANGE",
  deleteHouseholdInfoIntoSections: 'DELETE_HOUSEHOLD_INFO_INTO_SECTIONS',
  setError: 'SET_ERROR',
  postDataSuccess:"REPORT_POST_DATA_SUCCESS",
  postDataError:"REPORT_POST_DATA_ERROR",
  postDataLoading:"REPORT_POST_DATA_LOADING",
  appendRCIncome:"APPEND_RC_INCOME",
  updateReportChangeSections: "UPDATE_REPORT_CHANGE_SECTIONS",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
  setRCShelterData: "SET_RC_SHELTER_DATA",
  appendRCSections:"APPEND_RC_SECTIONS",
  appendRCShelterSubSectionQuestions:"APPEND_RC_SHELTER_SUBSECTIONS_QUESTIONS",
  setRCChildSupportData:"RC_SET_CHILD_SUPPORT",
  appendRCChildSupportAnswer: "APPEND_RC_CHILD_SUPPORT_ANSWER",
  appendSignInSections:"APPEND_SIGN_IN_SECTIONS",
  appendRCUtilityLiheapData:"APPEND_RC_UTILITY_LIHEAP_DATA",
  appendResources: "APPEND_RC_RESOURCES_DATA",
  setHouseholdResourcesChangeStatus: "SET_HOUSEHOLD_RESOURCES_STATUS",
};

export const fetchSectionsByModule = (module) => {
    return (dispatch) => {
      dispatch({
        type: actionTypes.fetchSectionQuestions,
		    payload: retriveSections(module)
      });
    }
}

export const setError = (sections) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.setError,
      payload: sections
    });
  }
}

export function addHouseholdIntoSections(houseHoldAddObj) {
  return (dispatch) => {
    dispatch({
    type: actionTypes.addHouseholdInfoIntoSections,
    payload: houseHoldAddObj,
  });
}
}

export function deleteHouseholdIntoSections() {
  return (dispatch) => {
    dispatch({
    type: actionTypes.deleteHouseholdInfoIntoSections,
  });
}
}


export function setHouseholdMembersList(houseHoldObj) {
  return (dispatch) => {
    dispatch({
    type: actionTypes.setHouseholdMembers,
    payload: houseHoldObj,
  })
};
}

export function addHouseholdToList(houseHoldSection) {
  return (dispatch) => {
    dispatch({
    type: actionTypes.addHouseholdSection,
    payload: houseHoldSection,
  })
};
}


export function updateHouseholdLabelChanges(houseHoldUpdatedSection) {
  return (dispatch) => {
    dispatch({
    type: actionTypes.updateHouseholdLabelChange,
    payload: houseHoldUpdatedSection,
  })
};
}


export function updateReportChangeSections(sections) {
  return (dispatch) => {
    return dispatch( {
      type: actionTypes.updateReportChangeSections,
      payload: sections,
    });
  };
}


export function postData(payloadData) {

  // triger api
  return (dispatch) => {
       dispatch({
        type: actionTypes.postDataLoading,
      })
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
    const selectedConsumerEmail = sessionStorage.getItem("selectedConsumerEmail");
    const audit = getAudit();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        audit: JSON.stringify(audit),
        Authorization: oauthToken.token_type + " " + oauthToken.access_token,
      },
      mode: "no-cors",
      "Access-Control-Allow-Origin": config.allowed_domain,
    };
    if (userInfo && userInfo.roleList[0].roleName === roleProps.CONSUMER) {
      sessionStorage.setItem("isNewConsumer", TextProps.VALUE_FALSE);
      var url = config.TigerContextURL + "/consumer/" + userInfo.email;
      axios
        .post(url, payloadData, requestOptions)
        .then(function (response) {
          if (response.data.code && response.data.code == 118) {
            return dispatch(serviceFailedAction());
          } else {
           //success
           return dispatch(
            {
              type: actionTypes.postDataSuccess,
            }
           )
          }
        })
        .catch(function (error) {
          return dispatch({type: actionTypes.postDataError});
        });
    } else {
      const providerData = JSON.parse(sessionStorage.getItem("providerData"));
      if (providerData && providerData.clientID) {
        sessionStorage.setItem("isNewConsumer", TextProps.VALUE_FALSE);
        var urlConsumerbyid =
          config.TigerContextURL + "/consumer/" + selectedConsumerEmail;
        payloadData.clientIdK = providerData.clientID
        axios
          .post(urlConsumerbyid, payloadData, requestOptions)
          .then(function (response) {
            if (response.data.code && response.data.code == 118) {
              return dispatch(serviceFailedAction());
            } else {
              //success
              return dispatch({
                type: actionTypes.postDataSuccess,
              })
            }
          })
          .catch(function (error) {
            return dispatch({type: actionTypes.postDataError});
          });
      }
    }
  };
}

// if service failure to getting data
function serviceFailedAction() {
  return {
    type: actionTypes.serviceFailedAction,
  };
}

export function clearMemberIncome(
  incomeMemberTemplate,
  incomeSummaryTemplate,
  incomeTemplate,
  status = null
) {
  if (status !== null && status === TextProps.VALUE_FALSE) {
    incomeTemplate.retainData = TextProps.VALUE_TRUE;
  } else {
    incomeTemplate.retainData = null;
  }
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(incomeSummaryTemplate);
    incomeTemplate.subsections = summaryTemplate;
    return dispatch(incomeSuccess(incomeTemplate));
  };
}

export function addRCIncomeSections(sections){
  return (dispatch) => {
    return dispatch(incomeSuccess(sections));
  }
}

function incomeSuccess(income) {
  return {
    type: actionTypes.appendRCIncome,
    payload: income,
  };
}



export function setShelterData(shelterObj) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.setRCShelterData,
      payload: shelterObj,
    });
  };
}

export function appendRCSections(section) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.appendRCSections,
      payload: section,
    });
  };
}

export function appendRCShelterFilteredQuestions(shelterQuestions) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch({
      type: actionTypes.appendRCShelterSubSectionQuestions,
      payload: shelterQuestions,
    });
  };
}


export function setChildSupportData(childObj) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.setRCChildSupportData,
      payload: childObj,
    });
  };
}

export function appendChildSupportAnswer(answer) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.appendRCChildSupportAnswer,
      payload: answer,
    });
  };
}

export function appendSignInSections(section) {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.appendSignInSections,
      payload: section,
    });
  };
}


export function appendRCUtilityLiheapData(data) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch({
        type: actionTypes.appendRCUtilityLiheapData,
        payload: data,
    });
  };
}


export function setDataChangeStatus(status, index) {
  return (dispatch) => {
    dispatch(setChangeStatus(status, index));
  };
}

function setChangeStatus(status, index) {
  return {
    type: actionTypes.setDataChangeStatus,
    payload: status,
    index: index,
  };
}

export function setEarnedIncomeChangeStatus(status) {
  return (dispatch) => {
    dispatch(setEarnedIncomeStatus(status));
  };
}

function setEarnedIncomeStatus(status) {
  return {
    type: actionTypes.setEarnedIncomeStatus,
    payload: status,
  };
}


//resources
export function appendResourcesDetails(resources) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(resourcesSuccess(resources));
  };
}

function resourcesSuccess(income) {
  return {
    type: actionTypes.appendResources,
    payload: income,
  };
}

//Clearing Resources Template
export function clearMemberResources(
  resourcesMemberTemplate,
  resourcesSummaryTemplate,
  resourcesTemplate,
  status = null
) {
  // if (status !== null && status === TextProps.VALUE_FALSE) {
  //   resourcesTemplate.retainData = TextProps.VALUE_TRUE;
  // } else {
  //   resourcesTemplate.retainData = null;
  // }
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(resourcesSummaryTemplate);
    resourcesTemplate.subsections = summaryTemplate;
    return dispatch(resourcesSuccess(resourcesTemplate));
  };
}

export function setHouseholdResourcesChangedStatus(status) {
  return (dispatch) => {
    dispatch(setHouseholdResourcesStatus(status));
  };
}

function setHouseholdResourcesStatus(status) {
  return {
    type: actionTypes.setHouseholdResourcesChangeStatus,
    payload: status,
  };
}