import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import Collapse from "react-collapse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import Radio from "../../../utils/components/radio";
import Icon from "../../../utils/components/dtaIcon";
import * as Props from "../../../utils/components/shared";
import {
  getFrequencyCode,
  getFrequency,
} from "../../../utils/components/utilities/utils";
import HelpTip from "../../../utils/components/helpTip";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import { formatMoney } from "../../../utils/components/utilities/formatters";
import { unearnedIncomeCodeMapper } from "../unearnedIncomeMapData";
import { unearnedIncomeSectionTemplate } from "../recertCovidMapData";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import {
  clearMemberUIIncome,
  appendUIIncomeDetails,
  setDataChangeStatus,
  setUnearnedIncomeChangeStatus,
} from "../../../redux/recertCovid/recertCovidActions";
import { interimReportPageLabels } from "../recertCovidText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class UnearnedIncome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unearnedIncomeMemberTemplate: null,
      unearnedIncomeSummaryTemplate: null,
      respData: null,
      incomeUIValuesPopulated: TextProps.VALUE_FALSE,
      unearnedIncomeSubSectionCleared: TextProps.VALUE_FALSE,
      unearnedIncomeChanged: TextProps.VALUE_FALSE,
      incomeUnearnedSummaryEdit: TextProps.VALUE_FALSE,
      reportedChanges: TextProps.VALUE_FALSE,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      sections: [],
      renderedSections: [],
      houseMemberLabels: [],
    };
    enableUniqueIds(this);
  }

  //Life cycle methods
  componentWillReceiveProps() {
    this.setState(
      {
        sections: this.props.covidRecert.sections,
        respData: this.props.covidRecert.responseObject,
      },
      () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(this.props.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );
    if (this.props.covidRecert.setOldUIncomeData === TextProps.VALUE_TRUE) {
      this.onUnearnedEarnedIncomeChanged(
        interimReportPageLabels(language, languageConstants.aboutMe)
          .optionYesNo[1]
      );
    }
  }

  // Toggle whether adding new income members is open on state
  toggleAddUnearnedIncomeMembers = () => {
    this.setState({
      addUnearnedMembersOpen: !this.state.addUnearnedMembersOpen,
    });
  };

  onUnearnedEarnedIncomeChanged = (value) => {
    if (
      value ===
      interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1]
    ) {
      this.setState(
        {
          incomeUIValuesPopulated: TextProps.VALUE_FALSE,
          unearnedIncomeSubSectionCleared: TextProps.VALUE_FALSE,
          unearnedIncomeChanged: !this.state.unearnedIncomeChanged,
        },
        () => {
          this.onUnearnedIncomeSubSectionRetain();
          this.props.setUnearnedIncomeChangeStatus(TextProps.VALUE_FALSE);
          this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 4);
        }
      );
    } else {
      this.setState(
        {
          unearnedIncomeChanged: !this.state.unearnedIncomeChanged,
        },
        () => {
          this.props.setUnearnedIncomeChangeStatus(TextProps.VALUE_TRUE);
          this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 4);
        }
      );
    }
  };

  onUnearnedIncomeSubSectionClean = () => {
    if (this.state.unearnedIncomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          unearnedIncomeSubSectionCleared: TextProps.VALUE_TRUE,
          unearnedIncomeMemberTemplate: _.cloneDeep(
            unearnedIncomeSectionTemplate
          ),
          unearnedIncomeSummaryTemplate:
            this.props.covidRecert.sections[4].subsections[
              this.props.covidRecert.sections[4].subsections.length - 1
            ],
        },
        () => {
          this.props.clearMemberUIIncome(
            this.state.unearnedIncomeMemberTemplate,
            this.state.unearnedIncomeSummaryTemplate,
            this.props.covidRecert.sections[4]
          );
          this.props.onUnearnedIncomeSubsectionRender();
        }
      );
    }
  };

  onUnearnedIncomeSubSectionRetain = () => {
    if (this.state.unearnedIncomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          unearnedIncomeSubSectionCleared: TextProps.VALUE_TRUE,
          unearnedIncomeMemberTemplate: _.cloneDeep(
            unearnedIncomeSectionTemplate
          ),
          unearnedIncomeSummaryTemplate:
            this.props.covidRecert.sections[4].subsections[
              this.props.covidRecert.sections[4].subsections.length - 1
            ],
        },
        () => {
          this.props.clearMemberUIIncome(
            this.state.unearnedIncomeMemberTemplate,
            this.state.unearnedIncomeSummaryTemplate,
            this.props.covidRecert.sections[4],
            TextProps.VALUE_FALSE
          );
          this.props.onUnearnedIncomeSubsectionRender(
            this.state.unearnedIncomeMemberTemplate
          );
        }
      );
    }
  };

  renderUnearnedIncomeSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >
          <HelpTip
            textBefore={
              this.props.getIncomeSummaryTitle(
                summary.firstName,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sUIncomebefore,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sUIncomeAfter
              ).before
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>

        <p className="interim-recert-heading apply__body-header help-text-fix">
          <HelpTip
            triggerText={
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).sUIncometrigger
            }
            textAfter={
              this.props.getIncomeSummaryTitle(
                summary.firstName,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sUIncomebefore,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sUIncomeAfter
              ).after
            }
            helpText={
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).sUIncomeHelp
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        {/*
          The edit button should route the user to the appropriate apply section
          to edit income for this household member.
          Later, when the user clicks continue on that section, they are returned to the
          "Income Summary" section again.
        */}
        {this.props.covidRecert.unearnedIncomeChanged &&
        this.props.covidRecert.unearnedIncomeChanged ===
          TextProps.VALUE_TRUE ? (
          <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => this.incomeUISummaryEditClick(summary.section)}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).editButtonLabel
            }
          </button>
        ) : null}
        <ul>
          {summary.incomes.map((incomeItem) => {
            if (
              incomeItem.Answer !== undefined &&
              incomeItem.Answer.length > 0
            ) {
              return incomeItem.Answer.map((answer) => {
                if (answer.unit && answer.amount) {
                  let label = "";
                  if (answer.code !== undefined) {
                    if (answer.code === "new") {
                      label = "newLabel";
                    } else if (answer.code === "changed") {
                      label = "changedLabel";
                    }
                  }
                  return (
                    <li key={answer.id}>
                      <span className="spaced-text">
                        {answer.originalTypeCode !== undefined
                          ? unearnedIncomeCodeMapper(language)[
                              answer.originalTypeCode
                            ]
                          : incomeItem.type}
                      </span>
                      {label !== "" ? (
                        <DataChangeIndicator
                          text={
                            interimReportPageLabels(
                              language,
                              languageConstants.householdStatic,
                              null,
                              null,
                              null
                            )[label]
                          }
                        />
                      ) : null}
                      {this.amountIteratorForIncome(answer.unit, answer.amount)}
                    </li>
                  );
                }
              });
            }
          })}
        </ul>
      </div>
    );
  };

  amountIteratorForIncome = (unit, amount) => {
    let amountIterator = 1;
    let classRowCount = 3;
    if (getFrequencyCode(language)[unit] === "WEEK") {
      amountIterator = 4;
      classRowCount = 24;
    } else if (getFrequencyCode(language)[unit] === "BIWEEK") {
      amountIterator = 2;
      classRowCount = 24;
    }

    let amountRows = [];
    for (let i = 0; i < amountIterator; i++) {
      if (amount[i]) {
        amountRows.push(
          <div className={"pure-u-" + classRowCount + "-24"}>
            <span>
              {amount !== undefined &&
              amount[i] !== undefined &&
              amount[i] !== ""
                ? formatMoney(amount[i])
                : ""}
            </span>
          </div>
        );
      }
    }

    return (
      <div className="max-width pure-u-1-1">
        <div className="pure-u-11-24 pure-u-sm-18-24 pure-u-md-18-24 pure-u-lg-18-24">
          <span className="label-text-clr" style={{ wordBreak: "break-word" }}>
            {getFrequency(language)[getFrequencyCode(language)[unit]]}{" "}
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).amountLabel
            }
          </span>
        </div>
        <div
          className={"pure-u-8-24 pure-u-sm-6-24 pure-u-md-6-24 pure-u-lg-6-24"}
        >
          {amountRows}
        </div>
        <hr className="divider" />
      </div>
    );
  };

  //Edit Button in Income Summary
  incomeUISummaryEditClick = (section) => {
    this.setState({ incomeUnearnedSummaryEdit: TextProps.VALUE_TRUE });
    this.props.onSectionChange(section);
  };

  renderUnearnedIncomeToAddNewMember = (name) => {
    var incomeMeTemplate = _.cloneDeep(unearnedIncomeSectionTemplate);
    var incomeCopy = this.props.covidRecert.sections[4];
    let incomeSubsectionCopy = incomeCopy.subsections;

    let incomeSummaryCopy = _.cloneDeep(
      incomeCopy.subsections[incomeCopy.subsections.length - 1]
    );
    incomeCopy.subsections.splice(incomeCopy.subsections.length - 1, 1);

    incomeMeTemplate.id =
      "unearned-combined-income-" + incomeCopy.subsections.length;
    incomeMeTemplate.header = interimReportPageLabels(
      language,
      languageConstants.unearnedIncomeDynamic,
      name,
      null,
      null
    ).heading;
    incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
      language,
      languageConstants.unearnedIncomeDynamic,
      name,
      null,
      null
    ).mainQuestion;
    incomeMeTemplate.questions[0].Answer = "";
    incomeMeTemplate.questions[0].value = "";
    incomeMeTemplate.questions[0].targetValue = "";
    incomeMeTemplate.subsections[0].id =
      "unearned-combined-income-subsection-" + incomeCopy.subsections.length;
    incomeMeTemplate.subsections[0].header = interimReportPageLabels(
      language,
      languageConstants.earnedIncomeDynamic,
      name,
      null,
      null
    ).heading;
    incomeMeTemplate.title = name;
    let incomeReturn = interimReportPageLabels(
      language,
      languageConstants.unearnedIncomeDynamic,
      name,
      null,
      null
    );

    for (var j = 0; j < incomeMeTemplate.subsections[0].questions.length; j++) {
      incomeMeTemplate.subsections[0].questions[j].amountLabel =
        incomeReturn.subQuestions[j].amountLabel;
      incomeMeTemplate.subsections[0].questions[j].unitLabel =
        incomeReturn.subQuestions[j].unitLabel;
      incomeMeTemplate.subsections[0].questions[
        j
      ].repeatedQuestion.amountLabel = incomeReturn.subQuestions[j].amountLabel;
      incomeMeTemplate.subsections[0].questions[j].repeatedQuestion.unitLabel =
        incomeReturn.subQuestions[j].unitLabel;
      incomeMeTemplate.subsections[0].questions[j].canBeDeleted =
        TextProps.VALUE_TRUE;
      incomeMeTemplate.subsections[0].questions[j].Answer = "";
      incomeMeTemplate.subsections[0].questions[j].value = "";
      incomeMeTemplate.subsections[0].questions[j].targetValue = "";
    }
    incomeSubsectionCopy.push(_.cloneDeep(incomeMeTemplate));
    incomeSubsectionCopy.push(incomeSummaryCopy);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeSubsectionCopy;
    this.props.appendUIIncomeDetails(incomeCopy);
    this.props.prepareUnearnedIncomeSummary();
    this.props.onSectionChange(incomeMeTemplate);
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  render() {
    var listOfUpdatedHHMembers = [];
    var existingIncomeMembers = [];
    let unearnedIncomeChangeVal = null;
    if (this.props.covidRecert.unearnedIncomeChanged === TextProps.VALUE_TRUE) {
      unearnedIncomeChangeVal = interimReportPageLabels(
        language,
        languageConstants.aboutMe
      ).optionYesNo[0];
    } else if (
      this.props.covidRecert.unearnedIncomeChanged === TextProps.VALUE_FALSE
    ) {
      unearnedIncomeChangeVal = interimReportPageLabels(
        language,
        languageConstants.aboutMe
      ).optionYesNo[1];
    }
    let addIncomeMembersActive = "";
    this.props.covidRecert.householdMembers.membersList.map((memberHH) => {
      if (memberHH.dataChangeCode !== undefined) return;
      var incomeHouseHoldName = memberHH.firstName;
      var incomeCombinedName = "";
      if (incomeHouseHoldName !== "") {
        incomeCombinedName = incomeHouseHoldName + " " + memberHH.lastName;
        let incomeHouseHoldDOB = memberHH.dateOfBirth;
        if (memberHH.dateOfBirth !== "") {
          incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
        }
      } else if (incomeHouseHoldName === "") {
        incomeCombinedName = memberHH.firstName;
      }
      listOfUpdatedHHMembers.push(incomeCombinedName);

      if (this.props.incomeSummary.unearnedIncomeMembers !== undefined) {
        this.props.incomeSummary.unearnedIncomeMembers.map((mem) => {
          existingIncomeMembers.push(mem.firstName);
        });
      }
    });
    if (this.state.addIncomeMembersOpen) {
      addIncomeMembersActive = "is-active";
    }
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {this.props.incomeSummary.unearnedIncomeMembers.map((summary) =>
          this.renderUnearnedIncomeSummaryItem(summary)
        )}
        {this.props.covidRecert.unearnedIncomeChanged &&
        this.props.covidRecert.unearnedIncomeChanged === TextProps.VALUE_TRUE &&
        this.props.incomeSummary.nonUnearnedIncomeMembers.length > 0 ? (
          <div>
            <button
              className={`add-new-button dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon text--left ${addIncomeMembersActive}`}
              onClick={this.toggleAddUnearnedIncomeMembers}
              aria-expanded={this.state.addUnearnedMembersOpen}
            >
              <Icon name="plus" ariaHidden={TextProps.VALUE_TRUE} />
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).anotherHouseholdIncomeLabel
              }
            </button>
            <Collapse isOpened={this.state.addUnearnedMembersOpen}>
              <span className="add-income-summary__label"></span>
              <ul className="list--unstyled add-income-summary__list">
                {listOfUpdatedHHMembers.map((member) => {
                  if (existingIncomeMembers.indexOf(member) >= 0)
                    return TextProps.VALUE_FALSE;
                  return (
                    <li key={member.id}>
                      {/*
                    Clicking the following button routes the user to a
                    new child Income section to fill out income info for this
                    household member (e.g. "Listing John's Income"). When user
                    is finished and clicks 'Continue', they will be returned to Income Summary page
                  */}
                      <a
                        style={{ textDecoration: "none" }}
                        href="javascript:void(0);"
                        onClick={() => {
                          this.renderUnearnedIncomeToAddNewMember(member);
                          return TextProps.VALUE_FALSE;
                        }}
                        className="dta-button dta-button--outline-secondary dta-button--block"
                      >
                        <span className="sr-only">Add income for </span>
                        <span className="truncate-text">{member}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </div>
        ) : null}
        <hr className="divider" />
        <span>
          {
            interimReportPageLabels(language, languageConstants.beforeYouStart)
              .unearnedIncome_ChangeQuestion
          }
        </span>
        <br />
        <br />
        <div className="pure-u-1-1">
          <div>
            <fieldset className="dta-form__group">
              {this.props.covidRecert.sections[4].selectionMade ===
                TextProps.VALUE_FALSE && (
                <span className="dta-form__error-message">
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).requiredErrorMessage
                  }
                </span>
              )}
              <Radio
                name="earned-income-chnaged"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={
                  interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo
                }
                required={TextProps.VALUE_TRUE}
                value={unearnedIncomeChangeVal}
                onChange={(value) => this.onUnearnedEarnedIncomeChanged(value)}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearMemberUIIncome: clearMemberUIIncome,
      appendUIIncomeDetails: appendUIIncomeDetails,
      setDataChangeStatus: setDataChangeStatus,
      setUnearnedIncomeChangeStatus: setUnearnedIncomeChangeStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UnearnedIncome);
