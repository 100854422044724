import * as Types from "../../utils/constants/types";
import * as OptionsProps from "../../utils/constants/options";
import * as Props from "../../utils/components/shared";
import {
  pebtCaseInfoPagelanguageLabels,
  aboutMeMultilanguageLabels,
} from "./applyPebtText";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (language === null || language === "select") {
    language = "en-US";
  }

export const sections = [
  {
    id: "pebtCaseInfo",
    title: pebtCaseInfoPagelanguageLabels(language).heading,
    header: pebtCaseInfoPagelanguageLabels(language).title,
    completed: TextProps.VALUE_TRUE,
    webClientId: "GRANTEE",
    webAppId: "",
    webPgrmId: "",
    isSubmitted: TextProps.VALUE_FALSE,
    isSubmitInitiated: TextProps.VALUE_FALSE,
    errorRedirect: TextProps.VALUE_FALSE,
    questions: [],
  },
  {
    id: "replacepebt",
    title: aboutMeMultilanguageLabels(language).title,
    header: aboutMeMultilanguageLabels(language).header,
    subHeader: aboutMeMultilanguageLabels(language).subHeader,

    questions: [
      {
        [Props.NAME]: "firstName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).labelchildFirstName,
        [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).firstNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: "middleName",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).middleName,
        [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).middleNameErrMsg,
        [Props.MAX_LENGTH]: 15,
      },
      {
        [Props.NAME]: "lastname",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).lastNamelabel,
        [Props.TYPE]: Types.INPUT_ALPHA_TEXT,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).lastNameErrMsg,
        [Props.MAX_LENGTH]: 20,
      },
      {
        [Props.NAME]: "dateOfBirth",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).dateOfBirthLabel,
        [Props.TYPE]: Types.DATE,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]: aboutMeMultilanguageLabels(language).dobAlertMsg,
      },
      {
        [Props.NAME]: "address",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).addrQuestionlabel,
        [Props.TYPE]: Types.ADDRESS_COMMON,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).addressErrmsg,
        [Props.ERROR_MESSAGE_FOR_CHAR]:
          aboutMeMultilanguageLabels(language).citiErrmsg,
        [Props.ERROR_MESSAGE_FOR_ADDRESS]:
          aboutMeMultilanguageLabels(language).addressErrmsg,
        [Props.ERROR_MESSAGE_FOR_ZIPCODE]:
          aboutMeMultilanguageLabels(language).zipCodeErrmsg,
        [Props.ERROR_MESSAGE_FOR_MAS_ZIPCODE]:
          aboutMeMultilanguageLabels(language).masZipCodeErrmsg,
      },
      {
        [Props.NAME]: "phoneNumber",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).bestPhoneqlabel,
        [Props.TYPE]: Types.INPUT_PHONE,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).validPhoneAlert,
      },
      {
        [Props.NAME]: "signupalert",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).signupalert,
        [Props.TYPE]: Types.RADIO,
        [Props.REQUIRED]: TextProps.VALUE_TRUE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).signupErrMsg,
        [OptionsProps.OPTIONS]:
          aboutMeMultilanguageLabels(language).houseLessOptions,
        [OptionsProps.OPTIONS_PER_ROW]: 2,
      },
      {
        [Props.NAME]: "childcasenumber",
        [Props.LABEL]: aboutMeMultilanguageLabels(language).childcaseNumber,
        [Props.TYPE]: Types.INPUT_NUMERIC_ONLY,
        [Props.REQUIRED]: TextProps.VALUE_FALSE,
        [Props.ERROR]: TextProps.VALUE_FALSE,
        [Props.ERROR_MESSAGE]:
          aboutMeMultilanguageLabels(language).childcaseNumberErrMsg,
        [Props.MAX_LENGTH]: 9,
      },
    ],
    completed: TextProps.VALUE_TRUE,
  },
];

export default sections;
