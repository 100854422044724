import React, { Component, Children } from "react";
import { enableUniqueIds } from "react-html-id";
import * as Props from "../../components/shared";
import Label from "./label";
import {
  addStringIf,
  isNone,
  hasHelpTip,
  validateAria,
} from "../../components/utilities/controls";
import { WrapperTypes, WrapperDefaults } from "../propTypes";
import { consumerConst } from "../../../pages/consumer/consumerText";
import * as helperFunction from "../helperFunctions/helperFunctions";
import * as TextProps from "../../../utils/constants/text";

var constantsLabels = require("../../constants/benefit");

const selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

/*
  Nongrouped
  ----------

  # Purpose: To render all of the markup supporting a specific question, including labels,
    any hidden or inline help text, and error messages in an accessible manner. This wrapper
    applies to questions that do NOT need to be rendered in a `fieldset` and therefore are NOT
    questions that are considered to be in a group.

    NOTE: this wrapper component passes the `id` value and the `aria-labelledby` value to
      the question component that is contained by this wrapper component.

  # Props: see the shared WrapperTypes in the `propTypes.js` file for an explanation of
    all of the properties that this wrapper accepts

  # Example: see the ControlWrapper component for examples of which question types
    will be rendered with this wrapper component.
 */

class Nongrouped extends Component {
  static propTypes = WrapperTypes;
  static defaultProps = WrapperDefaults;

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);
  }

  render() {
    const { className, questionClass, error, errorMessage, labelHidden } =
        this.props,
      thisClassName = addStringIf(
        labelHidden,
        addStringIf(questionClass, "dta-form__label"),
        "sr-only"
      ),
      labelObj = this.props[Props.LABEL],
      doesThisHaveHelpTip = hasHelpTip(labelObj),
      inlineHelp = this.props[Props.INLINE_HELP],
      inlineHelpHasMarkup = this.props[Props.INLINE_HELP_HAS_MARKUP],
      // ids for aria must have NO WHITESPACE or they are viewed as different
      thisId = this.nextUniqueId(),
      helpTipId = this.nextUniqueId(),
      inlineHelpId = this.nextUniqueId(),
      errorId = this.nextUniqueId(),
      ariaLabels = this._buildAriaIds(
        thisId,
        doesThisHaveHelpTip,
        helpTipId,
        !isNone(inlineHelp),
        inlineHelpId,
        error,
        errorId
      );
      const { children } = this.props;
      const { data } = children.props;
      const { addDeleteAndDone } = data;

    return (
      <div className={className}>

      {addDeleteAndDone && 
        <div className="remove-done-button-container">
          <div className="pure-u-12 pure-u-sm-4-24 pure-u-md-3-24 pure-u-lg-4-24 pure-u-xl-3-24" style={{textAlign:"right"}}>
              <button onClick={data.enabledClickAction} className="dta-button text--small dta-delete-button interim-recert-delete-button interim-mar-btn" id={data.clientId}>{consumerConst(selectedLanguage).remove}</button>
          </div>
          <div className="pure-u-12 pure-u-sm-4-24 pure-u-md-3-24 pure-u-lg-5-24 pure-u-xl-4-24" style={{textAlign:"right"}}>
              <button onClick={data.enabledDoneAction} className="dta-button text--small interim-mar-btn interim-recert-outline-button">{consumerConst(selectedLanguage).done}</button>
          </div>
        </div>
        }
        <Label
          data={labelObj}
          hasMarkup={this.props[Props.LABEL_HAS_MARKUP]}
          className={thisClassName}
          helpTipId={helpTipId}
          alwaysRenderLabel={TextProps.VALUE_TRUE}
          tagName="label"
          tagAttributes={{
            htmlFor: thisId,
            // want to hide always-rendered label if we have a help tip because the help tip
            // will duplicate the contents of the label. Otherwise, if the no help tip exists for
            // this question, then we want to fallback to the default styling for the label
            className: doesThisHaveHelpTip ? "sr-only" : thisClassName,
          }}
          required={this.props[Props.REQUIRED]}
        />
        {inlineHelp &&
          this._buildInlineHelp(
            inlineHelpId,
            inlineHelp,
            labelHidden,
            inlineHelpHasMarkup
          )}
        {error && this._buildError(errorId, errorMessage, labelHidden)}
        {Children.map(this.props.children, (child) => {
          return React.cloneElement(child, {
            id: thisId,
            "aria-labelledby": ariaLabels,
          });
        })}
      </div>
    );
  }

  //Conveting labels to multi lang
  convertingMultiLangLabels = (labelName) => {
    switch (labelName) {
      case constantsLabels.PHONEINLINETEXT:
        return consumerConst(selectedLanguage).phoneInLineHelp;
      case constantsLabels.EMAILINLINETEXT:
        return consumerConst(selectedLanguage).emailInLineHelp;
      default:
        return labelName;
    }
  };
  // Rendering
  // ---------

  _buildInlineHelp(
    inlineHelpId,
    inlineHelp,
    labelHidden,
    inlineHelpHasMarkup = TextProps.VALUE_FALSE
  ) {
    inlineHelp = this.convertingMultiLangLabels(inlineHelp);
    if (inlineHelpHasMarkup) {
      return (
        <span
          id={inlineHelpId}
          className={addStringIf(labelHidden, "dta-form__help-text", "sr-only")}
          dangerouslySetInnerHTML={{ __html: inlineHelp }}
        />
      );
    } else {
      return (
        <span id={inlineHelpId} className="dta-form__help-text">
          {inlineHelp}
        </span>
      );
    }
  }

  _buildError(errorId, errorMessage, labelHidden) {
    return (
      <span
        className={addStringIf(
          labelHidden,
          "dta-form__error-message",
          "sr-only"
        )}
        id={errorId}
      >
        {errorMessage}
      </span>
    );
  }

  // Helpers
  // -------

  _buildAriaIds(
    thisId,
    helpTip,
    helpTipId,
    hasInlineHelp,
    inlineHelpId,
    hasError,
    errorId
  ) {
    let ariaLabels = "";
    // condition build up aria labels
    if (helpTip) {
      ariaLabels += ` ${helpTipId}`;
    }
    if (hasInlineHelp) {
      ariaLabels += ` ${inlineHelpId}`;
    }
    if (hasError) {
      ariaLabels += ` ${errorId}`;
    }
    // validate aria labels, returns null if invalid
    ariaLabels = validateAria(ariaLabels);
    // appends thisId to the start of the string if is valid AND does not have help tip because
    // help tip will already duplicate the contents of the label
    if (ariaLabels && !hasHelpTip) {
      ariaLabels = `${thisId} ${ariaLabels}`;
    }
    return ariaLabels;
  }
}

export default Nongrouped;
