import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import dtaLogo from "../../utils/resources/images/alabama.png";
import LanguageSelect from "../../redux/language/languageSelect";
import { Link } from "react-router-dom";
import DTAFooter from "../../utils/components/dtaFooter";
import DTALoginModal from "../../utils/components/modal/dtaLoginModal";
import DTAInput from "../../utils/components/dtaInput";
import DTADate from "../../utils/components/dtaDate";
import {
  onRegister,
  resetSignupState,
} from "../../redux/user/consumerSignUpActions";
import PasswordValidator from "../../utils/components/passwordValidator";
import Icon from "../../utils/components/dtaIcon";
import * as routeProps from "../../utils/constants/routesConstants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import DTAModal from "../../utils/components/modal/dtaModal";
import * as TextProps from "../../utils/constants/text";

class ConsumerSignUp extends Component {
  constructor(props) {
    super(props);
    this.onSubmitValidation = this.onSubmitValidation.bind(this);
    this.onPasswordvalidatorChange = this.onPasswordvalidatorChange.bind(this);
    this.state = {
      isAutErrorMsg: "",
      signUpSpinner: TextProps.VALUE_FALSE,
      type: "password",
      emailError: TextProps.VALUE_FALSE,
      firstNameError: TextProps.VALUE_FALSE,
      lastNameError: TextProps.VALUE_FALSE,
      pswError: TextProps.VALUE_FALSE,
      dobError: TextProps.VALUE_FALSE,
      disableSubmitButton: TextProps.VALUE_TRUE,
      password: "",
      showWarningPopup: TextProps.VALUE_FALSE,
      errorMessage: TextProps.VALUE_TRUE,
    };
  }

  componentDidMount() {
    this.setState({
      signupSectionContents: TextProps.VALUE_TRUE,
      registrationSuccessful: TextProps.VALUE_FALSE,
      registrationErrorState: TextProps.VALUE_FALSE,
    });
    this.setState({ signUpSpinner: TextProps.VALUE_TRUE });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.consumer.registrationSuccessful === TextProps.VALUE_TRUE) {
      this.signupModal.hideBlur();
      this.setState({
        signupSectionContents: TextProps.VALUE_FALSE,
        registrationSuccessful: TextProps.VALUE_TRUE,
        registrationErrorState: TextProps.VALUE_FALSE,
      });
    } else if (
      nextProps.consumer.registrationErrorState === TextProps.VALUE_TRUE
    ) {
      this.setState({
        registrationErrorState: TextProps.VALUE_TRUE,
        registrationErrorMessage: nextProps.consumer.registrationErrorMessage,
        showWarningPopup: TextProps.VALUE_TRUE,
        hideCloseIcon: TextProps.VALUE_TRUE,
      });
      this.signupModal.hideBlur();
    }
  }

  onSubmitValidation = (event) => {
    const { register } = this.props.language.security;
    this.setState({ email: this.refs.regEmail.inputEl.value });
    this.setState({ firstName: this.refs.regFirstName.inputEl.value });
    this.setState({ lastName: this.refs.regLastName.inputEl.value });
    let registerDetails = {};

    registerDetails.email = this.state.email;
    registerDetails.password = this.state.password;
    registerDetails.firstname = this.state.firstName;
    registerDetails.lastname = this.state.lastName;
    registerDetails.dob = this.state.dob;
    var emailRegEx =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (
      !emailRegEx.test(registerDetails.email) ||
      registerDetails.email === undefined
    ) {
      this.setState({ emailError: TextProps.VALUE_TRUE });
      this.emailErrMSg = register.emailErrorMessage;
      event.preventDefault();
    } else {
      this.setState({ emailError: TextProps.VALUE_FALSE });
      this.emailErrMSg = "";
    }
    if (
      registerDetails.password === undefined ||
      registerDetails.password ===
        "" /* ||  registerDetails.password.length >5  */
    ) {
      this.setState({ pswError: TextProps.VALUE_TRUE });
      this.pswErrMSg = register.passwordErrorMessage;
      event.preventDefault();
    } else {
      this.pswErrMSg = "";
    }
    if (
      registerDetails.firstname === null ||
      registerDetails.firstname === undefined ||
      registerDetails.firstname === ""
    ) {
      this.setState({ firstNameError: TextProps.VALUE_TRUE });
      this.firstNameErrMSg = register.firstNameErrorMessage;
      event.preventDefault();
    } else {
      this.setState({ firstNameError: TextProps.VALUE_FALSE });
      this.firstNameErrMSg = "";
    }
    if (
      registerDetails.lastname === null ||
      registerDetails.lastname === undefined ||
      registerDetails.lastname === ""
    ) {
      this.setState({ lastNameError: TextProps.VALUE_TRUE });
      this.lastNameErrMSg = register.lastNameErrorMessage;
      event.preventDefault();
    } else {
      this.setState({ lastNameError: TextProps.VALUE_FALSE });
      this.lastNameErrMSg = "";
    }
    if (
      registerDetails.dob === null ||
      registerDetails.dob === undefined ||
      registerDetails.dob === ""
    ) {
      this.setState({ dobError: TextProps.VALUE_TRUE });
      this.dobErrMSg = register.dobErrorMessage;
      event.preventDefault();
    } else {
      this.setState({ dobError: TextProps.VALUE_FALSE });
      this.dobErrMSg = "";
    }
  };

  validateInputs(e) {
    e.preventDefault();
    //Validate
    this.onRegister();
  }

  onRegister() {
    const { signUpSpinner } = this.state;
    const { dobError } = this.state;
    if (!signUpSpinner || dobError === TextProps.VALUE_TRUE) {
      this.signupModal.hideBlur();
    } else {
      this.signupModal.showBlur("loading", "");
      let registerDetails = {};
      registerDetails.email = this.state.email;
      registerDetails.password = this.state.password;
      registerDetails.firstName = this.state.firstName;
      registerDetails.lastName = this.state.lastName;
      var stringDate = helperFunction.convertDOBObjectToString(this.state.dob);
      registerDetails.dateOfBirth = stringDate;
      this.props.onRegister(registerDetails);
    }
  }

  goToLogin() {
    this.props.resetSignupState();
    this.props.goToLogin();
  }

  showHidePass = (event) => {
    event.preventDefault();
    this.setState({
      type: this.state.type === "password" ? "text" : "password",
    });
  };
  onPasswordvalidatorChange(validatorData) {
    this.setState({
      disableSubmitButton: validatorData && validatorData.isInvalid,
      password: validatorData.value,
    });
  }
  onCloseModal = () => {
    window.location.assign(routeProps.HOME_PATH);
  };
  render() {
    const { register } = this.props.language.security;
    const { info } = this.props.language.consumer;

    const { home } = this.props.language;
    let signUpErrMsg = this.state.registrationErrorMessage;
    let errMsg = {
      firstName: "Please enter the valid First Name",
      lastName: "Please enter the valid Last Name",
      emailMsg: "Please enter the valid email address",
      unauthorized: "Unauthorized user",
      pswCriteria: "Must be at least 8 characters long",
      specialChars: "user names must be of the form",
      exitsUser: "Email address already exists",
      rolesErr: "Unable to set role",
      attributesErr: "Unable to set attribute",
      invalidInput: "Invalid input",
    };

    function containsString(errStr, data) {
      if (errStr.indexOf(data) >= 0) return TextProps.VALUE_TRUE;
      return TextProps.VALUE_FALSE;
    }

    return (
      <div className="home page-wrapper">
        <nav className="nav homepage__nav">
          <div className="navbar navbar--white">
            <div className="max-width">
              <Link to={routeProps.HOME_PATH} className="navbar__logo">
                <img src={dtaLogo} alt="ACES DHR Self-Service Portal home" />
              </Link>
              <h1 className="nav__title hidden-touch">ACES DHR Self-Service Portal</h1>
              <ul className="nav-list navbar__nav-list">
              <li className="nav-list__item1">Translate Page</li>
                <li className="nav-list__item">
                  <LanguageSelect />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <main className="main login-modal">
          <section className="homepage__hero-container">
            <div className="hero homepage__hero"></div>
          </section>
          <DTALoginModal
            ref={(instance) => (this.signupModal = instance)}
            isOpen={this.state.signupSectionContents}
            modalClass="signup-modal"
            titleText="SignUp"
          >
            <div className="dta-modal__body pad-all dtaRegisterModel">
              <div className="header-links">
                <button
                  className="dta-button--minor close-modal"
                  style={{ position: "absolute", right: "0px" }}
                  onClick={()=>{this.onCloseModal
                  this.clea
                  }}
                >
                  <Icon name="close" label="Close modal" />
                </button>
              </div>
              <div className="model-header">
                <h1>{register.signupbutton}</h1>
              </div>
              <form
                name="dtaregisterform"
                onSubmit={this.validateInputs.bind(this)}
              >
                <div className="pure-g">
                  <div className="pure-u-1">
                    <label className="dta-form__label">
                      {register.regEmailAddressLabel}
                    </label>
                    <div>
                      {this.state.emailError ? (
                        <span className="dta-form__error-message">
                          {" "}
                          {this.emailErrMSg}{" "}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <DTAInput
                      className="dta-form__control"
                      id="regEmail"
                      maxLength={350}
                      placeholder=""
                      ref="regEmail"
                      onChange={(val) => this.setState({ email: val })}
                      type="email"
                      required={TextProps.VALUE_TRUE}
                      error={this.emailErrMSg}
                      ariaLabel="regEmail"
                    />
                  </div>
                </div>
                <div className="pure-g">
                  <div className="pure-u-1">
                    <label className="dta-form__label">
                      {register.regPasswordLabel}

                      <PasswordValidator
                        onChange={this.onPasswordvalidatorChange}
                      >
                        {" "}
                      </PasswordValidator>
                    </label>
                  </div>
                </div>

                <div className="pure-g">
                  <div className="pure-u-1">
                    <label className="dta-form__label">
                      {register.regFirstNameLabel}
                    </label>
                    <div>
                      {this.state.firstNameError ? (
                        <span className="dta-form__error-message">
                          {" "}
                          {this.firstNameErrMSg}{" "}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <DTAInput
                      className="dta-form__control"
                      id="regFirstName"
                      maxLength={15}
                      placeholder=""
                      ref="regFirstName"
                      type="alpha-text"
                      onChange={(val) => this.setState({ firstName: val })}
                      required={TextProps.VALUE_TRUE}
                      error={this.firstNameErrMSg}
                      ariaLabel="regFirstName"
                    />
                  </div>
                </div>

                <div className="pure-g">
                  <div className="pure-u-1">
                    <label className="dta-form__label">
                      {register.regLastNameLabel}
                    </label>
                    <div>
                      {this.state.lastNameError ? (
                        <span className="dta-form__error-message">
                          {" "}
                          {this.lastNameErrMSg}{" "}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <DTAInput
                      className="dta-form__control"
                      id="regLastName"
                      maxLength={20}
                      placeholder=""
                      ref="regLastName"
                      type="alpha-text"
                      onChange={(val) => this.setState({ lastName: val })}
                      required={TextProps.VALUE_TRUE}
                      error={this.lastNameErrMSg}
                      ariaLabel="regLastName"
                    />
                  </div>
                </div>

                <div className="pure-g">
                  <div className="pure-u-1">
                    <label className="dta-form__label">
                      {register.regDOBLabel}
                    </label>
                    <div>
                      {this.state.dobError ? (
                        <span className="dta-form__error-message">
                          {" "}
                          {this.dobErrMSg}{" "}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <DTADate
                      {
                        ...this
                          .props /* For passing through value bindings and change handlers*/
                      }
                      name="regDob"
                      error={this.dobError}
                      required={TextProps.VALUE_TRUE}
                      onChange={(val) => this.setState({ dob: val })}
                      ref="regDOB"
                    />
                  </div>
                </div>

                <div className=" apply__footer pad-all">
                  <button
                    className="dta-button dta-button--outline-primary dta-button--large"
                    onClick={() => window.history.back()}
                  >
                    {register.regGoBackButton}
                  </button>
                  <button
                    className="dta-button dta-button--primary dta-button--large"
                    disabled={this.state.disableSubmitButton}
                    type="submit"
                    onClick={this.onSubmitValidation}
                  >
                    {register.regMainHeading}
                  </button>
                </div>
              </form>
            </div>
            {(() => {
              if (this.state.registrationErrorState === TextProps.VALUE_TRUE) {
                return (
                  <div className="pure-g">
                    <div className="pure-u-1 dta-form__error-message">
                      {signUpErrMsg === errMsg.firstName
                        ? register.errfirstName
                        : null}
                      {signUpErrMsg === errMsg.lastName
                        ? register.errlastName
                        : null}
                      {signUpErrMsg === errMsg.emailMsg
                        ? register.emailMsg
                        : null}
                      {signUpErrMsg === errMsg.unauthorized
                        ? register.unauthorized
                        : null}
                      {containsString(
                        this.state.registrationErrorMessage,
                        errMsg.pswCriteria
                      )
                        ? register.pswCriteria
                        : null}
                      {containsString(
                        this.state.registrationErrorMessage,
                        errMsg.specialChars
                      )
                        ? register.invalidEmail
                        : null}
                      {containsString(
                        this.state.registrationErrorMessage,
                        errMsg.invalidInput
                      )
                        ? register.dobErrorMessage
                        : null}
                      {containsString(
                        this.state.registrationErrorMessage,
                        errMsg.exitsUser
                      )
                        ? register.userNotExists
                        : null}
                      {containsString(
                        this.state.registrationErrorMessage,
                        errMsg.rolesErr
                      )
                        ? register.roleErr
                        : null}
                      {containsString(
                        this.state.registrationErrorMessage,
                        errMsg.attributesErr
                      )
                        ? register.attributeErr
                        : null}
                    </div>
                  </div>
                );
              }
            })()}
          </DTALoginModal>
          <DTALoginModal
            isOpen={this.state.registrationSuccessful}
            modalClass="signup-modal"
            titleText="SignUp"
          >
            <div className="dta-modal__body pad-all dtaRegisterModel">
              <div className="model-header">
                <h1>{register.signupbutton}</h1>
              </div>
              <p>{register.regSuccess}</p>
              <button
                className="dta-button dta-button--primary dta-button--large dta-login"
                onClick={this.goToLogin.bind(this)}
              >
                {register.login}
              </button>
            </div>
          </DTALoginModal>
          <DTAModal
            isOpen={this.state.registrationErrorState}
            onClose={this.onCloseModal}
            modalClass="save-progress-modal"
            titleText={register.info}
            headerText={register.info}
          >
            <div className="dta-modal__body pad-all">
              <p>{register.registerWarnText}</p>
              <p>&nbsp;</p>
              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="apply__footer pad-all--double"
              >
                <button
                  align="center"
                  className="dta-button dta-button--outline-primary"
                  onClick={() => {
                    this.onCloseModal();
                  }}
                >
                  {info.ok}
                </button>
                &nbsp;&nbsp;&nbsp;
              </div>
            </div>
          </DTAModal>
        </main>
        {<DTAFooter language={home} />}
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onRegister: onRegister,
      resetSignupState: resetSignupState,
      goToLogin: () => push(routeProps.HOME_PATH),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerSignUp);
