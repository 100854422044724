import React, { Component } from "react";
import PropTypes from "prop-types";

import DTAMarker from "./dtaMarker";
import WrappedMap from "./wrappedMap";
import { addStringIf } from "../utilities/controls";
import { componentOfType } from "../utilities/proptypes";
import { consumerConst } from "../../../pages/consumer/consumerText";
let selectedLanguage = localStorage.getItem("selectedLanguage");

if (selectedLanguage === null) {
  selectedLanguage = "en-US";
}

/*
  DTAMap
  ------

  # Purpose:
  A non-accessible map that users can pass `DTAMarkes` into as children to populate the map.
  For an accessible map, use `MapWrapper`

  # Props:
  apiKey: Required, string api key to pass as query parameter with requests
  className: Optional, string classes for the top-level element
  containerClass: Optional, string classes for the map container element
  loadingClass: Optional, string classes for the loading element
  mapClass: Optional, string classes for the map element itself
  loadingLabel: Optional, string label to display when the map is loading
  apiURL: Optional, string url base to append api key url parameter to
  apiURLKeyParameter: Optional, string url query parameter name for the api key
  containerProps: Optional, object with additional attributes to rener onto the map container element
  children: Optional, zero or nore `DTAMarkers to render on the map`

  # Example:
  <DTAMap
    {...otherProps}
    mapRef={el => (this._map = el)}
    containerProps={{ 'aria-hidden': TextProps.VALUE_TRUE, role: 'presentation' }}
  >
    <DTAMarker key={id} {...props}>
      <div>
        <span className="display-block text text--bold margin-top-half margin-bottom-half">
          {name}
        </span>
        <span className="display-block">
          {address1}
          <br />
          {address2}
        </span>
      </div>
    </DTAMarker>
    {children}
  </DTAMap>
 */

class DTAMap extends Component {
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    containerClass: PropTypes.string,
    loadingClass: PropTypes.string,
    mapClass: PropTypes.string,
    loadingLabel: PropTypes.string,
    apiURL: PropTypes.string,
    apiURLKeyParameter: PropTypes.string,
    containerProps: PropTypes.object,
    children: componentOfType(DTAMarker),
  };

  static defaultProps = {
    className: "",
    containerClass: "",
    loadingClass: "",
    mapClass: "",
    loadingLabel: consumerConst(selectedLanguage).loadingLabel,
    apiURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
    apiURLKeyParameter: "key",
    containerProps: {},
  };

  render() {
    const {
      className,
      containerClass,
      loadingClass,
      mapClass,
      loadingLabel,
      apiKey,
      apiURL,
      apiURLKeyParameter,
      containerProps,
      ...otherProps
    } = this.props;
    return (
      <div className={addStringIf(className, "dta-map")} {...containerProps}>
        <WrappedMap
          {...otherProps}
          googleMapURL={`${apiURL}&${apiURLKeyParameter}=${apiKey}`}
          loadingElement={this._buildLoading(
            containerClass,
            loadingClass,
            loadingLabel
          )}
          containerElement={this._buildContainer(containerClass)}
          mapElement={this._buildMap(mapClass)}
        />
      </div>
    );
  }

  // Rendering
  // ---------

  _buildContainer(containerClass) {
    return (
      <div className={addStringIf(containerClass, "dta-map__container")} />
    );
  }

  _buildLoading(containerClass, loadingClass, loadingLabel) {
    return (
      <div
        className={addStringIf(
          containerClass,
          "dta-map__container dta-map__container--loading"
        )}
      >
        <span className={addStringIf(loadingClass, "dta-map__label")}>
          {loadingLabel}
        </span>
      </div>
    );
  }

  _buildMap(mapClass) {
    return <div className={addStringIf(mapClass, "dta-map__map")} />;
  }
}

export default DTAMap;
