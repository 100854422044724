import axios from "axios";
import { sections } from "../../pages/greeterScreening/greeterScreeningMapData";
import { getAudit } from "../../utils/constants/audit";
var TigerURL = require("../../utils/constants/appConfigConstants").TigerURL;

const actionTypes = {
  applyGreeterPagesAction: "APPLY_GREETER_PAGES_FULLFILLED",
  serviceFailedAction: "GREETER_NETWORK_FAILED_LOAD_DATA",
  postApplyData: "POST_APPLY_GREETER_INFO_DATA",
  fillInputValuesAction: "FILL_GREETER_INPUT_VALUES_STATE",
  applySnapSectionData: "APPLY_GREETER_SNAP_SECTIONS_FULLFILLED",
};

export function applyGreeterScreeningQuestionsSections() {
  return (dispatch) => {
    return dispatch(applyGreeterSections(sections));
  };
}
function applyGreeterSections(sectionsVal) {
  return {
    type: actionTypes.applyGreeterPagesAction,
    payload: sectionsVal,
  };
}

export function updateCurrentSectionData(applyUserSectionData) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(applyUserSectionData));
  };
}

function questionsInputValuesAction(sectionsValue) {
  return {
    type: actionTypes.fillInputValuesAction,
    payload: sectionsValue,
  };
}

export function saveGreeterScreen(application, language) {
  const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
  const audit = getAudit();
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = TigerURL + "/greeterscreening";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          locale: language,
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
      };
      axios
        .post(url, application, options)
        .then(function (data) {
          dispatch(saveApplyData(data));
          resolve(data);
        })
        .catch(function (error) {
          dispatch(serviceFailedAction(error));
          reject(error);
        });
    });
  };
}

function saveApplyData(data) {
  return {
    type: actionTypes.postApplyData,
    payload: data,
  };
}

function serviceFailedAction(error) {
  return {
    type: actionTypes.serviceFailedAction,
    payload: error,
  };
}
