import React from "react";
import * as routeProps from "../../constants/routesConstants";
import * as consts from "../../constants/types";
import Loading from "../loading";
import { isNone } from "../utilities/controls";
import { MONTHSHORT } from "../../constants/controls";
import { animateScroll } from "react-scroll";
import * as TextProps from "../../constants/text";
import { getCookie } from "../../helper/cookie";

export const redirectToHome = () => {
  window.location.assign(routeProps.HOME_PATH);
};

export const isLoading = () => {
  return <Loading />;
};

export const selectedLanguageFromLocalStorage = (selectedLanguage) => {
  let language = localStorage.getItem(selectedLanguage);
  language === null
    ? getCookie(selectedLanguage)
      ? localStorage.setItem(selectedLanguage, getCookie(selectedLanguage))
      : localStorage.setItem(selectedLanguage, consts.ENGLISH)
    : language;
  language =
    language === null
      ? getCookie(selectedLanguage)
        ? getCookie(selectedLanguage)
        : consts.ENGLISH
      : language;
  return language;
};

export const convertBirthDate = (date) => {
  date = new Date(date);
  var mm = date.getMonth() + 1;
  var dd = date.getDate();
  return [
    date.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
  ].join("-");
};

export const convertDOBObjectToString = (str) => {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

export const _calculateAge = (dateOfBirth) => {
  var ageDifMs = Date.now() - dateOfBirth.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const _convertMonthNumberToString = (monthNumber) => {
  return isNone(MONTHSHORT[monthNumber])
    ? MONTHSHORT[0]
    : MONTHSHORT[monthNumber];
};

export const _convertDateToState = (date) => {
  if (isNone(date)) {
    return "";
  }
  if (typeof date === "string") {
    date = new Date(date);
  }
  let formatDate="";
  if(date.getMonth() < 9) {
    formatDate = (formatDate + "0"+ (date.getMonth()+1)) +"/";
  }else{
    formatDate = (formatDate +(date.getMonth()+1)) +"/"
  }
  if(date.getDate() < 10) {
    formatDate = (formatDate + "0"+date.getDate()) +"/";
  }else{
    formatDate = (formatDate + date.getDate()) +"/"
  }
  formatDate = formatDate + date.getFullYear();
  return formatDate; 
};

export const _animatedScroll = () => {
  return animateScroll.scrollToTop({
    containerId: "content",
    duration: 500, // Should be roughly the same as CSS transition time
    smooth: TextProps.VALUE_TRUE,
  });
};

export const removeSpace = (str) => {
  if (/\s/g.test(str)) {
    str = str.replace(/ /g, "");
  }
  return str;
};
export function camelCase(str) {
  // Using replace method with regEx
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}