import React from "react";
import _ from "lodash";

import ControlWrapper from "../controlWrappers/controlWrapper";

import * as HeadingProps from "../../constants/heading";
import * as OptionsProps from "../../constants/options";
import * as Props from "../../components/shared";
import * as TextProps from "../../constants/text";
import * as Types from "../../constants/types";

// When passed a type, it will generate markup as appropriate to the type. This function
// can handle any type, both question types and non-question types. This function is
// meant to embody the principles of how to resolve questions and the question data into
// JSX markup, but it also has logic for the sandbox-specific display of conditional questions

/**
 * [resolve description]
 * @param  {object} questionData                 data object containing the question data
 * @param  {function} obj1.doRetrieve            Required, passed question data object and conditional
 *                                               object (if any) and returns the current value
 *                                               for the current question
 * @param  {function} obj1.onChange              Required, passed question data object, conditional
 *                                               object (if any), and the new value and returns nothing
 * @param  {function} obj1.doGetUniqueId         Required, passed name of the question (if any) and
 *                                               returns the unique key for the resulting react node
 * @param  {function} obj2.doBuildContainerProps Optional, passed question data object and conditional
 *                                               object (if any) and returns an object of additional
 *                                               attributes to render onto the container around the question
 * @param  {function} obj2.doBuildControlProps   Optional, passed question data object and conditional
 *                                               object (if any) and returns an object of additional
 *                                               attributes to render onto the question itself
 * @return {node}                                Renderable node
 */
export function resolve(
  questionData,
  { doRetrieve, onChange, doGetUniqueId },
  { doBuildContainerProps, doBuildControlProps } = {}
) {
  return _recursiveResolve(
    questionData,
    doRetrieve,
    onChange,
    doGetUniqueId,
    doBuildContainerProps,
    doBuildControlProps
  );
}

function _recursiveResolve(
  questionData,
  doRetrieve,
  onChange,
  doGetUniqueId,
  doBuildContainerProps = undefined,
  doBuildControlProps = undefined,
  conditionObj = undefined
) {
  const type = questionData[Props.TYPE];
  if (type === Types.TEXT) {
    if(questionData['isCustomHTML']){
    return (
        <div key={doGetUniqueId()} className="dta-form__label" dangerouslySetInnerHTML={{__html: questionData[TextProps.VALUE]}}/>
      );
    }else{
    return (
      <p key={doGetUniqueId()} className="dta-form__label" >
        {questionData[TextProps.VALUE]}
      </p>
    );
    }

  } else if (type === Types.WARNING_TEXT) {
    return (
      <p key={doGetUniqueId()} className="dta-form__error-message">
        {questionData[TextProps.VALUE]}
      </p>
    );
  } else if (type === Types.HTML_TEXT) {
    return (
      <p
        dangerouslySetInnerHTML={{ __html: questionData[TextProps.VALUE] }}
        key={doGetUniqueId()}
        className="dta-form__label"
      ></p>
    );
  } else if (type === Types.DIVIDER) {
    return <hr key={doGetUniqueId()} className="divider" />;
  } else if (type === Types.HEADING) {
    switch (questionData[HeadingProps.SIZE]) {
      case HeadingProps.SIZE_LARGE:
        return (
          <h2 key={doGetUniqueId()}>{questionData[HeadingProps.VALUE]}</h2>
        );
      case HeadingProps.SIZE_MEDIUM:
        return (
          <h3 key={doGetUniqueId()}>{questionData[HeadingProps.VALUE]}</h3>
        );
      default:
        return (
          <h4 key={doGetUniqueId()}>{questionData[HeadingProps.VALUE]}</h4>
        );
    }
  } else {
    return _resolveQuestion(
      questionData,
      doRetrieve,
      onChange,
      doGetUniqueId,
      doBuildContainerProps,
      doBuildControlProps,
      conditionObj
    );
  }
}

// Helper function to handle rendering all question types, as well as the sandox-specific
// functionality of binding state and handlers to update state for the conditional
// display of questions based on previous answers. This function provides an example of how
// to do data-binding with the ControlWrapper component
function _resolveQuestion(
  questionData,
  doRetrieve,
  onChange,
  doGetUniqueId,
  doBuildContainerProps = undefined,
  doBuildControlProps = undefined,
  conditionObj = undefined
) {
  const recursiveConditionals = [],
    type = questionData[Props.TYPE],
    thisValue = doRetrieve(questionData, conditionObj);

  // loop through each conditional block to see if any questions to render
  if (
    (type === Types.CHECKBOX || type === Types.RADIO) &&
    questionData[OptionsProps.CONDITIONALS] &&
    thisValue !== undefined
  ) {
    questionData[OptionsProps.CONDITIONALS].forEach((conditionalObj2) => {
      const target = conditionalObj2[OptionsProps.CONDITIONAL_TARGET_VALUE];
      if (
        _.isArray(thisValue) ? thisValue.includes(target) : thisValue === target
      ) {
        recursiveConditionals.push(
          ...conditionalObj2[OptionsProps.CONDITIONAL_QUESTIONS].map(
            (questionData2) => {
              return _recursiveResolve(
                questionData2,
                doRetrieve,
                onChange,
                doGetUniqueId,
                doBuildContainerProps,
                doBuildControlProps,
                conditionalObj2
              );
            }
          )
        );
      }
    });
  }

  // build the question via the `ControlWrapper`
  const additionalContainerProps = doBuildContainerProps
      ? _.attempt(doBuildContainerProps, questionData, conditionObj)
      : {},
    additionalControlProps = doBuildControlProps
      ? _.attempt(doBuildControlProps, questionData, conditionObj)
      : {};
  return (
    <div
      {...(_.isObject(additionalContainerProps)
        ? additionalContainerProps
        : {})}
      key={doGetUniqueId(questionData[Props.NAME])}
    >
      <ControlWrapper
        {...(_.isObject(additionalControlProps) ? additionalControlProps : {})}
        value={thisValue}
        onChange={(val, optionalMetaData = {}) =>
          onChange(questionData, conditionObj, val, optionalMetaData)
        }
        type={type}
        data={questionData}
        error={questionData.error}
        errorMessage={questionData.errorMessage}
      />
      {recursiveConditionals}
    </div>
  );
}
