import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import Radio from "../../../utils/components/radio";
import HelpTip from "../../../utils/components/helpTip";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";

import {
  clearMemberUIIncome,
  appendUIIncomeDetails,
  setDataChangeStatus,
  setShelterUtilityChangeStatus
} from "../../../redux/recertInterimReport/recertInterimReportActions";

import { interimReportPageLabels } from "../recertInterimReportText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import { ENGLISH } from "../../../utils/constants/types";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  if (language === null || language === languageConstants.langOptSel) {
    language = ENGLISH;
  }



  export const getShelterExpenseFrequency = (frequencyCode) => {
    let shelterExpenseFrequency;
    switch (frequencyCode) {
      case 'Week':
      case'WEEK':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).week;
        return shelterExpenseFrequency;
      case 'Month':
      case 'MONTH':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).month;
        return shelterExpenseFrequency;
      case 'Year':
      case 'ANNUAL':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).year;
        return shelterExpenseFrequency;
      case 'BIWEEK':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).biweek;
        return shelterExpenseFrequency;
      case 'SEMANL':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).semanl;
        return shelterExpenseFrequency;
      case 'ONETME':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).onetime;
        return shelterExpenseFrequency;
      case 'BIMON':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).bimonthly;
        return shelterExpenseFrequency;
      case 'SEMMON':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).semimonthly;
        return shelterExpenseFrequency;
      case 'Monthly':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).monthly;
        return shelterExpenseFrequency;
      default: break;
    }
  }

  class ShelterExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shelterUtilitySectionTemplate: null,
      unearnedIncomeSummaryTemplate: null,
      respData: null,
      shelterUIValuesPopulated: TextProps.VALUE_FALSE,
      shelterUtilitySubSectionCleared: TextProps.VALUE_FALSE,
      shelterUtilityConfirmationChanged: TextProps.VALUE_FALSE,
      shelterSummaryEdit: TextProps.VALUE_FALSE,
      reportedChanges: TextProps.VALUE_FALSE,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      sections: [],
      renderedSections: [],
      houseMemberLabels: [],
    };
    this.houseHoldHead = "";
    enableUniqueIds(this);
  }

  //Life cycle methods
  componentWillReceiveProps() {
    this.setState(
      {
        sections: this.props.interimReport.sections,
        respData: this.props.interimReport.responseObject,
      },
      () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(this.props.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );
    if (this.props.interimReport.setOldUIncomeData === TextProps.VALUE_TRUE) {
      this.onUnearnedEarnedIncomeChanged(
        interimReportPageLabels(language, languageConstants.aboutMe)
          .optionYesNo[1]
      );
    }
  }

  // Toggle whether adding new income members is open on state
  toggleAddUnearnedIncomeMembers = () => {
    this.setState({
      addUnearnedMembersOpen: !this.state.addUnearnedMembersOpen,
    });
  };

  componentDidMount() {
    this.setInitialData();
  }


  setInitialData = () => {
    let utilityAnswers = [];

    if (this.props.shelterResponseData && this.props.shelterResponseData.utilityExpense) {
      let utilityOldAnswers = this.props.shelterResponseData.utilityExpense;
      for (let key in utilityOldAnswers) {
        if (utilityOldAnswers.hasOwnProperty(key)) {
          let val = utilityOldAnswers[key];
          if (val) {
            utilityAnswers.push(key);
          }
        }
      }
    }

    this.props.setShelterData({
      householdMembers: _.cloneDeep(
        this.props.shelterResponseData.householdMembers
      ),
      shelterData: _.cloneDeep(this.props.shelterResponseData.shelterExpense),
      utilityData: _.cloneDeep(utilityAnswers),
    });
  };

  onShelterConfirmationChanged = (value) => {
    if (
      value ===
      interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1]
    ) {
      this.setState(
        {
          shelterUIValuesPopulated: TextProps.VALUE_FALSE,
          shelterUtilitySubSectionCleared: TextProps.VALUE_FALSE,
          shelterUtilityConfirmationChanged: !this.state.shelterUtilityConfirmationChanged,
        },
        () => {
          // this.onUnearnedIncomeSubSectionRetain();
          this.props.setShelterUtilityChangeStatus(TextProps.VALUE_FALSE);
          this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 6);
        }
      );
    } else {
      this.setState(
        {
          shelterUtilityConfirmationChanged: !this.state.shelterUtilityConfirmationChanged,
        },
        () => {
          this.props.setShelterUtilityChangeStatus(TextProps.VALUE_TRUE);
          this.props.setDataChangeStatus(TextProps.VALUE_TRUE, 6);
        }
      );
    }
  };

  getShelterLabel = (type) => {
    let shelterExpenseLabel;
    switch (type) {
      case 'RENT':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).rent;
        return shelterExpenseLabel;
      case 'MTGPRN':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).mortgage;
        return shelterExpenseLabel;
      case 'PRPTAX':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).propertyTaxes;
        return shelterExpenseLabel;
      case 'HOMEIN':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).homeInsurance;
        return shelterExpenseLabel;
      case 'CONFEE':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).condoFee;
        return shelterExpenseLabel;
      case 'PRPINS':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).propertyInsurance;
        return shelterExpenseLabel;
      case 'SECMTG':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).secondMortage;
        return shelterExpenseLabel;
      case 'HOMEXP':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).homelessExpenses;
        return shelterExpenseLabel;
      case 'HOMEEQ':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).homeEquityLoan;
        return shelterExpenseLabel;
      case 'HOASFE':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).homeOwnerAssociationFees;
        return shelterExpenseLabel;
      case 'OTHER':
        shelterExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).other;
        return shelterExpenseLabel;
      default:
        shelterExpenseLabel = 'None';
        return shelterExpenseLabel;
    }
  }
 
  getutilityLabel = (type) => {
    let utilityExpenseLabel;
    switch (type) {
      case 'heatFlag':
        utilityExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).heatOil;
        return utilityExpenseLabel;
      case 'acFlag':
        utilityExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).electricityairConditioner;
        return utilityExpenseLabel;
      case 'electricOrGasFlag':
        utilityExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).electricityGas;
        return utilityExpenseLabel;
      case 'phoneFlag':
        utilityExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).cellPhoneService;
        return utilityExpenseLabel;
      case 'noUtilityFlag':
        utilityExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).none;
        return utilityExpenseLabel;
      case 'payWaterSewage':
        utilityExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).waterAndSewage;
        return utilityExpenseLabel;
      case 'payGarbage':
        utilityExpenseLabel = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).garbage;
        return utilityExpenseLabel;
      case 'liheapFlag':
          utilityExpenseLabel = interimReportPageLabels(
            language,
            languageConstants.shelterAndutility
          ).energyAssistance;
        return utilityExpenseLabel;
      default:
        utilityExpenseLabel = '';
        return utilityExpenseLabel;
    }
  }


  renderHouseHoldHeadSummary = () =>{
    let HOH=[];
    let summary={};
    if(this.props.interimReport.householdMembers && this.props.interimReport.householdMembers.membersList){
      HOH = this.props.interimReport.householdMembers.membersList.filter((mem)=>{
        return mem.granteeFlag
    })
    summary = HOH[0]
    }

    return (
       <p>
      { interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).noShelterIncomeText
       }
       </p>
    );
  }

  // this.getUtilityExpense(utilityItem)
  renderShelterUtilitySummary = (shelterSummary) => {
    let summary = shelterSummary && shelterSummary.shelterExpense && shelterSummary.shelterExpense[0].section;
    let utilitySummary = shelterSummary && shelterSummary.utilityExpenses && shelterSummary.utilityExpenses.filter((exp)=>exp.type !== "liheapFlag");
    return ( summary &&
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}>{this.houseHoldHead + "'s"}</p>

        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >

        </p>
        {this.props.interimReport.shelterUtilityConfirmationChanged &&
          this.props.interimReport.shelterUtilityConfirmationChanged ===
          TextProps.VALUE_TRUE || 
          ((this.props.interimReport.shelterUtilityConfirmationChanged &&
            this.props.interimReport.shelterUtilityConfirmationChanged ===
            TextProps.VALUE_TRUE && this.props.interimReport.additionalProperties && this.props.interimReport.additionalProperties.shelterChanged))? (
          <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => this.shelterSummaryEditClick(summary)}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).editButtonLabel
            }
          </button>
        ) : null}

        <p className="interim-recert-heading apply__body-header help-text-fix" style={{ marginTop: "10px" }}>
          <HelpTip
            triggerText={
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).shelterExpensesLabel
            }
            // textAfter={
            //   this.props.getIncomeSummaryTitle(
            //     summary.firstName,
            //     interimReportPageLabels(
            //       language,
            //       languageConstants.beforeYouStart
            //     ).sUIncomebefore,
            //     interimReportPageLabels(
            //       language,
            //       languageConstants.beforeYouStart
            //     ).sUIncomeAfter
            //   ).after
            // }
            helpText={""}
            // helpText={
            //   interimReportPageLabels(
            //     language,
            //     languageConstants.beforeYouStart
            //   ).sUIncomeHelp
            // }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        <ul>
          {this.props.shelterSummary && this.props.shelterSummary.shelterExpense && this.props.shelterSummary.shelterExpense[0].incomes.map((shelterSumm)=>{
              if(shelterSumm.Answer)
              return shelterSumm.Answer.map((oneAmount)=>{
                let label = "";
                if (oneAmount.code !== undefined) {
                  if (oneAmount.code === "new" || oneAmount.code === "NEW") {
                    label = "newLabel";
                  } else if (oneAmount.code === "changed" || oneAmount.code === "CHANGE") {
                    label = "changedLabel";
                  } else if (oneAmount.code === "removed" || oneAmount.code === "REMOVE") {
                    label = "removedLabel";
                  }
                }
                if(oneAmount.amount && oneAmount.unit){
                  return <li key={Math.random()}>
                <span className="pure-u-12-24">
                  {this.getShelterLabel(shelterSumm.typeCode)}
                </span>
                {label !== "" ?
                  <DataChangeIndicator
                    text={
                      interimReportPageLabels(
                        language,
                        languageConstants.householdStatic,
                        null,
                        null,
                        null
                      )[label]
                    }
                  /> : null
                }
                <div  style={{ paddingTop: "10px" }}>
                  <span className="pure-u-12-24">
                    { getShelterExpenseFrequency(oneAmount.unit) + ' ' +'Amount'}
                  </span>
                  <span className="pure-u-12-24">
                    {"$" + " " + oneAmount.amount}
                  </span>
                </div>
              </li>
                }
              })
          })}
        </ul>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        />
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        />
        <p className="interim-recert-heading apply__body-header help-text-fix" style={{ marginTop: "10px" }}>
          <HelpTip
            triggerText={
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic
              ).utilityExpensesLabel
            }
            // textAfter={
            //   this.props.getIncomeSummaryTitle(
            //     summary.firstName,
            //     interimReportPageLabels(
            //       language,
            //       languageConstants.beforeYouStart
            //     ).sUIncomebefore,
            //     interimReportPageLabels(
            //       language,
            //       languageConstants.beforeYouStart
            //     ).sUIncomeAfter
            //   ).after
            // }
            helpText={""}
            // helpText={
            //   interimReportPageLabels(
            //     language,
            //     languageConstants.beforeYouStart
            //   ).sUIncomeHelp
            // }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        
        <ul>
          {utilitySummary && utilitySummary.map((utilityItem) => {
            // let removedUtil = this.props.interimReport.responseObject.additionalProperties.removedUtility ? this.props.interimReport.responseObject.additionalProperties.removedUtility : [];
            // let newUtility = this.props.interimReport.responseObject.additionalProperties.newUtility ? this.props.interimReport.responseObject.additionalProperties.newUtility : [];
             let label = "";
             if (utilityItem.code !== undefined && utilityItem.type !== "dataChangeCode") {
               if (utilityItem.code === "new" || utilityItem.code === "NEW") {
                 label = "newLabel";
               } else if (utilityItem.code === "removed" || utilityItem.code === "REMOVE") {
                 label = "removedLabel";
               }
            return (
              <li key={Math.random()}>
                <span className="pure-u-12-24">                 
                  {this.getutilityLabel(utilityItem.type)}
                </span>
                {label !== "" ?
                  <DataChangeIndicator
                    text={
                      interimReportPageLabels(
                        language,
                        languageConstants.householdStatic,
                        null,
                        null,
                        null
                      )[label]
                    }
                  /> : null
                }
              </li>
            );
             }
          })}
        </ul>

      </div> 
    );
  };

  //Edit Button in Income Summary
  shelterSummaryEditClick = (section) => {
    this.setState({ shelterSummaryEdit: TextProps.VALUE_TRUE });
    this.props.onSectionChange(section);
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  returnShelterExpenseConfirmationLabel() {
    if (
      ( this.props.interimReport.shelterUtilityConfirmationChanged === TextProps.VALUE_TRUE || this.props.interimReport.additionalProperties && this.props.interimReport.additionalProperties.shelterChanged === TextProps.VALUE_TRUE)
    ) {
      return interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0];
    } else {
      if (
        (this.props.interimReport.shelterUtilityConfirmationChanged === TextProps.VALUE_FALSE || this.props.interimReport.additionalProperties && this.props.interimReport.additionalProperties.shelterChanged === TextProps.VALUE_FALSE)
      ) {
        return interimReportPageLabels(language, languageConstants.aboutMe)
          .optionYesNo[1];
      } else {
        return null;
      }
    }
  }

  render() {
    let head = [];
    if (this.props.interimReport.shelterUtilityData) {
      this.props.interimReport.shelterUtilityData.householdMembers.forEach((member) => {
        if (member.granteeFlag === TextProps.VALUE_TRUE) {
          head.push(member);
        }
      });
    }
    if (head && head.length > 0) {
      this.houseHoldHead = head[0].firstName + " " + head[0].lastName + " " + head[0].dateOfBirth
    }
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {this.renderShelterUtilitySummary(this.props.shelterSummary)}
        <span>
          {
            interimReportPageLabels(language, languageConstants.beforeYouStart)
              .shelterExpense_ChangeQuestion
          }
        </span>
        <br />
        <br />
        <div className="pure-u-1-1">
          <div>
            <fieldset className="dta-form__group">
              {this.props.interimReport.sections[6].selectionMade ===
                TextProps.VALUE_FALSE && !this.props.interimReport.sections[6].completed && (
                  <span className="dta-form__error-message">
                    {
                      interimReportPageLabels(
                        language,
                        languageConstants.contactInfo
                      ).requiredErrorMessage
                    }
                  </span>
                )}
              <Radio
                name="shelter-expense-changed"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={
                  interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo
                }
                required={TextProps.VALUE_TRUE}
                value={this.returnShelterExpenseConfirmationLabel()}
                onChange={(value) => this.onShelterConfirmationChanged(value)}
              />
            </fieldset>
          </div>
          <div>
            <p>
              {
                interimReportPageLabels(language, languageConstants.beforeYouStart)
                  .shelterExpenseSubText
              }
            </p>
          </div>
        </div>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearMemberUIIncome: clearMemberUIIncome,
      appendUIIncomeDetails: appendUIIncomeDetails,
      setDataChangeStatus: setDataChangeStatus,
      setShelterUtilityChangeStatus: setShelterUtilityChangeStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShelterExpenses);
