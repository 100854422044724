// Vendor / Components
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import moment from "moment";

// Constants / Formatters
import * as SharedProps from "../../../utils/constants/shared";
import * as formatters from "../../../utils/components/utilities/formatters";
import * as roleProps from "../../../utils/constants/rolesConstants";
import * as TextProps from "../../../utils/constants/text";

// DHR Components
import Icon from "../../../utils/components/dtaIcon";
import DTAModal from "../../../utils/components/modal/dtaModal";
import Checkbox from "../../../utils/components/checkbox";
import DTADatePicker from "../../../utils/components/dtaDatePicker";

class RequestAccountAccessModal extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.state = {
      submitToDTA: TextProps.VALUE_FALSE,
      showCheckError: TextProps.VALUE_FALSE,
      isCheckRequired: TextProps.VALUE_FALSE,
      con: {
        conWeb: TextProps.VALUE_FALSE,
        isConCheckRequired: TextProps.VALUE_TRUE,
      },
      path: {
        pathWeb: TextProps.VALUE_FALSE,
        isPathCheckRequired: TextProps.VALUE_TRUE,
      },
      IsDateCheckRequired: TextProps.VALUE_TRUE,
      showDateCheckError: TextProps.VALUE_FALSE,
      beforeMsg: TextProps.VALUE_FALSE,
      startDate: TextProps.VALUE_FALSE,
      afterMsg: TextProps.VALUE_FALSE,
      endDate: TextProps.VALUE_FALSE,
      dateErrorMsg: TextProps.VALUE_FALSE,
      startDateISOFormat: "",
      endDateISOFormat: "",
    };
    this.acceptTermsConditions = this.acceptTermsConditions.bind(this);
    this.acceptConWeb = this.acceptConWeb.bind(this);
    this.acceptPathWeb = this.acceptPathWeb.bind(this);
    this._handleDateChangeForFrom = this._handleDateChangeForFrom.bind(this);
    this._datesMessage = this._datesMessage.bind(this);
  }
  static propTypes = {
    requestAccessForUser: PropTypes.shape({
      [SharedProps.FIRST_NAME]: PropTypes.string.isRequired,
      [SharedProps.LAST_NAME]: PropTypes.string.isRequired,
      [SharedProps.DOB]: PropTypes.instanceOf(Date).isRequired,
      [SharedProps.ID]: PropTypes.number.isRequired,
      [SharedProps.WEBAPPID]: PropTypes.number.isRequired,
    }).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    filesToUpload: PropTypes.arrayOf(PropTypes.instanceOf(File)).isRequired,
    onSelectFile: PropTypes.func.isRequired,
    onRemoveFile: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    modalClass: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
  };

  onSubmit = () => {
    const loggedInUser = JSON.parse(sessionStorage.getItem("userInfo"));
    var webApplicationArray = [];
    if (
      loggedInUser.dualProviderFlag !== undefined &&
      loggedInUser.dualProviderFlag == "N"
    ) {
      webApplicationArray.push({ appCode: "CONWEB" });
    } else {
      if (this.state.con.conWeb) {
        webApplicationArray.push({ appCode: "CONWEB" });
      }
      if (this.state.path.pathWeb) {
        webApplicationArray.push({ appCode: "PATH" });
      }
    }
    
    let clientId = this.props.requestAccessForUser[SharedProps.CLIENT_ID];
    if (clientId === undefined || clientId === null) {
      clientId = this.props.requestAccessForUser[SharedProps.ACCOUNT_ID_NEW];
    }
    let webAppId = this.props.requestAccessForUser[SharedProps.WEBAPPID];
    if (webAppId === undefined || webAppId === null) {
      webAppId = this.props.requestAccessForUser[SharedProps.WEBAPPID_NEW];
    }

    if (
      this.props.selection !== undefined &&
      this.props.selection.name === "web" &&
      this.props.requestAccessForUser[SharedProps.CLIENT_ID] === 0
    ) {
      clientId = 0;
      webAppId = this.props.requestAccessForUser[SharedProps.WEBAPPID];
    }

    this.props.onSubmit(
      this.modalRef,
      this.state.startDateISOFormat,
      this.state.endDateISOFormat,
      clientId,
      this.props.apply.sections.length > 0
        ? this.props.apply.sections[0].webAppId
        : webAppId,
      webApplicationArray
    );
  };

  renderFilePreview = (file) => {
    return (
      <li key={file[SharedProps.ID]}>
        <span className="truncate-text margin-right">{file.name}</span>
        <button
          className="dta-button dta-button--secondary dta-button--circle"
          onClick={this.props.onRemoveFile(file)}
        >
          <Icon name="close" label={`Remove the file: ${file.name}`} />
        </button>
      </li>
    );
  };
  /**
   * @param {*} nextProps
   * @return all flags is TextProps.VALUE_FALSE, when state changed.
   */
  componentWillReceiveProps(nextProps) {
    this.setState({
      submitToDTA: TextProps.VALUE_FALSE,
      showCheckError: TextProps.VALUE_FALSE,
      isCheckRequired: TextProps.VALUE_FALSE,
      startDate: TextProps.VALUE_FALSE,
      endDate: TextProps.VALUE_FALSE,
      beforeMsg: TextProps.VALUE_FALSE,
      afterMsg: TextProps.VALUE_FALSE,
      dateErrorMsg: TextProps.VALUE_FALSE,
      showDateCheckError: TextProps.VALUE_FALSE,
      IsDateCheckRequired: TextProps.VALUE_TRUE,
    });
    this.setState({
      path: {
        pathWeb: TextProps.VALUE_FALSE,
        isPathCheckRequired: TextProps.VALUE_TRUE,
      },
    });
    this.setState({
      con: {
        conWeb: TextProps.VALUE_FALSE,
        isConCheckRequired: TextProps.VALUE_TRUE,
      },
    });
  }
  acceptConWeb = (event) => {
    if (event.length > 0) {
      this.setState({
        con: {
          conWeb: TextProps.VALUE_TRUE,
          isConCheckRequired: TextProps.VALUE_FALSE,
        },
      });
    } else {
      this.setState({
        con: {
          conWeb: TextProps.VALUE_FALSE,
          isConCheckRequired: TextProps.VALUE_TRUE,
        },
      });
    }
  };
  acceptPathWeb = (event) => {
    if (event.length > 0) {
      this.setState({
        path: {
          pathWeb: TextProps.VALUE_TRUE,
          isPathCheckRequired: TextProps.VALUE_FALSE,
        },
      });
    } else {
      this.setState({
        path: {
          pathWeb: TextProps.VALUE_FALSE,
          isPathCheckRequired: TextProps.VALUE_TRUE,
        },
      });
    }
  };
  acceptTermsConditions = (event) => {
    if (event.length > 0) {
      this.setState({
        submitToDTA: TextProps.VALUE_TRUE,
        isCheckRequired: TextProps.VALUE_FALSE,
        showCheckError: TextProps.VALUE_FALSE,
      });
    } else {
      this.setState({
        submitToDTA: TextProps.VALUE_FALSE,
        isCheckRequired: TextProps.VALUE_TRUE,
        showCheckError: TextProps.VALUE_TRUE,
      });
    }
  };

  // valid date
  _datesMessage(value) {
    if (value) {
      var startDate = formatters.date(value, {});
      var year = value.getFullYear();
      var month = value.getMonth();
      var day = value.getDate();
      var unFormatedEndDate = new Date(year + 2, month, day);
      unFormatedEndDate.setDate(unFormatedEndDate.getDate() - 1);
      var endDate = formatters.date(unFormatedEndDate, {}); // one day before last enddate
      let beforeMsg = `Access to this client’s case will be valid from `;
      let afterMsg = `till`;
      this.setState({ beforeMsg: beforeMsg });
      this.setState({ startDate: startDate });
      this.setState({ afterMsg: afterMsg });
      this.setState({ endDate: endDate });
      this.setState({ startDateISOFormat: value });
      this.setState({ endDateISOFormat: unFormatedEndDate });
    } else {
      return "";
    }
  }

  _handleDateChangeForFrom = (value) => {
    if (value !== undefined) {
      var today = moment(new Date());
      if (moment(value).isSameOrBefore(today, "day")) {
        this._datesMessage(value);
        this.setState({
          IsDateCheckRequired: TextProps.VALUE_FALSE,
          showDateCheckError: TextProps.VALUE_FALSE,
          dateErrorMsg: TextProps.VALUE_FALSE,
        });
      } else {
        //invalid date
        let futureDateErrorMsg = "Date cannot be in the future.";
        this.setState({ dateErrorMsg: futureDateErrorMsg });
        this.setState({
          IsDateCheckRequired: TextProps.VALUE_TRUE,
          showDateCheckError: TextProps.VALUE_TRUE,
          beforeMsg: TextProps.VALUE_FALSE,
          afterMsg: TextProps.VALUE_FALSE,
          startDate: TextProps.VALUE_FALSE,
          endDate: TextProps.VALUE_FALSE,
        });
      }
    } else {
      this.setState({
        IsDateCheckRequired: TextProps.VALUE_TRUE,
        showDateCheckError: TextProps.VALUE_TRUE,
        beforeMsg: TextProps.VALUE_FALSE,
        afterMsg: TextProps.VALUE_FALSE,
        startDate: TextProps.VALUE_FALSE,
        endDate: TextProps.VALUE_FALSE,
      });
    }
  };

  loadedPersonName = (propsObj) => {
    if (propsObj.requestAccessForUser.firstName !== undefined) {
      return (
        formatters.fullName(propsObj.requestAccessForUser) +
        formatters.convertStringDateToWithFormat(
          propsObj.requestAccessForUser[SharedProps.DOB]
        )
      );
    } else if (propsObj.requestAccessForUser.personName !== undefined) {
      return (
        formatters.fullNameProvider(propsObj.requestAccessForUser.personName) +
        formatters.convertStringDateToWithFormat(
          propsObj.requestAccessForUser[SharedProps.DOB]
        )
      );
    } else if (propsObj.apply.sections.length > 0) {
      return (
        propsObj.apply.sections[1].questions[0].Answer +
        " " +
        propsObj.apply.sections[1].questions[2].Answer +
        formatters.convertStringDateToWithFormat(
          propsObj.apply.sections[1].questions[4].Answer
        )
      );
    }
  };

  render() {
    const props = this.props;
    console.log(props.requestAccessForUser, "this.props");
    const { search } = this.props.language.provider;
    const { info } = this.props.language.consumer;
    let requestUserAccess;
    if (
      props.requestAccessForUser[SharedProps.ACCOUNT_ID_TIGER_NEW] !==
        undefined &&
      props.requestAccessForUser[SharedProps.ACCOUNT_ID_TIGER_NEW] !== 0
    ) {
      requestUserAccess =
        props.requestAccessForUser[SharedProps.ACCOUNT_ID_TIGER_NEW];
    } else if (props.requestAccessForUser[SharedProps.ACCOUNT_ID_NEW]) {
      requestUserAccess =
        props.requestAccessForUser[SharedProps.ACCOUNT_ID_NEW];
    } else {
      requestUserAccess = props.requestAccessForUser[SharedProps.WEBAPPID];
    }

    const loggedInUser = JSON.parse(sessionStorage.getItem("userInfo"));
    const roleList = loggedInUser.roleList;
    let roleName;
    if (roleList != undefined || roleList != null) {
      roleName = roleList.map((role) => role.roleName);
    }

    return (
      <DTAModal
        ref={(instance) => (this.modalRef = instance)}
        isOpen={props.isOpen}
        onClose={props.onClose}
        modalClass={props.modalClass}
        titleText="Permission to Share Information (PSI)"
        headerText={
          <h1 style={{ color: "black", padding: "24px", margin: "0px" }}>
            {search.requestAccountButton}
          </h1>
        }
        error={props.error}
        errorMessage={props.errorMessage}
      >
        <div className="dta-modal__body pad-all--double pad-top">
          <div>
            {props.requestAccessForUser[SharedProps.PSI_FLAG] !== undefined &&
            props.requestAccessForUser[SharedProps.PSI_FLAG] ===
              TextProps.VALUE_YES.toUpperCase() ? (
              <div className="pure-g margin-bottom">
                <div className="pure-u-1 pure-u-lg-1-5">
                  <span className="name">
                    <strong>{[info.name]}</strong>
                  </span>
                </div>
                <div className="pure-u-1 pure-u-lg-4-5">
                  : {this.loadedPersonName(props)}
                </div>
              </div>
            ) : null}
            {props.apply.sections.length > 0 ? (
              <div className="pure-g margin-bottom">
                <div className="pure-u-1 pure-u-lg-1-5">
                  <span className="id">
                    <strong>Web Application Number</strong>
                  </span>
                </div>
                <div className="pure-u-1 pure-u-lg-4-5">
                  : {props.apply.sections[0].webAppId}
                </div>
              </div>
            ) : (
              <div className="pure-g margin-bottom">
                <div className="pure-u-1 pure-u-lg-1-5">
                  <span className="id">
                    <strong>
                      {props.requestAccessForUser.accountId !== 0
                        ? info.agencyId
                        : info.webAppId}
                    </strong>
                  </span>
                </div>
                <div className="pure-u-1 pure-u-lg-4-5">
                  : {requestUserAccess}
                </div>
              </div>
            )}
          </div>

          <hr className="divider" />
          <div style={{ paddingTop: "16px" }} className="psi-text">
            {[search.commonInfo]}
          </div>
          <div style={{ paddingTop: "20px", paddingBottom: "16px" }}>
            {(roleName[0] === roleProps.PROVIDER &&
              loggedInUser.dualProviderFlag !== undefined &&
              loggedInUser.dualProviderFlag === "N") ||
            (roleName[0] === roleProps.PROVIDER_ROLE_DCPVSG &&
              loggedInUser.dualProviderFlag !== undefined &&
              loggedInUser.dualProviderFlag === "N") ||
            (roleName[0] === roleProps.PROVIDER_ROLE_SPVUSR &&
              loggedInUser.dualProviderFlag !== undefined &&
              loggedInUser.dualProviderFlag === "N") ? (
              <Checkbox
                className="dta-form__option-list"
                optionClass="dta-form__option"
                aria-labelledby="select-dtaconnect"
                value={[search.conWeb]}
                options={[search.conWeb]}
                ref="isConWeb"
                onChange={this.acceptConWeb}
                required={this.state.con.isConCheckRequired}
              />
            ) : (roleName[0] === roleProps.PROVIDER &&
                loggedInUser.dualProviderFlag !== undefined &&
                loggedInUser.dualProviderFlag === "Y") ||
              (roleName[0] === roleProps.PROVIDER_ROLE_DCPVSG &&
                loggedInUser.dualProviderFlag !== undefined &&
                loggedInUser.dualProviderFlag === "Y") ||
              (roleName[0] === roleProps.PROVIDER_ROLE_SPVUSR &&
                loggedInUser.dualProviderFlag !== undefined &&
                loggedInUser.dualProviderFlag === "Y") ? (
              <Checkbox
                className="dta-form__option-list"
                optionClass="dta-form__option"
                aria-labelledby="select-dtaconnect"
                options={[search.conWeb]}
                ref="isConWeb"
                onChange={this.acceptConWeb}
                required={this.state.con.isConCheckRequired}
              />
            ) : (
              <Checkbox
                className="dta-form__option-list"
                optionClass="dta-form__option"
                aria-labelledby="select-dtaconnect"
                options={[search.conWeb]}
                ref="isConWeb"
                onChange={this.acceptConWeb}
                required={this.state.con.isConCheckRequired}
              />
            )}
          </div>
          <div>
            {(roleName[0] === roleProps.PROVIDER &&
              loggedInUser.dualProviderFlag !== undefined &&
              loggedInUser.dualProviderFlag === "N") ||
            (roleName[0] === roleProps.PROVIDER_ROLE_DCPVSG &&
              loggedInUser.dualProviderFlag !== undefined &&
              loggedInUser.dualProviderFlag === "N") ||
            (roleName[0] === roleProps.PROVIDER_ROLE_SPVUSR &&
              loggedInUser.dualProviderFlag !== undefined &&
              loggedInUser.dualProviderFlag === "N") ? (
              <Checkbox
                disabled
                className="dta-form__option-list"
                optionClass="dta-form__option"
                aria-labelledby="select-path"
                options={[search.path]}
                ref="isPath"
                onChange={this.acceptPathWeb}
                required={this.state.path.isPathCheckRequired}
              />
            ) : (roleName[0] === roleProps.PROVIDER &&
                loggedInUser.dualProviderFlag !== undefined &&
                loggedInUser.dualProviderFlag === "Y") ||
              (roleName[0] === roleProps.PROVIDER_ROLE_DCPVSG &&
                loggedInUser.dualProviderFlag !== undefined &&
                loggedInUser.dualProviderFlag === "Y") ||
              (roleName[0] === roleProps.PROVIDER_ROLE_SPVUSR &&
                loggedInUser.dualProviderFlag !== undefined &&
                loggedInUser.dualProviderFlag === "Y") ? (
              <Checkbox
                className="dta-form__option-list"
                optionClass="dta-form__option"
                aria-labelledby="select-path"
                options={[search.path]}
                ref="isPath"
                onChange={this.acceptPathWeb}
                required={this.state.path.isPathCheckRequired}
              />
            ) : (
              <Checkbox
                className="dta-form__option-list"
                optionClass="dta-form__option"
                aria-labelledby="select-dtaconnect"
                options={[search.conWeb]}
                ref="isConWeb"
                onChange={this.acceptConWeb}
                required={this.state.con.isConCheckRequired}
              />
            )}
          </div>
          <div style={{ paddingTop: "20px" }}>
            <label>
              <strong>{search.terms}</strong>
            </label>
          </div>
          <div className="dta-terms-no-scroll dta-form__label">
            <p>{search.requestAccountDesc1}</p>
            <p>{search.requestAccountDesc2}</p>
          </div>
          <hr style={{ marginTop: "32px" }} className="divider" />
          {this.state.dateErrorMsg ? (
            <div className="dta-form__error-message">
              {this.state.dateErrorMsg}
            </div>
          ) : (
            ""
          )}
          <table>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "middle", paddingTop: "16px" }}>
                  <label>
                    Date the PSI form was signed by the client (Required)
                  </label>
                </td>
                <td
                  style={{
                    verticalAlign: "top",
                    paddingLeft: "16px",
                    paddingTop: "16px",
                  }}
                >
                  <DTADatePicker
                    name={`tnc-date-from`}
                    onChange={this._handleDateChangeForFrom}
                    dateProps={{}}
                    disabled={TextProps.VALUE_FALSE}
                    error={this.state.showDateCheckError}
                    required={this.state.IsDateCheckRequired}
                    closeOnChange={TextProps.VALUE_TRUE}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{ paddingTop: "8px", paddingBottom: "8px" }}>
            {this.state.beforeMsg} <b>{this.state.startDate}</b>{" "}
            {this.state.afterMsg} <b>{this.state.endDate}</b>
          </p>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={[search.iAgree]}
            ref="isAcceptTerms"
            onChange={this.acceptTermsConditions}
            required={this.state.isCheckRequired}
            error={this.state.showCheckError}
          />
          <div
            style={{ marginTop: "32px", paddingTop: "32px" }}
            className="dta-modal__footer dta-modal__footer--inline-buttons"
          >
            <button
              style={{ paddingTop: "16px" }}
              className="dta-button dta-button--large dta-button--outline-primary"
              onClick={props.onClose}
            >
              {search.back}
            </button>
            <button
              className="dta-button dta-button--large dta-button--primary"
              disabled={
                this.state.submitToDTA === TextProps.VALUE_FALSE ||
                this.state.IsDateCheckRequired === TextProps.VALUE_TRUE
              }
              onClick={this.onSubmit}
            >
              {search.submitToDTA}
            </button>
          </div>
        </div>
      </DTAModal>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestAccountAccessModal);
