import { Children } from "react";
import { some } from "lodash";
import * as TextProps from "../../constants/text";

/**
 * Custom PropType validator for marking a component's property required conditionally
 * @param  {PropType validator} type      a valid PropType validator
 * @param  {function} condition           passed the props of the component and returns a boolean
 *                                        if returns true, then this prop will be required,
 *                                        otherwise this prop will be optional
 * @return {PropType validator}           the custom PropType validator
 */
export const requiredIf = (type, condition) => {
  return function (props) {
    var test = condition(props) ? type.isRequired : type;
    return test.apply(this, arguments);
  };
};

/**
 * Custom PropType validator that returns a custom validator function that ensures that
 * the children of an element are one of one or several React Components
 * @param  {...React Component} allowedComponents    vararg array of allowed React Components
 * @return {Proptype validator}                      the custom PropType validator function
 */
export function componentOfType(...allowedComponents) {
  return function (props, propName, componentName) {
    let error;
    some(Children.toArray(props[propName]), (child) => {
      if (some(allowedComponents, (component) => child.type !== component)) {
        error = Error(
          `\`${componentName}\` all should be one of \`${JSON.stringify(
            allowedComponents
          )}\``
        );
        return TextProps.VALUE_TRUE;
      }
    });
    return error;
  };
}
