import React, { Component } from "react";
import Radio from "./radio";
import _ from "lodash";
import * as Names from "../../utils/constants/names";
import * as Types from "../constants/types";
import * as Props from "../../utils/components/shared";
import * as OptionsProps from "../../utils/constants/options";
import * as SelectProps from "../../utils/constants/select";
import * as InputProps from "../../utils/constants/input";
import * as languageConstants from "../constants/constants";
import * as TextProps from "../../utils/constants/text";
import DataChangeIndicator from "./utilities/dataChangeIndicator";
import { interimReportPageLabels } from "../../pages/recertInterimReport/recertInterimReportText";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as modalHelperFunction from "./helperFunctions/modalHelperFunction";
import { householdTemplate, tanfhouseholdTemplatemain } from "../../pages/recertification/recertificationMapData";
import { interimHouseHoldQuestions } from "../../pages/recertInterimReport/recertInterimReportMapData";
import * as UnitsProps from "../../utils/constants/units";
import { formatDate } from "../../utils/components/utilities/formatters";

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

  if (language === null || language === languageConstants.langOptSel) {
    language = Types.ENGLISH;
  }

class HouseholdInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removeHeadModalOpen: TextProps.VALUE_FALSE,
      removeSubHeadModalOpen:TextProps.VALUE_FALSE,
      householdChanged: null,
      selectedMember: ""
    };
  }
  componentDidMount() {
    if (!this.props.householdData.householdMembers) {
      this.setInitialData();
    } else if((this.props.householdData.householdMembers.membersListUpdated == TextProps.VALUE_TRUE || this.props.householdData.householdMembers.membersListUpdated == TextProps.VALUE_FALSE)) {
      this.setState({
        householdChanged:
          this.props.householdData.householdMembers.membersListUpdated,
      });
      this.props.setDataChangeStatus(
        TextProps.VALUE_TRUE,
        this.props.householdData.sections.indexOf(
          this.props.householdData.sections.filter(
            (sec) => sec.id === "my-household"
          )[0]
        )
      );
    }
  }

  setInitialData = (updatedMemberList) => {
    let oldData = this.props.householdData.responseObject.householdMembers;
    if(updatedMemberList && oldData){
      let memberList =  _.cloneDeep(updatedMemberList.membersList)
      this.props.setHouseholdMembers({
        membersListUpdated:  this.state.householdChanged,
        addHouseholdMember:  TextProps.VALUE_FALSE,// updatedMemberList.addHouseholdMember,
        membersList: memberList,
      });
    }
    else {
      this.props.setHouseholdMembers({
        membersListUpdated: this.state.householdChanged,
        addHouseholdMember: TextProps.VALUE_FALSE,
        membersList: _.cloneDeep(
          this.props.householdData.responseObject.householdMembers
        ),
      });
    }
    //Retain all the removed sections
    if (
      this.props.householdData.sections.length < 5 &&
      sessionStorage.getItem("fullSectionsIR")
    ) {
      let sections = JSON.parse(sessionStorage.getItem("fullSectionsIR"));
      let removedSections = sections.slice(3, sections.length);
      let existingSections = this.props.householdData.sections;
      existingSections = existingSections.slice(0, 3);
      let sectionsRefreshed = existingSections.concat(removedSections);
      this.props.snapRefreshQuestionsSections(_.cloneDeep(sectionsRefreshed));
      this.props.householdData.sections = sectionsRefreshed;
      sessionStorage.removeItem("fullSectionsIR");
    }

    this.setState({ removeHeadModalOpen: TextProps.VALUE_FALSE });
  };

  compare = (a, b) => {
    let aDate = new Date(this.props.convertStringTODate(a.dateOfBirth));
    let bDate = new Date(this.props.convertStringTODate(b.dateOfBirth));
    if (aDate < bDate) {
      return 1;
    }
    if (aDate > bDate) {
      return -1;
    }
    return 0;
  };

  render() {
    let members = [];
    let head = [];
    let memberListupdatedVal = null;
    if (
      (this.props.householdData.householdMembers &&
        this.props.householdData.householdMembers.membersListUpdated ===
          TextProps.VALUE_TRUE) ||
      (this.props.householdData.additionalProperties &&
        this.props.householdData.additionalProperties.houseHoldChanged ===
          TextProps.VALUE_TRUE)
    ) {
      memberListupdatedVal = interimReportPageLabels(
        language,
        languageConstants.aboutMe
      ).optionYesNo[0];
    } else if (
      (this.props.householdData.householdMembers &&
        this.props.householdData.householdMembers.membersListUpdated ===
          TextProps.VALUE_FALSE) ||
      (this.props.householdData.additionalProperties &&
        this.props.householdData.additionalProperties.houseHoldChanged ===
          TextProps.VALUE_FALSE)
    ) {
      memberListupdatedVal = interimReportPageLabels(
        language,
        languageConstants.aboutMe
      ).optionYesNo[1];
    }

    if (this.props.householdData.householdMembers) {
      this.props.householdData.householdMembers.membersList.map((member) => {
        if (member.granteeFlag === TextProps.VALUE_TRUE) {
          head = member;
          return head;
        } else {
          if(member.agencyID == 0 && (member.dataChangeCode == "removed" || member.dataChangeCode == "REMOVE"))
          {
            return members;
          }
          members.push(member);
        }
      });
    }

    members.sort(this.compare);

    let addMemberUI;
    if (
      this.props.householdData.sections.length > 4 &&
      this.props.householdData.householdMembers &&
      this.props.householdData.householdMembers.membersListUpdated
    ) {
      addMemberUI = (
        <div className="pure-u-1-1">
          <button
            onClick={() => this.addMember()}
            className="full-width dta-button text--small interim-recert-outline-button"
          >
            <b>
              +{" "}
              {
                interimReportPageLabels(
                  language,
                  languageConstants.householdStatic
                ).addMemberButtonLabel
              }
            </b>
          </button>
          <hr className="divider" />
        </div>
      );
    } else {
      addMemberUI = TextProps.VALUE_FALSE;
    }
  var recertType = sessionStorage.getItem('recertType');
    return this.props.householdData.householdMembers ? (
      <div className="pad-all--double">
        {head.firstName ? (
          <div className="pure-u-1-1">
            <div className="max-width pure-u-6-24 pure-u-sm-8-24 label-text-clr">
              <span>
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.householdStatic
                  ).headTitle
                }
              </span>
              <br />
              <br />
            </div>
            <div className="max-width pure-u-18-24 pure-u-sm-16-24">
              <span
                className="pure-u-18-24 household-memb-name"
                style={{ verticalAlign: "middle" }}
              >
                {head.firstName + " " + head.lastName + " " + formatDate(head.dateOfBirth)}
              </span>
              {this.props.householdData.householdMembers &&
              this.props.householdData.householdMembers.membersListUpdated && recertType !== "REEVAL" ? (
                <span className="pure-u-6-24">
                  {head.dataChangeCode !== "removed" ? <button
                    style={{ width: "4.7rem", padding: "0.5rem" }}
                    onClick={() => this.showDeleteModal()}
                    className="dta-button dta-delete-button interim-recert-delete-button text--small"
                  >
                    {
                      interimReportPageLabels(
                        language,
                        languageConstants.householdStatic
                      ).removeButtonLabel
                    }
                  </button> : <button
                    style={{ width: "4.7rem", padding: "0.5rem" }}
                    onClick={() => this.undoRemoveMember(head)}
                    className="dta-button text--small interim-recert-outline-button"
                  >
                    {
                      interimReportPageLabels(
                        language,
                        languageConstants.householdStatic
                      ).undoButtonLabel
                    }
                  </button>}
                </span>
              ) : (
                TextProps.VALUE_FALSE
              )}
            </div>
            {this.renderDataDeleteWarningModal(head)}
            {this.renderSubDataDeleteWarningModal(head)}
            <hr className="divider" />
          </div>
        ) : null}

        {members.length > 0 ? (
          <div className="pure-u-1-1">
            <div className="max-width pure-u-6-24 pure-u-sm-8-24 label-text-clr">
              <span>
                {
                  interimReportPageLabels(
                    language,
                    languageConstants.householdStatic
                  ).additionalMembersTitle
                }
              </span>
              <br />
              <br />
            </div>

            <div className="max-width pure-u-18-24 pure-u-sm-16-24">
              {members.map((member) => {
                let color = "black";
                let removedFlag = TextProps.VALUE_FALSE;
                if (
                  member.dataChangeCode &&
                  (member.dataChangeCode === "removed" ||
                    member.dataChangeCode === "REMOVE") && member.agencyID != 0
                ) {
                  color = "#be1317";
                  removedFlag = TextProps.VALUE_TRUE;

                  return (
                    <div className="household-memb-list-section">
                      <span
                        className="pure-u-18-24 household-memb-name"
                        style={{ verticalAlign: "middle", color: color }}
                      >
                        <span>
                          {member.firstName +
                            " " +
                            member.lastName +
                            " " +
                            formatDate(member.dateOfBirth)}
                        </span>
                        {removedFlag ? (
                          <div>
                            <DataChangeIndicator
                              text={
                                interimReportPageLabels(
                                  language,
                                  languageConstants.householdStatic
                                ).removedLabel
                              }
                            />
                          </div>
                        ) : member.allAnswers ||
                          member.dataChangeCode === "NEW" ? (
                          <DataChangeIndicator
                            text={
                              interimReportPageLabels(
                                language,
                                languageConstants.householdStatic
                              ).newLabel
                            }
                          />
                        ) : null}
                      </span>
                      {this.props.householdData.householdMembers &&
                      this.props.householdData.householdMembers
                        .membersListUpdated ? (
                        <span className="pure-u-6-24">
                          <button
                            style={{ width: "4.7rem", padding: "0.5rem" }}
                            onClick={() => this.undoRemoveMember(member)}
                            className="dta-button text--small interim-recert-outline-button"
                          >
                            {
                              interimReportPageLabels(
                                language,
                                languageConstants.householdStatic
                              ).undoButtonLabel
                            }
                          </button>
                        </span>
                      ) : (
                        TextProps.VALUE_FALSE
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div className="household-memb-list-section">
                      <span
                        className="pure-u-18-24 household-memb-name"
                        style={{ verticalAlign: "middle", color: color }}
                      >
                        {member.firstName +
                          " " +
                          member.lastName +
                          " " +
                          formatDate(member.dateOfBirth)}
                        {removedFlag ? (
                          <span style={{ display: "inline-block" }}>
                            <DataChangeIndicator
                              text={
                                interimReportPageLabels(
                                  language,
                                  languageConstants.householdStatic
                                ).removedLabel
                              }
                            />
                          </span>
                        ) : member.allAnswers ||
                          member.dataChangeCode === "NEW" ? (
                          <span style={{ display: "inline-block" }}>
                            <DataChangeIndicator
                              text={
                                interimReportPageLabels(
                                  language,
                                  languageConstants.householdStatic
                                ).newLabel
                              }
                            />
                          </span>
                        ) : null}
                      </span>
                      {this.props.householdData.householdMembers &&
                      this.props.householdData.householdMembers
                        .membersListUpdated ? (
                        <span className="pure-u-6-24">
                          <button
                            onClick={() => this.showSubDeleteModal(member)}
                            style={{ width: "4.7rem", padding: "0.5rem" }}
                            className="dta-button dta-delete-button interim-recert-delete-button text--small"
                          >
                            {
                              interimReportPageLabels(
                                language,
                                languageConstants.householdStatic
                              ).removeButtonLabel
                            }
                          </button>
                        </span>
                      ) : (
                        TextProps.VALUE_FALSE
                      )}
                    </div>
                  );
                }
              })}
            </div>
            <hr className="divider" />
          </div>
        ) : null}
        {addMemberUI}

        <span style={{ wordBreak: "keep-all" }}>
          {
            interimReportPageLabels(language, languageConstants.householdStatic)
              .householdChangedQuestionLabel
          }
        </span>
        <br />
        <br />
        <div className="pure-u-1-1">
          <div>
            <fieldset className="dta-form__group">
              {this.props.householdData.sections.filter(
                (sec) => sec.id === "my-household"
              )[0].selectionMade === TextProps.VALUE_FALSE && (
                <span className="dta-form__error-message">
                  {
                    interimReportPageLabels(
                      language,
                      languageConstants.contactInfo
                    ).requiredErrorMessage
                  }
                </span>
              )}
              <Radio
                name="household-update"
                className="dta-form__option-list"
                optionClass="dta-form__option dta-form__option--width-1-of-2"
                options={[
                  interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[0],
                  interimReportPageLabels(language, languageConstants.aboutMe)
                    .optionYesNo[1],
                ]}
                required={TextProps.VALUE_TRUE}
                value={memberListupdatedVal}
                onChange={(value) => this.handleHouseholdUpdated(value)}
              />
            </fieldset>
          </div>
        </div>
      </div>
    ) : null;
  }

  addMember = () => {
    let recertType = sessionStorage.getItem("recertType");
    let householdSubsectionObj = [];
    if (this.props.applicationType === "recert" && recertType !== "REEVAL") {
      householdSubsectionObj.push(_.cloneDeep(householdTemplate));
    } else if(recertType === "REEVAL") {
      householdSubsectionObj.push(_.cloneDeep(tanfhouseholdTemplatemain));
    }
    else {
      householdSubsectionObj.push({
        id: "household-member",
        parentId: "my-household",
        completed: TextProps.VALUE_FALSE,
        title: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).houseMemberTitle,
        header: interimReportPageLabels(
          language,
          languageConstants.householdStatic
        ).houseMemberTitle,
        subHeader:
          "<p>" +
          interimReportPageLabels(language, languageConstants.householdStatic)
            .houseMemberSubHeader1 +
          "</p>" +
          "<p>" +
          interimReportPageLabels(language, languageConstants.householdStatic)
            .houseMemberSubHeader2 +
          "</p>",
        hiddenFromNav: TextProps.VALUE_TRUE,
        navigateToPrimaryScreen: TextProps.VALUE_TRUE,
        questions: householdTemplate.questions
      });
    }

    this.props.addHouseholdAddSectionToList(householdSubsectionObj);
    this.props.onPrimaryActionClick();
  };

  removeMember = (member) => {
    let members = this.props.householdData.householdMembers.membersList;
    let filterdMembers = [];
    members.forEach((mem) => {
      if (
        mem.agencyID !== member.agencyID ||
        mem.firstName !== member.firstName ||
        mem.lastName !== member.lastName ||
        mem.dateOfBirth !== member.dateOfBirth
      ) {
        filterdMembers.push(mem);
      } else {
        if (mem.allAnswers !== undefined) {
          // mem.dataChangeCode = "removed";
          // filterdMembers.push(mem);
        } else {
          mem.dataChangeCode = "removed";
          filterdMembers.push(mem);
        }
      }
    });
    let stateObj = this.props.householdData.householdMembers;
    stateObj.membersList = filterdMembers;
    this.props.setHouseholdMembers(stateObj);
  };

  undoRemoveMember = (member) => {
    let members = this.props.householdData.householdMembers.membersList;
    let filterdMembers = [];
    members.forEach((mem) => {
      if (
        mem.agencyID !== member.agencyID ||
        mem.firstName !== member.firstName ||
        mem.lastName !== member.lastName ||
        mem.dateOfBirth !== member.dateOfBirth
      ) {
        // Checks house hold member details before undoing remove.
      } else {
        mem.dataChangeCode = "NOCHNG";
      }
      filterdMembers.push(mem);
    });
    let stateObj = this.props.householdData.householdMembers;
    stateObj.membersList = filterdMembers;
    this.props.setHouseholdMembers(stateObj);
    if(member.granteeFlag){
      this.props.onRemovedHOH(false);
    }
  };

  removeHead = (e,member) => {
    let houseHoldMembers =
      this.props.householdData.householdMembers.membersList;
    let filterdMembers = [];
    houseHoldMembers.forEach((HHmember) => {
      if (
        HHmember.agencyID !== member.agencyID ||
        HHmember.firstName !== member.firstName ||
        HHmember.lastName !== member.lastName ||
        HHmember.dateOfBirth !== member.dateOfBirth
      ) {
        filterdMembers.push(HHmember);
      } else {
        if (HHmember.allAnswers !== undefined) {
          console.log("NEWLY ADDED");
        } else {
          HHmember.dataChangeCode = "removed";
          filterdMembers.push(HHmember);
        }
      }
    });
    let stateObj = this.props.householdData.householdMembers;
    stateObj.membersList = filterdMembers;
    this.props.onRemovedHOH(true);

    this.props.setHouseholdMembers(stateObj);
    this.props.onPrimaryActionClick(e,"summary");
  };

  handleHouseholdUpdated = (value) => {
    if (
      value ===
      interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[1]
    ) {
      let updatedList = this.props.householdData.householdMembers;
      this.props.setHouseholdMembers(null);
      this.props.addHouseholdAddSectionToList(null);
      this.props.setDataChangeStatus(
        TextProps.VALUE_TRUE,
        this.props.householdData.sections.indexOf(
          this.props.householdData.sections.filter(
            (sec) => sec.id === "my-household"
          )[0]
        )
      );
      this.setState({ householdChanged: TextProps.VALUE_FALSE }, () =>
        this.setInitialData(updatedList)
      );
    } else {
      let stateObj = this.props.householdData.householdMembers;
      stateObj.membersListUpdated =
        value ===
        interimReportPageLabels(language, languageConstants.aboutMe)
          .optionYesNo[0]
          ? TextProps.VALUE_TRUE
          : TextProps.VALUE_FALSE;
      this.setState({ householdChanged: TextProps.VALUE_TRUE }, () => {
        this.props.setHouseholdMembers(stateObj);
        this.props.addHouseholdAddSectionToList(null);
        this.props.setDataChangeStatus(
          TextProps.VALUE_TRUE,
          this.props.householdData.sections.indexOf(
            this.props.householdData.sections.filter(
              (sec) => sec.id === "my-household"
            )[0]
          )
        );
      });
    }
  };

  closeDeleteModal = () => {
    this.setState({ removeHeadModalOpen: TextProps.VALUE_FALSE });
  };

  showDeleteModal = () => {
    this.setState({ removeHeadModalOpen: TextProps.VALUE_TRUE });
  };

  closeSubDeleteModal = (action) => {
    if(action == TextProps.VALUE_TRUE){
      this.removeMember(this.state.selectedMember);
    }
    this.setState({ removeSubHeadModalOpen: TextProps.VALUE_FALSE }, () => {
      this.setState({ selectedMember: ""})
    });
  };

showSubDeleteModal = (member) => {
    if(member.allAnswers !== undefined) {
      this.removeMember(member);
    } else {
      this.setState({ removeSubHeadModalOpen: TextProps.VALUE_TRUE, selectedMember: member });
    }
  };

  renderSubDataDeleteWarningModal = (head) => {
    return modalHelperFunction.dtaHelperModal(
      null,
      this.state.removeSubHeadModalOpen,
      this.closeSubDeleteModal,
      "save-progress-modal",
      "Delete Household Head",
      null,
      TextProps.VALUE_FALSE,
      TextProps.VALUE_FALSE,
      interimReportPageLabels(language, languageConstants.beforeYouStart)
        .removeHoHTitle,
      <div className="dta-modal__body pad-all">
        <p>
          {
            interimReportPageLabels(language, languageConstants.beforeYouStart)
              .removeNonHoHLabel
          }
        </p>
        <p> </p>
        <div className="apply__footer pad-all--double">
          <button
            className="dta-button dta-button--outline-primary"
            onClick={() => this.closeSubDeleteModal(TextProps.VALUE_FALSE)}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).boBackButtonLabel
            }
          </button>
          <button
            className="dta-button dta-button--primary"
            onClick={() => this.closeSubDeleteModal(TextProps.VALUE_TRUE)}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).continueLabel
            }
          </button>
        </div>
      </div>
    );
  };

  // Render a DHR modal for the save progress dialog
  renderDataDeleteWarningModal = (head) => {
    return modalHelperFunction.dtaHelperModal(
      null,
      this.state.removeHeadModalOpen,
      this.closeDeleteModal,
      "save-progress-modal",
      "Delete Household Head",
      null,
      TextProps.VALUE_FALSE,
      TextProps.VALUE_FALSE,
      interimReportPageLabels(language, languageConstants.beforeYouStart)
        .removeHoHTitle,
      <div className="dta-modal__body pad-all">
        <p>
          {
            interimReportPageLabels(language, languageConstants.beforeYouStart)
              .removeHoHLabel
          }
        </p>
        <p> </p>
        <div className="apply__footer pad-all--double">
          <button
            className="dta-button dta-button--outline-primary"
            onClick={() => this.closeDeleteModal()}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).boBackButtonLabel
            }
          </button>
          <button
            className="dta-button dta-button--primary"
            onClick={(e) => this.removeHead(e,head)}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).continueLabel
            }
          </button>
        </div>
      </div>
    );
  };
}

export default HouseholdInfo;
