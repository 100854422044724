import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import { beforeYoustartDsnapMlanguageLabels } from "../applyDsnapText";
import * as Names from "../../../utils/constants/names";
import * as OptionsProps from "../../../utils/constants/options";
import * as Props from "../../../utils/components/shared";
import * as Types from "../../../utils/constants/types";
import * as TextProps from "../../../utils/constants/text";
import { langOptSel } from "../../../utils/constants/constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { resolve as resolveQuestion } from "../../../utils/components/utilities/questions";
import * as routeProps from "../../../utils/constants/routesConstants.js";
import {applySectionStaticMultilanguageLabels,houseHoldSectionMultilanguageLabels,MyhouseholdDsnapMultilanguageLabels} from"../applyDsnapText.js"
import { isNone } from "../../../utils/components/utilities/controls.js";
import { filterData } from "../applyDsnapHelper.js";
import { appendIncomeDetails,appendHouseHoldMembers,
  updateHouseholdTextChanges,setHouseholdMembers } from "../../../redux/applyDsnap/applyDsnapActions.js";
  import { screeningStaticLabels } from "../../screening/screeningText";
import {aboutMeMultilanguageLabels} from "../applyDsnapText";

// let applicationType = sessionStorage.getItem("applicationType");
let selectedLanguage = localStorage.getItem("selectedLanguage");
if (selectedLanguage === null || selectedLanguage === "select" ) {
  selectedLanguage = "en-US";
}


class ApplySection extends Component {
    static propTypes = {
      section: PropTypes.object.isRequired,
    };
  
    constructor(props) {
      super();
  
      enableUniqueIds(this);

      this.state = {
        formId: this.nextUniqueId(),
        ApplyAnswer: {},
        windowHgt: "",
        doNotHaveAdress: TextProps.VALUE_FALSE
      };
    }


    componentWillMount() {
      // Set up conditional questions state
      this.setState({
        conditionalState: this._initConditionalState(this.props.section),
      });
    }

    // Add Max height for iPad(Safari)
  componentDidUpdate() {
    var elmnt = document.getElementsByClassName("apply__content");
    var hgt = elmnt[0].offsetHeight;
    if (this.state.windowHgt != hgt) {
      {
        this.addMaxHeight(hgt);
      }
    }
  }

  addMaxHeight = (hgt) => {
    var str = window.navigator.appVersion;
    var res = str.substring(18, 22);
    var version = res.split("_").join(".");
    if (version < 12.2 && str.includes("iPad")) {
      this.setState({ windowHgt: hgt });
    }
  };
    // apply-specific generation of unique keys to store the values of the questions that
  // trigger the conditional display of other questions
  _conditionalKey(sectionId, questionName, targetValue = "") {
    return `${sectionId}/${questionName}/${targetValue}`;
  }
   // Recursive helper function for _initConditionalState that goes through all sections
  // and subsections
  _initConditionalBySections(dataObj, sectionObj) {
    if (sectionObj && sectionObj.questions) {
      const sectionId = sectionObj.id;
      this._initConditionalByQuestions(
        dataObj,
        sectionId,
        sectionObj.questions,
      );
    }
    if (sectionObj && sectionObj.subsections) {
      sectionObj.subsections.forEach((subObj) =>
        this._initConditionalBySections(dataObj, subObj)
      );
    }
  }
  _initConditionalState(section) {
    const dataObj = {};
    this._initConditionalBySections(dataObj, section);
    return dataObj;
  }
   
    _doRetrieve = (sectionId, questionData, conditionObj) => {
      if (conditionObj && questionData[OptionsProps.TARGET_VALUE]) {
        return questionData[OptionsProps.TARGET_VALUE];
      } else if (conditionObj || questionData[OptionsProps.CONDITIONALS]) {
        const key = this._conditionalKey(
          sectionId,
          questionData[Props.NAME],
          conditionObj && conditionObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
        );
        return this.state.conditionalState[key];
      } else if (questionData[OptionsProps.TARGET_VALUE]) {
        return questionData[OptionsProps.TARGET_VALUE];
      } else if (questionData[OptionsProps.OPTIONS]) {
        questionData.value = "";
        return questionData[OptionsProps.VALUE];
      }
    };

    _handleChangeForQuestion = (
      sectionId,
      questionData,
      conditionObj,
      val,
      optionalMetaData = null
    ) => {
      //break if invalid date
      if (this.AnswerObject === undefined) {
        this.AnswerObject = this.props.answerObject;
      }
      if (this.AnswerObject[sectionId] === undefined) {
        this.AnswerObject[sectionId] = {};
      }
  
      if (questionData.type === "date" && val !== "" && val !== undefined) {
        if (val.getFullYear() === 1899 || val.getFullYear() === 1900) {
          if (this.AnswerObject[sectionId][questionData.name] instanceof Date) {
            this.AnswerObject[sectionId][questionData.name] = "";
            this.props.bindAnswerToState(this.AnswerObject);
          }
          return;
        }
      }

     
      //Income Add Button - only on optionalMetaData
      if (optionalMetaData.action) {
        this._incomeAddButton(sectionId, questionData, val, optionalMetaData);
      }
  
      //Real Time Appeneding Data to Section for Realtime view (select)
      if (questionData.type === "select") {
        this._updateAnswerForView(questionData, val);
      }
      this._handleWarningModal(sectionId, questionData, val);
      //HouseHold Label Updates
      if ("household-member" === sectionId.substring(0, 16)) {
        this._bindAnswerToQuestion(sectionId, val, questionData[Props.NAME]);
        this._appendLabelToQuestions(sectionId, val, questionData[Props.NAME]);
      }
      

  
      //Appending Values to Section
      if (sectionId === "kids-and-adults") {
        this.AnswerObject[sectionId][questionData.name] = val;
        questionData.error = TextProps.VALUE_FALSE;
        console.log(this.state.conditionalState);
        for (const [key, value] of Object.entries(this.state.conditionalState)) {
          console.log(`${key}: ${value}`);
          let keyValues = key.split("/");
          if (
            keyValues[0] === "kids-and-adults" &&
            keyValues[1].includes("recipient-of-care")
          ) {
            let keyExists = Object.keys(this.AnswerObject[sectionId]).filter(
              (key) => key === keyValues[1]
            );
            if (!keyExists.length) {
              this.AnswerObject[sectionId][keyValues[1]] = value;
            }
          }
          if (
            keyValues[0] === "kids-and-adults" &&
            keyValues[1].includes("caregivers")
          ) {
            let keyExists = Object.keys(this.AnswerObject[sectionId]).filter(
              (key) => key === "caregivers"
            );
            if (!keyExists.length) {
              this.AnswerObject[sectionId][keyValues[1]] = value;
            }
          }
          // caregivers
        }
        // this.state.conditionalState.map(cond => {
  
        // })
      } else {
        if (
          sectionId === "my-household" &&
          (val < 0 || val === "0" || val === "")
        ) {
          val = "";
          questionData.error = TextProps.VALUE_TRUE;
          questionData.isRequired = TextProps.VALUE_TRUE;
        } else {
          questionData.error = TextProps.VALUE_FALSE;
        }
  
        this.AnswerObject[sectionId][questionData.name] = val;
      }
      if (conditionObj || questionData[OptionsProps.CONDITIONALS]) {
        const key = this._conditionalKey(
          sectionId,
          questionData[Props.NAME],
          conditionObj && conditionObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
        );
        this.setState({
          conditionalState: {
            ...this.state.conditionalState,
            [key]: val,
          },
        });
      }
      // }
          // Updating the required label on contact info's permanent address field
    if (sectionId === "head-of-household-info") {
      const radioTypeSection = this.AnswerObject["head-of-household-info"];
      const res =
        this.props.section; /*To update the required label in OnChange*/
      const pat = filterData(this.props.applyDsnap.sections, "head-of-household-info"); /*To update the required label in Continue Button*/

      if (
        radioTypeSection["is-homeless"] ===
          aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[0] 
      ) {
        res.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_FALSE;
        pat.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_FALSE;
        res.questions.filter((addr) => addr.name === "address")[0].error =
          TextProps.VALUE_FALSE;
        this.setState({
          conditionalState: {
            ...this.state.conditionalState,
          },
        });
      }
      if (
        radioTypeSection["is-homeless"] ===
          aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[1] 
      ) {
        res.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_TRUE;
        pat.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_TRUE;
        // res.questions.filter((addr) => addr.name === "address")[0].error =
        //   TextProps.VALUE_TRUE;
        this.setState({
          conditionalState: {
            ...this.state.conditionalState,
          },
        });
      }
      if(JSON.stringify(radioTypeSection["do-not-have-address"]) === JSON.stringify(aboutMeMultilanguageLabels(selectedLanguage).yesOption)){
        res.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_FALSE;
        pat.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_FALSE;
        res.questions.filter((addr) => addr.name === "address")[0].error =
          TextProps.VALUE_FALSE;
        res.questions.filter((addr) => addr.name === "is-also-mailing")[0].conditionals[0].conditionalQuestions[0].isRequired =
          TextProps.VALUE_FALSE;
        pat.questions.filter((addr) => addr.name === "is-also-mailing")[0].conditionals[0].conditionalQuestions[0].isRequired =
          TextProps.VALUE_FALSE;
        res.questions.filter((addr) => addr.name === "is-also-mailing")[0].conditionals[0].conditionalQuestions[0].error =
          TextProps.VALUE_FALSE;
        this.setState({
          conditionalState: {
            ...this.state.conditionalState,
          },
          doNotHaveAdress: TextProps.VALUE_TRUE
        });
      } else if(this.state.doNotHaveAdress){
        if(radioTypeSection["is-also-mailing"] === TextProps.VALUE_STR_FALSE){
          res.questions.filter((addr) => addr.name === "is-also-mailing")[0].conditionals[0].conditionalQuestions[0].isRequired =
          TextProps.VALUE_TRUE;
          pat.questions.filter((addr) => addr.name === "is-also-mailing")[0].conditionals[0].conditionalQuestions[0].isRequired =
          TextProps.VALUE_TRUE;
        }else{
          res.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_TRUE;
          pat.questions.filter((addr) => addr.name === "address")[0].isRequired =
          TextProps.VALUE_TRUE;
        }
        this.setState({
          conditionalState: {
            ...this.state.conditionalState,
          },
          doNotHaveAdress: TextProps.VALUE_FALSE
        });
      }
    }
  
      //Dal Reference
      this.props.bindAnswerToState(this.AnswerObject);
    };
    
    _bindAnswerToQuestion = (sectionId, val, fieldName) => {
      let { subsections } = this.props.applyDsnap.sections[3];
   
      for (var k = 0; k < subsections.length ; k++) {
        if (subsections[k].id === sectionId) {
          switch (fieldName) {
            case "firstName":
              subsections[k].questions[0].tempAnswer = val;
              this.props.updateHouseholdTextChanges(subsections);
              break;
            case "lastName":
              subsections[k].questions[2].tempAnswer = val;
              this.props.updateHouseholdTextChanges(subsections);
              break;
            case "dateOfBirth":
              if (val !== "" && val !== undefined) {
                subsections[k].questions[3].tempAnswer =
                  this._convertDateToState(val);
              } else if (
                subsections[k].questions[3].Answer !== "" &&
                subsections[k].questions[3].Answer !== undefined
              ) {
                subsections[k].questions[3].tempAnswer = this._convertDateToState(
                  subsections[k].questions[3].Answer
                );
              }
  
              this.props.updateHouseholdTextChanges(subsections);
              break;
            default:
              break;
          }
        }
      }
    };
    _appendLabelToQuestions = (sectionId, val, fieldName) => {
      let { subsections } = this.props.applyDsnap.sections[3];
      for (var k = 0; k < subsections.length ; k++) {
        if (subsections[k].id === sectionId) {
          //FirstName Binding
          if (
            subsections[k].questions[0].tempAnswer !== undefined &&
            subsections[k].questions[0].tempAnswer !== ""
          ) {
            subsections[k].title = subsections[k].questions[0].tempAnswer;
          } else {
            if (
              subsections[k].questions[2].tempAnswer !== "" &&
              subsections[k].questions[2].tempAnswer !== undefined
            ) {
              subsections[k].title = "";
            } else {
              subsections[k].title =
                houseHoldSectionMultilanguageLabels(selectedLanguage)
                  .houseMemberTitle +
                (k + 1);
              subsections[k].header = applySectionStaticMultilanguageLabels(
                k + 1,
                selectedLanguage
              ).aboutMemberTitle;
              this.props.section.header = applySectionStaticMultilanguageLabels(
                k + 1,
                selectedLanguage
              ).aboutMemberTitle;
            }
          }
          //Last Name Binding
          if (
            subsections[k].questions[0].tempAnswer !== undefined &&
            subsections[k].questions[0].tempAnswer !== "" &&
            subsections[k].questions[2].tempAnswer !== undefined &&
            subsections[k].questions[2].tempAnswer !== ""
          ) {
            subsections[k].title =
              subsections[k].title + " " + subsections[k].questions[2].tempAnswer;
          } else if (
            subsections[k].questions[2].tempAnswer !== "" &&
            subsections[k].questions[2].tempAnswer !== undefined
          ) {
            subsections[k].title = subsections[k].questions[2].tempAnswer;
          }
  
          //DOB Binding
          if (
            subsections[k].questions[3].tempAnswer !== "" &&
            subsections[k].questions[3].tempAnswer !== undefined
          ) {
            subsections[k].title =
              subsections[k].title + subsections[k].questions[3].tempAnswer;
          }
          subsections[k].header = applySectionStaticMultilanguageLabels(
            subsections[k].title,
            selectedLanguage
          ).aboutTitle;
          this.props.section.header = applySectionStaticMultilanguageLabels(
            subsections[k].title,
            selectedLanguage
          ).aboutTitle;
          if (fieldName === "firstName") {
            if (val !== "" && val !== null) {
              subsections[k].header = applySectionStaticMultilanguageLabels(
                subsections[k].title,
                selectedLanguage
              ).aboutTitle;
              subsections[k].questions[1].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).labelMiddle;
              subsections[k].questions[2].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).labelLastName;
             
              subsections[k].questions[4].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).dateOfBirthQLabel;
              subsections[k].questions[5].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).genderQLabel;
              
              subsections[k].questions[6].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).ssnQLabel;
                this.props.section.questions[7].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).raceLabel;
              //this updates realtime without redux store
            
              this.props.section.header = applySectionStaticMultilanguageLabels(
                subsections[k].title,
                selectedLanguage
              ).aboutTitle;
              this.props.section.questions[1].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).labelMiddle;
              this.props.section.questions[2].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).labelLastName;
              this.props.section.questions[4].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).dateOfBirthQLabel;
              this.props.section.questions[5].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).genderQLabel;
              this.props.section.questions[6].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).ssnQLabel;
              this.props.section.questions[7].label =
                applySectionStaticMultilanguageLabels(
                  val,
                  selectedLanguage
                ).raceLabel;
            } else {
              
              subsections[k].questions[1].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personMiddleName; //"What is this person's middle name?";
              subsections[k].questions[2].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personLastName; //"What is this person's last name?";
              //"What is this person's relationship to you?";
              subsections[k].questions[4].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personDobLabel; //"What is this person's date of birth?";
              subsections[k].questions[5].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personGenderLabel; //"What is this person's gender?";
              subsections[k].questions[6].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personSSNLabel; //"What is this person's Social Security Number?";
              subsections[k].questions[7].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personRaceLabel;
              // this updates realtime without redux store
            
              this.props.section.questions[1].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personMiddleName;
              this.props.section.questions[2].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personLastName;
              this.props.section.questions[4].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personDobLabel;
              this.props.section.questions[5].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personGenderLabel;
              this.props.section.questions[6].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personSSNLabel;
                subsections[k].questions[7].label =
                applySectionStaticMultilanguageLabels(
                  "",
                  selectedLanguage
                ).personRaceLabel;
            
            }
          }
        }
      }
      this.setHouseholdData(subsections);

      // Finally, update the state
      this.props.updateHouseholdTextChanges(subsections);     
      
    };
    _handleWarningModal = (sectionId, questionData, val) => {
      switch (sectionId) {
        case "my-household":
          if (
            Number(questionData.Answer) > Number(val) &&
            val !== "" &&
            questionData.Answer !== "" &&
            questionData.Answer !== undefined
          ) {
            this.props.onWarningProgress(val);
          }
          break;
        default:
          break;
      }
    };
    setHouseholdData = (subsections) =>{
      let memberOptionsArray = [];
      let memberHeadObject = {};
      let headOfHouseholdQuestions = filterData(this.props.applyDsnap.sections, "head-of-household-info").questions;
      let headOfHouseholdName = headOfHouseholdQuestions[1].Answer + " " + headOfHouseholdQuestions[3].Answer;
      memberHeadObject[OptionsProps.OPTION_DISPLAY] = headOfHouseholdName;
      memberHeadObject.id = filterData(this.props.applyDsnap.sections, "before-you-start").webClientId;
      memberHeadObject[OptionsProps.OPTION_VALUE] =
      filterData(this.props.applyDsnap.sections, "before-you-start").webClientId;
      memberOptionsArray.push(memberHeadObject);

      for (var i = 0; i < subsections.length; i++) {
        let optionObj = {};
        optionObj[OptionsProps.OPTION_VALUE] = "DEP-" + (i+1);
        optionObj[OptionsProps.OPTION_DISPLAY] = subsections[i].title;
        optionObj.isGrantee = TextProps.VALUE_N;
        memberOptionsArray.push(optionObj)
        this.props.setHouseholdMembers(memberOptionsArray);
      }    
      let optionObj ={};
      let noIncomeReportOption = MyhouseholdDsnapMultilanguageLabels(selectedLanguage).incomeOptionLabel[1];
      optionObj[OptionsProps.OPTION_DISPLAY] = noIncomeReportOption;
      optionObj[OptionsProps.OPTION_VALUE] = noIncomeReportOption;
      memberOptionsArray.push(optionObj);
    }
    _incomeAddButton = (sectionId, questionData, val, optionalMetaData) => {
      //AddButton Value Update
      if (optionalMetaData.action) {
        questionData[OptionsProps.TARGET_VALUE] = val;
        if (questionData.Answer === undefined) {
          questionData.Answer = [];
        }
        questionData.Answer = val;
      }
      if (optionalMetaData.action !== undefined) {
        let incomeSectionCopy = _.cloneDeep(filterData(this.props.applyDsnap.sections, "income"));
        //finding which subsection to append
        for (
          var subSectionIndex = 0;
          subSectionIndex < incomeSectionCopy.subsections.length - 1;
          subSectionIndex++
        ) {
          if (
            incomeSectionCopy.subsections[subSectionIndex].subsections !==
            undefined
          ) {
            if (
              incomeSectionCopy.subsections[subSectionIndex].subsections[0].id ===
              sectionId
            ) {
              for (
                var questionDataIndex = 0;
                questionDataIndex <
                incomeSectionCopy.subsections[subSectionIndex].subsections[0]
                  .questions.length;
                questionDataIndex++
              ) {
                if (
                  incomeSectionCopy.subsections[subSectionIndex].subsections[0]
                    .questions[questionDataIndex].name === questionData.name
                ) {
                  incomeSectionCopy.subsections[
                    subSectionIndex
                  ].subsections[0].questions[questionDataIndex] =
                    _.cloneDeep(questionData);
                }
              }
            }
          }
        }
  
        this.props.appendIncomeDetails(incomeSectionCopy);
      }
    };

    _getUniqueId = (name = "") => {
      return name ? this.getUniqueId(name) : this.nextUniqueId();
    };

    _doBuildContainerProps = (questionData, conditionObj) => {
      const name = questionData[Props.NAME];
      switch (name) {
        case Names.LAST_NAME:
          return { className: "pure-u-4-5 pad-right-half" };
        case Names.SUFFIX:
          return { className: "pure-u-1-5 suffix" };
        case Names.STATE:
            return { className: "pure-u-1-5 " };
        case Names.COUNTY:
            return { className: "pure-u-1-2 suffix" };
            case Names.COUNTY_ASSISTANCE:
              return { className: "pure-u-1-2 suffix" };
        default:
          return {};
      }
    };

    _doBuildControlProps = (questionData, conditionObj) => {
      const name = questionData[Props.NAME];
      switch (name) {
        case Names.LAST_NAME:
          return { questionClass: "truncate-text" };
        case Names.SUFFIX:
          return { questionClass: "truncate-text" };
        case Names.STATE:
            return { questionClass: "truncate-text" };
        case Names.COUNTY:
            return { questionClass: "truncate-text" };
            case Names.COUNTY_ASSISTANCE:
              return { questionClass: "truncate-text" };
        default:
          return {};
      }
    };

    renderQuestions = () => {
      const { id, questions } = this.props.section,
  
        requiredHandlers = {
          doRetrieve: this._doRetrieve.bind(this, id),
          onChange: this._handleChangeForQuestion.bind(this, id),
          doGetUniqueId: this._getUniqueId,
        },
        optionalHandlers = {
          doBuildContainerProps: this._doBuildContainerProps,
          doBuildControlProps: this._doBuildControlProps,
        };
      return (
        // The id on each form is required to properly namespace questions/labels
        <form className="pad-all--double" id={id}>
          {questions &&
            questions.map((questionData) => {
              return resolveQuestion(
                questionData,
                requiredHandlers,
                optionalHandlers
              );
            })}
        </form>
      );
    };

render() {
    const section = this.props.section;
    return (
        // Main markup for each apply section page
        <div className="apply__content">
          <div className="apply__header pad-all--double apply_tfdc">
          { 
            section.id !== "sign-and-submit" &&
            section.id !== "before-you-start" &&
            section.id !== "head-of-household-info" ? (
              <div className="apply__sign__submit">
                <button
                  type="button"
                  className="dta-button dta-button--outline-primary"
                  onClick={this.props.onSignSubmitActionClick}
                >
                  {beforeYoustartDsnapMlanguageLabels(selectedLanguage).submitButtonLabel}
                </button>
              </div>
            ) : null}
            <h1>{section.header}</h1>
             {/* Subheader is optional property in JSON */}
             {section.subHeader ? (
            <p
              className="apply__sub-header"
              dangerouslySetInnerHTML={{
                __html: section.subHeader,
              }}
            ></p>
          ) : null}
          </div>
          <div className="apply__body">
            <div >    
                 
             {section.questions ? this.renderQuestions() : this.props.children}
           </div>
              </div>
              {section.id !== "submitted" ? (
                <div className="apply__footer pad-all--double">
                  {/* Generally, clicking this secondary action button would route user to previous section,
                    or back to homepage / where they came from if applicable */}
                  <button
                    type="button"
                    className="dta-button dta-button--outline-primary dta-button--large"
                    onClick={this.props.onSecondaryActionClick}
                  >
                    {beforeYoustartDsnapMlanguageLabels(selectedLanguage).goBack}
                  </button>
                  {/* {section.id !== "before-you-start" && !this.props.isPreScreening
                    ? [
                          <button
                          type="button"
                          className="dta-button dta-button--outline-primary dta-button--large"
                          form={section.id}
                          onClick={this.props.onContinueClick}
                        >
                          {beforeYoustartDsnapMlanguageLabels(language).continueLabel}
                        </button>,
                      ]
                    : ""} */}
                  {/* Generally, clicking this primary action button would perform any necessary
                    validation and route user to next section */}
                  {section.id === "screening-results" ? (
                    <button
                      type="submit"
                      className="dta-button dta-button--primary dta-button--large"
                      form={section.id}
                      onClick={this.redirectToApply}
                    >
                      {/* Using a placeholder function here to get a dynamic value for button text.
                      The value could also be stored in data or a state machine could be used,
                      not unlike the simplified approach here */}
                      {this.props.primaryActionLabel}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="dta-button dta-button--primary dta-button--large"
                      form={section.id}
                      onClick={this.props.onPrimaryActionClick}
                    >
                      {this.props.primaryActionLabel}
                    </button>
                  )}
                </div>
              ) : (
                TextProps.VALUE_FALSE
              )}
            { (this.state.windowHgt > 950) ? 
            <div style={{height:'95px'}}></div> : null
            }  
        </div>
       
      );
  }
  redirectToApply = () => {
    window.location.assign(routeProps.APPLY_DSNAP);
  };
  _updateAnswerForView = (questionData, val) => {
    let currentSectionQuestion = this.props.section.questions;
    let _this = this;
    if (currentSectionQuestion !== undefined) {
      currentSectionQuestion.map(function (question, index) {
        if (question.name === questionData.name) {
          _this.props.section.questions[index].Answer = val;
          _this.props.section.questions[index].targetValue = val;
        }
        return TextProps.VALUE_TRUE;
      });
    }
  };
  _convertDateToState = (date) => {
    if (isNone(date)) {
      return "";
    }
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return " (" + mm + "/" + dd + "/" + yyyy + ")";
  };
  // Recursive helper function for _initConditionalState that goes through all questions
  // and through the conditional block of the question, if it has one
  _initConditionalByQuestions(dataObj, sectionId, questions, targetValue = "") {
    questions.forEach((question) => {
      // look to see if each question has a conditional block
      if (question[OptionsProps.CONDITIONALS]) {
        const key = this._conditionalKey(
            sectionId,
            question[Props.NAME],
            targetValue
          ),
          defaultValue = question[Props.TYPE] === Types.CHECKBOX ? [] : "";
        dataObj[key] = question.targetValue
          ? question.targetValue
          : defaultValue;
        question[OptionsProps.CONDITIONALS].forEach((conditionalObj) => {
          this._initConditionalByQuestions(
            dataObj,
            sectionId,
            conditionalObj[OptionsProps.CONDITIONAL_QUESTIONS],
            conditionalObj[OptionsProps.CONDITIONAL_TARGET_VALUE]
          );
        });
      } else if (question.Answer && question.Answer.length) {
        const key = this._conditionalKey(
            sectionId,
            question[Props.NAME],
            targetValue
          ),
          defaultValue = question[Props.TYPE] === Types.CHECKBOX ? [] : "";
        dataObj[key] = question.targetValue
          ? question.targetValue
          : defaultValue;
      }
    });
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
      {
        appendIncomeDetails: appendIncomeDetails,
        appendHouseHoldMembers: appendHouseHoldMembers,
        updateHouseholdTextChanges: updateHouseholdTextChanges,
        setHouseholdMembers: setHouseholdMembers,         
      },
      dispatch
  );
}

export default (connect(mapStateToProps, mapDispatchToProps)(ApplySection));
