import * as TextProps from "../../../utils/constants/text";

// to form the anwers in sections
export function buildInputQuestionsData(sections, activeId, event) {
  const questions = sections[0].questions;
  if (questions !== undefined) {
    for (let questIndex of questions) {
      var currentQName = questIndex.name;
      questIndex.AnswerElements = event.target.form.elements[currentQName];
      questIndex.Answer = bindAnswerValuesToJson(
        questIndex,
        questIndex.AnswerElements
      );
      questIndex.targetValue = questIndex.Answer;
    }
  }

  return sections;
}

//New Approach
export function bindAnswers(section, activeId, AnswerObj) {
  section.questions.map((question) => {
    if (
      AnswerObj[activeId] !== undefined &&
      AnswerObj[activeId][question.name] !== undefined
    ) {
      question.Answer = AnswerObj[activeId][question.name];
      question.targetValue = AnswerObj[activeId][question.name];

      //Conditions
      if (question.conditionals) {
        question.conditionals.map((condition) => {
          //Question Itration
          condition.conditionalQuestions.map((conditionQuestion) => {
            if (AnswerObj[activeId][conditionQuestion.name] !== undefined) {
              conditionQuestion.Answer =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.targetValue =
                AnswerObj[activeId][conditionQuestion.name];
            } else {
              conditionQuestion.Answer = "";
              conditionQuestion.targetValue = "";
            }
          });
        });
      }
    } else {
      if (question.type === "units") {
        question.Answer = {
          amount: "",
          unit:
            question.targetValue.unit !== "" ? question.targetValue.unit : "",
        };
        question.targetValue = {
          amount: "",
          unit:
            question.targetValue.unit !== "" ? question.targetValue.unit : "",
        };
      } else {
        question.Answer = "";
        question.targetValue = "";
      }
    }
  });
  return section;
}

// iterate each question and set the answer in sections.questions.answer
function bindAnswerValuesToJson(question, elementsData) {
  switch (question.type) {
    case "select":
      return elementsData.value;
    case "radio":
      return elementsData.value;
    case "numbers":
      var outputVal = "";
      for (var index of question.AnswerElements.keys()) {
        if (
          (elementsData[index].checked === TextProps.VALUE_FALSE &&
            elementsData[index].type === "number" &&
            elementsData[index].value !== "") ||
          (elementsData[index].checked === TextProps.VALUE_TRUE &&
            elementsData[index].type === "radio" &&
            elementsData[index].value !== "")
        ) {
          outputVal = elementsData[index].value;
        }
      }
      if (outputVal !== null) {
        return outputVal;
      } else {
        return elementsData[index].value;
      }
    case "units":
      var unitsOutput = {};
      unitsOutput.amount = elementsData[0].value;
      unitsOutput.unit = elementsData[1].value;
      return unitsOutput;
    default:
  }
}

export function toValidateInputErros(dataSections, activeId, nextActiveId) {
  const questionsObj = dataSections.questions;
  for (let questionVal of questionsObj) {
    if (questionVal.type === "radio") {
      if (
        questionVal.Answer.length < 1 &&
        questionVal.isRequired === TextProps.VALUE_TRUE
      ) {
        questionVal.error = TextProps.VALUE_TRUE;
      } else {
        questionVal.error = TextProps.VALUE_FALSE;
      }
    } else if (questionVal.type === "numbers") {
      if (
        (questionVal.Answer < 1 || questionVal.Answer === "") &&
        questionVal.isRequired === TextProps.VALUE_TRUE
      ) {
        questionVal.error = TextProps.VALUE_TRUE;
      } else {
        questionVal.error = TextProps.VALUE_FALSE;
      }
    } else if (questionVal.type === "units") {
      let errorFlag = TextProps.VALUE_FALSE;
      for (var v = 0; v < Object.keys(questionVal.Answer).length; v++) {
        if (
          (questionVal.Answer.amount === "" ||
            questionVal.Answer.amount === null) &&
          (questionVal.Answer.unit === "" ||
            questionVal.Answer.unit === null) &&
          questionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          questionVal.error = TextProps.VALUE_TRUE;
          errorFlag = TextProps.VALUE_TRUE;
        } else if (
          (questionVal.Answer.amount === "" ||
            questionVal.Answer.amount === null) &&
          questionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          questionVal.error = TextProps.VALUE_TRUE;
          questionVal.errorMessage = questionVal.errorMessageIncm;
          errorFlag = TextProps.VALUE_TRUE;
        } else if (
          (questionVal.Answer.unit === "" ||
            questionVal.Answer.unit === null) &&
          questionVal.isRequired === TextProps.VALUE_TRUE
        ) {
          questionVal.error = TextProps.VALUE_TRUE;
          questionVal.errorMessage = questionVal.errorMessageFreq;
          errorFlag = TextProps.VALUE_TRUE;
        }
      }
      if (!errorFlag) {
        questionVal.error = TextProps.VALUE_FALSE;
      }
    }
  }

  const totalReqAnsers = [];
  if (questionsObj !== undefined) {
    for (let answerNotElected of questionsObj) {
      if (
        answerNotElected.Answer !== "" &&
        answerNotElected.error === TextProps.VALUE_FALSE
      ) {
        totalReqAnsers.push(answerNotElected.Answer);
      }
    }
  }
  if (questionsObj !== undefined && totalReqAnsers.length < 4)
    return TextProps.VALUE_FALSE;
  return TextProps.VALUE_TRUE;
}
