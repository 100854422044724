import React, { Component } from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import { bindActionCreators } from "redux";
import {
  getConsumerActivityList,
  hideSchedularPage,
  appointmentReschedulePostCall,
  showUrgentApmntAlert,
} from "../../../redux/consumer/consumerActivityActions";
import ControlWrapper from "../../../utils/components/controlWrappers/controlWrapper";
import Filter from "../../../utils/components/searchFilters/filter";
import ListWrapper from "../../../utils/components/list/listWrapper";
import SearchFilters from "../../../utils/components/searchFilters/searchFilters";
import * as Props from "../../../utils/components/shared";
import * as FiltersData from "../../../utils/constants/searchFilters";
import * as routeProps from "../../../utils/constants/routesConstants";
import { Link } from "react-router-dom";
import Icon from "../../../utils/components/dtaIcon";
import * as formatters from "../../../utils/components/utilities/formatters";
import DTAReschedulerDatePicker from "../../../utils/components/dtaReschedulerDatePicker";
import DTASelect from "../../../utils/components/dtaSelect";
import DTATextArea from "../../../utils/components/dtaTextArea";
import DTAInput from "../../../utils/components/dtaInput";
import DTAModal from "../../../utils/components/modal/dtaModal";
import Checkbox from "../../../utils/components/checkbox";
import { activityGetDescription, consumerConst } from "../consumerText";
import { DateUtils } from "react-day-picker";
import moment from "moment";
import * as TextProps from "../../../utils/constants/text";
import Loading from "../../../utils/components/loading";
import DaySelector from '../../../utils/components/daySelector'
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
// import * as helperFunction from "../../helperFunctions/helperFunctions";


const selectedLanguage =
helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class ConsumerActivity extends Component {
  constructor(props) {
    super(...arguments);
    this.state = {
      activityList: [],
      filteredActivityList: [],
      isFiltered: false,
      showDateError: TextProps.VALUE_FALSE,
      showTimeError: TextProps.VALUE_FALSE,
      phoneNumberError: TextProps.VALUE_FALSE,
      date: null,
      time: null,
      comment: "",
      cancelComment: "",
      successModalOpen: TextProps.VALUE_FALSE,
      errorMessage: TextProps.VALUE_TRUE,
      preferredPhoneNumber:"",
      selectedDays: null,
      initSelectedAMPM: null,
      dayValidError: TextProps.VALUE_FALSE,
      selectDayError: TextProps.VALUE_FALSE,
      DaySelectorError: TextProps.VALUE_FALSE,
      dayValue: "",
      selectedTimes: null,
      selectTimeError: TextProps.VALUE_FALSE,
      datesAvailable: [],
      appointmentScheduleId: "",
      availableTimeSlots: [],
      browserWidth: window.innerWidth,
      reasonSelect: '',
      reasonSelectError: TextProps.VALUE_FALSE,
      otherSelectError: TextProps.VALUE_FALSE,
      successModal:"",
      dateFilterError: false,
    };
    this._handleDateChangeForFrom = this._handleDateChangeForFrom.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { common } = this.props.language.consumer;
    const { consumer } = nextProps;
    var selectedDaysToDisable;
    var availableDatesSlot = [];
    if (
      consumer.aptSnapRescheduleSubmitted === TextProps.VALUE_TRUE ||
      consumer.aptCaseModelRescheduleSubmitted === TextProps.VALUE_TRUE
    ) {
      let successMsg;
      if (consumer.aptSnapRescheduleSubmitted === TextProps.VALUE_TRUE) {
        successMsg = common.aptrescheduleSuccessMsg;
      }
      else if (consumer.aptCaseModelRescheduleSubmitted) {
        const appointmentTypeCode = sessionStorage.getItem('appointmentTypeCode');
        successMsg = appointmentTypeCode === "PRVAPP" ? common.changeAptPRVAPPScuccessMsg : common.changeAptScuccessMsg;
        sessionStorage.removeItem('appointmentTypeCode');
      }
    
      this.setState({
        successModalOpen: TextProps.VALUE_TRUE,
      });
      setTimeout(() => {
        this.successMsgModal.showBlur("success", <div style={{marginBottom:'60px'}}>{successMsg}</div>);
      }, 100);

      setTimeout(() => {
        this.setState({ successModalOpen: TextProps.VALUE_TRUE });
        this.props.hideSchedularPage();
      }, 5000);

    } else if (consumer.rescheduleAptFailure === true) {
      this.setState({
        successModalOpen: TextProps.VALUE_TRUE,
      });
      this.successMsgModal.showBlur("error", <div style={{ marginBottom: '60px' }}><p>please try again later</p></div>);
      setTimeout(() => {
        window.location.assign("/consumer/activity");
      }, 3000);
    }
    if (consumer.appointmentAvailableSlots !== null) {
      selectedDaysToDisable = Object.keys(
        consumer.appointmentAvailableSlots
      ).filter((key) => key);
      selectedDaysToDisable.forEach((element) => {
        availableDatesSlot.push(new Date(element));
      });
      this.setState({ datesAvailable: availableDatesSlot });
    }
  }

  componentWillMount() {
    this.props.showUrgentApmntAlert();
  }

  componentDidMount() {
    //FETCH THE CONSUMER ACTIVITY LIST ON ACTIVITY TAB LOAD
    var activityInfo = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
    var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let userAuthToken = "";
    const agencyId =
      activityInfo !== undefined && activityInfo !== null
        ? activityInfo.clientInfo.agencyID
        : null;
    if (this.props.consumer.mfaDetails.userAuth !== undefined) {
      userAuthToken = this.props.consumer.mfaDetails.userAuth;
    } else if (userInfo !== null) {
      userAuthToken = userInfo.userAuth;
    } else {
      window.location.assign(routeProps.HOME_PATH);
    }
    if (
      this.props.consumer.consumerData.accountId !== undefined &&
      this.props.consumer.consumerData.accountId !== null &&
      this.props.consumer.consumerData.accountId
    ) {
      sessionStorage.setItem("activityTabResp", TextProps.VALUE_STR_TRUE);
      this.props.onConsumerActivityList(
        this.props.consumer.consumerData.accountId
      );
    } else if (
      this.props.consumer.consumerData.user !== undefined &&
      this.props.consumer.consumerData.user !== null &&
      this.props.consumer.consumerData.user.accountId
    ) {
      sessionStorage.setItem("activityTabResp", TextProps.VALUE_STR_TRUE);
      this.props.onConsumerActivityList(
        this.props.consumer.consumerData.user.accountId
      );
    } else if (agencyId !== null && agencyId !== undefined) {
      sessionStorage.setItem("activityTabResp", TextProps.VALUE_STR_TRUE);
      this.props.onConsumerActivityList(agencyId);
    }

    if(activityInfo){
      if(activityInfo.clientPhone){

        this.setState({
          preferredPhoneNumber: activityInfo.clientPhone.preferredPhoneNumber || ''
        })
      }
    }
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ browserWidth: window.innerWidth });
  };

  componentWillUpdate(currentProps, currentState) {
    //SET THE RESPONSE LIST TO THE CURRENT STATE
    if (currentProps.consumer.consumerActivityList !== undefined) {
      currentProps.consumer.consumerActivityList.data.alerts = null;
      currentState.activityList = [];
    }
    let activityTabResp = sessionStorage.getItem("activityTabResp");
    var activityInfo = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
    var alerts = [];

    if (
      activityTabResp === TextProps.VALUE_STR_FALSE &&
      currentProps.consumer.consumerActivityList !== undefined &&
      currentProps.consumer.consumerActivityList !== null &&
      currentProps.consumer.consumerActivityList.data &&
      currentProps.consumer.consumerActivityList.data.alerts
    ) {
      alerts = currentProps.consumer.consumerActivityList.data.alerts;
    } else if (activityInfo !== undefined && activityInfo !== null) {
      alerts = activityInfo.alerts;
    }
    if (
      alerts &&
      alerts.appointments !== undefined &&
      alerts.appointments !== null &&
      alerts.appointments.length > 0
    ) {
      currentState.activityList = alerts.appointments;
    }
    if (
      alerts &&
      alerts.recertifications !== undefined &&
      alerts.recertifications.length > 0
    ) {
      alerts.recertifications.map((recert) => {
        recert.activityType = "REVAL";
        recert.type = "event";
        currentState.activityList.push(recert);
      });
    }
  }

  // doLoadMore function fakes getting more results for list.
  // Can be done through appropriate production logic instead
  _doLoadMore = (stateKey, toInsert) => {
    var array = new Uint32Array(1);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.setState({
          [stateKey]: [
            ...this.state[stateKey],
            ...toInsert.map((item) => {
              return {
                ...item,
                id: `${window.crypto.getRandomValues(array)[0]}`,
              };
            }),
          ],
        });
        resolve();
      }, 2000);
    });
  };
  _selectHandler = (code) => {
    this.setState({
      reasonSelect: this.getSelect(code),
      reasonSelectError: TextProps.VALUE_FALSE
    })
  }
  getSelect = (code) => {
    switch (code) {
      case consumerConst(selectedLanguage).cancelRequestOptions[0]:
        return consumerConst(selectedLanguage).commonCONWAD
      case consumerConst(selectedLanguage).cancelRequestOptions[1]:
        return consumerConst(selectedLanguage).commonFLYEMG
      case consumerConst(selectedLanguage).cancelRequestOptions[2]:
        return consumerConst(selectedLanguage).commonSICKNS
      case consumerConst(selectedLanguage).cancelRequestOptions[3]:
        return consumerConst(selectedLanguage).commonWCREQA
      case consumerConst(selectedLanguage).cancelRequestOptions[4]:
        return consumerConst(selectedLanguage).commonLACKCC
      case consumerConst(selectedLanguage).cancelRequestOptions[5]:
        return consumerConst(selectedLanguage).commonLACKTS
      case consumerConst(selectedLanguage).cancelRequestOptions[6]:
        return consumerConst(selectedLanguage).commonOTHER
      default:
        return ''
    }
  }

  // phoneValidation = (phone) => {
  //   // console.log(phone ,"phone");
  //   return phone.length > 0 && phone.replace(/\D/g, "") 
   
  // }

  phoneValidation = (phone) => {
    if (phone.length === 0) {
        return "";
  }
    const editedValue = phone.replace(/\D/g, "");
    return editedValue; 
}


  submitForm = () => {
    const renderForm = this.props.consumer;
    var apmntDetails = JSON.parse(sessionStorage.getItem("apmntDetails"));
    var pvrID = JSON.parse(sessionStorage.getItem("apmntDetailPvrID"));
    var agencyID, apmntId, dueDate, appointmentTypeDesc;
    if (apmntDetails !== null) {
      apmntDetails.forEach((val) => {
        agencyID = val.agencyID;
        apmntId = val.id;
        dueDate = val.caseDueDate;
        appointmentTypeDesc = val.appointmentDescription;
      });
    }



    let dayArray = this.state.dayValues;
    let selectedAMPM = this.state.initSelectedAMPM
    for (let day in selectedAMPM) {
      if (!dayArray.includes(day)) {
        delete selectedAMPM[day];
      }
      }
      const amLabel = consumerConst(selectedLanguage).amLabelText; 
      const pmLabel = consumerConst(selectedLanguage).pmLabelText;

    const selectedDayObject = {};
    if (selectedAMPM && typeof selectedAMPM === 'object') {
      Object.keys(selectedAMPM).forEach(day => {
        selectedDayObject[`${day.toLowerCase()}Flag`] = selectedAMPM[day] ? true : false;
        selectedDayObject[`${day.toLowerCase()}AMFlag`] = selectedAMPM[day] && selectedAMPM[day][amLabel] ? true : false;
        selectedDayObject[`${day.toLowerCase()}PMFlag`] = selectedAMPM[day] && selectedAMPM[day][pmLabel] ? true : false;
        });
      }
    let len;
    let getSelectedLen; 
    if(selectedAMPM != undefined && selectedAMPM != null) {
      len = Object.keys(selectedAMPM).length
      getSelectedLen = Object.values(selectedAMPM).map((data) => {

        if (data[consumerConst(selectedLanguage).amLabelText] != undefined && data[consumerConst(selectedLanguage).amLabelText] != false) {
          return { ...data }
    }
        if (data[consumerConst(selectedLanguage).pmLabelText] != undefined && data[consumerConst(selectedLanguage).pmLabelText] != false) {
          return { ...data }
      }
  
      }).filter((data) => data != undefined)
      }
        


    if (selectedDayObject != null) {
      const { common } = this.props.language.consumer;

      this.setState({ DaySelectorError: TextProps.VALUE_FALSE,
        });

      const mondayStatus = selectedDayObject[ consumerConst(selectedLanguage).mondayFlagText] === true ? "Y" : 'N';
      const mondayAMStatus = selectedDayObject[consumerConst(selectedLanguage).mondayAMFlagText] === true ? "Y" : "N";
      const mondayPMStatus = selectedDayObject[consumerConst(selectedLanguage).mondayPMFlagText] === true ? "Y" : "N";
     
      
      const tuesdayStatus = selectedDayObject[consumerConst(selectedLanguage).tuesdayFlag] === true ? 'Y' : 'N'; 
      const tuesdayAMStatus = selectedDayObject[consumerConst(selectedLanguage).tuesdayAMFlag] === true ? "Y" : "N";
      const tuesdayPMStatus = selectedDayObject[consumerConst(selectedLanguage).tuesdayPMFlag] === true ? "Y" : "N";
      // wednesdayFlag wednesdayAMFlag wednesdayPMFlag
      const wednesdayStatus = selectedDayObject[consumerConst(selectedLanguage).wednesdayFlag] ? 'Y' : 'N';
      const wednesdayAMStatus = selectedDayObject[consumerConst(selectedLanguage).wednesdayAMFlag] === true ? "Y" : "N";
      const wednesdayPMStatus = selectedDayObject[consumerConst(selectedLanguage).wednesdayPMFlag] === true ? "Y" : "N";
      // thursdayFlag thursdayAMFlag thursdayPMFlag
      const thursdayStatus = selectedDayObject[consumerConst(selectedLanguage).thursdayFlag] ? 'Y' : 'N';
      const thursdayAMStatus = selectedDayObject[consumerConst(selectedLanguage).thursdayAMFlag] === true ? "Y" : "N";
      const thursdayPMStatus = selectedDayObject[consumerConst(selectedLanguage).thursdayPMFlag] === true ? "Y" : "N";
      // fridayFlag fridayAMFlag fridayPMFlag
      const fridayStatus = selectedDayObject[consumerConst(selectedLanguage).fridayFlag] ? 'Y' : 'N';
      const fridayAMStatus = selectedDayObject[consumerConst(selectedLanguage).fridayAMFlag] === true ? "Y" : "N";
      const fridayPMStatus = selectedDayObject[consumerConst(selectedLanguage).fridayPMFlag] === true ? "Y" : "N";


        let payload = {
          agencyID: agencyID,
        // clientSchApptID: apmntId,
          scheduledAppointmentID: apmntId,
        mondayFlag: mondayStatus,
        tuesdayFlag: tuesdayStatus,
        wednesdayFlag: wednesdayStatus,
        thursdayFlag: thursdayStatus,
        fridayFlag: fridayStatus,
        amFlag: "",
        pmFlag: "",
        mondayAMFlag: mondayAMStatus,
        tuesdayAMFlag: tuesdayAMStatus,
        wednesdayAMFlag: wednesdayAMStatus,
        thursdayAMFlag: thursdayAMStatus,
        fridayAMFlag: fridayAMStatus,
        mondayPMFlag: mondayPMStatus,
        tuesdayPMFlag: tuesdayPMStatus,
        wednesdayPMFlag: wednesdayPMStatus,
        thursdayPMFlag: thursdayPMStatus,
        fridayPMFlag: fridayPMStatus,
        rescheduleReasonCode: this.state.reasonSelect,
        comments: this.state.cancelComment,
          appointmentTypeDsc: appointmentTypeDesc,
          preferredPhoneNumber: this.phoneValidation(this.state.preferredPhoneNumber),
          pvrId:pvrID
        };
      if ((selectedAMPM == undefined || selectedAMPM == "" )||( dayArray == "" || dayArray == undefined)) {
        this.setState({ DaySelectorError: TextProps.VALUE_TRUE });
        this.setState({ dayValidError: TextProps.VALUE_TRUE });
      }
           else if (this.state.reasonSelect === "" || this.state.reasonSelect === undefined) {
        this.setState({ reasonSelectError: TextProps.VALUE_TRUE });
      }
      else if (this.state.reasonSelect === common.commonOTHER && this.state.cancelComment != "") {
        this.setState({ otherSelectError: TextProps.VALUE_FALSE })
        this.props.appointmentReschedulePostCall(payload, "CASE_MODEL");
        setTimeout(() => {
          this.setState({ selectedDays: null, selectedTimes: null });
        }, 6000);

      }
      // final check
      else if (getSelectedLen.length !== dayArray.length) {
        this.setState({ DaySelectorError: TextProps.VALUE_TRUE });
        this.setState({ dayValidError: TextProps.VALUE_TRUE })
      }
      else if (this.state.reasonSelect === common.commonOTHER) {
        this.setState({ otherSelectError: TextProps.VALUE_TRUE })
      }

      else {
        this.setState({ DaySelectorError: TextProps.VALUE_FALSE });
        this.setState({ reasonSelectError: TextProps.VALUE_FALSE });
        this.setState({ otherSelectError: TextProps.VALUE_FALSE });
        this.setState({ dayValidError: TextProps.VALUE_FALSE });
        this.setState({ phoneNumberError: TextProps.VALUE_FALSE });
        this.props.appointmentReschedulePostCall(payload, "CASE_MODEL",selectedLanguage,);
        setTimeout(() => {
          this.setState({ selectedDays: null, selectedTimes: null });
        }, 6000);
      }
      if ((selectedAMPM == undefined || selectedAMPM == "" || dayArray == "" || dayArray == undefined) || (this.state.reasonSelect === "" || this.state.reasonSelect === undefined)) {
        if (selectedAMPM == undefined || selectedAMPM == "" || dayArray == "" || dayArray == undefined) {
          this.setState({ DaySelectorError: TextProps.VALUE_TRUE });
          this.setState({ dayValidError: TextProps.VALUE_TRUE })
      }
        if (this.state.reasonSelect === "" || this.state.reasonSelect === undefined) {
          this.setState({ reasonSelectError: TextProps.VALUE_TRUE });

        }    
     }
   
    if(this.phoneValidation(this.state.preferredPhoneNumber).length < 10 && this.phoneValidation(this.state.preferredPhoneNumber).length > 0){
      this.setState({ phoneNumberError: TextProps.VALUE_TRUE });
     }else if(this.state.preferredPhoneNumber === ""){
      this.setState({ phoneNumberError: TextProps.VALUE_FALSE });
     }
    }
  };

  _handleDateChangeForFrom = (value) => {
    let selectedDate = value;
    const rescheduleDate = formatters.appointmentDateFormat(value);
    const dateSelectedArray =
      this.props.consumer.appointmentAvailableSlots[
        formatters.MonthDateYearFormat(selectedDate)
      ];
    this.setState({
      availableTimeSlots: dateSelectedArray,
      date: rescheduleDate,
      showDateError: TextProps.VALUE_FALSE,
    });
  };
  _handleChangeForPrefPhoneNumber = (value) => {
    this.setState({
      preferredPhoneNumber: value,
      phoneNumberError: TextProps.VALUE_FALSE,
    })
  }

  _handleChangeForTime = (value) => {
    this.setState({ time: value, showTimeError: TextProps.VALUE_FALSE });
  };
  _commentHandler = (value) => {
        const { common } = this.props.language.consumer;
    this.setState({ comment: value });
        this.state.reasonSelect === common.commonOTHER && this.state.reasonSelect == "" ? this.setState({
          cancelCommentError: TextProps.VALUE_TRUE,
          otherSelectError: TextProps.VALUE_TRUE
        }) : this.setState({
          cancelCommentError: TextProps.VALUE_FALSE
        })
        this.setState({
          cancelComment: value,
        })
  };
  selectTimeHandler = (value) => {
    const { common } = this.props.language.consumer;
    var time = [];
    for (let val of value) {
      for (const [j, timeOptionVal] of common.timeOptions.entries()) {
        if (timeOptionVal === val) {
          if (j === 0) {
            time.push("AM");
          } else if (j === 1) {
            time.push("PM");
          }
        }
      }
    }
    if (time.length > 0) {
      this.setState({ selectTimeError: TextProps.VALUE_FALSE });
    }
    this.setState({ selectedTimes: time });
  };
  selectDayHandler = (value) => {
    const { common } = this.props.language.consumer;
    this.setState({
      dayValue: value,
    })
    var day = [];
    for (let val of value) {
      for (const [j, dayOptionVal] of common.dayOptions.entries()) {
        if (dayOptionVal === val) {
          if (j === 0) {
            day.push("Monday");
          } else if (j === 1) {
            day.push("Tuesday");
          } else if (j === 2) {
            day.push("Wednesday");
          } else if (j === 3) {
            day.push("Thursday");
          } else if (j === 4) {
            day.push("Friday");
          }
        }
      }
    }
    if (day.length > 0) {
      this.setState({ selectDayError: TextProps.VALUE_FALSE });
    }
    this.setState({ selectedDays: day });
  };
  selectedCheckDay = (dayArray) => {
    this.setState({ dayValues: dayArray })
  }

  selectedAMPMValue = (selectedAMPM) => {
    if (this.state.initSelectedAMPM != "") {
      this.setState({
        dayValidError: TextProps.VALUE_FALSE,
        DaySelectorError: TextProps.VALUE_FALSE
      })
    }
    this.setState({ initSelectedAMPM: selectedAMPM })
  }

  closeSuccessModal = () => {
    this.setState({
      successModalOpen: TextProps.VALUE_FALSE,
    });
  };

  cancelHandler = () => {
    this.setState({
      showDateError: TextProps.VALUE_FALSE,
      showTimeError: TextProps.VALUE_FALSE,
      selectDayError: TextProps.VALUE_FALSE,
      selectTimeError: TextProps.VALUE_FALSE,
      phoneNumberError: TextProps.VALUE_FALSE,
      date: null,
      time: null,
      selectedDays: null,
      selectedTimes: null,
    });
    this.props.hideSchedularPage();
  };

  isDayDisabled = (day) => {
    return !this.state.datesAvailable.some((disabledDay) =>
      DateUtils.isSameDay(day, disabledDay)
    );
  };

  clearFilter = () => {
    this.setState({
      filteredActivityList: [],
      isFiltered: false
    })
  }

  filterAppointmentByDate = (valueFrom, ValueTo) => {
    const appointmentList = this.state.activityList;
    let filteredData = [];
    if(!valueFrom || !ValueTo){
      filteredData = appointmentList;
      this.setState({dateFilterError:true});
    }else{
      this.setState({dateFilterError:false});
      const dateFrom = moment(valueFrom).startOf('day').unix();
      const dateTo = moment(ValueTo).endOf('day').unix();
      appointmentList.map(item => {
        if(item.startTime && item.endTime){
          const startDateinSec = new Date(item.startTime).getTime()/1000;
          const endDateinSec = new Date(item.endTime).getTime()/1000;
          if(dateFrom < startDateinSec && dateTo > endDateinSec){
            filteredData.push(item);
          }
        }
      })
    }    
    this.setState({
      filteredActivityList: filteredData,
      isFiltered: true
    })
  }

  render() {
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    if (selectedLanguage === null) {
      selectedLanguage = "en-US";
    }
    const {
      time,
      showDateError,
      showTimeError,
      selectDayError,
      daySelectorError,
      selectTimeError,
      phoneNumberError,
      availableTimeSlots,
      preferredPhoneNumber,
      filteredActivityList,
      isFiltered
    } = this.state;
    const showAptContent = this.props.consumer.showSchedularPage;
    const showAptForm = this.props.consumer;
    const { common, documentupload, info } = this.props.language.consumer;
    const isLoading = this.props.consumer.isLoading;
    let apmntDetails = JSON.parse(sessionStorage.getItem("apmntDetails"));
    let clientPhone = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
    let phoneNum ,worker, typeCode,appointmentTypeDesc;
    if (apmntDetails !== null) {
      apmntDetails.forEach((val) => {
        worker = val.caseManagerName.first + " " + val.caseManagerName.last;
        typeCode = val.typeCode;
        appointmentTypeDesc = val.appointmentDescription;
      });
    }

    if (
      clientPhone &&
      clientPhone.clientPhone &&
      clientPhone.clientPhone.phoneNumber
    ) {
      phoneNum = clientPhone.clientPhone.phoneNumber;
    }

    let selectedTime = sessionStorage.getItem("selectFilter");
    if (this.state.availableTimeSlots.length > 0) {
      let availableTimeSlotVal = this.state.availableTimeSlots.find((val) =>
        val === selectedTime ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE
      );
      if (availableTimeSlotVal === undefined) {
        sessionStorage.setItem("selectFilter", JSON.stringify([]));
        this.setState({
          time: null
        })
      } else {
        sessionStorage.setItem("selectFilter", this.state.time);
        this.setState({
          time: selectedTime
        })
      }
    }

    // browser responsiveness
    let width = "2";
    var modalClass = "";
    if (this.state.browserWidth < 400) {
      width = "1";
    }
    if (this.state.browserWidth <= 240) {
      modalClass = "apmnt-xxsmall";
    } else if (this.state.browserWidth <= 768) {
      modalClass = "apmnt-small";
    } else {
      modalClass = "apmnt-large";
    }

    var activityPastArray = [];
    var activityUpcomingArray = [];
    if(filteredActivityList.length > 0 || isFiltered)
    {
      filteredActivityList.forEach((data) => {
        var endTime = "";
        if (data.dueDate !== undefined) {
          endTime = data.dueDate;
        } else if (data.endTime !== undefined) {
          endTime = moment(data.endTime).unix();
        }
        var today = moment().unix();
        if (endTime < today || data.appointmentChangeRequestStatusCode === "CANCEL") {
          activityPastArray.push({
            ...data,
            changeAllowedFlg: 'N'
          });
        } else {
          //invalid date
          activityUpcomingArray.push(data);
        }
      });
    }
    else if (filteredActivityList.length == 0 && this.state.activityList.length > 0) {
      this.state.activityList.forEach((data) => {
        var endTime = "";
        if (data.dueDate !== undefined) {
          endTime = data.dueDate;
        } else if (data.endTime !== undefined) {
          endTime = moment(data.endTime).unix();
        }
        var today = moment().unix();
        if (endTime < today || data.appointmentChangeRequestStatusCode === "CANCEL") {
          activityPastArray.push({
            ...data,
            changeAllowedFlg: 'N'
          });
        } else {
          //invalid date
          activityUpcomingArray.push(data);
        }
      });
    }

    if (isLoading) {
      return <Loading />;
    }
    return (
      <div className="max-width-myinfo max-width--three-quarters pad-all--double">
        {!showAptContent ? (
          <div>
            <SearchFilters
              search={
                <ControlWrapper
                  type={FiltersData.SEARCH[Props.TYPE]}
                  data={FiltersData.SEARCH}
                />
              }
              clearFilter={this.clearFilter}
            >
              <Filter
                className="pure-u-3-5 dta-filter--no-grow"
                data={FiltersData.FILTER_DATE_ACTIVITY}
                filterAppointmentByDate={this.filterAppointmentByDate}
                error={this.state.dateFilterError}
              />
            </SearchFilters>

            <h1 className="text--gray consumer-title">
              {consumerConst(selectedLanguage).upcomingActivities}
            </h1>
            <ListWrapper
              total={100}
              doLoadMore={this._doLoadMore.bind(
                this,
                "activityList",
                activityUpcomingArray
              )}
              items={activityUpcomingArray}
              screenName="activityList"
            />

            <p className="text--gray consumer-title">
              {consumerConst(selectedLanguage).pastActivities}
            </p>
            <ListWrapper
              total={100}
              doLoadMore={this._doLoadMore.bind(
                this,
                "activityList",
                activityPastArray
              )}
              items={activityPastArray}
              screenName="activityList"
            />
          </div>
        ) : (
          <div className="appointment">
            <Link
              to="/activity"
              onClick={this.cancelHandler}
              className="dta-button dta-button--outline-secondary dta-button--text-with-icon benefit-detail__back-button"
            >
              <Icon name="arrow-back" ariaHidden={TextProps.VALUE_TRUE} />{" "}
              {info.back}{" "}
            </Link>
            <div
              className="appointmentForm"
              style={{ justifyContent: "center", display: "flex" }}
            >
              <div className="pure-g pure-u-lg-1-1">
                <div className="pure-u-3 text--center">
                  <h1>{common.requestForChange}</h1>
                </div>
              </div>
            </div>
            <hr className="divider" />
            <div className="appointmentForm">
              <div className="pure-g pure-u-lg-1-2">
                <div className="pure-u-1">
                  <label className="dta-form__label" for="appointmentType">{common.apmntType}</label>
                  <DTAInput
                    className="dta-form__control"
                    id="appointmentType"
                    disabled
                    value={typeCode === "PRVAPP" ? appointmentTypeDesc : activityGetDescription("appnmt_code_" + typeCode)}
                  />
                </div>
                {/* {!showAptForm.showSchedularSnapPage && (
                  <div className="pure-u-1">
                    <label className="dta-form__label">{common.worker}</label>
                    <DTAInput
                      className="dta-form__control"
                      disabled
                      value={worker}
                    />
                  </div>
                )} */}
                <div className="pure-u-1">
                  <label className="dta-form__label" for="phoneNumber">{common.phone}</label>
                  <DTAInput
                    className="dta-form__control"
                    id="phoneNumber"
                    disabled
                    value={formatters.formatPhoneNumber(phoneNum)}
                  />
                </div>
                <div className="pure-u-1">
                  <label className="dta-form__label" for="preferredPhnNum">{common.preferredPhoneNumber}</label>
                  {phoneNumberError && (
                    <div>
                      <div className="peat-case-error-msg">
                        <span className="dta-form__error-message">
                          {common.phoneNumberError}
                        </span>
                      </div>
                    </div>
                  )}
                  <DTAInput
                    className="dta-form__control"
                    id="preferredPhnNum"
                    value={preferredPhoneNumber}
                    type="tel"
                    error={phoneNumberError}
                    onChange={this._handleChangeForPrefPhoneNumber}
                  />
                </div>
                {showAptForm.showSchedularSnapPage ? (
                  <div className="pure-u-1">
                    <div className="pure-u-1">
                      <label className="dta-form__label">
                        {common.availableDate}
                      </label>
                      {showDateError && (
                        <div>
                          <div className="peat-case-error-msg">
                            <span className="dta-form__error-message">
                              {common.selectDate}
                            </span>
                          </div>
                        </div>
                      )}
                      <DTAReschedulerDatePicker
                        name={`appointment_day_picker`}
                        dropdownIcon="calendar"
                        onChange={this._handleDateChangeForFrom}
                        disabled={TextProps.VALUE_FALSE}
                        error={showDateError}
                        required={this.state.IsDateCheckRequired}
                        closeOnChange={TextProps.VALUE_TRUE}
                        emptyLabel
                        disabledDays={[
                          { daysOfWeek: [0, 6] },
                          this.isDayDisabled,
                          { before: new Date() },
                        ]}
                      />
                    </div>
                    <div className="pure-u-1">
                      <label className="dta-form__label">
                        {common.availableTime}
                      </label>
                      {showTimeError && (
                        <div>
                          <div className="peat-case-error-msg">
                            <span className="dta-form__error-message">
                              {common.selectTime}
                            </span>
                          </div>
                        </div>
                      )}
                      <DTASelect
                        name={"appointment_time_dropdown"}
                        options={availableTimeSlots}
                        className="dta-form__control dta-select--expand-width"
                        id="appointment_time_dropdown"
                        aria-labelledby="appointment_time_dropdown"
                        onChange={this._handleChangeForTime}
                        value={time}
                        error={showTimeError}
                        disabled={TextProps.VALUE_FALSE}
                        required={TextProps.VALUE_TRUE}
                      />
                    </div>
                  </div>
                ) : (
                    <div className="pure-u-1">
                      <label className="dta-form__label">
                  {common.selectDateTime}
                      </label><br />
                      <p style={{fontWeight:"bolder",marginTop:"-20px"}}>{common.ALDHRWorkerLabel}</p>
                  {this.state.DaySelectorError && (
                          <div className="peat-case-error-msg">
                            <span className="dta-form__error-message">
                        {common.daySelectorError}
                            </span>
                          </div>
                      )}
                  <DaySelector
                    onSelectedDay={this.selectedCheckDay}
                    onChange={this.selectedAMPMValue}
                    value={this.state.dayValue}
                    error={this.state.DaySelectorError}
                    dayValidError={this.state.dayValidError}
                      />
                    </div>
              )}
              <div className="pure-u-1" style={{ marginTop: '6%' }}>
                      <label className="dta-form__label" for="cancel">
                  {common.reasonSelect}
                      </label>
                {this.state.reasonSelectError && (
                        <div>
                          <div className="peat-case-error-msg">
                            <span className="dta-form__error-message">
                        {common.reasonError}
                            </span>
                          </div>
                        </div>
                      )}
                <DTASelect
                  name={"cancel"}
                  options={common.cancelRequestOptions}
                  className="dta-form__control dta-select--expand-width"
                  id="cancel"
                  // aria-labelledby={this.lastUniqueId()}
                  value={this.state.reasonSelect}
                  onChange={this._selectHandler}
                  error={this.state.reasonSelectError}
                      />
                    </div>
              <div className="pure-u-1">
                <label className="dta-form__label" for="cmntForCancel">{this.state.reasonSelect === common.commonOTHER ?
                  common.cmntForCancelRequired : common.cmntForCancel}</label>
                {this.state.otherSelectError && (
                  <div>
                    <div className="peat-case-error-msg">
                      <span className="dta-form__error-message">
                        {common.otherReasonSelectError}
                      </span>
                    </div>
                  </div>
                )}
                  <DTATextArea
                    className="dta-form__control"
                    id="cmntForCancel"
                    placeholder={common.cmntPlaceholder}
                    error={this.state.otherSelectError}
                    value={this.state.cancelComment}
                    disabled={TextProps.VALUE_FALSE}
                    required={TextProps.VALUE_TRUE}
                    maxLength={250}
                    onChange={this._commentHandler}
                  />
                </div>
                <div className="dta-modal__footer--inline-buttons pure-u-1">
                  <button
                    className="dta-button dta-button--large dta-button--outline-primary"
                    onClick={this.cancelHandler}
                  >
                    {" "}
                    {documentupload.cancelButton}{" "}
                  </button>
                  <button
                    className="dta-button dta-button--large dta-button--primary"
                    id="apt_btn"
                    onClick={this.submitForm}
                  >
                    {" "}
                    {info.submit}{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <DTAModal
          ref={(instance) => (this.successMsgModal = instance)}
          isOpen={this.state.successModalOpen}
          onClose={this.closeSuccessModal}
          modalClass={`rivl-modal ${modalClass}`}
          titleText="appointment-reschedule-modal"
          hideCloseIcon={this.state.hideCloseIcon}
          error={this.state.errorMessage}
        >
          {!this.state.errorMessage ? (
            <div className="dta-modal__body pad-all mh-provider-success">
              <button className="hide-close-btn"></button>
            </div>
          ) : (
            <div className="pure-g">
              <div className="pure-u-5-5 peat-case-error-msg mh-update-error mar-btm">
                <button className="hide-close-btn close-btn"></button>
              </div>
            </div>
          )}
        </DTAModal>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onConsumerActivityList: getConsumerActivityList,
      hideSchedularPage: hideSchedularPage,
      appointmentReschedulePostCall: appointmentReschedulePostCall,
      showUrgentApmntAlert: showUrgentApmntAlert,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerActivity);