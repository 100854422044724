import PropTypes from "prop-types";
import * as TextProps from "../../utils/constants/text";

//New Approach -
export function bindAnswers(section, activeId, AnswerObj) {
  section.questions.map((question) => {
    if (AnswerObj[activeId][question.name] !== undefined) {
      question.Answer = AnswerObj[activeId][question.name];
      question.targetValue = AnswerObj[activeId][question.name];
      //Conditions
      if (question.conditionals) {
        question.conditionals.map((condition) => {
          //Question Itration
          condition.conditionalQuestions.map((conditionQuestion) => {
            if (AnswerObj[activeId][conditionQuestion.name] !== undefined) {
              conditionQuestion.Answer =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.targetValue =
                AnswerObj[activeId][conditionQuestion.name];
            } else {
              conditionQuestion.Answer = "";
              conditionQuestion.targetValue = "";
            }
          });
        });
      }
    } else {
      if (question.Answer === undefined) {
        question.Answer = "";
        question.targetValue = "";
      }
    }
  });
  return section;
}

export function toValidateInputErros(sections, activeId) {
  let forceInvalid = TextProps.VALUE_FALSE;
  if (sections !== null) {
    const inputSections = sections.questions;
    if (inputSections !== undefined) {
      if (activeId === "replacepebt") {
        forceInvalid = buildApplyAboutMeValidations(inputSections);
      }
    }
  }
  // total required fields selected or not
  const totalReqFields = [];
  if (sections !== undefined) {
    const dataID = sections.id;
    const dataSectionsCopy = sections.questions;
    for (let dataSections of dataSectionsCopy) {
      if (
        dataSections.type === "date" &&
        (dataSections.error === TextProps.VALUE_TRUE ||
          dataSections.Answer !== "")
      ) {
        totalReqFields.push(dataSections.Answer);
      } else if (
        dataSections.type === "inputSSN" &&
        (dataSections.error === TextProps.VALUE_TRUE ||
          dataSections.Answer !== "")
      ) {
        totalReqFields.push(dataSections.Answer);
      } else if (
        dataSections.type === "inputPhone" &&
        (dataSections.error === TextProps.VALUE_TRUE ||
          dataSections.Answer !== "")
      ) {
        totalReqFields.push(dataSections.Answer);
      } else if (
        dataSections.type === "inputText" &&
        (dataSections.error === TextProps.VALUE_TRUE ||
          dataSections.Answer !== "") &&
        dataSections.name === "emailAddress"
      ) {
        totalReqFields.push(dataSections.Answer);
      } else if (
        dataSections.type === "address" &&
        (dataSections.error === TextProps.VALUE_TRUE ||
          dataSections.Answer !== "") &&
        dataSections.name === "address"
      ) {
        totalReqFields.push(dataSections.Answer);
      } else if (dataSections.isRequired === TextProps.VALUE_TRUE) {
        totalReqFields.push(dataSections.Answer);
      } else if (
        dataID.substring(0, 27) === "kids-adult-multiple-address" ||
        dataID.substring(0, 15) === "medical-address"
      ) {
        if (
          dataSections.type === "address" &&
          dataSections.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(dataSections.Answer);
        }
        if (
          dataSections.type === "units" &&
          dataSections.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(dataSections.Answer);
        }
      } else if (dataID === "contact-info") {
        var answerNotElectedMailingAddress =
          dataSections[4].conditionals[0].conditionalQuestions[0];
        if (
          answerNotElectedMailingAddress.type === "address" &&
          answerNotElectedMailingAddress.error === TextProps.VALUE_TRUE
        ) {
          totalReqFields.push(answerNotElectedMailingAddress.Answer);
        }
      } else if (
        dataSections.type === "address" &&
        (dataSections.error === TextProps.VALUE_TRUE ||
          dataSections.Answer !== "")
      ) {
        totalReqFields.push(dataSections.Answer);
      } else if (
        (dataSections.type === "inputText" ||
          dataSections.type === "inputAlphaText") &&
        dataSections.error === TextProps.VALUE_TRUE
      ) {
        totalReqFields.push(dataSections.Answer);
      }
    }
  }
  // total required fields questions answers has provide or not
  const totalAnwsers = [];
  if (sections !== undefined) {
    for (let dataSections of sections.questions) {
      if (
        (dataSections.type === "date" &&
          dataSections.Answer !== "" &&
          dataSections.error === TextProps.VALUE_FALSE) ||
        (dataSections.type === "inputSSN" &&
          dataSections.Answer !== "" &&
          dataSections.error === TextProps.VALUE_FALSE) ||
        (dataSections.type === "inputPhone" &&
          dataSections.Answer !== "" &&
          dataSections.error === TextProps.VALUE_FALSE) ||
        (dataSections.type === "inputText" &&
          dataSections.Answer !== "" &&
          dataSections.error === TextProps.VALUE_FALSE &&
          dataSections.name === "emailAddress") ||
        (dataSections.type === "address" &&
          dataSections.Answer !== "" &&
          dataSections.error === TextProps.VALUE_FALSE &&
          dataSections.name === "address") ||
        (dataSections.type !== "address" &&
          dataSections.Answer !== "" &&
          dataSections.error === TextProps.VALUE_FALSE &&
          dataSections.isRequired === TextProps.VALUE_TRUE)
      ) {
        totalAnwsers.push(dataSections.Answer);
      }
    }
  }
  // checking required fields and total touched fileds
  if (
    forceInvalid ||
    (sections !== undefined && totalReqFields.length !== totalAnwsers.length)
  )
    return TextProps.VALUE_FALSE;
  return TextProps.VALUE_TRUE;
}

function buildApplyAboutMeValidations(dataSections) {
  var forceFlag = TextProps.VALUE_FALSE;
  if (dataSections) {
    for (let dataSectionsCopy of dataSections) {
      if (dataSectionsCopy.type === "select") {
        if (
          dataSectionsCopy.Answer.length < 1 &&
          dataSectionsCopy.Answer !== null &&
          dataSectionsCopy.isRequired === TextProps.VALUE_TRUE
        ) {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;
        } else {
          dataSectionsCopy.error = TextProps.VALUE_FALSE;
        }
      } else if (
        dataSectionsCopy.type === "inputText" ||
        dataSectionsCopy.type === "inputAlphaText"
      ) {
        let errorFlag = TextProps.VALUE_FALSE;
        var rexAnyNumber = /\d+/;
        var rexAnySpace = /^(?=.*\S).+$/;
        if (
          dataSectionsCopy.Answer.length >= 0 &&
          dataSectionsCopy.isRequired === TextProps.VALUE_TRUE
        ) {
          if (
            rexAnyNumber.test(dataSectionsCopy.Answer) ===
              TextProps.VALUE_TRUE ||
            rexAnySpace.test(dataSectionsCopy.Answer) === TextProps.VALUE_FALSE
          ) {
            dataSectionsCopy.error = TextProps.VALUE_TRUE;

            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (
          dataSectionsCopy.Answer.length > 0 &&
          dataSectionsCopy.isRequired === TextProps.VALUE_FALSE
        ) {
          if (
            rexAnyNumber.test(dataSectionsCopy.Answer) ===
              TextProps.VALUE_TRUE ||
            rexAnySpace.test(dataSectionsCopy.Answer) === TextProps.VALUE_FALSE
          ) {
            dataSectionsCopy.error = TextProps.VALUE_TRUE;

            errorFlag = TextProps.VALUE_TRUE;
          }
        }
        if (!errorFlag) {
          dataSectionsCopy.error = TextProps.VALUE_FALSE;
        }
      } else if (dataSectionsCopy.type === "date") {
        if (PropTypes.date_error === 1) {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;
        } else if (
          (dataSectionsCopy.Answer === "" ||
            dataSectionsCopy.Answer === null) &&
          dataSectionsCopy.isRequired === TextProps.VALUE_TRUE
        ) {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;
        } else if (
          (dataSectionsCopy.Answer !== "" ||
            dataSectionsCopy.Answer !== null) &&
          dataSectionsCopy.isRequired === TextProps.VALUE_TRUE
        ) {
          var inputDate = dataSectionsCopy.Answer;
          if (inputDate.getTime() > new Date().getTime()) {
            dataSectionsCopy.error = TextProps.VALUE_TRUE;
          } else {
            dataSectionsCopy.error = TextProps.VALUE_FALSE;
          }
        } else {
          dataSectionsCopy.error = TextProps.VALUE_FALSE;
        }
      } else if (dataSectionsCopy.type === "inputPhone") {
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (
          dataSectionsCopy.Answer === undefined ||
          dataSectionsCopy.Answer === "" ||
          phoneno.test(dataSectionsCopy.Answer) === TextProps.VALUE_FALSE
        ) {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;
          dataSectionsCopy.Answer = "";
        } else {
          dataSectionsCopy.error = TextProps.VALUE_FALSE;
        }
      } else if (
        (dataSectionsCopy.type === "select" &&
          dataSectionsCopy.name === "language-preference") ||
        (dataSectionsCopy.type === "radio" &&
          dataSectionsCopy.name === "signupalert")
      ) {
        if (
          dataSectionsCopy.Answer === "" &&
          dataSectionsCopy.isRequired === TextProps.VALUE_TRUE
        ) {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;
        } else {
          dataSectionsCopy.error = TextProps.VALUE_FALSE;
        }
      } else if (
        dataSectionsCopy.conditionals !== undefined &&
        dataSectionsCopy.conditionals !== ""
      ) {
        if (
          dataSectionsCopy.conditionals[0].conditionalQuestions[0] !==
            undefined &&
          dataSectionsCopy.conditionals[0].conditionalQuestions[0] !== ""
        ) {
          if (
            dataSectionsCopy.conditionals[0].conditionalQuestions[0].type ===
            "date"
          ) {
            if (PropTypes.date_error === 1) {
              dataSectionsCopy.conditionals[0].conditionalQuestions[0].error =
                TextProps.VALUE_TRUE;
            } else if (
              (dataSectionsCopy.conditionals[0].conditionalQuestions[0]
                .Answer === "" ||
                dataSectionsCopy.conditionals[0].conditionalQuestions[0]
                  .Answer === null) &&
              dataSectionsCopy.conditionals[0].conditionalQuestions[0]
                .isRequired === TextProps.VALUE_TRUE
            ) {
              dataSectionsCopy.conditionals[0].conditionalQuestions[0].error =
                TextProps.VALUE_TRUE;
            } else if (
              dataSectionsCopy.conditionals[0].conditionalQuestions[0]
                .targetValue &&
              dataSectionsCopy.conditionals[0].conditionalQuestions[0]
                .isRequired === TextProps.VALUE_TRUE
            ) {
              var dateInput =
                dataSectionsCopy.conditionals[0].conditionalQuestions[0]
                  .targetValue;
              if (dateInput.getTime() > new Date().getTime()) {
                dataSectionsCopy.conditionals[0].conditionalQuestions[0].error =
                  TextProps.VALUE_TRUE;
                dataSectionsCopy.conditionals[0].conditionalQuestions[0].errorMessage =
                  dataSectionsCopy.errorMessage;
              } else if (
                dataSections[4].Answer !== "" &&
                dataSections[4].Answer !== undefined &&
                dataSections[16].conditionals[0].conditionalQuestions[0]
                  .Answer !== "" &&
                dataSections[16].conditionals[0].conditionalQuestions[0]
                  .Answer !== undefined
              ) {
                if (
                  dataSections[4].Answer >
                  dataSections[16].conditionals[0].conditionalQuestions[0]
                    .Answer
                ) {
                  dataSections[16].conditionals[0].conditionalQuestions[0].error =
                    TextProps.VALUE_TRUE;
                  dataSections[16].conditionals[0].conditionalQuestions[0].errorMessage =
                    dataSections[16].conditionals[0].conditionalQuestions[0].errorlastEmployment;
                }
              }
            }
          }
        }
      }
      if (dataSectionsCopy.name === "emailAddress") {
        const pattern =
          /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (
          pattern.test(dataSectionsCopy.Answer) === TextProps.VALUE_FALSE &&
          dataSectionsCopy.Answer !== ""
        ) {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;
        } else {
          dataSectionsCopy.error = TextProps.VALUE_FALSE;
        }
      }
      if (dataSectionsCopy.name === "childcasenumber") {
        if (
          dataSectionsCopy.Answer != undefined &&
          dataSectionsCopy.Answer != "" &&
          dataSectionsCopy.Answer.length < 9
        ) {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;

          forceFlag = TextProps.VALUE_TRUE;
        }
      }
      if (dataSectionsCopy.name === "ssn") {
        const pattern = /^[0-9]{9}$/;
        if (
          dataSectionsCopy.Answer &&
          pattern.test(dataSectionsCopy.Answer) === TextProps.VALUE_FALSE
        ) {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;
          dataSectionsCopy.Answer = "";

          forceFlag = TextProps.VALUE_TRUE;
        } else {
          dataSectionsCopy.error = TextProps.VALUE_FALSE;
        }
      } else if (dataSectionsCopy.name === "address") {
        if (
          dataSectionsCopy.Answer === "" ||
          dataSectionsCopy.Answer.street === "" ||
          dataSectionsCopy.Answer.state === ""
        ) {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;
          dataSectionsCopy.errorMessage = dataSectionsCopy.addressErrmsg;
        } else if (
          dataSectionsCopy.Answer.zipCode === "" ||
          dataSectionsCopy.Answer.zipCode.length < 5
        ) {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;
          dataSectionsCopy.errorMessage = dataSectionsCopy.zipCodeErrmsg;
        } else if (dataSectionsCopy.Answer.city === "") {
          dataSectionsCopy.error = TextProps.VALUE_TRUE;
          dataSectionsCopy.errorMessage = dataSectionsCopy.citiErrmsg;
        } else {
          dataSectionsCopy.error = TextProps.VALUE_FALSE;
        }
      }
    }
  }
  return forceFlag;
}
