import React, { Component } from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/rootReducer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  addStringIf,
  tryCall,
  validateAria,
  isNone,
} from "../components/utilities/controls";
import { getDocumentTypeCode } from "../components/utilities/utils";
import { CommonTypes, CommonDefaults } from "./propTypes";
import {
  getConsumerDocumentList,
  getConsumerSelectedDocumentList,
} from "../../redux/consumer/consumerDocumentsActions";
import * as TextProps from "../constants/text";
class DTASelect extends Component {
  static propTypes = {
    ...CommonTypes,
    // required
    options: PropTypes.array.isRequired,
    // handlers
    onChange: PropTypes.func,
    // container
    id: PropTypes.string,
    // one-way data bindings
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };
  static defaultProps = {
    ...CommonDefaults,
    // container
    id: "",
  };

  constructor(props) {
    super(...arguments);
    this._handleChange = this._handleChange.bind(this);
    this.state = {
      _value: isNone(props.value) ? [] : props.value,
    };
    sessionStorage.setItem("selectFilter", JSON.stringify(this.state._value));
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state._value && props.type === "addresscommon") {
      return {
        _value: props.value,
      };
    }
    return null;
  }

  render() {
    const {
        error,
        disabled,
        required,
        name,
        className,
        options,
        dob,
        months,
        isNotEmptyOption,
        ariaLabel,
      } = this.props,
      selectId = this.props.id,
      // use the passed in aria labels if present, otherwise, fall back on the select id so that
      // focusing on the option will read the label
      ariaLabels = validateAria(this.props["aria-labelledby"] || selectId);
    return (
      <select
        name={name}
        id={selectId}
        className={addStringIf(
          error,
          addStringIf(className, "dta-select"),
          "dta-select--error"
        )}
        value={this.state._value}
        disabled={disabled}
        required={required}
        onChange={this._handleChange}
        aria-label={ariaLabel}
      >
        {isNotEmptyOption ? (
          ""
        ) : (
          <option value="" aria-labelledby={ariaLabels} />
        )}
        {(() => {
          if (dob === TextProps.VALUE_STR_TRUE) {
            return this._buildOptionsForMonth(options, months, ariaLabels);
          } else {
            return this._buildOptions(options, ariaLabels);
          }
        })()}
      </select>
    );
  }

  // Rendering
  _buildOptions(options, ariaLabels) {
    return options.map((option, index) => {
      return (
        <option key={index} value={option} aria-labelledby={ariaLabels}>
          {option}
        </option>
      );
    });
  }

  _buildOptionsForMonth(options, values, ariaLabels) {
    return options.map((option, index) => {
      return (
        <option key={index} value={values[index]} aria-labelledby={ariaLabels}>
          {option}
        </option>
      );
    });
  }

  // Events
  _handleChange = ({ target: { value } }) => {
    var theCode = "";
    if (this.props.name === "filter-document-type") {
      this.props.onFilterChangeType(value);
    }
    this.setState({ _value: value });
    sessionStorage.setItem("selectFilter", value);
    tryCall(this.props.onChange, value);
  };
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getConsumerDocumentList: getConsumerDocumentList,
      getConsumerSelectedDocumentList: getConsumerSelectedDocumentList,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DTASelect);
