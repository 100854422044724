import React, { Component, Children } from "react";
import { enableUniqueIds } from "react-html-id";

import * as Props from "../../components/shared";
import Label from "./label";
import { addStringIf } from "../../components/utilities/controls";
import { WrapperTypes, WrapperDefaults } from "../propTypes";
import * as TextProps from "../../constants/text";

/*
  Grouped
  -------

  # Purpose: To render all of the markup supporting a specific question, including labels,
    any hidden or inline help text, and error messages in an accessible manner. This wrapper
    applies to questions that DO need to be rendered in a `fieldset` and therefore ARE
    questions that are considered to be in a group.

    NOTE: this wrapper component passes the `aria-labelledby` value to the question component
      that is contained by this wrapper component.

  # Props: see the shared WrapperTypes in the `propTypes.js` file for an explanation of
    all of the properties that this wrapper accepts

  # Example: see the ControlWrapper component for examples of which question types
    will be rendered with this wrapper component.
 */

class Grouped extends Component {
  static propTypes = WrapperTypes;
  static defaultProps = WrapperDefaults;

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);
  }

  render() {
    const thisId = this.nextUniqueId(),
      { className, questionClass, error, errorMessage, labelHidden } = this.props,
      labelObj = this.props[Props.LABEL],
      subLabelObj = this.props[Props.SUB_LABEL],
      subLabel = this.props[Props.SUB_LABEL],
      inlineHelpObj = this.props[Props.INLINE_HELP],
      inlineHelpHasMarkup = this.props[Props.INLINE_HELP_HAS_MARKUP];
    let hideErrorMsg;
    if (this.props.name === "stateCountyBenefits" || this.props.name === "stateCountyAssistance") {
      hideErrorMsg = this.props.error;
    }
   
    return (
       hideErrorMsg === true ?
      (
        <fieldset id={thisId} className={addStringIf(className, "dta-form__group")}>
          <legend
            className={addStringIf(labelHidden, "dta-form__label", "sr-only")}
          >
            <Label
              data={labelObj}
              subLabel={subLabel}
              hasMarkup={this.props[Props.LABEL_HAS_MARKUP]}
              className={addStringIf(questionClass, "dta-form__label")}
              required={this.props[Props.REQUIRED]}
            />
            {subLabelObj && 
            <Label
              data={subLabelObj}
              hasMarkup={this.props[Props.LABEL_HAS_MARKUP]}
              className={addStringIf(questionClass, "dta-form__label")}
              required={this.props[Props.REQUIRED]}
            />
            }
            {inlineHelpObj &&
              this._buildInlineHelp(inlineHelpObj, inlineHelpHasMarkup)}
            {error && (
              // If there is an error
              <span className="hide-err-msg">
                {errorMessage}
              </span>
            )}
          </legend>
          {Children.map(this.props.children, (child) => {
            return React.cloneElement(child, {
              "aria-labelledby": thisId,
            });
          })}
        </fieldset>
      ) :
      (
        <fieldset id={thisId} className={addStringIf(className, "dta-form__group")}>
          <legend
            className={addStringIf(labelHidden, "dta-form__label", "sr-only")}
          >
            <Label
              data={labelObj}
              subLabel={subLabel}
              hasMarkup={this.props[Props.LABEL_HAS_MARKUP]}
              className={addStringIf(questionClass, "dta-form__label")}
              required={this.props[Props.REQUIRED]}
            />
            {subLabelObj && 
            <Label
              data={subLabelObj}
              hasMarkup={this.props[Props.LABEL_HAS_MARKUP]}
              className={addStringIf(questionClass, "dta-form__label")}
              required={this.props[Props.REQUIRED]}
            />
            }
            {inlineHelpObj &&
              this._buildInlineHelp(inlineHelpObj, inlineHelpHasMarkup)}
            {error && (
              // If there is an error
              <span className="dta-form__error-message">
                {errorMessage}
              </span>
            )}
          </legend>
          {Children.map(this.props.children, (child) => {
            return React.cloneElement(child, {
              "aria-labelledby": thisId,
            });
          })}
        </fieldset>
      )
    );
    
  }
  

  // Rendering
  // ---------

  _buildInlineHelp(inlineHelpObj, inlineHelpHasMarkup = TextProps.VALUE_FALSE) {
    if (inlineHelpHasMarkup) {
      return (
        <span
          className="dta-form__help-text"
          dangerouslySetInnerHTML={{ __html: inlineHelpObj }}
        />
      );
    } else {
      return <span className="dta-form__help-text"><b>{inlineHelpObj}</b></span>;
    }
  }
}

export default Grouped;
