export const TYPE = "type";
export const AMOUNT = "amount";
export const EBT_BALANCE = "ebtBalance";
export const DATE = "date";
export const APP_SUBMITTED_DATE = "appSubmittedDate";
export const APP_EXPIRATION_DATE = "appExpirationDate";
export const EFFECTIVE_DATE = "effectiveDate";
export const START_DATE = "startDate";
export const RE_EVAL_DATE = "reEvalDate";
export const RE_CERT_DATE = "reCertDate";
export const NEXT_BENEFIT_DATE = "nextBenefitDate";
export const CLOSED_DATE = "closedDate";
export const HOUSEHOLD_MEMBERS = "householdMembers";
export const HEAD_OF_HOUSEHOLD = "headOfHousehold";
export const CASE_MANAGER = "caseManager";

export const ACCOUNT_ACCESS_EXPIRATION_DATE = "accountAccessExpirationDate";
export const CLIENT_ACTIVITY = "clientActivity";

export const STATUS = "applicationStatus";
export const STATUSCODE = "statusCode";
export const SUBSTATUSES = "subStatuses";
export const SUBSTATUS = "subStatus";
export const SUBSTATUS_ACTIVE = "subStatusActive";
export const SUBSTATUS_PROBLEM = "subStatusProblem";
export const STATUS_REASON = "statusReason";
export const STATUS_MESSAGE = "statusMessage";
export const STATUS_MESSAGE_URGENT = "statusMessageUrgent";

export const STATUS_TYPE_ACTIVE = "ACTIVE";
export const STATUS_TYPE_PENDING = "pending";
export const STATUS_TYPE_INELIGIBLE = "ineligible";
export const STATUS_TYPE_DENIED = "DENIED";
export const STATUS_TYPE_CLOSED = "CLOSED";
export const STATUS_TYPE_STARTED = "started";
export const STATUS_TYPE_INTERVIEW = "interview";
export const STATUS_TYPE_DOCUMENTS = "documents";
export const STATUS_TYPE_PROCESSING = "processing";
export const STATUS_TYPE_EVENT = "event";

export const BENEFIT_TYPE_CASH = "CASH";
export const BENEFIT_TYPE_SNAP = "SNAP";
export const BENEFIT_TYPE_FS = "FS";
export const BENEFIT_TYPE_TAFDC = "TAFDC";
export const BENEFIT_TYPE_TANF = "TANF";
export const BENEFIT_TYPE_EAEDC = "EAEDC";
// based on database column changed to pending to pend
export const STATUS_TYPE_PEND = "PEND";

//Label to convert multi lang
export const PHONENUM = "phoneNumber";
export const EMAILADDRS = "emailAddress";
export const RESDADDRS = "residentialAddress";
export const MAILINGADDRS = "mailingAddress";
export const ISALSOMAILINGADDR = "is-also-mailing";
export const TEXTNOTIFICATIONS = "allowTextNotification";
export const EMAILNOTIFICATIONS = "allowEmailNotification";
export const EDITPHONEDETTAILS = "Edit my phone details";
export const EDITEMAILDETAILS = "Edit my email details";
export const PHONEINLINETEXT =
  "I want to get SMS Text Messages. I give DHR permission to use my cell phone number to send me text messages. I understand that data charges may apply. I can stop SMS Text Messaging at any time by telling DHR.";
export const EMAILINLINETEXT =
  "I want to participate in eNotification. I give DHR permission to use my email address. I can stop eNotification at any time by telling DHR.";
export const EDITADDRESSDETAILS = "Edit my address details";
export const EDITEMAILADDRESS = "Edit my email address";

export const TYPECODE = "typeCode";
