import _ from "lodash";
import { incomeMultilanguageLabels } from "./applySnapText";
import * as Props from "../../utils/components/shared";
import * as OptionsProps from "../../utils/constants/options";
import * as TextProps from "../../utils/constants/text";

let language = localStorage.getItem("selectedLanguage");
if (language === null) {
  language = "en-US";
}

function _checkIncomeType(income) {
  let type =
    income.wageTypeCode === ""
      ? income.otherIncomeTypeCode
      : income.wageTypeCode;
      if ( type === "SELEMP" || type === "FEDWRK" || type === "ROOMER" || type === "BOARD" || type === "WAGES") {
        return "EI";
      } else if (
        type === "SSI" ||
        type==="WRKCMP" ||
        type === "RENTAL" ||
        type === "MILPE" ||
        type === "TAFDCS" ||
        type === "RSDI" ||
        type === "VETBEN" ||
        type === "INTINC" ||
        type === "RETFND" ||
        type === "RRRET" ||
        type === "LIHEAP" ||
        type === "ALIMNY" ||
        type === "CONTR" ||
        type === "RSDI" ||
        type === "UNEMPL" ||
        type === "CHSDOR" ||
        type === "EMPPEN" ||
        type === "VETBPR" ||
        type === "RENTAL" ||
        type === "WRKCMP" ||
        type === "OTHER"
      ) {
        return "UI";
      }
}

var membersList = [];
export function incomeBinding(
  sections,
  serverJson,
  IncomeSectionTemplate,
  incomeSummaryTemplate
) {
  let HouseHoldSection = sections[2];
  let IncomeSection = sections[3];
  let application = serverJson.application;
  let applicant = application.applicant;
  let houseHoldMembers = HouseHoldSection.subsections;
  //getting Disablitiy Options for Future use
  let HouseHoldMembersOptions =
    houseHoldMembers[houseHoldMembers.length - 1].questions[0].options;
  IncomeSection.questions[0].options = HouseHoldMembersOptions;
  membersList = [];
  for (let mem = 0; mem < HouseHoldMembersOptions.length; mem++) {
    membersList.push(HouseHoldMembersOptions[mem].optionDisplay);
  }

  IncomeSection.memberOptions = membersList;
  IncomeSection.questions[0].memberOptions = membersList;
  //Answer Collection Garbage
  let incomeMembers = [];
  if (applicant.income.length > 0) {
    let granteeTotIncomes = [];
    applicant.income.forEach((income) => {
      let incomeType = _checkIncomeType(income);
      if (income.otherIncomeTypeCode === "RENTAL") {
        let rentalIncome = {};
        rentalIncome.code = "UI-" + income.otherIncomeTypeCode;
        rentalIncome.amount = income.grossIncome;
        rentalIncome.units = income.frequencyCode;
        granteeTotIncomes.push(rentalIncome);
        rentalIncome = {};
        rentalIncome.code = "UI-RENTAL-HOURS";
        rentalIncome.amount = income.hours;
        rentalIncome.units = income.hoursfrequencyCode;
        granteeTotIncomes.push(rentalIncome);
      } else if (incomeType === "EI") {
        let earnedIncome = {};
        earnedIncome.code = "EI-" + income.wageTypeCode;
        earnedIncome.amount = income.grossIncome;
        earnedIncome.units = income.frequencyCode;
        granteeTotIncomes.push(earnedIncome);
      } else if (incomeType === "UI") {
        let unEarnedIncome = {};
        unEarnedIncome.code = "UI-" + income.otherIncomeTypeCode;
        unEarnedIncome.amount = income.grossIncome;
        unEarnedIncome.units = income.frequencyCode;
        granteeTotIncomes.push(unEarnedIncome);
      }
    });
    let granteeIncome = {};
    granteeIncome.incomes = granteeTotIncomes;
    granteeIncome.clientId = applicant.clientId;
    incomeMembers.push(granteeIncome);
  }
  if (applicant.household.length > 0) {
    //Itrate Each Household members
    applicant.household.forEach((household) => {
      let incomes = [];
      //Itrate the Income from the household member
      household.income.forEach((income) => {
        let incomeType = _checkIncomeType(income);
        if (income.otherIncomeTypeCode === "RENTAL") {
          let dependentRentalIncome = {};
          dependentRentalIncome.code = "UI-" + income.otherIncomeTypeCode;
          dependentRentalIncome.amount = income.grossIncome;
          dependentRentalIncome.units = income.frequencyCode;
          incomes.push(dependentRentalIncome);
          dependentRentalIncome = {};
          dependentRentalIncome.code = "UI-RENTAL-HOURS";
          dependentRentalIncome.amount = income.hours;
          dependentRentalIncome.units = income.hoursfrequencyCode;
          incomes.push(dependentRentalIncome);
        } else if (incomeType === "EI") {
          let dependentEarnedIncome = {};
          dependentEarnedIncome.code = "EI-" + income.wageTypeCode;
          dependentEarnedIncome.amount = income.grossIncome;
          dependentEarnedIncome.units = income.frequencyCode;
          incomes.push(dependentEarnedIncome);
        } else if (incomeType === "UI") {
          let dependentUnEarnedIncome = {};
          dependentUnEarnedIncome.code = "UI-" + income.otherIncomeTypeCode;
          dependentUnEarnedIncome.amount = income.grossIncome;
          dependentUnEarnedIncome.units = income.frequencyCode;
          incomes.push(dependentUnEarnedIncome);
        }
      });
      if (household.income.length > 0) {
        let dependentIncome = {};
        dependentIncome.incomes = incomes;
        dependentIncome.clientId = household.clientId;
        incomeMembers.push(dependentIncome);
      }
    });
  }

  //Filter and Arrange the Duplicate Incomes for Repeatable Question
  incomeMembers.forEach((income) => {
    if (income.incomes.length > 0) {
      income.incomes = checkDuplicateInObject("code", income.incomes);
    }
  });

  //Bind Answer to Static Page
  IncomeSection.questions[0].Answer = [];
  IncomeSection.questions[0].targetValue = [];
  incomeMembers.forEach((income, subIncome) => {
    if (income.incomes.length > 0) {
      IncomeSection.questions[0].Answer.push(income.clientId);
      IncomeSection.questions[0].targetValue.push(income.clientId);
    }
  });

  //Bind First Page Templates
  let incomeMemberMultipleTemplate = [];

  incomeMembers.forEach((income) => {
    //Lets Itrate for Client ID and Name
    HouseHoldMembersOptions.forEach((member, householdIndex) => {
      //only template renders if income present.
      if (income.incomes.length > 0) {
        let incomeMeTemplate = _.cloneDeep(IncomeSectionTemplate);
        //Match the Client ID
        if (member.optionValue === income.clientId) {
          let memName = member[OptionsProps.OPTION_DISPLAY];
          incomeMeTemplate.id =
            "combined-income-" + incomeMemberMultipleTemplate.length;
          incomeMeTemplate.header = incomeMultilanguageLabels(
            memName,
            language
          ).heading;
          incomeMeTemplate.title = memName;
          incomeMeTemplate.clientId = income.clientId;
          incomeMeTemplate.completed = TextProps.VALUE_TRUE;
          incomeMeTemplate.questions[0][Props.LABEL] =
            incomeMultilanguageLabels(memName, language).mainQuestion;
          incomeMeTemplate.questions[0].clientId = income.clientId;
          incomeMeTemplate.memberOptions = membersList;
          //Binding Answer to First Page Template
          incomeMeTemplate.questions[0].Answer = [];
          incomeMeTemplate.questions[0].targetValue = [];
          income.incomes.forEach((income, index) => {
            incomeMeTemplate.questions[0].Answer.push(income.code);
            incomeMeTemplate.questions[0].targetValue.push(income.code);
          });

          incomeMeTemplate.subsections[0].id =
            "combined-income-subsection-" + incomeMemberMultipleTemplate.length;
          incomeMeTemplate.subsections[0].header = incomeMultilanguageLabels(
            memName,
            language
          ).heading;
          incomeMeTemplate.subsections[0].memberOptions = membersList;
          incomeMeTemplate.subsections[0].clientId = income.clientId;

          let incomeReturn = incomeMultilanguageLabels(memName, language);
          for (
            var j = 0;
            j < incomeMeTemplate.subsections[0].questions.length;
            j++
          ) {
            incomeMeTemplate.subsections[0].questions[j][Props.LABEL] =
              incomeReturn.subQuestions[j][Props.LABEL];
            incomeMeTemplate.subsections[0].questions[j].repeatedQuestion[
              Props.LABEL
            ] = incomeReturn.subQuestions[j][Props.LABEL];
          }
          let displaySummaryNames = incomeMeTemplate.questions[0].options;
          let displayTargetValue = incomeMeTemplate.questions[0].targetValue;
          for (
            let optSelect = 0;
            optSelect < displayTargetValue.length;
            optSelect++
          ) {
            for (let opt = 0; opt < displaySummaryNames.length; opt++) {
              if (
                displaySummaryNames[opt].optionValue ===
                displayTargetValue[optSelect]
              ) {
                incomeMeTemplate.subsections[0].questions[
                  opt
                ].displayIncomeName = displaySummaryNames[opt].optionDisplay;
              }
            }
          }
          let subSectionQuestions = _.cloneDeep(
            incomeMeTemplate.subsections[0].questions
          );
          //Bind the Answer Here
          let Answers = [];
          income.incomes.forEach((income) => {
            Answers.push(income.code);
          });
          incomeMeTemplate.questions[0] = bindBothTargetAnswer(
            incomeMeTemplate.questions[0],
            Answers
          );

          //Filter the Questions for Second page Subsection let incomeMeTemplate = incomeMeTemplate
          let incomePageAnswers = incomeMeTemplate.questions[0].Answer;
          let incomeFilteredQuestions = [];
          let incomeFilteredQuestionsWithAnswers = [];

          //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
          if (
            incomePageAnswers[0] !== "" &&
            incomePageAnswers[0] !== undefined
          ) {
            incomeFilteredQuestions = filterQuestionsBySelection(
              incomePageAnswers,
              subSectionQuestions
            );
            incomeFilteredQuestionsWithAnswers = bindAnswerBySelection(
              income.incomes,
              incomeFilteredQuestions
            );
            incomeMeTemplate.subsections[0].questions = _.cloneDeep(
              incomeFilteredQuestionsWithAnswers
            );
          } else {
            delete incomeMeTemplate.subsections;
          }
          //Finally Push the template
          incomeMemberMultipleTemplate.push(_.cloneDeep(incomeMeTemplate));
        }
      }
    });
  });
  incomeMemberMultipleTemplate.push(incomeSummaryTemplate);
  IncomeSection.subsections = [];
  IncomeSection.subsections = incomeMemberMultipleTemplate;
  return IncomeSection;
}

//Answer and Target Object Created Here
function bindBothTargetAnswer(question, Answer) {
  question.Answer = Answer;
  question.targetValue = Answer;
  return question;
}

function filterQuestionsBySelection(activePageAnswers, nextPageQuestions) {
  let selectedQuestions = [];
  for (
    var answerIndex = 0;
    answerIndex < activePageAnswers.length;
    answerIndex++
  ) {
    for (
      var questionIndex = 0;
      questionIndex < nextPageQuestions.length;
      questionIndex++
    ) {
      if (
        activePageAnswers[answerIndex] ===
        nextPageQuestions[questionIndex].valueToMatch
      ) {
        selectedQuestions.push(nextPageQuestions[questionIndex]);
      }
    }
  }
  return selectedQuestions;
}

function bindAnswerBySelection(Answers, Questions) {
  let selectedQuestions = [];
  for (var answerIndex = 0; answerIndex < Answers.length; answerIndex++) {
    for (
      var questionIndex = 0;
      questionIndex < Questions.length;
      questionIndex++
    ) {
      if (Answers[answerIndex].code === Questions[questionIndex].valueToMatch) {
        Questions[questionIndex].Answer = [];
        Questions[questionIndex].targetValue = [];
        Questions[questionIndex].Answer = Answers[answerIndex].repeat;
        Questions[questionIndex].targetValue = Answers[answerIndex].repeat;
        selectedQuestions.push(Questions[questionIndex]);
      }
    }
  }
  return selectedQuestions;
}

function checkDuplicateInObject(propertyName, inputArray) {
  let testObject = Object.create(null);
  let IncomeObject = [];
  inputArray.map(function (item, index) {
    var itemPropertyName = item[propertyName];
    if (itemPropertyName in testObject) {
      let repeat = {};
      repeat.amount = item.amount;
      repeat.units = item.units;
      testObject[itemPropertyName].repeat.push(item);
      IncomeObject.forEach((element, index) => {
        if (element.code === item.code) IncomeObject[index].repeat.push(repeat);
      });
    } else {
      testObject[itemPropertyName] = item;
      let repeat = {};
      repeat.amount = item.amount;
      repeat.units = item.units;
      repeat.id = "repeatable_q_" + index;
      testObject[itemPropertyName].repeat = [];
      testObject[itemPropertyName].repeat.push(repeat);
      let indexIncome = _.cloneDeep(IncomeObject.length);
      IncomeObject[indexIncome] = {};
      IncomeObject[indexIncome].code = item.code;
      IncomeObject[indexIncome].repeat = [];
      IncomeObject[indexIncome].repeat.push(repeat);
    }
  });
  return IncomeObject;
}
