import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import { mapStateToProps } from "../../redux/rootReducer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import DTADatePicker from "./dtaDatePicker";

import { addStringIf, tryCall } from "../components/utilities/controls";
import { getDocumentTypeCode } from "../components/utilities/utils";
import { date as formatDate } from "../components/utilities/formatters";
import { CommonTypes, CommonDefaults } from "./propTypes";
import {
  getConsumerDocumentListFilter,
  getDTANotificationListFilter,
} from "../../redux/consumer/consumerDocumentsActions";
import { filterBenefitHistoryRecords } from "../../redux/consumer/consumerBenefitActions";
import { getConsumerActivityList } from "../../redux/consumer/consumerActivityActions";
import * as TextProps from "../../utils/constants/text";
class DTADateRange extends Component {
  static propTypes = {
    ...CommonTypes,
    value: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    onChange: PropTypes.func,
    // behavior
    closeOnChange: PropTypes.bool,
  };

  static defaultProps = {
    ...CommonDefaults,
    value: {
      from: null,
      to: null,
    },
    // behavior
    closeOnChange: TextProps.VALUE_TRUE,
  };

  state = {
    _value: this.props.value,
  };

  constructor(props) {
    super(...arguments);
    this.handleDateSortAction = this.handleDateSortAction.bind(this);
    enableUniqueIds(this);
    sessionStorage.setItem(
      "dateRangeFilter",
      JSON.stringify(this.state._value)
    );
  }
  componentWillUnMount() {
    sessionStorage.setItem(
      "dateRangeFilter",
      JSON.stringify({ from: "", to: "" })
    );
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ _value: nextProps.value });
      sessionStorage.setItem(
        "dateRangeFilter",
        JSON.stringify(nextProps.value)
      );
    }
  }

  handleDateSortAction() {
    // TRIGGER LIST SORT BASED ON THE ITEM. SEND THE ACCOUNT ID, FROM DATE AND TO DATE
    var consumerInfo = JSON.parse(
      sessionStorage.getItem("consumerAccountData")
    );
    var consumerDocListObj = JSON.parse(sessionStorage.getItem("userInfo"));
    var consumerDataUserObj = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    let providerUserdata = JSON.parse(sessionStorage.getItem("providerData"));
    var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    var programID = JSON.parse(sessionStorage.getItem("programID"));
    let userAuthToken = "";
    if (this.props.consumer.mfaDetails.userAuth !== undefined) {
      userAuthToken = this.props.consumer.mfaDetails.userAuth;
    } else if (userInfo !== null) {
      userAuthToken = userInfo.userAuth;
    }

    if (this.props.data.target === "Document") {
      let docTypeFilter = sessionStorage.getItem("selectFilter");
      docTypeFilter = getDocumentTypeCode(
        docTypeFilter == "[]" ? null : docTypeFilter
      );
      this.props.filterAppointmentByDate(
        this.state._value.from,
        this.state._value.to)
    } else if (this.props.data.target === "Notification") {
      this.props.filterAppointmentByDate(
        this.state._value.from,
        this.state._value.to)
    } else if (this.props.data.target === "Benefits") {
      if (
        this.props.consumer.consumerData.clientInfo !== undefined &&
        this.props.consumer.consumerData.clientInfo.agencyID !== null &&
        this.props.consumer.consumerData.clientInfo.agencyID !== ""
      ) {
        this.props.filterBenefitHistoryRecords(
          programID !== undefined ? programID : 0,
          this.state._value.from,
          this.state._value.to,
          userAuthToken
        );
      } else if (consumerInfo !== null) {
        this.props.filterBenefitHistoryRecords(
          programID !== undefined ? programID : 0,
          this.state._value.from,
          this.state._value.to,
          consumerInfo.userAuth
        );
      } else if (
        consumerDataUserObj !== undefined &&
        consumerDataUserObj != null
      ) {
        if (
          consumerDataUserObj.clientInfo !== undefined &&
          consumerDataUserObj.clientInfo.agencyID
        ) {
          this.props.filterBenefitHistoryRecords(
            programID !== undefined ? programID : 0,
            this.state._value.from,
            this.state._value.to
          );
        }
      }
    } else if (this.props.data.target === "Activity") {
      this.props.filterAppointmentByDate(
        this.state._value.from,
        this.state._value.to)
    }
  }

  render() {
    const { common } = this.props.language.consumer;

    const {
      name,
      "aria-labelledby": ariaLabels,
      className,
      disabled,
      error,
      required,
      closeOnChange,
    } = this.props;
    return (
      <div className={addStringIf(className, "dta-date-range")}>
        <div className="dta-date-range__item">
          <div className="dta-date-range__item dta-date-range__item--minimum">
            {common.from}
          </div>
          <div className="dta-date-range__item">
            <span id={this.getUniqueId("from-label")} className="sr-only">
              What is the starting date?
              {this.state._value.from
                ? ` The selected value is ${formatDate(this.state._value.from, {
                    screenReader: TextProps.VALUE_TRUE,
                  })}`
                : " You have not selected a value yet."}
            </span>
            <DTADatePicker
              name={addStringIf(name, "", `${name}-from`)}
              value={this.state._value.from}
              onChange={this._handleChangeForFrom}
              dateProps={{
                disabledDays: {
                  after: this.state._value.to,
                },
              }}
              aria-labelledby={`${ariaLabels} ${this.getUniqueId(
                "from-label"
              )}`}
              disabled={disabled}
              error={error}
              required={required}
              closeOnChange={closeOnChange}
            />
          </div>
        </div>
        <div className="dta-date-range__item">
          <div className="dta-date-range__item dta-date-range__item--minimum">
            {common.to}
          </div>
          <div className="dta-date-range__item">
            <span id={this.getUniqueId("to-label")} className="sr-only">
              What is the ending date?
              {this.state._value.to
                ? ` The selected value is ${formatDate(this.state._value.to, {
                    screenReader: TextProps.VALUE_TRUE,
                  })}`
                : " You have not selected a value yet."}
            </span>
            <DTADatePicker
              name={addStringIf(name, "", `${name}-to`)}
              value={this.state._value.to}
              onChange={this._handleChangeForTo}
              dateProps={{
                disabledDays: {
                  before: this.state._value.from,
                },
              }}
              aria-labelledby={`${ariaLabels} ${this.getUniqueId("to-label")}`}
              disabled={disabled}
              error={error}
              required={required}
              closeOnChange={closeOnChange}
            />
          </div>
          &emsp;
          <button
            className="dta-button dta-button--outline-secondary"
            onClick={this.handleDateSortAction}
          >
            {common.go}
          </button>
        </div>
        {/* Needs to be last for nth-child selector classes to work */}
        <div
          className="sr-only"
          aria-live="polite"
          aria-relevant="additions text"
        >
          {this.state.message}
        </div>
      </div>
    );
  }

  // Events
  // ------

  _handleChangeForFrom = (dateVal) => {
    this._updateRange(
      {
        ...this.state._value,
        from: dateVal,
      },
      this._buildScreenReaderMessage(dateVal, this.props.closeOnChange)
    );
  };

  _handleChangeForTo = (dateVal) => {
    this._updateRange(
      {
        ...this.state._value,
        to: dateVal,
      },
      this._buildScreenReaderMessage(dateVal, this.props.closeOnChange)
    );
  };

  // Helpers
  // -------

  _updateRange(newRange, message) {
    this.setState({ _value: newRange, message });
    sessionStorage.setItem("dateRangeFilter", JSON.stringify(newRange));
    tryCall(this.props.onChange, newRange);
  }

  _buildScreenReaderMessage(dateVal, closeOnChange) {
    if (dateVal) {
      let msg = `You have selected ${formatDate(dateVal, {
        screenReader: TextProps.VALUE_TRUE,
      })}.`;
      if (!closeOnChange) {
        msg += " Press the escape key to close the pop up if you are done.";
      }
      return msg;
    } else {
      return "";
    }
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getConsumerDocumentListFilter: getConsumerDocumentListFilter,
      getDTANotificationListFilter: getDTANotificationListFilter,
      filterBenefitHistoryRecords: filterBenefitHistoryRecords,
      getConsumerActivityList: getConsumerActivityList,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DTADateRange);
