import axios from "axios";
import sections from "../../pages/selfdeclare/selfdeclareMapData";
import { getAudit } from "../../utils/constants/audit";
import { resetAlertMessage } from "../../utils/components/utilities/utils";
import * as TextProps from "../../utils/constants/text";

var BaseUrl = require("../../utils/constants/appConfigConstants").BaseUrl;
var HttpOptions =
  require("../../utils/constants/appConfigConstants").HttpOptions;

var config = require("../../utils/constants/appConfigConstants");

const actionTypes = {
  applySDSnapPagesAction: "SD_SNAP_PAGES_FULLFILLED",
  serviceFailedAction: "NETWORK_FAILED_LOAD_DATA",
  appendDeclareExpensSubsec: "APPEND_DECLAREEXPENSE_SUBSEC",
  appendIncomeSelf: "APPEND_INCOME_DATA_SELF",
  appendExpense: "APPEND_EXPENSE_DATA",
  fillInputValuesAction: "FILL_INPUT_VALUES_STATE",
  downloadPdfAction: "DOWNLOAD_PDF_SD_APPLICATION",
  setSDDataChangeStatus: "SD_ADD_DATA_CHANGE_STATUS",
  setSelfDeclaretResponse: "SET_SELFDECLARE_RESPONSE",
  postSelfDeclareDataFulfilled: "POST_SELF_DECLARE_DATA_FULFILLED",
};
export function applySanpQuestionsSections() {
  return (dispatch) => {
    return dispatch(applySnapSections(sections));
  };
}
function applySnapSections(sectionsValue) {
  return {
    type: actionTypes.applySDSnapPagesAction,
    payload: sectionsValue,
  };
}
export function updateCurrentSectionData(applyUserSectionData) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(applyUserSectionData));
  };
}

export function updateResolveQuestionsData(dataSections) {
  return (dispatch) => {
    return dispatch(questionsInputValuesAction(dataSections));
  };
}
function questionsInputValuesAction(sectionsInputValue) {
  return {
    type: actionTypes.fillInputValuesAction,
    payload: sectionsInputValue,
  };
}
//Household Action
export function appendHouseHoldMembers(subMembers) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(houseHoldSuccess(subMembers));
  };
}
function houseHoldSuccess(houseHold) {
  return {
    type: actionTypes.appendHouseHold,
    payload: houseHold,
  };
}
export function appendDeclareExpenseSubsections(subSections) {
  return (dispatch) => {
    return dispatch(declareExpenseSubsections(subSections));
  };
}
function declareExpenseSubsections(subSections) {
  return {
    type: actionTypes.appendDeclareExpensSubsec,
    payload: subSections,
  };
}
//Appened Income
export function appendIncomeDetailsSelf(income) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(incomeSuccess(income));
  };
}
function incomeSuccess(income) {
  return {
    type: actionTypes.appendIncomeSelf,
    payload: income,
  };
}
//Appened Expense
export function appendExpenseDetails(expense) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(expenseSuccess(expense));
  };
}
function expenseSuccess(expense) {
  return {
    type: actionTypes.appendExpense,
    payload: expense,
  };
}
export function clearHouseHoldMembers(
  houseHoldSubMemberTemplate,
  houseHoldSubDetailTemplate
) {
  return (dispatch) => {
    let detailTemplate = [];
    detailTemplate.push(houseHoldSubDetailTemplate);
    return dispatch(houseHoldSuccess(detailTemplate));
  };
}

//Clearing Income Template
export function clearMemberIncome(
  incomeMemberTemplate,
  incomeSummaryTemplate,
  incomeTemplate
) {
  return (dispatch) => {
    let summaryTemplate = [];
    summaryTemplate.push(incomeSummaryTemplate);
    incomeTemplate.subsections = summaryTemplate;
    return dispatch(incomeSuccess(incomeTemplate));
  };
}

export function updateHouseholdTextChanges(householdDetails) {
  return (dispatch) => {
    //rendering Multiple templates to render
    return dispatch(appendHouseholdTextChanges(householdDetails));
  };
}
function appendHouseholdTextChanges(householdDetails) {
  return {
    type: actionTypes.appendHouseHold,
    payload: householdDetails,
  };
}
export function saveSnapApply(application) {
  return (dispatch) => {
    axios
      .post(BaseUrl + "/apply/save", application, HttpOptions)
      .then(function (data) {
        dispatch(saveApplyData(data));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}
function saveApplyData(data) {
  return {
    type: actionTypes.postApplyData,
    payload: data,
  };
}
export function serviceFailedAction() {
  return {
    type: actionTypes.serviceFailedAction,
  };
}
export function updateDefault() {
  return (dispatch) => {
    return dispatch({
      type: "default",
    });
  };
}
export function updateApplySection(AnswerObject, dalReference) {
  let data = [AnswerObject, dalReference];
  return (dispatch) => {
    return dispatch({
      type: "APPEND_APPLY_ANSER",
      payload: data,
    });
  };
}
export function postSelfDeclareData(answerObject, auditData) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      var url = config.TigerContextURL + "/declareexpenses";
      const oauthToken = JSON.parse(sessionStorage.getItem("oauthToken"));
      const audit = getAudit();
      const TigerOptions = {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          audit: JSON.stringify(audit),
          Authorization: oauthToken.token_type + " " + oauthToken.access_token,
        },
        mode: "no-cors",
        "Access-Control-Allow-Origin": config.allowed_domain,
      };
      axios
        .post(url, answerObject, TigerOptions)
        .then((response) => {
          var newConsumerInfo = resetAlertMessage("IR");
          sessionStorage.setItem(
            "loadConsumerUser",
            JSON.stringify(newConsumerInfo)
          );
          dispatch(submitSelfDeclareDataAction(response.data));
          resolve(TextProps.VALUE_TRUE);
        })
        .catch((error) => {
          dispatch(serviceFailedAction());
          resolve(TextProps.VALUE_FALSE);
        });
    });
  };
}

function submitSelfDeclareDataAction(response) {
  return {
    type: actionTypes.postSelfDeclareDataFulfilled,
    payload: response.referenceNumber,
  };
}
//
export function downloadPDFData(sectionsObj) {
  return (dispatch) => {
    axios
      .post(BaseUrl + "/apply/downloadPDFData", sectionsObj, HttpOptions)
      .then(function (data) {
        if (data !== undefined)
          toShowPDFinWindow(data, sectionsObj[0].webAppId);
      });
  };
}
/**
 * To show PDF in window
 * Set Base64String in server side code for viewing PDF
 *
 */
function toShowPDFinWindow(data, webAppId) {
  //For IE using atob convert base64 encoded data to byte array
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var byteCharacters = atob(data.data.base64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: "application/pdf",
    });
    window.navigator.msSaveOrOpenBlob(blob, "SnapApply_" + webAppId + ".pdf");
  } else {
    // Directly use base 64 encoded data for rest browsers (not IE)
    var objbuilder = "";
    objbuilder +=
      '<object width="100%" height="100%"  data="data:application/pdf;base64,';
    objbuilder += data.data.base64;
    objbuilder += '" type="application/pdf" class="internal">';
    objbuilder += '<embed src="data:application/pdf;base64,';

    objbuilder += data.base64;
    objbuilder += '" type="application/pdf" />';
    objbuilder += "</object>";

    var win = window.open("", "_blank", "titlebar=yes");
    win.document.title = "SNAP Application";
    win.document.write("<html><body>");
    win.document.write(objbuilder);
    win.document.write("</body></html>");
  }
}

export function selectMaxOfIdsComesBack(sectionsObj) {
  return (dispatch) => {
    axios
      .post(BaseUrl + "/apply/getMaxTbIds", sectionsObj, HttpOptions)
      .then(function (data) {
        dispatch(appendDatabaseIds(sectionsObj));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}

function appendDatabaseIds(response) {
  return {
    type: actionTypes.downloadPdfAction,
    payload: response,
  };
}

export function saveMyProgressInfoLater(sectionsObj) {
  return (dispatch) => {
    axios
      .post(BaseUrl + "/apply/onSaveMyProgressLater", sectionsObj, HttpOptions)
      .then(function (data) {
        // it's required to save in webAppid in json
        dispatch(updateWebApplicationIdInJson(data));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}

function updateWebApplicationIdInJson(data) {
  return {
    type: actionTypes.updateWebApplicationIdAction,
    payload: data,
  };
}

export function getSaveMyProgessDataByWebAppId(id) {
  return (dispatch) => {
    var passingParams = "/apply/callSaveMyProgressData?id=" + id;
    var options = {
      method: "GET",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
    };
    axios
      .get(BaseUrl + passingParams, {}, options)
      .then(function (applySectionsData) {
        return dispatch(resumeBackApplyInfo(applySectionsData.data));
      })
      .catch(function (error) {
        dispatch(serviceFailedAction());
      });
  };
}

function resumeBackApplyInfo(sectionsResume) {
  return {
    type: actionTypes.applySDSnapPagesAction,
    payload: sectionsResume,
  };
}

export function saveFinalSignAndSubmit(webId) {
  return (dispatch) => {
    dispatch(setWebAppID(webId));
  };
}

function setWebAppID(data) {
  return {
    type: actionTypes.saveWebAppId,
    payload: data,
  };
}

export function setContactInfo(contactInfoObj) {
  return (dispatch) => {
    dispatch(setContactInfoObjIR(contactInfoObj));
  };
}

function setContactInfoObjIR(contactInfoObj) {
  return {
    type: actionTypes.setContactInfo,
    payload: contactInfoObj,
  };
}

export function setHouseholdMembers(houseHoldObj) {
  return (dispatch) => {
    dispatch(setHouseholdMembersListIR(houseHoldObj));
  };
}

function setHouseholdMembersListIR(houseHoldObj) {
  return {
    type: actionTypes.setHouseholdMembers,
    payload: houseHoldObj,
  };
}

export function addStudentToList(studentObj) {
  return (dispatch) => {
    dispatch(addStudentObjIR(studentObj));
  };
}

function addStudentObjIR(studentObj) {
  return {
    type: actionTypes.addStudentToList,
    payload: studentObj,
  };
}

export function addContactInfoSubSection(subSectionObj) {
  return (dispatch) => {
    dispatch(addContactInfoToSectionsIR(subSectionObj));
  };
}

function addContactInfoToSectionsIR(subSectionObj) {
  return {
    type: actionTypes.addContactInfoToSections,
    payload: subSectionObj,
  };
}

export function addHouseholdAddSectionToList(houseHoldAddObj) {
  return (dispatch) => {
    dispatch(addHouseholdToSectionsIR(houseHoldAddObj));
  };
}

function addHouseholdToSectionsIR(houseHoldAddObj) {
  return {
    type: actionTypes.addHouseholdInfoToSections,
    payload: houseHoldAddObj,
  };
}

export function setSDDataChangeStatus(status, index) {
  return (dispatch) => {
    dispatch(setChangeStatusIR(status, index));
  };
}

function setChangeStatusIR(status, index) {
  return {
    type: actionTypes.setSDDataChangeStatus,
    payload: status,
    index: index,
  };
}

export function setSelfDeclareResponseObj(payload) {
  return (dispatch) => {
    dispatch(setSelfDeclareResponseObjToState(payload));
  };
}

function setSelfDeclareResponseObjToState(payload) {
  return {
    type: actionTypes.setSelfDeclaretResponse,
    payload: payload,
  };
}
