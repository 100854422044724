import _ from 'lodash';
import * as TextProps from "../../utils/constants/text";

export function concernsAppealsReducer(state = {}, action) {
  switch (action.type) {
    case "CUSTOMER_CONCERNS_DATA_LOADING": {
      let newState = Object.assign({}, state);
      newState.customerConcernsDataLoading = TextProps.VALUE_TRUE;
      newState.customerConcernsSuccess = TextProps.VALUE_FALSE;
      newState.customerConcernsFailure = TextProps.VALUE_FALSE;
      return newState;
    }

    case "CUSTOMER_CONCERNS_SUCCESS": {
      let newState = Object.assign({}, state);
      newState.customerConcernsSuccess = action.payload;
      newState.customerConcernsDataLoading = TextProps.VALUE_FALSE;
      newState.customerConcernsFailure = TextProps.VALUE_FALSE;
      return newState;
    }

    case "CUSTOMER_CONCERNS_FAILURE": {
      const newState = Object.assign({}, state);
      newState.customerConcernsFailure = TextProps.VALUE_TRUE;
      newState.customerConcernsSuccess = TextProps.VALUE_FALSE;
      newState.customerConcernsDataLoading = TextProps.VALUE_FALSE;
      return newState;
    }
    case "ANONYMOUS_CONCERNS_DATA_LOADING": {
      let newState = Object.assign({}, state);
      newState.anonymousConcernsDataLoading = TextProps.VALUE_TRUE;
      newState.anonymousConcernsFailure = TextProps.VALUE_FALSE;
      return newState;
    }

    case "ANONYMOUS_CONCERNS_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.status === 200) {
        newState.anonymousConcernsSuccess = action.payload;
        newState.anonymousConcernsDataLoading = TextProps.VALUE_FALSE;
        newState.anonymousConcernsFailure = TextProps.VALUE_FALSE;
      }
      return newState;
    }

    case "ANONYMOUS_CONCERNS_FAILURE": {
      const newState = Object.assign({}, state);
      newState.anonymousConcernsFailure = TextProps.VALUE_TRUE;
      newState.anonymousConcernsDataLoading = TextProps.VALUE_FALSE;
      return newState;
    }

    case "CONCERNS_APPEAL_DATA_LOADING": {
      let newState = Object.assign({}, state);
      newState.getConcernsAppealDataLoading = TextProps.VALUE_TRUE;
      return newState;
    }
    case "CONCERN_APPEAL_SUCCESS": {
      const newState = Object.assign({}, state);
      newState.getConcernsAppealDataLoading = TextProps.VALUE_FALSE;
      newState.concernsAppeals = action.payload;
      return newState;
    }
    case "CONCERN_APPEAL_FAILURE" :{
      const newState = Object.assign({}, state);
      newState.getConcernsAppealDataLoading = TextProps.VALUE_FALSE;
      newState. getConcernAppealSuccess = TextProps.VALUE_FALSE;
      return newState;

    }
    case "WITHDRAW_APPOINTMENT_DATA_LOADING": {
      let newState = Object.assign({}, state);
      newState.withdrawRequest = TextProps.VALUE_TRUE;
      // newState.withdrawsuccess = TextProps.VALUE_FALSE;
      newState.withdrawFailure = TextProps.VALUE_FALSE;
      return newState;
    }

    case "WITHDRAW_APPOINTMENT_SUCCESS": {
      const newState = Object.assign({}, state);
        newState.withdrawRequest = TextProps.VALUE_FALSE;
        newState.withdrawSuccess = action.payload.data;
      return newState;
    }
    case "WITHDRAW_APPOINTMENT_FAILURE": {
      const newState = Object.assign({}, state);
      newState.withdrawRequest = TextProps.VALUE_FALSE;
      newState.withdrawFailure = TextProps.VALUE_TRUE;
      return newState;
    }

    //appeal Document
    case "APPEAL_DOCUMENT_DATA_LOADING": {
      let newState = Object.assign({}, state);
      newState.appealdocRequest = TextProps.VALUE_TRUE;
      newState.appealdocSuccess = TextProps.VALUE_FALSE;
      newState.appealdocFailure = TextProps.VALUE_FALSE;
      return newState;
    }

    case "APPEAL_DOCUMENT_DATA_SUCCESS": {
      const newState = Object.assign({}, state);
      if (action.payload.status === 200) {
        newState.appealdocRequest = TextProps.VALUE_FALSE;
        newState.appealdocSuccess = TextProps.VALUE_TRUE;
        newState.appealdocFailure = TextProps.VALUE_FALSE;
      }
      return newState;
    }

    case "APPEAL_DOCUMENT_DATA-FAILURE": {
      const newState = Object.assign({}, state);
      newState.appealdocRequest = TextProps.VALUE_FALSE;
      newState.appealdocSuccess = TextProps.VALUE_FALSE;
      newState.appealdocFailure = TextProps.VALUE_TRUE;
      return newState;
    }

    //anonymous

    case "ANONYMOUS_DOCUMENT_DATA_LOADING": {
      let newState = Object.assign({}, state);
      newState.anonymousDataLoading = TextProps.VALUE_TRUE;
      // newState.anonymousGetCallSuccess = TextProps.VALUE_FALSE;
      newState.anonymousGetCallFailure = TextProps.VALUE_FALSE;
      return newState;
    }

    case "ANONYMOUS_DOCUMENT_DATA_SUCCESS": {
      const newState = Object.assign({}, state);
        newState.anonymousDataLoading = TextProps.VALUE_FALSE;
        newState.anonymousGetCallSuccess = action.payload.data;
        newState.anonymousGetCallFailure = TextProps.VALUE_FALSE;

      return newState;
    }

    case "ANONYMOUS_DOUCMENT_DATA_FAILURE": {
      const newState = Object.assign({}, state);
      newState.anonymousDataLoading = TextProps.VALUE_FALSE;
      newState.anonymousGetCallFailure = TextProps.VALUE_TRUE;
      return newState;
    }
    default:
      return Object.assign({}, state);
  }
}

