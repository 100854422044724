import * as Props from "../../utils/components/shared";
import * as languageConstants from "../../utils/constants/constants";
import * as languageConsts from "../../utils/constants/types";

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

export function incomeDynamicMultilanguageLabels(name, typeCode, language) {
  switch (language) {
    case EN:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "What is the ",
          [Props.LABEL_OBJ_TRIGGER]: "gross income",
          [Props.LABEL_OBJ_AFTER]: " amount of " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "What is the ",
          [Props.LABEL_OBJ_TRIGGER]: "gross amount",
          [Props.LABEL_OBJ_AFTER]: " of " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
        },
        employerLabel: "Who is " + name + "'s employer for these wages?",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "What is the ",
          [Props.LABEL_OBJ_TRIGGER]: "frequency",
          [Props.LABEL_OBJ_AFTER]: " of " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]: "How often you are paid.",
        },
      };
    case ES:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
          [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
          [Props.LABEL_OBJ_AFTER]:
            " monto del " + name + " de " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
          [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
          [Props.LABEL_OBJ_AFTER]: " del " + name + " de " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico.",
        },
        employerLabel:
          "¿Quién es el empleador de" + name + " para este Salarios?",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
          [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
          [Props.LABEL_OBJ_AFTER]: " del " + name + " de " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
        },
      };
    case PT:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
          [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
          [Props.LABEL_OBJ_AFTER]:
            " valor bruto do " + typeCode + " de " + name + "?",
          [Props.LABEL_OBJ_HELP]:
            "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
          [Props.LABEL_OBJ_TRIGGER]: "valor bruto do ",
          [Props.LABEL_OBJ_AFTER]: typeCode + " de " + name + "?",
          [Props.LABEL_OBJ_HELP]:
            "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde",
        },
        employerLabel: "Quem é o empregador de " + name + " " + typeCode + "?",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
          [Props.LABEL_OBJ_TRIGGER]: "frequência",
          [Props.LABEL_OBJ_AFTER]: " do " + typeCode + " de " + name + "?",
          [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
        },
      };
    case ZH:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
          [Props.LABEL_OBJ_TRIGGER]: "收入总额",
          [Props.LABEL_OBJ_AFTER]: " 收入 " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: name + "的" + typeCode + "的",
          [Props.LABEL_OBJ_TRIGGER]: "总金额",
          [Props.LABEL_OBJ_AFTER]: " ?",
          [Props.LABEL_OBJ_HELP]:
            "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
        },
        employerLabel: name + "的" + typeCode + "雇主是谁？",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: name + "的" + typeCode + "的",
          [Props.LABEL_OBJ_TRIGGER]: "收入频率",
          [Props.LABEL_OBJ_AFTER]: " 是多少?",
          [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
        },
      };
    case VI:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "Là gì ",
          [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
          [Props.LABEL_OBJ_AFTER]: " số tiền " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "",
          [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
          [Props.LABEL_OBJ_AFTER]:
            "của " + typeCode + " của " + name + " là gì?",
          [Props.LABEL_OBJ_HELP]:
            "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
        },
        employerLabel: "Ai là người chủ của " + name + " cho " + typeCode + "?",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "",
          [Props.LABEL_OBJ_TRIGGER]: "Tần suất ",
          [Props.LABEL_OBJ_AFTER]:
            "của " + typeCode + " của " + name + " là gì?",
          [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
        },
      };
    case HI:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "Ki ",
          [Props.LABEL_OBJ_TRIGGER]: "montan",
          [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante. ",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Ki ",
          [Props.LABEL_OBJ_TRIGGER]: "montan brit",
          [Props.LABEL_OBJ_AFTER]: " nan " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante. ",
        },
        employerLabel:
          "Kilès ki se anplwayè " + name + "pou travay endepandan sa yo?",
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Ki ",
          [Props.LABEL_OBJ_TRIGGER]: "frekans",
          [Props.LABEL_OBJ_AFTER]: " nan " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
        },
      };
    default:
      return;
  }
}

export function beforeYoustartMlanguageLabels(language) {
  switch (language) {
    case EN:
      return {
        areYouSureWarningLabel: "Are you sure ?",
        saveOrExitLabel:
          "You might lose the unsaved information in the SNAP Six-Month Report. Do you want to save the information before you exit?",
        saveLabel: "Save",
        exitLabel: "Exit",
        addressValidHeaderText: "Address Validation",
        addressValidLabelText: "Please select from the results below.",
        enteredHomeAddressLabelText: "Entered home address:",
        suggestedHomeAddressLabelText: "Suggested home address:",
        enteredMailingAddressLabelText: "Entered mailing address:",
        suggestedMailingAddressLabelText: "Suggested mailing address:",
        CancelButtonLabel: "Cancel",
        confirmButtonLabel: "Confirm",
        languageWarningLabel:"If you want to change the langauge your application will get restarted"
      }
    case ES:
      return {
        areYouSureWarningLabel: "Estas segura",
        languageWarningLabel:"Si desea cambiar el idioma, su aplicación se reiniciará.",
        saveOrExitLabel:
          "Es posible que pierda la información no guardada en la aplicación. ¿Desea guardar la información antes de salir?",
        saveLabel: "Ahorrar",
        exitLabel: "Salida",
        addressValidHeaderText: "Validación de dirección",
        addressValidLabelText:
          "Seleccione entre los resultados a continuación.",
        enteredHomeAddressLabelText: "Dirección de casa introducida:",
        suggestedHomeAddressLabelText: "Dirección de casa sugerida:",
        enteredMailingAddressLabelText: "Dirección postal introducida:",
        suggestedMailingAddressLabelText: "Dirección postal sugerida:",
        confirmButtonLabel: "Confirmar",
        CancelButtonLabel: "Cancelar",
      }
  }
}

export function unearnedIncomeDynamicMultilanguageLabels(
  name,
  typeCode,
  language
) {
  switch (language) {
    case EN:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "What is the ",
          [Props.LABEL_OBJ_TRIGGER]: "gross income",
          [Props.LABEL_OBJ_AFTER]: " amount of " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "What is the ",
          [Props.LABEL_OBJ_TRIGGER]: "gross amount",
          [Props.LABEL_OBJ_AFTER]: " of " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Gross Unearned Income is money you receive from a source other than employment before anything is taken out, such as taxes or health insurance",
        },
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "What is the ",
          [Props.LABEL_OBJ_TRIGGER]: "frequency",
          [Props.LABEL_OBJ_AFTER]: " of " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]: "How often you are paid.",
        },
      };
    case ES:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
          [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
          [Props.LABEL_OBJ_AFTER]:
            " monto del " + name + " de " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
          [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
          [Props.LABEL_OBJ_AFTER]: " del " + name + " de " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Los ingresos brutos no devengados es dinero que recibe de una fuente que no es su empleo antes de cualquier deducción, como impuestos o seguro médico.",
        },
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
          [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
          [Props.LABEL_OBJ_AFTER]: " del " + name + " de " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
        },
      };
    case PT:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
          [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
          [Props.LABEL_OBJ_AFTER]:
            " valor bruto do " + typeCode + " de " + name + "?",
          [Props.LABEL_OBJ_HELP]:
            "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
          [Props.LABEL_OBJ_TRIGGER]: "valor bruto do ",
          [Props.LABEL_OBJ_AFTER]: typeCode + " de " + name + "?",
          [Props.LABEL_OBJ_HELP]:
            "Renda bruta não realizada é o dinheiro que você recebe de uma fonte que não seja emprego antes de qualquer coisa ser descontada, como impostos ou seguro de saúde.",
        },
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
          [Props.LABEL_OBJ_TRIGGER]: "frequência",
          [Props.LABEL_OBJ_AFTER]: " do " + typeCode + " de " + name + "?",
          [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
        },
      };
    case ZH:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
          [Props.LABEL_OBJ_TRIGGER]: "收入总额",
          [Props.LABEL_OBJ_AFTER]: " 收入 " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: name + "的" + typeCode + "的",
          [Props.LABEL_OBJ_TRIGGER]: "总金额",
          [Props.LABEL_OBJ_AFTER]: " ?",
          [Props.LABEL_OBJ_HELP]:
            "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
        },
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: name + "的" + typeCode + "的",
          [Props.LABEL_OBJ_TRIGGER]: "收入频率",
          [Props.LABEL_OBJ_AFTER]: " 是多少?",
          [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
        },
      };
    case VI:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "Là gì ",
          [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
          [Props.LABEL_OBJ_AFTER]: " số tiền " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "",
          [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
          [Props.LABEL_OBJ_AFTER]:
            "của " + typeCode + " của " + name + " là gì?",
          [Props.LABEL_OBJ_HELP]:
            "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
        },
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "",
          [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
          [Props.LABEL_OBJ_AFTER]:
            "của " + typeCode + " của " + name + " là gì?",
          [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
        },
      };
    case HI:
      return {
        [Props.LABEL]: {
          [Props.LABEL_OBJ_BEFORE]: "Ki ",
          [Props.LABEL_OBJ_TRIGGER]: "montan",
          [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
        },
        amountLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Ki ",
          [Props.LABEL_OBJ_TRIGGER]: "montan brit",
          [Props.LABEL_OBJ_AFTER]: " nan " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]:
            "Revni brit ou pa fè nan travay se lajan ou resevwa nan yon lòt sous ki pa travay anvan yo retire anyen, tankou taks oswa asirans sante.",
        },
        unitLabel: {
          [Props.LABEL_OBJ_BEFORE]: "Ki ",
          [Props.LABEL_OBJ_TRIGGER]: "frekans",
          [Props.LABEL_OBJ_AFTER]: " nan " + name + " " + typeCode + "?",
          [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
        },
      };
    default:
      return;
  }
}

//All the Label translations for Interim Report page
export function interimReportPageLabels(
  language,
  pageType,
  name,
  caseManagerPhone
) {
  switch (pageType) {
    case languageConstants.aboutMe: {
      switch (language) {
        case EN:
          return {
            title: "About me",
            header: "About me",
            labelFirstName: "What is your first name?",
            firstNameErrMsg: "Required - Please enter first name",
            middleName: "What is your middle name?",
            middleNameErrMsg: "Please enter middle name",
            lastNamelabel: "What is your last name?",
            lastNameErrMsg: "Required - Please enter last name",
            labelSuffix: "Suffix",
            suffixOption: ["Jr.",
            "Sr.",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Esq",],
            dateOfBirthLabel: "What is your date of birth?",
            dobAlertMsg: "Please enter a valid Date of Birth",
            ssnLabel: "What is your Social Security Number?",
            inputClearLabel: "I don't have one",
            ssnErrMsg: "Please enter a valid Social Security Number.",
            headerMyContactInfo: "My Contact Info",
            houselessLabel: "Is your household homeless?",
            houselessErrMsg: "Please select whether your having home or not",
            houseLessOptions: ["Yes", "No"],
            addrQuestionlabel: "What is your home address?",
            validZipcode: "Please enter a valid Zipcode.",
            alsoMailingLabel: "Is this also your mailing address?",
            optionsSendMail: "Yes, send mail there.",
            optionsNoDiffAddress: "No, I use a different address for mail.",
            mailOptions: [
              "Yes, send mail there.",
              "No, I use a different address for mail.",
            ],
            mhProviderMemberSearch: ["First Name / Last Name", "Zip Code"],
            agedOver60: ["Aged over 60"],
            mailingQlabel: "What is your mailing address?",
            zipCodeErrmsg: "Please enter a valid Zipcode.",
            masZipCodeErrmsg: `If you moved outside of Alabama, please call ${caseManagerPhone}.`,
            citiErrmsg: "Please enter a valid City.",
            countyErrmsg: "Please enter a valid County.",
            addressErrmsg: "Please enter a valid Address.",
            mailingAddressErrmsg: "Please enter a mailing address.",
            homeAddressErrmsg: "Please enter a home address.",
            bestPhoneqlabel: "What is the best phone number to reach you?",
            inlineHelp: "This is the number the DHR will use to reach you.",
            validPhoneAlert: "Please enter a valid phone number.",
            emailAddressLabel: "What is your email address?",
            emailErrormsg: "Please enter a valid email address.",
            titleEmergency: "Emergency SNAP benefits",
            headerEmergency: "Emergency SNAP benefits",
            emergencySnap:
              "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
            incomeMoneylabel:
              "Does your income and money in the bank add up to less than your monthly housing expenses?",
            inlineHelpLabel:
              "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
            optionYesNo: ["Yes", "No"],
            alertSelectIncome: "Please select income you pay",
            incomeLesslabel:
              "Is your monthly income less than $150 and is your money in the bank $100 or less?",
            monthlyIncomeoptions: ["Yes", "No"],
            selectIncomeErrmsg: "Please select income you pay",
            migrantBefore: "Are you a ",
            migrantTrigger: "migrant worker",
            migrantAfter: " and is your money in the bank $100 or less?",
            migrantHelp:
              "A migrant worker is a person who moves from place to place doing or looking for seasonal farm work.",
            migrantOptions: ["Yes", "No"],
            migrantErrmsg: "Please select migrant you pay",
            headerMoreAbout: "More about me",
            areUsBefore: "Are you a ",
            areUsTrigger: "US citizen",
            areUsafter: "?",
            eligibleHelp:
              "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
            chooseErrmsg: "Please choose citizen",
            eligibleMemberHelp:
              "Even if you are not a US Citizen, you or other household members may still be eligible.",
            eligibleOptions: ["Yes", "No"],
            laguageBefore: "What language do you ",
            LANGUAGES: [
              "English",
              "Spanish",
              "Portuguese",
              "French",
              "Haitian Creole",
              "Chinese",
              "Vietnamese",
              "Khmer",
              "Korean",
              "Russian",
              "Italian",
              "Polish",
              "Arabic",
            ],
            languageTrigger: "prefer to speak",
            languageAfter: "?",
            comfortLanHelp:
              "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
            languageErrMsg: "Please choose language",
            genderLabel: "What is your gender?",
            genderOptions: ["Male", "Female"],
            genderErrmsg: "Please choose gender",
            labelEthnicity: "What is your ethnicity?",
            helpEthnicity:
              "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            raceLabel: "What is your race?",
            raceHelp:
              "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
            optionAIdisplay: "American Indian",
            optionAsiandisplay_VALUE: "ASIAN",
            optionAsiandisplay: "Asian",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Black/African American",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Pacific Islander",
            optionwhitedisplay_VALUE: "WHITE",
            optionwhitedisplay: "White",
            declineToAnswerLabel: "Decline to answer",
            tripCountErrMsgLen:
              "Please enter less than/equal to 6 characters for trip count",
              tanfheadinghead: "Voter Registration",
          tanfheadingone:"Domestic Violence Screening",
          tanfheadingonesub: "(Your response to this screening is voluntary)",
          tanfheadingtwo: "Other Information",
          tanfheadingthree: "Voter Registration",
          tanfpleaseexplain: "Please Explain",
          tanfquestionone: "Is there someone (friend, relative, child, spouse) who has ever physically, sexually, or emotionally hurt you or threatened to hurt you?",
          tanfquestiontwo:"Is this happening now?",
          tanfquestionthree:"Is there anyone you are afraid of?",
          tanfquestionfour:"Is there anything else not listed above that you need to report to us?",
          voterHeadone: "IF YOU DO NOT CHECK EITHER BOX, YOU WILL BE CONSIDERED TO HAVE DECIDED NOT TO REGISTER TO VOTE AT THIS TIME.",
          voterParaone: "Applying to register or declining to register to vote will not affect the amount of assistance that you will be provided by this agency. If you would like help in filling out the voter registration information, we will help you. You may seek assistance at the time of your interview or by calling your local Department of Human Resources located within your county. The decision whether to seek or accept help is yours. You may fill out an application form in private.",
          voterParatwo: "If you choose to apply to register to vote or if you decline to register to vote, the information will remain confidential and will be used for voter registration purposes only.",
          voterParathree: "If you believe that someone has interfered with your right to register or to decline to register to vote, your right to privacy in deciding whether to register or in applying to register to vote, or your right to choose your own political party or other political preference, you may file a complaint with the Secretary of State at State Capitol, 600 Dexter Avenue Suite E-208, Montgomery, AL 36130 or by calling 334-242-7210 or 1-800-274-VOTE (1-800-274-8683).",
          ifansweryes: "If you answered 'Yes' to any of the above questions, Alabama DHR can refer you to a local domestic violence specialist. The specialist will be able to contact you via the phone number or address provided in the TANF Redetermination form. Do you want a referral? ",
          finalnote: "If you are in immediate danger call 911 or the Alabama Domestic Violence Hotline at 1-800-650-6522 (TTY for the deaf – 1-800-787-3224).",
          voterQuestion: "If you are not registered to vote where you live now, would you like to apply to register to vote here today?",
        };

        case ES:
          return {
            title: "Información de contacto",
            header: "Mi información de contacto",
            tanfheadingone:"Detección de violencia doméstica",
            tanfheadingonesub: "(Su respuesta a esta evaluación es voluntaria)",
            tanfheadingtwo: "Otra información",
            tanfheadingthree: "Registro de votantes",
            tanfpleaseexplain: "Por favor explique",
            tanfquestionone: "¿Hay alguien (amigo, pariente, hijo, cónyuge) que alguna vez lo haya lastimado física, sexual o emocionalmente o haya amenazado con lastimarlo?",
            tanfquestiontwo:"¿Está sucediendo esto ahora?",
            tanfquestionthree:"¿Hay alguien a quien le tengas miedo?",
            tanfquestionfour:"¿Hay algo más que no esté mencionado anteriormente que deba informarnos?",
            voterHeadone: "SI NO MARCA NINGUNA CASILLA, SE CONSIDERARÁ QUE HA DECIDIDO NO REGISTRARSE PARA VOTAR EN ESTE MOMENTO.",
            voterParaone: "Solicitar registrarse o negarse a registrarse para votar no afectará la cantidad de asistencia que le brindará esta agencia. Si desea ayuda para completar la información de registro de votantes, lo ayudaremos. Puede buscar ayuda en el momento de su entrevista o llamando al Departamento de Recursos Humanos local ubicado dentro de su condado. La decisión de buscar o aceptar ayuda es tuya. Puede completar un formulario de solicitud en privado.",
            voterParatwo: "Si decide solicitar registrarse para votar o si se niega a registrarse para votar, la información permanecerá confidencial y se utilizará únicamente con fines de registro de votantes.",
            voterParathree: "Si cree que alguien ha interferido con su derecho a registrarse o a negarse a registrarse para votar, su derecho a la privacidad al decidir si registrarse o solicitar registrarse para votar, o su derecho a elegir su propio partido político u otra preferencia política. , puede presentar una queja ante el Secretario de Estado en el Capitolio del Estado, 600 Dexter Avenue Suite E-208, Montgomery, AL 36130 o llamando al 334-242-7210 o al 1-800-274-VOTE (1-800-274- 8683).",
            ifansweryes: "Si respondió 'Sí' a cualquiera de las preguntas anteriores, el DHR de Alabama puede derivarlo a un especialista local en violencia doméstica. El especialista podrá comunicarse con usted a través del número de teléfono o la dirección proporcionada en el formulario de redeterminación de TANF. ¿Quieres una referencia? ",
            finalnote: "Si se encuentra en peligro inmediato, llame al 911 o a la línea directa de violencia doméstica de Alabama al 1-800-650-6522 (TTY para personas sordas: 1-800-787-3224).",
            voterQuestion: "Si no está registrado para votar en el lugar donde vive ahora, ¿le gustaría solicitar registrarse para votar aquí hoy?",
            addContactInfoTitle: "Cambiar mi dirección",
            addContactInfoHeader: "Cambiar mi dirección",
            contactChangedQuestion:
              "¿Se han producido cambios en su información de contacto? (Obligatorio)",
            required: "Obligatorio",
            requiredErrorMessage: "Escoja una opción",
            addContactInfoSubHeader:
              'Para presentar un Informe provisional del SNAP en línea, debe proporcionar una dirección postal. Si no tiene una dirección a donde DHR pueda enviarle correspondencia, llame a la línea de la Fuente de alimentos de Project Bread al<a href="tel:18006458333">1-800-645-8333</a> o diríjase a la oficina local de DHR.',
            labelFirstName: "¿Cuál es su nombre?",
            firstNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            middleName: "¿Cuál es su segundo nombre?",
            middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            lastNamelabel: "¿Cuál es su apellido?",
            lastNameErrMsg: "Requerido: ingrese el apellido",
            labelSuffix: "Sufijo",
            suffixOption: ["Jr.",
            "Sr.",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Esq",],
            dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
            resSameAddressLabel: "Igual que el domicilio",
            dobAlertMsg: "Por favor ingresa una fecha de nacimiento valida",
            ssnLabel: "¿Cuál es su número de seguro social?",
            inputClearLabel: "No tengo",
            ssnErrMsg: "Por favor ingrese un número de seguro social válido.",
            headerMyContactInfo: "Mi información de contacto",
            houselessLabel: "¿Se encuentra su grupo familiar sin vivienda?",
            resAddressLabel: "Dirección residencial",
            mailingAddresssLabel: "Dirección postal",
            houselessErrMsg: "Seleccione si tiene o no su hogar",
            houseLessOptions: ["Sí", "No"],
            addrQuestionlabel: "¿Cuál es su dirección?",
            addressErrmsg: "Por favor, ingrese una dirección válida",
            mailingAddressErrmsg: "Por favor ingrese una dirección postal.",
            homeAddressErrmsg: "Por favor ingrese una dirección particular.",
            validZipcode: "Por favor ingrese un código postal válido",
            alsoMailingLabel: "¿Es igual a su dirección postal?",
            optionsSendMail: "Sí, enviar correo allí. ",
            optionsNoDiffAddress:
              "No, utilizo una dirección diferente para el correo postal.",
            mailOptions: [
              "Sí, enviar correo allí.",
              "No, utilizo una dirección diferente para el correo postal.",
            ],
            mailingQlabel: "¿Cuál es su dirección postal?",
            zipCodeErrmsg: "Por favor ingrese un código postal válido",
            masZipCodeErrmsg: `Si se mudó fuera de Massachussetts, llame ${caseManagerPhone}.`,
            citiErrmsg: "Por favor introduzca una ciudad válida.",
            countyErrmsg: "Por favor ingrese un condado válido.",
            bestPhoneqlabel:
              "¿Cuál es el mejor número de teléfono para comunicarse con usted?",
            inlineHelp: "Este es el número que el DHR utilizará para llamarlo.",
            validPhoneAlert: "Por favor ingrese un número de teléfono válido",
            editButtonLabel: "Editar",
            doneButtonlabel: "Listo",
            phoneEditTitle: "Editar los detalles de mi teléfono",
            phoneLabel: "Teléfono principal",
            phoneNotificationQuestion:
              "¿Permitir notificaciones por mensaje de texto?",
            phoneNotificationAgreeLabel:
              "DHR podría enviarle mensajes de texto sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
            emailEditTitle: "Dirección de correo electrónico",
            emailLabel: "Editar mi dirección de correo electrónico",
            emailNotificationQuestion:
              "Permitir notificaciones por correo electrónico?",
            emailNotificationAgreeLabel:
              "DHR podría enviarle correos electrónicos sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
            emailAddressLabel: "¿Cuál es su dirección de correo electrónico?",
            emailErrormsg:
              "Por favor, introduce una dirección de correo electrónico válida",
            titleEmergency: "Beneficios de SNAP de emergencia",
            headerEmergency: "Beneficios de SNAP de emergencia",
            emergencySnap:
              "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
            incomeMoneylabel:
              "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
            inlineHelpLabel:
              "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
            optionYesNo: ["Sí", "No"],
            alertSelectIncome: "Por favor seleccione el ingreso que paga",
            incomeLesslabel:
              "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
            monthlyIncomeoptions: ["Sí", "No"],
            selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
            migrantBefore: "¿Es usted un ",
            migrantTrigger: " trabajador migrante",
            migrantAfter: " y su dinero en el banco es $100 o menos?",
            migrantHelp:
              "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
            migrantOptions: ["Sí", "No"],
            migrantErrmsg: "Por favor selecciona migrante pagas",
            headerMoreAbout: "Más sobre mí",
            areUsBefore: "¿Es usted ",
            areUsTrigger: "ciudadano de EE. UU.",
            areUsafter: "?",
            eligibleHelp:
              "Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
            chooseErrmsg: "Por favor elija ciudadano",
            eligibleMemberHelp:
              "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
            eligibleOptions: ["Sí", "No"],
            LANGUAGES: [
              "Inglés",
              "Español",
              "Portugués",
              "Francés",
              "Criollo haitiano",
              "Chino",
              "Vietnamita",
              "Camboyano",
              "Coreano",
              "Ruso",
              "Italiano",
              "Polaco",
              "Árabe",
            ],
            laguageBefore: "¿Qué idioma ",
            languageTrigger: " prefiere hablar",
            languageAfter: "?",
            comfortLanHelp:
              "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
            languageErrMsg: "Por favor, elija el idioma",
            genderLabel: "¿Cuál es su sexo?",
            genderOptions: ["Masculino", "Femenino"],
            genderErrmsg: "Por favor elija género",
            labelEthnicity: "¿Cuál es tu origen étnico?",
            helpEthnicity:
              "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
            hisPanicOption: "Hispano/Latino",
            nonhisPanicOption: "No hispano/Latino",
            raceLabel: "¿Cuál es tu raza?",
            raceHelp:
              "Le preguntamos su raza para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
            optionAIdisplay: "Indio americano",
            optionAsiandisplay_VALUE: "Asiático",
            optionAsiandisplay: "Asiático",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Negro/Afroamericano",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Isleño del Pacífico",
            optionwhitedisplay_VALUE: "Blanco",
            optionwhitedisplay: "Blanco",
            declineToAnswerLabel: "Me niego a responder",
            tripCountErrMsgLen:
              "Ingrese 6 caracteres como máximo por conteo de desconexiones",
          };

        case PT: //portugeesh
          return {
            title: "Dados de contato",
            header: "Minhas informações de contato",
            addContactInfoTitle: "Alterar meu endereço",
            addContactInfoHeader: "Alterar meu endereço",
            contactChangedQuestion:
              "Houve alguma alteração nos seus dados de contato? (Obrigatório)",
            required: "Obrigatório",
            requiredErrorMessage: "Por favor escolha uma opção",
            addContactInfoSubHeader:
              'Para enviar um Relatório Interino SNAP on-line, você deve fornecer um endereço para correspondência. Se você não tiver um endereço para o qual o DHR possa enviar correspondências, ligue para a Linha Direta do projeto Project Bread Food Source no número <a href="tel:18006458333">1-800-645-8333</a> ou acesse o escritório local do DHR.',
            labelFirstName: "Qual é o seu primeiro nome?",
            firstNameErrMsg: "Obrigatório - Digite o primeiro nome",
            middleName: "Qual é o seu nome do meio?",
            middleNameErrMsg: "Digite o nome do meio",
            lastNamelabel: "Qual é o seu último nome?",
            lastNameErrMsg: "Obrigatório - Digite o sobrenome",
            labelSuffix: "Sufixo",
            suffixOption: ["Jr.",
            "Sr.",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Esq",],
            dateOfBirthLabel: "Qual é a sua data de nascimento?",
            dobAlertMsg: "Por favor, insira uma data de nascimento válida ",
            ssnLabel: "Qual é o número do seu seguro social?",
            inputClearLabel: "Eu não tenho",
            ssnErrMsg: "Digite um Número de Segurança Social válido",
            headerMyContactInfo: "Minhas informações de contato",
            houselessLabel: "Sua família não tem moradia?",
            resAddressLabel: "Endereço residencial",
            resSameAddressLabel: "O mesmo endereço residencial",
            mailingAddresssLabel: "Endereço de correspondência",
            houselessErrMsg: "Selecione se você tem casa ou não",
            houseLessOptions: ["Sim", "Não"],
            addrQuestionlabel: "Qual é o seu endereço?",
            addressErrmsg: "Digite um endereço válido",
            validZipcode: "Por favor insira um código postal válido",
            alsoMailingLabel:
              "Esse também é o seu endereço de correspondência?",
            optionsSendMail:
              "Sim, envie as correspondências para esse endereço. ",
            optionsNoDiffAddress:
              "Não, eu uso um endereço diferente para correspondência.",
            mailOptions: [
              "Sim, envie as correspondências para esse endereço.",
              "Não, eu uso um endereço diferente para correspondência.",
            ],
            mailingQlabel: "Qual é o seu endereço de correspondência? ",
            zipCodeErrmsg: "Por favor insira um código postal válido",
            masZipCodeErrmsg: `Caso tenha se mudado de Massachussets, ligue para ${caseManagerPhone}.`,
            citiErrmsg: "Digite uma cidade válida",
            bestPhoneqlabel:
              "Qual é o melhor número de telefone para falar com você?",
            inlineHelp:
              "Esse será o número que o DHR irá usar para falar com você.",
            validPhoneAlert: "Por favor insira um número de telefone válido",
            editButtonLabel: "Editar",
            doneButtonlabel: "Concluir",
            phoneEditTitle: "Editar of detalhes do meu telefone",
            phoneLabel: "Número de telefone principal",
            phoneNotificationQuestion:
              "Permitir notificações por mensagens de texto",
            phoneNotificationAgreeLabel:
              "O DHR pode enviar mensagens de texto sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações.",
            emailEditTitle: "Endereço de e-mail",
            emailLabel: "Editar of detalhes do meu endereço.",
            emailNotificationQuestion: "Permitir notificações por e-mail?",
            emailNotificationAgreeLabel:
              "O DHR pode enviar e-mails sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações",
            emailAddressLabel: "Qual é o seu endereço de e-mail?",
            emailErrormsg: "Por favor insira um endereço de e-mail válido",
            titleEmergency: "Benefício SNAP emergencial",
            headerEmergency: "Benefício SNAP emergencial",
            emergencySnap:
              "O benefício SNAP emergencial é um benefício inicial, ao qual você é elegível, que você recebe enquanto analisamos a sua solicitação.",
            incomeMoneylabel:
              "A sua renda e o dinheiro que você tem no banco são menores do que as suas despesas residenciais mensais?",
            inlineHelpLabel:
              'Por exemplo: se você tem US$ 100 como renda mais o dinheiro que tem no banco, mas gasta US$ 101 em despesas residenciais mensais, clique em "Sim".',
            optionYesNo: ["Sim", "Não"],
            alertSelectIncome: "Selecione a renda que você paga",
            incomeLesslabel:
              "A sua renda mensal é menor que US$ 150 e você tem US$ 100 ou menos no banco?",
            monthlyIncomeoptions: ["Sim", "Não"],
            selectIncomeErrmsg: "Selecione a renda que você paga",
            migrantBefore: "Você é um ",
            migrantTrigger: "trabalhador migrante",
            migrantAfter: " e tem US$ 100 ou menos no banco?",
            migrantHelp:
              "Um trabalhador migrante é alguém que vai de cidade em cidade em busca de trabalhos sazonais em fazendas.",
            migrantOptions: ["Sim", "Não"],
            migrantErrmsg: "Selecione o migrante que você paga",
            headerMoreAbout: "Mais sobre mim",
            areUsBefore: "Você é ",
            areUsTrigger: "cidadão americano",
            areUsafter: "?",
            eligibleHelp:
              "Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
            chooseErrmsg: "Escolha cidadão",
            eligibleMemberHelp:
              "Mesmo se você não for um cidadão americano, você ou outros membros da família ainda podem ser elegíveis.",
            eligibleOptions: ["Sim", "Não"],
            laguageBefore: "Em qual idioma você ",
            LANGUAGES: [
              "Inglês",
              "Espanhol",
              "Português",
              "Francês",
              "Créole haitiano",
              "Chinês",
              "Vietnamita",
              "Kmè",
              "Coreano",
              "Russo",
              "Italiano",
              "Polonês",
              "Árabe",
            ],
            languageTrigger: "prefere se comunicar",
            languageAfter: "?",
            comfortLanHelp:
              "Informe em qual idioma você se sente mais confortável para se comunicar, para que o DHR faça as entrevistas nesse idioma.",
            languageErrMsg: "Escolha o idioma",
            genderLabel: "Qual é o seu gênero?",
            genderOptions: ["Masculino", "Feminino"],
            genderErrmsg: "Escolha o gênero",
            labelEthnicity: "Qual é a sua etnia?",
            helpEthnicity:
              "Perguntamos a sua etnia para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
            raceLabel: "Qual é a sua raça?",
            hisPanicOption: "Hispânico/Latino",
            nonhisPanicOption: "Não-Hispânico/Latino",
            raceHelp:
              "Perguntamos a sua raça para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
            optionAIdisplay: "Americano nativo",
            optionAsiandisplay_VALUE: "Asiático",
            optionAsiandisplay: "Asiático",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Negro/Afro-americano",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Nativo das ilhas do Pacífico",
            optionwhitedisplay_VALUE: "Branco",
            optionwhitedisplay: "Branco",
            declineToAnswerLabel: "Não quero responder",
            tripCountErrMsgLen:
              "Digite um valor de medidor com até 6 caracteres",
          };

        case ZH: //China
          return {
            title: "联系信息",
            header: "我的联系信息",
            addContactInfoTitle: "更改我的地址",
            addContactInfoHeader: "更改我的地址",
            contactChangedQuestion: "您的联系信息是否有任何更改？（必填）",
            required: "必填",
            requiredErrorMessage: "请选择一个选项",
            resSameAddressLabel: "与居住地址相同",
            addContactInfoSubHeader:
              '如需在线提交 SNAP《中间报告》，您必须提供邮寄地址。如果您没有可接收 DHR 所发邮件的地址，请致电面包项目食物来源热线 (Project Bread Food Source Hotline) <a href="tel:18006458333">1-800-645-8333</a> 或前往您当地的 DHR 办事处.',
            labelFirstName: "您的名字",
            firstNameErrMsg: "必填 - 請輸入名字",
            middleName: "您的中间名",
            middleNameErrMsg: "請輸入中間名",
            lastNamelabel: "您的姓氏",
            lastNameErrMsg: "必填 - 請輸入姓氏",
            labelSuffix: "后缀",
            suffixOption: ["一世", "II", "III", "IV", "V", "小", "高級"],
            dateOfBirthLabel: "您的出生日期？",
            dobAlertMsg: "請輸入有效的出生日期",
            ssnLabel: "你的社保号码？",
            inputClearLabel: "我没有社保",
            ssnErrMsg: "請輸入有效的社會安全號碼",
            headerMyContactInfo: "我的联系信息",
            houselessLabel: "您的家庭是否无家可归？",
            houselessErrMsg: "請選擇您是否有家",
            houseLessOptions: ["是", "否"],
            addrQuestionlabel: "您的家庭地址？",
            addressErrmsg: "请输入有效地址",
            validZipcode: "請輸入有效的郵政編碼",
            alsoMailingLabel: "这是否也是您的邮寄地址？",
            optionsSendMail: "是，可以发送信件到这里。",
            optionsNoDiffAddress: "否，我的邮箱地址是另外一个。",
            mailOptions: [
              "是，可以发送信件到这里。",
              "否，我的邮箱地址是另外一个。",
            ],
            mailingQlabel: "您的邮箱地址？",
            zipCodeErrmsg: "請輸入有效的郵政編碼",
            masZipCodeErrmsg: `如果您搬出麻省，请致电 ${caseManagerPhone}.`,
            citiErrmsg: "请输入有效城市",
            bestPhoneqlabel: "您最常使用的电话号码？",
            inlineHelp: "这将是DTA与您联络所使用的电话号码。",
            validPhoneAlert: "請輸入一個有效的電話號碼",
            editButtonLabel: "编辑",
            doneButtonlabel: "完成",
            mailingAddresssLabel: "邮寄地址",
            phoneEditTitle: "编辑我的手机详情",
            phoneLabel: "主要电话号码",
            phoneNotificationQuestion: "允许短信通知？",
            phoneNotificationAgreeLabel:
              "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的短信。如果您希望选择加入，请点击此处.",
            emailEditTitle: "电子邮箱地址",
            emailLabel: "编辑我的电子邮件地址",
            emailNotificationQuestion: "允许电子邮件通知?",
            emailNotificationAgreeLabel:
              "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的电子邮件。如果您希望选择加入，请点击此处",
            emailAddressLabel: "您的邮箱地址？",
            emailErrormsg: "請輸入有效的電子郵件地址",
            titleEmergency: "紧急SNAP补助",
            headerEmergency: "紧急SNAP补助",
            emergencySnap:
              "紧急SNAP补助是您在我们审核您的申请期间可能获得的第一次补助。",
            incomeMoneylabel: "您的收入和银行存款总和是否少于您的每月家庭开支?",
            inlineHelpLabel:
              "例如，如果您的收入和银行存款加起来等于100美元，而您的每月家庭开支是101美元，请选择是。",
            optionYesNo: ["是", "否"],
            alertSelectIncome: "請選擇您支付的收入",
            incomeLesslabel:
              "您的月收入是否低于150美元，您的银行存款是否少于100美元？",
            monthlyIncomeoptions: ["是", "否"],
            selectIncomeErrmsg: "請選擇您支付的收入",
            migrantBefore: "您是否是 ",
            migrantTrigger: "外来务工人员",
            migrantAfter: " 您的银行存款是否少于100美元？",
            migrantHelp:
              "外来务工人员是指从一个地方搬迁到另一个地方做季节性工作的人。",
            migrantOptions: ["是 ", "否"],
            migrantErrmsg: "請選擇你支付的移民",
            headerMoreAbout: "关于我的更多信息",
            areUsBefore: "您是否是 ",
            areUsTrigger: "美国公民",
            areUsafter: "?",
            eligibleHelp:
              "要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
            chooseErrmsg: "請選擇公民",
            eligibleMemberHelp:
              "即使您并不是美国公民，您或其他家庭成员也依然可能符合资格。",
            eligibleOptions: ["是", "否"],
            resAddressLabel: "住宅地址",
            laguageBefore: "您 ",
            LANGUAGES: [
              "英语",
              "西班牙语",
              "葡萄牙语",
              "法语",
              "海地克里奥尔语",
              "中文",
              "越南语",
              "柬埔寨语",
              "朝鮮的",
              "俄语",
              "意大利语",
              "波兰语",
              "阿拉伯语",
            ],
            languageTrigger: "日常使用哪种语言交流",
            languageAfter: "?",
            comfortLanHelp:
              "请告诉我们您最熟悉的语言，以便DTA可以确保使用您的语言进行面试。",
            languageErrMsg: "請選擇語言",
            genderLabel: "您的性别？",
            genderOptions: ["男", "女"],
            genderErrmsg: "請選擇性別",
            labelEthnicity: "您的民族？",
            helpEthnicity:
              "我们询问您的民族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
            hisPanicOption: "西班牙/拉丁美洲",
            nonhisPanicOption: "非西班牙/拉丁美洲",
            raceLabel: "您的种族？",
            raceHelp:
              "我们询问您的种族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
            optionAIdisplay: "美洲印第安人",
            optionAsiandisplay_VALUE: "亚洲人",
            optionAsiandisplay: "亚洲人",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "黑人/非裔美国人",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "太平洋岛民",
            optionwhitedisplay_VALUE: "白人",
            optionwhitedisplay: "白人",
            declineToAnswerLabel: "拒绝回答",
            tripCountErrMsgLen: "请输入不超过 6 个字符以完成行程计数",
          };

        case VI: //Vietnam
          return {
            title: "Thông tin liên lạc",
            header: "Thông tin liên lạc",
            addContactInfoTitle: "Thay đổi địa chỉ của tôi",
            addContactInfoHeader: "Thay đổi địa chỉ của tôi",
            contactChangedQuestion:
              "Đã có bất kỳ thay đổi nào về thông tin liên lạc của quý vị không? (Bắt buộc)",
            required: "Bắt buộc",
            resSameAddressLabel: "Giống như địa chỉ nhà ở",
            requiredErrorMessage: "Vui lòng chọn một lựa chọn",
            addContactInfoSubHeader:
              'Để gửi Báo cáo Giữa kỳ SNAP trực tuyến, quý vị phải cung cấp địa chỉ gửi thư. Nếu quý vị không có địa chỉ mà DHR có thể gửi tới, xin vui lòng gọi Đường dây nóng của Project Bread Food Source theo sốat <a href="tel:18006458333">1-800-645-8333</a> hoặc đến Văn phòng DHR địa phương của quý vị.',
            labelFirstName: "Tên của quý vị?",
            firstNameErrMsg: "Bắt buộc - Vui lòng nhập tên",
            middleName: "Tên lót của quý vị?",
            middleNameErrMsg: "Vui lòng nhập tên đệm",
            lastNamelabel: "Họ của quý vị?",
            lastNameErrMsg: "Bắt buộc - Vui lòng nhập họ",
            labelSuffix: "Tước hiệu",
            suffixOption: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "Jr.", "Sr."],
            dateOfBirthLabel: "Ngày tháng năm sinh của quý vị?",
            dobAlertMsg: "Vui lòng nhập một ngày sinh hợp lệ",
            ssnLabel: "Số an sinh xã hội của quý vị?",
            inputClearLabel: "Tôi không có số ASXH",
            mailingAddresssLabel: "Địa chỉ gửi thư",
            ssnErrMsg: "Vui lòng nhập Số an sinh xã hội hợp lệ",
            headerMyContactInfo: "Thông tin liên lạc",
            resAddressLabel: "Địa chỉ cư trú",
            houselessLabel: "Gia đình quý vị có chỗ ở không?",
            houselessErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            houseLessOptions: ["Có", "Không"],
            addrQuestionlabel: "Địa chỉ của quý vị là gì?",
            addressErrmsg: "Vui lòng nhập vào địa chỉ có thật",
            validZipcode: "Vui lòng nhập một mã zip hợp lệ",
            alsoMailingLabel: "Đây cũng là địa chỉ gửi thư của quý vị?",
            optionsSendMail: "Phải, hãy gửi thư đến đó.",
            optionsNoDiffAddress:
              "Không, tôi dùng một địa chỉ khác để nhận thư.",
            mailOptions: [
              "Phải, hãy gửi thư đến đó.",
              "Không, tôi dùng một địa chỉ khác để nhận thư.",
            ],
            mailingQlabel: "Địa chỉ gởi thư của quý vị là gì?",
            zipCodeErrmsg: "Vui lòng nhập một mã zip hợp lệ",
            masZipCodeErrmsg: `Nếu bạn chuyển ra ngoài Alabama, vui lòng gọi ${caseManagerPhone}.`,
            citiErrmsg: "Vui lòng nhập vào tên thành phố",
            bestPhoneqlabel:
              "Số điện thoại nào để liên lạc với quý vị tốt nhất?",
            inlineHelp: "Đây là số mà DHR sẽ dùng để gọi cho quý vị.",
            validPhoneAlert: "Xin vui lòng nhập một số điện thoại hợp lệ",
            editButtonLabel: "Chỉnh sửa",
            doneButtonlabel: "Hoàn tất",
            phoneEditTitle: "Chỉnh sửa chi tiết điện thoại của tôi",
            phoneLabel: "Số điện thoại chính",
            phoneNotificationQuestion: "Cho phép các thông báo bằng in nhắn ?",
            phoneNotificationAgreeLabel:
              "DHR có thể gửi cho quý vị tin nhắn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
            emailEditTitle: "Địa chỉ email",
            emailLabel: "Chỉnh sửa địa chỉ email của tôi",
            emailNotificationQuestion: "Cho phép các thông báo bằng email?",
            emailNotificationAgreeLabel:
              "DHR có thể gửi email cho bạn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
            emailAddressLabel: "Địa chỉ email của quý vị là gì?",
            emailErrormsg: "Vui lòng nhập một địa chỉ E-mail hợp lệ",
            titleEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
            headerEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
            emergencySnap:
              "Trợ cấp SNAP khẩn cấp là một khoản trợ cấp đầu tiên mà quý vị có thể hội đủ điều kiện nhận được trong khi chúng tôi xem xét đơn xin của quý vị. ",
            incomeMoneylabel:
              "Có phải thu nhập và tiền trong ngân hàng của quý vị cộng lại ít hơn chi phí nhà ở hàng tháng của quý vị không?",
            inlineHelpLabel:
              "Ví dụ, nếu quý vị có thu nhập 100 Đô la cộng với tiền trong ngân hàng, nhưng phải trả 101 Đô la cho chi phí nhà ở hàng tháng thì chọn Yes (Có). ",
            optionYesNo: ["Có", "Không"],
            alertSelectIncome: "Vui lòng chọn thu nhập bạn phải trả",
            incomeLesslabel:
              "Thu nhập hàng tháng của quý vị ít hơn 150 Đô la và tiền của quý vị trong ngân hàng là 100 đô la hoặc ít hơn phải không?",
            monthlyIncomeoptions: ["Có", "Không"],
            selectIncomeErrmsg: "Vui lòng chọn thu nhập bạn phải trả",
            migrantBefore: "Quý vị có phải là ",
            migrantTrigger: "công nhân di cư",
            migrantAfter:
              " và số tiền của quý vị trong ngân hàng là 100 Đô la hoặc ít hơn?",
            migrantHelp:
              "Một công nhân di cư là người di chuyển từ nơi này đến nơi khác đang làm việc hoặc đang tìm kiếm việc làm ở nông trại theo thời vụ.",
            migrantOptions: ["Có", "Không"],
            migrantErrmsg: "Hãy chọn người di dân bạn phải trả",
            headerMoreAbout: "Thông tin thêm về tôi",
            areUsBefore: "Quý vị có là một ",
            areUsTrigger: "Công dân Hoa kỳ",
            areUsafter: "?",
            eligibleHelp:
              "Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
            chooseErrmsg: "Vui lòng chọn công dân",
            eligibleMemberHelp:
              "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong gia đình vẫn có thể đủ điều kiện để hưởng các lợi ích.",
            eligibleOptions: ["Có", "Không"],
            laguageBefore: "Quý vị ",
            LANGUAGES: [
              "Tiếng Anh",
              "Tiếng Tây Ban Nha",
              "Tiếng Bồ Đào nha",
              "Tiếng Pháp",
              "Tiếng Haitian Creole",
              "Tiếng Trung quốc",
              "Tiếng Việt",
              "Tiếng Campuchia",
              "Hàn Quốc",
              "Tiếng Nga",
              "Tiếng Ý",
              "Tiếng Ba Lan",
              "Tiếng Ả rập",
            ],
            languageTrigger: " thích nói ngôn ngữ nào hơn",
            languageAfter: "?",
            comfortLanHelp:
              "Vui lòng cho chúng tôi biết ngôn ngữ mà quý vị cảm thấy thoải mái nhất khi nói chuyện để DHR có thể bảo đảm tiến hành các cuộc phỏng vấn bằng ngôn ngữ đó.",
            languageErrMsg: "Vui lòng chọn ngôn ngữ",
            genderLabel: "Giới tính của quý vị là gì?",
            genderOptions: ["Nam", "Nữ"],
            genderErrmsg: "Vui lòng chọn giới tính",
            labelEthnicity: "Quý vị thuộc dân tộc nào?",
            hisPanicOption: "Gốc Tây Ban Nha, Bồ Đào Nha / La tinh",
            nonhisPanicOption:
              "Không phải gốc Tây Ban Nha, Bồ Đào nha /La tinh",
            helpEthnicity:
              "Chúng tôi hỏi về dân tộc của quý vị nhằm bảo đảm việc đối xử bình đẳng và khả năng tiếp cận với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
            raceLabel: "Quý vị thuộc chủng tộc nào?",
            raceHelp:
              "Chúng tôi hỏi về chủng tộc của quý vị để bảo đảm việc đối xử bình đẳng và khả năng tiếp cận đối với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
            optionAIdisplay: "Người Mỹ bản xứ",
            optionAsiandisplay_VALUE: "Người Châu Á",
            optionAsiandisplay: "Người Châu Á",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Người Mỹ da đen/Mỹ gốc Phi",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Người Quần đảo Thái Bình Dương",
            optionwhitedisplay_VALUE: "Người da trắng",
            optionwhitedisplay: "Người da trắng",
            declineToAnswerLabel: "Từ chối trả lời",
            tripCountErrMsgLen:
              "Vui lòng nhập vào bằng hoặc ít hơn 6 ký tự cho chuyến đi",
          };

        case HI:
          return {
            title: "Konsènan mwen menm",
            header: "Konsènan mwen menm",
            labelFirstName: "Ki prenon w?",
            firstNameErrMsg: "Obligatwa - Tanpri antre prenon",
            middleName: "Ki dezyèm prenon w?",
            middleNameErrMsg: "Tanpri antre dezyèm prenon",
            lastNamelabel: "Ki siyati w?",
            lastNameErrMsg: "Obligatwa - Tanpri antre siyati",
            labelSuffix: "Sifiks",
            suffixOption: ["I", "II", "III", "4", "5", "Fis", "Papa"],
            dateOfBirthLabel: "Ki dat nesans ou?",
            dobAlertMsg: "Tanpri antre yon dat nesans ki valid",
            ssnLabel: "Ki nimewo Sekirite Sosyal ou?",
            inputClearLabel: "M pa genyen youn",
            ssnErrMsg: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
            headerMyContactInfo: "Enfòmasyon kontak mwen",
            houselessLabel: "Èske fwaye w la sanzabri?",
            houselessErrMsg: "Tanpri chwazi si ou gen kay ou oswa pa genyen",
            houseLessOptions: ["Wi", "Non"],
            addrQuestionlabel: "Ki adrès ou?",
            validZipcode: "Tanpri antre yon kòd postal ki valid",
            alsoMailingLabel: "Èske se adrès postal ou a tou?",
            optionsSendMail: "Wi, voye lapòs la.",
            optionsNoDiffAddress: "Non, mwen itilize yon lòt adrès pou lapòs.",
            mailOptions: [
              "Wi, voye lapòs la.",
              "Non, mwen itilize yon lòt adrès pou lapòs.",
            ],
            mhProviderMemberSearch: ["Prenon / Siyati", "Kòd Postal"],
            agedOver60: ["Plis pase 60 an"],
            mailingQlabel: "Ki adrès postal ou?",
            zipCodeErrmsg: "Tanpri antre yon kòd postal ki valid",
            masZipCodeErrmsg: `Si w te demenaje andeyò Alabama, tanpri rele ${caseManagerPhone}.`,
            citiErrmsg: "Tanpri antre yon vil ki valid",
            addressErrmsg: "Tanpri antre yon adrès ki valid",
            bestPhoneqlabel: "Ki pi bon nimewo telefòn pou kontakte ou?",
            inlineHelp: "Sa a se nimewo DHR a pral itilize pou kontakte w.",
            validPhoneAlert: "Tanpri antre yon nimewo telefòn ki valid",
            emailAddressLabel: "Ki adrès imel ou?",
            emailErrormsg: "Tanpri antre yon adrès imel ki valid.",
            titleEmergency: "Benefis SNAP nan Ijans",
            headerEmergency: "Benefis SNAP nan Ijans",
            emergencySnap:
              "Benefis SNAP Ijans se yon premye benefis ou ka kalifye pou li pandan n ap revize aplikasyon w lan.",
            incomeMoneylabel:
              "Èske revni w ak lajan labank ou ajoute ansanm reprezante mwens pase depans lojman mansyèl ou?",
            inlineHelpLabel:
              "Pa egzanp, si w gen $100 revni plis lajan labank, men ou peye $101 pou depans lojman mansyèl, chwazi Wi.",
            optionYesNo: ["Wi", "Non"],
            alertSelectIncome: "Tanpri chwazi revni ou peye",
            incomeLesslabel:
              "Èske revni mansyèl ou mwens pase $150 epi èske lajan labank ou se $100 oswa mwens?",
            monthlyIncomeoptions: ["Wi", "Non"],
            selectIncomeErrmsg: "Tanpri chwazi revni ou peye",
            migrantBefore: "Èske w se yon ",
            migrantTrigger: "travayè migran",
            migrantAfter: " epi èske lajan labank ou se $100 oswa mwens?",
            migrantHelp:
              "Yon travayè migran se yon moun ki deplase soti yon kote pou ale nan yon lòt pou fè oswa chèche travay agrikòl sezonye.",
            migrantOptions: ["Wi", "Non"],
            migrantErrmsg: "Tanpri chwazi migran ou peye",
            headerMoreAbout: "Plis konsènan mwen menm",
            areUsBefore: "Èske w se yon ",
            areUsTrigger: "sitwayen Ameriken",
            areUsafter: "?",
            eligibleHelp:
              "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
            chooseErrmsg: "Tanpri chwazi sitwayen",
            eligibleMemberHelp:
              "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
            eligibleOptions: ["Wi", "Non"],
            LANGUAGES: [
              "Angle",
              "Panyòl",
              "Pòtigè",
              "Franse",
              "Kreyòl Ayisyen",
              "Chinwa",
              "Vyetnamyen",
              "Kmè",
              "Korean",
              "Ris",
              "Italyen",
              "Polonè",
              "Arabik",
            ],
            languageBefore: "Ki lang ou ",
            languageTrigger: "prefere pale ",
            languageAfter: "?",
            comfortLanHelp:
              " Tanpri di nou ki lang ou pi alèz pou pale pou DHR ka asire li ke li fè entèvyou w yo nan lang sa a.",
            languageErrMsg: "Tanpri chwazi ki lang",
            genderLabel: "Ki sèks ou?",
            genderOptions: ["Gason", "Fi"],
            genderErrmsg: "Tanpri chwazi sèks",
            labelEthnicity: "Ki etnisite ou?",
            helpEthnicity:
              "Nou mande etnisite w pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Ispanik/Latino",
            raceLabel: "Ki ras ou?",
            raceHelp:
              "Nou mande ras ou pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
            optionAIdisplay: "Ameriken Endyen",
            optionAsiandisplay_VALUE: "Azyatik",
            optionAsiandisplay: "Azyatik",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Nwa/Afriken Ameriken",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "moun Zile Pasifik",
            optionwhitedisplay_VALUE: "WHITE",
            optionwhitedisplay: "Blan",
            declineToAnswerLabel: "Refize reponn",
            tripCountErrMsgLen:
              "Tanpri antre mwens pase/egal a 6 karaktè pou kantite vwayaj",
          };

        default:
          return;
      }
    }

    case languageConstants.beforeYouStart: {
      switch (language) {
        case EN:
          return {
            heading: "SNAP Six-Month Report",
            howApplyLabel: "SNAP Six-Month Report",
            emailTextLabel: "Email address",
            fieldLabel1:"It is time for your six-month report. ",
            fieldOverDueLabel: "You must complete this form and submit it to the county office with verification of any changes by the end of the month or you must reapply for SNAP benefits.",
            fieldLabelCompleteForm:"You must complete this form and submit it to the county office with verification of any changes between the 1st and 10th of",
            fieldLabelCompleteFormsecond: "or your SNAP case will close at the end of",
            fieldLabel2: "Make sure to answer all the questions within this form. Each section displays if you are required to verify the changes. If you do not sign and submit it with all required verifications, your benefits may be delayed, reduced, or terminated. Call your local office if you need help completing this form. If you need help with reading, writing, hearing, etc., under the Americans with Disabilities Act, you should make your needs known to the county office where you live. If you have completed and submitted a paper six-month report form, please do not complete this form.",
            fieldLabel3: "You may complete your SNAP Six-Month Report and/or submit your verification documents using the following methods:",
            recertMethods: [
              "Online by clicking <strong>Start SNAP Six-Month Report.</strong> This is the fastest way!",
              {
                before:
                  " If you do not wish to complete your SNAP application online, you may download and print your paper application form ",
                hereLinkText: "here.",
                after: " You can submit your form by:",
              },
              {
                before:
                  "Fax, mail, email or take your paper application to your local county DHR office by clicking ",
                hereLinkText: "here",
              },
            ],
            interimFaxText: [
              "Faxing, emailing, or mailing to your local county office (click ",
              " for more information).",
            ],
            interimBeforePicture: "Taking a picture of each page and uploading via the ACES DHR Self-Service portal ",
            interimbeforeStart: "Download and print your paper form ",
            boBackButtonLabel: "Go Back",
            getStartButtonLabel: "Start SNAP Six-Month Report",
            //sIncome:"'s gross earned income",
            sIncomebefore: "'s ",
            sIncometrigger: "Gross Earned Income",
            sIncometrigger1: "Earned Income",
            sIncomeAfter: " ",
            sIncomeHelp:
              "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. insurance",
            sIncomeHelp1:
              "Earned Income is money you receive from working before anything is taken out, such as taxes or health insurance",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: "'s ",
            sUIncometrigger: "Gross Unearned Income",
            sUIncometrigger1: "Unearned Income",
            sUIncomeAfter: " ",
            sUIncomeHelp:
              "Gross Unearned Income is money you receive from a source other than employment  before anything is taken out, such as taxes or health insurance",
              sUIncomeHelp1:
              "Unearned Income is money you receive from a source other than employment  before anything is taken out, such as taxes or health insurance",
              noEarnedIncomeText:"The total amount of earned income used in your monthly food assistance budget is $0.",
              noTanfEarnedIncomeText:"The total amount of earned income used in your monthly TANF budget is $0.",
              noTanfUnEarnedIncomeText:"The total amount of unearned income used in your monthly TANF budget is $0.",
            noUnearnedIncomeText:"The total amount of unearned income used in your monthly food assistance budget is $0.",
            nochildsupportPaymentText:"The total amount of child support payments used in your monthly food assistance budget is $0.",
            noShelterIncomeText:"The total amount of shelter and utility expenses used in your monthly food assistance budget is $0.",

            //sUIIncome:"'s gross unearned income",
            earningsPerMonthLabel: "Has anyone had a change in earnings (including earnings from self-employment) because they started or stopped a job or changed jobs? (Required)",
            editButtonLabel: "Edit",
            perLabel: "Per",
            ofLabel: "of",
            residentialAddr: "Home Address",
            mailingAddress: "Mailing address",
            fromMultiLang: " from ",
            renderAppNoLabel: "Your application no:",
            youMayLabelOne: "You may want to ",
            youMayLabeltwo: "download your application",
            youMayLabelThree: "for future reference.",
            whatisNextLabel: "What's Next?",
            dtaReachLabel:
              "The DHR will reach out to you within the next 5 days.",
            sendDocLabel:
              "Send in documents so that DHR can confirm your situation.",
            howDoSpeedLabel: "How do I speed up the process?",
            donotNeedLabel:
              "You do not need to wait for DHR to send you a list of verifications",
            sendLabelOne: "You can ",
            sendLabelTwo: "send us your documents ",
            sendLabelThree: "in many ways",
            toLearnOneLabel:
              "To learn more about documents that you may submit now,",
            toLearnOneLabelTwo: "click here",
            manyWayLabel:
              "There are many other ways to submit documents to us.",
            manyWayLabelTwo: "Find out more",
            howDoSubmitLabel: "How do I submit documents?",
            weSuggestLabel: "We suggest",
            weSuggestLabelOne: "creating an online account",
            weSuggestLabelTwo:
              "so you can upload documents, track the progress of your application, check your balance, and more!",
            findOutMoreLabel: "Find out more",
            createOnlineLabel: "Create an online account",
            orExistLabel: "Or exit without uploading any documents",
            renderSaveLabel: "To save your progress, create an account here.",
            laterOnLabel:
              "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
            orExitLabel: "Or exit without saving your work",
            changingLabel:
              "Chaning this value will delete the previous entered values in upcoming sections.",
            greatHereLabel: "Great! Here's what we got from you.",
            lookIncorrectLabel:
              "If any of it looks incorrect, feel free to edit.",
            IncomePerMonthLabel:
              "Has your household gross earned income changed by more than $100? (Required)",
            IncomeTafdcPerMonthLabel:
              "Has anyone started working? (Required)", 
            anotherHouseholdIncomeLabel:
              "Add another household member's earned income",
            anotherHouseholdIncomeLabel1:
              "Add another household member's employment income",
            anotherHouseholdUnEarnedIncomeLabel:
              "Add another household member's unearned income",
            unearnedIncome_ChangeQuestion:
              "Has your household's gross unearned income changed by more than $100? (Required)",
              unearnedIncomeSubText1: "Examples: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
              unearnedIncomeSubText2:"If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
              unearnedIncomeSubText3:"You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            applicationSummaryTitle: "Application Summary",
            applicationSummaryHeader: "Application Summary",
            viewApplicationLinkLabel: "view as pdf",
            iagreeTermsLabel: "I agree",
            signYourAppllication:
              "Sign your SNAP Six-Month Report by typing your full name below.(Required)",
            continueLabel: "Continue",
            saveAndContinueLabel: "Save and Continue",
            getStartedLabel: "Get Started",
            submitButtonLabel: "Submit SNAP Six-Month Report",
            submitSelfButtonLabel: "Submit Self-declare expenses",
            expenseFieldLabel: "This last section is about",
            expenseHouseutility: "Housing and utility costs",
            expenseCaring: "Caring for kids and adults",
            expenseChsupport: "Child Support",
            expenseMed: "Medical expenses",
            expenseAnyinfo:
              "Any information you provide here will provide a more accurate benefit amount.",
            removeHoHTitle: "Warning",
            removeHoHLabel:
              "You are attempting to remove a head of household. If this is correct, please select ‘Continue’ and a DHR worker will contact you for more information. If this was a mistake, please select ‘Go Back’.",
              removeNonHoHLabel:
              "You are attempting to remove a household member. If this is correct, please select ‘Continue’ and a DHR worker will contact you for more information. If this was a mistake, please select ‘Go Back’.",
              agreeContent: [
              "By signing, I agree that:",
              "I have read this entire form (or have had it read to me in a language that I understand), including the section about rights and responsibilities, and understand that I must comply with these rules;",
              "The information I am giving is true and complete to the best of my knowledge;",
              "I could go to prison or be required to pay fines if I knowingly give wrong or incomplete information; and DHR and other federal, state, and local officials may verify (check) any information I give.",
            ],
            didReportChanges: "I did report changes",
            didNotReportChanges: "I did not report changes",
            badgeChangedLabel: "Changed",
            badgeNewLabel: "New",
            agreeTermsErrorMsg: "Please agree to the terms.",
            signatureErrorMsg: "Please sign your Interim Report.",

            shelterExpense_ChangeQuestion:"Has your household shelter or utility expenses changed? (Required)",
            shelterExpenseSubText: "Note: If you have moved, you must submit proof of your shelter expenses. If you have not moved but report an increase in the following shelter expenses (rent, mortgage, property taxes or home insurance) and do not provide verification, the change will not be made until recertification.",
            incomePerMonthSubText: 'If you Answered "Yes" to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.',
            tanfincomePerMonthSubText: 'If you answered Yes, contact your TANF worker immediately if you have not already done so. You must complete this Redetermination even if you contact your worker about any questions.',
            tanfincomePerMonthSubText1: 'You must report any changes within 10 days of the change. Failure to do so may result in a reduction or termination of benefits. Contact your FA worker if you have questions.',
            earningsNote: 'NOTE: If you are an "Able-Bodied Adult without Dependents" (ABAWD), you must report when your work hours fall below 20 hours a week (or at least 80 hours a month). The change must be reported 10 days from the end of the month the change occurred.',
            signatureErrorMsg: "Please sign the SNAP Six-Month Report",
            signatureUserNameErrorMsg:
            "Signature needs to match the name in the application.",
            nonDiscriminationHeader: "USDA Nondiscrimination Statement",
            nonDiscriminationContent: "In accordance with federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, this institution is prohibited from discriminating on the basis of race, color,national origin, sex (including gender identity and sexual orientation), religious creed, disability, age,  political beliefs, or reprisal or retaliation for prior civil rights activity.",
            personDisabilities: "Program information may be made available in languages other than English. Persons with disabilities who require alternative means of communication to obtain program information (e.g., Braille, large print, audiotape, American Sign Language), should contact the agency (state or local)where they applied for benefits. Individuals who are deaf, hard of hearing or have speech disabilities may contact USDA through the Federal Relay Service at .",
            programComplaint: "To file a program discrimination complaint, a Complainant should complete a Form AD-3027,USDA Program Discrimination Complaint Form which can be obtained online at: ",
            usdaOffice: "from any USDA office, by calling (833) 620-1071, or by writing a letter addressed to USDA. The letter must contain the complainant's name,  address, telephone number, and a written description of the alleged discriminatory action in sufficient detail to inform the Assistant Secretary for Civil Rights (ASCR) about the nature and date of an alleged civil rights violation. The completed AD-3027 form or letter must be submitted to: ",
            mail: "Mail:",
            mailAddress: "Food and Nutrition Service, USDA 1320 Braddock Place, Room 334 Alexandria, VA 22314; or",
            mailAddressArray: ["Food and Nutrition Service, USDA","1320 Braddock Place, Room 334","Alexandria, VA 22314; or"],
            fax: " Fax:",
            orLabel: " or",
            eamilTitle: "Email: ",
            thisInstution: "This institution is an equal opportunity provider.",
            federalLaws: "You may also be fined up to $250,000, imprisoned up to 20 years, or both; and subject to  prosecution under other applicable federal laws.",
            hideInformation: "Hide information or give false information to get or continue  receiving SNAP",
            tradeOrSell: "Trade or sell your SNAP or EBT cards (also known as trafficking)",
            alterEbt: "Alter EBT cards to get SNAP you are not entitled to receive",
            alcoholicDrinksOrTobacco: "Use SNAP to buy ineligible items such as alcoholic drinks or tobacco",
            someoneElseSnapOrEbt: "Use someone else's SNAP or EBT cards for your houschold",
            iUnderstandThePenalty: " I understand the penalty warning above. I also understand that I will owe the value of any extra SNAP benefits I receive if I don't fully report changes in my household circumstances when required. I agree to provide proof of any changes I report.",
            iCertifyThatTheStatement: "I certify that the statements on this form are true and correct to the best of my knowledge.I understand that the information that I provide on this form may result in a reduction or termination of my benefits.",
            signatureandPenalty: "Signature and Penalty Warning.",
            whenYourHouseholdReceivesSnap: "When your household receives SNAP, you must follow the rules. If you do any of the following you  will lose your SNAP benefits for one year for the first violation, two years for the second violation and life for the third violation.",
            reportingRulesDtachanges: "Most of the time, households under the SNAP Simplified Reporting rules have to tell DHR changes at Interim Report (IR)  and recertification with the exception of:",
            noEarnedIncomeText: "The total amount of earned income used in your monthly food assistance budget is $0.",
            noUnearnedIncomeText:"The total amount of unearned income used in your monthly food assistance budget is $0.",
            unearnedIncome_ChangeQuestion: "Has your household's gross unearned income changed by more than $100? (Required)",
            unearnedIncome_ChangeQuestion1: "Has anyone started to receive any other money/checks from any other source? (Required)",
            unearnedIncomeSubText1: "Examples: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
            unearnedIncomeSubText2: "If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
            unearnedIncomeSubText3: "You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            unearnedIncomeSubTextTanf1: "EXAMPLES: Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker’s compensation or other kinds of money or checks other than Family Assistance.",
            unearnedIncomeSubTextTanf2: "You must report any changes within 10 days of the change. Failure to do so may result in a reduction or termination of benefits. Contact your FA worker if you have questions.",
            unearnedIncomeSubTextTanf3: "If the answer to any of the questions is yes, contact your TANF worker immediately if you have not already done so. You must complete this Redetermination even if you contact your worker about any questions.",
            saveOrExitLabel:
            "You might lose the unsaved information in the SNAP Six-Month Report. Do you want to save the information before you exit?",
            noResourceText:"The total amount of resource used in your monthly food assistance budget is $0.",
          }; //return End

        case ES:
          return {
            heading: "Informe de seis meses de SNAP",
            howApplyLabel: "Informe de seis meses de SNAP",
            residentialAddr: "Dirección residencial",
            mailingAddress: "Dirección postal",
            emailTextLabel: "Dirección de correo electrónico",
            incomePerMonthSubText: 'Si respondió "Sí" a la pregunta anterior, deberá proporcionar pruebas de cada cambio, como declaraciones escritas de personas que le dan dinero, talones de cheques o declaraciones del empleador y cartas de concesión para verificar los nuevos ingresos y cuándo los ingresos cambiaron.',
            tanfincomePerMonthSubText: 'Si respondió Sí, comuníquese con su trabajador de TANF de inmediato si aún no lo ha hecho. Debe completar esta Redeterminación incluso si se comunica con su trabajador si tiene alguna pregunta.',
            tanfincomePerMonthSubText1: 'Debe informar cualquier cambio dentro de los 10 días posteriores al cambio. No hacerlo puede resultar en una reducción o terminación de los beneficios. Comuníquese con su trabajador de FA si tiene preguntas.',
            shelterExpenseSubText: "Nota: Si se ha mudado, debe presentar prueba de sus gastos de alojamiento. Si no se ha mudado pero informa un aumento en los siguientes gastos de alojamiento (alquiler, hipoteca, impuestos a la propiedad o seguro de hogar) y no proporciona verificación, el cambio no se realizará hasta la recertificación.",
            earningsPerMonthLabel: "¿Alguien ha tenido un cambio en sus ingresos (incluidos los ingresos del trabajo por cuenta propia) porque comenzó o dejó un trabajo o cambió de trabajo? (Requerido)",
            earningsNote: 'NOTA: Si es un "adulto sano sin dependientes" (ABAWD), debe informar cuando sus horas de trabajo sean inferiores a 20 horas por semana (o al menos 80 horas por mes). El cambio debe ser reportado 10 días después del final del mes en que ocurrió el cambio..',
            shelterExpense_ChangeQuestion:"¿Han cambiado los gastos de alojamiento o servicios públicos de su hogar? (Requerido)",
            fieldLabel1:
              "Puede completar su Informe provisional con los siguientes métodos:",
            fieldOverDueLabel :"Debe completar este formulario y enviarlo a la oficina del condado con la verificación de cualquier cambio antes de fin de mes o debe volver a solicitar los beneficios de SNAP.",
            fieldLabelCompleteForm:"Debe completar este formulario y enviarlo a la oficina del condado con la verificación de cualquier cambio entre el 1 y el 10 de agosto o su caso SNAP se cerrará a finales de agosto..",
            fieldLabel2: "Asegúrese de responder todas las preguntas dentro de este formulario. Cada sección muestra si se le solicita que proporcione verificación de los cambios. Si no lo firma y lo envía con toda la verificación requerida, sus beneficios pueden retrasarse, reducirse o cancelarse. Llame a su oficina local si necesita ayuda para completar este formulario. Si necesita ayuda para leer, escribir, oír, etc., según la Ley de Estadounidenses con Discapacidades, debe comunicar sus necesidades a la oficina del condado donde vive. Si ha completado y enviado un formulario de informe semestral en papel, no complete este formulario..",
            fieldLabel3: "Puede completar su informe semestral de SNAP y/o enviar sus documentos de verificación utilizando los siguientes métodos:",  
            recertMethods: [
              "En línea haciendo clic en <strong>Iniciar informe semestral de SNAP.</strong> Esta es la forma más rápida!",
              {
                before:
                  " Descargue e imprima su formulario en papel ",
                hereLinkText: "aquí.",
                after: " Puede enviar su formulario por:",
              },
              {
                before:
                  "Tomar una fotografía de cada página y cargarla a través del portal de autoservicio ACES DHR ",
                hereLinkText: "aquí",
                after: "para más información)",
              },
              {
                before:
                  "Enviar por fax, correo electrónico o correo postal a la oficina local de su condado (haga clic en ",
                hereLinkText: "aquí",
                after: " para más información)",
              },
            ],
            goBackButtonLabel: "Volver",
            boBackButtonLabel: "Volver",
            sIncomebefore: " ",
            sIncometrigger: "Ingresos brutos del trabajo de",
            sIncometrigger1: "Ingresos obtenidos",
            sIncomeAfter: " ",
            sIncomeHelp:
              "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico.",
              sIncomeHelp1:
                "El ingreso del trabajo es el dinero que recibe por trabajar antes de deducir algo, como impuestos o seguro médico.",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: " ",
            sUIncometrigger: "Ingresos brutos no provenientes del trabajo de",
            sUIncometrigger1: "Ingresos no derivados del trabajo",
            sUIncomeAfter: " ",
            sUIncomeHelp:
              "Los ingresos brutos no devengados es dinero que recibe de una fuente que no es su empleo antes de cualquier deducción, como impuestos o seguro médico.",
              sUIncomeHelp1:
              "Los ingresos no derivados del trabajo son dinero que recibe de una fuente distinta del empleo antes de deducir algo, como impuestos o seguro médico.",
            getStartButtonLabel: "Comenzar el Informe provisional",
            noEarnedIncomeText:"La cantidad total de ingresos del trabajo utilizados en su presupuesto mensual de asistencia alimentaria es $0.",
            noTanfEarnedIncomeText: "La cantidad total de ingresos del trabajo utilizados en su presupuesto mensual de TANF es $0.",
            noTanfUnEarnedIncomeText: "La cantidad total de ingresos no derivados del trabajo utilizados en su presupuesto mensual de TANF es $0.",
            noUnearnedIncomeText:"La cantidad total de ingresos no derivados del trabajo utilizados en su presupuesto mensual de asistencia alimentaria es de $0.",
            nochildsupportPaymentText:"El monto total de los pagos de manutención infantil utilizados en su presupuesto mensual de asistencia alimentaria es $0.",   
            noShelterIncomeText:"El monto total de los gastos de alojamiento utilizados en su presupuesto mensual de asistencia alimentaria es $0.",  
            noUtilityText:"None",  
            editButtonLabel: "Editar",
            badgeNewLabel: "Nuevo",
            perLabel: "Por",
            ofLabel: "De",
            //new label:

            renderAppNoLabel: "El número de su solicitud es:",
            youMayLabelOne: "Puede",
            fromMultiLang: " de ",
            youMayLabeltwo: "descargar su solicitud",
            youMayLabelThree: "para tenerla como referencia en el futuro.",
            whatisNextLabel: "¿Cuál es el siguiente paso?",
            dtaReachLabel:
              "El DHR se comunicará con usted en los próximos 5 días.",
            sendDocLabel:
              "Envíe los documentos para que el DHR pueda confirmar su situación.",
            howDoSpeedLabel: "¿Cómo puedo acelerar el proceso?",
            donotNeedLabel:
              "No es necesario que espere a que el DHR le envíe una lista de verificaciones. ",
            sendLabelOne: "Puede",
            IncomePerMonthLabel:
              "¿Se modificaron los ingresos brutos provenientes del trabajo del grupo familiar en más o menos de $100/mes? (Obligatorio)",
            IncomeTafdcPerMonthLabel:
              "¿Alguien ha empezado a trabajar? (Requerido)", 
            sendLabelTwo: "enviarnos sus documentos",
            sendLabelThree: "de muchas maneras. ",
            toLearnOneLabel:
              "Para saber más acerca de los documentos que puede enviar ahora, ",
            toLearnOneLabelTwo: "haga clic aquí",
            manyWayLabel: "Hay muchas otras maneras de presentar documentos.",
            manyWayLabelTwo: "Obtenga más información.",
            howDoSubmitLabel: "¿Cómo envío documentos?",
            weSuggestLabel: "¡Le sugerimos que",
            weSuggestLabelOne: " cree una cuenta en línea",
            weSuggestLabelTwo:
              "para que pueda subir documentos, darle seguimiento a su solicitud, consultar su saldo y más!",
            findOutMoreLabel: "Averiguar más",
            createOnlineLabel: "Crear una cuenta en línea",
            orExistLabel: "O salir sin subir documentos", //
            renderSaveLabel: "Para guardar su progreso, cree una cuenta aquí.",
            laterOnLabel:
              "Más adelante, su cuenta en línea puede ayudarle a recibir mensajes del DHR sobre su solicitud, enviar documentos, verificar su saldo y más.",
            orExitLabel: "O salir sin guardar tu trabajo",
            changingLabel:
              "Cambiar este valor eliminará los valores ingresados ​​anteriormente en las próximas secciones.",
            greatHereLabel: "Excelente. Esto es lo que tenemos de usted",
            lookIncorrectLabel:
              "Si algo parece incorrecto, no dude en editarlo.",
            anotherHouseholdIncomeLabel:
              "Agregar ingresos del trabajo de otro miembro del grupo familiar",
              anotherHouseholdIncomeLabel1:
                "Agregue el ingreso laboral de otro miembro del hogar",
            anotherHouseholdUnEarnedIncomeLabel:
              "Agregar ingresos no provenientes del trabajo de otro miembro del grupo familiar",
            unearnedIncome_ChangeQuestion:
              "¿Se modificaron los ingresos brutos no provenientes del trabajo del grupo familiar en más o menos de $100/mes? (Obligatorio)",
              unearnedIncome_ChangeQuestion1: "¿Alguien ha comenzado a recibir dinero/cheques de alguna otra fuente? (Requerido)",
              unearnedIncomeSubText1: "Ejemplos: Asistencia familiar, Seguro Social, SSI, VA, desempleo, manutención infantil, pensiones, contribuciones de amigos o familiares, asignaciones militares, compensación laboral u otros tipos de dinero o cheques.",
              unearnedIncomeSubText2:"Si respondió Sí a la pregunta anterior, deberá proporcionar pruebas de cada cambio, como declaraciones escritas de las personas que le dan dinero, talones de cheques o declaraciones del empleador y cartas de concesión para verificar los nuevos ingresos y cuándo cambiaron los ingresos. .",
              unearnedIncomeSubText3:"Debe informar si un miembro de su hogar gana ganancias sustanciales en lotería o juegos de azar de $4,250 o más.",unearnedIncomeSubTextTanf1: "EJEMPLOS: Seguro Social, SSI, VA, desempleo, manutención infantil, pensiones, contribuciones de amigos o familiares, asignaciones militares, compensación laboral u otros tipos de dinero o cheques que no sean Asistencia Familiar.",
              unearnedIncomeSubTextTanf2: "Debe informar cualquier cambio dentro de los 10 días posteriores al cambio. No hacerlo puede resultar en una reducción o terminación de los beneficios. Comuníquese con su trabajador de FA si tiene preguntas.",
              unearnedIncomeSubTextTanf3: "Si la respuesta a cualquiera de las preguntas es sí, comuníquese con su trabajador de TANF de inmediato si aún no lo ha hecho. Debe completar esta Redeterminación incluso si se comunica con su trabajador si tiene alguna pregunta.",
            applicationSummaryTitle: "Resumen de la aplicación",
            applicationSummaryHeader: "Resumen de la aplicación",
            viewApplicationLinkLabel: "Ver como PDF",
            signatureandPenalty: "Firma y Aviso de Penalización.",
            nonDiscriminationHeader: "Declaración de no discriminación del USDA",
            nonDiscriminationContent: "De acuerdo con la ley federal de derechos civiles y las regulaciones y políticas de derechos civiles del Departamento de Agricultura de los EE. UU. (USDA), esta institución tiene prohibido discriminar por motivos de raza, color, origen nacional, sexo (incluida la identidad de género y la orientación sexual), credo religioso. , discapacidad, edad, creencias políticas o represalias por actividades anteriores de derechos civiles.",
            personDisabilities: "La información del programa puede estar disponible en otros idiomas además del inglés. Las personas con discapacidades que requieran medios de comunicación alternativos para obtener información del programa (por ejemplo, Braille, letra grande, cintas de audio, lenguaje de señas americano), deben comunicarse con la agencia (estatal o local) donde solicitaron los beneficios. Las personas sordas, con problemas de audición o del habla pueden comunicarse con el USDA a través del Servicio Federal de Retransmisión en .",
            programComplaint: "Para presentar una queja por discriminación en el programa, el demandante debe completar un formulario AD-3027, Formulario de queja por discriminación en el programa del USDA, que se puede obtener en línea en: ",
            usdaOffice: "desde cualquier oficina del USDA, llamando al (833) 620-1071, o escribiendo una carta dirigida al USDA. La carta debe contener el nombre, la dirección, el número de teléfono del denunciante y una descripción escrita de la supuesta acción discriminatoria con suficiente detalle para informar al Subsecretario de Derechos Civiles (ASCR) sobre la naturaleza y la fecha de una supuesta violación de los derechos civiles. El formulario o carta AD-3027 completo debe enviarse a: ",
            mail: "Correo:",
            mailAddress: "Servicio de Alimentos y Nutrición, USDA 1320 Braddock Place, Room 334 Alexandria, VA 22314: o",
            mailAddressArray: ["Servicio de Alimentos y Nutrición, USDA","1320 Braddock Place, Room 334","Alexandria, VA 22314: o"],
            fax: " Fax:",
            orLabel: " o",
            eamilTitle: "Correo electrónico: ",
            thisInstution: "Esta institución es un proveedor de igualdad de oportunidades.",
            federalLaws: "También le pueden imponer una multa de hasta 250.000 dólares, una pena de prisión de hasta 20 años, o ambas cosas; y sujeto a procesamiento bajo otras leyes federales aplicables.",
            hideInformation: "Ocultar información o dar información falsa para obtener o continuar recibiendo SNAP",
            tradeOrSell: "Intercambie o venda sus tarjetas SNAP o EBT (también conocido como tráfico)",
            alterEbt: "Modifique las tarjetas EBT para obtener SNAP que no tiene derecho a recibir",
            alcoholicDrinksOrTobacco: "Utilice SNAP para comprar artículos no elegibles, como bebidas alcohólicas o tabaco.",
            someoneElseSnapOrEbt: "Utilice las tarjetas SNAP o EBT de otra persona para su hogar",
            iUnderstandThePenalty: " Entiendo la advertencia de penalización anterior. También entiendo que debo el valor de cualquier beneficio adicional de SNAP que reciba si no informo completamente los cambios en las circunstancias de mi hogar cuando sea necesario. Acepto proporcionar pruebas de cualquier cambio que informe.",
            iCertifyThatTheStatement: "Certifico que las declaraciones en este formulario son verdaderas y correctas a mi leal saber y entender. Entiendo que la información que proporciono en este formulario puede resultar en una reducción o terminación de mis beneficios.",
            whenYourHouseholdReceivesSnap: "Cuando su hogar recibe SNAP, debe seguir las reglas. Si hace cualquiera de las siguientes acciones, perderá sus beneficios de SNAP durante un año por la primera infracción, dos años por la segunda infracción y vida por la tercera infracción.",
            iagreeTermsLabel: "Acepto",
            signYourAppllication:
              "Firme su informe semestral de SNAP escribiendo su nombre completo a continuación. (Obligatorio)",
            continueLabel: "Continuar",
            badgeChangedLabel: "Modificado",
            saveAndContinueLabel: "Guardar y continuar",
            getStartedLabel: "Comenzar",
            submitButtonLabel: "Presentar Informe provisional",
            submitSelfButtonLabel: "Presentar Autodeclaración de gastos",
            expenseFieldLabel: "Esta última sección trata sobre",
            expenseHouseutility: "Costos de vivienda y servicios públicos",
            expenseCaring: "Cuidado de niños y adultos",
            expenseChsupport: "Manutención",
            expenseMed: "Gastos médicos",
            expenseAnyinfo:
              "Toda la información que proporcione aquí dará un monto más exacto del beneficio.",
            removeHoHTitle: "Advertencia",
            removeHoHLabel:
              "Está intentando destituir a un cabeza de familia. Si esto es correcto, seleccione 'Continuar' y un trabajador del DHR se comunicará con usted para obtener más información. Si esto fue un error, seleccione 'Regresar'.",
            removeNonHoHLabel:
              "Está intentando eliminar a un miembro del hogar. Si esto es correcto, seleccione 'Continuar' y un trabajador del DHR se comunicará con usted para obtener más información. Si esto fue un error, seleccione 'Regresar'.",
            agreeContent: [
              "Al firmar, acepto lo siguiente:",
              "Leí todo el formulario (o alguien lo leyó para mí en un idioma que comprendo), incluida la sección sobre derechos y responsabilidades, y entiendo que debo cumplir con esas reglas;",
              "A mi leal saber y entender, la información que proporciono es verdadera y completa;",
              "Podría ir a prisión o se me podría exigir el pago de multas si proporciono intencionalmente información errónea o incompleta; y DHR y otros funcionarios federales, estatales y locales podrán verificar (controlar) la información que proporciono.",
            ],
            didReportChanges: "Informé cambios.",
            didNotReportChanges: "No informé cambios.",
            agreeTermsErrorMsg: "Por favor, acepte los términos.",
            signatureErrorMsg: "Firme su informe preliminar",
            signatureUserNameErrorMsg:
            "La firma debe coincidir con el nombre en la solicitud.",
            mailAddressArray: ["Servicio de Alimentos y Nutrición, USDA","1320 Braddock Place, Room 334","Alexandria, VA 22314; o"],
            noResourceText:"The total amount of resource used in your monthly food assistance budget is $0.",
          }; //return End

        case PT:
          return {
            heading: "Relatório interino",
            howApplyLabel: "Relatório interino",
            emailTextLabel: "Endereço de e-mail",
            residentialAddr: "Endereço residencial",
            mailingAddress: "Endereço de correspondência",
            fieldLabel1:
              "Você pode preencher seu Relatório interino usando os seguintes métodos:",
            recertMethods: [
              "Online clicando em <b>iniciar Relatório interino.</b> Essa é a forma mais rápida!",
              {
                before:
                  "Aceitaremos um formulário impresso se você incluir seu nome, endereço e assinatura. Esta opção não está disponível para envio online. Baixe e imprima seu formulário ",
                hereLinkText: "aqui.",
                after:
                  " Após preencher esse formulário, você pode enviá-lo por:",
              },
              "Foto, tirando uma foto de cada página e carregando via ACES DHR Self Service Portal",
              "Fax para (617) 887-8765",
              "Correio para P.O. Box 4406, Taunton, MA 02780",
              "Pessoalmente, trazendo ao seu escritório DHR local para digitalizar na área de autoatendimento",
            ],
            boBackButtonLabel: "Voltar",
            sIncomebefore: " ",
            sIncometrigger: "Rendimento bruto de",
            sIncomeAfter: " ",
            sIncomeHelp:
              "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: " ",
            badgeNewLabel: "Novo",
            sUIncometrigger: "Rendimento bruto não proveniente de trabalho de",
            sUIncomeAfter: " ",
            sUIncomeHelp:
              "Renda bruta não realizada é o dinheiro que você recebe de uma fonte que não seja emprego antes de qualquer coisa ser descontada, como impostos ou seguro de saúde.",
            getStartButtonLabel: "Iniciar Relatório interino",
            editButtonLabel: "Editar",
            fromMultiLang: " de ",
            badgeChangedLabel: "Alterado",
            IncomePerMonthLabel:
              "O rendimento bruto do seu domicílio foi alterado em mais ou menos $100/mês? (Obrigatório)",
            perLabel: "Por",
            ofLabel: "do",

            //new label:

            renderAppNoLabel: "O número da sua solicitação é :",
            youMayLabelOne: "Baixe a sua solicitação ",
            youMayLabeltwo: "caso precise dela no futuro.",
            youMayLabelThree: "for future reference.",
            whatisNextLabel: "Qual é a próxima etapa?",
            dtaReachLabel: "O DHR entrará em contato com você em 5 dias.",
            sendDocLabel:
              "Envie os documentos para que o DHR possa confirmar a sua situação.",
            howDoSpeedLabel: "Como eu posso acelerar o processo?",
            donotNeedLabel:
              "Você não precisa esperar que o DHR envie uma lista de verificações.",
            sendLabelOne: "Você pode nos enviar seus",
            sendLabelTwo: " documentos",
            sendLabelThree: "de diversas formas.",
            toLearnOneLabel:
              "Para saber mais sobre os documentos que você pode enviar agora,",
            toLearnOneLabelTwo: "clique aqui",
            manyWayLabel:
              "Existem muitas outras maneiras de enviar documentos para nós.",
            manyWayLabelTwo: "Saiba mais",
            howDoSubmitLabel: "Como eu envio os documentos?",
            weSuggestLabel: "Sugerimos que você crie uma conta",
            weSuggestLabelOne: " on-line para fazer o upload dos ",
            weSuggestLabelTwo:
              "documentos, verificar o andamento da sua solicitação, checar o seu saldo e muito mais!",
            findOutMoreLabel: "Saiba mais",
            createOnlineLabel: "Crie uma conta on-line",
            orExistLabel: "Ou saia sem fazer o upload de nenhum documento",
            renderSaveLabel: "Para guardar su progreso, cree una cuenta aquí.",
            laterOnLabel:
              "Más adelante, su cuenta en línea puede ayudarle a recibir mensajes del DHR sobre su solicitud, enviar documentos, verificar su saldo y más.",
            orExitLabel: "O salir sin guardar tu trabajo",
            changingLabel:
              "Cambiar este valor eliminará los valores ingresados ​​anteriormente en las próximas secciones.",
            greatHereLabel: "Ótimo! É isso que recebemos de você.",
            lookIncorrectLabel:
              "Se algo estiver incorreto, sinta-se à vontade para editar.",
            anotherHouseholdIncomeLabel:
              "Adicionar outros membros do domicílio com rendimento",
            anotherHouseholdUnEarnedIncomeLabel:
              "Adicionar outro rendimento não proveniente de trabalho de membros do agregado familiar",
            unearnedIncome_ChangeQuestion:
              "O rendimento bruto não proveniente de trabalho do seu domicílio foi alterado em mais ou menos $ 100/mês? (Obrigatório)",
              unearnedIncomeSubText1: "Ejemplos: Asistencia familiar, Seguro Social, SSI, VA, desempleo, manutención infantil, pensiones, contribuciones de amigos o familiares, asignaciones militares, compensación laboral u otros tipos de dinero o cheques.",
              unearnedIncomeSubText2:"Si respondió Sí a la pregunta anterior, deberá proporcionar pruebas de cada cambio, como declaraciones escritas de las personas que le dan dinero, talones de cheques o declaraciones del empleador y cartas de concesión para verificar los nuevos ingresos y cuándo cambiaron los ingresos.",
              unearnedIncomeSubText3:"Debe informar si un miembro de su hogar gana ganancias sustanciales en lotería o juegos de azar de $4,250 o más.",
            applicationSummaryTitle: "Resumo da aplicação",
            applicationSummaryHeader: "Resumo da aplicação",
            viewApplicationLinkLabel: "Visualizar como PDF",
            iagreeTermsLabel: "Concordo",
            signYourAppllication:
              "Assine seu Relatório Provisório digitando seu nome completo abaixo",
            continueLabel: "Continuar",
            getStartedLabel: "Vamos Começar",
            submitButtonLabel: "Enviar Relatório interino",
            submitSelfButtonLabel: "Enviar Auto-declarar despesas",
            expenseFieldLabel: "Esta última seção é sobre",
            expenseHouseutility: "Custos residenciais e utilitários",
            expenseCaring: "Cuidados com crianças e adultos",
            expenseChsupport: "Suporte infantil",
            expenseMed: "Despesas médicas",
            expenseAnyinfo:
              "Qualquer informação que você prestar aqui irá fornecer um valor mais preciso do benefício.",
            removeHoHTitle: "Aviso",
            removeHoHLabel:
              "Você está tentando destituir o Chefe da Família. Se isto estiver correto, selecione ‘Continuar’ e o DHR entrará em contato com você para obter mais informações. Se isso foi um erro, selecione ‘Voltar’.",
            agreeContent: [
              "Ao assinar, concordo que:",
              "Li todo este formulário (ou o leram para mim em um idioma que compreendo), incluindo a seção sobre direitos e responsabilidades, e entendo que devo cumprir essas regras;",
              "As informações que estou dando são verdadeiras e completas, de acordo com o meu conhecimento; ",
              "Eu poderia ir para a prisão ou ser obrigado a pagar multas se conscientemente fornecer informações erradas ou incompletas; e o DHR e outras autoridades federais, estaduais e locais podem verificar (conferir) qualquer informação que eu der.",
            ],
            didReportChanges: "Eu reportei mudanças.",
            didNotReportChanges: "Eu não relatei nenhuma alteração.",
            agreeTermsErrorMsg: "Concorde com os termos",
            signatureErrorMsg: "Assine o seu Relatório Provisório",
          }; //return End

        case ZH:
          return {
            heading: "中间报告",
            howApplyLabel: "中间报告",
            residentialAddr: "住宅地址",
            mailingAddress: "邮寄地址",
            fieldLabel1: "您可使用下列方法填写您的《中间报告》:",
            emailTextLabel: "电子邮箱地址",
            fromMultiLang: " 来自 ",
            recertMethods: [
              "在线填写，点击开始填写 <b>《中间报告》。</b> 这是最快的填写方式！",
              {
                before:
                  "如果您在纸质表格中填写您的姓名、地址并签名，我们也接受纸质表格。此选项针对在线提交不可用。处下载并打印您的纸质表格 ",
                hereLinkText: "在此。",
                after: " 您填写好表格后. 可以通过下列方式提交:",
              },
              "将表格各页拍照并利用 ACES DHR Self Service Portal 上传",
              "传真发送至 (617)887-8765",
              "邮寄至 P.O. Box 4406, Taunton, MA 02780",
              "携带表格前往您当地的 DHR 办事处，在自助区域进行扫描",
            ],
            boBackButtonLabel: "返回",
            sIncomebefore: " ",
            sIncometrigger: "的劳动所得收入总额",
            IncomePerMonthLabel:
              "您的家庭劳动所得总收入变动是否超过或少于 $100 美元/月？（必填）",
            sIncomeAfter: " ",
            sIncomeHelp:
              "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险）",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: " ",
            sUIncometrigger: "的非劳动所得总收入",
            sUIncomeAfter: "",
            sUIncomeHelp:
              "非劳动所得收入总额是您从工作以外的来源获得的金钱（未扣除任何费用，例如税或健康保险）",
            getStartButtonLabel: "开始填写《中间报告》",
            editButtonLabel: "编辑",
            badgeChangedLabel: "已变化",
            perLabel: "每",
            ofLabel: "的",
            badgeNewLabel: "新添内容",
            //new label:
            renderAppNoLabel: "您的申请编号是",
            youMayLabelOne: "您可能想要",
            youMayLabeltwo: "下载申请表",
            youMayLabelThree: "下载申请表以备日后查阅。",
            whatisNextLabel: "下一步",
            dtaReachLabel: "DTA将在接下来5个工作日内与您联系。",
            sendDocLabel: "发送文件以便DTA能够确认您的状况。",
            howDoSpeedLabel: "如何加速审核流程？",
            donotNeedLabel: "您无需等待DTA向您发送证明文件清单。",
            sendLabelOne: "您可通过多种方式",
            sendLabelTwo: "向我们发送文件 ",
            sendLabelThree: "in many ways",
            toLearnOneLabel: "要详细了解您现在可以提交的文件，请",
            toLearnOneLabelTwo: "单击这里",
            manyWayLabel: "向我们提交文件可通过很多其他方式。",
            manyWayLabelTwo: "了解更多",
            howDoSubmitLabel: "如何提交文件？",
            weSuggestLabel: "我们建议您",
            weSuggestLabelOne: "创建一个在线账户",
            weSuggestLabelTwo:
              "您可以通过此账户上传资料，跟踪申请进度，查看余额等等！",
            findOutMoreLabel: "了解更多",
            createOnlineLabel: "创建在线账户",
            orExistLabel: "或退出而不上传任何资料",
            renderSaveLabel: "To save your progress, create an account here.",
            laterOnLabel:
              "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
            orExitLabel: "Or exit without saving your work",
            changingLabel:
              "Chaning this value will delete the previous entered values in upcoming sections.",
            greatHereLabel: "这是我们从你那里了解到的情况",
            lookIncorrectLabel: "如果有任何错误之处，敬请改正。",
            anotherHouseholdIncomeLabel: "添加另一位家庭成员的劳动所得收入",
            anotherHouseholdUnEarnedIncomeLabel:
              "添加另一位家庭成员的非劳动所得收入",
            unearnedIncome_ChangeQuestion:
              "您的家庭非劳动所得总收入变动是否超过或少于 $100 美元/月？ (必填)",
              unearnedIncomeSubText1: "Examples: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
              unearnedIncomeSubText2:"If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
              unearnedIncomeSubText3:"You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            applicationSummaryTitle: "應用摘要",
            applicationSummaryHeader: "應用摘要",
            viewApplicationLinkLabel: "查看 PDF 文件",
            iagreeTermsLabel: "我同意",
            signYourAppllication: "在下方输入您的全名即为签署您的中间报告文件",
            continueLabel: "继续",
            getStartedLabel: "开始",
            submitButtonLabel: "提交中间报告",
            submitSelfButtonLabel: "提交自我声明的费用",
            expenseFieldLabel: "最后一部分是关于",
            expenseHouseutility: "住房和通用开支",
            expenseCaring: "子女和成人照护费",
            expenseChsupport: "子女抚养费",
            expenseMed: "医疗费",
            expenseAnyinfo:
              "您在此提供的任何信息都将有助于我们确定更准确的补助金额。",
            removeHoHTitle: "警告",
            removeHoHLabel:
              "您正试图罢免户主。如果正确，请选择“继续”，DHR 将与您联系以获取更多信息。如果这是一个错误，请选择“返回”。",
            agreeContent: [
              "我签名即表示同意：",
              "我已经阅读此表格完整内容（或让人以我理解的语言为我朗读此表格完整内容），包括关于权利和责任的部分，而且我理解，我必须遵守这些规定；",
              "我提供的信息据我所知均真实而且完整。 ",
              "如果我在知情的前提下给出错误或不完整的信息，我可能入狱或按要求支付罚款；DHR 及其他联邦、州和地方官员可验证（检查）我提供的所有信息。",
            ],
            didReportChanges: "我已经报告信息 更改。",
            didNotReportChanges: "我并未报告任何更改。",
            agreeTermsErrorMsg: "请同意条款",
            signatureErrorMsg: "请签署您的中间报告",
          }; //return End
        case VI:
          return {
            heading: "Báo cáo giữa kỳ",
            howApplyLabel: "Báo cáo giữa kỳ",
            residentialAddr: "Địa chỉ cư trú",
            mailingAddress: "Địa chỉ hòm thư",
            fieldLabel1:
              "Quý vị có thể hoàn thành Báo cáo giữa kỳ của mình bằng các phương pháp sau:",
            emailTextLabel: "Địa chỉ email",
            recertMethods: [
              "Trực tuyến bằng cách nhấp vào nút <b>bắt đầu Báo cáo giữa kỳ.</b> Đây là cách nhanh nhất!",
              {
                before:
                  "Chúng tôi sẽ chấp nhận đăng ký bằng biểu mẫu trên giấy nếu trên đó có tên, địa chỉ và chữ ký của quý vị. Tùy chọn này không có sẵn khi nộp trực tuyến. Tải về và in biểu mẫu ở ",
                hereLinkText: "đây.",
                after:
                  " Sau khi quý vị hoàn thành biểu mẫu đó, quý vị có thể gửi bằng cách:",
              },
              "Chụp ảnh từng trang và tải lên qua ACES DHR Self Service Portal",
              "Gửi fax theo số (617) 887-8765",
              "Gửi thư đến Hộp thư bưu điện 4406, Taunton, MA 02780",
              "Mang đến văn phòng DHR địa phương của quý vị để scan tại khu vực tự phục vụ",
            ],
            boBackButtonLabel: "Quay lại",
            sIncomebefore: " ",
            sIncometrigger: "Tổng thu nhập đã thu của",
            sIncomeAfter: " ",
            sIncomeHelp:
              "Tổng thu nhập kiếm được là số tiền bạn nhận được từ công việc trước khi trừ ra bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: " ",
            badgeChangedLabel: "thay đổi",
            fromMultiLang: " từ ",
            sUIncometrigger: "Tổng thu nhập chưa thu của",
            sUIncomeAfter: " ",
            badgeNewLabel: "mới",
            IncomePerMonthLabel:
              "Tổng thu nhập đã thu được của gia đình quý vị đã thay đổi nhiều hơn hoặc ít hơn $100 Đô la / tháng? (Bắt buộc)",
            sUIncomeHelp:
              "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
            getStartButtonLabel: "Bắt đầu báo cáo giữa kỳ",
            editButtonLabel: "Chỉnh sửa",
            perLabel: "Mỗi",
            ofLabel: "Của",

            //new label:
            renderAppNoLabel: "Số Đơn xin:",
            youMayLabelOne: "Có thể Quý vị muốn ",
            youMayLabeltwo: " tải đơn xin của quý vị về",
            youMayLabelThree: "để tham khảo trong tương lai.",
            whatisNextLabel: "Điều gì diễn ra kế tiếp?",
            dtaReachLabel: "DHR sẽ liên lạc với quý vị trong vòng 5 ngày tới.",
            sendDocLabel:
              "Gửi các tài liệu để DHR có thể xác nhận tình trạng của quý vị. ",
            howDoSpeedLabel: "Làm thế nào để đẩy nhanh tiến trình?",
            donotNeedLabel:
              "Quý vị không cần đợi DHR gửi cho quý vị danh sách xác minh.",
            sendLabelOne: "Quý vị có thể ",
            sendLabelTwo: " gửi cho chúng tôi giấy tờ của quý vị",
            sendLabelThree: " bằng nhiều cách",
            toLearnOneLabel:
              "Để tìm hiểu thêm về các giấy tờ mà quý vị có thể gửi ngay bây giờ,",
            toLearnOneLabelTwo: "hãy nhấp vào đây",
            manyWayLabel:
              "Có nhiều cách thức khác để gửi giấy tờ cho chúng tôi.",
            manyWayLabelTwo: "Tìm hiểu thêm",
            howDoSubmitLabel: "Làm thế nào để tôi gửi giấy tờ?",
            weSuggestLabel: "Chúng tôi khuyên quý vị nên",
            weSuggestLabelOne: "tạo một tài khoản trực tuyến",
            weSuggestLabelTwo:
              "để quý vị có thể tải lên giấy tờ, theo dõi tiến trình đơn xin của quý vị, kiểm tra số dư của quý vị và những việc khác nữa!",
            findOutMoreLabel: "Tìm hiểu thêm",
            createOnlineLabel: "Tạo tài khoản trực tuyến",
            orExistLabel:
              "Hoặc thoát ra mà không cần tải lên bất kỳ giấy tờ nào",
            renderSaveLabel: "To save your progress, create an account here.",
            laterOnLabel:
              "Later on, your online account can help you receive messages from the DHR about your application, submit documents, check your balance, and more!",
            orExitLabel: "Or exit without saving your work",
            changingLabel:
              "Chaning this value will delete the previous entered values in upcoming sections.",
            greatHereLabel:
              "Tuyệt vời, đây là những gì chúng tôi thu thập được từ quý vị",
            lookIncorrectLabel:
              "Nếu có bất cứ điều nào trông có vẻ không chính xác, quý vị cứ tự nhiên chỉnh sửa",
            anotherHouseholdIncomeLabel:
              "Bổ sung một thành viên khác trong gia đình có thu nhập đã thu",
            anotherHouseholdUnEarnedIncomeLabel:
              "Bổ sung thu nhập chưa thu khác của các thành viên trong gia đình ",
            unearnedIncome_ChangeQuestion:
              "Tổng thu nhập chưa thu của gia đình quý vị có thay đổi nhiều hơn hoặc ít hơn $100 Đô la / tháng không? (Bắt buộc)",
              unearnedIncomeSubText1: "Examples: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
              unearnedIncomeSubText2:"If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
              unearnedIncomeSubText3:"You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            applicationSummaryTitle: "Tóm tắt ứng dụng",
            applicationSummaryHeader: "Tóm tắt ứng dụng",
            viewApplicationLinkLabel: "Xem dưới dạng PDF",
            iagreeTermsLabel: "Tôi đồng ý",
            signYourAppllication:
              "Ký Báo cáo tạm thời của bạn bằng cách nhập tên đầy đủ của bạn dưới đây",
            continueLabel: "Tiếp tục",
            getStartedLabel: "Bắt đầu",
            submitButtonLabel: "Gửi đi báo cáo giữa kỳ",
            submitSelfButtonLabel: "Gửi đi chi phí tự kê khai",
            expenseFieldLabel: "Phần cuối cùng này là về",
            expenseHouseutility: "Chi phí nhà ở và tiện ích",
            expenseCaring: "Chăm sóc trẻ em và người lớn",
            expenseChsupport: "Trợ cấp nuôi con",
            expenseMed: "Chi phí y tế",
            expenseAnyinfo:
              "Bất kỳ thông tin nào quý vị cung cấp ở đây sẽ đưa ra số tiền trợ cấp chính xác hơn.",
            removeHoHTitle: "Cảnh báo",
            removeHoHLabel:
              "Bạn đang cố gắng loại bỏ Chủ hộ. Nếu điều này đúng, hãy chọn 'Tiếp tục' và DHR sẽ liên hệ với bạn để biết thêm thông tin. Nếu đây là sự nhầm lẫn, vui lòng chọn 'Quay lại'.",
            agreeContent: [
              "Bằng cách ký vào mẫu này, tôi đồng ý rằng:",
              "Tôi đã đọc toàn bộ biểu mẫu này (hoặc biểu mẫu này đã được đọc cho tôi bằng ngôn ngữ mà tôi hiểu) bao gồm cả phần về quyền và trách nhiệm và hiểu rằng tôi phải tuân thủ các quy tắc này;",
              "Theo sự hiểu biết tốt nhất của tôi, thông tin tôi đang cung cấp là đúng sự thật và đầy đủ;",
              "Tôi có thể vào tù hoặc bị buộc phải nộp tiền phạt nếu tôi cố tình cung cấp thông tin sai hoặc không đầy đủ; và DHR và các cán bộ liên bang, tiểu bang và địa phương khác có thể xác minh (kiểm tra) bất kỳ thông tin nào tôi cung cấp",
            ],
            didReportChanges: "Tôi đã báo cáo những thay dổi.",
            didNotReportChanges: "Tôi đã không báo cáo bất kỳ thay đổi nào.",
            agreeTermsErrorMsg: "Vui lòng đồng ý với các điều khoản",
            signatureErrorMsg: "Vui lòng ký Báo cáo tạm thời của bạn",
          }; //return End
        case HI:
          return {
            heading: "Rapò Pwovizwa",
            howApplyLabel: "Rapò Pwovizwa",
            emailTextLabel: "Adrès imel",
            fieldLabel1:
              "Ou ka ranpli Rapò Pwovizwa w la nan itilize metòd sa yo:",
            recertMethods: [
              "An liy nan klike sou <b>Kòmanse Rapò Pwovizwa.</b> Se fason ki pi rapid la!",
              {
                before:
                  "Nou pral aksepte yon fòm papye si w mete non, adrès ak siyati w. Opsyon sa a pa disponib pou soumèt an liy. Telechaje epi enprime fòm  ",
                hereLinkText: "papye w la la a.",
                after: " Ou ka soumèt fòm ou an nan:",
              },
              "Pran yon foto chak paj epi telechaje atravè ACES DHR Self Service Portal",
              "Fakse nan (617) 887-8765",
              "Poste nan P.O. Box 4406, Taunton, MA 02780.",
            ],
            boBackButtonLabel: "Tounen",
            getStartButtonLabel: "Kòmanse Rapò Pwovizwa",
            //sIncome:"'s gross earned income",
            sIncomebefore: "",
            sIncometrigger: "Revni brit",
            sIncomeAfter: "",
            sIncomeHelp:
              "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante.",
            //sUIIncome:"'s gross unearned income",
            sUIncomebefore: " ",
            sUIncometrigger: "revni brit ou pa fè nan travay",
            sUIncomeAfter: " ",
            sUIncomeHelp:
              "Revni Brit ou pa Touche se lajan ou resevwa nan yon lòt sous ki pa travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante",
            //sUIIncome:"'s gross unearned income",
            editButtonLabel: "Edite",
            perLabel: "Pa",
            ofLabel: "nan",
            residentialAddr: "Adrès Rezidansyèl",
            mailingAddress: "Adrès postal",
            fromMultiLang: " soti ",
            renderAppNoLabel: "Nimewo aplikasyon w lan:",
            youMayLabelOne: "Ou ka vle ",
            youMayLabeltwo: "telechaje aplikasyon w lan",
            youMayLabelThree: "pou plis referans.",
            whatisNextLabel: "Kisa k ap vini apre?",
            dtaReachLabel: "DHR pral kontakte w nan 5 jou k ap vini apre yo.",
            sendDocLabel:
              "Voye dokiman yo pou DHR ka konfime sitiyasyon w lan.",
            howDoSpeedLabel: "Kijan pou m akselere pwosesis la?",
            donotNeedLabel:
              "Ou pa bezwen tann DHR voye yon lis verifikasyon ba ou",
            sendLabelOne: "Ou ka ",
            sendLabelTwo: "voye dokiman w yo ban nou ",
            sendLabelThree: "nan plizyè fason",
            toLearnOneLabel:
              "Pou aprann plis konsènan dokiman ou ka soumèt kounye a,",
            toLearnOneLabelTwo: "klike la a",
            manyWayLabel: "Gen plizyè lòt fason pou soumèt dokiman ban nou.",
            manyWayLabelTwo: "Jwenn plis enfòmasyon",
            howDoSubmitLabel: "Kijan pou m soumèt dokiman yo?",
            weSuggestLabel: "Nou sijere",
            weSuggestLabelOne: "kreye yon kont an liy",
            weSuggestLabelTwo:
              "konsa ou ka telechaje dokiman, suiv pwogrè aplikasyon w lan, tcheke balans ou, ak plis ankò!",
            findOutMoreLabel: "Jwenn plis enfòmasyon",
            createOnlineLabel: "Kreye yon kont an Liy",
            orExistLabel: "Oswa sòti san w pa telechaje okenn dokiman",
            renderSaveLabel: "Pou konsève pwogrè ou, kreye yon kont la a.",
            laterOnLabel:
              "Pita, kont an liy ou an ka ede w resevwa mesaj DHR konsènan aplikasyon w lan, soumèt dokiman, tcheke balans ou, ak plis ankò!",
            orExitLabel: "Oswa sòti san ou pa konsève travay ou",
            changingLabel:
              "Chanje valè sa a pral efase valè ki te antre anvan yo nan seksyon k ap vini yo.",
            greatHereLabel: "Ekselan! Men sa nou te jwenn na men w.",
            lookIncorrectLabel:
              "Si nenpòt nan yo sanble pa kòrèk, pa ezite modifye.",
            IncomePerMonthLabel:
              "Èske revni brit fwaye a fè te chanje pa plis pase oswa mwens pase $100/mwa? (Obligatwa)",
            anotherHouseholdIncomeLabel: "Ajoute revni yon lòt manm fwaye a",
            anotherHouseholdUnEarnedIncomeLabel:
              "Ajoute revni yon lòt manm fwaye a pa fè nan travay",
            unearnedIncome_ChangeQuestion:
              "Èske revni brit fwaye w la pa fè nan travay plis pase $100/mwa? (Obligatwa)",
             unearnedIncomeSubText1: "Examples: Family Assistance, Social Security, SSI, VA, unemployment, child support, pensions, contributions from friends or relatives, military allotments, worker's compensation or other kinds of money or checks.",
              unearnedIncomeSubText2:"If you answered Yes to the question above, you will need to provide proof of each change, such as written statements from people who give you money, check stubs or statement from employer, and award letters to verify the new income and when the income changed.",
              unearnedIncomeSubText3:"You must report if a member of your household wins substantial lottery or gambling winnings of $4,250 or more.",
            applicationSummaryTitle: "Rezime aplikasyon",
            applicationSummaryHeader: "Rezime aplikasyon",
            viewApplicationLinkLabel: "gade sou fòma pdf",
            iagreeTermsLabel: "Mwen dakò",
            signYourAppllication:
              "Siyen Rapò Pwovizwa w la nan tape non konplè w anba a",
            continueLabel: "Kontinye",
            getStartedLabel: "Kòmanse",
            submitButtonLabel: "Soumèt Rapò Pwovizwa",
            submitSelfButtonLabel: "Soumèt depans yo ou deklare oumenm",
            expenseFieldLabel: "Dènye seksyon sa a se konsènan",
            expenseHouseutility: "Depans pou lojman ak sèvis piblik",
            expenseCaring: "Pran swen timoun ak adilt ",
            expenseChsupport: "Sipò Timoun",
            expenseMed: "Depans medikal",
            expenseAnyinfo:
              "Tout enfòmasyon ou bay la a pral bay yon montan benefis ki pi egzak.",
            removeHoHTitle: "avètisman",
            removeHoHLabel:
              "W ap eseye retire chèf fanmi an. Si sa a kòrèk, Chwazi 'Kontinye' epi DHR ap kontakte ou pou plis enfòmasyon. Si sa a se te yon erè, tanpri chwazi 'Tounen'.",
            agreeContent: [
              "Nan siyen, mwen dakò ke:",
              "Mwen te li tout fòm sa a (oswa mwen te fè li li pou mwen nan yon lang ke mwen konprann), enkli seksyon sou dwa ak responsablite yo, epi m konprann ke mwen dwe respekte règleman sa yo.",
              "Enfòmasyon m ap bay yo vre epi yo konplè dapre sa m byen konnen.",
              "M ka ale nan prizon oswa ka oblije peye amann si m bay enfòmasyon ki pa kòrèk oswa ki pa konplè; epi DHR ak lòt ofisyèl federal, etatik ak lokal yo ka verifye (tcheke) tout enfòmasyon m bay yo",
            ],
            didReportChanges: "Mwen te rapòte chanjman",
            didNotReportChanges: "Mwen pa t rapòte chanjman ",
            badgeChangedLabel: "Chanje",
            badgeNewLabel: "Nouvo",
            agreeTermsErrorMsg: "Tanpri dakò ak kondisyon yo",
            signatureErrorMsg: "Tanpri siyen Rapò Pwovizwa w la",
          }; //return End

        default:
          return;
      }
    }

    case languageConstants.contactInfo: {
      switch (language) {
        case EN:
          return {
            headhouseholdlabel: "Head of Household",
            Homelesslable: "Is your household homeless?",
            Homeaddresslabel: "What is your home address?",
            Mailingaddresslabel: "Is this also your mailing address?",
            Whatismailindaddresslabel: "What is your mailing address?",
            Primaryphonenumberlabel: "Primary phone number",
            Textmessagelabel: "Allow text message notifications?",
            Emaillable: "Email",
            Emailnotificationlabel: "Allow email notifications?",
            title: "Contact Information",
            header: "My Contact Info",
            addContactInfoTitle: "Change My Address",
            addContactInfoHeader: "Change My Address",
            addContactInfoSubHeaderRecert: 'To submit an online SNAP Recertification, you must provide a mailing address. If you do not have an address Alabama DHR can send mail to, call Alabama DHR SNAP Information Line at <a href="tel:8338222202">833-822-2202</a> or go to your local <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">Alabama DHR office.</a>',
            addContactInfoSubHeadertanf: "To submit an online TANF Redetermination, you must provide a mailing address. If you do not have an address Alabama DHR can send an mail to, call Alabama DHR or go to your local Alabama DHR office.",
            addContactInfoSubHeaderAesap: 'To submit an online AESAP Recertification you must provide a mailing address. If you do not have an address Alabama DHR can send mail to, call Alabama DHR Assistance Line at <a href="tel:8338222202">833-822-2202</a> or go to your local <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">Alabama DHR office.</a>',
            contactChangedQuestion:
              "Has there been any change in your contact information? (Required)",
            required: "Required",
            requiredErrorMessage: "Please choose an option.",
            addContactInfoSubHeader:
              'To submit an online SNAP Interim Report you must provide a mailing address. If you do not have an address DHR can send mail to, please call  DHR Assistance Line at <a href="tel:8773822363">877-382-2363</a> or go to your local <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">DHR office.</a>',
            labelFirstName: "What is your first name?",
            firstNameErrMsg: "Required - Please enter first name",
            middleName: "What is your middle name?",
            middleNameErrMsg: "Please enter middle name",
            lastNamelabel: "What is your last name?",
            lastNameErrMsg: "Required - Please enter last name",
            labelSuffix: "Suffix",
            suffixOption: ["Jr.",
            "Sr.",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Esq",],
            dateOfBirthLabel: "What is your date of birth?",
            dobAlertMsg: "Please enter a valid Date of Birth",
            ssnLabel: "What is your Social Security Number?",
            inputClearLabel: "I don't have one",
            ssnErrMsg: "Please enter a valid Social Security Number.",
            headerMyContactInfo: "My contact info",
            houselessLabel: "Is your household homeless?",
            resAddressLabel: "Home address",
            resSameAddressLabel: "Same as home address",
            mailingAddresssLabel: "Mailing address",
            houselessErrMsg: "Please select whether your having home or not",
            houseLessOptions: ["Yes", "No"],
            addrQuestionlabel: "What is your address?",
            validZipcode: "Please enter a valid Zipcode.",
            alsoMailingLabel: "Is this also your mailing address?",
            optionsSendMail: "Yes, send mail there.",
            optionsNoDiffAddress: "No, I use a different address for mail.",
            mailingQlabel: "What is your mailing address?",
            zipCodeErrmsg: "Please enter a valid Zipcode.",
            bestPhoneqlabel: "What is the best phone number to reach you?",
            inlineHelp: "This is the number the DHR will use to reach you.",
            validPhoneAlert: "Please enter a valid phone number.",
            editButtonLabel: "Edit",
            doneButtonlabel: "Done",
            phoneEditTitle: "Edit my phone details",
            phoneLabel: "Primary phone number",
            phoneNotificationQuestion: "Allow text message notifications?",
            phoneNotificationAgreeLabel:
              "Alabama DHR may be able to send you text messages about due dates, case information, office closing, and other important information.  Click yes if you would like to opt-in.",
            emailEditTitle: "E-mail",
            emailLabel: "Edit my e-mail address",
            emailNotificationQuestion: "Allow email notifications?",
            emailNotificationAgreeLabel:
              "Alabama DHR may be able to send you email notifications about due dates, case information, office closing, and other important information.  Click yes if you would like to opt-in.",
            emailAddressLabel: "What is your email address?",
            emailErrormsg: "Please enter a valid email address.",
            titleEmergency: "Emergency SNAP benefits",
            headerEmergency: "Emergency SNAP benefits",
            emergencySnap:
              "Emergency SNAP benefits are an initial benefit that you may be eligible for while we review your application.",
            incomeMoneylabel:
              "Does your income and money in the bank add up to less than your monthly housing expenses?",
            inlineHelpLabel:
              "For example, if you have $100 worth of income plus money in the bank, but pay $101 for monthly housing expenses, select Yes.",
            optionYesNo: ["Yes", "No"],
            alertSelectIncome: "Please select income you pay",
            incomeLesslabel:
              "Is your monthly income less than $150 and is your money in the bank $100 or less?",
            monthlyIncomeoptions: ["Yes", "No"],
            selectIncomeErrmsg: "Please select income you pay",
            migrantBefore: "Are you a ",
            migrantTrigger: "migrant worker",
            migrantAfter: " and is your money in the bank $100 or less?",
            migrantHelp:
              "A migrant worker is a person who moves from place to place doing or looking for seasonal farm work.",
            migrantOptions: ["Yes", "No"],
            migrantErrmsg: "Please select migrant you pay",
            headerMoreAbout: "More about me",
            areUsBefore: "Are you a ",
            areUsTrigger: "US citizen",
            areUsafter: "?",
            eligibleHelp:
              "To be eligible for SNAP, a person must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
            chooseErrmsg: "Please choose citizen",
            eligibleMemberHelp:
              "Even if you are not a US Citizen, you or other household members may still be eligible.",
            eligibleOptions: ["Yes", "No"],
            laguageBefore: "What language do you ",
            LANGUAGES: [
              "English",
              "Spanish",
              "Portuguese",
              "French",
              "Haitian Creole",
              "Chinese",
              "Vietnamese",
              "Khmer",
              "Korean",
              "Russian",
              "Italian",
              "Polish",
              "Arabic",
            ],
            languageTrigger: "prefer to speak",
            languageAfter: "?",
            comfortLanHelp:
              "Please tell us the language you are most comfortable speaking so that DHR can be sure to conduct your interviews in that language.",
            languageErrMsg: "Please choose language",
            genderLabel: "What is your gender?",
            genderOptions: ["Male", "Female"],
            genderErrmsg: "Please choose gender",
            labelEthnicity: "What is your ethnicity?",
            helpEthnicity:
              "We ask for your ethnicity to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            raceLabel: "What is your race?",
            raceHelp:
              "We ask for your race to ensure equal treatment and access for all. Your answer will not impact your eligibility to receive SNAP benefits.",
            optionAIdisplay: "American Indian",
            optionAsiandisplay_VALUE: "ASIAN",
            optionAsiandisplay: "Asian",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Black/African American",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Pacific Islander",
            optionwhitedisplay_VALUE: "WHITE",
            optionwhitedisplay: "White",
            declineToAnswerLabel: "Decline to answer",
            headOfHousehold: "Head of Household"
          };

        case ES:
          return {
            title: "Información de contacto",
            headhouseholdlabel: "Jefe de hogar",
            Homelesslable: "¿Su hogar es una persona sin hogar?",
            Homeaddresslabel: "¿Cual es la dirección de tu casa?",
            Mailingaddresslabel: "¿Es esta también su dirección postal?",
            Primaryphonenumberlabel: "Número de teléfono primario",
            Textmessagelabel: "¿Permitir notificaciones por mensaje de texto?",
            Emaillable: "Correo electrónico",
            Emailnotificationlabel: "¿Permitir notificaciones por correo electrónico?",
            header: "Mi información de contacto",
            addContactInfoTitle: "Cambiar mi dirección",
            addContactInfoHeader: "Cambiar mi dirección",
            addContactInfoSubHeadertanf: "Para enviar una redeterminación de TANF en línea, debe proporcionar una dirección postal. Si no tiene una dirección a la que Alabama DHR pueda enviar un correo, llame a Alabama DHR o vaya a su oficina local de Alabama DHR.",
            addContactInfoSubHeaderRecert: 'Para enviar una recertificación SNAP en línea, debe proporcionar una dirección postal. Si no tiene una dirección a la que Alabama DHR pueda enviar correo, llame a la línea de información SNAP de Alabama DHR al <a href="tel:8338222202">833-822-2202</a> o vaya a su <a href=" local https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">Oficina del DHR de Alabama.</a>',
            addContactInfoSubHeaderAesap: 'Para enviar una Recertificación AESAP en línea debe proporcionar una dirección postal. Si no tiene una dirección a la que Alabama DHR pueda enviar correo, llame a la línea de asistencia de Alabama DHR al <a href="tel:8338222202">833-822-2202</a> o vaya a su <a href=" https local ://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">Oficina del DHR de Alabama.</a>',
            contactChangedQuestion:
              "¿Se han producido cambios en su información de contacto? (Obligatorio)",
            required: "Obligatorio",
            requiredErrorMessage: "Escoja una opción",
            addContactInfoSubHeader:
              'Para presentar un Informe provisional del SNAP en línea, debe proporcionar una dirección postal. Si no tiene una dirección a donde DHR pueda enviarle correspondencia, llame a la línea de asistencia del DHR al <a href="tel:8773822363">877-382-2363</a> o acuda a su <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">oficina local del DHR.</a>',
            labelFirstName: "¿Cuál es su nombre?",
            firstNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            middleName: "¿Cuál es su segundo nombre?",
            middleNameErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            lastNamelabel: "¿Cuál es su apellido?",
            lastNameErrMsg: "Requerido: ingrese el apellido",
            labelSuffix: "Sufijo",
            suffixOption: ["Jr.",
            "Sr.",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Esq",],
            dateOfBirthLabel: "¿Cuál es su fecha de nacimiento?",
            resSameAddressLabel: "Igual que el domicilio",
            dobAlertMsg: "Por favor ingresa una fecha de nacimiento valida",
            ssnLabel: "¿Cuál es su número de seguro social?",
            inputClearLabel: "No tengo",
            ssnErrMsg: "Por favor ingrese un número de seguro social válido.",
            headerMyContactInfo: "Mi información de contacto",
            houselessLabel: "¿Se encuentra su grupo familiar sin vivienda?",
            resAddressLabel: "Dirección residencial",
            mailingAddresssLabel: "Dirección postal",
            houselessErrMsg: "Seleccione si tiene o no su hogar",
            houseLessOptions: ["Sí", "No"],
            addrQuestionlabel: "¿Cuál es su dirección?",
            validZipcode: "Por favor ingrese un código postal válido",
            alsoMailingLabel: "¿Es igual a su dirección postal?",
            optionsSendMail: "Sí, enviar correo allí. ",
            optionsNoDiffAddress:
              "No, utilizo una dirección diferente para el correo postal.",
            mailingQlabel: "¿Cuál es su dirección postal?",
            zipCodeErrmsg: "Por favor ingrese un código postal válido",
            bestPhoneqlabel:
              "¿Cuál es el mejor número de teléfono para comunicarse con usted?",
            inlineHelp: "Este es el número que el DHR utilizará para llamarlo.",
            validPhoneAlert: "Por favor ingrese un número de teléfono válido",
            editButtonLabel: "Editar",
            doneButtonlabel: "Listo",
            phoneEditTitle: "Editar los detalles de mi teléfono",
            phoneLabel: "Teléfono principal",
            phoneNotificationQuestion:
              "¿Permitir notificaciones por mensaje de texto?",
            phoneNotificationAgreeLabel:
              "DHR podría enviarle mensajes de texto sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
            emailEditTitle: "Dirección de correo electrónico",
            emailLabel: "Editar mi dirección de correo electrónico",
            emailNotificationQuestion:
              "Permitir notificaciones por correo electrónico?",
            emailNotificationAgreeLabel:
              "DHR podría enviarle correos electrónicos sobre fechas de vencimiento, información sobre el caso, cierres de oficina y otra información importante. Haga clic aquí si acepta recibirlos.",
            emailAddressLabel: "¿Cuál es su dirección de correo electrónico?",
            emailErrormsg:
              "Por favor, introduce una dirección de correo electrónico válida",
            titleEmergency: "Beneficios de SNAP de emergencia",
            headerEmergency: "Beneficios de SNAP de emergencia",
            emergencySnap:
              "Los beneficios de SNAP de emergencia son un beneficio inicial al que podría ser elegible mientras revisamos su solicitud.",
            incomeMoneylabel:
              "¿Sus ingresos y dinero en el banco son inferiores a sus gastos mensuales de vivienda?",
            inlineHelpLabel:
              "Por ejemplo, si tiene $100 en ingresos más dinero en el banco, pero paga $101 de gastos mensuales de vivienda, seleccione Sí.",
            optionYesNo: ["Sí", "No"],
            alertSelectIncome: "Por favor seleccione el ingreso que paga",
            incomeLesslabel:
              "¿Es su ingreso mensual inferior a $150 y su dinero en el banco es $100 o menos?",
            monthlyIncomeoptions: ["Sí", "No"],
            selectIncomeErrmsg: "Por favor seleccione el ingreso que paga",
            migrantBefore: "¿Es usted un ",
            migrantTrigger: " trabajador migrante",
            migrantAfter: " y su dinero en el banco es $100 o menos?",
            migrantHelp:
              "Un trabajador migrante es una persona que se mueve de un lugar a otro haciendo o buscando trabajos por temporada en granjas.",
            migrantOptions: ["Sí", "No"],
            migrantErrmsg: "Por favor selecciona migrante pagas",
            headerMoreAbout: "Más sobre mí",
            areUsBefore: "¿Es usted ",
            areUsTrigger: "ciudadano de EE. UU.",
            areUsafter: "?",
            eligibleHelp:
              "Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
            chooseErrmsg: "Por favor elija ciudadano",
            eligibleMemberHelp:
              "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
            eligibleOptions: ["Sí", "No"],
            LANGUAGES: [
              "Inglés",
              "Español",
              "Portugués",
              "Francés",
              "Criollo haitiano",
              "Chino",
              "Vietnamita",
              "Camboyano",
              "Coreano",
              "Ruso",
              "Italiano",
              "Polaco",
              "Árabe",
            ],
            laguageBefore: "¿Qué idioma ",
            languageTrigger: " prefiere hablar",
            languageAfter: "?",
            comfortLanHelp:
              "Díganos cuál es el idioma con el que se siente más cómodo para hablar de forma que el DHR pueda asegurarse de realizar las entrevistas en ese idioma.",
            languageErrMsg: "Por favor, elija el idioma",
            genderLabel: "¿Cuál es su sexo?",
            genderOptions: ["Masculino", "Femenino"],
            genderErrmsg: "Por favor elija género",
            labelEthnicity: "¿Cuál es tu origen étnico?",
            helpEthnicity:
              "Le preguntamos su origen étnico para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
            hisPanicOption: "Hispano/Latino",
            nonhisPanicOption: "No hispano/Latino",
            raceLabel: "¿Cuál es tu raza?",
            raceHelp:
              "Le preguntamos su raza para garantizar un tratamiento y acceso igualitarios para todos. Su respuesta no tendrá impacto sobre su elegibilidad para recibir beneficios de SNAP.",
            optionAIdisplay: "Indio americano",
            optionAsiandisplay_VALUE: "Asiático",
            optionAsiandisplay: "Asiático",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Negro/Afroamericano",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Isleño del Pacífico",
            optionwhitedisplay_VALUE: "Blanco",
            optionwhitedisplay: "Blanco",
            declineToAnswerLabel: "Me niego a responder",
            headOfHousehold: "Jefe de hogar"
          };

        case PT: //portugeesh
          return {
            title: "Dados de contato",
            header: "Minhas informações de contato",
            addContactInfoTitle: "Alterar meu endereço",
            addContactInfoHeader: "Alterar meu endereço",
            contactChangedQuestion:
              "Houve alguma alteração nos seus dados de contato? (Obrigatório)",
            required: "Obrigatório",
            requiredErrorMessage: "Por favor escolha uma opção",
            addContactInfoSubHeader:
              'Para enviar um Relatório Interino SNAP on-line, você deve fornecer um endereço para correspondência. Se você não tiver um endereço para o qual o DHR possa enviar correspondências, ligue para o Serviço de Atendimento DHR no número <a href="tel:8773822363">877-382-2363</a> ou compareça ao seu <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">escritório do DHR local.</a> ',
            labelFirstName: "Qual é o seu primeiro nome?",
            firstNameErrMsg: "Obrigatório - Digite o primeiro nome",
            middleName: "Qual é o seu nome do meio?",
            middleNameErrMsg: "Digite o nome do meio",
            lastNamelabel: "Qual é o seu último nome?",
            lastNameErrMsg: "Obrigatório - Digite o sobrenome",
            labelSuffix: "Sufixo",
            suffixOption: ["Jr.",
            "Sr.",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Esq",],
            dateOfBirthLabel: "Qual é a sua data de nascimento?",
            dobAlertMsg: "Por favor, insira uma data de nascimento válida ",
            ssnLabel: "Qual é o número do seu seguro social?",
            inputClearLabel: "Eu não tenho",
            ssnErrMsg: "Digite um Número de Segurança Social válido",
            headerMyContactInfo: "Minhas informações de contato",
            houselessLabel: "Sua família não tem moradia?",
            resAddressLabel: "Endereço residencial",
            resSameAddressLabel: "O mesmo endereço residencial",
            mailingAddresssLabel: "Endereço de correspondência",
            houselessErrMsg: "Selecione se você tem casa ou não",
            houseLessOptions: ["Sim", "Não"],
            addrQuestionlabel: "Qual é o seu endereço?",
            validZipcode: "Por favor insira um código postal válido",
            alsoMailingLabel:
              "Esse também é o seu endereço de correspondência?",
            optionsSendMail:
              "Sim, envie as correspondências para esse endereço. ",
            optionsNoDiffAddress:
              "Não, eu uso um endereço diferente para correspondência.",
            mailingQlabel: "Qual é o seu endereço de correspondência? ",
            zipCodeErrmsg: "Por favor insira um código postal válido",
            bestPhoneqlabel:
              "Qual é o melhor número de telefone para falar com você?",
            inlineHelp:
              "Esse será o número que o DHR irá usar para falar com você.",
            validPhoneAlert: "Por favor insira um número de telefone válido",
            editButtonLabel: "Editar",
            doneButtonlabel: "Concluir",
            phoneEditTitle: "Editar of detalhes do meu telefone",
            phoneLabel: "Número de telefone principal",
            phoneNotificationQuestion:
              "Permitir notificações por mensagens de texto",
            phoneNotificationAgreeLabel:
              "O DHR pode enviar mensagens de texto sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações.",
            emailEditTitle: "Endereço de e-mail",
            emailLabel: "Editar of detalhes do meu endereço.",
            emailNotificationQuestion: "Permitir notificações por e-mail?",
            emailNotificationAgreeLabel:
              "O DHR pode enviar e-mails sobre datas de vencimento, informações de casos, fechamentos de escritórios e outras informações importantes. Clique aqui se quiser receber essas informações",
            emailAddressLabel: "Qual é o seu endereço de e-mail?",
            emailErrormsg: "Por favor insira um endereço de e-mail válido",
            titleEmergency: "Benefício SNAP emergencial",
            headerEmergency: "Benefício SNAP emergencial",
            emergencySnap:
              "O benefício SNAP emergencial é um benefício inicial, ao qual você é elegível, que você recebe enquanto analisamos a sua solicitação.",
            incomeMoneylabel:
              "A sua renda e o dinheiro que você tem no banco são menores do que as suas despesas residenciais mensais?",
            inlineHelpLabel:
              'Por exemplo: se você tem US$ 100 como renda mais o dinheiro que tem no banco, mas gasta US$ 101 em despesas residenciais mensais, clique em "Sim".',
            optionYesNo: ["Sim", "Não"],
            alertSelectIncome: "Selecione a renda que você paga",
            incomeLesslabel:
              "A sua renda mensal é menor que US$ 150 e você tem US$ 100 ou menos no banco?",
            monthlyIncomeoptions: ["Sim", "Não"],
            selectIncomeErrmsg: "Selecione a renda que você paga",
            migrantBefore: "Você é um ",
            migrantTrigger: "trabalhador migrante",
            migrantAfter: " e tem US$ 100 ou menos no banco?",
            migrantHelp:
              "Um trabalhador migrante é alguém que vai de cidade em cidade em busca de trabalhos sazonais em fazendas.",
            migrantOptions: ["Sim", "Não"],
            migrantErrmsg: "Selecione o migrante que você paga",
            headerMoreAbout: "Mais sobre mim",
            areUsBefore: "Você é ",
            areUsTrigger: "cidadão americano",
            areUsafter: "?",
            eligibleHelp:
              "Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
            chooseErrmsg: "Escolha cidadão",
            eligibleMemberHelp:
              "Mesmo se você não for um cidadão americano, você ou outros membros da família ainda podem ser elegíveis.",
            eligibleOptions: ["Sim", "Não"],
            laguageBefore: "Em qual idioma você ",
            LANGUAGES: [
              "Inglês",
              "Espanhol",
              "Português",
              "Francês",
              "Créole haitiano",
              "Chinês",
              "Vietnamita",
              "Khmer",
              "Coreano",
              "Russo",
              "Italiano",
              "Polonês",
              "Árabe",
            ],
            languageTrigger: "prefere se comunicar",
            languageAfter: "?",
            comfortLanHelp:
              "Informe em qual idioma você se sente mais confortável para se comunicar, para que o DHR faça as entrevistas nesse idioma.",
            languageErrMsg: "Escolha o idioma",
            genderLabel: "Qual é o seu gênero?",
            genderOptions: ["Masculino", "Feminino"],
            genderErrmsg: "Escolha o gênero",
            labelEthnicity: "Qual é a sua etnia?",
            helpEthnicity:
              "Perguntamos a sua etnia para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
            raceLabel: "Qual é a sua raça?",
            hisPanicOption: "Hispânico/Latino",
            nonhisPanicOption: "Não-Hispânico/Latino",
            raceHelp:
              "Perguntamos a sua raça para garantir tratamento e acesso igualitário a todos. A sua resposta não impactará a sua elegibilidade para receber o benefício SNAP.",
            optionAIdisplay: "Americano nativo",
            optionAsiandisplay_VALUE: "Asiático",
            optionAsiandisplay: "Asiático",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Negro/Afro-americano",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Nativo das ilhas do Pacífico",
            optionwhitedisplay_VALUE: "Branco",
            optionwhitedisplay: "Branco",
            declineToAnswerLabel: "Não quero responder",
          };

        case ZH: //China
          return {
            title: "联系信息",
            header: "我的联系信息",
            addContactInfoTitle: "更改我的地址",
            addContactInfoHeader: "更改我的地址",
            contactChangedQuestion: "您的联系信息是否有任何更改？（必填）",
            required: "必填",
            requiredErrorMessage: "请选择一个选项",
            resSameAddressLabel: "与居住地址相同",
            addContactInfoSubHeader:
              '如需在线提交 SNAP《中间报告》，您必须提供邮寄地址。如果您没有可接收 DHR 所发邮件的地址，请拨打 DHR 援助热线 <a href="tel:8773822363"> 877-382-2363</a>  或前往您当地的 <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">DHR</a>办公室。',
            labelFirstName: "您的名字",
            firstNameErrMsg: "必填 - 請輸入名字",
            middleName: "您的中间名",
            middleNameErrMsg: "請輸入中間名",
            lastNamelabel: "您的姓氏",
            lastNameErrMsg: "必填 - 請輸入姓氏",
            labelSuffix: "后缀",
            suffixOption: ["一世", "II", "III", "IV", "V", "小", "高級"],
            dateOfBirthLabel: "您的出生日期？",
            dobAlertMsg: "請輸入有效的出生日期",
            ssnLabel: "你的社保号码？",
            inputClearLabel: "我没有社保",
            ssnErrMsg: "請輸入有效的社會安全號碼",
            headerMyContactInfo: "我的联系信息",
            houselessLabel: "您的家庭是否无家可归？",
            houselessErrMsg: "請選擇您是否有家",
            houseLessOptions: ["是", "否"],
            addrQuestionlabel: "您的家庭地址？",
            validZipcode: "請輸入有效的郵政編碼",
            alsoMailingLabel: "这是否也是您的邮寄地址？",
            optionsSendMail: "是，可以发送信件到这里。",
            optionsNoDiffAddress: "否，我的邮箱地址是另外一个。",
            mailingQlabel: "您的邮箱地址？",
            zipCodeErrmsg: "請輸入有效的郵政編碼",
            bestPhoneqlabel: "您最常使用的电话号码？",
            inlineHelp: "这将是DTA与您联络所使用的电话号码。",
            validPhoneAlert: "請輸入一個有效的電話號碼",
            editButtonLabel: "编辑",
            doneButtonlabel: "完成",
            mailingAddresssLabel: "邮寄地址",
            phoneEditTitle: "编辑我的手机详情",
            phoneLabel: "主要电话号码",
            phoneNotificationQuestion: "允许短信通知？",
            phoneNotificationAgreeLabel:
              "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的短信。如果您希望选择加入，请点击此处.",
            emailEditTitle: "电子邮箱地址",
            emailLabel: "编辑我的电子邮件地址",
            emailNotificationQuestion: "允许电子邮件通知?",
            emailNotificationAgreeLabel:
              "DHR 可向您发送关于到期日、案例信息、办事处关闭和其他重要信息的电子邮件。如果您希望选择加入，请点击此处",
            emailAddressLabel: "您的邮箱地址？",
            emailErrormsg: "請輸入有效的電子郵件地址",
            titleEmergency: "紧急SNAP补助",
            headerEmergency: "紧急SNAP补助",
            emergencySnap:
              "紧急SNAP补助是您在我们审核您的申请期间可能获得的第一次补助。",
            incomeMoneylabel: "您的收入和银行存款总和是否少于您的每月家庭开支?",
            inlineHelpLabel:
              "例如，如果您的收入和银行存款加起来等于100美元，而您的每月家庭开支是101美元，请选择是。",
            optionYesNo: ["是", "否"],
            alertSelectIncome: "請選擇您支付的收入",
            incomeLesslabel:
              "您的月收入是否低于150美元，您的银行存款是否少于100美元？",
            monthlyIncomeoptions: ["是", "否"],
            selectIncomeErrmsg: "請選擇您支付的收入",
            migrantBefore: "您是否是 ",
            migrantTrigger: "外来务工人员",
            migrantAfter: " 您的银行存款是否少于100美元？",
            migrantHelp:
              "外来务工人员是指从一个地方搬迁到另一个地方做季节性工作的人。",
            migrantOptions: ["是 ", "否"],
            migrantErrmsg: "請選擇你支付的移民",
            headerMoreAbout: "关于我的更多信息",
            areUsBefore: "您是否是 ",
            areUsTrigger: "美国公民",
            areUsafter: "?",
            eligibleHelp:
              "要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
            chooseErrmsg: "請選擇公民",
            eligibleMemberHelp:
              "即使您并不是美国公民，您或其他家庭成员也依然可能符合资格。",
            eligibleOptions: ["是", "否"],
            resAddressLabel: "住宅地址",
            laguageBefore: "您 ",
            LANGUAGES: [
              "英语",
              "西班牙语",
              "葡萄牙语",
              "法语",
              "海地克里奥尔语",
              "中文",
              "越南语",
              "柬埔寨语",
              "朝鮮的",
              "俄语",
              "意大利语",
              "波兰语",
              "阿拉伯语",
            ],
            languageTrigger: "日常使用哪种语言交流",
            languageAfter: "?",
            comfortLanHelp:
              "请告诉我们您最熟悉的语言，以便DTA可以确保使用您的语言进行面试。",
            languageErrMsg: "請選擇語言",
            genderLabel: "您的性别？",
            genderOptions: ["男", "女"],
            genderErrmsg: "請選擇性別",
            labelEthnicity: "您的民族？",
            helpEthnicity:
              "我们询问您的民族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
            hisPanicOption: "西班牙/拉丁美洲",
            nonhisPanicOption: "非西班牙/拉丁美洲",
            raceLabel: "您的种族？",
            raceHelp:
              "我们询问您的种族是为了确保平等对待和评价所有人。您的答案不会影响您获得SNAP补助的资格",
            optionAIdisplay: "美洲印第安人",
            optionAsiandisplay_VALUE: "亚洲人",
            optionAsiandisplay: "亚洲人",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "黑人/非裔美国人",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "太平洋岛民",
            optionwhitedisplay_VALUE: "白人",
            optionwhitedisplay: "白人",
            declineToAnswerLabel: "拒绝回答",
          };

        case VI: //Vietnam
          return {
            title: "Thông tin liên lạc",
            header: "Thông tin liên lạc",
            addContactInfoTitle: "Thay đổi địa chỉ của tôi",
            addContactInfoHeader: "Thay đổi địa chỉ của tôi",
            contactChangedQuestion:
              "Đã có bất kỳ thay đổi nào về thông tin liên lạc của quý vị không? (Bắt buộc)",
            required: "Bắt buộc",
            resSameAddressLabel: "Giống như địa chỉ nhà ở",
            requiredErrorMessage: "Vui lòng chọn một lựa chọn",
            addContactInfoSubHeader:
              'Để gửi Báo cáo Giữa kỳ SNAP trực tuyến, quý vị phải cung cấp địa chỉ gửi thư. Nếu quý vị không có địa chỉ mà DHR có thể gửi tới, vui lòng gọi cho Đường Dây Hỗ Trợ DHR tại số  <a href="tel:8773822363">877-382-2363</a> hoặc tới <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">văn phòng DHR tại địa phương của bạn.</a>',
            labelFirstName: "Tên của quý vị?",
            firstNameErrMsg: "Bắt buộc - Vui lòng nhập tên",
            middleName: "Tên lót của quý vị?",
            middleNameErrMsg: "Vui lòng nhập tên đệm",
            lastNamelabel: "Họ của quý vị?",
            lastNameErrMsg: "Bắt buộc - Vui lòng nhập họ",
            labelSuffix: "Tước hiệu",
            suffixOption: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "Jr.", "Sr."],
            dateOfBirthLabel: "Ngày tháng năm sinh của quý vị?",
            dobAlertMsg: "Vui lòng nhập một ngày sinh hợp lệ",
            ssnLabel: "Số an sinh xã hội của quý vị?",
            inputClearLabel: "Tôi không có số ASXH",
            mailingAddresssLabel: "Địa chỉ gửi thư",
            ssnErrMsg: "Vui lòng nhập Số an sinh xã hội hợp lệ",
            headerMyContactInfo: "Thông tin liên lạc",
            resAddressLabel: "Địa chỉ cư trú",
            houselessLabel: "Gia đình quý vị có chỗ ở không?",
            houselessErrMsg: "Vui lòng chọn có nhà của bạn hay không",
            houseLessOptions: ["Có", "Không"],
            addrQuestionlabel: "Địa chỉ của quý vị là gì?",
            validZipcode: "Vui lòng nhập một mã zip hợp lệ",
            alsoMailingLabel: "Đây cũng là địa chỉ gửi thư của quý vị?",
            optionsSendMail: "Phải, hãy gửi thư đến đó.",
            optionsNoDiffAddress:
              "Không, tôi dùng một địa chỉ khác để nhận thư.",
            mailingQlabel: "Địa chỉ gởi thư của quý vị là gì?",
            zipCodeErrmsg: "Vui lòng nhập một mã zip hợp lệ",
            bestPhoneqlabel:
              "Số điện thoại nào để liên lạc với quý vị tốt nhất?",
            inlineHelp: "Đây là số mà DHR sẽ dùng để gọi cho quý vị.",
            validPhoneAlert: "Xin vui lòng nhập một số điện thoại hợp lệ",
            editButtonLabel: "Chỉnh sửa",
            doneButtonlabel: "Hoàn tất",
            phoneEditTitle: "Chỉnh sửa chi tiết điện thoại của tôi",
            phoneLabel: "Số điện thoại chính",
            phoneNotificationQuestion: "Cho phép các thông báo bằng in nhắn ?",
            phoneNotificationAgreeLabel:
              "DHR có thể gửi cho quý vị tin nhắn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
            emailEditTitle: "Địa chỉ email",
            emailLabel: "Chỉnh sửa địa chỉ email của tôi",
            emailNotificationQuestion: "Cho phép các thông báo bằng email?",
            emailNotificationAgreeLabel:
              "DHR có thể gửi email cho bạn về thời hạn, thông tin về trường hợp của quý vị, đóng cửa văn phòng và các thông tin quan trọng khác. Nhấn vào đây nếu quý vị chấp nhận",
            emailAddressLabel: "Địa chỉ email của quý vị là gì?",
            emailErrormsg: "Vui lòng nhập một địa chỉ E-mail hợp lệ",
            titleEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
            headerEmergency: "Các khoản trợ cấp SNAP khẩn cấp",
            emergencySnap:
              "Trợ cấp SNAP khẩn cấp là một khoản trợ cấp đầu tiên mà quý vị có thể hội đủ điều kiện nhận được trong khi chúng tôi xem xét đơn xin của quý vị. ",
            incomeMoneylabel:
              "Có phải thu nhập và tiền trong ngân hàng của quý vị cộng lại ít hơn chi phí nhà ở hàng tháng của quý vị không?",
            inlineHelpLabel:
              "Ví dụ, nếu quý vị có thu nhập 100 Đô la cộng với tiền trong ngân hàng, nhưng phải trả 101 Đô la cho chi phí nhà ở hàng tháng thì chọn Yes (Có). ",
            optionYesNo: ["Có", "Không"],
            alertSelectIncome: "Vui lòng chọn thu nhập bạn phải trả",
            incomeLesslabel:
              "Thu nhập hàng tháng của quý vị ít hơn 150 Đô la và tiền của quý vị trong ngân hàng là 100 đô la hoặc ít hơn phải không?",
            monthlyIncomeoptions: ["Có", "Không"],
            selectIncomeErrmsg: "Vui lòng chọn thu nhập bạn phải trả",
            migrantBefore: "Quý vị có phải là ",
            migrantTrigger: "công nhân di cư",
            migrantAfter:
              " và số tiền của quý vị trong ngân hàng là 100 Đô la hoặc ít hơn?",
            migrantHelp:
              "Một công nhân di cư là người di chuyển từ nơi này đến nơi khác đang làm việc hoặc đang tìm kiếm việc làm ở nông trại theo thời vụ.",
            migrantOptions: ["Có", "Không"],
            migrantErrmsg: "Hãy chọn người di dân bạn phải trả",
            headerMoreAbout: "Thông tin thêm về tôi",
            areUsBefore: "Quý vị có là một ",
            areUsTrigger: "Công dân Hoa kỳ",
            areUsafter: "?",
            eligibleHelp:
              "Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
            chooseErrmsg: "Vui lòng chọn công dân",
            eligibleMemberHelp:
              "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong gia đình vẫn có thể đủ điều kiện để hưởng các lợi ích.",
            eligibleOptions: ["Có", "Không"],
            laguageBefore: "Quý vị ",
            LANGUAGES: [
              "Tiếng Anh",
              "Tiếng Tây Ban Nha",
              "Tiếng Bồ Đào nha",
              "Tiếng Pháp",
              "Tiếng Haitian Creole",
              "Tiếng Trung quốc",
              "Tiếng Việt",
              "Tiếng Campuchia",
              "Hàn Quốc",
              "Tiếng Nga",
              "Tiếng Ý",
              "Tiếng Ba Lan",
              "Tiếng Ả rập",
            ],
            languageTrigger: " thích nói ngôn ngữ nào hơn",
            languageAfter: "?",
            comfortLanHelp:
              "Vui lòng cho chúng tôi biết ngôn ngữ mà quý vị cảm thấy thoải mái nhất khi nói chuyện để DHR có thể bảo đảm tiến hành các cuộc phỏng vấn bằng ngôn ngữ đó.",
            languageErrMsg: "Vui lòng chọn ngôn ngữ",
            genderLabel: "Giới tính của quý vị là gì?",
            genderOptions: ["Nam", "Nữ"],
            genderErrmsg: "Vui lòng chọn giới tính",
            labelEthnicity: "Quý vị thuộc dân tộc nào?",
            hisPanicOption: "Gốc Tây Ban Nha, Bồ Đào Nha / La tinh",
            nonhisPanicOption:
              "Không phải gốc Tây Ban Nha, Bồ Đào nha /La tinh",
            helpEthnicity:
              "Chúng tôi hỏi về dân tộc của quý vị nhằm bảo đảm việc đối xử bình đẳng và khả năng tiếp cận với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
            raceLabel: "Quý vị thuộc chủng tộc nào?",
            raceHelp:
              "Chúng tôi hỏi về chủng tộc của quý vị để bảo đảm việc đối xử bình đẳng và khả năng tiếp cận đối với tất cả thông tin. Câu trả lời của quý vị sẽ không ảnh hưởng đến khả năng nhận trợ cấp SNAP ",
            optionAIdisplay: "Người Mỹ bản xứ",
            optionAsiandisplay_VALUE: "Người Châu Á",
            optionAsiandisplay: "Người Châu Á",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Người Mỹ da đen/Mỹ gốc Phi",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "Người Quần đảo Thái Bình Dương",
            optionwhitedisplay_VALUE: "Người da trắng",
            optionwhitedisplay: "Người da trắng",
            declineToAnswerLabel: "Từ chối trả lời",
          };
        case HI:
          return {
            title: "Enfòmasyon sou kontak",
            header: "Enfòmasyon kontak mwen",
            addContactInfoTitle: "Chanje adrès mwen",
            addContactInfoHeader: "Chanje adrès mwen",
            contactChangedQuestion:
              "Èske te gen nenpòt chanjman nan enfòmasyon kontak ou? (Obligatwa)",
            required: "Obligatwa",
            requiredErrorMessage: "Tanpri chwazi yon opsyon",
            addContactInfoSubHeader:
              'Pou soumèt yon Rapò Pwovizwa SNAP an liy ou dwe bay yon adrès postal. Si w pa gen yon adrès kote DHR ka voye lapòs, tanpri rele Liy Asistans DHR a nan <a href="tel:8773822363">877-382-2363</a>  oswa ale nan biwo lokal <a href=" https://www.mass.gov/orgs/department-of-transitional-assistance/locations" target="_blank">DHR w la.</a>',
            labelFirstName: "Ki prenon w?",
            firstNameErrMsg: "Obligatwa - Tanpri antre prenon",
            middleName: "Ki dezyèm prenon w?",
            middleNameErrMsg: "Tanpri antre dezyèm prenon",
            lastNamelabel: "Ki siyati w?",
            lastNameErrMsg: "Obligatwa - Tanpri antre siyati",
            labelSuffix: "Sifiks",
            suffixOption: ["I", "II", "III", "4", "5", "Fis", "Papa"],
            dateOfBirthLabel: "Ki dat nesans ou?",
            dobAlertMsg: "Tanpri antre yon dat nesans ki valid",
            ssnLabel: "Ki nimewo Sekirite Sosyal ou?",
            inputClearLabel: "M pa genyen youn",
            ssnErrMsg: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
            headerMyContactInfo: "Enfòmasyon kontak mwen",
            houselessLabel: "Èske fwaye w la sanzabri?",
            resAddressLabel: "Adrès rezidansyèl",
            resSameAddressLabel: "Menm ak Adrès Rezidansyèl",
            mailingAddresssLabel: "Adrès postal",
            houselessErrMsg: "Tanpri chwazi si ou gen kay ou oswa pa genyen",
            houseLessOptions: ["Wi", "Non"],
            addrQuestionlabel: "Ki adrès ou?",
            validZipcode: "Tanpri antre yon kòd postal ki valid",
            alsoMailingLabel: "Èske se adrès postal ou a tou?",
            optionsSendMail: "Wi, voye lapòs la.",
            optionsNoDiffAddress: "Non, mwen itilize yon lòt adrès pou lapòs.",
            mailingQlabel: "Ki adrès postal ou?",
            zipCodeErrmsg: "Tanpri antre yon kòd postal ki valid",
            bestPhoneqlabel: "Ki pi bon nimewo telefòn pou kontakte ou?",
            inlineHelp: "Sa a se nimewo DHR a pral itilize pou kontakte w.",
            validPhoneAlert: "Tanpri antre yon nimewo telefòn ki valid",
            editButtonLabel: "Edite",
            doneButtonlabel: "Fini",
            phoneEditTitle: "Edite detay telefòn mwen yo",
            phoneLabel: "Nimewo telefòn prensipal la",
            phoneNotificationQuestion: "Pèmèt notifikasyon mesaj tèks?",
            phoneNotificationAgreeLabel:
              "DHR ka anmezi pou voye mesaj tèks ba ou sou dat limit, enfòmasyon sou dosye, fèmti biwo, ak lòt enfòmasyon enpòtan. Klike sou wi si ou ta renmen patisipe.",
            emailEditTitle: "Imel",
            emailLabel: "Korije adrès imel mwen",
            emailNotificationQuestion: "Pèmèt notifikasyon imel?",
            emailNotificationAgreeLabel:
              "DHR ka anmezi pou voye imèl notifikasyon ba ou sou dat limit, enfòmasyon sou dosye, fèmti biwo, ak lòt enfòmasyon enpòtan. Klike sou wi si ou ta renmen patisipe",
            emailAddressLabel: "Ki adrès imel ou?",
            emailErrormsg: "Tanpri antre yon adrès imel ki valid",
            titleEmergency: "Benefis SNAP nan Ijans",
            headerEmergency: "Benefis SNAP nan Ijans",
            emergencySnap:
              "Benefis SNAP Ijans se yon premye benefis ou ka kalifye pou li pandan n ap revize aplikasyon w lan.",
            incomeMoneylabel:
              "Èske revni w ak lajan labank ou ajoute ansanm reprezante mwens pase depans lojman mansyèl ou?",
            inlineHelpLabel:
              "Pa egzanp, si w gen $100 revni plis lajan labank, men ou peye $101 pou depans lojman mansyèl, chwazi Wi.",
            optionYesNo: ["Wi", "Non"],
            alertSelectIncome: "Tanpri chwazi revni ou peye",
            incomeLesslabel:
              "Èske revni mansyèl ou mwens pase $150epi èske lajan labank ou se $100 oswa mwens?",
            monthlyIncomeoptions: ["Wi", "Non"],
            selectIncomeErrmsg: "Tanpri chwazi revni ou peye",
            migrantBefore: "Èske w se yon ",
            migrantTrigger: "travayè migran",
            migrantAfter: " epi èske lajan labank ou se $100 oswa mwens?",
            migrantHelp:
              "Yon travayè migran se yon moun ki deplase soti yon kote pou ale nan yon lòt pou fè oswa chèche travay agrikòl sezonye.",
            migrantOptions: ["Wi", "Non"],
            migrantErrmsg: "Tanpri chwazi migran ou peye",
            headerMoreAbout: "Plis konsènan mwen menm",
            areUsBefore: "Èske w se yon ",
            areUsTrigger: "sitwayen Ameriken",
            areUsafter: "?",
            eligibleHelp:
              "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
            chooseErrmsg: "Tanpri chwazi sitwayen",
            eligibleMemberHelp:
              "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
            eligibleOptions: ["Wi", "Non"],
            LANGUAGES: [
              "Angle",
              "Panyòl",
              "Pòtigè",
              "Franse",
              "Kreyòl Ayisyen",
              "Chinwa",
              "Vyetnamyen",
              "Kmè",
              "Korean",
              "Ris",
              "Italyen",
              "Polonè",
              "Arabik",
            ],
            languageBefore: "Ki lang ou ",
            languageTrigger: "prefere pale ",
            languageAfter: "?",
            comfortLanHelp:
              " Tanpri di nou ki lang ou pi alèz pou pale pou DHR ka asire li ke li fè entèvyou w yo nan lang sa a.",
            languageErrMsg: "Tanpri chwazi ki lang",
            genderLabel: "Ki sèks ou?",
            genderOptions: ["Gason", "Fi"],
            genderErrmsg: "Tanpri chwazi sèks",
            labelEthnicity: "Ki etnisite ou?",
            helpEthnicity:
              "Nou mande etnisite w pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            raceLabel: "Ki ras ou?",
            raceHelp:
              "Nou mande ras ou pou nou ka asire tretman ak aksè egal pou tout moun. Repons ou pa pral gen enpak sou kalifikasyon w pou resevwa benefis SNAP.",
            optionAIdisplay: "Ameriken Endyen",
            optionAsiandisplay_VALUE: "Azyatik",
            optionAsiandisplay: "Azyatik",
            optionBlackdisplay_VALUE: "BLCAFR",
            optionBlackdisplay: "Nwa/Afriken Ameriken",
            optionPacificdisplay_VALUE: "NTHPCI",
            optionPacificdisplay: "moun Zile Pasifik",
            optionwhitedisplay_VALUE: "WHITE",
            optionwhitedisplay: "Blan",
            declineToAnswerLabel: "Refize reponn",
          };
        default:
          return;
      }
    }

    case languageConstants.householdStatic: {
      switch (language) {
        case EN:
          return {
            houseTitle: "Household Members",
            houseHeader: "About My Household",
            householdDetailslabel: "Household Details",
            howManyLabel:
              "How many people live in your household, including you?",
            houseHelp:
              "If you buy and make more than 2/3 of your meals with others, they must be in your household. If your spouse or children under 22 live with you, they must be in your household even if you do not buy and make meals with them.",
            houseErrMsg: "Please choose no.of household",
            houseMemberTitle: "Add a New Household Member",
            houseMemberHeader: "Your first household member is ",
            houseMemberSubHeader1: "Please fill in the information for anyone that has moved into your household. If a new member has income, please attach proof of the income such as check stubs or statement from employer, award letter, and written statements to verify the income.",
            houseMemberSubHeader2: "We can give Food Assistance benefits only to people who give us their social security number or proof that they have applied for a social security number. You don't have to give us the social security number or citizenship status for the people you do not want Food Assistance benefits for. (We have to ask for ethnicity and race, but you don't have to answer. Your answer won't affect how many benefits you get or how soon you get them).",
            houseFirstName: "What is this person's first name?",
            firstErrmsg: "Please enter the first name.",
            houseMiddleLabel: "What is this person's middle name?",
            houseMiddleName: "Please enter middle name",
            houseLastNameLabel: "What is this person's last name?",
            enterLastname: "Please enter the last name.",
            enterDOB: "Please enter a valid date of birth.",
            suffixLabel: "Suffix",
            SuffixOptions: ["Jr.",
            "Sr.",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Esq",],
            relationQlabel: "What is this person's relationship to you?",
            spouseDisplay: "Spouse",
            motherDisplay: "Mother",
            faterDisplay: "Father",
            daughterDisplay: "Daughter",
            sonDisplay: "Son",
            brotherDisplay: "Brother",
            sisterDisplay: "Sister",
            grandMaDisplay: "Grandmother",
            grandFaDisplay: "Grandfather",
            grandDaugther: "Granddaughter",
            grandSon: "Grandson",
            otherDisplay: "Other",
            houseDobLabel: "What is this person's date of birth?",
            personGenLabel: "What is this person's gender?",
            genderOptios: ["Male", "Female"],
            citiBeforeQLabel: "Is this person a ",
            citiTriggerQLabel: "US Citizen",
            citiAfterQLabel: "?",
            citizenHelp:
              " To be eligible for SNAP, a member must be a U.S citizen or qualified non-citizen. If you are not sure if a household member has an eligible status, DHR will work with you to determine eligibility. You can apply for eligible household members, even if you do not have a qualified status.",
            citizenInhelp:
              "Even if you are not a US Citizen, you or other household members may still be eligible.",
            citizenOptions: ["Yes", "No"],
            ssnQLabel: "What is this person's Social Security Number?",
            ssnDntLabel: "Doesn't have one",
            ssnErrLabel: "Please enter a valid Social Security Number.",
            hholdHeader: "Household details",
            disabilityQLabel:
              "Does anyone in the house have a physical or mental ",
            disabilityQlabel2: "",
            disabilityQTriggerLabel: "disability",
            disabilityHelp:
              "A disability must be federally certified to be considered for SNAP. For example, people who receive SSI have a federally qualified disability.",
            disablilityCler: "No one",
            buyFoodLabel: "Do you regularly purchase and prepare food together?",
            buyFoodOptions: ["Yes", "No"],
            personIncomLabel: "Does this person have any income?",
            personIncomeOptions: ["Yes", "No"],
            headTitle: "Head of Household",
            removeButtonLabel: "Remove",
            undoButtonLabel: "Undo",
            changedLabel: "Changed",
            removedLabel: "Removed",
            removedLabelContact: "None",
            newLabel: "New",
            membersTitle: "Members of Household",
            additionalMembersTitle: "Additional Household Members ",
            addMemberButtonLabel: "Add a new household member",
            householdChangedQuestionLabel:
              "Has anyone moved into or out of your household?",
            inlineHelpSSN:
              "Per Federal regulations, noncitizens who are not applying for SNAP do not need to give their SSN or citizenship status.",
            labelEthnicity: "What is this person's ethnicity?",
            raceLabel: "What is this person's race?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "American Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Please enter other race information",
            otherRaceErrMsgLength: "Please enter other race information greater than 3 characters or less than 250 characters",
            helpTextSSN1: "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
            helpTextSSN2: "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
            helpTextSSN3: "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
            helpTextSSN4: "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
            helpTextSSN5: "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
            helpTextSSN6: "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
            receivedLieapOptions: ["Yes", "No"],
            receivedLieapLabel: "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
          };

        case ES: //spanish
          return {
            houseTitle: "Miembros del grupo familiar",
            houseHeader: "Acerca de mi grupo familiar",
            householdDetailslabel: "Detalles del hogar",
            howManyLabel:
              "¿Cuántas personas viven en su núcleo familiar, incluido usted?",
            houseHelp:
              "Si compra y prepara más de 2/3 de sus comidas con otras personas, esas personas deben estar en su núcleo familiar. Si su cónyuge o hijos menores de 22 años viven con usted, deben estar en su núcleo familiar aunque usted no compre y prepare comidas con ellos.",
            houseErrMsg: "Por favor, elija no.of hogar",
            headTitle: "Jefe/a de familia",
            houseMemberTitle: "Agregar un nuevo miembro al grupo familiar",
            houseMemberHeader: "Su primer miembro del hogar es ",
            houseMemberSubHeader1: "Complete la información de cualquier persona que se haya mudado a su hogar. Si un nuevo miembro tiene ingresos, adjunte prueba de los ingresos, como talones de cheques o declaración del empleador, carta de concesión y declaraciones escritas para verificar los ingresos.",
            houseMemberSubHeader2: "Podemos otorgar beneficios de Asistencia Alimentaria solo a personas que nos brinden su número de seguro social o prueba de que han solicitado un número de seguro social. No es necesario que nos proporcione el número de seguro social o el estado de ciudadanía de las personas para las que no desea recibir beneficios de Asistencia Alimentaria. (Tenemos que preguntar por origen étnico y raza, pero no es necesario que responda. Su respuesta no afectará la cantidad de beneficios que obtenga ni la rapidez con la que los obtenga).",
            houseFirstName: "¿Cuál es el nombre de esta persona?",
            firstErrmsg: "Por favor, introduzca el primer nombre",
            houseMiddleLabel: "¿Cuál es el segundo nombre de esta persona?",
            houseMiddleName: "Por favor ingrese un segundo nombre",
            houseLastNameLabel: "¿Cuál es el apellido de esta persona?",
            enterLastname: "Por favor ingrese el apellido",
            enterDOB: "Ingrese una fecha de nacimiento válida.",
            suffixLabel: "Sufijo",
            SuffixOptions: ["Jr.",
            "Sr.",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Esq",],
            relationQlabel:
              "¿Cuál es la relación de esta persona contigo?",
            spouseDisplay: "Cónyuge",
            motherDisplay: "Madre",
            faterDisplay: "Padre",
            daughterDisplay: "Hija",
            sonDisplay: "Hijo",
            brotherDisplay: "Hermano",
            sisterDisplay: "Hermana",
            grandMaDisplay: "Abuela",
            grandFaDisplay: "Abuelo",
            grandDaugther: "Nieta",
            grandSon: "Nieto",
            otherDisplay: "Otro",
            houseDobLabel: "¿Cuál es la fecha de nacimiento de esta persona?",
            personGenLabel: "¿Cuál es el género de esta persona?",
            genderOptios: ["Masculino", "Femenino"],
            citiBeforeQLabel: "¿Es esta persona una ",
            citiTriggerQLabel: "ciudadano/a estadounidense",
            citiAfterQLabel: "?",
            addMemberButtonLabel: "Agregar un nuevo miembro al grupo familiar",
            citizenHelp:
              " Para ser elegible a SNAP, una persona debe ser ciudadano de EE. UU. o un extranjero calificado. Si no está seguro de si un miembro de su núcleo familiar tiene estado de elegibilidad, el DHR trabajará con usted para determinar la elegibilidad. Puede presentar su solicitud para los miembros elegibles de su núcleo familiar, incluso si usted no tiene un estado calificado.",
            citizenInhelp:
              "Aunque no sean ciudadanos estadounidenses, usted u otros miembros del grupo familiar podrían ser elegibles.",
            citizenOptions: ["Sí", "No"],
            ssnQLabel: "¿Cuál es el número de Seguro Social de esta persona?",
            ssnDntLabel: "No tiene",
            ssnErrLabel: "Ingrese un Número de Seguro Social válido.",
            hholdHeader: "Detalles de mi núcleo familiar",
            disabilityQLabel: "¿Alguna persona tiene una ",
            disabilityQlabel2: " mental o física ",
            disabilityQTriggerLabel: "discapacidad",
            disabilityHelp:
              "Una discapacidad debe contar con certificación federal para ser considerada para el SNAP. Por ejemplo, las personas que reciben SSI tienen una discapacidad con certificación federal.",
            disablilityCler: "Ninguno",
            inlineHelpSSN:
              "Según las regulaciones federales, los no ciudadanos que no están solicitando SNAP no necesitan dar su SSN o estado de ciudadanía",
            removeButtonLabel: "Eliminar",
            undoButtonLabel: "Deshacer",
            changedLabel: "Modificado",
            removedLabel: "Eliminado",
            newLabel: "Nuevo",
            membersTitle: "Miembros del grupo familiar",
            additionalMembersTitle: "Miembros adicionales del hogar",
            householdChangedQuestionLabel:
              "¿Alguien se ha unido o ha dejado su hogar?",
            buyFoodLabel: "¿Normalmente compran alimentos y cocinan juntos?",
            buyFoodOptions: ["Sí", "No"],
            personIncomeOptions: ["Sí", "No"],
            personIncomLabel: "¿Tiene esta persona algún ingreso?",
            labelEthnicity: "¿Cuál es el origen étnico de esta persona?",
            raceLabel: "¿Cuál es la raza de esta persona?",
            hisPanicOption: "Hispana / latina",
            nonhisPanicOption: "No hispana / latina",
            declineToAnswerLabel: "Negarse a contestar",
            raceOptions: [
              "Indio americano/nativo de Alaska",
              "Asiático",
              "Negro / afroamericano",
              "Nativo de Hawái/Otro isleño del Pacífico",
              "Blanco",
              "Negarse a contestar",
              "Otro",
            ],
            otherRaceErrmsg: "Por favor ingrese otra información de la carrera",
            otherRaceErrMsgLength: "Ingrese otra información de la carrera de más de 3 caracteres o menos de 250 caracteres",
            helpTextSSN1: "La recopilación de un Número de Seguro Social (SSN) para cada miembro del hogar está autorizada según la Ley de Alimentos y Nutrición de 2008, según enmendada por P.L.96-58 (7 U.S. C.2025F). La información se utilizará para determinar si su hogar es elegible. o continúa siendo elegible para participar en el Programa de Asistencia Alimentaria.",
            helpTextSSN2: "Su SSN se utilizará para monitorear el cumplimiento de las regulaciones del programa y para la administración del programa. Su SSN se usará en comparaciones por computadora y revisiones o auditorías del programa para garantizar que su hogar sea elegible para recibir asistencia alimentaria",
            helpTextSSN3: "Proporcionar un SSN para cada miembro del hogar es voluntario. Sin embargo, no proporcionar un SSN para cada miembro del hogar resultará en la descalificación de ese miembro. Aún tendrá que proporcionar información como los ingresos de este miembro. ",
            helpTextSSN4: "Cualquier SSN proporcionado se utilizará y divulgará de la misma manera que los SSN de los miembros elegibles del hogar.",
            helpTextSSN5: "El uso o divulgación de información se realizará únicamente para ciertos fines limitados permitidos según las leyes y regulaciones estatales y federales. También se puede divulgar información a funcionarios encargados de hacer cumplir la ley con el fin de detener a personas que huyen para eludir la ley.",
            helpTextSSN6: "Si surge un reclamo de asistencia alimentaria contra su hogar, la información contenida en este informe semestral, incluidos todos los números de seguro social, puede remitirse a agencias federales y estatales, así como a agencias privadas de cobro de reclamos, para que se tomen medidas de cobro de reclamos.",
            receivedLieapOptions: ["Sí", "No"],
            receivedLieapLabel: "¿Ha recibido un pago del Programa de asistencia energética para hogares de bajos ingresos o espera recibir LIHEAP?",
            removedLabelContact: "Ninguno",
          };

        case PT: //portugeesh
          return {
            houseTitle: "Membros do domicílio",
            houseHeader: "Sobre o meu domicílio",
            howManyLabel:
              "Quantas pessoas compõem a sua família, incluindo você?",
            houseHelp:
              "Se você fizer mais do que 2/3 das suas refeições com outras pessoas, deve-se considerar que elas vivem na sua casa. Se a sua esposa ou filhos com menos de 22 anos vivem com você, deve-se considerar que eles vivem na sua casa, mesmo que você não faça suas refeições com eles.",
            houseErrMsg: "Por favor, escolha o número de casa",
            houseMemberTitle: "Adicionar um novo membro ao domicílio",
            houseMemberHeader: "Seu primeiro membro da família é ",
            houseFirstName: "Qual é o primeiro nome dessa pessoa?",
            firstErrmsg: "Por favor digite o primeiro nome",
            houseMiddleLabel: "Qual é o nome do meio dessa pessoa?",
            houseMiddleName: "Digite o nome do meio",
            houseLastNameLabel: "Qual é o último nome dessa pessoa?",
            enterLastname: "Por favor insira o sobrenome",
            enterDOB: "Insira uma data de nascimento válida",
            suffixLabel: "Sufixo",
            SuffixOptions: ["Jr.",
            "Sr.",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
            "Esq",],
            relationQlabel: "Qual é o relacionamento dessa pessoa com você?",
            spouseDisplay: "Cônjuge",
            motherDisplay: "Mãe",
            faterDisplay: "Pai",
            daughterDisplay: "Filha",
            sonDisplay: "Filho",
            brotherDisplay: "Irmão",
            sisterDisplay: "Irmã",
            grandMaDisplay: "Avó",
            grandFaDisplay: "Avô",
            grandDaugther: "Neta",
            grandSon: "Neto",
            headTitle: "Chefe do domicílio",
            otherDisplay: "Outros",
            houseDobLabel: "Qual é a data de nascimento desta pessoa? ",
            personGenLabel: "Qual é o sexo dessa pessoa?",
            genderOptios: ["Masculino", "Feminino"],
            citiBeforeQLabel: "Esta pessoa é um ",
            citiTriggerQLabel: "cidadão americano ",
            citiAfterQLabel: "?",
            citizenHelp:
              " 'Para ser elegível ao benefício SNAP, a pessoa deve ser cidadã americana ou não cidadã qualificada. Se você não tem certeza se um membro da sua família tem esse status de elegibilidade, o DHR irá entrar em contato com você para determinar isso. Você pode enviar uma solicitação em nome dos membros elegíveis da sua família, mesmo que você não seja qualificado.",
            citizenInhelp:
              "Mesmo que você não seja cidadão americano, você ou outro membro da sua família ainda pode ser elegível.",
            citizenOptions: ["Sim", "Não"],
            ssnQLabel: "Qual é o número do seguro social dessa pessoa?",
            ssnDntLabel: "Não tem",
            ssnErrLabel: "Digite um Número de Segurança Social válido",
            hholdHeader: "Informações da minha família",
            disabilityQLabel: "Alguém sofre de algum tipo de deficiência ",
            disabilityQlabel2: " física ou mental ",
            disabilityQTriggerLabel: "deficiência",
            disabilityHelp:
              "A deficiência deve ter certificação federal para ser considerada para o benefício SNAP. Por exemplo, pessoas que recebem SSI têm seu tipo de deficiência certificada.",
            disablilityCler: "Nenhum",
            inlineHelpSSN:
              "De acordo com as regulamentações federais, os não-cidadãos que não solicitam o SNAP não precisam fornecer seu status de SSN ou de cidadania.",
            removeButtonLabel: "Excluir",
            undoButtonLabel: "Desfazer",
            changedLabel: "Alterado",
            removedLabel: "excluído",
            newLabel: "Novo",
            membersTitle: "Membros da família",
            additionalMembersTitle: "Additional Household Members ",
            householdChangedQuestionLabel:
              "Alguém se juntou ou saiu da sua casa?",
            buyFoodLabel: "Vocês compram e fazem comida juntos regularmente?",
            buyFoodOptions: ["Sim", "Não"],
            personIncomeOptions: ["Sim", "Não"],
            personIncomLabel: "Essa pessoa tem algum rendimento?",
            addMemberButtonLabel: "Adicionar um novo membro ao domicílio",
            labelEthnicity: "What is your ethnicity?",
            raceLabel: "What is your race?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "America Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Please enter other race information",
            otherRaceErrMsgLength: "Please enter other race information greater than 3 characters or less than 250 characters",
            helpTextSSN1: "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
            helpTextSSN2: "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
            helpTextSSN3: "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
            helpTextSSN4: "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
            helpTextSSN5: "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
            helpTextSSN6: "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
            receivedLieapOptions: ["Yes", "No"],
            receivedLieapLabel: "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
          };

        case ZH: //China
          return {
            houseTitle: "家庭成员",
            houseHeader: "关于我的家庭",
            howManyLabel: "包括您自己在内，您家庭中有几人？",
            houseHelp:
              "如果有人与您一起购买和制作家庭2/3以上的餐食，则必须将这些人记入您的家庭。如果您的配偶或22岁以下的子女与您同住，则必须将他们记入您的家庭，即使您不与他们一起购买和做饭",
            houseErrMsg: "請選擇家庭數量",
            houseMemberTitle: "新增家庭成员",
            houseMemberHeader: "你的第一個家庭成員是 ",
            houseFirstName: "此人的名字？",
            firstErrmsg: "請輸入名字",
            houseMiddleLabel: "此人的中间名?",
            houseMiddleName: "請輸入中間名",
            houseLastNameLabel: "此人的姓氏？",
            enterLastname: "請輸入姓氏",
            enterDOB: "请输入有效出生日期",
            suffixLabel: "后缀",
            addMemberButtonLabel: "新增家庭成员",
            headTitle: "户主",
            SuffixOptions: ["一世", "II", "III", "IV", "V", "小", "高級"],
            relationQlabel: "此人与您的关系是？",
            spouseDisplay: "配偶",
            motherDisplay: "母亲",
            faterDisplay: "父亲",
            daughterDisplay: "女儿",
            sonDisplay: "儿子",
            brotherDisplay: "兄弟",
            sisterDisplay: "姐妹",
            grandMaDisplay: "祖母",
            grandFaDisplay: "祖父",
            grandDaugther: "（外）孙女",
            grandSon: "（外）孙子",
            otherDisplay: "其他",
            houseDobLabel: "此人的出生日期是？ ",
            personGenLabel: "此人的性别是？",
            genderOptios: ["男", "女"],
            citiBeforeQLabel: "此人是否是 ",
            citiTriggerQLabel: "美国公民",
            citiAfterQLabel: "?",
            citizenHelp:
              " '要获得SNAP资格，必须是美国公民或符合资格的非美国公民。如果您无法确定家庭成员是否符合资格，DTA将协助您确定资格。即使您自己不符合资格，您可为符合资格的家庭成员提交申请。",
            citizenInhelp:
              "即使您不是美国公民，您或其他家庭成员仍可能符合条件。",
            citizenOptions: ["是", "否"],
            ssnQLabel: "此人的社会安全号码是？",
            ssnDntLabel: "无社会安全号码",
            ssnErrLabel: "請輸入有效的社會安全號碼",
            hholdHeader: "家庭详细情况",
            disabilityQLabel: "是否有人患有身体或精神 ",
            disabilityQlabel2: "",
            disabilityQTriggerLabel: "残疾",
            disabilityHelp:
              "必须具有联邦发放的残疾证才能被SNAP接受。例如，获得SSI补助的人拥有经联邦认可的残疾",
            disablilityCler: "无人",
            inlineHelpSSN:
              "根据联邦法规，未申请SNAP的非公民不需要提供其SSN或公民身份",
            removeButtonLabel: "删除",
            undoButtonLabel: "撤销",
            changedLabel: "已变化",
            removedLabel: "已搬迁",
            newLabel: "新添内容",
            membersTitle: "家庭成员",
            additionalMembersTitle: "Additional Household Members ",
            householdChangedQuestionLabel: "有没有人加入或离开你的家庭？ ",
            buyFoodLabel: "你们是否定期一起购买并制作食物？",
            buyFoodOptions: ["是", "否"],
            personIncomeOptions: ["是", "否"],
            personIncomLabel: "此人是否有收入?",
            labelEthnicity: "What is your ethnicity?",
            raceLabel: "What is your race?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "America Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Please enter other race information",
            otherRaceErrMsgLength: "Please enter other race information greater than 3 characters or less than 250 characters",
            helpTextSSN1: "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
            helpTextSSN2: "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
            helpTextSSN3: "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
            helpTextSSN4: "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
            helpTextSSN5: "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
            helpTextSSN6: "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
            receivedLieapOptions: ["Yes", "No"],
            receivedLieapLabel: "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
          };

        case VI: //Vietnam
          return {
            headTitle: "Chủ hộ",
            houseTitle: "Các thành viên trong hộ gia đình",
            houseHeader: "Về hộ gia đình của tôi",
            howManyLabel:
              "Có bao nhiêu người sống trong hộ gia đình của quý vị, kể cả quý vị?",
            houseHelp:
              "Nếu quý vị mua và nấu hơn 2/3 bữa ăn của quý vị với những người khác, họ phải ở trong hộ gia đình của quý vị. Nếu vợ / chồng hoặc con của quý vị dưới 22 tuổi sống chung với quý vị, họ phải ở trong hộ gia đình của quý vị ngay cả khi quý vị không mua và nấu bữa ăn cùng với họ",
            houseErrMsg: "Vui lòng chọn số hộ gia đình",
            houseMemberTitle: "Bổ sung một thành viên mới trong gia đình",
            houseMemberHeader: "Thành viên gia đình đầu tiên của bạn là ",
            houseFirstName: "Tên của người này là gì?",
            firstErrmsg: "Vui lòng nhập tên",
            houseMiddleLabel: "Tên lót của người này là gì?",
            houseMiddleName: "Vui lòng nhập tên đệm",
            houseLastNameLabel: "Họ của người này là gì?",
            enterLastname: "Vui lòng nhập họ",
            enterDOB: "Điền ngày sinh",
            suffixLabel: "Tước hiệu",
            addMemberButtonLabel: "Bổ sung một thành viên mới trong gia đình",
            SuffixOptions: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "Jr.", "Sr."],
            relationQlabel: "Mối quan hệ của người này với quý vị là gì?",
            spouseDisplay: "Vợ / chồng",
            motherDisplay: "Mẹ",
            faterDisplay: "Cha",
            daughterDisplay: "Con gái",
            sonDisplay: "Con trai",
            brotherDisplay: "Em trai",
            sisterDisplay: "Em gái",
            grandMaDisplay: "Bà ",
            grandFaDisplay: "Ông ",
            grandDaugther: "Cháu gái",
            grandSon: "Cháu trai (nội, ngoại)",
            otherDisplay: "Quan hệ khác",
            houseDobLabel: "Ngày sinh của người là gì?",
            personGenLabel: "Giới tính của người này là gì?",
            genderOptios: ["Nam", "Nữ"],
            citiBeforeQLabel: "Người này có phải là ",
            citiTriggerQLabel: "Công dân Hoa Kỳ không",
            citiAfterQLabel: "?",
            citizenHelp:
              " 'Để hội đủ điều kiện của SNAP, một người phải là một Công dân Hoa Kỳ hoặc người không phải công dân có đủ tư cách. Nếu quý vị không chắc chắn liệu một thành viên trong hộ gia đình có hội đủ điều kiện hay không, DHR sẽ làm việc với quý vị để xác định tình trạng hội đủ điều kiện. Quý vị có thể nộp đơn xin cho các thành viên trong gia đình hội đủ điều kiện, ngay cả khi quý vị không có đủ tư cách.",
            citizenInhelp:
              "Ngay cả khi quý vị không phải là Công dân Hoa Kỳ, quý vị hoặc các thành viên khác trong hộ gia đình của quý vị có thể vẫn hội đủ điều kiện.",
            citizenOptions: ["Có", "Không"],
            ssnQLabel: "Số An sinh Xã hội của người này là gì?",
            ssnDntLabel: "Không có",
            ssnErrLabel: "Vui lòng nhập Số an sinh xã hội hợp lệ",
            hholdHeader: "Chi tiết về hộ gia đình",
            disabilityQLabel: "Có ai bị ",
            disabilityQlabel2: " về thể chất hoặc tinh thần không ",
            disabilityQTriggerLabel: "khuyết tật",
            disabilityHelp:
              "Một người khuyết tật phải được liên bang xác nhận để được xem xét hưởng trợ cấp SNAP. Ví dụ, người người nhận SSI là người khuyết tật được liên bang xác nhận",
            disablilityCler: "Không có ai",
            inlineHelpSSN:
              "Theo quy định của Liên bang, những người không phải là công dân không đăng ký SNAP không cần phải cung cấp SSN hoặc tư cách công dân của họ.",
            removeButtonLabel: "Xóa",
            undoButtonLabel: "Hủy bỏ",
            changedLabel: "thay đổi",
            removedLabel: "xóa bỏ",
            newLabel: "mới",
            membersTitle: "Thành viên hộ gia đình",
            additionalMembersTitle: "Additional Household Members ",
            householdChangedQuestionLabel:
              "Có ai tham gia hoặc rời khỏi hộ gia đình của bạn?",
            buyFoodLabel:
              "Quý vị có thường xuyên mua thức ăn và nấu ăn cùng nhau không?",
            buyFoodOptions: ["Có", "Không"],
            personIncomeOptions: ["Có", "Không"],
            personIncomLabel: "Người này có thu nhập không",
            labelEthnicity: "What is your ethnicity?",
            raceLabel: "What is your race?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "America Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Please enter other race information",
            otherRaceErrMsgLength: "Please enter other race information greater than 3 characters or less than 250 characters",
            helpTextSSN1: "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
            helpTextSSN2: "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
            helpTextSSN3: "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
            helpTextSSN4: "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
            helpTextSSN5: "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
            helpTextSSN6: "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
            receivedLieapOptions: ["Yes", "No"],
          };
        case HI:
          return {
            houseTitle: "Manm fwaye a",
            houseHeader: "Konsènan Kay mwen an",
            howManyLabel: "Konbyen moun k ap viv nan fwaye w la, enkli oumenm?",
            houseHelp:
              "Si ou achte epi prepare plis pase 2/3 nan repa w yo ak lòt moun, yo dwe fè pati fwaye w la. Si konjwen w oswa timoun ki poko gen 22 an ap viv ak ou, yo dwe fè pati fwaye w, menm si ou pa achte ak fprepare repa ak yo.",
            houseErrMsg: "Tanpri chwazi kantite moun nan fwaye",
            houseMemberTitle: "Ajoute yon nouvo manm fwaye",
            houseMemberHeader: "Premye manm nan fwaye w la se ",
            houseFirstName: "Ki prenon moun sa a?",
            firstErrmsg: "Tanpri antre prenon",
            houseMiddleLabel: "Ki dezyèm prenon moun sa a?",
            houseMiddleName: "Tanpri antre dezyèm prenon",
            houseLastNameLabel: "Ki siyati moun sa a?",
            enterLastname: "Tanpri antre siyati",
            enterDOB: "Tanpri antre yon dat nesans ki valid",
            suffixLabel: "Sifiks",
            SuffixOptions: ["I", "II", "III", "4", "5", "Fis", "Papa"],
            relationQlabel: "Ki relasyon moun sa genyen ak ou?",
            spouseDisplay: "Konjwen",
            motherDisplay: "Manman",
            faterDisplay: "Papa",
            daughterDisplay: "Pitit fi",
            sonDisplay: "Pitit gason",
            brotherDisplay: "Frè",
            sisterDisplay: "Sè",
            grandMaDisplay: "Grann",
            grandFaDisplay: "Granpè",
            grandDaugther: "Pitit pitit fi",
            grandSon: "Pitit pitit gason",
            otherDisplay: "Lòt",
            houseDobLabel: "Ki dat nesans moun sa a?",
            personGenLabel: "Ki sèks moun sa a?",
            genderOptios: ["Gason", "Fi"],
            citiBeforeQLabel: "Èske Moun sa a se yon ",
            citiTriggerQLabel: "Sitwayen Ameriken",
            citiAfterQLabel: "?",
            citizenHelp:
              "Pou ka kalifye pou SNAP, yon moun dwe se yon sitwayen Ameriken oswa yon non-sitwayen Ameriken ki kalifye. Si w pa konnen si yon manm fwaye a gen yon estati ki kalifye, DHR pral travay ak ou pou detèmine kalifikasyon. Ou ka aplike pou manm fwaye a ki kalifye yo, menm si ou pa gen yon estati ki kalifye.",
            citizenInhelp:
              "Menmsi ou pa yon Sitwayen Ameriken, oumenm oswa lòt manm fwaye a ka toujou kalifye.",
            citizenOptions: ["Wi", "Non"],
            ssnQLabel: "Ki Nimewo Sekirite Sosyal moun sa a?",
            ssnDntLabel: "Li pa gen youn",
            ssnErrLabel: "Tanpri antre yon Nimewo Sekirite Sosyal ki valid",
            hholdHeader: "Detay Fwaye",
            disabilityQLabel: "Èske nenpòt moun nan fwaye a gen yon ",
            disabilityQlabel2: " fizik oswa mantal",
            disabilityQTriggerLabel: "andikap",
            disabilityHelp:
              "Yon andikap dwe sètifye pa gouvènman federal pou yo konsidere li pou SNAP. Pa egzanp, moun ki resevwa SSI gen yon andikap ki kalifye nan nivo federal.",
            disablilityCler: "Pesonn",
            buyFoodLabel: "Èske ou regilyèman achte epi prepare repa ansanm?",
            buyFoodOptions: ["Wi", "Non"],
            personIncomLabel: "Èske moun sa a gen revni?",
            personIncomeOptions: ["Wi", "Non"],
            headTitle: "Chèf Fwaye a",
            removeButtonLabel: "Retire",
            undoButtonLabel: "Defèt",
            changedLabel: "Chanje",
            removedLabel: "Retire",
            newLabel: "Nouvo",
            membersTitle: "Manm Fwaye",
            additionalMembersTitle: "Additional Household Members ",
            addMemberButtonLabel: "Ajoute yon nouvo manm fwaye",
            householdChangedQuestionLabel:
              "Èske nenpòt moun vin jwenn oswa kite fwaye w la?",
            inlineHelpSSN:
              "Dapre règleman federal yo, nonsitwayen Ameriken yo ki p ap aplike pou SNAP pa bezwen bay NSS oswa estati sitwayènte yo.",
            labelEthnicity: "What is your ethnicity?",
            raceLabel: "What is your race?",
            hisPanicOption: "Hispanic/Latino",
            nonhisPanicOption: "Non-Hispanic/Latino",
            declineToAnswerLabel: "Decline to answer",
            raceOptions: [
              "America Indian/ Alaskan Native",
              "Asian",
              "Black / African American",
              "Native Hawaiian/ Other Pacific Islander",
              "White",
              "Decline to answer",
              "Other",
            ],
            otherRaceErrmsg: "Please enter other race information",
            otherRaceErrMsgLength: "Please enter other race information greater than 3 characters or less than 250 characters",
            helpTextSSN1: "The collection of a Social Security Number (SSN) for each household member is authorized under the Food & Nutrition Act of 2008 as amended by P.L.96-58 (7 U.S. C.2025F). The information will be used to determine whether your household is eligible or continues to be eligible to participate in the Food Assistance Program. ",
            helpTextSSN2: "Your SSN will be used to monitor compliance with program regulations and for program management. Your SSN will be used in computer matching and program reviews or audits to make sure your household is eligible for food assistance. ",
            helpTextSSN3: "Providing a SSN for each household member is voluntary. However, failure to provide a SSN for each household member will result in disqualification of that member. You will still have to give information such as income for this member. ",
            helpTextSSN4: "Any SSNs provided will be used and disclosed in the same manner as SSNs of eligible household members.",
            helpTextSSN5: "The use or disclosure of information will be made only for certain limited purposes allowed under State and Federal laws and regulations. Information may also be disclosed to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law.",
            helpTextSSN6: "If a food assistance claim arises against your household, the information on this six-month report, including all social security numbers, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.",
            receivedLieapOptions: ["Yes", "No"],
            receivedLieapLabel: "Have you received a Low Income Home Energy Assistance Program payment or do you expect to get LIHEAP?",
          };
        default:
          return;
      }
    }

    case languageConstants.householdDynamic: {
      switch (language) {
        case EN:
          return {
            title: "Member " + name,
            header: "About Member " + name,
            conditionalLabel: "Who does " + name + " pay dependent care for?",
          };
        case ES:
          return {
            title: "miembro " + name,
            header: "Acerca de los miembros " + name,
            conditionalLabel:
              "¿Por quién paga " + name + " el cuidado de dependientes ?",
          };
        case PT:
          return {
            title: "miembro " + name,
            header: "Sobre o Membro " + name,
            conditionalLabel:
              "Para quem " + name + " paga pelos cuidados de dependentes ?",
          };
        case ZH:
          return {
            title: "會員 " + name,
            header: "關於會員 " + name,
            conditionalLabel: name + " 为谁支付亲属护理费用？",
          };
        case VI:
          return {
            title: "thành viên " + name,
            header: "Giới thiệu Thành viên " + name,
            conditionalLabel:
              name + " trả tiền chi phí chăm sóc người phụ thuộc cho ai?",
          };
        case HI:
          return {
            title: "Manm " + name,
            header: "Konsènan Manm " + name,
            conditionalLabel: "Who does " + name + " peye swen depandan?",
          };
        default:
          return;
      }
    }

    case languageConstants.earnedIncomeStatic: {
      switch (language) {
        case EN:
          return {
            emptyLabel: "'s ",
            shelterExpensesLabel: "shelter expenses",
            utilityExpensesLabel: "utility expenses",
            amountLabel: "amount",
            wages_AmountLabel:
              "What is the gross amount of John Doe's (8.Aug.1967) wages?",
            wages_EmployerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these wages?",
            wages_UnitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) wages?",
            wages_Label: "Wages ",
            selfGross_Label: "Self employment ",
            workStudy_Label: "Work study ",
            rental_Label: "Rental income ",
            childSupportSideBar: "Child Support Expenses",
            childSupportSummaryHeader: "Child Support Expenses Summary",
            otherInfoSideBar: "Other information",
            childSupport_Question: "What is the child support expense for ",
            selectHouseHold_Question:
              "Please select the name of the household member, who is paying this cost?",
            childSupport_AddHouseHold:
              "Add another household member's child support",
            childSupportExpenses_Question:
              "Has any household member had a change in his/her legal obligation to pay child support? (Required)",
            doneButtonlabel: "Done",
            deleteButtonlabel: "Remove",
            titlePer: "Per",
            editButtonLabel: "Edit",
            childSupportPayLabel: "pays",
            childSupportPerLabel: "Per",
            childSupportLabel: "in child support",
            otherInfo_Question:
              "Has there been any change in your contact information? (Required)",

            incomeTitle: "Earned Income Summary",
            earnedIncomeMenuTitle: "Earned Income",
            earnedIncomeTitle: "Earned Income Summary",
            tanfearnedIncomeTitle: "Employment Summary",
            unEarnedIncomeMenuTitle: "Unearned Income",
            unEarnedIncomeTitle: "Unearned Income Summary",
            incomeHeader: "About your household's income",
            aboutIncomeBefore: "Does anyone in the household receive any ",
            aboutIncomeTrigger: "income or benefits",
            incomeHelp:
              "Income is money you receive from working. Benefits are money you receive from a source other than employment.",
            clearOption: "No one",
            // combineearnedincomeoptions : [
            //   "Job Income",
            //   "Self-Employement",
            //   "Board"
            // ],
            // combineIncomeoptions: [
            //   "Wages",
            //   "Self-Employment",
            //   "Work Study",
            //   "Rental Income",
            //   "SSI (Supplemental Security Income)",
            //   "RSDI (Retirement, Survivors, and Disability Insurance)",
            //   "Unemployment",
            //   "Child Support",
            //   "Pension",
            //   "Veterans Benefits",
            //   "Worker's Compensation",
            //   "Other",
            //   "Paid Family and Medical Leave",
            // ],
            combineIncomeoptions: [
              "Job Income",
              "Self-Employment",
              "Room and Board Income",
              "Wages",
              "Work Study",
              "Rental Income",
              "RSDI (Retirement, Survivors, and Disability Insurance)",
              "Unemployment",
              "Pension",
              "Worker's Compensation",
              "Paid Family and Medical Leave",
            ],
            unearnedIncomeoptions: [
              "TANF",
              "Social Security (SSA /RSDI)",
              "Supplemental Security Income",
              "Veteran's Benefits",
              "Unemployment Compensation",
              "Workman's Compensation",
              "Interest Dividends from Stock and Bonds",
              "Military Allotment",
              "Pension or Retirement Income",
              "Railroad Retirement",
              "HUD Utility Assistance",
              "Income from Land Rental or Rental Property",
              "Child Support",
              "Alimony",
              "Contributions from Friends and Relatives",
              "Other"
            ],
            wageIncomeBeforeLabel: "What is the ",
            wageIncomeTriggerLabel: "gross income",
            wagesTargetValue: "Wages",
            selfemeploymentTargetValue: "Self-Employment",
            workStudyTargetValue: "Work Study",
            rsdiTargetValue:
              "RSDI (Retirement, Survivors, and Disability Insurance)",
            SSITargetValue: "SSI (Supplemental Security Income)",
            unEmploymentTargetValue: "Unemployment",
            childSuppTargetValue: "Child Support",
            pensionTargetValue: "Pension",
            veteranBenefitsTargetValue: "Veterans Benefits",
            rentalIncomeTargetValue: "Income from Land Rental or Rental Property",
            workerCompTargetValue: "Workman's Compensation",
            otherTargetValue: "Other",
            noneTargetValue: "None",

            mortageInlineHelpText:
              "If home insurance, taxes, and/or condo fee are included in your mortgage, do not list the amount separately in the following questions.",
            grossIncomeHelp:
              "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
            unitsPerLabel: " ",
            unitsOptions: [
              "Annual",
              "Bimonthly (every two months)",
              "Biweekly (every two weeks)",
              "Monthly",
              "Quarterly",
              "Semiannual (twice a year)",
              "Semimonthly (twice a month)",
              "Weekly",
            ],
            labelOtherwage: "+ Add another wage",
            adsSelfEmployee: "+ Add another Self-Employment",
            condWorkStudy: "Work Study",
            workStudyLabel: "+ Add another Work Study",
            anotherSSILabel: "+ Add another SSI",
            anotherLabel: "+ Add another",
            addRDILabel: "+ Add another RSDI",
            unEmpLabel: "+ Add another Unemployment",
            childSuppLabel: "Child Support",
            anotherChildsupport: "+ Add another Child Support",
            pensionLabel: "Pension",
            anotherPensionLabel: "+ Add another Pension",
            workerCompensationLabel: "+ Add another Worker's Compensation",
            veteranBenefit: "Veteran's Benefits",
            addAnotherVBenefitLabel: "+ Add another Veteran's Benefits",
            rentIncomeLabel: "Rental Income",
            repeatQuestionLabel: "+ Add another Rental Income",
            repeatincomeQuestionLabel: "+ Add another Rental Hours",
            unitsRentalOptions: ["Month", "Week"],
            questionButtonLabel: "+ Add another Other",
            incomeSummaryTitle: "Income summary",
            incomeSummaryHeader: "Income summary",
            expenseTitle: "Expenses",
            almostLabel: "Almost there!",
            houseUtlilityLabel: "Housing and Utility Costs",
            expenseHeader: "Tell us about your housing costs",
            expenseSubHeader:
              "Please include costs you are responsible for paying even if you are behind or not able to pay them.",
            houseHoldQLabel: "Is your household responsible for any utilities?",
            utilityQOptions: [
              "Heat (oil, gas, electricity or propane, etc.)",
              "Electricity for an air conditioner",
              "A fee to use an air conditioner",
              "Electricity and/or gas",
              "Phone or cell phone service",
            ],
            utilitiesClearOption: "None",
            houseQLabel: "What type of housing cost does your household have?",
            houseQOptions: [
              "Rent",
              "Mortgage",
              "Property Taxes",
              "Home Insurance",
              "Condo Fee",
            ],
            houseRentQLabel: "How much is your household's rent?",
            rentTargetValue: "RENT",
            mortageTargetValue: "MORTGAGE",
            propertyTaxTargetValue: "PROPERTY_TAX",
            homeInsurTargetValue: "HOME_INSURANCE",
            condoFeeTargetValue: "CONDO_FEE",

            unitBetween: "per",
            houseUnitOptions: [
              "Annual",
              "Bimonthly (every two months)",
              "Biweekly (every two weeks)",
              "Monthly",
              "Quarterly",
              "Semiannual (twice a year)",
              "Semimonthly (twice a month)",
              "Weekly",
            ],
            mortageQLabel: "How much is your household's mortgage?",
            mortageunitBetween: " ",
            mortageQoptions: [
              "Annual",
              "Bimonthly (every two months)",
              "Biweekly (every two weeks)",
              "Monthly",
              "Quarterly",
              "Semiannual (twice a year)",
              "Semimonthly (twice a month)",
              "Weekly",
            ],
            mortageProptaxLabel: "How much is your household's property taxes?",
            mortagepropBetween: " ",
            mortagepropoptions: [
              "Annual",
              "Bimonthly (every two months)",
              "Biweekly (every two weeks)",
              "Monthly",
              "Quarterly",
              "Semiannual (twice a year)",
              "Semimonthly (twice a month)",
              "Weekly",
            ],
            insuranceCostQLabel:
              "How much is your household's home insurance costs?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: [
              "Annual",
              "Bimonthly (every two months)",
              "Biweekly (every two weeks)",
              "Monthly",
              "Quarterly",
              "Semiannual (twice a year)",
              "Semimonthly (twice a month)",
              "Weekly",
            ],
            insuranceClearOption: "No. I don't need to pay for this.",
            condofeeQLabel: "How much is your household's condo fee?",
            condofeepropBetween: "per",
            condofeeCostoptions: [
              "Annual",
              "Bimonthly (every two months)",
              "Biweekly (every two weeks)",
              "Monthly",
              "Quarterly",
              "Semiannual (twice a year)",
              "Semimonthly (twice a month)",
              "Weekly",
            ],
            condofeeClearOption: "No. I don't need to pay for this.",
            propTaxQLabel: "Does your household have property taxes?",
            propTaxBetween: "per",
            propTaxOptions: ["Week", "Month", "Year"],
            propTaxClearOption: "No. I don't need to pay for this.",
            homeInsuranceQLabel:
              "Does your household have home insurance costs?",
            homeInsuranceBetween: "per",
            homeInsuranceUnits: ["Week", "Month", "Year"],
            homeInsuranceClearOption: "No. I don't need to pay for this.",
            condoFeeQLabel: "Does your household have a condo fee?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Week", "Month", "Year"],
            condoFeeClearOption: "No. I don't need to pay for this.",

            //new labels:
            anyUtilQcost: "Does your household have any utility costs?",
            chooseHoldErrmsg: "Please choose household utilities",
            houseCostErrmsg: "Please choose housing costs",
            rentErrMsg: "Please enter rent",
            anotherRentLabel: "+ Add another rent",
            mortageErrLabel: "Please enter mortgage",
            propTaxLabel: "Please enter property taxes",
            propTaxButtonLabel: "+ Add another property taxes",
            homeInsurbutton: "+ Add another home insurance",
            homeInsurErrmsg: "Please enter home insurance",
            condoButtonLabel: "+ Add another condo fee",
            condoFeeErrmsg: "Please enter condo fee",
            addMortageButtonLabel: "+ Add another mortgage",
            childTitle: "Child or Adult Dependent Care Costs",
            childCostHeader:
              "Tell us about your Child or Adult Dependent Care costs",
            childCostSubHeader:
              "Please include costs you are responsible for paying even if you are behind or not able to pay them.",
            careGiveObjbefore:
              "Does anyone in the household have Child or Adult Dependent Care costs? This includes costs to ",
            careGiveObjtrigger: "transport",
            careGiveObjAfter:
              " the dependent(s) to and/or from a care provider.",
            careGiveHelp:
              "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
            questionClearOption: "No one",
            kidsandadultSubheader:
              "Please include costs you are responsible for paying even if you are behind or not able to pay them.",
            costBetween: " ",
            weekMonthOptions: ["Month", "Week"],
            costOptions: ["Week", "Month", "Year"],
            yesnoOptions: ["Yes", "No"],
            targetValueYes: "Yes",
            targetValueNo: "No",
            noofAppointment: "Please choose no.of appointments",
            addressProvider: "What is the address of the provider?",
            freqTripoptions: [
              "Annual",
              "Bimonthly (every two months)",
              "Biweekly (every two weeks)",
              "Monthly",
              "Quarterly",
              "Semiannual (twice a year)",
              "Semimonthly (twice a month)",
              "Weekly",
            ],
            unitMonthTargetValue: { unit: "Month" },

            childSupTitle: "Child Support Costs",
            supportCostHeader: "Child Support Costs",
            childSupportQlegalbefore:
              "Does anyone in the household have a legal obligation (court order) to pay ",
            childSupportQlegaltrigger: "child support",
            childSupportQlegalAfter: " to a child not living with you?",
            childSupportHelp:
              "Child support paid can be counted as an expense when it is legally required and payments are being made.  This includes payments for arrears.",
            medicalTitle: "Medical Costs",
            medicalInlineSeethisFull: "See this full list of",
            medicalInlineEligibleMedical: " eligible medical expenses",
            medicalHeader: "Tell us about your medical costs",
            medicalSubHeader:
              "Please include costs you are responsible for paying even if you are behind or not able to pay them.",
            medicalExpBefore:
              "Does anyone listed below pay for health insurance or other medical costs including prescriptions,  ",
            medicalExpTrigger: " transportation",
            medicalExpAfter:
              ", over the counter medications, dental or eye care, adult diapers, etc.?",
            medicalExphelp:
              "Includes payment for public or private transportation. This includes buses, cabs, parking costs, payment to a friend or family member for transportation, etc.",
            medicalInhelp: "See this full list of",
            medicalInhelpEligible: "eligible medical expenses",
            medicalAddress:
              "What is the address of the medical appointment or pharmacy?",
            zipErrmsg: "Please enter a valid Zipcode.",
            signSubmitLabel: "Sign & Submit",
            signSubmitHeader: "Sign & submit",
            signSubmitSub:
              "You made it through the form! Now for the easy stuff.",
            subSectionLabel: "Submitted",
            submitLabelFinal: "Your SNAP Six-Month Report has been submitted successfully!",
            tellusAbout: "Tell us about your Child support costs",
            moneyYougettitle:
              "This includes money you may get from a job, the government, or other sources.",
            jobIncomeTargetValue: "Job Income", 
            roomAndBoardIncomeTargetValue: "Room and Board Income",
            jobIncomeAmountLabel:
            "What is the gross amount of John Doe's (8.Aug.1967) job income?",
            jobIncomeEmployerLabel:
            "Who is John Doe (8.Aug.1967)'s employer for these job income?",
            jobIncomeUnitLabel:
            "What is the frequency of John Doe's (8.Aug.1967) job income?",
            jobIncomeLabel: "Job Income ",
            roomAndBoardIncomeLabel: "Room and Board Income",
            labelOtherJobIncome: "+ Add another Job Income",
            addAnotherRoomAndBoardIncome: "+ Add another Room and Board Income",
            boardIncomeAmountLabel:
            "What is the gross amount of John Doe's (8.Aug.1967) room and Board Income?",
            boardIncomeEmployerLabel:
            "Who is John Doe (8.Aug.1967)'s employer for these room and Board Income?",
            boardIncomeUnitLabel:
            "What is the frequency of John Doe's (8.Aug.1967) room and Board Income?",
              //unearnedIncome
              anotherTANFLabel: "+ Add another TANF",
              TANFTargetValue: "TANF",
              workmanCompensationLabel: "+ Add another Workman's Compensation",
              unEmpCompensationLabel: "+ Add another Unemployment Compensation",
              interestDividendTargetValue: "Interest Dividends from Stock and Bonds",
              interestDividendCompensationLabel: "+ Add another Interest Dividends from Stock and Bonds",
              workmanTargetValue: "Workman's Compensation",
              militaryTargetValue: "Military Allotment",
              militaryCompensationLabel: "+ Add another Military Allotment",
              pensionTargetValue:"Pension or RetirementIncome",
              pensionCompensationLabel:"+ Add another Pension or Retirement Income",
              railroadTargetValue:"Railroad Retirement",
              railroadCompensationLabel:"+ Add another Railroad Retirement",
              hudTargetValue:"HUD Utility Assistance",
              hudCompensationLabel:"+ Add another HUD Utility Assistance",
              landTargetValue:"Income from Land Rental or Rental Property",
              landCompensationLabel:"+ Add another Income from Land Rental or Rental Property",
              childSupportTargetValue:"Child Support",
              childSupportCompensationLabel:"+ Add another Income from Child Support",
              alimonyTargetValue:"Alimony",
              alimonyCompensationLabel:"+ Add another Income from Alimony",
              contributionTargetValue:"Contributions from Friends and Relatives",
              contributionCompensationLabel:"+ Add another Income from Contributions from Friends and Relatives",
              courtOrderedChildSupport: "Is any other household member now paying court-ordered child support?",
              perLabel: "per",
              summaryLabel: "Summary",
            jobIncomeTargetValue: "Job Income",
            jobIncomeAmountLabel: "What is the gross amount of John Doe's (8.Aug.1967) job income?",
            jobIncomeUnitLabel:
              "What is the frequency of John Doe's (8.Aug.1967) job income?",
            jobIncomeLabel: "Job Income ",
            roomAndBoardIncomeLabel: "Room and Board Income ",
            labelOtherJobIncome: "+ Add another Job Income",
            jobIncomeEmployerLabel:
              "Who is John Doe (8.Aug.1967)'s employer for these job income?",
            //unearnedIncome
            anotherTANFLabel: "+ Add another TANF",
            TANFTargetValue: "TANF",
            workmanCompensationLabel: "+ Add another Workman's Compensation",
            interestDividendTargetValue: "Interest Dividends from Stock and Bonds",
            interestDividendCompensationLabel: "+ Add another Interest Dividends from Stock and Bonds",
            workmanTargetValue: "Workman's Compensation",
            militaryTargetValue: "Military Allotment",
            militaryCompensationLabel: "+ Add another Military Allotment",
            pensionTargetValue: "Pension or RetirementIncome",
            pensionCompensationLabel: "+ Add another Pension or Retirement Income",
            railroadTargetValue: "Railroad Retirement",
            railroadCompensationLabel: "+ Add another Railroad Retirement",
            hudTargetValue: "HUD Utility Assistance",
            hudCompensationLabel: "+ Add another HUD Utility Assistance",
            landTargetValue: "Income from Land Rental or Rental Property",
            landCompensationLabel: "+ Add another Income from Land Rental or Rental Property",
            childSupportTargetValue: "Child Support",
            childSupportCompensationLabel: "+ Add another Income from Child Support",
            alimonyTargetValue: "Alimony",
            alimonyCompensationLabel: "+ Add another Income from Alimony",
            contributionTargetValue: "Contributions from Friends and Relatives",
            contributionCompensationLabel: "+ Add another Income from Contributions from Friends and Relatives",
            courtOrderedChildSupport: "Is any other household member now paying court-ordered child support?",
            perLabel: "per",
            provideProof: 'If you answered "Yes" to either question, please provide proof. If you do not provide proof, the deduction will not be allowed.',

            
          };
        case ES: //SPANISH
          return {
            summaryLabel: "Resumen",
            TANFTargetValue: "TANF",
            interestDividendTargetValue: "Dividendos de intereses de acciones y bonos",
            militaryTargetValue: "Asignación militar",
            railroadTargetValue:"Jubilación ferroviaria",
            hudTargetValue:"Asistencia de servicios públicos de HUD",
            Shelterandutilityexplabel: "Shelter & Utility Expenses",
            shelterExpensesLabel: "gastos de alojamiento",
            utilityExpensesLabel: "gastos de servicios públicos",
            childSupportCompensationLabel: "+ Agregue otro ingreso de manutención infantil",
            emptyLabel: " ",
            amountLabel: "monto",
            roomAndBoardIncomeLabel: "Ingresos por alojamiento y comida",
            addAnotherRoomAndBoardIncome: "+ Añadir otro ingreso por alojamiento y comida",
            labelOtherJobIncome: "+ Agregar otro ingreso laboral",
            anotherTANFLabel: "+ Añadir otro TANF",
            wages_AmountLabel:
              "¿Cuál es el monto bruto del John Doe's (8.Aug.1967) de wages?",
            wages_EmployerLabel:
              "¿Quién es el empleador de John Doe's (8.Aug.1967) para este de wages?",
            wages_UnitLabel:
              "¿Cuál es la frecuencia del John Doe's (8.Aug.1967) de wages?",
            wages_Label: "Wages from MarketBasket",
            childSupportSideBar: "Gastos de manutención infantil",
            childSupportSummaryHeader:
              "Resumen de gastos de manutención infantil",
            childSupport_Question:
              "¿Cuál es el costo de la manutención infantil para ",
            selectHouseHold_Question:
              "Seleccione el nombre del miembro del grupo familiar que está pagando este costo?",
            childSupport_AddHouseHold:
              "+ Agregar manutención infantil de otros miembros del grupo familiar",
            childSupportExpenses_Question:
              "¿Ocurrió algún cambio en su información sobre gastos de manutención infantil? (Obligatorio)",
            doneButtonlabel: "Listo",
            otherInfoSideBar: "Otra información",
            deleteButtonlabel: "Eliminar",
            titlePer: "cad",
            childSupportPayLabel: "paga",
            childSupportPerLabel: "cada",
            childSupportLabel: "en manutención infantil",
            editButtonLabel: "Editar",
            otherInfo_Question:
              "¿Se han producido cambios en su información de contacto? (Obligatorio)",
            jobIncomeLabel: "Ingresos laborales ",
            selfGross_Label: "Auto-empleo ",
            roomAndBoardIncomeLabel: "Ingresos por alojamiento y comida ",
            incomeTitle: "Resumen de ingresos del trabajo",
            earnedIncomeMenuTitle: "Ingresos del trabajo",
            earnedIncomeTitle: "Resumen de ingresos del trabajo",
            unEarnedIncomeMenuTitle: "Ingresos no derivados del trabajo",
            unEarnedIncomeTitle: "Resumen de ingresos no devengados",
            incomeHeader: "Sobre el ingreso de su núcleo familiar",
            aboutIncomeBefore: "¿Alguna persona en su núcleo familiar recibe ",
            aboutIncomeTrigger: "ingresos o beneficios?",
            incomeHelp:
              "El ingreso es dinero que usted recibe por trabajar. Los beneficios son dinero que usted recibe de una fuente distinta a un empleo.",
            clearOption: "Ninguno",
            roomAndBoardIncomeTargetValue: "Ingresos por alojamiento y comida",
            combineIncomeoptions: [
              "Salarios",
              "Trabajo independiente",
              "Ingresos por alojamiento y comida",
              "Trabajo estudio",
              "Ingreso por alquiler",
              "SSI (Ingreso de seguridad suplementario)",
              "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
              "Desempleo",
              "Manutención",
              "Pensión",
              "Beneficios de veteranos",
              "Compensación del trabajador",
              "Otro",
            ],
            unearnedIncomeoptions: [
              "TANF",
              "Seguridad Social (SSA/RSDI)",
              "Seguridad de Ingreso Suplementario",
              "Beneficios para veteranos",
              "Compensacion por desempleo",
              "Compensación laboral",
              "Dividendos de intereses de acciones y bonos",
              "Asignación militar",
              "Ingresos de Pensión o Jubilación",
              "Jubilación ferroviaria",
              "Asistencia de servicios públicos de HUD",
              "Ingresos por arrendamiento de terrenos o propiedades en alquiler",
              "Manutención de los hijos",
              "Pensión alimenticia",
              "Aportaciones de amigos y familiares",
              "Otro"
            ],
            wageIncomeBeforeLabel: "Cuál es el ",
            wageIncomeTriggerLabel: "ingresos brutos",
            wagesTargetValue: "Salarios",
            selfemeploymentTargetValue: "Trabajo independiente",
            jobIncomeTargetValue: "Ingresos laborales",
            workStudyTargetValue: "Trabajo estudio",
            rsdiTargetValue:
              "RSDI (Seguro de jubilación, sobrevivientes y discapacidad)",
            SSITargetValue: "SSI (Ingreso de seguridad suplementario)",
            unEmploymentTargetValue: "Desempleo",
            childSuppTargetValue: "Manutención",
            pensionTargetValue: "Pensión",
            veteranBenefitsTargetValue: "Beneficios de veteranos",
            rentalIncomeTargetValue: "Ingreso por alquiler",
            workerCompTargetValue: "Compensación a los trabajadores",
            otherTargetValue: "Otro",
            noneTargetValue: "Ninguno",
            mortageInlineHelpText:
              "Si el seguro de la casa, impuestos y/o tasa de condominio están incluidos en su hipoteca, no los enumere por separado en las siguientes preguntas.",
            grossIncomeHelp:
              "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
            unitsPerLabel: " ",
            unitsOptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            labelOtherwage: "+ Agrega otro Salarios",
            adsSelfEmployee: "+ Agrega otro Trabajo independiente",
            addAnotherRoomAndBoardIncome: "+ Añadir otro ingreso por alojamiento y comida",
            condWorkStudy: "Trabajo estudio",
            workStudyLabel: "+ Agrega otro Trabajo estudio",
            anotherSSILabel: "+ Agrega otro SSI",
            anotherLabel: "+ Agregar otro",
            addRDILabel: "+ Agregue otro RSDI",
            unEmpLabel: "+ Agrega otro Desempleo",
            childSuppLabel: "Manutención",
            anotherChildsupport: "+ Agrega otro Manutención",
            pensionLabel: "Pensión",
            anotherPensionLabel: "+ Agrega otro Pensión",
            workerCompensationLabel:
              "+ Agregue otra Compensación para Trabajadores",
            veteranBenefit: "Beneficios de veteranos",
            addAnotherVBenefitLabel: "+ Agrega otro Beneficios de veteranos",
            unEmpCompensationLabel: "+ Agregar otra compensación por desempleo",
            interestDividendCompensationLabel: "+ Agregue otro dividendo de intereses de acciones y bonos",
            militaryCompensationLabel: "+ Agregar otra asignación militar",
            pensionCompensationLabel:"+ Agregar otra Pensión o Ingreso de Jubilación",
            railroadCompensationLabel:"+ Agregar otro retiro ferroviario",
            hudCompensationLabel:"+ Agregar otra asistencia de servicios públicos de HUD",
            landCompensationLabel:"+ Agregue otro ingreso por alquiler de terrenos o propiedad de alquiler",
            alimonyCompensationLabel:"+ Agregar otro ingreso por pensión alimenticia",
            contributionCompensationLabel:"+ Agregue otro ingreso por contribuciones de amigos y familiares",
            rentIncomeLabel: "Ingreso por alquiler",
            repeatQuestionLabel: "+ Agrega otro Ingreso por alquiler",
            repeatincomeQuestionLabel: "+ Agrega otro Ingreso por alquiler",
            unitsRentalOptions: ["Mes", "Semana"],
            questionButtonLabel: "+ Agrega otro Otro",
            incomeSummaryTitle: "Resumen de ingresos",
            incomeSummaryHeader: "Resumen de ingresos",
            expenseTitle: "Gastos",
            almostLabel: "¡Casi termina!",
            houseUtlilityLabel: " Costos de vivienda y servicios públicos",
            expenseHeader: "Díganos sobre los costos de su vivienda",
            expenseSubHeader:
              "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
            houseHoldQLabel: "Is your household responsible for any utilities?",
            utilityQOptions: [
              "Calefacción (nafta, gas, electricidad o propano, etc.)",
              "Electricidad para un aire acondicionado",
              "Cargo por usar un aire acondicionado ",
              "Electricidad y/o gas",
              "Servicio de teléfono o celular",
            ],
            utilitiesClearOption: "Ninguno",
            houseQLabel:
              "¿Qué tipo de costo de vivienda tiene su núcleo familiar?",
            houseQOptions: [
              "Alquiler",
              "Hipoteca",
              "Seguro de propiedad",
              "Impuestos a la propiedad",
              "Seguro de la casa",
              "Tasa de condominio",
            ],
            houseRentQLabel: "¿Cuánto es el alquiler de su grupo familiar?",
            rentTargetValue: "RENT",
            mortageTargetValue: "MORTGAGE",
            propertyTaxTargetValue: "PROPERTY_TAX",
            homeInsurTargetValue: "HOME_INSURANCE",
            condoFeeTargetValue: "CONDO_FEE",
            unitBetween: "por",
            houseUnitOptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            mortageQLabel: "¿Cuál es el hipoteca de su núcleo familiar?",
            mortageunitBetween: " ",
            mortageQoptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            mortageProptaxLabel:
              "¿Cuál es el impuestos a la propiedad de su núcleo familiar?",
            mortagepropBetween: " ",
            mortagepropoptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            insuranceCostQLabel:
              "¿Cuál es el seguro de la casa de su núcleo familiar?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            insuranceClearOption: "No. No necesito pagar por esto.",
            condofeeQLabel:
              "¿Cuál es el tasa de condominio de su núcleo familiar?",
            condofeepropBetween: "por",
            condofeeCostoptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            condoFeeClearOption: "No. No necesito pagar por esto.",

            //new labels:
            anyUtilQcost:
              "¿Su núcleo familiar tiene costos de servicios públicos? ",
            chooseHoldErrmsg: "Por favor elija servicios domésticos",
            houseCostErrmsg: "Por favor elija los costos de la vivienda",
            rentErrMsg: "Por favor ingrese alquiler",
            anotherRentLabel: "+ Agregar otra alquiler",
            mortageErrLabel: "Por favor ingrese la hipoteca",
            propTaxLabel: "Ingrese los impuestos a la propiedad",
            propTaxButtonLabel: "+ Agregue otros impuestos a la propiedad",
            homeInsurbutton: "+ Agregue otro seguro de hogar",
            homeInsurErrmsg: "Por favor ingrese seguro de hogar",
            condoButtonLabel: "+ Agregar otra Tasa de condominio",
            condoFeeErrmsg: "Por favor ingrese el costo",
            addMortageButtonLabel: "+ Agregar otra hipoteca",
            childTitle: "Costos de cuidado de hijos o adultos dependientes",
            childCostHeader:
              "Díganos sobre los costos de cuidado de hijos o adultos dependientes",
            childCostSubHeader:
              "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
            careGiveObjbefore:
              "¿Alguna persona en su núcleo familiar tiene costos de cuidado de hijos o adultos dependientes? Esto incluye costos de ",
            careGiveObjtrigger: "transporte ",
            careGiveObjAfter: "del dependiente hacia y desde un cuidador.",
            careGiveHelp:
              "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
            questionClearOption: "Ninguno",
            kidsandadultSubheader:
              "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
            costBetween: " ",
            weekMonthOptions: ["Semana", "Mes"],
            costOptions: ["Semana", "Mes", "Año"],
            yesnoOptions: ["Sí", "No"],
            targetValueYes: "Sí",
            targetValueNo: "No",
            noofAppointment: "Por favor, elija no.of citas",
            addressProvider: "¿Cuál es la dirección del proveedor?",
            freqTripoptions: [
              "Anualmente",
              "Bimestralmente (cada dos meses)",
              "Quincenalmente (cada dos semanas)",
              "Mensualmente",
              "Trimestralmente",
              "Bianual (dos veces al año)",
              "semi-mensal (dos veces al mes)",
              "Semanalmente",
            ],
            unitMonthTargetValue: { unit: "Mes" },
            childSupTitle: "costo de la manutención infanti",
            supportCostHeader: "costo de la manutención infanti",
            childSupportQlegalbefore:
              "¿Alguna persona en su hogar tiene la obligación legal (orden de un tribunal) de pagar ",
            childSupportQlegaltrigger: "manutención a un niño",
            childSupportQlegalAfter: " que no viva con usted?",
            childSupportHelp:
              "La manutención infantil pagada puede contabilizarse como un gasto cuando lo exija la ley y cuando se estén realizando los pagos. Esto incluye pagos en mora.",
            medicalTitle: "Costos médicos",
            medicalInlineSeethisFull: "Consulte la lista completa de",
            medicalInlineEligibleMedical: " gastos médicos elegibles.",
            medicalHeader: "Díganos sobre sus costos médicos",
            medicalSubHeader:
              "Incluya todos los costos de los que es responsable de pagar, incluso si está atrasado o no puede pagarlos.",
            medicalExpBefore:
              "¿Alguna de las personas enumeradas a continuación paga seguro médico u otros costos médicos, incluso medicamentos con receta,",
            medicalExpTrigger: "transporte",
            medicalExpAfter:
              ", medicamentos de venta libre, atención dental o de la vista, pañales para adultos, etc.?",
            medicalExphelp:
              "Incluye el pago de transporte público o privado. Esto incluye estacionamiento, autobuses, taxis, costos de estacionamiento, pagar a un amigo o familiar por transporte, etc.",
            medicalInhelp: "Consulte esta lista completa de",
            medicalInhelpEligible: " gastos médicos elegibles",
            medicalAddress:
              "¿Cuál es la dirección de la cita médica o farmacia?",
            zipErrmsg: "Por favor ingrese un código postal válido.",
            signSubmitLabel: "Firmar y presentar",
            signSubmitHeader: "Firmar y presentar",
            signSubmitSub:
              "¡Completo todo el formulario! Ahora, pasemos a lo fácil.",
            subSectionLabel: "Presentada",
            submitLabelFinal: "Se presentó su Informe provisorio!",
            tellusAbout: "Díganos sobre sus costos de manutención",
            moneyYougettitle:
              "Esto incluye dinero que pueda obtener de un trabajo, el gobierno u otras fuentes.",
            courtOrderedChildSupport: "¿Algún otro miembro del hogar está pagando manutención infantil ordenada por el tribunal?",
            provideProof: 'Si respondió "Sí" a cualquiera de las preguntas, proporcione pruebas. Si no aporta prueba, no se permitirá la deducción.',
            TANFTargetValue:"TANF",
            rsdiTargetValue: "Seguridad Social (SSA/RSDI)",
            SSITargetValue:"SSI (Seguridad de Ingreso Suplementario)",
            veteranBenefitsTargetValue:"Beneficios para veteranos",
            unEmploymentTargetValue:"Compensacion por desempleo",
            workerCompTargetValue:"Compensación laboral",
            interestDividendTargetValue:"Dividendos de intereses de acciones y bonos",
            militaryTargetValue: "Asignación militar",
            pensionTargetValue:"Ingresos de Pensión o Jubilación",
            railroadTargetValue:"Jubilación ferroviaria",
            hudTargetValue:"Asistencia de servicios públicos de HUD",
            landTargetValue:"Ingresos por arrendamiento de terrenos o propiedades en alquiler",
            childSupportTargetValue:"Manutención de los hijos",
            alimonyTargetValue:"Pensión alimenticia",
            contributionTargetValue:"Aportaciones de amigos y familiares",
            otherTargetValue:"Otro"
          };
        case PT: //PORTUGES
          return {
            emptyLabel: " ",
            amountLabel: "quantia",
            wages_AmountLabel:
              "Qual é o valor bruto de John Doe's (8.Aug.1967) de wages?",
            wages_EmployerLabel:
              "Quem é o empregador de John Doe's (8.Aug.1967) para estes de wages?",
            wages_UnitLabel:
              "Qual é a frequência da John Doe's de (8.Aug.1967) wages?",
            wages_Label: "Wages from MarketBasket",
            childSupportLabel: "pensão alimentícia",
            childSupport_Question:
              "Qual é o custo de pensão alimentícia da criança para ",
            selectHouseHold_Question:
              "Selecione o nome do membro do domicílio, quem está pagando esse custo?",
            childSupport_AddHouseHold:
              "+ Adicionar pensão alimentícia para outros membros da família",
            childSupportExpenses_Question:
              "Há alguma mudança nas informações sobre despesas com pensão alimentívia? (Obrigatório)",
            doneButtonlabel: "Concluir",
            deleteButtonlabel: "Excluir",
            titlePer: "por",
            childSupportPayLabel: "paga",
            otherInfoSideBar: "Outras informações",
            editButtonLabel: "Editar",
            otherInfo_Question:
              "Houve alguma alteração nos seus dados de contato? (Obrigatório)",

            incomeTitle: "Resumo de rendimentos",
            childSupportPerLabel: "por",
            earnedIncomeMenuTitle: "Rendimento proveniente de trabalho",
            earnedIncomeTitle: "Resumo de rendimentos",
            unEarnedIncomeMenuTitle: "Rendimento não proveniente de trabalho",
            unEarnedIncomeTitle: "Resumo do rendimento não realizado",
            childSupportSideBar: "Despesas com pensão alimentícia",
            childSupportSummaryHeader:
              "Resumo de despesas com pensão alimentícia",
            incomeHeader: "Sobre a renda da sua família",
            aboutIncomeBefore: "Alguém da sua família recebe algum tipo de ",
            aboutIncomeTrigger: " renda ou benefício?",
            incomeHelp:
              "Renda é o dinheiro que você recebe por trabalhar. Benefício é o dinheiro que você recebe de qualquer outra fonte.",
            clearOption: "Nenhum",
            combineIncomeoptions: [
              "Salário",
              "Trabalho autônomo",
              "Estudo",
              "Renda de aluguel",
              "SSI (Renda de segurança suplementar)",
              "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
              "Desemprego",
              "Suporte infantil",
              "Pensão",
              "Benefício de veterano",
              "compensação do trabalhador",
              "Outros",
            ],
            unearnedIncomeoptions: [
              "TANF",
              "Social Security (SSA /RSDI)",
              "SSI (Supplemental Security Income)",
              "Veterans Benefits",
              "Unemployment Compensation",
              "Workman's Compensation",
              "Interest Dividends from Stock and Bonds",
              "Military Allotment",
              "Pension or Retirement Income",
              "Railroad Retirement",
              "HUD Utility Assistance",
              "Income from Land Rental or Rental Property",
              "Child Support",
              "Alimony",
              "Contributions from Friends and Relatives",
              "Other"
            ],
            wageIncomeBeforeLabel: "Qual é o ",
            wageIncomeTriggerLabel: "rendimento bruto",
            wagesTargetValue: "Salário",
            selfemeploymentTargetValue: "Trabalho autônomo",
            workStudyTargetValue: "Estudo",
            rsdiTargetValue:
              "RSDI (Seguro de aposentadoria, sobreviventes e deficiências)",
            SSITargetValue: "SSI (Renda de segurança suplementar)",
            unEmploymentTargetValue: "Desemprego",
            childSuppTargetValue: "Suporte infantil",
            pensionTargetValue: "Pensão",
            veteranBenefitsTargetValue: "Benefício de veterano",
            rentalIncomeTargetValue: "Renda de aluguel",
            workerCompTargetValue: "Compensação do trabalhador",
            otherTargetValue: "Outros",
            noneTargetValue: "Nenhum",
            mortageInlineHelpText:
              "Se estiverem inclusos no seu financiamento valores como seguro, impostos e/ou condomínio, não liste-os separadamente nas próximas questões.",
            grossIncomeHelp:
              "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
            unitsPerLabel: " ",
            unitsOptions: [
              "Anual",
              "Bimestralmente (a cada dois meses)",
              "Quinzenal (a cada duas semanas)",
              "Mensal",
              "Trimestral",
              "Semestral (duas vezes ao ano)",
              "semi-mensal (duas vezes por mês)",
              "Semanal",
            ],
            labelOtherwage: "+ Adicionar outro Salário",
            adsSelfEmployee: "+ Adicionar outro Trabalho autônomo",
            addAnotherRoomAndBoardIncome: "+ Adicionar outra renda de hospedagem e alimentação",
            condWorkStudy: "Estudo",
            workStudyLabel: "+ Adicionar outro Estudo",
            anotherSSILabel: "+ Adicionar outro SSI",
            anotherLabel: "+ Adicionar outra",
            addRDILabel: "+ Adicione outro RSDI",
            unEmpLabel: "+ Adicionar outro Desemprego",
            childSuppLabel: "Suporte infantil",
            anotherChildsupport: "+ Adicionar outro Suporte infantil",
            pensionLabel: "Pensão",
            anotherPensionLabel: "+ Adicionar outro Pensão",
            workerCompensationLabel:
              "+ adicionar compensação de outro trabalhador",
            veteranBenefit: "Benefício de veterano",
            addAnotherVBenefitLabel: "+ Adicionar outro Benefício de veterano",
            rentIncomeLabel: "Renda de aluguel",
            repeatQuestionLabel: "+ Adicionar outro Renda de aluguel",
            repeatincomeQuestionLabel: "+ Adicionar outro Renda de aluguel",
            unitsRentalOptions: ["Mês", "Semana"],
            questionButtonLabel: "+ Adicionar outro Outros",
            incomeSummaryTitle: "Resumo da renda",
            incomeSummaryHeader: "Resumo da renda",
            expenseTitle: "Despesas",
            almostLabel: "Quase lá!",
            houseUtlilityLabel: "Custos residenciais e utilitários",
            expenseHeader: "Fale sobre os seus custos residenciais",
            expenseSubHeader:
              "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
            houseHoldQLabel: "Is your household responsible for any utilities?",
            utilityQOptions: [
              "Aquecimento (óleo, gás, eletricidade ou propano, etc.)",
              "Eletricidade para ar condicionado",
              "Uma taxa para usar um ar condicionado",
              "Eletricidade e/ou gás",
              "Serviço de telefone fixo ou celular",
            ],
            utilitiesClearOption: "Nenhum",
            houseQLabel: "Que tipo de custos residenciais a sua família tem?",
            houseQOptions: [
              "Aluguel",
              "Financiamento",
              "Impostos da propriedade",
              "Seguro residencial",
              "Condomínio",
            ],
            houseRentQLabel: "Qual é o valor do seu aluguel?",
            rentTargetValue: "RENT",
            mortageTargetValue: "MORTGAGE",
            propertyTaxTargetValue: "PROPERTY_TAX",
            homeInsurTargetValue: "HOME_INSURANCE",
            condoFeeTargetValue: "CONDO_FEE",
            unitBetween: "por",
            houseUnitOptions: [
              "Anual",
              "Bimestralmente (a cada dois meses)",
              "Quinzenal (a cada duas semanas)",
              "Mensal",
              "Trimestral",
              "Semestral (duas vezes ao ano)",
              "semi-mensal (duas vezes por mês)",
              "Semanal",
            ],
            mortageQLabel: "Qual é o valor do seu financiamento?",
            mortageunitBetween: " ",
            mortageQoptions: ["Semana", "Mês", "Ano"],
            mortageProptaxLabel:
              "Qual é o valor dos impostos da sua propriedade?",
            mortagepropBetween: " ",
            mortagepropoptions: ["Semana", "Mês", "Ano"],
            insuranceCostQLabel: "Qual é o valor do seu seguro residencial?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["Semana", "Mês", "Ano"],
            insuranceClearOption: "Não. Eu não preciso pagar por isso.",
            condofeeQLabel: "Qual é o valor do seu condomínio?",
            condofeepropBetween: "por",
            condofeeCostoptions: ["Semana", "Mês", "Ano"],
            condofeeClearOption: "Não. Eu não preciso pagar por isso.",
            propTaxQLabel: "A sua casa tem impostos sobre a propriedade??",
            propTaxBetween: "por",
            propTaxOptions: ["Semana", "Mês", "Ano"],
            propTaxClearOption: "Não. Eu não preciso pagar por isso.",
            homeInsuranceQLabel: "A sua casa possui custos de seguro de casa?",
            homeInsuranceBetween: "por",
            homeInsuranceUnits: ["Semana", "Mês", "Ano"],
            homeInsuranceClearOption: "Não. Eu não preciso pagar por isso.",
            condoFeeQLabel: "A sua casa possui uma Tasa de condominio?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Semana", "Mês", "Ano"],
            condoFeeClearOption: "Não. Eu não preciso pagar por isso.",

            //new labels:
            anyUtilQcost: "A sua família tem algum tipo de custo utilitário?",
            chooseHoldErrmsg: "Escolha utilitários domésticos",
            houseCostErrmsg: "Escolha os custos de habitação",
            rentErrMsg: "Entre com aluguel",
            anotherRentLabel: "+ Adicionar outro aluguel",
            mortageErrLabel: "Insira uma hipoteca",
            propTaxLabel: "Digite os impostos sobre a propriedade",
            propTaxButtonLabel:
              "+ Adicione outros impostos sobre a propriedade",
            homeInsurbutton: "+ Adicione outro seguro de casa",
            homeInsurErrmsg: "Digite o seguro de casa",
            condoButtonLabel: "+ Adicionar outra Tasa de condominio",
            condoFeeErrmsg: "Entre com Tasa de condominio",
            addMortageButtonLabel: "+ Adicionar outros Tasa de condominio",
            childTitle: "Custos de crianças ou dependentes",
            childCostHeader:
              "Fale sobre os seus custos com cuidados para crianças ou adultos dependentes",
            childCostSubHeader:
              "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
            careGiveObjbefore:
              "Alguém na sua família tem custos com cuidados para crianças ou adultos dependentes? Incluindo os custos de ",
            careGiveObjtrigger: "transporte do(s)",
            careGiveObjAfter: "dependente(s) até o prestador de serviços.",
            careGiveHelp:
              "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
            questionClearOption: "Nenhum",
            kidsandadultSubheader:
              "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
            costBetween: " ",
            weekMonthOptions: ["Semana", "Mês"],
            costOptions: ["Semana", "Mês", "Ano"],
            yesnoOptions: ["Sim", "Não"],
            targetValueYes: "Sim",
            targetValueNo: "Não",
            noofAppointment: "Por favor, escolha o número de compromissos",
            addressProvider: "Qual é o endereço do prestador de serviço?",
            freqTripoptions: ["Semana", "Mês", "Ano"],
            unitMonthTargetValue: { unit: "Mês" },
            childSupTitle: "costo de la manutención infanti",
            supportCostHeader: "costo de la manutención infanti",
            childSupportQlegalbefore:
              "Alguém na família tem uma obrigação legal (imposta juridicamente) de pagar pelo ",
            childSupportQlegaltrigger: " suporte de uma criança",
            childSupportQlegalAfter: " que não vive com você?",
            childSupportHelp:
              "A pensão alimentícia paga pode ser contada como uma despesa quando é legalmente exigida e os pagamentos estão sendo feitos. Isso inclui pagamentos por atrasos.",
            medicalTitle: "Custos médicos",
            medicalInlineSeethisFull: "Veja esta lista completa de",
            medicalInlineEligibleMedical: " despesas médicas elegíveis.",
            medicalHeader: "Fale sobre os seus custos médicos",
            medicalSubHeader:
              "Inclua os custos pelos quais você é responsável, mesmo se você estiver em atraso ou não for capaz de pagá-los.",
            medicalExpBefore:
              "Alguém listado abaixo paga plano de saúde ou outros custos médicos, como receitas,",
            medicalExpTrigger: "transporte",
            medicalExpAfter:
              ", medicamentos de farmácia, dentistas, oftalmologistas, fraldas geriátricas, etc.?",
            medicalExphelp:
              "Incluindo pagamento de transporte público ou privado. Incluindo estacionamento, ônibus, táxi, custos de estacionamento, pagamento a um amigo ou membro da família pelo transporte, etc.",
            medicalInhelp: "Veja esta lista completa de",
            medicalInhelpEligible: "despesas médicas elegíveis",
            medicalAddress:
              "Qual é o endereço da consulta médica ou da farmácia?",
            zipErrmsg: "Por favor insira um código postal válido",
            signSubmitLabel: "Assinar e enviar",
            signSubmitHeader: "Assinar e enviar",
            signSubmitSub:
              "Você chegou ao fim do formulário! Agora vamos à parte fácil.",
            subSectionLabel: "Submetido",
            submitLabelFinal: "Seu Relatório interino foi enviado!",
            tellusAbout: "Fale sobre os custos de suporte infantil",
            moneyYougettitle:
              "Incluindo dinheiro recebido de um trabalho, do governo ou de outra fonte de renda.",
          };
        case ZH: //China
          return {
            emptyLabel: " ",
            amountLabel: "金额",
            wages_AmountLabel: "John Doe's (8.Aug.1967) wages 总额是多少？?",
            wages_EmployerLabel: "这些{收入类型}的雇主{姓名（出生日期）}是？",
            wages_UnitLabel: "John Doe's (8.Aug.1967) wages 的频率如何?",
            wages_Label: "Wages from MarketBasket",
            childSupport_Question: "的子女抚养费用是多少 ",
            selectHouseHold_Question: "请选择家庭成员姓名，谁支付这笔费用？",
            childSupport_AddHouseHold: "+ 添加另一位家庭成员的子女抚养费",
            childSupportExpenses_Question:
              "您的子女抚养费信息是否有任何变更？（必填）",
            doneButtonlabel: "完成",
            deleteButtonlabel: "删除",
            titlePer: "频率",
            editButtonLabel: "编辑",
            otherInfo_Question: "您的联系信息是否有任何更改？（必填）",

            earnedIncomeTitle: "劳动所得收入汇总",
            incomeTitle: "劳动所得收入汇总",
            earnedIncomeMenuTitle: "劳动所得收入",
            childSupportLabel: "子女抚养费",
            unEarnedIncomeMenuTitle: "非劳动所得收入",
            unEarnedIncomeTitle: "非劳动所得收入汇总",
            incomeHeader: "关于您的家庭收入",
            aboutIncomeBefore: "您家中是否有人获得任何 ",
            aboutIncomeTrigger: "收入或补助?",
            childSupportSideBar: "子女抚养费",
            childSupportPayLabel: "支付",
            childSupportPerLabel: "频率",
            childSupportSummaryHeader: "子女抚养费汇总",
            incomeHelp:
              "收入是指您从工作获得的报酬。补助是指您从工作以外途径获得的报酬。",
            clearOption: "无人",
            otherInfoSideBar: "其他信息",
            combineIncomeoptions: [
              "工资",
              "个体经营",
              "工作研究",
              "租金收入",
              "SSI（补充保障收入）",
              "RSDI（退休，幸存者和残疾保险）",
              "失业",
              "子女抚养费",
              "养老金",
              "退伍军人补贴",
              "劳动者报酬",
              "其他",
            ],
            unearnedIncomeoptions: [
              "TANF",
              "Social Security (SSA /RSDI)",
              "SSI (Supplemental Security Income)",
              "Veterans Benefits",
              "Unemployment Compensation",
              "Workman's Compensation",
              "Interest Dividends from Stock and Bonds",
              "Military Allotment",
              "Pension or Retirement Income",
              "Railroad Retirement",
              "HUD Utility Assistance",
              "Income from Land Rental or Rental Property",
              "Child Support",
              "Alimony",
              "Contributions from Friends and Relatives",
              "Other"
            ],
            wageIncomeBeforeLabel: "是什麼 ",
            wageIncomeTriggerLabel: "總收入",
            wagesTargetValue: "工资",
            selfemeploymentTargetValue: "个体经营",
            workStudyTargetValue: "工作研究",
            SSITargetValue: "SSI（补充保障收入）",
            unEmploymentTargetValue: "失业",
            rsdiTargetValue: "RSDI（退休，幸存者和残疾保险）",
            childSuppTargetValue: "子女抚养费",
            pensionTargetValue: "养老金",
            veteranBenefitsTargetValue: "退伍军人补贴",
            rentalIncomeTargetValue: "租金收入",
            workerCompTargetValue: "工伤赔偿其",
            otherTargetValue: "其他",
            noneTargetValue: "无",
            mortageInlineHelpText:
              "如果房屋保险，税收和/或物业管理费用包含在您的抵押贷款中，请不要在以下问题中单独列出金额。",
            grossIncomeHelp:
              "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
            unitsPerLabel: " ",
            unitsOptions: [
              "全年",
              "每两月（每两个月一次）",
              "每两周（每两周一次）",
              "每月",
              "每季度",
              "每半年（一年两次）",
              "每半月（每月两次）",
              "每周",
            ],
            labelOtherwage: "+ 添加另一個工資",
            adsSelfEmployee: "+ 添加另一個自僱",
            addAnotherRoomAndBoardIncome: "+ 添加另一项食宿收入",
            condWorkStudy: "工作研究",
            workStudyLabel: "+ 添加另一個工作研究",
            anotherSSILabel: "+ 添加另一個SSI",
            anotherLabel: "+ 添加另一项",
            addRDILabel: "+ 添加另一個RSDI",
            unEmpLabel: "+ 再增加一個失業",
            childSuppLabel: "子女撫養費",
            anotherChildsupport: "+ 添加另一個子支持",
            pensionLabel: "養老金",
            anotherPensionLabel: "+ 再加一個養老金",
            workerCompensationLabel: "+ 添加另一个工人的赔偿",
            veteranBenefit: "老兵的好处",
            addAnotherVBenefitLabel: "+ 添加另一個退伍軍人的好處",
            rentIncomeLabel: "贴租金收",
            repeatQuestionLabel: "+ 添加另一个租金收入",
            repeatincomeQuestionLabel: "+ 添加另一个租金收入",
            unitsRentalOptions: ["月", "周"],
            questionButtonLabel: "+ 添加另一個租金收入",
            incomeSummaryTitle: "收入摘要",
            incomeSummaryHeader: "收入摘要",
            expenseTitle: "开支",
            almostLabel: "还差一步！",
            houseUtlilityLabel: "住房和通用开支",
            expenseHeader: "请告诉我们您的家庭开支",
            expenseSubHeader:
              "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
            houseHoldQLabel: "你家是否负责任何公用事业？?",
            utilityQOptions: [
              "取暖费（石油，天然气，电力或丙烷等）",
              "空调用电费",
              "空调使用费",
              "电费和/或燃气费",
              "电话费或手机费",
            ],
            utilitiesClearOption: "无",
            houseQLabel: "您的家庭有哪些住房开支？",
            houseQOptions: [
              "房租",
              "抵押贷款",
              "财产税",
              "家庭保险",
              "物业管理费用",
            ],
            houseRentQLabel: "您的住房的月租金有多少？",
            rentTargetValue: "RENT",
            mortageTargetValue: "MORTGAGE",
            propertyTaxTargetValue: "PROPERTY_TAX",
            homeInsurTargetValue: "HOME_INSURANCE",
            condoFeeTargetValue: "CONDO_FEE",
            unitBetween: "每",
            houseUnitOptions: ["周", "月", "年"],
            mortageQLabel: "您家的房贷有多少？",
            mortageunitBetween: " ",
            mortageQoptions: ["周", "月", "年"],
            mortageProptaxLabel: "您的住房的财产税有多少？",
            mortagepropBetween: " ",
            mortagepropoptions: ["周", "月", "年"],
            insuranceCostQLabel: "您的住房的房屋保险有多少？",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["周", "月", "年"],
            insuranceClearOption: "不，我不需要为此付费。",
            condofeeQLabel: "您的住房的物业管理费有多少？",
            condofeepropBetween: "每",
            condofeeCostoptions: ["周", "月", "年"],
            condofeeClearOption: "不，我不需要为此付费。",
            propTaxQLabel: "你家有財產稅嗎？",
            propTaxBetween: "每",
            propTaxOptions: ["周", "月", "年"],
            propTaxClearOption: "不，我不需要为此付费。",
            homeInsuranceQLabel: "你的家庭有家庭保險費用嗎？",
            homeInsuranceBetween: "每",
            homeInsuranceUnits: ["周", "月", "年"],
            homeInsuranceClearOption: "不，我不需要为此付费。",
            condoFeeQLabel: "你家有公寓費嗎？",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["周", "月", "年"],
            condoFeeClearOption: "不，我不需要为此付费。",

            //new labels:
            anyUtilQcost: "您的家庭有哪些通用开支？",
            chooseHoldErrmsg: "请选择家用电器",
            houseCostErrmsg: "请选择住房费用",
            rentErrMsg: "请输入租金",
            anotherRentLabel: "+ 添加另一個租金",
            mortageErrLabel: "请输入抵押",
            propTaxLabel: "请输入财产税",
            propTaxButtonLabel: "+ 添加另一個財產稅",
            homeInsurbutton: "+ 添加另一個家庭保險",
            homeInsurErrmsg: "请输入家庭保险",
            condoButtonLabel: "+ 添加另一個公寓費用",
            condoFeeErrmsg: "请输入公寓费用",
            addMortageButtonLabel: "+ 添加另一個抵押",
            childTitle: "子女或成人照护费用",
            childCostHeader: "告诉我们您的子女或成人的照护费",
            childCostSubHeader:
              "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
            careGiveObjbefore: "您的家人有子女或成人照护开支吗？这包括 ",
            careGiveObjtrigger: "接送",
            careGiveObjAfter: "被照护人到其看护人的费用。",
            careGiveHelp:
              "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
            questionClearOption: "无人",
            kidsandadultSubheader:
              "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
            costBetween: " ",
            weekMonthOptions: ["周", "月"],
            costOptions: ["周", "月", "年"],
            yesnoOptions: ["是", "否"],
            targetValueYes: "是",
            targetValueNo: "否",
            noofAppointment: "请选择任命数量",
            addressProvider: "看护人的地址？",
            freqTripoptions: ["周", "月", "年"],
            unitMonthTargetValue: { unit: "月" },
            childSupTitle: "子女抚养费用",
            supportCostHeader: "子女抚养费用",
            childSupportQlegalbefore:
              "您家中是否有人必须向不与您同住的子女支付法定 ",
            childSupportQlegaltrigger: "子女抚养费",
            childSupportQlegalAfter: " ?",
            childSupportHelp:
              "已支付的子女抚养费在按法律要求完成付款的情况下可被记为支出费用。这包括欠款支付。",
            medicalTitle: "医疗费用",
            medicalInlineSeethisFull: "参阅此的完整列表。",
            medicalInlineEligibleMedical: "合格的医疗费用",
            medicalHeader: "请告诉我们您的医疗费",
            medicalSubHeader:
              "请包括您负责支付的费用，即使您无法及时支付或无力支付。",
            medicalExpBefore:
              "下列任何人是否支付健康保险或其他医疗费用，包括门诊，",
            medicalExpTrigger: "交通",
            medicalExpAfter: ", 非处方药，牙科或眼睛护理，成人尿片等？",
            medicalExphelp:
              "包括公共或私人交通费。包括停车费、公交车费、出租车费、停车费、支付给朋友或家人的交通费等。",
            medicalInhelp: "请参阅此完整列表",
            medicalInhelpEligible: "合格医疗开支的完整清单。",
            medicalInhelpFull: "查看有关合格医疗开支的完整清单。",
            medicalAddress: "医疗机构或药房地址？",
            zipErrmsg: "請輸入有效的郵政編碼",
            signSubmitLabel: "签名并提交",
            signSubmitHeader: "签名并提交",
            signSubmitSub: "您已填写完全部表格！现在更简单。",
            subSectionLabel: "提交",
            submitLabelFinal: "您的《中间报告》已提交!",
            tellusAbout: "请告诉我们您的子女抚养费",
            moneyYougettitle: "这包括您从工作、政府或其他来源获得的钱。",
          };
        case VI: //Vietnam
          return {
            emptyLabel: " ",
            amountLabel: "số Tiền",
            wages_AmountLabel:
              "Tổng số tiền của John Doe's (8.Aug.1967) wages là bao nhiêu?",
            wages_EmployerLabel:
              "Ai là chủ nhân của John Doe's (8.Aug.1967)'s {Tên (Ngày sinh) cho {Loại thu nhập} này?",
            wages_UnitLabel:
              "Bao lâu một lần John Doe's (8.Aug.1967) nhận wages?",
            wages_Label: "Wages from MarketBasket",
            childSupport_Question: "Chi phí trợ cấp nuôi con cho là bao nhiêu",
            selectHouseHold_Question:
              "Vui lòng chọn tên của thành viên trong hộ gia đình, ai đang trả chi phí này?",
            childSupport_AddHouseHold:
              "+ Bổ sung khoản hỗ trợ nuôi con khác của các thành viên trong gia đình",
            childSupportExpenses_Question:
              "Có thay đổi nào về thông tin chi phí hỗ trợ nuôi con của bạn không? (Bắt buộc)",
            doneButtonlabel: "Hoàn tất",
            deleteButtonlabel: "Xóa",
            titlePer: "mỗi",
            editButtonLabel: "Chỉnh sửa",
            childSupportSideBar: "Chi phí trợ cấp nuôi con",
            childSupportSummaryHeader: "Tóm tắt chi phí hỗ trợ nuôi con",
            otherInfoSideBar: "Thông tin khác",
            otherInfo_Question:
              "Đã có bất kỳ thay đổi nào về thông tin liên lạc của quý vị không? (Bắt buộc)",

            incomeTitle: "Tóm tắt thu nhập đã kiếm được",
            childSupportPerLabel: "mỗi",
            earnedIncomeMenuTitle: "Thu nhập đã thu",
            earnedIncomeTitle: "Tóm tắt thu nhập đã kiếm được",
            unEarnedIncomeMenuTitle: "Thu nhập chưa thu",
            unEarnedIncomeTitle: "Tóm tắt thu nhập chưa kiếm được",
            incomeHeader: "Thông tin về thu nhập của hộ gia đình quý vị",
            aboutIncomeBefore: "Có ai trong hộ gia đình nhận được bất kỳ ",
            aboutIncomeTrigger: "thu nhập hoặc trợ cấp nào không?",
            incomeHelp:
              "Thu nhập là tiền quý vị nhận được từ làm việc. Các khoản trợ cấp là tiền quý vị nhận được từ một nguồn không phải là việc làm.",
            clearOption: "Không có ai",
            combineIncomeoptions: [
              "Lương",
              "Tự làm chủ",
              "Nghiên cứu việc làm",
              "Thu nhập cho thuê",
              "SSI (Thu Nhập An Sinh Bổ Sung)",
              "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
              "Thất nghiệp",
              "Trợ cấp nuôi con",
              "Lương hưu",
              "Trợ cấp cho cựu chiến binh",
              "Bồi thường lao động",
              "Khác",
            ],
            unearnedIncomeoptions: [
              "TANF",
              "Social Security (SSA /RSDI)",
              "SSI (Supplemental Security Income)",
              "Veterans Benefits",
              "Unemployment Compensation",
              "Workman's Compensation",
              "Interest Dividends from Stock and Bonds",
              "Military Allotment",
              "Pension or Retirement Income",
              "Railroad Retirement",
              "HUD Utility Assistance",
              "Income from Land Rental or Rental Property",
              "Child Support",
              "Alimony",
              "Contributions from Friends and Relatives",
              "Other"
            ],
            wageIncomeBeforeLabel: "Cái gì là ",
            wageIncomeTriggerLabel: "Tổng thu nhập",
            wagesTargetValue: "Lương",

            selfemeploymentTargetValue: "Tự làm chủ",
            workStudyTargetValue: "Nghiên cứu việc làm",
            SSITargetValue: "SSI (Thu Nhập An Sinh Bổ Sung)",
            rsdiTargetValue:
              "RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)",
            unEmploymentTargetValue: "Thất nghiệp",
            childSuppTargetValue: "Trợ cấp nuôi con",
            childSupportLabel: "hỗ trợ nuôi con",
            pensionTargetValue: "Lương hưu",
            veteranBenefitsTargetValue: "Trợ cấp cho cựu chiến binh",
            rentalIncomeTargetValue: "Thu nhập cho thuê",
            workerCompTargetValue: "Bồi thường lao động",
            otherTargetValue: "Khác",
            noneTargetValue: "Không có",
            mortageInlineHelpText:
              "Nếu chi phí bảo hiểm nhà, thuế, và / hoặc phí công quản đã được bao gồm trong thế chấp của quý vị, đừng liệt kêsố tiền riêng biệt trong những câu hỏi sau đây. ",
            grossIncomeHelp:
              "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
            unitsPerLabel: " ",
            workerCompensationLabel: "+ thêm bồi thường của công nhân khác",
            unitsOptions: [
              "hàng năm",
              "Hai tháng một lần (hai tháng một lần)",
              "Hai tuần một lần",
              "Hàng tháng",
              "Hàng quý",
              "Nửa năm (Hai lần một năm)",
              "nửa tháng (hai lần một tháng)",
              "Hàng tuần",
            ],
            labelOtherwage: "+ Bổ sung mức lương khác",
            adsSelfEmployee: "+ Bổ sung việc tự khác", 
            addAnotherRoomAndBoardIncome: "+ Thêm thu nhập phòng và tiền ăn khác",
            condWorkStudy: "Nghiên cứu việc làm",
            workStudyLabel: "+ Thêm nghiên cứu việc khác",
            anotherSSILabel: "+ Thêm SSI khác",
            anotherLabel: "+ Bổ sung khác",
            addRDILabel: "+ Bổ sung RSDI khác",
            unEmpLabel: "+ Bổ sung Thất nghiệp",
            childSuppLabel: "Trợ cấp nuôi con",
            anotherChildsupport: "+ Bổ sung khoản Hỗ trợ Nuôi con khác",
            pensionLabel: "Lương hưu",
            anotherPensionLabel: "+ Thêm tiền trợ cấp khác",
            veteranBenefit: "Trợ cấp cho cựu chiến binh",
            addAnotherVBenefitLabel: "+ Bổ sung lợi ích của cựu chiến binh",
            rentIncomeLabel: "Thu nhập cho thuê",
            repeatQuestionLabel: "+ Thêm Thu nhập Cho thuê khác",
            repeatincomeQuestionLabel: "+ Thêm Thu nhập Cho thuê khác",
            unitsRentalOptions: ["Tháng", "Tuần"],
            questionButtonLabel: "+ Bổ sung Khác",
            incomeSummaryTitle: "Tóm tắt Thu nhập",
            incomeSummaryHeader: "Tóm tắt Thu nhập",
            expenseTitle: "Các chi phí",
            almostLabel: "Gần xong rồi!",
            houseUtlilityLabel: "Các chi phí về Nhà ở và Tiện ích",
            expenseHeader:
              "Hãy nói cho chúng tôi biết về chi phí nhà ở của quý vị",
            expenseSubHeader:
              "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
            houseHoldQLabel: "Is your household responsible for any utilities?",
            utilityQOptions: [
              "Nhiệt (dầu, khí đốt, điện hoặc khí prô-pan, v.v)",
              "Điện cho máy điều hòa không khí",
              "Một khoản phí để sử dụng điều hòa không khí",
              "Điện và / hoặc khí đốt",
              "Dịch vụ điện thoại hoặc điện thoại di động",
            ],
            utilitiesClearOption: "Không có",
            houseQLabel: "Loại chi phí nhà ở mà hộ gia đình có?",
            houseQOptions: [
              "Thuê",
              "Thế chấp",
              "Thuế bất động sản",
              "Bảo hiểm nhà ở",
              "Chi phí công quản",
            ],
            houseRentQLabel: "Tiền thuê của hộ gia đình quý vị là bao nhiêu?",
            rentTargetValue: "RENT",
            mortageTargetValue: "MORTGAGE",
            propertyTaxTargetValue: "PROPERTY_TAX",
            homeInsurTargetValue: "HOME_INSURANCE",
            condoFeeTargetValue: "CONDO_FEE",
            unitBetween: "theo",
            childSupportPayLabel: "trả tiền",
            houseUnitOptions: ["Tuần", "Tháng", "Năm"],
            mortageQLabel:
              "Khoản thế chấp của hộ gia đình quý vị là bao nhiêu?",
            mortageunitBetween: " ",
            mortageQoptions: ["Tuần", "Tháng", "Năm"],
            mortageProptaxLabel:
              "Thuế bất động sản của hộ gia đình quý vị là bao nhiêu?",
            mortagepropBetween: " ",
            mortagepropoptions: ["Tuần", "Tháng", "Năm"],
            insuranceCostQLabel:
              "Bảo hiểm nhà ở của hộ gia đình quý vị là bao nhiêu?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["Tuần", "Tháng", "Năm"],
            insuranceClearOption: "Không, tôi không cần trả tiền cho việc này.",
            condofeeQLabel:
              "Chi phí công quản của hộ gia đình quý vị là bao nhiêu?",
            condofeepropBetween: "theo",
            condofeeCostoptions: ["Tuần", "Tháng", "Năm"],
            condofeeClearOption: "Không, tôi không cần trả tiền cho việc này.",
            propTaxQLabel: "Gia đình của bạn có thuế bất động sản không?",
            propTaxBetween: "theo",
            propTaxOptions: ["Tuần", "Tháng", "Năm"],
            propTaxClearOption: "Không, tôi không cần trả tiền cho việc này.",
            homeInsuranceQLabel:
              "Gia đình của bạn có chi phí bảo hiểm tại nhà không?",
            homeInsuranceBetween: "theo",
            homeInsuranceUnits: ["Tuần", "Tháng", "Năm"],
            homeInsuranceClearOption:
              "Không, tôi không cần trả tiền cho việc này.",
            condoFeeQLabel: "Hộ gia đình của bạn có phí chung cư không?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Tuần", "Tháng", "Năm"],
            condoFeeClearOption: "Không, tôi không cần trả tiền cho việc này.",

            //new labels:
            anyUtilQcost:
              "Hộ gia đình của quý vị có bất kỳ khoản chi phí tiện ích nào không?",
            chooseHoldErrmsg: "Vui lòng chọn tiện ích gia đình",
            houseCostErrmsg: "Vui lòng chọn chi phí nhà ở",
            rentErrMsg: "Hãy nhập tiền thuê",
            anotherRentLabel: "+ Thêm tiền thuê khác",
            mortageErrLabel: "Hãy nhập khoản thế chấp",
            propTaxLabel: "Vui lòng nhập thuế bất động sản",
            propTaxButtonLabel: "+ Thêm tiền thuê khác",
            homeInsurbutton: "+ Bổ sung bảo hiểm khác",
            homeInsurErrmsg: "Hãy nhập bảo hiểm nhà",
            condoButtonLabel: "+ Bổ sung khoản phí chung",
            condoFeeErrmsg: "Vui lòng nhập lệ phí nhà chung cư",
            addMortageButtonLabel: "+ Thêm thế chấp khác",
            childTitle: "Chi phí về Chăm sóc Trẻ em hoặc Người Lớn Phụ thuộc",
            childCostHeader:
              "Hãy cho chúng tôi biết các Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc",
            childCostSubHeader:
              "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
            careGiveObjbefore:
              "Có ai trong hộ gia đình có các Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc không? Chi phí này bao gồm các chi phí ",
            careGiveObjtrigger: "chuyên chở",
            careGiveObjAfter:
              "(những) người phụ thuộc đến và/về từ nhà cung cấp dịch vụ chăm sóc.",
            careGiveHelp:
              "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
            questionClearOption: "Không có ai",
            kidsandadultSubheader:
              "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
            costBetween: " ",
            weekMonthOptions: ["Tuần", "Tháng"],
            costOptions: ["Tuần", "Tháng", "Năm"],
            yesnoOptions: ["Có", "Không"],
            targetValueYes: "Có",
            targetValueNo: "Không",
            noofAppointment: "Vui lòng chọn no.of cuộc hẹn",
            addressProvider: "Địa chỉ của nhà cung cấp là gì?",
            freqTripoptions: ["Tuần", "Tháng", "Năm"],
            unitMonthTargetValue: { unit: "Tháng" },
            childSupTitle: "Chi phí Trợ cấp nuôi Con",
            supportCostHeader: "Chi phí Trợ cấp nuôi Con",
            childSupportQlegalbefore:
              "Có ai trong hộ gia đình có nghĩa vụ pháp lý (án lệnh) để trả cho khoản ",
            childSupportQlegaltrigger: "trợ cấp nuôi con",
            childSupportQlegalAfter:
              " cho một đứa trẻ không sống chung với quý vị?",
            childSupportHelp:
              "Tiền trợ cấp nuôi con có thể được tính là một khoản chi phí khi được yêu cầu về mặt pháp lý và các khoản thanh toán đang được thực hiện, bao gồm các khoản thanh toán sau.",
            medicalTitle: "Chi phí Y tế",
            medicalInlineSeethisFull: "Xem danh sách đầy đủ các",
            medicalInlineEligibleMedical: " chi phí y tế đủ điều kiện.",
            medicalHeader: "Hãy cho chúng tôi biết về các chi phí y tế ",
            medicalSubHeader:
              "Hãy bao gồm các chi phí quý vị có trách nhiệm chi trả ngay cả khi quý vị chưa trả hoặc không thể trả chúng",
            medicalExpBefore:
              "Có ai được liệt kê dưới đây chi trả tiền bảo hiểm y tế hay các chi phí y tế khác bao gồmtoa thuốc,",
            medicalExpTrigger: " chuyên chở",
            medicalExpAfter:
              ", thuốc không cần toa, chăm sóc răng hoặc mắt, tã người lớn, v.v.?",
            medicalExphelp:
              "Bao gồm khoản thanh toán cho việc chuyên chở công cộng hoặc tư nhân, bao gồm bãi đậu xe, xe buýt, xe taxi, chi phí đậu xe, thanh toán cho bạn bè hoặc thành viên gia đình cho việc chuyển chở, v.v. ",
            medicalInhelp: "Xem danh sách đầy đủ các",
            medicalInhelpEligible: " chi phí y tế hợp lệ",
            medicalAddress: "Địa chỉ của cuộc hẹn y tế hoặc nhà thuốc là gì?",
            zipErrmsg: "Please enter a valid Zipcode.",
            signSubmitLabel: "Ký và gửi",
            signSubmitHeader: "Ký và gửi",
            signSubmitSub:
              "Quý vị đã làm xong mẫu đơn! Bây giờ đến những việc dễ dàng.",
            subSectionLabel: "Đã gửi",
            submitLabelFinal: "Báo cáo giữa kỳ của quý vị đã được gửi!",
            tellusAbout: "Hãy cho chúng tôi biết các Chi phí Trợ cấp Nuôi con",
            moneyYougettitle:
              "Điều này bao gồm tiền quý vị có thể nhận được từ việc làm, chính phủ hoặc các nguồn khác.",
          };
        case HI:
          return {
            emptyLabel: "'s ",
            shelterExpensesLabel: "Depans pou lojman",
            amountLabel: "Montan",
            wages_AmountLabel: "Ki salè brit John Doe's (8.Aug.1967) ?",
            wages_EmployerLabel:
              "Kilès ki se patwon John Doe (8.Aug.1967) pou salè sa yo?",
            wages_UnitLabel: "Ki frekans salè John Doe's (8.Aug.1967) ?",
            wages_Label: "Salè ",
            selfGross_Label: "Travay endepandan ",
            workStudy_Label: "Travay Etidyan ",
            rental_Label: "Revni Lwaye ",
            childSupportSideBar: "Depans pou Sipò Timoun",
            childSupportSummaryHeader: "Rezime depans pou sipò timoun",
            otherInfoSideBar: "Lòt enfòmasyon",
            childSupport_Question: "Ki depans sipò timoun ",
            selectHouseHold_Question:
              "Please select the name of the household member, who is paying this cost?",
            childSupport_AddHouseHold:
              "+ Ajoute yon lòt manm nan kay la sipò timoun",
            childSupportExpenses_Question:
              "Èske gen nenpòt chanjman nan enfòmasyon w lan sou depans pou sipò timoun? (Obligatwa) ",
            doneButtonlabel: "Fini",
            deleteButtonlabel: "Retire",
            titlePer: "Pa",
            editButtonLabel: "Edite",
            childSupportPayLabel: "pays",
            childSupportPerLabel: "Pa",
            childSupportLabel: "nan sipò timoun",
            otherInfo_Question:
              "Èske te gen nenpòt chanjman nan enfòmasyon kontak ou? (Obligatwa)",

            incomeTitle: "Rezime revni ou fè",
            earnedIncomeMenuTitle: "Revni touche",
            earnedIncomeTitle: "Rezime revni ou fè",
            unEarnedIncomeMenuTitle: "Revni pa touche",
            unEarnedIncomeTitle: "Rezime revni ki pa fèt nan travay",
            incomeHeader: "Konsènan revni fwaye w la",
            aboutIncomeBefore: "Èske nenpòt moun nan Kay la resevwa nenpòt ",
            aboutIncomeTrigger: "revni oswa benefis",
            incomeHelp:
              "Revni se lajan ou resevwa nan travay. Benefis se lajan ou resevwa soti nan yon lòt sous ki pa travay.",
            clearOption: "Pesonn",
            combineIncomeoptions: [
              "Salè",
              "Travay Endepandan",
              "Travay Etidyan",
              "Revni Lwaye",
              "SSI (Revni Sekirite Siplemantè)",
              "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
              "Chomaj",
              "Sipò pou Timoun",
              "Pansyon",
              "Benefis pou Veteran",
              "Worker's Compensation",
              "Lòt",
            ],
            unearnedIncomeoptions: [
              "TANF",
              "Social Security (SSA /RSDI)",
              "Supplemental Security Income",
              "Veteran's Benefits",
              "Unemployment Compensation",
              "Workman's Compensation",
              "Interest Dividends from Stock and Bonds",
              "Military Allotment",
              "Pension or Retirement Income",
              "Railroad Retirement",
              "HUD Utility Assistance",
              "Income from Land Rental or Rental Property",
              "Child Support",
              "Alimony",
              "Contributions from Friends and Relatives",
              "Other"
            ],
            wageIncomeBeforeLabel: "Ki sa ki ",
            wageIncomeTriggerLabel: "revni brit",
            wagesTargetValue: "Salè",
            selfemeploymentTargetValue: "Travay Endepandan",
            workStudyTargetValue: "Travay Etidyan",
            rsdiTargetValue: "RSDI (Asirans Retrèt, Sivivan ak Andikap)",
            SSITargetValue: "SSI (Revni Sekirite Siplemantè)",
            unEmploymentTargetValue: "Chomaj",
            childSuppTargetValue: "Sipò pou timoun",
            pensionTargetValue: "Pansyon",
            veteranBenefitsTargetValue: "Benefis pou Veteran",
            rentalIncomeTargetValue: "Revni Lwaye",
            workerCompTargetValue: "Konpansasyon Travayè",
            otherTargetValue: "Lòt",
            noneTargetValue: "Okenn",

            mortageInlineHelpText:
              "Si asirans kay, taks, ak/oswa frè kondo enkli nan prè ipotèk ou a, pa mete montan an apa nan kesyon anba yo.",
            grossIncomeHelp:
              "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
            unitsPerLabel: " ",
            unitsOptions: [
              "Anyèl",
              "Bimansyèl (chak de mwa)",
              "Biebdomadè (chak de semèn)",
              "Mansyèl",
              "Chak trimès",
              "Semianyèl (de fwa pa ane)",
              "Semimansyèl (de fwa pa mwa)",
              "Chak semèn",
            ],
            labelOtherwage: "+ Ajoute yon lòt salè",
            adsSelfEmployee: "+ Ajoute yon lòt travay endepandan",
            addAnotherRoomAndBoardIncome: "+ Ajoute yon lòt chanm ak revni Komisyon Konsèy",
            condWorkStudy: "Travay Etidyan",
            workStudyLabel: "+ Ajoute yon lòt travay etidyan",
            anotherSSILabel: "+ Ajoute yon lòt SSI",
            anotherLabel: "+ Ajoute yon lòt",
            addRDILabel: "+ Ajoute yon lòt RSDI",
            unEmpLabel: "+ Ajoute yon lòt Chomaj ",
            childSuppLabel: "Sipò Timoun",
            anotherChildsupport: "+ Ajoute yon lòt Sipò Timoun",
            pensionLabel: "Pansyon",
            anotherPensionLabel: "+ Ajoute yon lòt Pansyon",
            workerCompensationLabel: "+ Ajoute yon lòt Konpansasyon Travayè",
            veteranBenefit: "Benefis pou Veteran",
            addAnotherVBenefitLabel: "+ Ajoute yon lòt benefis pou veteran",
            rentIncomeLabel: "Revni Lwaye",
            repeatQuestionLabel: "+ Ajoute yon lòt revni lwaye",
            repeatincomeQuestionLabel: "+ Ajoute èdtan pou yon lòt lwaye",
            unitsRentalOptions: ["Mwa", "Semèn"],
            questionButtonLabel: "+ Ajoute yon lòt Lòt",
            incomeSummaryTitle: "Rezime revni",
            incomeSummaryHeader: "Rezime revni",
            expenseTitle: "Depans",
            almostLabel: "Prèske fini!",
            houseUtlilityLabel: "Depans pou lojman ak sèvis piblik",
            expenseHeader: "Pale nou konsènan depans ou yo pou lojman",
            expenseSubHeader:
              "Tanpri enkli depans ou responsab pou peye menm si ou an reta oswa pa ka peye yo.",
            houseHoldQLabel:
              "Èske fwaye w la responsab pou nenpòt sèvis piblik?",
            utilityQOptions: [
              "Chofaj (luil, gaz, elektrisite oswa pwopàn, elatriye)",
              "Elektrisite pou yon klimatizè",
              "Yon frè pou itilize yon klimatizè",
              "Elektrisite ak/yon gaz",
              "Telefòn oswa sèvis telefòn selilè",
            ],
            utilitiesClearOption: "Okenn",
            houseQLabel: "Ki tip depans pou lojman fwaye w la genyen?",
            houseQOptions: [
              "Lwaye",
              "Prè ipotèk",
              "Taks sou pwopriyete",
              "Asirans Kay",
              "Frè Kondo",
            ],
            houseRentQLabel: "Konbyen lwaye fwaye w la ye?",
            rentTargetValue: "RENT",
            mortageTargetValue: "MORTGAGE",
            propertyTaxTargetValue: "PROPERTY_TAX",
            homeInsurTargetValue: "HOME_INSURANCE",
            condoFeeTargetValue: "CONDO_FEE",

            unitBetween: "pa",
            houseUnitOptions: ["Semèn", "Mwa", "Ane"],
            mortageQLabel: "Konbyen prè ipotèk fwaye w la ye?",
            mortageunitBetween: " ",
            mortageQoptions: ["Semèn", "Mwa", "Ane"],
            mortageProptaxLabel: "Konbyen taks sou pwopriyete fwaye w la ye?",
            mortagepropBetween: " ",
            mortagepropoptions: ["Semèn", "Mwa", "Ane"],
            insuranceCostQLabel: "Konbyen asirans kay fwaye w la ye?",
            insuranceCostpropBetween: " ",
            insuranceCostoptions: ["Semèn", "Mwa", "Ane"],
            insuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            condofeeQLabel: "Konbyen frè kondo fwaye w la ye?",
            condofeepropBetween: "pa",
            condofeeCostoptions: ["Semèn", "Mwa", "Ane"],
            condofeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            propTaxQLabel: "Èske fwaye w la gen taks sou pwopriyete?",
            propTaxBetween: "pa",
            propTaxOptions: ["Semèn", "Mwa", "Ane"],
            propTaxClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            homeInsuranceQLabel: "Èske fwaye w la gen frè asirans kay?",
            homeInsuranceBetween: "pa",
            homeInsuranceUnits: ["Semèn", "Mwa", "Ane"],
            homeInsuranceClearOption: "Non. Mwen pa bezwen peye pou sa a.",
            condoFeeQLabel: "Èske fwaye w la gen frè Kondo?",
            condoFeeBeweenLabel: " ",
            condoFeeUnits: ["Semèn", "Mwa", "Ane"],
            condoFeeClearOption: "Non. Mwen pa bezwen peye pou sa a.",

            //new labels:
            anyUtilQcost: "Èske fwaye w la gen okenn depans pou sèvis piblik?",
            chooseHoldErrmsg: "Tanpri chwazi sèvis piblik fwaye a",
            houseCostErrmsg: "Tanpri chwazi depans pou lojman",
            rentErrMsg: "Tanpri antre lwaye",
            anotherRentLabel: "+ Ajoute yon lòt lwaye",
            mortageErrLabel: "Tanpri antre prè ipotèk",
            propTaxLabel: "Tanpri antre taks sou pwopriyete",
            propTaxButtonLabel: "+ Ajoute yon lòt taks sou pwopriyete",
            homeInsurbutton: "+ Ajoute yon lòt asirans kay",
            homeInsurErrmsg: "Tanpri antre asirans kay",
            condoButtonLabel: "+ Ajoute yon lòt frè kondo",
            condoFeeErrmsg: "Tanpri antre frè kondo",
            addMortageButtonLabel: "+ Ajoute yon lòt prè ipotèk",
            childTitle: "Depans pou Swen Timoun oswa Adilt",
            childCostHeader:
              "Pale nou konsènan depans ou yo pou Swen Timoun oswa Granmoun Aje",
            childCostSubHeader:
              "Tanpri enkli depans ou responsab pou peye menm si ou an reta oswa pa ka peye yo.",
            careGiveObjbefore:
              "Èske gen yon moun nan Kay la ki gen depans pou swen pou timoun oswa adilt? Sa enkli depans pou ",
            careGiveObjtrigger: "transpòte",
            careGiveObjAfter:
              " depandan an(yo) pou ale/soti lakay yon founisè swen.",
            careGiveHelp:
              "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
            questionClearOption: "Pesonn",
            kidsandadultSubheader:
              "Tanpri enkli depans ou responsab pou peye menm si ou an reta oswa pa ka peye yo.",
            costBetween: " ",
            weekMonthOptions: ["Mwa", "Semèn"],
            costOptions: ["Semèn", "Mwa", "Ane"],
            yesnoOptions: ["Wi", "Non"],
            targetValueYes: "Wi",
            targetValueNo: "Non",
            noofAppointment: "Please choose no.of appointments",
            addressProvider: "Ki adrès founisè a?",
            freqTripoptions: ["Semèn", "Mwa", "Ane"],
            unitMonthTargetValue: { unit: "Mwa" },

            childSupTitle: "Depans pou Sipò Timoun",
            supportCostHeader: "Depans pou Sipò Timoun",
            childSupportQlegalbefore:
              "Èske nenpòt moun nan fwaye w la gen yon obligasyon legal (òdonans tribinal) pou peye ",
            childSupportQlegaltrigger: "sipò timoun",
            childSupportQlegalAfter: " pou yon timoun ki pa viv ak ou?",
            childSupportHelp:
              "Sipò timoun ki peye yo ka konte kòm yon depans lè li obligatwa legalman epi peman yo ap fèt. Sa enkli peman pou aryere yo.",
            medicalTitle: "Depans medikal",
            medicalInlineSeethisFull: "Gade lis konplè sa a",
            medicalInlineEligibleMedical: " depans medikal ki kalifye",
            medicalHeader: "Pale nou konsènan depans medikal ou yo",
            medicalSubHeader:
              "Tanpri enkli depans ou responsab pou peye menm si ou an reta oswa pa ka peye yo.",
            medicalExpBefore:
              "Èske nenpòt moun ki nan lis anba a peye pou asirans sante oswa lòt depans medikal ki enkli preskripsyon,  ",
            medicalExpTrigger: " transpò",
            medicalExpAfter:
              ", medikaman san preskripsyon, swen dantè oswa pou je, kouchèt pou granmoun, elatriye?",
            medicalExphelp:
              "Enkli peman pou transpò piblik oswa prive. Sa enkli otobis, taksi, frè pakin, peman a yon zanmi oswa yon manm fanmi pou transpò, elatriye.",
            medicalInhelp: "Gade lis konplè sa a",
            medicalInhelpEligible: " depans medikal ki kalifye",
            medicalAddress: "Ki adrès randevou medikal la oswa famasi a?",
            zipErrmsg: "Tanpri antre yon kòd postal ki valid",
            signSubmitLabel: "Siyen epi soumèt",
            signSubmitHeader: "Siyen epi soumèt",
            signSubmitSub:
              "Ou fin ranpli fòm nan! Kounye a, pou sa ki fasil la.",
            subSectionLabel: "Soumèt",
            submitLabelFinal: "Rapò Pwovizwa w la te soumèt!",
            tellusAbout: "Pale nou konsènan depans pou sipò timoun",
            moneyYougettitle:
              "Sa enkli lajan ou ka resevwa nan yon jòb, nan men gouvènman an, oswa lòt sous.",
          };
        default:
          return;
      }
    }

    case languageConstants.earnedIncomeDynamic: {
      switch (language) {
        case EN:
          return {
            heading: name + "'s Gross Earned Income",
            headingTafdc: name + "'s Earned Income",
            unearnedHeading: name + "'s gross earned income",
            mainQuestion:
              "What type(s) of income and benefits does " + name + " have?",
            mainTanfdcQuestion:
              "What type(s) of employment does " + name + " have?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]: " amount of " + name + " job income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s job income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for this job income?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s job income?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " self-employment?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s self-employment?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for these self-employment?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s self-employment?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " room and board income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s room and board income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for these room and board income?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s room and board income?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " work study?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s work study?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for these work study?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s work study?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]: " amount of " + name + " wages?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s wages?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for these wages?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s wages?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
            ],
          };
        case ES:
          return {
            heading: "Ingresos brutos del trabajo des " + name,
            headingTafdc: "Ingreso del trabajo de "+ name,
            unearnedHeading: "Ingresos brutos del trabajo des " + name,
            mainQuestion:
              "¿Qué tipos de ingresos y beneficios tiene " + name + "?",
            mainTanfdcQuestion:
              "¿Qué tipo(s) de empleo tiene " + name + "?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del " + name + " de Salarios?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Salarios?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico.",
                },
                employerLabel:
                  "¿Quién es el empleador de" + name + " para este Salarios?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Salarios?",
                  [Props.LABEL_OBJ_HELP]: "on qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del " + name + " de Trabajo independiente?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Trabajo independiente?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico.",
                },
                employerLabel:
                  "¿Quién es el empleador de " +
                  name +
                  " para este Trabajo independiente?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Trabajo independiente?",
                  [Props.LABEL_OBJ_HELP]: "on qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del " + name + " de Trabajo estudi?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + "de Trabajo estudi?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico.",
                },
                employerLabel:
                  "¿Quién es el empleador de " +
                  name +
                  " para este Trabajo estudi?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es la ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Trabajo estudi?",
                  [Props.LABEL_OBJ_HELP]: "on qué frecuencia le pagan",
                },
              },
            ],
          };
        case PT:
          return {
            heading: "Rendimento bruto de " + name,
            mainQuestion:
              "Que tipo(s) de rendimentos e benefícios " + name + " tem?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " valor bruto dos salários de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o",
                  [Props.LABEL_OBJ_TRIGGER]: " salário bruto",
                  [Props.LABEL_OBJ_AFTER]: " de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde",
                },
                employerLabel:
                  "Quem é o empregador de " + name + "para estes Salário?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]: " dos salários de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " valor bruto de Trabalho autônomo de" + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto ",
                  [Props.LABEL_OBJ_AFTER]:
                    "de Trabalho autônomo de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde",
                },
                employerLabel:
                  "Quem é o empregador de " +
                  name +
                  " para estes Trabalho autônomo?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do Trabalho autônomo de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " valor bruto de Estudo de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto ",
                  [Props.LABEL_OBJ_AFTER]: "de " + name + " Estudo?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde",
                },
                employerLabel:
                  "Quem é o empregador de " + name + " para estes Estudo?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]: " do Estudo de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
            ],
          };
        case ZH:
          return {
            heading: name + "的劳动所得收入总额",
            unearnedHeading: name + "的劳动所得收入总额",
            mainQuestion: name + " 有哪些类型的收入和福利？",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入 " + name + " 工资?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工资的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                employerLabel: name + "的工资雇主是谁？",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工资的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入 " + name + " 个体经营?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的个体经营的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                employerLabel: name + "的个体经营雇主是谁？",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的个体经营的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入 " + name + " 工作研究?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工作研究的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                employerLabel: name + "的工作研究雇主是谁？",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工作研究的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
            ],
          };
        case VI:
          return {
            heading: name + " Tổng thu nhập đã thu của",
            unearnedHeading: "Tổng thu nhập đã thu của " + name,
            mainQuestion:
              "(Những) loại thu nhập và lợi ích mà " + name + " có?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]: " số tiền " + name + " Lương?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]: "của lương của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                employerLabel: "Ai là người chủ của " + name + " cho là gì?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần suất ",
                  [Props.LABEL_OBJ_AFTER]: "của lương của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]: " số tiền " + name + " Tự làm chủ?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    "của Tự làm chủ của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                employerLabel: "Ai là người chủ của " + name + " cho là gì?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần suất ",
                  [Props.LABEL_OBJ_AFTER]:
                    "của Tự làm chủ của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền " + name + " Nghiên cứu việc làm?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    "của Nghiên cứu việc làm của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                employerLabel:
                  "Ai là người chủ của " + name + " cho Nghiên cứu việc làm?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần suất ",
                  [Props.LABEL_OBJ_AFTER]:
                    "của Nghiên cứu việc làm của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
            ],
          };
        case HI:
          return {
            heading: name + " Revni Brit",
            unearnedHeading: name + " Revni Brit",
            mainQuestion: "Ki tip revni ak benefis " + name + " genyen?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " salè?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " salè?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante. ",
                },
                employerLabel: "Kilès ki se patwon " + name + "pou salè sa yo?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " salè?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " travay-endepandan?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " travay-endepandan?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante. ",
                },
                employerLabel:
                  "Kilès ki se anplwayè " +
                  name +
                  " pou travay endepandan sa yo?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " travay-endepandan?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " travay etidyan?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " travay etidyan?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou fè se lajan ou resevwa nan travay anvan yo retire anyen, tankou taks oswa sante. ",
                },
                employerLabel:
                  "Kilès ki se anplwayè " + name + " pou travay etidyan sa yo?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " travay etidyan?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
            ],
          };
        default:
          return;
      }
    }

    case languageConstants.unearnedIncomeDynamic: {
      switch (language) {
        case EN:
          return {
            heading: name + "'s Gross Unearned Income",
            tanfHeading: name + "'s Unearned Income",
            unearnedHeading: name + "'s Gross Unearned Income",
            mainQuestion:
              "What type(s) of income and benefits does " + name + " have?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    "TANF?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s TANF?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s TANF?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " RSDI (Retirement, Survivor's, and Disability Insurance)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s RSDI (Retirement, Survivor's, and Disability Insurance)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s RSDI (Retirement, Survivor's, and Disability Insurance)?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " +
                    name +
                    " SSI (Supplemental Security Income)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s SSI (Supplemental Security Income)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " +
                    name +
                    "'s SSI (Supplemental Security Income)?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " veteran's benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s veteran's benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s veteran's benefits?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " unemployment compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s unemployment compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s unemployment compensation?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " workman's compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s  workman's compensation?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s  workman's compensation?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Interest Dividends from Stock and Bonds?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Interest Dividends from Stock and Bonds?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Interest Dividends from Stock and Bonds?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]: " amount of " + name + " Military Allotment?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Military Allotment?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]: " of " + name + "'s Military Allotment?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Pension or Retirement Income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Pension or Retirement Income?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Pension or Retirement Income?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Railroad Retirement?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Railroad Retirement?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Railroad Retirement?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " HUD Utility Assistance?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s HUD Utility Assistance?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s HUD Utility Assistance?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Income from Land Rental or Rental Property?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Land Rental or Rental Property?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Land Rental or Rental Property?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Child Support?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Child Support?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Child Support?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Alimony?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Alimony?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Alimony?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " Contribution from Friends and Relatives?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Contribution from Friends and Relatives?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s Contribution from Friends and Relatives?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross income",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " other income or benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross income is the total income before anything is taken out, such as taxes or health insurance.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "gross amount",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s other income or benefits?",
                  [Props.LABEL_OBJ_HELP]:
                    "Gross Earned Income is money you receive from working before anything is taken out, such as taxes or health. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "What is the ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequency",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s other income or benefits?",
                  [Props.LABEL_OBJ_HELP]: "How often you are paid.",
                },
              },
            ],
          };
        case ES:
          return {
            heading:
              "Ingresos brutos no provenientes del trabajo de " + name + ".",
            tanfHeading: "Los ingresos no derivados del trabajo de "+name+".",
            unearnedHeading:
              "Ingresos brutos no provenientes del trabajo de " + name + ".",
            mainQuestion:
              "¿Qué tipos de ingresos y beneficios tiene " + name + "?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de SSI (Ingreso de seguridad suplementario)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " +
                    name +
                    " de SSI (Ingreso de seguridad suplementario)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " +
                    name +
                    " de SSI (Ingreso de seguridad suplementario)?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de RSDI (Seguro de jubilación, sobrevivientes y discapacidad)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " +
                    name +
                    " de RSDI (Seguro de jubilación, sobrevivientes y discapacidad)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " +
                    name +
                    " de RSDI (Seguro de jubilación, sobrevivientes y discapacidad)?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " + name + " de Desempleo?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Desempleo?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Desempleo?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de Manutención?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Manutención?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Manutención?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " + name + " de Pensión?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Pensión?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Pensión?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de Beneficios de veteranos?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Beneficios de veteranos?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Beneficios de veteranos?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de Compensación a los trabajadores?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Compensación a los trabajadores?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Compensación a los trabajadores?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " +
                    name +
                    " de Ingreso por alquiler?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Ingreso por alquiler?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                employerLabel:
                  "¿Quién es el empleador de " +
                  name +
                  " para este Ingreso por alquiler?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " del " + name + " de Ingreso por alquiler?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " monto del tipo de ingreso del " + name + " de Otro?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos son el total de los ingresos antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "monto brutos",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Otro?",
                  [Props.LABEL_OBJ_HELP]:
                    "Los ingresos brutos devengados es dinero que recibe por su trabajo antes de cualquier deducción, como impuestos o seguro médico. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "¿Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]: " del " + name + " de Otro?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " cantidad de " + name + " ¿Jubilación ferroviaria?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto es el ingreso total antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "Cantidad bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'s ¿Retiro del ferrocarril?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto del trabajo es el dinero que recibe por trabajar antes de que se deduzca algo, como impuestos o salud. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s ¿Retiro del ferrocarril?",
                  [Props.LABEL_OBJ_HELP]: "¿Con qué frecuencia te pagan?.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " cantidad de " + name + " ¿Asistencia de servicios públicos de HUD?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto es el ingreso total antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "Cantidad bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'¿Asistencia de servicios públicos de HUD?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto del trabajo es el dinero que recibe por trabajar antes de que se deduzca algo, como impuestos o salud. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'¿Asistencia de servicios públicos de HUD?",
                  [Props.LABEL_OBJ_HELP]: "¿Con qué frecuencia te pagan?.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " amount of " + name + " ¿Ingresos por alquiler de terrenos o propiedades en alquiler?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto es el ingreso total antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "Cantidad bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'¿Alquiler de terrenos o propiedad de alquiler?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto del trabajo es el dinero que recibe por trabajar antes de que se deduzca algo, como impuestos o salud. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'¿Alquiler de terrenos o propiedad de alquiler?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " cantidad de " + name + " ¿Manutención de los hijos?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto es el ingreso total antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "Cantidad bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'¿Manutención de menores?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto del trabajo es el dinero que recibe por trabajar antes de que se deduzca algo, como impuestos o salud. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'¿Manutención de menores?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " cantidad de " + name + " ¿Pensión alimenticia?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto es el ingreso total antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "Cantidad bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'¿Alimentación?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto del trabajo es el dinero que recibe por trabajar antes de que se deduzca algo, como impuestos o salud. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'¿Alimentación?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " cantidad de " + name + " ¿Contribución de amigas y familiares?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto es el ingreso total antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "Cantidad bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'s ¿Contribución de amigas y familiares?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto del trabajo es el dinero que recibe por trabajar antes de que se deduzca algo, como impuestos o salud. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " of " + name + "'s ¿Contribución de amigas y familiares?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "ingresos brutos",
                  [Props.LABEL_OBJ_AFTER]:
                    " cantidad de " + name + " ¿Otros ingresos o beneficios?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto es el ingreso total antes de deducir nada, como impuestos o seguro médico.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "Cantidad bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'¿Hay otros ingresos o beneficios?",
                  [Props.LABEL_OBJ_HELP]:
                    "El ingreso bruto del trabajo es el dinero que recibe por trabajar antes de que se deduzca algo, como impuestos o salud.",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Cuál es el ",
                  [Props.LABEL_OBJ_TRIGGER]: "frecuencia",
                  [Props.LABEL_OBJ_AFTER]:
                    " de " + name + "'¿Hay otros ingresos o beneficios?",
                  [Props.LABEL_OBJ_HELP]: "Con qué frecuencia le pagan.",
                },
              },
            ],
          };
        case PT:
          return {
            heading:
              "Rendimento bruto não proveniente de trabalho de " + name + ".",
            unearnedHeading: "Rendimento bruto de " + name + ".",
            mainQuestion:
              "Que tipo(s) de rendimentos e benefícios " + name + " tem?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " +
                    name +
                    " de SSI (Renda de segurança suplementar)?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto ",
                  [Props.LABEL_OBJ_AFTER]:
                    "de SSI (Renda de segurança suplementar) de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do SSI (Renda de segurança suplementar) de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " +
                    name +
                    " de RSDI (Seguro de aposentadoria, sobreviventes e deficiências)?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]:
                    " de RSDI (Seguro de aposentadoria, sobreviventes e deficiências) de " +
                    name +
                    "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do RSDI (Seguro de aposentadoria, sobreviventes e deficiências) de " +
                    name +
                    "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " + name + " de Desemprego?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]: " de Desemprego de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]: " do Desemprego de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " + name + " de Suporte infantil?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]:
                    " de Suporte infantil de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do Suporte infantil de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " + name + " de Pensão?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]: " de Pensão de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]: " do Pensão de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " +
                    name +
                    " de Benefício de veterano?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]:
                    " de Benefício de veterano de" + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do Benefício de veterano de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " +
                    name +
                    " de Compensação do trabalhador?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]:
                    " de Compensação do trabalhador de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do Compensação do trabalhador de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " + name + " de Renda de aluguel?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]:
                    " de Renda de aluguel de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                employerLabel:
                  "Quem é o empregador de " +
                  name +
                  " para estes Renda de aluguel?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]:
                    " do Renda de aluguel de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor da renda bruta",
                  [Props.LABEL_OBJ_AFTER]:
                    " do tipo de receita de " + name + " Outros?",
                  [Props.LABEL_OBJ_HELP]:
                    "A renda bruta é a renda total recebida antes que ocorra qualquer desconto, como impostos ou seguro saúde.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é o ",
                  [Props.LABEL_OBJ_TRIGGER]: "valor bruto",
                  [Props.LABEL_OBJ_AFTER]: " de Outros de " + name + "?",
                  [Props.LABEL_OBJ_HELP]:
                    "Renda bruta realizada é o dinheiro que você recebe do trabalho antes de qualquer coisa ser descontada, como impostos ou seguro de saúde ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Qual é a ",
                  [Props.LABEL_OBJ_TRIGGER]: "frequência",
                  [Props.LABEL_OBJ_AFTER]: " do Outros de " + name + "?",
                  [Props.LABEL_OBJ_HELP]: "Com que frequência você é pago",
                },
              },
            ],
          };
        case ZH:
          return {
            heading: name + "的非劳动所得总收入",
            unearnedHeading: name + "的非劳动所得总收入",
            mainQuestion: name + " 有哪些类型的收入和福利？",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " + name + " SSI（补充保障收入）?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的SSI（补充保障收入）的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的SSI（补充保障收入）的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " +
                    name +
                    " RSDI（退休，幸存者和残疾保险）?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]:
                    name + "的RSDI（退休，幸存者和残疾保险）的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]:
                    name + "的RSDI（退休，幸存者和残疾保险）的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入类型金额 " + name + " 失业?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的失业的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的失业的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " + name + " 子女抚养费?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的子女抚养费的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的子女抚养费的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入类型金额 " + name + " 养老金?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的养老金的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的养老金的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " + name + " 退伍军人补贴?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的退伍军人补贴的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的退伍军人补贴的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " + name + " 工伤赔偿其?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工伤赔偿其的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的工伤赔偿其的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]:
                    " 收入类型金额 " + name + " 贴租金收?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的贴租金收的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的贴租金收的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
                employerLabel: name + "的贴租金收雇主是谁？",
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "{姓名}收入类型的 ",
                  [Props.LABEL_OBJ_TRIGGER]: "收入总额",
                  [Props.LABEL_OBJ_AFTER]: " 收入类型金额 " + name + " 其他?",
                  [Props.LABEL_OBJ_HELP]:
                    "总收入是扣除诸如税金或医疗保险等项目之前的总收入。",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的其他的",
                  [Props.LABEL_OBJ_TRIGGER]: "总金额",
                  [Props.LABEL_OBJ_AFTER]: " ?",
                  [Props.LABEL_OBJ_HELP]:
                    "劳动所得收入总额是您工作所得金钱（未扣除任何费用，例如税或健康保险） ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: name + "的其他的",
                  [Props.LABEL_OBJ_TRIGGER]: "收入频率",
                  [Props.LABEL_OBJ_AFTER]: " 是多少?",
                  [Props.LABEL_OBJ_HELP]: "您多久收到一次付款",
                },
              },
            ],
          };
        case VI:
          return {
            heading: "Tổng thu nhập chưa thu của " + name + ".",
            unearnedHeading: "Tổng thu nhập đã thu của " + name + ".",
            mainQuestion:
              "(Những) loại thu nhập và lợi ích mà " + name + " có?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " SSI (Thu Nhập An Sinh Bổ Sung)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của SSI (Thu Nhập An Sinh Bổ Sung) của " +
                    name +
                    " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của SSI (Thu Nhập An Sinh Bổ Sung) của " +
                    name +
                    " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " +
                    name +
                    " RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật) của " +
                    name +
                    " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của RSDI (Bảo hiểm Hưu Trí, Người Sống sót và Khuyết Tật) của " +
                    name +
                    " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Thất nghiệp?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Thất nghiệp của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Thất nghiệp của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Trợ cấp nuôi con?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Trợ cấp nuôi con của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Trợ cấp nuôi con của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Lương hưu?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Lương hưu của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Lương hưu của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Trợ cấp cho cựu chiến binh?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Trợ cấp cho cựu chiến binh của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Trợ cấp cho cựu chiến binh của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Bồi thường lao động?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Bồi thường lao động của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Bồi thường lao động của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Thu nhập cho thuê?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Thu nhập cho thuê của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Thu nhập cho thuê của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
                employerLabel:
                  "Ai là chủ nhân của " + name + " cho này Thu nhập cho thuê?",
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Là gì ",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng thu nhập",
                  [Props.LABEL_OBJ_AFTER]:
                    " số tiền của " + name + " Những chi phí khác?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng số lợi tức trước khấu trừ là tổng số lợi tức trước khi bất cứ phần tiền nào bị trừ ra, thí dụ như thuế hoặc lệ phí bảo hiểm y tế.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tổng số tiền ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Những chi phí khác của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]:
                    "Tổng thu nhập chưa kiếm được là số tiền bạn nhận được từ một nguồn khác ngoài việc làm trước khi trừ bất cứ khoản nào, chẳng hạn như thuế hoặc bảo hiểm y tế",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "",
                  [Props.LABEL_OBJ_TRIGGER]: "Tần số ",
                  [Props.LABEL_OBJ_AFTER]:
                    " của Những chi phí khác của " + name + " là gì?",
                  [Props.LABEL_OBJ_HELP]: "Quý vị được trả thường xuyên ra sao",
                },
              },
            ],
          };
        case HI:
          return {
            heading: name + "revni brit ou pa fè nan travay",
            unearnedHeading: name + "revni brit ou pa fè nan travay",
            mainQuestion: "Ki tip revni ak benefis " + name + " genyen?",
            subQuestions: [
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " SSI (Revni Sekirite Siplemantè)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " SSI (Revni Sekirite Siplemantè)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " SSI (Revni Sekirite Siplemantè)?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " +
                    name +
                    " RSDI (Asirans Retrèt, Sivivan ak Andikap)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " +
                    name +
                    " RSDI (Asirans Retrèt, Sivivan ak Andikap)?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " +
                    name +
                    " RSDI (Asirans Retrèt, Sivivan ak Andikap)?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " chomaj?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " chomaj?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " chomaj?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " sipò timoun?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " sipò timoun?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " sipò timoun?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]: " revni brit " + name + " pansyon?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + "'s pansyon?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + "'s pansyon?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " benefis pou veteran?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " benefis pou veteran?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " benefis pou veteran?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " Konpansasyon Travayè?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " Konpansasyon Travayè?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " Konpansasyon Travayè?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " revni lwaye?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " revni lwaye?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                employerLabel:
                  "Who is " + name + "'s employer for these rental income?",
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]: " nan " + name + " revni lwaye?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
              {
                [Props.LABEL]: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan",
                  [Props.LABEL_OBJ_AFTER]:
                    " revni brit " + name + " lòt revni oswa benefis?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni brit se revni total anvan yo retire anyen, tankou taks oswa asirans sante.",
                },
                amountLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "montan brit",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " lòt revni oswa benefis?",
                  [Props.LABEL_OBJ_HELP]:
                    "Revni Brit ou touche se lajan ou resevwa nan travay anvan yo retire nenpòt bagay, tankou taks oswa asirans sante. ",
                },
                unitLabel: {
                  [Props.LABEL_OBJ_BEFORE]: "Ki ",
                  [Props.LABEL_OBJ_TRIGGER]: "frekans",
                  [Props.LABEL_OBJ_AFTER]:
                    " nan " + name + " lòt revni oswa benefis?",
                  [Props.LABEL_OBJ_HELP]: "Konbyen fwa yo peye w.",
                },
              },
            ],
          };
        default:
      }
    }

    case languageConstants.childSupportDynamic: {
      switch (language) {
        case EN:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion:
              "What is the child support expense for " + name + "?",
          };
        case ES:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion:
              "¿Para qué es el gasto de manutención infantil?" + name + "?",
          };
        case PT:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion:
              "What is the child support expense for " + name + "?",
          };
        case ZH:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion:
              "What is the child support expense for " + name + "?",
          };
        case VI:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion:
              "What is the child support expense for " + name + "?",
          };
        case HI:
          return {
            heading: "Child Support Expenses Summary",
            mainQuestion:
              "What is the child support expense for " + name + "?",
          };
        default:
          return;
      }
    }

    case languageConstants.shelterAndutility: {
      switch (language) {
        case EN:
        return {
          shelterAndUtilityMenuTitle: "Shelter & Utility Expenses",
          shelterAndUtilityHeading: "Shelter & Utility Expenses Summary",
          rentTargetValue: "RENT",
          mortgageTargetValue: "MTGPRN",
          propInsTargetValue: "PRPINS",
          propTaxTargetValue: "PRPTAX",
          secondMortgageTargetValue: "SECMTG",
          homelessExpTargetValue: "HOMEXP",
          shelterExpenseLabel : "Shelter Expenses",
          utilityExpensesLabel: "Utility Expenses",
          shelterSubTitle:"Please include expenses that you are responsible for paying rent even if you are behind or not able to pay them.",
          utilitySubTitle: "Please tell us all the utilities you are responsible for paying, even if we already have them on file. You may include expenses even if you are behind or not able to pay them.",
          homeEquityTargetValue: "HOMEEQ",
          condoTargetValue: "CONFEE",
          houseOwnerTargetValue: "HOASFE",
          otherTargetValue: "OTHER",
          rentQuestion: "How much is your household's rent?",
          mortageQuestion: "How much is your household's mortage?",
          propInsQuestion: "How much is your household's property insurance?",
          propTaxQuestion: "How much is your household's property taxes?",
          secMortgageQuestion: "How much is your household's second mortage?",
          homelessQuestion: "How much is your household's homeless expenses?",
          equityLoanQuestion: "How much is your household's home equity loan?",
          condoQuestion: "How much is your household's condo fee?",
          houseAssQuestion:
            "How much is your household's house owner association fees?",
          otherQuestion: "How much is your household's other?",
          rentErrMsg: "Please enter rent",
          mortageErrMsg: "Please enter mortage",
          propInsErrMsg: "Please enter property insurance",
          propTaxErrMsg: "Please enter property taxes",
          secondMortgageErrMsg: "Please enter second mortage",
          homelessExpErrMsg: "Please enter homeless expenses",
          homeEquityErrMsg: "Please enter home equity loan",
          condoErrMsg: "Please enter condo fee",
          houseOwnerErrMsg: "Please enter house owner association fees",
          otherErrMsg: "Please enter other amount",
          perLabel: "Per",
          anotherRentLabel: "+ Add another rent",
          anothermortgageLabel: "+ Add another mortage",
          anotherpropInsLabel: "+ Add another property insurance",
          anotherpropTaxLabel: "+ Add another property taxes",
          anothersecondMortgageLabel: "+ Add another second mortage",
          anotherhomelessExpLabel: "+ Add another homeless expenses",
          anotherhomeEquityLabel: "+ Add another home equity loan",
          anothercondoLabel: "+ Add another condo fee",
          anotherhouseOwnerLabel: "+ Add another house owner association fees",
          anotherOtherLabel: "+ Add another other amount",
          houseUnitOptions: ["Week", "Month", "Year"],
          rent: "Rent",
          mortgage: "Mortgage",
          propertyTaxes: "Property Taxes",
          homeInsurance: "Home Insurance",
          condoFee: "Condo Fee",
          propertyInsurance: "Property Insurance",
          secondMortage: "Second Mortgage",
          homelessExpenses: "Homeless Expenses",
          homeEquityLoan: "Home Equity Loan",
          homeOwnerAssociationFees: "Homeowner Association Fees",
          other: "Other",
          week: "Weekly",
          quarter: "Quarter",
          month: "Monthly",
          year: "Yearly",
          weekly: "Week",
          monthly: "Month",
          yearly: "Yearly",
          semanl: "Semi-yearly",
          biweek: "Biweekly",
          onetime: "One-time only",
          bimonthly: "Bimonthly",
          semimonthly: "Semimonthly",
          //utility
          heatTargetValue: "payHeat",
          eletricityTargetValue: "payAC",
          elgasTargetValue: "elgas",
          phoneTargetValue: "hasPhone",
          waterTargetValue: "water",
          garbageTargetValue: "garbage",
          energyTargetValue: "liheap",
          heading: "Shelter & Utility",
          heatOil: "Heat (oil, gas, electricity or propane, etc.)",
          electricityairConditioner: "Electricity for an air conditioner",
          feeairConditioner: "A fee to use an air conditioner",
          electricityGas: "Electricity and/or gas",
          cellPhoneService: "Phone or cell phone service",
          none: "None",
          noneLable:"I do not pay for any utility expenses",
          waterAndSewage: "Water and Sewage",
          garbage: "Garbage",
          energyAssistance: "Energy Assistance (LIHEAP)",
        };
      case ES:
        return {
          shelterAndUtilityMenuTitle: "Refugio y utilidad",
          shelterAndUtilityHeading: "Resumen de gastos de alojamiento y servicios públicos",
          rentTargetValue: "RENT",
          mortgageTargetValue: "MTGPRN",
          propInsTargetValue: "PRPINS",
          propTaxTargetValue: "PRPTAX",
          secondMortgageTargetValue: "SECMTG",
          homelessExpTargetValue: "HOMEXP",
          homeEquityTargetValue: "HOMEEQ",
          shelterExpenseLabel : "Gastos de alojamiento",
          utilityExpensesLabel: "Gastos de servicios públicos",
          shelterSubTitle:"Incluya los gastos de los cuales usted es responsable de pagar el alquiler, incluso si está atrasado o no puede pagarlos.",
          utilitySubTitle: "Indíquenos todos los servicios públicos que usted es responsable de pagar, incluso si ya los tenemos archivados. Puede incluir gastos incluso si está atrasado o no puede pagarlos.",
          condoTargetValue: "CONFEE",
          houseOwnerTargetValue: "HOASFE",
          otherTargetValue: "OTRO",
          rentQuestion: "¿A cuánto asciende el alquiler de su hogar?",
          mortageQuestion: "¿A cuánto asciende la hipoteca de su hogar?",
          propInsQuestion: "¿A cuánto asciende el seguro de propiedad de su hogar?",
          propTaxQuestion: "¿A cuánto ascienden los impuestos a la propiedad de su hogar?",
          secMortgageQuestion: "¿A cuánto asciende la segunda hipoteca de su hogar?",
          homelessQuestion: "¿A cuánto ascienden los gastos de personas sin hogar de su hogar?",
          equityLoanQuestion: "¿A cuánto asciende el préstamo con garantía hipotecaria de su hogar?",
          condoQuestion: "¿A cuánto asciende la tarifa de condominio de su hogar?",
          houseAssQuestion:
            "¿A cuánto ascienden las cuotas de la asociación de propietarios de su hogar?",
          otherQuestion: "¿Cuánto cuesta el otro de su hogar?",
          rentErrMsg: "Por favor ingrese el alquiler",
          mortageErrMsg: "Por favor ingrese la hipoteca",
          propInsErrMsg: "Por favor ingrese seguro de propiedad",
          propTaxErrMsg: "Por favor ingrese los impuestos a la propiedad",
          secondMortgageErrMsg: "Por favor introduzca segunda hipoteca",
          homelessExpErrMsg: "Por favor ingrese los gastos de personas sin hogar",
          homeEquityErrMsg: "Por favor ingrese préstamo sobre el valor líquido de la vivienda",
          condoErrMsg: "Por favor ingrese la tarifa del condominio",
          houseOwnerErrMsg: "Por favor, introduzca las tarifas de la asociación de propietarios de viviendas.",
          otherErrMsg: "Por favor ingresa otra cantidad",
          perLabel: "Por",
          anotherRentLabel: "+ Añadir otro alquiler",
          anothermortgageLabel: "+ Añadir otra hipoteca",
          anotherpropInsLabel: "+ Agregar otro seguro de propiedad",
          anotherpropTaxLabel: "+ Agregar otros impuestos a la propiedad",
          anothersecondMortgageLabel: "+ Añadir otra segunda hipoteca",
          anotherhomelessExpLabel: "+ Agregue otro gasto para personas sin hogars",
          anotherhomeEquityLabel: "+ Agregar otro préstamo sobre el valor líquido de la vivienda",
          anothercondoLabel: "+ Agregar otra tarifa de condominio",
          anotherhouseOwnerLabel: "+ Agregar otras tarifas de asociación de propietarios de casas",
          anotherOtherLabel: "+ Añade otra cantidad",
          houseUnitOptions: ["Semana", "Mes", "Año"],
          rent: "Alquilar",
          mortgage: "Hipoteca",
          propertyTaxes: "Impuestos de propiedad",
          homeInsurance: "Seguro de hogar",
          condoFee: "Tarifa de condominio",
          propertyInsurance: "Seguro de propiedad",
          secondMortage: "Segunda hipoteca",
          homelessExpenses: "Gastos para personas sin hogar",
          homeEquityLoan: "Préstamo con garantía hipotecaria",
          homeOwnerAssociationFees: "Tarifas de la asociación de propietarios",
          other: "Otro",
          week: "Semanalmente",
          quarter: "Cuarto",
          month: "Mensual",
          year: "Anual",
          weekly: "Semana",
          monthly: "Mes",
          yearly: "Anual",
          semanl: "Semestral",
          biweek: "Quincenal",
          onetime: "Solo una vez",
          bimonthly: "Bimensual",
          semimonthly: "Semi mensual",
          //utility
          heatTargetValue: "pagarCalor",
          eletricityTargetValue: "pagarAC",
          elgasTargetValue: "vivido",
          phoneTargetValue: "tiene teléfono",
          waterTargetValue: "agua",
          garbageTargetValue: "basura",
          energyTargetValue: "montón",
          heading: "Refugio y utilidad",
          heatOil: "Calor (petróleo, gas, electricidad o propano, etc.)",
          electricityairConditioner: "Electricidad para un aire acondicionado.",
          feeairConditioner: "Una tarifa por usar un aire acondicionado.",
          electricityGas: "Electricidad y/o gas",
          cellPhoneService: "Servicio de telefonía o celular",
          none: "Ninguno",
          noneLable:"I do not pay for any utility expenses",
          waterAndSewage: "Agua y Alcantarillado",
          garbage: "Basura",
          energyAssistance: "Asistencia Energética (LIHEAP)",
        };
      default:
        return;
      }
    }

    case languageConstants.signAndSubmitSummary: {
      switch (language) {
        case EN:
          return {
            rightsText: "Rights, Responsibilities and Penalties",
            referenceNumberText: "Your SNAP Six-Month Report Reference Number: ",
            downloadBeforeText: "You may want to",
            downloadAfterText: "for future reference.",
            downloadLinkText: "download your completed SNAP Six-Month Report",
            textbeforeHere: "If you cannot submit your verification online. Click here",
            textHere: "here",
            summaryList1: "The ACES DHR Self-Service Portal allows for documents to be uploaded as an attachment. If you reported  a changes in your income, child support paid, or new rent or mortgage you can upload and submit your verification",
            summaryListExample: "Examples of documents that could be submitted as verification are as follows:",
            summaryListExample1: " Check Stubs, Employer Statement of Income, Income Award Letters, Written Statements from People who give you money",
            summaryListExample2: " New lease or mortgage papers",
            summaryListExample3: " Court order child support payment, statement, or receipt",
            summaryList2: "If verification is needed and you are unable to upload and submit your documents, you can either mail, fax, e-mail or take the verification to the DHR SNAP Office in the",
            summaryCountryLive: "county where you live.",
            summaryExit: "Exit",
            householdBoldMovedText: "You may submit your verification documents using the following methods:",
            householdMovedText:
              "You have reported that your household has moved. You can report your new housing costs ",
            houseHoldMoveLinkText: "here",
            earnedIncomeChangedText:
              "You have reported that your household’s gross earned income has changed by more than $100 per month. Send us proof of income for the last four weeks, such as:",
            earnedIncomeSteps: [
              "Paystubs or record of payment",
              "Proof of any pay you got and hours worked",
              "Completed tax forms with all schedules attached, if self-employed",
            ],
            unearnedIncomeChangedText:
              "You have reported that your household’s gross unearned income has changed by more than $100 per month. Send us proof of the monthly amount, such as: ",
            unearnedIncomeSteps: [
              "Benefit or award letter",
              "Statement from agency making payments",
            ],
            uneanrednoteText: "NOTE",
            uneanrednoteBulletDetailText:
              "You have reported that your household’s child support expense has changed. To verify child support payments, send us verification of the legal obligation to pay the child support (such as a court order) and proof of recent payments",
            uneanrednoteDetailText:
              "We can usually verify the amount of Social Security, SSI, DOR Child Support, or MA Unemployment Compensation benefits ourselves.  We will tell you if you need to verify any of these items.",

            expensesTitle: "Expenses can increase SNAP benefits",
            expensesQuestion: "Do you have expenses to declare?",
            expensesBtnLabel: "Self-declare expenses",
            sendDocumentBtnLabel: "Send a document to DHR",
            dontSendDocumentBtnLabel: "Or exit without uploading any documents",
            otherDocWays:
              "There are many other ways to submit documents to us.",
            findMoreText: "Find out more",
            rightsHHRemovedText:
              "Important: Clicking submit does not start the Interim Report process. DHR must speak with you to confirm changes.",
          };
        case ES:
          return {
            referenceNumberText: "Su número de referencia: ",
            Shelterandutilityexplabel: "Gastos de alojamiento y servicios públicos",
            shelterAndUtilityHeading: "Resumen de gastos de alojamiento y servicios públicos",
            downloadBeforeText: "Puede",
            summaryList1: "El portal de autoservicio de ACES DHR permite cargar documentos como archivos adjuntos. Si informó cambios en sus ingresos, manutención infantil pagada o nuevo alquiler o hipoteca, puede cargar y enviar su verificación.",
            summaryListExample: "Ejemplos de documentos que podrían presentarse como verificación son los siguientes:",
            summaryListExample1: " Talones de cheques, declaración de ingresos del empleador, cartas de concesión de ingresos, declaraciones escritas de personas que le dan dinero",
            summaryListExample2: " Nuevos papeles de arrendamiento o hipoteca",
            summaryListExample3: " Orden judicial para pago, declaración o recibo de manutención infantil",
            summaryList2: "Si se necesita verificación y no puede cargar y enviar sus documentos, puede enviar la verificación por correo, fax, correo electrónico o llevarla a la Oficina SNAP del DHR en el",
            summaryCountryLive: "condado donde vives.",
            summaryExit: "Exit",
            householdBoldMovedText: "Puede enviar sus documentos de verificación utilizando los siguientes métodos:",
            downloadAfterText: "para referencia futura",
            uneanrednoteBulletDetailText:
              "Usted ha comunicado que los gastos de manutención infantil de su hogar han cambiado.. Para verificar costos médicos, envíenos un comprobante del costo, como una factura o un recibo. Los costos de transporte por motivos médicos se pueden autodeclarar.",
            downloadLinkText: "descargar su Informe provisorio completo",
            householdMovedText:
              "Usted informó que se mudó de vivienda. Puede informar los nuevos costos de vivienda aquí  ",
            houseHoldMoveLinkText: "aquí",
            earnedIncomeChangedText:
              "Usted informó que los ingresos brutos provenientes del trabajo del grupo familiar cambiaron más de $100/mes. Envíenos una constancia de ingresos de las últimas cuatro semanas, como:",
            earnedIncomeSteps: [
              "Recibos de pago o registro de pago",
              "Comprobante de cualquier pago que haya recibido y las horas trabajadas",
              "Formularios tributarios completos con todas las escalas adjuntas, si es trabajador/a independiente",
            ],
            unearnedIncomeChangedText:
              "Usted informó que los ingresos brutos no provenientes del trabajo del grupo familiar cambiaron más de $100/mes. Envíenos un comprobante del monto mensual, como: ",
            unearnedIncomeSteps: [
              "Carta de concesión o beneficio",
              "Declaración de la agencia que realiza los pagos",
            ],
            uneanrednoteText: "Aclaración",
            uneanrednoteDetailText:
              "Generalmente podremos verificar nosotros mismos los montos de los beneficios del Seguro social, SSI, manutención infantil DOR o Compensación por desempleo de MA. Le informaremos si necesita verificar alguno de estos puntos.",
            rightsText: "Derechos, Responsabilidades y Penalidades",
            expensesTitle:
              "Los gastos pueden aumentar los beneficios del SNAP.",
            expensesQuestion: "¿Tienes gastos para declarar?",
            expensesBtnLabel: "Autodeclaración de gastos",
            sendDocumentBtnLabel: "Enviar documento a DHR",
            dontSendDocumentBtnLabel: "O salir sin subir documentos.",
            otherDocWays: "Hay muchas otras maneras de presentar documentos.",
            findMoreText: "Obtenga más información.",
            rightsHHRemovedText:
              "Importante: Hacer clic en Presentar no inicia el proceso del Informe provisorio. DHR debe hablar con usted para confirmar los cambios.",
          };
        case PT:
          return {
            referenceNumberText: "Seu número de referência: ",
            downloadBeforeText: "Você pode",
            rightsText: "Direitos, Responsabilidades e Penalidades",
            downloadAfterText: "para referência futura",
            downloadLinkText: "baixar seu Relatório interino preenchido",
            householdMovedText:
              "Você relatou que seu domicílio mudou. Você pode relatar seus novos gastos domiciliares aqui. ",
            houseHoldMoveLinkText: "aqui",
            earnedIncomeChangedText:
              "Você relatou que o rendimento bruto do seu domicílio foi alterado em mais de US $100 por mês. Envie-nos comprovantes de renda das últimas quatro semanas, como:",
            earnedIncomeSteps: [
              "Comprovantes ou registro de pagamento",
              "Comprovante de qualquer pagamento que você recebeu e horas trabalhadas",
              "Formulários de impostos preenchidos com todos os horários anexados, se autônomo",
            ],
            unearnedIncomeChangedText:
              "Você relatou que o rendimento bruto do seu domicílio foi alterado em mais de $100 por mês. Envie-nos um comprovante do valor mensal, como: ",
            unearnedIncomeSteps: [
              "Carta de benefícios ou gratificações",
              "Declaração da agência que faz pagamentos",
            ],
            uneanrednoteText: "Nota",
            uneanrednoteDetailText:
              "Geralmente, podemos verificar a quantia de benefícios de Seguro Social, SSI, DOR Child Support ou MA Unemployment Compensation nós mesmos. Nós informaremos se você precisar verificar algum desses itens.",
            uneanrednoteBulletDetailText:
              "Você informou que os gastos infantis na sua casa mudaram. Para verificar costos médicos, envíenos un comprobante del costo, como una factura o un recibo. Los costos de transporte por motivos médicos se pueden autodeclarar.",
            expensesTitle: "Despesas podem aumentar os benefícios do SNAP",
            expensesQuestion: "Você tem despesas para declarar?",
            expensesBtnLabel: "Auto-declarar despesas",
            sendDocumentBtnLabel: "Envie um documento para o DHR",
            dontSendDocumentBtnLabel:
              "Ou saia sem fazer o upload de nenhum documento",
            otherDocWays:
              "Existem muitas outras maneiras de enviar documentos para nós",
            findMoreText: " Saiba mais.",
            rightsHHRemovedText:
              "Importante: Clicar em enviar não inicia o processo do Relatório interino. O DHR deve falar com você para confirmar as alterações.",
          };

        case ZH: //chinees
          return {
            referenceNumberText: "您的参考编号：",
            downloadBeforeText: "您可能想要",
            downloadAfterText: "以供未来参考。",
            rightsText: "权利，责任和惩罚",
            downloadLinkText: "下载您填写完成的《中间报告》",
            householdMovedText:
              "您报告说您的家庭已搬迁。您可以在此处报告您的新住房费用 ",
            houseHoldMoveLinkText: "此处",
            earnedIncomeChangedText:
              "您报告说您的家庭劳动所得总收入变动超过每个月 $100 美元。请向我们寄送过去四周的收入证据，例如：",
            earnedIncomeSteps: [
              "工资单或付款记录",
              "您得到的任何付款和工时证据",
              "填写好的纳税申请书（附上所有税率表）（如果您为自雇）",
            ],
            unearnedIncomeChangedText:
              "您报告说您的家庭非劳动所得总收入变动超过每个月 $100 美元。请向我们寄送每月收入金额的证据，例如：",
            unearnedIncomeSteps: ["福利或授予函", "付款机构提供的声明"],
            uneanrednoteText: "注意",
            uneanrednoteBulletDetailText:
              "您已报告您家庭中的子女抚养费有所变化这一情况 如需验证医疗费用，请向我们寄送费用证据，例如账单、发票或收据。出于医疗原因的交通费用可自行声明。",
            uneanrednoteDetailText:
              "我们可以经常自己验证社会保障、SSI、DOR 子女抚养费或 MA 失业赔偿福利的金额。如果您需要验证任何此项，我们会告知您相关结果。",

            expensesTitle: "费用开支可以增加 SNAP 福利",
            expensesQuestion: "您是否有费用需要申报？",
            expensesBtnLabel: "自行声明的费用",
            sendDocumentBtnLabel: "向 DHR 寄送文件",
            dontSendDocumentBtnLabel: "或退出而不上传任何资料",
            otherDocWays: "向我们提交文件可通过很多其他方式。",
            findMoreText: "了解更多",
            rightsHHRemovedText:
              "重要提示: 点击提交并未开始《中间报告》流程。DHR 必须与您交谈从而确认更改。",
          };
        case VI:
          return {
            referenceNumberText: "Số tham chiếu của quý vị: ",
            downloadBeforeText: "Quý vị có thể muốn",
            downloadAfterText: "để tham khảo trong",
            rightsText: "Quyền, Trách nhiệm & Hình phạt",
            downloadLinkText:
              " tải xuống Báo cáo giữa kỳ đã hoàn thành của mình",
            householdMovedText:
              "Quý vị đã báo cáo rằng hộ gia đình của quý vị đã chuyển nhà. Quý vị có thể báo cáo chi phí nhà ở mới của mình ở đây. ",
            houseHoldMoveLinkText: "đây",
            earnedIncomeChangedText:
              "Quý vị đã báo cáo rằng tổng thu nhập đã thu được trong gia đình của quý vị đã thay đổi trên $100 Đô la mỗi tháng. Vui lòng gửi cho chúng tôi bằng chứng về thu nhập trong bốn tuần qua, ví dụ như:",
            earnedIncomeSteps: [
              "Bảng kê thu nhập hoặc hồ sơ thanh toán",
              "Bằng chứng về bất kỳ khoản lương nào quý vị nhận được và số giờ làm việc",
              "Các biểu mẫu về thuế đã được hoàn tất với tất cả các lịch đóng thuế đính kèm, nếu quý vị là chủ",
            ],
            unearnedIncomeChangedText:
              "Quý vị đã báo cáo rằng thu nhập chưa thu trong gia đình của quý vị đã thay đổi trên $100 Đô la mỗi tháng. Vui lòng gửi cho chúng tôi bằng chứng về số tiền hàng tháng, ví dụ như: ",
            unearnedIncomeSteps: [
              "Thư về lợi ích hoặc giải thưởng",
              "Báo cáo từ các cơ quan thanh toán",
            ],
            uneanrednoteText: "LƯU Ý",
            uneanrednoteDetailText:
              "Chúng tôi thường có thể tự xác minh số tiền từ các lợi ích về An sinh Xã hội, Thu Nhập An Sinh Bổ Sung (SSI), Trợ cấp nuôi con theo yêu cầu của Sở Thuế Vụ (DOR) hoặc các lợi ích về trợ cấp thất nghiệp của Massachusets. Chúng tôi sẽ cho quý vị biết nếu quý vị cần xác minh bất kỳ mục nào trong số này.",
            uneanrednoteBulletDetailText:
              "Quý vị đã báo cáo rằng số tiền cấp dưỡng cho con của gia đình của quý vị đã thay đổi. Để xác minh các chi phí y tế, xin vui lòng gửi cho chúng tôi bằng chứng của chi phí này, ví dụ như hóa đơn hoặc biên lai. Các chi phí vận chuyển vì lý do y tế có thể tự kê khai.",
            expensesTitle: "Chi phí có thể tăng các lợi ích SNAP",
            expensesQuestion: "Bạn có chi phí để khai báo?",
            expensesBtnLabel: "Chi phí tự kê khai",
            sendDocumentBtnLabel: "Gửi giấy tờ tới DHR",
            dontSendDocumentBtnLabel:
              "Hoặc thoát ra mà không cần tải lên bất kỳ giấy tờ nào",
            otherDocWays:
              "Có nhiều cách thức khác để gửi giấy tờ cho chúng tôi.",
            findMoreText: "Tìm hiểu thêm",
            rightsHHRemovedText:
              "Nhập khẩu: Việc nhấp vào nút gửi sẽ không khởi động quá trình Báo cáo giữa kỳ. DHR phải nói chuyện với quý vị để xác nhận những thay đổi.",
          };
        case HI:
          return {
            rightsText: "Dwa, Responsablite ak Sanksyon",
            referenceNumberText: "Nimewo referans ou: ",
            downloadBeforeText: "Ou ka vle",
            downloadAfterText: "pou plis referans.",
            downloadLinkText: "telechaje Rapò Pwovizwa konplè w la",
            householdMovedText:
              "Ou te rapòte ke ou chanje kay. Ou ka rapòte nouvo depans lojman kay la isit la",
            houseHoldMoveLinkText: "la a",
            earnedIncomeChangedText:
              "Ou te rapòte ke lajan ou touche avan tax te chanje pou plis pase $100 pa mwa.",
            earnedIncomeSteps: [
              "Souch chèk, oswa dosye peman",
              "Prèv nenpòt salè ou te resevwa ak konbyen lè ou te travay",
              "Ranpli fòm taks ak tout orè yo atache, si travayè-endepandan",
            ],
            unearnedIncomeChangedText:
              "Ou te rapòte ke lajan èd fanmi w touche a te chanje pou plis pase $100 pa mwa.",
            unearnedIncomeSteps: [
              "Lèt desizyon sou benefis",
              "Deklarasyon ajans k ap fè peman yo",
            ],
            uneanrednoteText: "NOTE",
            uneanrednoteBulletDetailText:
              "Ou te rapòte ke depans pou sipò timoun fwaye w la te chanje. Pou verifye peman pou sipò timoun, voye ban nou verifikasyon obligasyon legal la pou peye sipò timoun nan (tankou yon òdonans tribinal) ak prèv peman resan yo.",
            uneanrednoteDetailText:
              "Anjeneral nou ka verifye montan benefis Sekirite Sosyal, SSI, Sipò Timoun DOR, oswa MA Konpansasyon Chomaj. Nou pral di w si w bezwen verifye nenpòt nan bagay sa yo.",

            expensesTitle: "Depans yo ka ogmante benefis SNAP",
            expensesQuestion: "Èske ou gen depans pou deklare?",
            expensesBtnLabel: "Depans ou deklare oumenm ",
            sendDocumentBtnLabel: "Voye yon dokiman bay DHR",
            dontSendDocumentBtnLabel:
              "Oswa sòti san w pa telechaje okenn dokiman",
            otherDocWays: "Gen plizyè lòt fason pou soumèt dokiman ban nou.",
            findMoreText: "Jwenn plis enfòmasyon",
            rightsHHRemovedText:
              "Enpòtan: Klike sou soumèt pa kòmanse pwosesis Rapò Pwovizwa a. DHR dwe pale ak ou pou konfime chanjman yo.",
          };
        default:
          return;
      }
    }

    case languageConstants.otherInfoPage: {
      switch (language) {
        case EN:
          return {
            FE04PR:
              name +
              " is participating in training at Division of Unemployment Assistance.",
            hasChangedQuestionLabel:
              "Has there been any change in this information?",
          };
        case ES:
          return {
            hasChangedQuestionLabel:
              "¿Se han producido cambios en esta información?",
          };
        case PT:
          return {
            hasChangedQuestionLabel: "Houve alguma mudança nessas informações?",
          };

        case ZH: //chinees
          return {
            hasChangedQuestionLabel: "此信息是否有任何更改？",
          };
        case VI:
          return {
            hasChangedQuestionLabel:
              "Đã có thay đổi nào trong thông tin này không?",
          };
        case HI:
          return {
            FE04PR:
              name +
              " is participating in training at Division of Unemployment Assistance.",
            hasChangedQuestionLabel:
              "Èske gen nenpòt chanjman nan enfòmasyon sa a?",
          };
        default:
          return;
      }
    }
  }
}

export function interimWarningPopupMLanguageLabels(language, date, phone) {
  switch (language) {
    case EN:
      return {
        title: "Info",
        popupBodyTexts: [
          "You have reported a change but did not enter any information. If you have a change click “Yes”. If you do not have a change click “No”.",
          "You must complete this section. If you cannot complete this now, you may complete your SNAP Six-Month Report online later or submit the paper form.",
          "If you do not complete this process by " +
          date +
          ", your case will close. ",
          "If you need help, contact Alabama DHR at " + phone + ".",
        ],
        saveAndExitHeader: "Save and Exit Application",
        savedSuccessfully: "Saved Successfully",
        // saveExitLabel1: "This application has been saved successfully on",
        saveExitLabel1: "The SNAP Six-Month Report has been saved successfully on",
        saveExitLabel2: "at",
        saveExitLabel3:
          "and can be accessed from the home screen. It will be available for the next ",
        saveExitLabel4: " hours for you to make any changes.",
        CancelButtonLabel: "Cancel",
        saveAndExit: "Save",
        areYouSureWarningLabel: "Are you sure ?",
        saveOrExitLabel:
          "You might lose the unsaved information in the SNAP Six-Month Report. Do you want to save the information before you exit?",
        okLabel: "Ok",
        deleteWarningLabel: "WARNING!",
        deleteExitLabel:
          "The information you have entered will be permanently deleted. Are you sure you want to delete?",
        deletePopUpLabel:
          "Deleting the SNAP Six-Month Report will not affect any current services you are receiving through the Department of Human Resources.",
        CancelButtonLabel: "Cancel",
        confirmButtonLabel: "Confirm",
        deleteButtonLabel: "Delete",
        confirmDelete: "Delete",
        languageWarningLabel:"If you want to change the langauge your application will get restarted",
        areYouSureWarningLabel:"Are you sure ?"
      };
    case ES:
      return {
        title: "Información",
        languageWarningLabel:"Si desea cambiar el idioma, su aplicación se reiniciará.",
        deleteButtonLabel: "Borrar",
        CancelButtonLabel:"Cancelar",
        areYouSureWarningLabel  :"Estas segura",
        popupBodyTexts: [
          "Usted informó un cambio pero no ingresó ninguna información. Si tiene un cambio, haga clic en “Sí”. Si no tiene un cambio haga clic en “No”.",
          "Debes completar esta sección. Si no puede completar esto ahora, puede completar su Informe de seis meses de SNAP en línea más tarde o enviar el formulario impreso.",
          "Si no completa este proceso antes del " +
          date +
          ", su caso se cerrará. ",
          "Si necesita ayuda, contacte al Alabama DHR en el " + phone + ".",
        ],
        areYouSureWarningLabel: "Estas segura",
        saveAndExitHeader: "Guardar y salir de la aplicación",
        savedSuccessfully: "Guardado exitosamente",
        saveExitLabel1: "El informe semestral de SNAP se ha guardado correctamente en",
        saveExitLabel2: "en",
        saveExitLabel3:
          "y se puede acceder desde la pantalla de inicio. Estará disponible para la próxima",
        saveExitLabel4: " horas para que usted pueda realizar cualquier cambio.",
        CancelButtonLabel: "Cancelar",
        okLabel: "De acuerdo",
        deleteWarningLabel: "¡ADVERTENCIA! ",
        deleteExitLabel:
          "La información que ha ingresado se eliminará permanentemente. ¿Estas seguro que quieres borrarlo?",
        deletePopUpLabel:
          "Eliminar este informe semestral de SNAP no afectará ningún servicio actual que esté recibiendo a través del Departamento de Recursos Humanos.",
        CancelButtonLabel: "Cancelar",
        confirmButtonLabel: "Confirmar",
        deleteButtonLabel: "Borrar",
        confirmDelete: "Confirmar eliminación",
      };
    case PT:
      return {
        title: "Info",
        popupBodyTexts: [
          "Você informou que houve uma alteração, mas não digitou nada. Se realmente houve uma alteração, clique em “Sim”. Se não houve alteração, clique em “Não”",
          "É preciso preencher esta seção. Se não puder fazer isso agora, preencha o seu formulário on-line interino depois, envie o formulário de papel ou compareça a um escritório local do DHR.",
          "Se você não concluir esse processo até " +
          date +
          ", o seu caso será encerrado. ",
          "Se precisar de ajuda, entre em contato com o DHR pelo número " +
          phone +
          ".",
        ],
      };
    case ZH:
      return {
        title: "信息",
        popupBodyTexts: [
          "您报告了变更，但未输入任何信息。如果您有变更，请点击“是”。如果您没有变更，请点击“否”。",
          "必须填写此部分。如果现在无法填写，您可以稍后在线填写临时报告，提交纸质表格，或前往当地的 DHR 办公室。",
          "如果您未在 " + date + ", 之前完成这一流程，则您的案例将会关闭 ",
          "如果您需要帮助，请拨打 " + phone + " 与 DHR 联系。",
        ],
      };
    case VI:
      return {
        title: "Thông tin",
        popupBodyTexts: [
          "Quý vị đã báo cáo một sự thay đổi nhưng không nhập điều gì cả. Nếu quý vị có điều gì thay đổi, xin nhấp vào Có. Nếu quý vị không có điều gì thay đổi, xin nhấp vào 'Không'",
          "Quý vị phải điền phần này. Nếu quý vị không thể điền xong giấy này ngay bây giờ, quý vị có thể điền bản Báo cáo tạm thời trên mạng sau, gửi giấy này đi hoặc đến tại văn phòng DHR địa phương.",
          "Nếu quý vị không hoàn tất thủ tục này hạn chót là ngày " +
          date +
          ",  hồ sơ của quý vị sẽ bị đóng. ",
          "Nếu quý vị cần giúp đỡ, vui lòng liên lạc với DHR tại " +
          phone +
          ".",
        ],
      };
    case HI:
      return {
        title: "Enfòmasyon",
        popupBodyTexts: [
          'Ou te rapòte yon chanjman men ou pa t antre anyen. Si w gen yon chanjman klike sou "Wi". Si ou pa gen yon chanjman klike sou "Non"',
          "Ou dwe ranpli seksyon sa a. Si ou pa kapab fè sa kounye a, ou ka ranpli Resètifikasyon COVID ou an liy pita oswa soumèt fòm papye a.",
          "Si w pa fini pwosesis sa a anvan " +
          date +
          ", Dosye w la pral fèmen. ",
          "Si w bezwen èd, kontakte DHR nan " + phone + ".",
        ],
      };
    default:
      return {
        title: "Info",
        popupBodyTexts: [
          "You have reported a change but did not enter anything. If you have a change click “Yes”. If you do not have a change click “No”",
          "You must complete this section. If you cannot complete this now, you may complete your SNAP Six-Month Report online later or submit the paper form.",
          "If you do not complete this process by " +
          date +
          ", your case will close. ",
          "If you need help, contact Alabama DHR at " + phone + ".",
        ],
        saveAndExitHeader: "Save and Exit Application",
        saveExitLabel1: "This application has been saved successfully",
        saveExitLabel2: "",
        saveExitLabel3:
          "and can be accessed from the home screen.",
        saveExitLabel4: "",
        CancelButtonLabel: "Cancel",
        okLabel: "Ok",
        deleteExitLabel:
          "WARNING! The information you have entered will be permanently deleted. Are you sure you want to delete?",
        deletePopUpLabel:
          "Deleting this application will not affect any current services you are receiving through the Department of Human Resources.",
        CancelButtonLabel: "Cancel",
        confirmButtonLabel: "Confirm",
        deleteButtonLabel: "Delete",
        confirmDelete: "Confirm Delete",
      };
  }
}

export function selfDeclaredependentCareLabels(
  language,
  pageType,
  name,
  nextName
) {
  if (pageType == languageConstants.childSupport) {
    //6th section page
    switch (language) {
      case EN:
        return {
          depedentCostHeader: "Child or Adult Dependent Care costs for " + name,
          dependentCostsQoneLabel:
            "How much does " +
            name +
            " pay for provider care for " +
            nextName +
            ".?",
          dependentCostsQtwoLabel:
            "Does " + name + " pay for transportation for " + nextName + ".?",
          dependentCoditionalQLabel:
            "How much does " +
            name +
            " pay for transportation for " +
            nextName +
            ".?",
          driveToQLabel:
            "Does " +
            name +
            " drive " +
            nextName +
            " to and/or from the provider?",
          dependConQuestion:
            "How many providers does " +
            name +
            "  drive " +
            nextName +
            "  to? ",
          chAdultCareAddrHeader:
            name + "'s Child or Adult Dependent Care address",
          subSectionQuestionLabel:
            "How often does " +
            name +
            " drive " +
            nextName +
            " to the provider? To and from a provider should be counted as two trips.",
          childSupptcondQLabel:
            "What is the child support expense for " + name + " ?",
          depedentCostSummaryHeader: "Dependent care Expenses for",
          selfDeclareAmt: "Amount",
          transportation: "Transportation",
          careprovider: "Care provider",
        };
      case ES:
        return {
          depedentCostHeader:
            "Costos de cuidado de hijos o adultos dependientes para " + name,
          dependentCostsQoneLabel:
            "¿Cuánto paga " +
            name +
            "  por un cuidador para " +
            nextName +
            ".?",
          dependentCostsQtwoLabel:
            "¿" + name + " paga el transporte de " + nextName + " ?",
          dependentCoditionalQLabel:
            "¿Cuánto paga " + name + " por transporte para " + nextName + ".?",
          driveToQLabel:
            "¿ " +
            name +
            " transporta a " +
            nextName +
            " hacia y desde el proveedor?",
          dependConQuestion:
            "¿A cuántos proveedores " +
            name +
            "  transporta a " +
            nextName +
            "  ? ",
          chAdultCareAddrHeader:
            name + " Costos de cuidado de hijos o adultos dependientes",
          subSectionQuestionLabel:
            "¿Con qué frecuencia " +
            name +
            " lleva a " +
            nextName +
            " al proveedor? Hacia y desde un proveedor debe contarse como dos viajes.",
          childSupptcondQLabel:
            "¿Cuál es el costo de manutención para " + name + " ?",
          depedentCostSummaryHeader:
            "Costos de cuidado de hijos o adultos dependientes para",
          selfDeclareAmt: "Monto",
          transportation: "Transporte",
          careprovider: "Proveedor de cuidados",
        };
      case PT:
        return {
          depedentCostHeader:
            "Custos de cuidados para criança ou adulto dependente de " + name,
          dependentCostsQoneLabel:
            "Quanto " +
            name +
            "  paga ao prestador de serviço que cuida de " +
            nextName +
            ".?",
          dependentCostsQtwoLabel:
            "Quanto " + name + " paga pelo transporte de " + nextName + ".?",
          dependentCoditionalQLabel:
            "Quanto " + name + "  paga pelo transporte de " + nextName + " ?",
          driveToQLabel:
            name + " leva " + nextName + " até o prestador de serviço?",
          dependConQuestion:
            "A quantos prestadores de serviço " +
            name +
            "  leva" +
            nextName +
            " ?",
          chAdultCareAddrHeader: name + " Custos de crianças ou dependentes",
          subSectionQuestionLabel:
            "Com que frequência " +
            name +
            " leva " +
            nextName +
            " ao prestador de serviço? Levar e buscar conta como duas viagens",
          childSupptcondQLabel:
            "Quais são os custos de suporte infantil de " + name + " ?",
          depedentCostSummaryHeader:
            "Custos de cuidados para criança ou adulto dependente de",
          selfDeclareAmt: "Quantia",
          transportation: "Transporte",
          careprovider: "Prestador de serviços",
        };
      case ZH:
        return {
          depedentCostHeader: name + "的子女或成人照护费 ",
          dependentCostsQoneLabel:
            name + "向看护人支付多少 " + nextName + " 的照护费？",
          dependentCostsQtwoLabel:
            name + " 是否需要支付 " + nextName + " 的交通费？",
          dependentCoditionalQLabel:
            name + " 需要支付多少 " + nextName + " 的交通费？",
          driveToQLabel: name + " 是否开车接送 " + nextName + " 到看护人那里？",
          dependConQuestion:
            name + " 开车送 " + nextName + " 到多少个看护人那里？",
          chAdultCareAddrHeader: name + " 子女或成人照护费用",
          subSectionQuestionLabel:
            name +
            " 开车送" +
            nextName +
            " 到看护人那里的频率是多少？ 接送看护人的费用应计为两趟行程",
          childSupptcondQLabel: name + " 的子女抚养费是多少？",
          depedentCostSummaryHeader: "的子女或成人照护费",
          selfDeclareAmt: "金额",
          transportation: "交通",
          careprovider: "医疗服务提供者 ",
        };
      case VI:
        return {
          depedentCostHeader:
            "Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc đối với " + name,
          dependentCostsQoneLabel:
            name +
            "chi trả cho nhà cung cấp dịch vụ chăm sóc cho " +
            nextName +
            " là bao nhiêu?",
          dependentCostsQtwoLabel:
            name + " có thanh toán cho việc chuyên chở " + nextName + " không?",
          dependentCoditionalQLabel:
            name +
            " thanh toán cho việc chuyên chở cho " +
            nextName +
            " bao nhiêu?",
          driveToQLabel:
            name +
            "có lái xe đưa " +
            nextName +
            " đến và / hoặc về từ nhà cung cấp dịch vụ không?",
          dependConQuestion:
            "Có bao nhiêu nhà cung cấp dịch vụ mà " +
            name +
            "  lái xe đưa " +
            nextName +
            " đến?",
          chAdultCareAddrHeader:
            name + " Chi phí về Chăm sóc Trẻ em hoặc Người Lớn Phụ thuộc",
          subSectionQuestionLabel:
            name +
            " lái xe đưa " +
            nextName +
            " đến nhà cung cấp  bao lâu một lần? Đến và về từ chỗ của nhà cung cấp phải được tính là hai chuyến",
          childSupptcondQLabel:
            "Khoản trợ cấp nuôi con đối với " + name + " là gì?",
          depedentCostSummaryHeader:
            "Chi phí Chăm sóc Trẻ em hoặc Người lớn Phụ thuộc đối với",
          selfDeclareAmt: "số tiền",
          transportation: "Chuyên chở ",
          careprovider: "Nhà cung cấp dịch vụ chăm sóc ",
        };
      case HI:
        return {
          depedentCostHeader:
            "Depans pou swen Timoun oswa Adilt Depandan pou " + name,
          dependentCostsQoneLabel:
            "Konbyen " + name + " peye founisè swen pou " + nextName + ".?",
          dependentCostsQtwoLabel:
            "Èske " + name + "peye transpò pou " + nextName + ".?",
          dependentCoditionalQLabel:
            "Konbyen " + name + " peye founisè swen pou " + nextName + ".?",
          driveToQLabel:
            "Èske " +
            name +
            " kondi " +
            nextName +
            " pou ale ak/oswa soti lakay founisè a?",
          dependConQuestion:
            "Lakay Konbyen founisè " + name + "  kondi " + nextName + "? ",
          chAdultCareAddrHeader:
            name + " Adrès Swen pou Timoun oswa Adilt Depandan",
          subSectionQuestionLabel:
            "Konbyen fwa " +
            name +
            " kondi " +
            nextName +
            " lakay founisè a? Ale ak soti lakay yon founisè ta dwe konte kòm de vwayaj.",
          childSupptcondQLabel: "Ki depans sipò timoun " + name + " ?",
          depedentCostSummaryHeader: "Depapns pou swen depandan pou",
          selfDeclareAmt: "Montan",
          transportation: "Transpò",
          careprovider: "Founisè swen",
        };
      default:
        return;
    }
  }
}

export function termsInterimLanguageTranslation(language) {
  switch (language) {
    case languageConsts.ENGLISH:
      return {
        termsHeader: "Rights, Responsibilities and Penalties",
        headerLabel:
          "Notice of Rights, Responsibilities and Penalties – Please Read Carefully",
        certifyContents:
          "I certify that I have read, or have had read to me, the information in this application. My answers to the questions in this application are true and complete to the best of my knowledge. I also certify that information I provide to the Department during the application interview and in the future will also be true and complete to the best of my knowledge. I understand that giving false or misleading information is fraud. I also understand that misrepresenting or withholding facts to establish SNAP eligibility is fraud. This results in an Intentional Program Violation (IPV) and is punishable by civil and criminal penalties.",
        understandDta:
          "I understand that the Department of Transitional Assistance (DHR) administers SNAP. I understand that DHR has 30 days from the date of application to process my application. Further, I understand that:",
        foodNutritionact:
          "The Food and Nutrition Act of 2008 (7 U.S.C. 2011-2036) allows DHR to use my Social Security Number (SSN) and the SSN of each household member I apply for. DHR uses this information to determine my household’s eligibility for SNAP. DHR verifies this information through computer matching programs. I understand that DHR uses it to monitor compliance with program regulations.",
        reportingRulesDtachanges:
          "Most of the time, households under the SNAP Simplified Reporting rules have to tell DHR changes at Interim Report (IR)  and recertification with the exception of:",
        incomeExceeds:
          "If my household’s income exceeds the gross income threshold",
        workRequirementlabel:
          "If I am under the able-bodied adult without dependents (ABAWD) work requirements and my work hours drop below 20 hours weekly",
        verifiedInfomation:
          "If DHR receives verified information about my household, my benefit amount may change",
        tbaRulesone:
          "If I am not under the SNAP Simplified Reporting rules or Transitional Benefits Alternative (TBA) rules, I must report to DHR changes to my household that may affect our eligibility. I understand that I must report these changes to DHR in person, in writing or by phone ",
        tbaRuletwo: " within 10 days of the change.",
        tbaExample:
          " For example, you must report changes in your household’s income, size, or address.",
        findmeIneligible:
          "I have a right to speak to a supervisor if DHR finds me ineligible for emergency SNAP benefits and I disagree. I may speak to a supervisor if I am eligible for emergency SNAP benefits but do not get my benefits by the seventh calendar day after I applied for SNAP. I may speak to a supervisor if I am eligible for emergency SNAP benefits but do not get my Electronic Benefit Transfer (EBT) card by the seventh calendar day after I applied for SNAP.",
        verificationtoDta:
          "I may receive more SNAP benefits if I report and give verification to DHR of:",
        childorOtherTitile:
          "child or other dependent care costs, shelter costs, and/or utility costs",
        legallyObligatedlabel:
          "legally-obligated child support to a nonhousehold member",
        disabledMedicalcost:
          "If I am 60 years or older or if I am disabled and I pay for medical costs, I can report and give verification of these costs to DHR. This may make me eligible for a deduction and increase my SNAP benefits.",
        meetExemptionlabel:
          "Unless they meet an exemption, all SNAP recipients between the ages of 16 and 59 are work registered and subject to General SNAP Work Requirements. SNAP recipients between the ages of 18 and 49 may also be subject to the ABAWD Work Program requirements. DHR will inform nonexempt household members of the work requirements. DHR will inform nonexempt household members of exceptions and penalties for noncompliance.",
        voluntarilyParticipatelabel:
          "Most SNAP recipients may voluntarily participate in education and employment training services through the SNAP Path to Work program. DHR will give referrals to the SNAP Path to Work program if appropriate",
        dtaNamescontact:
          "DHR may also share the names and contact information of SNAP recipients with SNAP Path to Work providers for recruitment purposes. I understand that members of my household may be contacted by DHR SNAP Path to Work specialists or contracted providers to explore SNAP Path to Work participation options. For more information about the SNAP Path to Work program, visit ",
        subjectToverification:
          "I understand that the information I give with my application will be subject to verification to determine if it is true. If any information is false, DHR may deny my SNAP benefits. I may also be subject to criminal prosecution for providing false information.",
        signingApplication:
          "I understand that by signing this application I give DHR permission to verify and investigate the information I give that relates to my eligibility for SNAP benefits, including permission to:",
        proveInformation:
          "Get documents to prove information on this application with other state agencies, federal agencies, local housing authorities, out-of-state welfare departments, financial institutions and from Equifax Workforce Solutions. I also give permission to these agencies to give DHR information about my household that concerns my SNAP benefits.",
        immigrationStatusLabel:
          "If applicable, verify my immigration status through the United States Citizenship and Immigration Services (USCIS). I understand that DHR may check information from my SNAP application with USCIS. Any information received from USCIS may affect my household’s eligibility and amount of SNAP benefits. ",
        secondaryEducation:
          "Share information about me and my dependents under age 19 with the Department of Elementary and Secondary Education (DESE). DESE will certify my dependents for school breakfast and lunch programs. ",
        publicHealth:
          "Share information about me, my dependents under age 5 and anyone pregnant in my household with the Department of Public Health (DPH). DPH refers these individuals to the Women,  Infants and Children (WIC) Program for nutrition services.",
        executiveOfficeHealth:
          "Share information, along with the Alabama Executive Office of Health and Human Services, about my eligibility for SNAP with electric companies, gas companies and eligible phone and cable carriers to certify my eligibility for discount utility rates.",
        housingCommuntiy:
          "Share my information with the Department of Housing and Community Development (DHCD) for the purpose of enrolling me in the Heat & Eat Program. ",
        lowerBenefitslabel:
          "DHR may deny, stop or lower my benefits based on information from Equifax Workforce Solutions. I have the right to a free copy of my report from Equifax if I request it within 60 days of DHR’s decision. I have the right to question the accuracy or completeness of the information in my report. I may contact Equifax at: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146, ",
        tollFreeLabel: "",
        rightoKnowbrochure:
          "I understand that I will get a copy of the “Your Right to Know” brochure and the SNAP Program brochure. I will read or have read to me the brochures and I must understand their contents and my rights and responsibilities. If I have any questions about the brochures or any of this information, I will contact DHR. If I have trouble reading or understanding any of this information, I will contact DHR. DHR can be reached at:",
        lawfullyResiding:
          "I swear that all members of my SNAP household requesting SNAP benefits are either U.S. citizens or lawfully residing noncitizens.",
        registerVoteTitle: "Right to Register to Vote",
        registerVoteFirstLabel:
          "I understand I have the right to register to vote at DHR. I understand that DHR will help me fill out the voter registration application form if I want help. I am allowed to fill out the voter registration application form in private.",
        registerVoteSecondLabel:
          "I understand that applying to register or declining to register to vote will not affect the amount of benefits I get from DHR. ",
        penaltyWarningTitle: "SNAP Penalty Warning",
        penaltyWarningContent:
          "I understand that if I or any member of my SNAP household intentionally breaks any of the rules listed below, that person will not be eligible for SNAP for one year after the first violation, two years after the second violation and forever after the third violation. That person may also be fined up to $250,000, imprisoned up to 20 years or both. S/he may also be subject to prosecution under other applicable Federal and State laws. These rules are:",
        penaltyWarningOne:
          "Do not give false information or hide information to get SNAP benefits.",
        penaltyWarningTwo: "Do not trade or sell SNAP benefits.",
        penaltyWarningThree:
          "Do not alter EBT cards to get SNAP benefits you are not eligible to get.",
        penaltyWarningFour:
          "Do not use SNAP benefits to buy ineligible items, such as alcoholic drinks and tobacco.",
        penaltyWarningFive:
          "Do not use someone else’s SNAP benefits or EBT card, unless you are an authorized representative.",

        penaltiesTitle: "I also understand the following penalties: ",
        individualLabel: "Individuals who commit a",
        cashProgram: "cash program",
        internationalProgram:
          " Intentional Program Violation (IPV) will be ineligible for SNAP for the same period the individual is ineligible from cash assistance.",
        penalitiesTwo:
          "Individuals who make a fraudulent statement about their identity or residency to get multiple SNAP benefits at the same time will be ineligible for SNAP for  ",
        tenYearLabel: "ten years",
        penalitiesThree:
          "Individuals who trade (buy or sell) SNAP benefits for a controlled substance/illegal drug(s), will be ineligible for SNAP for ",
        twoYearLabel: "two years ",
        penalitiesThreeMiddle: "for the first finding, and",
        foreverText: "forever ",
        penalitiesThreeLast: "for the second finding.",
        penalitiesFour:
          "Individuals who trade (buy or sell) SNAP benefits for firearms, ammunition or explosives, will be ineligible for SNAP",
        penalitiesFive:
          "Individuals who trade (buy or sell) SNAP benefits having a value of $500 or more, will be ineligible for SNAP",
        penalitiesSix:
          "The State may pursue an IPV against an individual who makes an offer to sell SNAP benefits or an EBT card online or in person",
        penalitiesSeven:
          "Individuals who are fleeing to avoid prosecution, custody or confinement after conviction for a felony, or are violating probation or parole, are ineligible for SNAP.",
        penalitiesEight:
          "Paying for food purchased on credit is not allowed and can result in disqualification from SNAP.",
        penaltiesNine:
          "Individuals may not buy products with SNAP benefits with the intent to discard the contents and return containers for cash.",

        interpreterHeader: "Right to an Interpreter",
        interpreterContent:
          "I understand that I have a right to an interpreter provided by DHR if no adult in my SNAP household is able to speak or understand English. I also understand that I can get an interpreter for any DHR fair hearing or bring one of my own. If I need an interpreter for a hearing, I must call the Division of Hearings at least one week before the hearing date.",
        nonDiscriminationHeader: "Nondiscrimination Statement",
        nonDiscriminationContent:
          "In accordance with Federal civil rights law and U.S. Department of Agriculture (USDA) civil rights regulations and policies, the USDA, its Agencies, offices, and employees, and institutions participating in or administering USDA programs are prohibited from discriminating based on race, color, national origin, sex, religious creed, disability, age, political beliefs, or reprisal or retaliation for prior civil rights activity in any program or activity conducted or funded by USDA.",
        personDisabilities:
          "Persons with disabilities who require alternative means of communication for program information (e.g. Braille, large print, audiotape, American Sign Language, etc.), should contact the Agency (State or local) where they applied for benefits. Individuals who are deaf, hard of hearing or have speech disabilities may contact USDA through the Federal Relay Service at ",
        programInformation:
          "Additionally, program information may be made available in languages other than English.,",

        programComplaint:
          "To file a program complaint of discrimination, complete the USDA Program Discrimination Complaint Form, (AD-3027) found online at: ",
        usdaOffice:
          "and at any USDA office, or write a letter addressed to USDA and provide in the letter all of the information requested in the form. To request a copy of the complaint form, call ",
        sumbitYourLabel: "Submit your completed form or letter to USDA by:",

        mailAddress: "mail: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " fax:",
        orLabel: " or",
        eamilTitle: "email: ",
        thisInstution: "This institution is an equal opportunity provider.",
      };

    case languageConsts.SPANISH:
      return {
        termsHeader: "Derechos, responsabilidades y multas",
        headerLabel:
          "Aviso de Derechos, responsabilidades y multas – Por favor, lea atentamente",
        certifyContents:
          "Certifico que he leído, o me han leído, la información de esta solicitud. Mis respuestas a las preguntas en esta solicitud son verdaderas y completas a mi mejor saber y entender. También certifico que la información que proporcione al Departamento durante la entrevista de solicitud y en el futuro también será verdadera y completa a mi mejor saber y entender. Entiendo que dar información falsa o engañosa es fraude. También entiendo que alterar o retener datos para establecer elegibilidad para SNAP es fraude. Esto lleva a una Violación Intencional del Programa (IPV) y es sancionable con multas civiles y criminales.",
        understandDta:
          "Entiendo que el Departamento de Asistencia Transicional (DHR) administra SNAP. Entiendo que el DHR tiene 30 días desde la fecha de la solicitud para procesar mi solicitud. Más aún, entiendo que:",
        foodNutritionact:
          "La Ley de Alimentos y Nutrición del 2008 (7 U.S.C. 2011-2036) permite que el DHR use mi Número de Seguro Social (SSN) y el SSN de cada miembro del hogar para quien presente la solicitud. El DHR usa esta información para determinar la elegibilidad de mi hogar para SNAP. El DHR verifica esta información por medio de programas informáticos de comparación de datos. Entiendo que el DHR los usa para monitorear el cumplimiento de los reglamentos del programa.",
        reportingRulesDtachanges:
          "La mayor parte del tiempo, los hogares bajo las reglas de Informe Simplificado de SNAP deben informar al DHR sobre cambios en el Informe Provisional (IR) y la recertificación con excepción de:",
        incomeExceeds:
          "Si los ingresos de mi hogar superan el umbral de ingresos brutos",
        workRequirementlabel:
          " Si yo estoy bajo los requisitos de trabajo de adultos sanos sin dependientes a cargo (ABAWD) y mis horas de trabajo disminuyeran por debajo de 20 horas por semana",
        verifiedInfomation:
          "Si el DHR recibe información verificada sobre mi hogar, la cantidad de mi beneficio podría cambiar",
        tbaRulesone:
          " Si yo no estuviera bajo las reglas del Informe Simplificado de SNAP o de la Alternativa de Beneficios Transicionales (TBA), debo informar al DHR sobre cambios en mi hogar que puedan afectar nuestra elegibilidad. Entiendo que debo informar sobre estos cambios al DHR en persona, por escrito o por teléfono ",
        tbaRuletwo: " dentro de los 10 días del cambio.",
        tbaExample:
          " Por ejemplo, usted debe informar sobre cambios en los ingresos, tamaño o dirección de su hogar.",
        findmeIneligible:
          "Tengo derecho a hablar con un supervisor si el DHR no me considerara elegible para beneficios de emergencia de SNAP y yo estuviera en desacuerdo. Podría hablar con un supervisor si yo fuera elegible para beneficios de emergencia de SNAP pero no recibiera mis beneficios al séptimo día calendario después de haber presentado la solicitud para SNAP. Podría hablar con un supervisor si yo fuera elegible para beneficios de emergencia de SNAP pero no recibiera mi tarjeta de Transferencia Electrónica de Beneficios (EBT) al séptimo día calendario después de haber presentado la solicitud para SNAP.",
        verificationtoDta:
          "Podría recibir más beneficios de SNAP si yo informara y presentara verificación al DHR de:",
        childorOtherTitile:
          "costos para niños o de otros servicios de atención de dependientes, costos de albergue, y/o costos de servicios públicos",
        legallyObligatedlabel:
          "Manutención infantil de obligación legal para un miembro que no esté en el hogar.",
        disabledMedicalcost:
          "Si tuviera 60 años de edad o más o si estuviera discapacitado y pagara por costos médicos, puedo informar y presentar verificación de estos costos al DHR. Esto podría hacerme elegible para una deducción y aumentar mis beneficios de SNAP.",
        meetExemptionlabel:
          "A menos que cumplan con una exención, todos los beneficiarios de SNAP entre los 16 y 59 años de edad están registrados para trabajar y sujetos a los Requisitos Generales de Trabajo de SNAP. Los beneficiarios de SNAP entre los 18 y 49 años de edad también podrían estar sujetos a los requisitos del Programa de Trabajo de ABAWD. El DHR le informará a los miembros no exentos del hogar sobre los requisitos de trabajo. El DHR le informará a los miembros no exentos del hogar sobre las excepciones y multas por incumplimiento.",
        voluntarilyParticipatelabel:
          "La mayoría de los beneficiarios de SNAP pueden participar voluntariamente en servicios de capacitación para el empleo y educación por medio del programa Camino al Trabajo de SNAP. El DHR entregará remisiones para el programa Camino al Trabajo de SNAP si correspondiera.",
        dtaNamescontact:
          "El DHR también podría compartir los nombres e información de contacto de los beneficiarios de SNAP con los proveedores de Camino al Trabajo de SNAP para propósitos de contratación. Entiendo que los miembros de mi hogar podrían ser contactados por especialistas de Camino al Trabajo de SNAP del DHR o por proveedores contratados para explorar las opciones de participación en Camino al Trabajo de SNAP. Para obtener más información sobre el programa Camino al Trabajo de SNAP, visite ",
        subjectToverification:
          "Entiendo que la información que entregue con mi solicitud estará sujeta a verificación para determinar si es verdadera. Si cualquier información fuera falsa, el DHR podría negarme mis beneficios de SNAP. También yo podría estar sujeto a juicio por proporcionar información falsa.",
        signingApplication:
          "Entiendo que al firmar esta solicitud le doy permiso al DHR para verificar e investigar la información que proporcione relacionada con mi elegibilidad para beneficios de SNAP, incluyendo permiso para:",
        proveInformation:
          "Obtener documentos para probar información en esta solicitud con otras agencias estatales y federales, autoridades locales de vivienda, departamentos de bienestar fuera del estado, instituciones financieras y de Equifax Workforce Solutions. También doy permiso a estas agencias para que le den información sobre mi hogar al DHR concerniente a mis beneficios de SNAP.",
        immigrationStatusLabel:
          "Si correspondiera, verificar mi estado inmigratorio por medio de los Servicios de Ciudadanía e Inmigración de Estados Unidos (USCIS). Entiendo que el DHR podría verificar la información de mi solicitud para SNAP con USCIS. Cualquier información recibida de USCIS podría afectar la elegibilidad de mi hogar y la cantidad de los beneficios de SNAP.",
        secondaryEducation:
          "Compartir información sobre mí y mis dependientes menores de 19 años con el Departamento de Educación Primaria y Secundaria (DESE). El DESE certificará a mis dependientes para programas escolares de desayuno y almuerzo.",
        publicHealth:
          "Compartir información sobre mí, mis dependientes menores de 5 años y cualquier persona embarazada en mi hogar con el Departamento de Salud Pública (DPH). El DPH remite a estas personas para servicios de nutrición al Programa para Mujeres, Infantes y Niños (WIC).",
        executiveOfficeHealth:
          "Compartir información, junto con la Oficina Ejecutiva de Salud y Servicios Humanos de Alabama, sobre mi elegibilidad para SNAP con compañías de electricidad, gas y proveedores de teléfono y cable elegibles para certificar mi elegibilidad para descuentos en las tarifas de servicios públicos.",
        housingCommuntiy:
          "Compartir mi información con el Departamento de Vivienda y Desarrollo Comunitario (DHCD) para el fin de inscribirme en el programa de Calefacción y Alimentación.",
        lowerBenefitslabel:
          "El DHR podría negar, suspender o disminuir mis beneficios basado en la información de Equifax Workforce Solutions. Tengo derecho a una copia gratuita de mi informe de Equifax si lo solicitara dentro de los 60 días de la decisión del DHR. Tengo derecho a cuestionar la precisión o integridad de la información en mi informe. Podría comunicarme con Equifax a: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146,",
        tollFreeLabel: "(línea gratuita).",
        rightoKnowbrochure:
          "Entiendo que obtendré una copia del folleto “Su derecho a saber” y del folleto del programa de SNAP. Leeré o me leerán los folletos, debiendo entender su contenido y mis derechos y responsabilidades. Si tuviera preguntas sobre los folletos o cualquier parte de esta información, me comunicaré con el DHR. Si tuviera dificultad para leer o entender cualquier parte de esta información, me comunicaré con el DHR. Puede comunicarse con el DHR al: ",
        lawfullyResiding:
          "Juro que todos los miembros de mi hogar SNAP que solicitan beneficios de SNAP son ciudadanos de EE. UU. o no ciudadanos con residencia legal.",
        registerVoteTitle: "Derecho a inscribirse para votar",
        registerVoteFirstLabel:
          "Entiendo que tengo derecho a inscribirme para votar en el DHR. Entiendo que el DHR me ayudará a completar el Formulario de Inscripción de Votante si deseara ayuda. Tengo derecho a completar el Formulario de Inscripción de Votante en privado.",
        registerVoteSecondLabel:
          "Entiendo que solicitar o rechazar la inscripción para votar no afectará la cantidad de beneficios que obtenga del DHR. ",
        penaltyWarningTitle: "Advertencia de multa de SNAP",
        penaltyWarningContent:
          "Entiendo que, si yo o cualquier miembro de mi hogar SNAP intencionalmente no cumpliera cualquiera de las reglas indicadas a continuación, esa persona no será elegible para SNAP durante un año después de la primera infracción, dos años después de la segunda infracción y para siempre después de la tercera infracción. Esa persona también puede ser multada hasta con $250,000, ir a prisión hasta 20 años o ambas. También puede estar sujeta a juicio bajo otras leyes federales y estatales aplicables. Estas reglas son:",
        penaltyWarningOne:
          "No proporcionar información falsa u ocultar información para obtener beneficios de SNAP.",
        penaltyWarningTwo: "No intercambiar o vender beneficios de SNAP.",
        penaltyWarningThree:
          "No alterar las tarjetas EBT para obtener beneficios de SNAP para los cuales usted no es elegible.",
        penaltyWarningFour:
          "No usar los beneficios de SNAP para comprar artículos inelegibles, como bebidas alcohólicas y tabaco.",
        penaltyWarningFive:
          "No usar los beneficios de SNAP o la tarjeta EBT de otra persona, a menos que sea un Representante autorizado.",

        penaltiesTitle: "También entiendo las siguientes multas: ",
        individualLabel:
          "Las personas que cometan una Infracción Intencional del Programa (IPV)",
        cashProgram: "de efectivo ",
        internationalProgram:
          " no serán elegibles para SNAP durante el mismo período en que la persona no sea elegible para la asistencia con efectivo.",
        penalitiesTwo:
          "Las personas que hagan una declaración fraudulenta sobre su identidad o residencia para obtener múltiples beneficios de SNAP en el mismo período no serán elegibles para SNAP durante   ",
        tenYearLabel: "diez años",
        penalitiesThree:
          "Las personas que intercambien (compren o vendan) beneficios de SNAP por drogas ilegales/sustancias controladas, no serán elegibles para SNAP durante dos años para la primera falta y para siempre para la segunda falta",
        twoYearLabel: "dos años ",
        penalitiesThreeMiddle: "para la primera falta ",
        foreverText: "para siempre ",
        penalitiesThreeLast: "para la segunda falta.",
        penalitiesFour:
          "Las personas que intercambien (compren o vendan) beneficios de SNAP por armas de fuego, municiones o explosivos, no serán elegibles para SNAP",
        penalitiesFive:
          "Las personas que intercambien (compren o vendan) beneficios de SNAP que tengan un valor de $500 o más, no serán elegibles para SNAP",
        penalitiesSix:
          "El estado podría enjuiciar por una IPV a una persona que haga una oferta para vender beneficios de SNAP o una tarjeta EBT en línea o en persona",
        penalitiesSeven:
          "Las personas que escapen para evitar el juicio, detención o reclusión después de la convicción por un delito, o que estén violando la libertad bajo palabra, no son elegibles para SNAP.",
        penalitiesEight:
          "No se permite pagar por alimentos comprados a crédito, y esto pudiera resultar en la descalificación de SNAP.",
        penaltiesNine:
          "Las personas no pueden comprar productos con beneficios de SNAP con la intención de descartar el contenido y devolver los envases por efectivo.",

        interpreterHeader: "Derecho a un intérprete",
        interpreterContent:
          "Entiendo que tengo derecho a un intérprete proporcionado por el DHR si ningún adulto en mi hogar SNAP pudiera hablar o entender inglés. También entiendo que puedo obtener un intérprete para cualquier audiencia imparcial del DHR o llevar a un intérprete por mi cuenta. Si necesitara un intérprete para una audiencia, debo llamar a la División de Audiencias al menos una semana antes de la fecha de la audiencia.",
        nonDiscriminationHeader: "Declaración de no discriminación",
        nonDiscriminationContent:
          "De acuerdo con la ley federal de derechos civiles y los reglamentos y políticas de derechos civiles del Departamento de Agricultura de EE. UU. (USDA), se prohíbe al USDA, sus agencias, oficinas, empleados e instituciones que participen o administren programas de USDA discriminar basado en raza, color, nacionalidad, sexo, credo religioso, discapacidad, edad, creencias políticas, represalia o venganza por actividades de derechos civiles en cualquier programa o actividad realizado o patrocinado por USDA.",
        personDisabilities:
          "Las personas con discapacidades que requieran medios alternativos de comunicación para obtener información sobre el programa (por ej. braille, letras grandes, cintas de audio, Lenguaje de Señas Americano, etc.), deben comunicarse con la Agencia (estatal o local) donde presentaron la solicitud para beneficios. Las personas sordas, con dificultad auditiva o discapacidades del habla pueden comunicarse con el USDA por medio del Servicio de Retransmisión Federal al ",
        programInformation:
          "La información sobre el programa también puede ponerse a disposición en otros idiomas además del inglés.,",

        programComplaint:
          "Para presentar una queja de discriminación para el programa, complete el Formulario de Queja por Discriminación del Programa del USDA, (AD-3027) que se encuentra en línea en: ",
        usdaOffice:
          "y en cualquier oficina del USDA, o escriba una carta dirigida al USDA y proporcione en la carta toda la información solicitada en el formulario. Para solicitar una copia del formulario de quejas, llame al ",
        sumbitYourLabel: "Envíe su carta o formulario completado al USDA por:",

        mailAddress: "correo: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " fax:",
        orLabel: " o por",
        eamilTitle: "correo electrónico: ",
        thisInstution:
          "Esta institución es un proveedor que ofrece igualdad de oportunidades.",
      };

    case languageConsts.PORTUGUESE: //portugeesh
      return {
        termsHeader: "Direitos, responsabilidades e penalidades",
        headerLabel:
          "Notificação de direitos, responsabilidades e penalidades. Leia com atenção!",
        certifyContents:
          "Certifico de que li, ou de que alguém leu para mim, as informações contidas neste formulário. Atesto que minhas respostas às perguntas deste formulário são verdadeiras e completas, segundo o meu conhecimento. Também certifico de que todas as informações fornecidas por mim ao departamento, tanto durante a entrevista de inscrição bem como em ocasiões futuras, serão verdadeiras e completas, de acordo com o meu conhecimento. Entendo que fornecer informações falsas ou deturpadas constituem fraude. Também compreendo constituir fraude qualquer fornecimento de informações deturpadas ou a omissão de fatos com o objetivo de garantir a qualificação no programa SNAP. Tais atos se enquadram em Violação Intencional do Programa (IPV, Intentional Program Violation), sendo passíveis de punições civis e criminais.",
        understandDta:
          "Eu entendo que o Departamento de Auxílio Transitório (DHR) administra o SNAP. Eu entendo que o DHR tem um prazo de 30 dias a contar da data de inscrição para processar o meu caso. Entendo ainda que:",
        foodNutritionact:
          "De acordo com o Food and Nutrition Act de 2008 (7 U.S.C. 2011-2036), o DHR pode utilizar o número do meu seguro social (SSN) e o de qualquer membro da minha família que esteja se inscrevendo neste programa. O DHR utiliza esta informação a fim de determinar a qualificação da minha família ao programa SNAP. Além disso, o DHR verifica tais informações por meio de programas de comparação por computador. Entendo que o DHR faz uso desta prática para monitorar o cumprimento das normas do programa.",
        reportingRulesDtachanges:
          "Na maioria das vezes, as famílias ou grupos familiares que se encontram sujeitas às regras de fornecimento de Informações Simplificadas do SNAP precisam informar ao DHR sobre quaisquer mudanças que afetem o Relatório Provisório (IR, Interim Report), bem como em casos de re-certificação, com as seguintes exceções:",
        incomeExceeds:
          "Caso a minha renda familiar ultrapasse o limite de renda bruta",
        workRequirementlabel:
          "Caso eu me encaixe nos requisitos de trabalho de adulto fisicamente capaz sem dependentes (ABAWD, able-bodied adult without dependents,) e as minhas horas de trabalho forem reduzidas para menos de 20 horas semanais",
        verifiedInfomation:
          "O valor do meu benefício pode ser alterado, caso o DHR receba informações previamente verificadas sobre a minha família.",
        tbaRulesone:
          "Caso eu não esteja sujeito às regras de Informações Simplificadas (Simplified Reporting) do SNAP, nem às regras da Alternativa de Benefícios Transitórios (TBA, Transitional Benefits Alternative) é obrigatório que eu informe o DHR sobre quaisquer mudanças em minha família, ou agregado familiar, que altere nossa qualificação. Eu entendo que preciso informar o DHR sobre tais alterações pessoalmente, por escrito ou por telefone ",
        tbaRuletwo: " dentro de um prazo de 10 dias da mudança.",
        tbaExample:
          " aPor exemplo, é obrigatório informar sobre alterações de renda, tamanho ou endereço da família.",
        findmeIneligible:
          "Tenho direito a falar com um supervisor, caso o DHR negue a minha qualificação para os benefícios de emergência do SNAP e eu não concorde com esta decisão. Também poderei entrar em contato com um supervisor, caso seja qualificado para os benefícios de emergência do SNAP, mas não tenha recebido nenhum benefício depois de sete dias corridos após a inscrição no programa SNAP. O candidato poderá falar com um supervisor, caso se qualifique para os benefícios, mas não tenha recebido o cartão de Transferência Eletrônica de Benefícios (EBT) depois de sete dias corridos após ter realizado a inscrição.",
        verificationtoDta:
          "Poderei receber benefícios SNAP adicionais caso eu informe e comprove ao DHR:",
        childorOtherTitile:
          "possuir custos relativos a cuidados de crianças e outros dependentes, custos de asilo e/ou custos de serviços de utilidade pública",
        legallyObligatedlabel:
          "estar obrigado legalmente a fornecer pensão alimentícia a um membro que não pertença a minha família",
        disabledMedicalcost:
          "Se eu tiver 60 anos ou mais, ou no caso de ser portador de deficiência e pagar por despesas médicas, posso informar e enviar comprovação ao DHR dos custos relativos a estas despesas, Talvez isso qualifique o candidato para uma redução ou aumento dos benefícios do SNAP.",
        meetExemptionlabel:
          "Todos os beneficiários do SNAP com idades de 16 a 59 anos devem possuir registro de trabalho de acordo com os Requisitos Gerais de Trabalho do SNAP (General SNAP Work Requirements), a não ser que eles atendam alguma categoria de isenção. Os beneficiários do SNAP com idades entre 18 e 49 anos podem também estar sujeitos aos requisitos do programa de trabalho ABAWD. O DHR se encarregará de informar os membros da família não-isentos sobre os requisitos de trabalho necessários. O DHR também informará os membros não-isentos da família sobre as exceções e as penalidades caso as exigências solicitadas não forem cumpridas.",
        voluntarilyParticipatelabel:
          "A maior parte dos beneficiários do SNAP poderá participar voluntariamente em serviços de ensino e formação profissionais por meio do programa Caminho para Trabalhar do SNAP (SNAP Path to Work). O DHR poderá encaminhar beneficiários para o programa SNAP Path to Work, se necessário.",
        dtaNamescontact:
          "Para fins de recrutamento, o DHR poderá também compartilhar nomes e informações de contato dos beneficiários com os organizadores do SNAP Path to Work. Entendo que os membros da minha família poderão ser contatados por especialistas do programa SNAP Path to Work do DHR, ou pelos fornecedores de serviço contratados para o programa, a fim de analisar opções de participação no programa SNAP Path to Work. Para obter mais informações sobre o programa SNAP Path to Work, visite ",
        subjectToverification:
          "Entendo que as informações fornecidas com a minha inscrição estão sujeitas à verificação para determinar sua veracidade. Se alguma informação for falsa, o DHR poderá negar-me os benefícios do SNAP. Eu posso estar sujeito a julgamento criminal por fornecimento de informações falsas.",
        signingApplication:
          "Entendo que ao assinar este formulário eu autorizo a verificação e a investigação pelo DHR dos dados informados relativos à minha qualificação para os benefícios do SNAP, incluindo permissão para:",
        proveInformation:
          "Obter documentos que comprovem a informação deste formulário com outras agências estaduais e federais, autoridades locais de moradia, departamentos de assistência social de outros estados, instituições financeiras e da Equifax Worforce Solutions. Também autorizo que estas agências forneçam ao DHR dados sobre minha família referentes aos meus benefícios do SNAP.",
        immigrationStatusLabel:
          "Se for o caso, verificar minha situação de imigração junto aos Serviços de Imigração e Cidadania dos Estados Unidos (USCIS, United States Citizenship and Immigration Services). Entendo que o DHR poderá verificar dados da minha inscrição no SNAP com o USCIS. Qualquer informação recebida do USCIS poderá afetar a qualificação da minha família, bem como o valor dos benefícios do SNAP.",
        secondaryEducation:
          "Compartilhar meus dados pessoais e de meus dependentes menores de 19 anos de idade com o Departamento de Ensino Fundamental e Médio (DESE, Department of Elementary and Secondary Education). O DESE irá aprovar meus dependentes para os programas de alimentação escolar (café da manhã e almoço).",
        publicHealth:
          "Compartilhar meus dados pessoais, de meus dependentes menores de cinco anos de idade e de qualquer gestante em minha família com o Departamento de Saúde Pública (DPH, Department of Public Health). O DPH encaminha estes indivíduos para o Programa para Mulheres, Bebês e Crianças (WIC, Women, Infants and Children Program) para auxílio nutricional.",
        executiveOfficeHealth:
          "Compartilhar informações, junto ao Escritório Executivo de Serviços Humanos e Saúde de Alabama (Alabama Executive Office of Health and Human Services), sobre minha qualificação no programa SNAP, com empresas fornecedoras de eletricidade, gás e de serviços de telefonia e TV a cabo qualificadas para certificar-se da minha qualificação para receber descontos nas taxas de serviços de utilidade pública.",
        housingCommuntiy:
          "Compartilhar meus dados com o Departamento de Desenvolvimento de Habitação e Comunidade (DHCD, Department of Housing and Community Development) com o objetivo de inscrever-me no Programa Aquecer e Alimentar (Heat & Eat Program).",
        lowerBenefitslabel:
          "DHR poderá negar, interromper ou diminuir meus benefícios de acordo com as informações recebidas do Equifax Workforce Solutions. Tenho o direito de receber uma cópia gratuita do meu relatório do Equifax caso eu solicite o mesmo dentro do prazo de 60 dias a partir da decisão do DHR. Tenho o direito de questionar se as informações no meu relatório estão precisas ou completas. Eu posso entrar em contato com a Equifax em: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146,",
        tollFreeLabel: "(toll free).",
        rightoKnowbrochure:
          "Entendo que irei receber uma cópia dos folhetos “Seu Direito de Saber” ('Your Right to Know') e do SNAP. Confirmo que vou ler ou já li os folhetos e preciso entender o conteúdo dos mesmos, bem como meus direitos e responsabilidades. Caso tenha qualquer pergunta sobre estes folhetos ou sobre quaisquer destas informações, entrarei em contato com o DHR. Caso eu tenha dificuldade de ler ou compreender quaisquer informações, entrarei em contato com o DHR. O DHR pode ser contactado pelo número:",
        lawfullyResiding:
          "Eu asseguro que todos os membros da minha família solicitantes dos benefícios do SNAP são cidadãos dos Estados Unidos ou não-cidadãos residentes legais.",
        registerVoteTitle: "Direito de registro eleitoral",
        registerVoteFirstLabel:
          "Entendo que no DHR tenho o direito de registrar-me para votar. Entendo que o DHR vai me ajudar no preenchimento do formulário de inscrição eleitoral caso necessário. Posso optar por preencher o formulário de registro eleitoral em particular.",
        registerVoteSecondLabel:
          "Entendo que o ato de inscrição ou desistência ao registro eleitoral não afetará o valor dos benefícios que eu receber do DHR. ",
        penaltyWarningTitle: "Aviso de penalidade do SNAP",
        penaltyWarningContent:
          "Entendo que se eu mesmo, ou quaisquer membros da minha família beneficiários do SNAP, não cumprirmos qualquer uma das regras listadas abaixo, perderemos a qualificação ao SNAP por um ano após a primeira infração, dois anos após a segunda infração e permanentemente após a terceira infração. Este indivíduo poderá ser multado em até US$ 250.000,00 (duzentos e cinquenta mil dólares), ficar preso por até vinte anos ou ambos. O beneficiário também poderá ser processado sob outras leis federais e estaduais aplicáveis. As regras a serem seguidas são:",
        penaltyWarningOne:
          "Não ocultar ou fornecer informações falsas com o objetivo de receber benefícios do SNAP.",
        penaltyWarningTwo: "Não negociar ou vender benefícios do SNAP.",
        penaltyWarningThree:
          "Não falsificar cartões de EBT ou receber benefícios do SNAP para os quais você não se qualifica.",
        penaltyWarningFour:
          "Não usar os benefícios do SNAP para comprar itens ilegais como bebidas alcoólicas, cigarros e outros produtos de tabaco.",
        penaltyWarningFive:
          "Não usar os benefícios do SNAP ou cartões de EBT de terceiros, a não ser que você seja um representante autorizado.",

        penaltiesTitle: "Também entendo as penalidades a seguir:",
        individualLabel:
          "Os beneficiários, que porventura cometam uma Violação Intencional do Programa (IPV, Intentional Program Violation) relativa ao ",
        cashProgram: "recebimento em dinheiro",
        internationalProgram:
          ", ficarão desqualificados do SNAP. Durante esse mesmo período eles não poderão receber o auxílio em dinheiro.",
        penalitiesTwo:
          "O beneficiário que fizer uma declaração fraudulenta sobre sua identidade ou residência visando receber diversos benefícios do SNAP ficará desqualificado do programa por um prazo de ",
        tenYearLabel: "dez anos.",
        penalitiesThree:
          "Os beneficiários que negociarem (compra ou venda) utilizando benefícios do SNAP em troca de substância(s)/droga(s) ilegal(is) ficarão desqualificados por ",
        twoYearLabel: "dois anos ",
        penalitiesThreeMiddle: "para a primeira violação, e",
        foreverText: "permanentemente ",
        penalitiesThreeLast: "para a segunda.",
        penalitiesFour:
          "Os beneficiários que negociarem (compra ou venda) utilizando benefícios do SNAP em troca de armas de fogo, munição ou explosivos ficarão permanentemente desqualificados do SNAP",
        penalitiesFive:
          "Os beneficiários que negociarem (compra ou venda) utilizando benefícios do SNAP no valor de US$ 500,00 (quinhentos dólares) ou mais ficarão permanentemente desqualificados do SNAP.",
        penalitiesSix:
          "O Estado poderá prosseguir com uma IPV contra o beneficiário que fizer uma oferta para vender on-line ou pessoalmente os benefícios do SNAP ou os cartões de EBT.",
        penalitiesSeven:
          "Indivíduos que estiverem fugindo para evitar processo, custódia ou prisão após condenação por crime ou que estejam violando a liberdade condicional ou em liberdade provisória não se qualificam para o programa SNAP.",
        penalitiesEight:
          "Não é permitido pagar por alimento comprado a crédito e isso poderá resultar em desqualificação pelo SNAP.",
        penaltiesNine:
          "Os beneficiários não poderão comprar produtos com os benefícios do SNAP com a intenção de descartar seus conteúdos e retornar as embalagens em troca de dinheiro.",

        interpreterHeader: "Direito a um intérprete",
        interpreterContent:
          "Entendo ter o direito de solicitar um intérprete fornecido pelo DHR, caso nenhum membro adulto pertencente ao meu agregado familiar do SNAP falar ou entender inglês. Também entendo poder solicitar um intérprete para qualquer sessão de audiência com o DHR, ou trazer um intérprete de minha confiança. Caso eu precise de um intérprete para uma audiência, preciso ligar para a Division of Hearings, com pelo menos uma semana de antecedência da data da minha audiência.",
        nonDiscriminationHeader: "Declaração de não-discriminação",
        nonDiscriminationContent:
          "De acordo com as leis federais de direitos civis e os regulamentos e as políticas de direitos civis do Departamento de Agricultura dos EUA (USDA, U.S. Department of Agriculture), o USDA, suas agências, escritórios e empregados, assim como as instituições que participam ou administram programas do USDA são proibidas de fazer qualquer tipo de discriminação com base em raça, cor, nacionalidade de origem, sexo, credo religioso, deficiência, idade, convicções políticas. Também são proibidas em todos os programas ou atividades conduzidos ou custeados pelo USDA qualquer tipo de represálias ou retaliações relativas a atividades de direitos civis anteriores.",
        personDisabilities:
          "Os indivíduos portadores de deficiências que precisam de formas alternativas de comunicação para obter informações sobre o programa (por exemplo, braile, publicações com letras maiores, fitas de áudio, linguagem americana de sinais etc.), devem entrar em contato com a agência (estadual ou local) onde foi feita a inscrição para os benefícios. Pessoas deficiente auditivas, com dificuldades de audição ou que possuem distúrbios da fala devem entrar em contato com o USDA por meio do Federal Relay Service no telefone  ",
        programInformation:
          "Adicionalmente, as informações do programa podem estar disponíveis em outros idiomas, que não somente o inglês.",

        programComplaint:
          "Para fazer uma reclamação ou relatar um caso de discriminação, preencha o formulário AD-3027 (USDA Program Discrimination Complaint Form), disponível on-line no site: ",
        usdaOffice:
          "e em qualquer escritório do USDA. Você pode também enviar uma carta para o USDA, fornecendo todas as informações solicitadas no formulário. Para solicitar uma cópia do formulário de reclamação, ligue para ",
        sumbitYourLabel:
          "Envie o formulário preenchido ou a carta para o USDA por:",

        mailAddress: "Correio: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " fax:",
        orLabel: " ou",
        eamilTitle: "Email: ",
        thisInstution: "Esta instituição oferece igualdade de oportunidades.",
      };

    case languageConsts.CHINESE: //China
      return {
        termsHeader: "《权利、责任和处罚》",
        headerLabel: "权利、责任和处罚通知 — 请细心阅读",
        certifyContents:
          "我确认，我已经阅读或有人向我读出本申请表中的资讯。据我所知所信，我对本申请表中问题的回答是真实和完整的。我还确认，据我所知所信，我在申请面谈期间向过渡援助部提供的资料是真实和完整的，将来提供的资料也会真实和完整。我理解，提供虚假或误导性信息是欺诈行为。我还理解，为了符合 SNAP 资格而歪曲或隐瞒事实是欺诈行为。这等行为将导致蓄意违反计划行为（IPV），本人亦因此受到民事和刑事处罚。",
        understandDta:
          "我理解，过渡援助部（DHR）负责管理 SNAP。我理解，DHR 将在申请日期开始的 30 天内处理我的申请。此外，我理解：",
        foodNutritionact:
          "《2008 年食品与营养法案》（7 U.S.C. 2011-2036）允许 DHR 使用我的社会安全号码（SSN）以及我为其提交申请的每一名家庭成员的 SSN。DTA会 使用此类资料确定我的家庭是否有获取 SNAP 的资格。DHR 通过电脑匹配程序确认此类资料的准确性。我理解， DHR 会利用此类资讯监管申请是否符合计划规章。",
        reportingRulesDtachanges:
          "在大多数情况下，符合 SNAP 简化报告规定的家庭必须在临时报告（IR）中将所有变化通知 DHR，并重新认证，但以下情况例外：",
        incomeExceeds: "我的家庭收入超过总收入限额",
        workRequirementlabel:
          "我受「无家属健全成人」（ABAWD）的工作要求制约，而我的工作时数降低到每周 20 小时以下",
        verifiedInfomation:
          "如果 DHR 收到已确认的有关我家庭的资讯，我的福利数额可能会有改变。",
        tbaRulesone:
          "如果我没有受制於SNAP 简化报告规定或过渡福利替代（TBA）的规定，则我必须将有可能影响我们获得批准的资格之家庭变化向DTA 报告。我理解我必须 ",
        tbaRuletwo: "在变更发生后 10 天内",
        tbaExample:
          " 亲自到办事处、写信或打电话向 DHR 报告这些变更。例如，您必须报告您的家庭的收入、人口或地址的改变。",
        findmeIneligible:
          " 如果 DTA认为我没有资格享受紧急 SNAP 福利，而我不同意他们的决定，我有权向直属主管反映。如果我有资格享受紧急 SNAP 福利，但在我申请 SNAP 后的第七天仍未有收到我的福利，我可以向直属主管反映。如果我有资格享受紧急 SNAP 福利，但在我申请 SNAP 后的第七天仍未收到我的电子福利转账（EBT）卡，我可以向直属主管反映。",
        verificationtoDta:
          "如果我向 DHR 报告以下情况并提供证明，我可能享受更多的 SNAP 福利：",
        childorOtherTitile:
          "子女或其他家属护理费用、庇护所费用、及水、电、煤气、电话等费用",
        legallyObligatedlabel: "付给非家庭成员法律规定支付的子女赡养费",
        disabledMedicalcost:
          "如果我年满 60 岁，又或者我是残障人仕并自付医疗费用，我可以向 DHR 申报这些费用，并提供证明。这可能会使我有资格获得税收减免，并增加我的 SNAP 福利。",
        meetExemptionlabel:
          "•	除非符合豁免条件，否则所有年龄在 16 岁至 59 岁之间的 SNAP 受惠者均须进行工作注册，且须符合SNAP的普通工作要求 (General SNAP Work Requirement)。年龄在 18 岁至 49 岁之间的 SNAP 受惠者亦可能须要符合 ABAWD 工作计划要求。DHR 将会通知非豁免的家庭成员有关工作要求的事宜。DHR 亦通知非豁免的家庭成员有关例外情况和不遵守此项规定的处罚。",
        voluntarilyParticipatelabel:
          "大多数 SNAP 受惠者会自愿通过 SNAP 工作之路（SNAP Path to Work）计划参与教育和就业培训。DHR 将在有需要时提供 SNAP 工作之路计划的转介服务。",
        dtaNamescontact:
          "DHR 还可能与 SNAP 工作之路提供服务者分享 SNAP 受惠者的姓名和联系资料，以作招募之用。我理解，DHR SNAP 工作之路计划专家或特约提供服务者可能与我的家庭成员联系，讨论参加 SNAP 工作之路计划的各种选择。如欲得到更多有关 SNAP 工作之路计划的详情，请查阅网站 ",
        subjectToverification:
          "我理解，我在申请表中提供的资料必须接受验证，以确定其真实性。如果有任何虚假资讯，DHR 有可能拒绝提供我的 SNAP 福利。我还可能因提供虚假资讯而受到刑事起诉。",
        signingApplication:
          "我理解，我在本申请表中的签名表示我允许 DHR 确认和调查我所提供的、与我获得 SNAP 福利资格相关的资料，包括允许他们：",
        proveInformation:
          "向其他州立机构、联邦机构、地方住宅管理机构、外州福利部门、金融机构和 Equifax Workforce Solutions 索取文件，以确定本申请表中的资讯。我还允许这些机构向 DHR 提供与我的 SNAP 福利相关的、有关我的家庭的资料。",
        immigrationStatusLabel:
          " 如果适用的话，通过美国公民与移民服务部（USCIS）确认我的移民身份。我理解，DHR 可能与 USCIS 核对我的 SNAP 申请表中的资料。从 USCIS 收到的任何资讯都可能影响我的家庭获 SNAP 福利的资格和福利数额。",
        secondaryEducation:
          " 与小学和中学教育部（DESE）分享有关我和我的 19 岁以下的家属的资料。DESE 将证明我的家属可参加学校早餐和午餐计划",
        publicHealth:
          "与公共卫生部（DPH）分享有关我、我的 5 岁以下的家属和我的家中任何孕妇的资料。DPH 会将这些人转介给妇女、婴儿和儿童（WIC）计划，接受营养服务。",
        executiveOfficeHealth:
          "与马萨诸塞州健康与公众服务执行办公室合作，与电力公司、煤气公司和合格的电话和有线电视运营商分享有关我参加 SNAP 资格的资讯，以确认我获得水、电、煤气、电话等优惠费率的资格。",
        housingCommuntiy:
          "与住宅与社区开发部（DHCD）分享有关我的资料，从而使我得以注册参加取暖与食品计划（Heat & Eat Program）。",
        lowerBenefitslabel:
          "DHR 可能根据从 Equifax Workforce Solutions 获得的讯息拒绝、终止或减低我的福利。如果我在 DHR 作出决定后 60 天内提出请求，我有权免费获得一份我的 Equifax 报告。我有权质疑我的报告中的资料的准确性或完整性。我可以与 Equifax 联系，地址：Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146；免费电话：",
        tollFreeLabel: " ",
        rightoKnowbrochure:
          "我理解，我会收到一份《您的知情权利》（Your Right to Know）手册和《补充营养援助计划》（SNAP Program）手册。我将阅读或请人向我读出这两份手册的内容，我必须理解手册的内容以及我的权利和责任。如果我对手册或手册中的任何资讯有任何疑问，我将与 DHR 联系。如果我在阅读或理解任何此类资讯时遇到困难，我将与 DHR 联系。DHR 的电话号码：",
        lawfullyResiding:
          "我宣誓，所有与我一同申请 SNAP 福利的 SNAP 家庭成员都是美国公民或合法居住非公民。",
        registerVoteTitle: "登记投票的权利",
        registerVoteFirstLabel:
          "我理解，我有权在 DHR 登记投票。我理解，如果我需要帮助，DHR 将帮助我填写选民登记申请表。我亦可以私下填写选民登记申请表。",
        registerVoteSecondLabel:
          "我理解，申请登记投票或拒绝登记投票不会影响我从 DHR 获得的福利数额。 ",
        penaltyWarningTitle: "SNAP 处罚警告",
        penaltyWarningContent:
          "我理解，如果我或我的任何SNAP 家庭的成员有意违反下列任何规定，该人仕将在首次违规后一年内没有资格享用SNAP；在第二次违规后两年内没有资格享用 SNAP；在第三次违规后将永远没有资格享用 SNAP。该人仕亦可能被处以高达 $250,000 的罚款、高达 20 年的监禁或二者并罚。根据其他相关联邦法律和州法律的，该人仕还可能被起诉。此类规定是：",
        penaltyWarningOne: "不得为了获得 SNAP 福利提供虚假资料或隐瞒资讯。",
        penaltyWarningTwo: "不得买卖或出售 SNAP 福利。",
        penaltyWarningThree:
          "不得更改 EBT 卡，以便获取您没有资格享用的 SNAP 福利。",
        penaltyWarningFour:
          "不得使用 SNAP 福利购买不合格的物品，例如酒精饮料和烟草产品。",
        penaltyWarningFive:
          "	除非您是经授权代表，否则不得使用他人的 SNAP 福利或 EBT 卡。",

        penaltiesTitle: "我还理解以下处罚 ",
        individualLabel: "有蓄意违反",
        cashProgram: "现金计划",
        internationalProgram:
          " 	行为（IPV）者，在其失去获得现金援助资格的同时，亦会被取消获得SNAP 福利的资格。",
        penalitiesTwo:
          "•	为了同时获得多项 SNAP 福利而以自己的身份或居住地作出欺骗性陈述者将在  ",
        tenYearLabel: "十年",
        penalitiesThree:
          "意图获得受控物质/毒品而买卖 SNAP 福利者，首次发现将在两年内被取消获得SNAP 福利的资格，第二次发现将永远被取消获得 SNAP 福利的资格。 ",
        twoYearLabel: "两年 ",
        penalitiesThreeMiddle: "",
        foreverText: "永远 ",
        penalitiesThreeLast: "第二次发现将永远被取消获得  SNAP 福利的资格。",
        penalitiesFour:
          "意图获取武器、弹药或爆炸品而买卖 SNAP 福利者，将永远失去获得SNAP 福利的资格。",
        penalitiesFive:
          "买卖价值达 $500 的 SNAP 福利者，将永远被取消获得 SNAP 福利的资格。",
        penalitiesSix:
          "州政府有可能向在网上或亲自出售 SNAP 福利或 EBT 卡者 提出蓄意违反计划行为（IPV）的起诉。",
        penalitiesSeven:
          "避免被起诉而逃走者、已被定罪而逃避羁押或监禁者、及违反缓刑或假释规定者，均没有资格获得 SNAP 福利。",
        penalitiesEight:
          "严禁用获得的福利以支付购买粮食的信贷，违者有可能被取消 SNAP 资格。",
        penaltiesNine:
          "严禁以 SNAP 福利购买物品后，意图丢弃物品内装物再退回容器以套取现金。",

        interpreterHeader: "接受口译员协助的权利",
        interpreterContent:
          "我理解，如果我的 SNAP 家庭成员中没有任何成年人会讲或听懂英语，我有权获得 DHR 提供的口译员协助。我还理解，出席任何 DHR 公平听证时，我可以请求口译员协助，或者自带口译员。如果我因为出席听证而需要请求口译员协助，我必须在听证日期至少一周前打电话给听证分部（Division of Hearings）。",
        nonDiscriminationHeader: "不歧视声明",
        nonDiscriminationContent:
          "依照联邦民权法和美国农业部（USDA）民权法规章和政策的规定，USDA 及其代理机构、办事处和雇员以及参加或管理 USDA 计划的机构不得在 USDA 开展或资助的任何计划或活动中，有任何对种族、肤色、原国籍、性别、宗教信仰、残障、年龄、政治信仰的歧视行动或行为，或因以前的民权活动进行报复。",
        personDisabilities:
          "要求其他计划信息交流方法（例如，盲文、大印刷体、录音带、美国手语等）的残障人士应当与申请福利的（州或地方）代理机构联系。失聪者、听力或语言障碍者，均可通过联邦电话中转服务与 USDA 联系。电话号码为",
        programInformation:
          "此外，本计划是以用英语之外的其他语言提供计划资讯的。",

        programComplaint:
          "如需提交计划歧视申诉，请填写 USDA 计划歧视申诉表（AD-3027 表），请在以下网站查找该表格：",
        usdaOffice:
          "或到任何 USDA 办事处索取；您亦可写信给 USDA 提出申诉，请在信函中提供申诉表中要求的所有资料。如需索取申诉表，请电洽  ",
        sumbitYourLabel: "请通过以下方法向 USDA 提交填妥的申诉表或信函：",

        mailAddress: "邮寄: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " 传真:",
        orLabel: " 或",
        eamilTitle: "电子邮件: ",
        thisInstution: "本机构是公平机会服务提供者。",
      };

    case languageConsts.VIETNAMESE: //Vietnam
      return {
        termsHeader: "Quyền, Trách Nhiệm và Mức Phạt",
        headerLabel:
          "Thông Báo về Quyền, Trách Nhiệm và Mức Phạt - Vui lòng Đọc Kỹ",
        certifyContents:
          "Tôi xác nhận rằng tôi đã đọc, hoặc đã được đọc cho nghe, những dữ kiện thông tin trong đơn này. Các câu trả lời của tôi cho các câu hỏi trong đơn xin này là đúng và đầy đủ theo sự hiểu biết nhất của tôi. Tôi cũng xác nhận rằng các dữ kiện thông tin mà tôi cung cấp cho Sở này trong buổi phỏng vấn xin trợ cấp và trong tương lai cũng sẽ đúng và đầy đủ theo sự hiểu biết nhất của tôi. Tôi hiểu rằng việc khai man hoặc cung cấp các dữ kiện thông tin không đúng là gian lận. Tôi cũng hiểu rằng việc khai man hoặc che giấu các dữ kiện thông tin để hợp lệ nhận trợ cấp SNAP là gian lận. Việc này sẽ có hậu quả là tội Cố Ý Vi Phạm Chương Trình (IPV) và sẽ bị phạt bởi các hình phạt dân sự và hình sự.",
        understandDta:
          "Tôi hiểu rằng Sở Trợ Cấp Chuyển Tiếp (DHR) quản lý trợ cấp SNAP. Tôi hiểu rằng DHR có 30 ngày kể từ ngày nộp đơn xin để xử lý đơn xin của tôi. Ngoài ra, tôi cũng hiểu rằng:",
        foodNutritionact:
          "Đạo Luật Thực Phẩm và Dinh Dưỡng ban hành năm 2008 (7 U.S.C. 2011-2036) cho phép DHR sử dụng Số An Sinh Xã Hội (SSN) của tôi và SSN của mỗi thành viên hộ gia đình được tôi nộp đơn cho. DHR sử dụng thông tin này để xác định sự hợp lệ nhận trợ cấp SNAP của hộ gia đình tôi. DHR xác minh dữ kiện thông tin này qua các chương trình đối chiếu trên máy điện toán. Tôi hiểu rằng DHR sử dụng các dữ kiện thông tin này để giám sát việc tuân thủ nội quy chương trình.",
        reportingRulesDtachanges:
          "Trong đa số các trường hợp, các hộ gia đình thuộc diện quy định Báo Cáo Đơn Giản của SNAP phải thông báo cho DHR biết về các thay đổi trong bản Báo Cáo Tạm Thời (IR) và tái chứng nhận ngoại trừ :",
        incomeExceeds:
          "Nếu thu nhập của hộ gia đình tôi vượt quá mức ngưỡng tổng thu nhập",
        workRequirementlabel:
          "o	Nếu tôi là người lớn khỏe mạnh không có người phụ thuộc (ABAWD) tuân thủ các yêu cầu về làm việc và số giờ làm việc của tôi giảm xuống dưới 20 giờ mỗi tuần",
        verifiedInfomation:
          "Nếu DHR nhận được thông tin đã xác minh về hộ gia đình tôi, số tiền trợ cấp của tôi có thể thay đổi",
        tbaRulesone:
          "Nếu tôi không thuộc diện qui định trong các quy chế Báo Cáo Đơn Giản của SNAP hoặc các quy chế Thay Thế Trợ Cấp Chuyển Tiếp (TBA), tôi phải báo cáo cho DHR về các thay đổi liên quan đến hộ gia đình tôi mà có thể ảnh hưởng đến sự hợp lệ nhận trợ cấp của chúng tôi. Tôi hiểu rằng tôi phải báo cáo các thay đổi này cho DHR bằng cách đích thân tới gặp, qua thư viết hoặc qua điện thoại ",
        tbaRuletwo: " trong vòng 10 ngày kể từ khi có thay đổi đó.",
        tbaExample:
          " Ví dụ, quý vị phải báo cáo các thay đổi về thu nhập của hộ gia đình của quý vị, số người trong gia đình, hoặc địa chỉ.",
        findmeIneligible:
          "Tôi có quyền nói chuyện với một giám sát viên nếu DHR thấy tôi không hợp lệ nhận trợ cấp SNAP khẩn cấp và tôi không đồng ý với quyết định đó. Tôi có thể nói chuyện với một giám sát viên nếu tôi hợp lệ nhận trợ cấp SNAP khẩn cấp nhưng không nhận được trợ cấp của tôi trong vòng 7 ngày tính theo lịch sau khi nộp đơn xin trợ cấp SNAP. Tôi có thể nói chuyện với một giám sát viên nếu tôi hợp lệ nhận trợ cấp SNAP khẩn cấp nhưng không nhận được Thẻ Chuyển Trợ Cấp qua Mạng Điện Tử (EBT) của tôi trong vòng 7 ngày tính theo lịch sau khi tôi nộp đơn xin trợ cấp SNAP.",
        verificationtoDta:
          "Tôi có thể được nhận thêm trợ cấp SNAP nếu tôi báo cáo và cung cấp chứng từ cho DHR về:",
        childorOtherTitile:
          "chi phí giữ trẻ hoặc chi phí chăm sóc người phụ thuộc khác, chi phí nơi tạm trú, và/hoặc chi phí dịch vụ điện nước",
        legallyObligatedlabel:
          "tiền chu cấp nuôi con theo nghĩa vụ pháp lý đối với một thành viên không ở trong hộ gia đình",
        disabledMedicalcost:
          "Nếu tôi 60 tuổi hoặc già hơn và hoặc nếu tôi bị khuyết tật và tôi trả chi phí y tế, tôi có thể báo cáo và cung cấp chứng từ về các khoản chi phí này cho DHR. Việc này có thể giúp tôi hợp lệ được hưởng một khoản khấu trừ và làm tăng trợ cấp SNAP của tôi.",
        meetExemptionlabel:
          "Trừ khi hội đủ điều kiện được miễn, tất cả những người nhận SNAP tuổi từ 16 đến 59 nào đều phải đăng ký làm việc và phải tuân thủ các Yêu Cầu Làm Việc Tổng Quát của SNAP. Những người nhận SNAP tuổi từ 18 đến 49 cũng có thể phải tuân thủ các yêu cầu của Chương Trình Làm Việc ABAWD. DHR sẽ thông báo cho các thành viên gia đình không thuộc diện được miễn về các yêu cầu làm việc nói trên. DHR sẽ thông báo cho các thành viên gia đình không thuộc diện được miễn về các trường hợp ngoại lệ và các mức phạt trong trường hợp không tuân thủ.",
        voluntarilyParticipatelabel:
          "Đa số những người nhận SNAP có thể tự nguyện tham gia các dịch vụ huấn luyện việc làm và giáo dục qua chương trình Path to Work của SNAP. DHR sẽ giới thiệu hồ sơ đến chương trình SNAP Path to Work nếu thích hợp.",
        dtaNamescontact:
          "DHR cũng sẽ chia sẻ tên và thông tin liên lạc của những người nhận SNAP với các cơ sở cung cấp dịch vụ SNAP Path to Work để tuyển dụng. Tôi hiểu rằng các thành viên trong hộ gia đình của tôi có thể được các chuyên gia SNAP Path to Work hoặc các nhà thầu của DHR liên lạc để tìm hiểu về các lựa chọn tham gia SNAP Path to Work. Để biết thêm tin tức về chương trình SNAP Path to Work, thăm mạng ",
        subjectToverification:
          "Tôi hiểu rằng các dữ kiện thông tin mà tôi cung cấp cùng với đơn xin của tôi sẽ được kiểm tra xác minh để xác định là có trung thực hay không. Nếu bất kỳ dữ kiện thông tin nào là giả mạo, DHR có thể từ chối trợ cấp SNAP của tôi. Tôi cũng có thể bị truy tố hình sự vì cung cấp dữ kiện thông tin giả mạo.",
        signingApplication:
          "Tôi hiểu rằng khi ký đơn xin này, tôi cho phép DHR kiểm tra xác minh và điều tra các dữ kiện thông tin mà tôi cung cấp liên quan đến sự hợp lệ hưởng trợ cấp SNAP của tôi, trong đó bao gồm cả việc cho phép:",
        proveInformation:
          "Có được các giấy tờ để chứng minh dữ kiện thông tin trong đơn xin này với các cơ quan khác của tiểu bang, các cơ quan liên bang, các cơ quan gia cư địa phương, các sở phúc lợi ngoài tiểu bang, các tổ chức tài chánh và từ Equifax Workforce Solutions. Tôi cũng cho phép các cơ quan này cung cấp cho DHR thông tin về hộ gia đình tôi liên quan đến trợ cấp SNAP của tôi.",
        immigrationStatusLabel:
          "Nếu ứng dụng, kiểm tra xác minh diện di trú của tôi qua Sở Di Trú và Nhập Tịch Hoa Kỳ (USCIS). Tôi hiểu rằng DHR có thể xác minh dữ kiện thông tin trong đơn xin SNAP của tôi với USCIS. Bất kỳ dữ kiện thông tin nào nhận được từ USICIS có thể ảnh hưởng đến sự hợp lệ nhận trợ cấp của gia đình tôi và số tiền trợ cấp SNAP.",
        secondaryEducation:
          "Chia sẻ dữ kiện thông tin về tôi và những người phụ thuộc dưới 19 tuổi của tôi với Sở Giáo Dục Tiểu Học và Trung Học Phổ Thông (DESE). DESE sẽ chứng nhận những người phụ thuộc của tôi cho các chương trình bữa sáng và bữa trưa học đường.",
        publicHealth:
          "Chia sẻ dữ kiện thông tin về tôi, những người phụ thuộc dưới 5 tuổi của tôi và bất kỳ ai hiện đang mang thai trong gia đình tôi với Sở Sức Khỏe Công Cộng (DPH). DPH giới thiệu những người này tới Chương Trình Phụ Nữ, Trẻ Sơ Sinh và Trẻ Em (WIC) để nhận các dịch vụ dinh dưỡng.",
        executiveOfficeHealth:
          "Chia sẻ dữ kiện thông tin, cùng với Văn Phòng Điều Hành Dịch Vụ Sức Khỏe và Nhân Sự Alabama, về sự hợp lệ nhận trợ cấp SNAP của tôi với các hãng điện, hãng khí đốt, và các hãng cung cấp dịch vụ truyền hình cáp và điện thoại hợp lệ để chứng nhận sự hợp lệ được giảm giá dịch vụ điện nước của tôi.",
        housingCommuntiy:
          "Chia sẻ dữ kiện thông tin của tôi với Sở Gia Cư và Phát Triển Cộng Đồng (DHCD) để ghi danh cho tôi tham gia Chương Trình Sưởi Ấm và Thực Phẩm (Heat & Eat Program).",
        lowerBenefitslabel:
          "DHR có thể từ chối, ngừng hoặc giảm trợ cấp của tôi dựa trên dữ kiện thông tin từ Equifax Workforce Solutions. Tôi có quyền nhận được một bản miễn phí báo cáo của tôi từ Equifax nếu tôi yêu cầu trong vòng 60 ngày kể từ khi có quyết định của DHR. Tôi có quyền chất vấn mức độ chính xác hoặc đầy đủ của các dữ kiện thông tin trong báo cáo của tôi. Tôi có thể liên lạc với Equifax tại: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146, ",
        tollFreeLabel: "(đường dây miễn phí).",
        rightoKnowbrochure:
          "Tôi hiểu rằng tôi sẽ nhận được một bản sao tờ thông tin 'Quyền Được Biết của Quý Vị' và tờ thông tin về Chương Trình SNAP. Tôi sẽ đọc hoặc sẽ nhờ người đọc các tờ thông tin và tôi phải hiểu nội dung cũng như các quyền và trách nhiệm của tôi. Nếu có thắc mắc về các tờ thông tin hoặc bất kỳ thông tin nào, tôi sẽ liên lạc với DHR. Nếu gặp khó khăn trong việc đọc hoặc hiểu bất kỳ thông tin nào, tôi sẽ liên lạc với DHR. Có thể liên lạc với DHR tại: ",
        lawfullyResiding:
          "Tôi tuyên thề rằng tất cả các thành viên trong hộ gia đình SNAP hiện đang xin trợ cấp SNAP của tôi là công dân Hoa Kỳ hoặc ngoại kiều cư trú hợp pháp.",
        registerVoteTitle: "Quyền Đăng Ký để Bỏ Phiếu",
        registerVoteFirstLabel:
          "Tôi hiểu rằng tôi có quyền đăng ký bỏ phiếu tại DHR. Tôi hiểu rằng DHR sẽ giúp tôi điền mẫu đơn xin đăng ký cử tri nếu tôi cần giúp đỡ. Tôi được phép điền mẫu đơn xin đăng ký cử tri một cách kín đáo.",
        registerVoteSecondLabel:
          "Tôi hiểu rằng việc xin đăng ký hoặc từ chối đăng ký bỏ phiếu sẽ không ảnh hưởng đến số tiền trợ cấp mà tôi nhận được từ DHR. ",
        penaltyWarningTitle: "Cảnh Báo về Mức Phạt SNAP",
        penaltyWarningContent:
          "Tôi hiểu rằng nếu tôi hoặc bất kỳ thành viên nào trong hộ gia đình SNAP của tôi cố ý vi phạm bất kỳ quy chế nào ghi dưới đây, người đó sẽ không hợp lệ nhận trợ cấp SNAP cho một năm sau lần vi phạm đầu tiên, hai năm sau lần vi phạm thứ hai và vĩnh viễn sau lần vi phạm thứ ba. Người đó cũng có thể bị phạt tiền tới mức tối đa là $250.000 (250 ngàn Mỹ kim), phạt tù tới mức tối đa 20 năm hoặc cả hai. Người đó cũng có thể bị truy tố theo các điều luật hiện hành khác của Tiểu Bang và Liên Bang. Các quy chế này là:",
        penaltyWarningOne:
          "Không khai man hoặc che giấu đữ kiện thông tin để được nhận trợ cấp SNAP.",
        penaltyWarningTwo: "Không mua bán trao đổi hoặc bán trợ cấp SNAP.",
        penaltyWarningThree:
          "Không sửa đổi thẻ EBT để được nhận tiền trợ cấp SNAP mà quý vị không hợp lệ nhận.",
        penaltyWarningFour:
          "Không sử dụng trợ cấp SNAP để mua các vật dụng không hợp lệ, chẳng hạn như đồ uống có chất rượu và thuốc lá.",
        penaltyWarningFive:
          "Không sử dụng trợ cấp SNAP hoặc thẻ EBT của người khác, trừ khi quý vị là người được ủy quyền hợp pháp.",

        penaltiesTitle: "Tôi cũng hiểu các mức phạt sau đây: ",
        individualLabel:
          "	Những người phạm tội Cố Ý Vi Phạm Chương Trình (IPV) của ",
        cashProgram: "chương trình trợ cấp tiền mặt ",
        internationalProgram:
          " sẽ không hợp lệ nhận SNAP trong cùng một giai đoạn mà người đó không hợp lệ nhận trợ cấp tiền mặt.",
        penalitiesTwo:
          "Những người khai man về lý lịch hoặc nơi cư trú để được nhận nhiều khoản trợ cấp SNAP cùng một lúc sẽ không hợp lệ nhận trợ cấp SNAP trong  ",
        tenYearLabel: "mười năm",
        penalitiesThree:
          "Những người trao đổi (mua hay bán) trợ cấp SNAP để lấy chất kích thích bất hợp pháp/chất bị kiểm soát, sẽ không hợp lệ nhận SNAP trong  ",
        twoYearLabel: "hai năm  ",
        penalitiesThreeMiddle: "đối với lần tuyên án đầu tiên và ",
        foreverText: "vĩnh viễn  ",
        penalitiesThreeLast: " đối với lần tuyên án thứ hai.",
        penalitiesFour:
          "Những người trao đổi (mua hay bán) trợ cấp SNAP để đổi lấy vũ khí, đạn dược, hoặc chất nổ, sẽ không hợp lệ nhận SNAP",
        penalitiesFive:
          "Những người trao đổi mua bán trợ cấp SNAP có giá trị từ $500 trở lên sẽ không hội đủ điều kiện nhận trợ cấp SNAP ",
        penalitiesSix:
          "Tiểu Bang có thể áp dụng IPV đối với một người mời chào bán trợ cấp SNAP hoặc thẻ EBT trên mạng trực tuyến hoặc gặp trực diện.",
        penalitiesSeven:
          "Những người bỏ trốn để tránh bị truy tố, quản chế hoặc giam giữ sau khi bị kết án phạm trọng tội, hoặc vi phạm điều kiện quản chế hoặc tha bổng có điều kiện, đều không hợp lệ nhận trợ cấp SNAP.",
        penalitiesEight:
          "Không được phép dùng để trả đồ ăn mua nợ và nếu làm như vậy có thể bị mất điều kiện nhận trợ cấp SNAP.",
        penaltiesNine:
          "Các cá nhân không được mua sản phẩm bằng trợ cấp SNAP với mục đích lấy sản phẩm bên trong ra và trả lại hộp đựng để lấy tiền mặt.",

        interpreterHeader: "Quyền có Người Thông Dịch",
        interpreterContent:
          "Tôi hiểu rằng tôi có quyền dùng một thông dịch viên do DHR cung cấp nếu trong hộ gia đình tôi không có người lớn nào có thể nói hoặc hiểu tiếng Anh. Tôi cũng hiểu rằng tôi có thể có một thông dịch viên cho bất kỳ buổi điều trần công bằng DHR nào hoặc mang theo người thông dịch riêng. Nếu cần người thông dịch cho một buổi điều trần, tôi phải gọi Ban Điều Trần ít nhất một tuần trước ngày điều trần.",
        nonDiscriminationHeader: "Tuyền Bố về Không Phân Biệt Đối Xử",
        nonDiscriminationContent:
          "Chiếu theo luật dân quyền Liên Bang và các chính sách và quy chế về dân quyền của Bộ Nông Nghiệp Hoa Kỳ (USDA), USDA, các Cơ Quan, văn phòng, và nhân viên của bộ, cũng như các tổ chức tham gia hoặc điều hành các chương trình của USDA thì bị nghiêm cấm sự phân biệt đối xử vì lý do sắc tộc, màu da, nguồn gốc quốc gia, giới tính, tín ngưỡng, tôn giáo, tình trạng khuyết tật, tuổi, lý tưởng chính trị, hoặc trả đũa hoặc trả thù vì đã từng tham gia hoạt động dân quyền trong bất kỳ chương trình hay hoạt động nào do USDA tổ chức hoặc đài thọ..",
        personDisabilities:
          "Những người khuyết tật cần phương tiện giao tiếp thay thế khác để nhận tin tức về chương trình (ví dụ như chữ nổi Braille, bản in bằng chữ khổ lớn, băng ghi âm, Ngôn ngữ ký hiệu Hoa Kỳ, v.v...), cần liên lạc với văn phòng Cơ quan (Tiểu bang hoặc địa phương) nơi họ nộp đơn xin trợ cấp. Người bị điếc, khiếm thính hoặc có khuyết tật về nói có thể liên lạc với USDA qua Dịch vụ Tiếp Âm Liên Bang tại số ",
        programInformation:
          "Thêm vào đó, tin tức về chương trình có thể được cung cấp bằng các ngôn ngữ khác không phải là tiếng Anh.,",

        programComplaint:
          "Để nộp khiếu nại về phân biệt đối xử liên quan đến chương trình, điền Mẫu Đơn Khiếu Nại về Phân Biệt Đối Xử trong Chương Trình của USDA, (AD-3027), có trên mạng trực tuyến tại: ",
        usdaOffice:
          "và tại bất kỳ văn phòng nào của USDA, hoặc viết thư gửi cho USDA và trong thư cung cấp tất cả các dữ kiện thông tin yêu cầu trong mẫu đơn khiếu nại. Để yêu cầu cung cấp mẫu đơn khiếu nại, vui lòng gọi số ",
        sumbitYourLabel:
          "Nộp mẫu đơn đã điền hoàn tất hoặc thư của quý vị tới cho USDA qua:",

        mailAddress: "qua thư bưu điện: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " fax:",
        orLabel: " hoặc",
        eamilTitle: "email: ",
        thisInstution: "Tổ chức này là một cơ quan cung cấp cơ hội bình đẳng.",
      };
    case HI:
      return {
        termsHeader: "Avi sou Dwa, Responsablite ak Sanksyon",
        headerLabel:
          "Avi sou Dwa, Responsablite ak Sanksyon – Tanpri Li ak anpil Atansyon",
        certifyContents:
          "Mwen sètifye ke m te li, oswa te fè li pou mwen, enfòmasyon ki nan aplikasyon sa a. Repons mwen a kesyon yo nan aplikasyon sa a vre epi konplè selon sa m byen konnen. Mwen sètifye tou ke enfòmasyon mwen bay Depatman an pandan entèvyou aplikasyon an ak alavni pral vre epi konplè selon sa m byen konnen. M konprann ke bay fo enfòmasyon oswa ki twonpe se fwòd. M konprann tou ke defòme oswa kache enfòmasyon pou etabli kalifikasyon SNAP se fwòd. Sa lakòz yon Vyolasyon Pwogram Entansyonèl (IPV) epi ki pini ak sanksyon sivil ak kriminèl.",
        understandDta:
          "M konprann ke Depatman Asistans Tranzisyonèl la (DHR) administre SNAP. M konprann ke DHR gen 30 jou apati dat aplikasyon an pou trete aplikasyon mwen an. Anplis, m konprann ke:",
        foodNutritionact:
          "Lwa sou Manje ak Nitrisyon 2008 la (7 U.S.C. 2011-2036) pèmèt DHR itilize Nimewo Sekirite Sosyal mwen (NSS) ak NSS chak manm fwaye mwen aplike pou yo. DHR itilize enfòmasyon sa a pou detèmine kalifikasyon fwaye mwen pou SNAP. DHR verifye enfòmasyon sa yo atravè pwogram enfòmatik pou matche. M konprann ke DHR itilize li pou kontwole konfòmite ak règleman pwogram yo.",
        reportingRulesDtachanges:
          "Anpil fwa, fwaye yo ki anba règleman SNAP Rapò Senplifye dwe enfòme DHR konsènan chanjman nan Rapò Pwovizwa (IR) ak resètifikasyon eksepte:",
        incomeExceeds: "Si revni fwaye mwen depase limit revni brit la",
        workRequirementlabel:
          "Si mwen anba kondisyon travay Adilt kapab san depandan yo (ABAWD) epi kantite lè travay mwen an desann pi ba pase 20 èdtan pa semèn.",
        verifiedInfomation:
          "Si DHR resevwa enfòmasyon ki verifye Konsènan fwaye m, montan benefis mwen an ka chanje",
        tbaRulesone:
          "Si mwen pa anba règleman SNAP Rapò Senplifye oswa Benefis Altènatif Tranzisyonèl (TBA), mwen dwe rapòte bay DHR chanjman ki fèt nan fwaye m ki ka afekte kalifikasyon nou. M konprann ke mwen dwe rapòte chanjman sa yo bay DHR an pèsòn, alekri oswa pa telefòn ",
        tbaRuletwo: " nan 10 jou apre chanjman an.",
        tbaExample:
          " Pa egzanp, ou dwe rapòte chanjman nan revni, gwosè, oswa adrès fwaye w la.",
        findmeIneligible:
          "Mwen gen yon dwa pou pale ak yon sipèvizè si DHR jwenn mwen pa kalifye pou benefis Ijans SNAP epi m pa dakò. Mwen ka pale ak yon sipèvizè si m kalifye pou benefis SNAP ijans men m pa resevwa benefis mwen nan setyèm jou kalandriye apre mwen te aplike pou SNAP la. Mwen ka pale ak yon sipèvizè si m kalifye pou benefis ijans SNAP men m pa resevwa kat Benefis Transfè Elektwonik (EBT) mwen nan setyèm jou Kalandriye apre mwen te aplike pou SNAP la.",
        verificationtoDta:
          "Mwen ka resevwa plis benefis SNAP si mwen rapòte epi bay DHR verifikasyon pou:",
        childorOtherTitile:
          "depans pou swen timoun oswa lòt depandan, depans pou lojman, ak/oswa depans pou sèvis piblik",
        legallyObligatedlabel:
          "sipò timoun legalman-obligatwa a yon manm ki pa nan fwaye a",
        disabledMedicalcost:
          "Si m gen 60 an oswa plis oswa si mwen andikape epi mwen peye pou depans medikal, mwen ka rapòte epi bay DHR verifikasyon depans sa yo. Sa ka fè m kalifye pou yon dediksyon epi ogmante benefis SNAP mwen.",
        meetExemptionlabel:
          "Sòf si yo satisfè yon egzanpsyon, tout benefisyè SNAP yo ki genyen ant laj 16 ak 59 an enskri pou travay epi anba Kondisyon Jeneral Travay SNAP. Benefisyè SNAP ki genyen ant laj 18 ak 49 an kapab tou anba kondisyon Pwogram Travay ABAWD. DHR pral enfòme manm fwaye yo ki pa gen egzanpsyon konsènan kondisyon travay yo. DHR pral enfòme manm fwaye yo ki pa gen egzanpsyon konsènan eksepsyon ak sanksyon pou non-konfòmite.",
        voluntarilyParticipatelabel:
          "Pifò benefisyè SNAP yo ka volontèman patisipe nan sèvis edikasyon ak fòmasyon pou travay atravè pwogram Path to Work SNAP la. DHR pral bay referans nan pwogram Path to Work SNAP la si sa apwopriye",
        dtaNamescontact:
          "DHR ka pataje tou non ak enfòmasyon kontak benefisyè SNAP yo ak founisè Path to Work yo pou rezon rekritman. M konprann ke manm fwaye mwen yo ka kontakte pa espesyalis DHR SNAP Path to Work oswa founisè kontra pou eksplore opsyon patisipasyon nan SNAP Path to Work.",
        subjectToverification:
          "M konprann ke enfòmasyon mwen bay ak aplikasyon m nan pral sijè a verifikasyon pou detèmine si li vre. Si nenpòt enfòmasyon fo, DHR ka refize benefis SNAP mwen yo. Mwen ka tou sijè a pwosekisyon kriminèl paske m te bay fo enfòmasyon.",
        signingApplication:
          "M konprann ke nan siyen aplikasyon sa a, mwen bay DHR pèmisyon pou verifye epi mennen ankèt sou enfòmasyon mwen bay ki lye ak kalifikasyon m pou benefis SNAP, ki enkli pèmisyon pou:",
        proveInformation:
          "Jwenn dokiman pou pwouve enfòmasyon nan aplikasyon sa a ak lòt ajans etatik, ajans federal, otorite lojman lokal, depatman byennèt andeyò eta a, enstitisyon finansye ak soti nan Equifax Workforce Solutions. Mwen bay ajans sa yo pèmisyon tou pou bay DHR enfòmasyon sou fwaye m nan ki konsène benefis SNAP mwen yo.",
        immigrationStatusLabel:
          "Si sa aplikab, verifye estati imigrasyon mwen atravè Sèvis Sitwayènte ak Imigrasyon Etazini (USCIS). M konprann ke DHR ka verifye enfòmasyon ki nan aplikasyon SNAP mwen ak USCIS. Tout enfòmasyon yo resevwa nan men USCIS ka afekte kalifikasyon fwaye m ak montan benefis SNAP.",
        secondaryEducation:
          "Pataje enfòmasyon konsènan mwen menm ak depandan mwen yo ki poko gen 19 an ak Depatman Edikasyon Elemantè ak Segondè (DESE). DESE pral sètifye depandan mwen yo pou pwogram manje maten ak manje midi nan lekòl. ",
        publicHealth:
          "Pataje enfòmasyon konsènan mwen, depandan mwen ki poko gen laj 5 an ak nenpòt moun ki ansent nan fwaye m nan ak Depatman Sante Piblik (DPH). DPH refere moun sa yo bay Pwogram Fanm, Tibebe ak Timoun (WIC) pou sèvis nitrisyon.",
        executiveOfficeHealth:
          "Pataje enfòmasyon, ansanm ak Biwo Egzekitif Sante ak Sèvis Imen Alabama la, sou kalifikasyon m pou SNAP ak konpayi elektrik, konpayi gaz ak konpayi telefòn ak kab ki kalifye pou sètifye kalifikasyon m nan tarif redui pou rabè sèvis piblik.",
        housingCommuntiy:
          "Pataje enfòmasyon m ak Depatman Lojman ak Devlopman Kominotè (DHCD) nan objektif pou enskri m nan Pwogram Heat & Eat a. ",
        lowerBenefitslabel:
          "DHR ka refize, sispann oswa diminye benefis mwen yo baze sou enfòmasyon ki soti nan Equifax Workforce Solutions. M gen dwa pou jwenn yon kopi rapò mwen an gratis nan men Equifax si mwen mande li nan 60 jou apre desizyon DHR a. M gen dwa pou poze kesyon sou presizyon oswa eta konplè enfòmasyon ki nan rapò mwen an. Mwen ka kontakte Equifax nan: Equifax Workforce Solutions, 11432 Lackland Road, St. Louis, MO 63146, 1-800-996-7566  ",
        tollFreeLabel: "",
        rightoKnowbrochure:
          "M konprann ke m pral jwenn yon kopi bwochi “Your Right to Know” a ak bwochi Pwogram SNAP la. M pral li oswa te fè li bwochi yo epi mwen dwe konprann kontni yo ak dwa ak responsablite mwen yo. Si m gen nenpòt kesyon konsènan bwochi yo oswa nenpòt nan enfòmasyon sa yo, mwen pral kontakte DHR. Si m gen pwoblèm pou li oswa konprann nenpòt nan enfòmasyon sa yo, mwen pral kontakte DHR. Ou ka kontakte DHR nan:",
        lawfullyResiding:
          "Mwen fè sèman ke tout manm fwaye SNAP mwen yo k ap mande benefis SNAP se swa Sitwayen Ameriken swa Non-sitwayen Ameriken ki rezide legalman.",
        registerVoteTitle: "Dwa pou Enskri pou Vote",
        registerVoteFirstLabel:
          "M konprann ke mwen gen dwa enskri pou vote nan DHR. Mwen konprann ke  DHR pral ede m ranpli fòm aplikasyon pou enskripsyon elektè a si mwen bezwen èd. Mwen gen dwa ranpli fòm aplikasyon pou enskripsyon elektè a an prive.",
        registerVoteSecondLabel:
          "M konprann ke nan aplike pou m enskri oswa refize enskri pou vote pa pral afekte montan benefis mwen resevwa nan men DHR. ",
        penaltyWarningTitle: "Avètisman sou Sanksyon SNAP",
        penaltyWarningContent:
          "M konprann ke si mwen menm oswa nenpòt manm fwaye SNAP mwen vyole nenpòt nan règleman ki anba yo, moun sa a pa pral kalifye pou SNAP pou yon ane apre premye vyolasyon an, dezan apre dezyèm vyolasyon an epi pou tout tan apre twazyèm vyolasyon an. Moun sa a ka peye tou yon amann jiska $250,000, ale nan prizon jiska 20 an, oswa toulede. Yo kapab tou sijè a pousuit anba lwa Federal ak Etatik. Règleman sa yo se:",
        penaltyWarningOne:
          "Pa bay fo enfòmasyon oswa kache enfòmasyon pou resevwa benefis SNAP.",
        penaltyWarningTwo: "Pa chanje oswa vann benefis SNAP.",
        penaltyWarningThree:
          "Pa chanje kat EBT pou resevwa benefis SNAP ou pa kalifye pou yo.",
        penaltyWarningFour:
          "Pa itilize benefis SNAP pou achte atik ki pa kalifye, tankou bwason alkolize ak tabak.",
        penaltyWarningFive:
          "Pa itilize SNAP oswa kat EBT yon lòt moun, sof si ou se yon reprezantan otorize.",

        penaltiesTitle: "M konprann tou sanksyon ki anba yo: ",
        individualLabel: "Moun yo ki komèt yon pwogram",
        cashProgram: "kach Pwogram",
        internationalProgram:
          " Vyolasyon Entansyonèl (IPV), yo pa pral kalifye pou SNAP pou menm peryòd moun nan pa kalifye pou asistans lajan kach.",
        penalitiesTwo:
          "Moun yo ki fè yon deklarasyon fwòd sou idantite oswa rezidans yo pou resevwa plizyè benefis SNAP alafwa pa pral kalifye pou SNAP ",
        tenYearLabel: "pou dizan.",
        penalitiesThree:
          "Moun ki fè echanj ak (achte oswa vann) benefis SNAP pou yon sibstans kontwole/dwòg ilegal (yo), yo pa pral kalifye pou SNAP ",
        twoYearLabel: "pou dezan ",
        penalitiesThreeMiddle: "pou premye dekouvèt la, epi ",
        foreverText: "pou tout tan ",
        penalitiesThreeLast: "pou dezyèm dekouvèt la.",
        penalitiesFour:
          "Moun ki fè echanj ak (achte oswa vann) benefis SNAP pou zam afe, minisyon oswa eksplozif, yo pa pral kalifye pou SNAP",
        penalitiesFive:
          "Moun ki fè echanj ak (achte oswa vann) benefis SNAP ki gen yon valè $500 oswa plis, pa pral kalifye pou SNAP",
        penalitiesSix:
          "Eta a ka pousuiv yon IPV kont yon moun ki fè yon òf pou vann benefis SNAP oswa yon kat EBT an liy oswa an pèsòn.",
        penalitiesSeven:
          "Moun ki sove pou evite pousuit, detansyon oswa prizon apre yo fin kondane pou yon krim, oswa ki vyole pwobasyon oswa libète sou kondisyon, yo pa kalifye pou SNAP.",
        penalitiesEight:
          "Peye pou manje achte kredi pa otorize epi ka menmen nan diskalifikasyon pou SNAP.",
        penaltiesNine:
          "Moun pa gen dwa achte pwodui ak benefis SNAP ak entansyon pou jete kontni yo epi retounen resipyan pou lajan kach.",

        interpreterHeader: "Dwa a yon Entèprèt",
        interpreterContent:
          "M konprann ke mwen gen dwa pou jwenn yon entèprèt DHR bay si okenn adilt nan fwaye SNAP mwen pa kapab pale oswa konprann angle. Mwen konprann tou ke mwen ka jwenn yon entèprèt pou nenpòt odyans DHR san patipri oswa mennen pwòp entèprèt mwen. Si m bezwen yon entèprèt pou yon odyans, mwen dwe rele Divizyon Odyans lan omwen yon semèn anvan dat odyans lan.",
        nonDiscriminationHeader: "Deklarasyon Non-diskriminasyon",
        nonDiscriminationContent:
          "Nan konfòmite ak lwa sou dwa sivil federal yo ak règleman ak politik Depatman Agrikilti Ameriken (USDA), USDA, ajans li yo, biwo, ak anplwaye li yo, ak enstitisyon k ap patisipe nan oswa ap administre pwogram USDA yo, yo entèdi pou fè diskriminasyon ki baze sou ras, koulè, orijin nasyonal, sèks, kwayans relijye, andikap, laj, konviksyon politik, oswa reprezay oswa vanjans pou aktivite dwa sivil anvan nan nenpòt pwogram oswa aktivite ki fèt oswa ki finanse pa USDA.",
        personDisabilities:
          "Moun andikape ki bezwen lòt mwayen kominikasyon pou enfòmasyon sou pwogram yo (pa egzanp Bray, gwo lèt, kasèt odyo, Lang Siy Ameriken, elatriye), ta dwe kontakte Ajans (Etatik oswa lokal) la kote yo te aplike pou benefis yo. Moun ki soud, ki pa tande byen oswa ki gen andikap pou pale ka kontakte USDA atravè Sèvis Federal Relay la nan ",
        programInformation:
          "Anplis, enfòmasyon sou pwogram yo ka disponib nan lòt lang ki pa Angle.",

        programComplaint:
          "Pou depoze yon plent diskriminasyon kont yon pwogram, ranpli Fòm Program Discrimination Complaint USDA a, (AD-3027) ke w ap jwenn an liy nan: ",
        usdaOffice:
          "ak nan nenpòt biwo USDA, oswa ekri yon lèt ki adrese bay USDA epi mete nan lèt la tout enfòmasyon yo mande nan fòm nan. Pou mande yon kopi fòm plent lan, rele (866) 632-9992. ",
        sumbitYourLabel: "Soumèt fòm oswa lèt ranpli w la bay USDA nan:",

        mailAddress: "lapòs: U.S. Department of Agriculture",
        addressOne: " Office of the Assistant Secretary for Civil Rights",
        addressVenue: " 1400 Independence Avenue, SW",
        addressState: "Washington, D.C. 20250-9410",
        fax: " faks: (202) 690-7442;",
        orLabel: " oswa",
        eamilTitle: "imel:  program.intake@usda.gov.",
        thisInstution: "Enstitisyon sa a se yon founisè opòtinite egal.",
      };
    default:
      return;
  } //terms tranlation ending
}
