export function covidRecertReducer(state = {}, action) {
  switch (action.type) {
    case "GET_COVIDRECERT_DATA_FULFILLED": {
      let newState = Object.assign({}, state);
      newState.responseObject = action.payload;
      return newState;
    }
    case "COVID_RECERT_APPLY_SNAP_FULLFILLED": {
      let newState = Object.assign({}, state);
      newState.sections = action.payload;
      return newState;
    }

    case "APPEND_COVID_HOME_HOLD_DATA": {
      let newState = Object.assign({}, state);
      newState.sections[2].subsections = action.payload;
      return newState;
    }
    case "APPEND_COVID_INCOME_DATA": {
      let newState = Object.assign({}, state);

      newState.sections[3] = action.payload;
      return newState;
    }

    case "APPEND_UI_INCOME_DATA":
    case "APPEND_COVID_EXPENSE_DATA": {
      let newState = Object.assign({}, state);

      newState.sections[4] = action.payload;
      return newState;
    }

    case "FILL_INPUT_VALUES_STATE": {
      return state;
    }

    case "DOWNLOAD_PDF_COVID_APPLICATION": {
      return state;
    }

    case "@@redux/INIT": {
      const applyInit = Object.assign({}, state);
      applyInit.sections = [];
      applyInit.AnswerObject = {};
      applyInit.dalReference = {};
      return applyInit;
    }

    //sutudent list screen
    case "COVID_ADD_STUDENT_TO_LIST": {
      const interimState = Object.assign({}, state);
      interimState.sections[4].subsections = action.payload;
      return interimState;
    }

    //Child Support screen
    case "COVID_SET_CHILD_SUPPORT": {
      const interimState = Object.assign({}, state);
      interimState.childExpScreen = action.payload;
      return interimState;
    }

    //Contact info screen
    case "COVID_SET_CONTACT_INFO": {
      const interimState = Object.assign({}, state);
      interimState.contactInfoScreen = action.payload;
      return interimState;
    }

    case "COVID_ADD_ADD_CONTACT_INFO_SECTION": {
      const interimState = Object.assign({}, state);
      interimState.sections[1].subsections = action.payload;
      return interimState;
    }

    //Household info screen
    case "COVID_SET_HOUSEHOLD_MEMBERS": {
      const interimState = Object.assign({}, state);
      interimState.householdMembers = action.payload;
      return interimState;
    }

    case "COVID_ADD_ADD_HOUSEHOLD_INFO_SECTION": {
      const interimState = Object.assign({}, state);
      interimState.sections[2].subsections = action.payload;
      return interimState;
    }

    //To set yes no selection status
    case "COVID_ADD_DATA_CHANGE_STATUS": {
      const interimState = Object.assign({}, state);
      interimState.sections[action.index].selectionMade = action.payload;
      return interimState;
    }

    //Earned income
    case "SET_EARNED_INCOME_STATUS": {
      const interimState = Object.assign({}, state);
      interimState.earnedIncomeChanged = action.payload;
      return interimState;
    }

    //Earned income
    case "SET_UNEARNED_INCOME_STATUS": {
      const interimState = Object.assign({}, state);
      interimState.unearnedIncomeChanged = action.payload;
      return interimState;
    }

    case "SET_CHILD_SUPPORT_CHANGED": {
      const interimState = Object.assign({}, state);
      interimState.childSupportChanged = action.payload;
      return interimState;
    }

    case "SET_INTERIM_REPORT_RESPONSE": {
      const interimState = Object.assign({}, state);
      interimState.responseObject = action.payload;
      return interimState;
    }
    case "POST_INTERIM_DATA_FULFILLED": {
      const interimState = Object.assign({}, state);
      interimState.responseNumbers = action.payload;
      return interimState;
    }

    default:
      return state;
  }
}
