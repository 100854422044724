export function bindAnswers(section, activeId, AnswerObj) {
  section.questions.map((question) => {
    if (AnswerObj[activeId] && AnswerObj[activeId][question.name] && AnswerObj[activeId][question.name] !== undefined) {
      question.Answer = AnswerObj[activeId][question.name];
      question.targetValue = AnswerObj[activeId][question.name];
      question.value = AnswerObj[activeId][question.name];
      //Conditions
      if (question.conditionals) {
        question.conditionals.map((condition) => {
          //Question Itration
          condition.conditionalQuestions.map((conditionQuestion) => {
            if (AnswerObj[activeId] && AnswerObj[activeId][conditionQuestion.name] && AnswerObj[activeId][conditionQuestion.name] !== undefined) {
              conditionQuestion.Answer =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.targetValue =
                AnswerObj[activeId][conditionQuestion.name];
              conditionQuestion.value =
                AnswerObj[activeId][conditionQuestion.name];
            } else {
              if(section.parentId !== "Dependentcarecosts"){
                conditionQuestion.Answer = "";
                conditionQuestion.targetValue = "";
                conditionQuestion.value = "";
              }
            }
            return conditionQuestion;
          });
          return condition;
        });
      }
    } else if (question.conditionals) {
      question.conditionals.map((condition) => {
        //Question Itration
        condition.conditionalQuestions.map((conditionQuestion) => {
          if (AnswerObj[activeId] && AnswerObj[activeId][conditionQuestion.name] && AnswerObj[activeId][conditionQuestion.name] !== undefined) {
            conditionQuestion.Answer =
              AnswerObj[activeId][conditionQuestion.name];
            conditionQuestion.targetValue =
              AnswerObj[activeId][conditionQuestion.name];
            conditionQuestion.value =
              AnswerObj[activeId][conditionQuestion.name];
          }
          return conditionQuestion;
        });
        return condition;
      });
    }else {
      if (
        (AnswerObj[activeId] === undefined && question.Answer === undefined) ||
        question.Answer === undefined
      ) {
        question.Answer = "";
        question.targetValue = "";
        question.value = "";
      }
    }
    return question;
  });
  return section;
}