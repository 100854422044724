const appHomeUrl = "https://dev.falcon.kyybaapps.com" // "https://xxxxxxx.xxxxxx.xxx"

const newTigerURL = "http://44.219.248.34:8080/tiger" // "https://xxxxxxx.xxxxxx.xxx/tiger"
//const newTigerURL = "http://localhost:8443"


const rights_en_url = "https://www.mass.gov/files/documents/2019/03/19/snap-rightsresponstext-english-0319.pdf"
const rights_es_url = "https://www.mass.gov/files/documents/2019/03/19/snap-rightsresponstext-spanish-0319.pdf"
const rights_pt_url = "https://www.mass.gov/files/documents/2019/03/19/snap-rightsresponstext-brazilianportuguese-0319.pdf"
const rights_zh_url = "https://www.mass.gov/files/documents/2019/03/19/snap-rightsresponstext-simplifiedchinese-0319.pdf"
const rights_vi_url = "https://www.mass.gov/files/documents/2019/03/19/snap-rightsresponstext-vietnamese-0319.pdf"

const programComplainLink = "https://www.ascr.usda.gov/complaint_filing_cust.html"

const source = "dtaconnect-website"
const azureLoginFlag = false;
const addressValidate = false;
const idleTimeout = "1200000"
const applyTimeOut = "120000"

//google analytics Configurations
const TRACKING_ID = "UA-115036897-2" //DEV ENV Only
const CLIENT_ID = "112764375"
const RECAPTCHA_FLAG = false

//site key for Dev
const SITE_KEY = "6Le59U4UAAAAAD8qjIyoy3g67K8RKSjHdiGBMLgT"
const SECRETE_KEY = "6Le59U4UAAAAAKU1sUBsjlxcP8vyo7E3m86WGT5r"

const LOGIN_SCREEN_DATA = {
    username : "Username",
    password: "Passsword"
}

const TIGER_LOGIN = {
    username: "AL Client Connect Portal (ACCP)",
    password: "AL@PPC@NNECT20230220Client"
}

const ALLOWED_DOMAIN = "*"
// Config object to be passed to Msal on creation
const msalConfig = {
    auth: {
        clientId: "e2e7cce2-821c-40fa-8177-2498f3bacf24",
        authority: 'https://conduent0879.b2clogin.com/conduent0879.onmicrosoft.com/B2C_1A_POC_DEV_EMAIL_SIGNUP_SIGNIN',
        knownAuthorities: ["conduent0879.b2clogin.com"],
        redirectUri: appHomeUrl,
        postLogoutRedirectUri: appHomeUrl,
        // response_type: "id_token",
        // prompt: "login",
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};


const loginRequest = {
     scopes: ['https://conduent0879.onmicrosoft.com/TodoList/ToDoList.Read', 'https://conduent0879.onmicrosoft.com/TodoList/ToDoList.ReadWrite'],
     authority: 'https://conduent0879.b2clogin.com/conduent0879.onmicrosoft.com/B2C_1A_POC_DEV_EMAIL_SIGNUP_SIGNIN',
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const signUpSignIn = {
    authority: 'https://conduent0879.b2clogin.com/conduent0879.onmicrosoft.com/B2C_1A_POC_DEV_EMAIL_SIGNUP_SIGNIN'
};

const forgotPasswordRequest = {
    authority: 'https://conduent0879.b2clogin.com/conduent0879.onmicrosoft.com/B2C_1A_POC_DEV_EMAIL_SIGNUP_SIGNIN',
}

 const editProfile ={
    authority: 'https://conduent0879.b2clogin.com/conduent0879.onmicrosoft.com/B2C_1A_POC_DEV_EMAIL_SIGNUP_SIGNIN',
}
//
// const signUp = {
//     authority: 'https://cndtb2ctest.b2clogin.us/cndtb2ctest.onmicrosoft.us/B2C_1A_SIGNUP_SIGNIN',
//     // extraQueryParameters: { idps: 'signup', capp: 'dta' , domain_hint: 'signup'}
//     extraQueryParameters: {  domain_hint: 'signup'}
//
// }

const postLogoutRedirectUri = {
    redirectUri: appHomeUrl
}

const tenant = {
    url:"conduent0879.onmicrosoft.com"
}

const names = {
    signUpSignIn: "B2C_1A_POC_DEV_EMAIL_SIGNUP_SIGNIN",
    forgotPassword: "B2C_1A_POC_DEV_EMAIL_SIGNUP_SIGNIN",
    editProfile: "B2C_1A_POC_DEV_EMAIL_SIGNUP_SIGNIN"
}

module.exports = {
    appHomeUrl: appHomeUrl,
    tigerURL: newTigerURL,
    rights_en_url: rights_en_url,
    rights_es_url: rights_es_url,
    rights_pt_url: rights_pt_url,
    rights_zh_url: rights_zh_url,
    rights_vi_url: rights_vi_url,
    applyTimeOut: applyTimeOut,
    idleTimeout: idleTimeout,
    TRACKING_ID: TRACKING_ID,
    CLIENT_ID: CLIENT_ID,
    RECAPTCHA_FLAG: RECAPTCHA_FLAG,
    SITE_KEY: SITE_KEY,
    SECRETE_KEY: SECRETE_KEY,
    source: source,
    login_screen_data : LOGIN_SCREEN_DATA,
    tiger_login : TIGER_LOGIN,
    allowed_domain : ALLOWED_DOMAIN,
    programComplainLink: programComplainLink,
    msalConfig:msalConfig,
    loginRequest:loginRequest,
    forgotPasswordRequest: forgotPasswordRequest,
    signUp: signUpSignIn,
    postLogoutRedirectUri: postLogoutRedirectUri,
    tenant:tenant,
    names:names,
    azureLoginFlag:azureLoginFlag,
    addressValidate:addressValidate
}