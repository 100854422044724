import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import CSSTransition from "react-transition-group/CSSTransition";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import Loading from "../../utils/components/loading";
import SidebarNavigation from "../../utils/components/sidebar/sidebarNavigation";
import { getAudit } from "../../utils/constants/audit";
import LanguageSelect from "../../redux/language/languageSelect";
import * as OptionsProps from "../../utils/constants/options";
import * as roleProps from "../../utils/constants/rolesConstants";
import * as TextProps from "../../utils/constants/text";
import * as Props from "../../utils/components/shared";
import Checkbox from "../../utils/components/checkbox";
import DTAInputSubmit from "../../utils/components/dtaInputSubmit";
import DTADropdown from "../../utils/components/dtaDropdown";
import InterimApplicationEN from "../../utils/resources/pdfs/Interim-ApplicationEN.pdf";
import InterimApplicationES from "../../utils/resources/pdfs/Interim-ApplicationES.pdf";
import {acesButtonClicked, languageChanged} from "../../redux/applySnap/applySnapActions";
import * as appConfigConstants from "../../../src/utils/constants/appConfigConstants";
import {
  onLogOut,
  onAzureLogOut,
} from "../../utils/helper/accountNavigationActions";
import Modal from "../../utils/components/modal/dtaModal";
import Radio from "../../utils/components/radio";
import * as Types from "../../utils/constants/types";

//azure
import { withMsal } from "@azure/msal-react";
// apply specific imports
import {
  getNextSection,
  getPreviousSection,
} from "../../utils/helper/pageRoutingHelper";
import InterimSection from "./components/recertInterimReportSection";
import * as UnitsProps from "../../utils/constants/units";
import * as ControlConstants from "../../utils/constants/controls";
import {
  IncomeSectionTemplate,
  unearnedIncomeSectionTemplate,
  unearnedIncomeSummaryTemplate,
  ethinicityCodeMapper,
  relationshipCodeMapper,
  raceCodeMapper,
  genderCodeMapper,
} from "./recertInterimReportMapData";
import * as languageConstants from "../../utils/constants/constants";
import {
  interimReportPageLabels,
  interimWarningPopupMLanguageLabels,
  incomeDynamicMultilanguageLabels,
  unearnedIncomeDynamicMultilanguageLabels,
} from "./recertInterimReportText";
import {
  formAddressDisplayValue,
  formatAddressDisplay,
  todaysDateOrTime,
  checkIfAddressIsUpdated
} from "../applySnap/applySnapHelper";
import * as formatters from "../../utils/components/utilities/formatters";
// Redux implementations
import { connect } from "react-redux";
import {
  addContactInfoSubSection,
  setDataChangeStatus,
  setHouseholdMembers,
  setContactInfo,
  addStudentToList,
  applyIRSanpQuestionsSections,
  appendIRHouseHoldMembers,
  clearHouseHoldMembers,
  appendIRIncomeDetails,
  appendUIIncomeDetails,
  appendShelterSections,
  clearMemberIncome,
  clearMemberUIIncome,
  appendIRExpenseDetails,
  updateResolveQuestionsData,
  downloadIRPDFData,
  updateCurrentSectionData,
  setEarnedIncomeChangeStatus,
  setUnearnedIncomeChangeStatus,
  setInterimReportResponseObj,
  updateHouseholdTextChanges,
  setChildSupportData,
  snapRefreshQuestionsSections,
  addHouseholdAddSectionToList,
  displayPDFData,
  postInterimReportData,
  appendShelterFilteredQuestions,
  setShelterData,
  setShelterOptions,
  saveInterimApplication,
  onPrimaryActionSaveClickedAction,
  // getInterimSavedDataForUser,
  appendChildSupportDetails,
  appendIRShelterQuestions,
  appendChildSupportAnswer,
  setChildSupportOptions,
  deleteInterimApplication,
  appendInterimDataForSummary,
  enableNavigationForSections,
  applyAddressStorageAPI,
  addressValidation,
  setRadioButtonFlags
} from "../../redux/recertInterimReport/recertInterimReportActions";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import {
  filterQuestionsBySelection,
  toValidateInputErros,
  bindAnswers,
} from "./recertInterimReportHelper";
import {
  getFrequencyCode,
  getFrequency,
  getShelterandChildSupportFrequency
} from "../../utils/components/utilities/utils";
import { getMainSections } from "./components/recertInterimReportSummary";
//start code for google analytics
import * as googleAnalyticsConstVar from "../../utils/constants/googleAnalyticsConst";
import StartForm from "../../utils/components/startForm/beforeYouStart";
import ContactInfo from "../../utils/components/contactInfo";
import HouseholdInfo from "../../utils/components/householdInfo";
import EarnedIncome from "./components/earnedIncome";
import UnearnedIncome from "./components/unearnedIncome";
import ShelterExpenses from "./components/ShelterExpenses";
import {getShelterExpenseFrequency} from "./components/ShelterExpenses";

import ChildSupportExpenses from "./components/childSupportExpense";
import SubmitSummary from "./components/submitSummary";
import { otherInfoCodeMapper } from "./otherInfoMapData";
import { unearnedIncomeCodeMapper } from "./unearnedIncomemapData";
import { earnedIncomeCodeMapper } from "./earnedIncomeMapData";
import * as routeProps from "../../utils/constants/routesConstants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as types from "../../utils/constants/types";
import * as modalHelperFunction from "../../utils/components/helperFunctions/modalHelperFunction";
import Icon from "../../utils/components/dtaIcon";
import DocumentTitle from "react-document-title";
import * as config from "../../utils/constants/appConfigConstants";
import { shelterUtilitySectionTemplate,expenseChildSupportTemplate, contactInfoSummaryTemplate, householdSummaryTemplate, sections } from "./recertInterimReportMapData";
import SnapSixMonthReportContainer from "./components/SnapSixMonthReportContainer";
import {
  addressValidationObj,
  appendUpdatedAdrress,
} from "../recertification/recertificationHelper";
import {
  beforeYoustartMlanguageLabels
} from "./recertInterimReportText";

var ua = require("universal-analytics");
var visitor = ua(
  googleAnalyticsConstVar.TRACKING_ID,
  googleAnalyticsConstVar.CLIENT_ID,
  { strictCidFormat: TextProps.VALUE_FALSE }
);
//end code for google analytics
var rights_en_url =
  require("../../utils/constants/appConfigConstants").rights_en_url;
var rights_es_url =
  require("../../utils/constants/appConfigConstants").rights_es_url;
var rights_pt_url =
  require("../../utils/constants/appConfigConstants").rights_pt_url;
var rights_vi_url =
  require("../../utils/constants/appConfigConstants").rights_vi_url;
var rights_zh_url =
  require("../../utils/constants/appConfigConstants").rights_zh_url;

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

const EN = "en-US";
const ES = "es-ES";
const PT = "pt-BR";
const ZH = "zh-Hans";
const VI = "vi-VI";
const HI = "ht";

if (language === null || language === languageConstants.langOptSel) {
  language = EN;
}
// For tesing resume apply feautre
// var resumeApply = TextProps.VALUE_FALSE;

// apply specific configuration
// ID's used in config correspond to section ID's in apply/data/applySections and apply/data/screeningSection
const pageConfig = {
  // Show screening section or not.
  screening: TextProps.VALUE_TRUE,
  // The ID of the section desired to be active initially.
  activeSectionId: "before-you-start",
  // The ID of the section last in progress.
  inProgressSectionId: "sign-and-submit",
};

/* NOTE
 * There are many 'key' properties used in this apply area. Iterated items need
 * unique key's compared to their siblings when using React. They don't necessarily
 * need to use the key approach we've chosen though, so long as keys are set and remain unique.
 * That being said, don't use index as a key, since index is reassigned with each render.
 * https://facebook.github.io/react/docs/reconciliation.html#keys
 */

class InterimReport extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.renderApplyQuestions = this.renderApplyQuestions.bind(this);
    this.onPrimaryActionClick = this.onPrimaryActionClick.bind(this);
    this.acceptTermsConditions = this.acceptTermsConditions.bind(this);
    this.updateSignature = this.updateSignature.bind(this);
    this.downloadApplicationAsPdf = this.downloadApplicationAsPdf.bind(this);
    this.downloadIRPDFData = this.downloadIRPDFData.bind(this);
    this.updateHouseHoldIncomeDetails =
      this.updateHouseHoldIncomeDetails.bind(this);
    this.onIncomeSubsectionRender = this.onIncomeSubsectionRender.bind(this);
    this.onUnearnedIncomeSubsectionRender =
      this.onUnearnedIncomeSubsectionRender.bind(this);
    this.onIncomeSubSectionClean = this.onIncomeSubSectionClean.bind(this);
    this.onUnearnedIncomeSubSectionClean =
      this.onUnearnedIncomeSubSectionClean.bind(this);
    this.incomefilterQuestionsBySelection =
    this.incomefilterQuestionsBySelection.bind(this);
    this.handleRemove= this.handleRemove.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.preLoadData = this.preLoadData.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onSecondaryActionClick = this.onSecondaryActionClick.bind(this);
    // this.onGoBackAction = this.onGoBackAction.bind(this);
    this.renderCounter = 0;
    this.nonRenderCounter = 0;
    this.incomeSubSections = [];
    this.unearnedIncomeSubSections = [];
    this.incomeSummary = {};
    this.shelterSummary={};
    this.isShowSaveSuccessModal = true;
    this.isShowWarningModalFlag = false;
    this.existingtypes = [
      "TAFDCS",
      "RSDI",
      "SSI",
      "VETBEN",
      "UNEMPL",
      "WRKCMP",
      "INTINC",
      "MILPE",
      "RETFND",
      "RRRET",
      "LIHEAP",
      "RENTAL",
      "CHSDOR",
      "ALIMNY",
      "CONTR",
      "OTHER"
    ];
    this.existingEItypes = ["WAGES", "SELEMP", "BOARD"];
    this.shelterDoNotPayOptions = [
    "我不支付任何公用设施费用",
    "No pago ningún gasto de alojamiento.",
    "Eu não pago por serviços públicos",
    "Tôi không trả bất kỳ chi phí tiện ích nào",
    "Mwen pa peye okenn frè pou sèvis piblik",
    "I do not pay for any shelter expenses"
    ];
    this.state = {
      referenceNumber: null,
      docReferenceNumber: null,
      agencyID: null,
      sections: [],
      renderedSections: [],
      activeSectionId: pageConfig.activeSectionId,
      previousSectionId: null,
      inProgressSectionId: pageConfig.inProgressSectionId,
      addIncomeMembersOpen: TextProps.VALUE_FALSE,
      saveProgressModalOpen: TextProps.VALUE_FALSE,
      WarningProgressModalOpen: TextProps.VALUE_FALSE,
      viewApplicationDataPopupOpen: TextProps.VALUE_FALSE,
      directionClass: "forward",
      houseHoldSubMemberTemplate: null,
      houseHoldSubDetailTemplate: null,
      houseHoldSubSectionCleared: TextProps.VALUE_FALSE,
      incomeMemberTemplate: null,
      incomeSummaryTemplate: null,
      incomeSubSectionCleared: TextProps.VALUE_FALSE,
      unearnedIncomeMemberTemplate: null,
      unearnedIncomeSummaryTemplate: null,
      unearnedIncomeSubSectionCleared: TextProps.VALUE_FALSE,
      childSupportSubSectionCleared: TextProps.VALUE_FALSE,
      incomeValuesPopulated: TextProps.VALUE_FALSE,
      incomeUIValuesPopulated: TextProps.VALUE_FALSE,
      unearnedIncomeValuesPopulated: TextProps.VALUE_FALSE,
      contactInfoSummaryChanged: TextProps.VALUE_FALSE,
      houseMemberLabels: [],
      dependentsParentsLabels: [],
      dependentsChildsLabels: [],
      dependentsCareCostsMemberLabels: [],
      dependentCareCostsTemplate: null,
      dependentCareCostsSubSectionCleared: TextProps.VALUE_FALSE,
      medicalExpenseSubSectionCleared: TextProps.VALUE_FALSE,
      medicalExpenseMemberTemplate: null,
      kidsAdultExpenseSubSectionCleared: TextProps.VALUE_FALSE,
      kidsAdultExpenseMemberTemplate: null,
      incomeSummaryEdit: TextProps.VALUE_FALSE,
      incomeUnearnedSummaryEdit: TextProps.VALUE_FALSE,
      childSupportSummaryEdit: TextProps.VALUE_FALSE,
      languageChangeWarning:TextProps.VALUE_FALSE,
      c: TextProps.VALUE_FALSE,
      rights: 0,
      signature: "",
      AnswerObject: [],
      oldHouseHoldSizeValue: 0,
      //For contact info screens
      email: "John.doe@example.com",
      phoneNumber: "(413) 444-4444",
      emailOptIn: TextProps.VALUE_TRUE,
      textOptIn: TextProps.VALUE_FALSE,
      editPhone: TextProps.VALUE_FALSE,
      editEmail: TextProps.VALUE_FALSE,
      editAddress: TextProps.VALUE_FALSE,
      sameMailingAddress: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      //For household info screens
      addHouseholdMember: TextProps.VALUE_FALSE,
      reportedChanges: TextProps.VALUE_FALSE,
      requestBody: {},
      respData: null,
      agreeError: TextProps.VALUE_FALSE,
      signatureError: TextProps.VALUE_FALSE,
      houseHoldMembers: [
        {
          id: 1,
          firstName: "John",
          lastName: "Smith",
          dob: "16.Aug.1982",
          isGrantee: TextProps.VALUE_TRUE,
          citizenship: "Legal permanent resident",
        },
        {
          id: 2,
          firstName: "Mary",
          lastName: "Smith",
          dob: "04.July.1980",
          isGrantee: TextProps.VALUE_FALSE,
          citizenship: "Immigrant worker (H1B)",
        },
        {
          id: 3,
          firstName: "Bob",
          lastName: "Smith",
          dob: "01.Feb.1980",
          isGrantee: TextProps.VALUE_FALSE,
          citizenship: "Noncitizen",
        },
      ],
      earnedIncomeChangeFlag: TextProps.VALUE_FALSE,
      unearnedIncomeChanged: TextProps.VALUE_FALSE,
      sectionDataUpdated:TextProps.VALUE_FALSE,
      // exitStatusValue: TextProps.VALUE_FALSE,
      saveStatusValue: TextProps.VALUE_FALSE,
      onPrimaryActionSaveClicked:TextProps.VALUE_FALSE,
      shelterProcessedQuestions:[],
      childSupportSummaryTemplate:null,
      acesButtonClickWarningShowModal:TextProps.VALUE_FALSE,
      acesButtonClickWarningRedirectPage: TextProps.VALUE_FALSE,
      saveExitShowModal:false,
      deleteWarningShowModal:false,
      isPreLoadDone:false,
      addressValidationModalOpen: TextProps.VALUE_FALSE,
      enteredResAddressValue: "",
      selectedResAdressValue: "",
      enteredMailingAddressValue: "",
      selectedMailingAddressValue: "",
      addressObj: {},
      finalResValueSelected: "",
      finalMailingValueSelected: "",
      mailingAddressFlag: TextProps.VALUE_FALSE,
      resAddressFlag: TextProps.VALUE_FALSE,
      MelissaApiResponded: true,
      addressDatasections: {},
      saveOrExitWarningShowModal: TextProps.VALUE_FALSE,
      saveORExitWarningRedirectPage: TextProps.VALUE_FALSE,
      exitStateValue: TextProps.VALUE_FALSE,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentWillMount = () => {
    var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
    var userDetails = JSON.parse(sessionStorage.getItem("loadConsumerUser"));
    var recertFlag, benifitListInfo;
    if (
      userObject !== null &&
      userObject !== undefined &&
      userDetails !== null &&
      userDetails !== undefined
    ) {
      benifitListInfo = userDetails.benefitInfo;
      if (userObject && benifitListInfo) {
        for (let listType of benifitListInfo) {
          if (
            listType.alertMessages.SNAP_FLAG &&
            listType.alertMessages.SNAP_FLAG === TextProps.VALUE_YES &&
            listType.alertMessages.RECERT_TYPE === "IR" &&
            this.props.interimReport.responseObject
          ) {
            recertFlag = TextProps.VALUE_YES;
          }
        }
        var userRole = userObject.roleList[1]
          ? userObject.roleList[1].roleName
          : userObject.roleList[0].roleName;
      }
    }
    if (
      recertFlag === TextProps.VALUE_YES &&
      (userRole === roleProps.CONSUMER ||
        userRole === roleProps.PROVIDER ||
        userRole === roleProps.PROVIDER_ROLE_SPVUSR ||
        userRole ===
        roleProps.PROVIDER_ROLE_DCPVSG) /*&& this.props.interimReport.responseObject */
    ) {
      //continue
    } else {
      this.props.gotoConsumer();
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !_.isEqual(this.props, nextProps);
    const stateChanged = !_.isEqual(this.state, nextState);
    const webAppIdChanged = this.props.match.params.webAppId !== undefined;
    
    if (propsChanged || stateChanged || webAppIdChanged) {
      this.renderCounter++;
    } else {
      this.nonRenderCounter++;
    }

    if (
      nextState.sections[0] !== undefined &&
      nextState.sections[0].isSubmitInitiated &&
      nextState.sections[0].isSubmitted
    ) {
      return true; // Return true to trigger a re-render when conditions are met
    }
  
    return propsChanged || stateChanged || webAppIdChanged;
  }

  componentWillReceiveProps(nextProps) {
    let activeSectionId = this.props.interimReport.additionalProperties && this.props.interimReport.additionalProperties.activeId
          ?  this.props.interimReport.additionalProperties.activeId : pageConfig.activeSectionId;
    // let activeSectionId = pageConfig.activeSectionId;     
    if (this.props.interimReport.sections.length > 0) {
      this.setState(
        {
          sections: this.props.interimReport.sections,
          respData: this.props.interimReport.responseObject,
        },
        () => {
          if (this.state.renderedSections.length < 1) {
            this.setState({
              renderedSections: [this._getSection(pageConfig.activeSectionId)],
              submitFormId: this.nextUniqueId(),
              activeSectionId: pageConfig.activeSectionId,
              discontinuedSection:activeSectionId
            },()=>{
              if (activeSectionId && activeSectionId !== "before-you-start") {
                this.preLoadData();
              }
            });
          }
        }
      );
    }
      if (
        nextProps.apply && nextProps.apply.islanguageChanged != undefined &&
        nextProps.apply.islanguageChanged === true
      ) {
        // if (this.state.sectionDataUpdated) {
          this.setState({
            languageChangeWarning: TextProps.VALUE_TRUE,
          });
        }
    if (
      nextProps.apply.isAcesButtonClicked != undefined &&
      nextProps.apply.isAcesButtonClicked === true
    ) {
      if (this.state.sectionDataUpdated) {
        this.setState({
          acesButtonClickWarningShowModal: TextProps.VALUE_TRUE,
        });
      } else {
        this.redirectToHomePage();
       }
    }
  }

   //Function to handle aces button close popup actions(close/cancel)
   closeAcesButtonClickWarningModal = (status, isClose, event) => {
    if (status) {
      sessionStorage.setItem("acesButtonClickedFlag", true);
      // this.onPrimaryActionClick(event, TextProps.ACES);
      this.onPrimaryActionClick(event, TextProps.SAVEEXIT);
    }
    this.setState({
      acesButtonClickWarningShowModal: TextProps.VALUE_FALSE,
      acesButtonClickWarningRedirectPage: TextProps.VALUE_TRUE,
    });
    if (isClose) {
      sessionStorage.setItem("acesButtonClickedFlag", false);
      this.setState({
        sectionDataUpdated: TextProps.VALUE_FALSE,
      });
    }
    this.props.acesButtonClicked({value:false, path:""})
  };

  //Render A DHR modal for ACES button click updated info
  renderACESButtonClickWarningModal = () => {
    return (
      <Modal
        isOpen={this.state.acesButtonClickWarningShowModal}
        onClose={() =>
          this.closeAcesButtonClickWarningModal(TextProps.VALUE_FALSE, true)
        }
        modalClass="save-exit-modal"
        titleText="save or exit Warning"
        headerText={
          interimWarningPopupMLanguageLabels(language).areYouSureWarningLabel
        }
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {interimWarningPopupMLanguageLabels(language).saveOrExitLabel}
          </p>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={(event) =>
                this.closeAcesButtonClickWarningModal(
                  TextProps.VALUE_FALSE,
                  false,
                  event
                )
              }
            >
              {interimWarningPopupMLanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeAcesButtonClickWarningModal(
                  TextProps.VALUE_TRUE,
                  false,
                  event
                )
              }
            >
              {interimWarningPopupMLanguageLabels(language).saveAndExit}
            </button>
          </div>
        </div>
      </Modal>
    );
};
renderlanguageSwitchWarningModal = () => {

  return (
    <div style={{ height: "100%" }}>
      <div className="full-height apply ir-container" style={{ overflowY: "unset" }}>
      <div style={{ display: "contents" }}>
    <Modal
      isOpen={this.state.languageChangeWarning}
      onClose={() =>{this.props.languageChanged({ isCancel: true}),
         this.setState({languageChangeWarning:TextProps.VALUE_FALSE})}}
          modalClass="save-exit-modal"
      titleText="delete Warning"
      headerText={interimWarningPopupMLanguageLabels(language).deleteButtonLabel}
    >
      <div className="dta-modal__body pad-all">
        <p className="text--small">
          {interimWarningPopupMLanguageLabels(language).areYouSureWarningLabel}
        </p>
        <p className="text--small"> 
        {interimWarningPopupMLanguageLabels(language).languageWarningLabel} 
        </p>
        <br/>
        <div className="dta-modal__footer--inline-buttons pure-u-1">
          <button
            className="dta-button dta-button--outline-primary"
            onClick={() =>{
              this.props.languageChanged({ isCancel: true})
              this.setState({languageChangeWarning:TextProps.VALUE_FALSE})}
            }
          >
            {interimWarningPopupMLanguageLabels(language).CancelButtonLabel}
          </button>
          <button
            className="dta-button dta-button--primary"
            onClick={() =>{ this.closeDeleteWarningModal(TextProps.VALUE_TRUE), 
              this.props.languageChanged({ isDelete: true})}}
          >
            {interimWarningPopupMLanguageLabels(language).deleteButtonLabel}
          </button>
        </div>
      </div>
    </Modal>
    </div>
    </div>
    </div>
  );
};
  // redux apply sections loading
  async componentDidMount() {
    await this.props.applyIRSanpQuestionsSections();
    //Remove other info section if no question exist
    if (
      this.props.interimReport.responseObject &&
      this.props.interimReport.responseObject.otherInformation
    ) {
      let otherInfo =
        this.props.interimReport.responseObject.otherInformation
          .workRequirement;
      if (otherInfo === undefined || (otherInfo && otherInfo.length === 0)) {
        if (
          this.props.interimReport.sections.length > 0 &&
          this.props.interimReport.sections[6].id === "Other information"
        ) {
          this.props.interimReport.sections.splice(6, 1);
        }
      }
    }

    this.setState({
      earnedIncomeChangeFlag: !this.state.earnedIncomeChangeFlag,
      unearnedIncomeChanged: this.state.unearnedIncomeChanged,
    });
    this.updateDimensions();
    window.addEventListener("mousemove", this.updateDimensions);
    window.addEventListener("click", this.updateDimensions);
  }
  updateDimensions() {
    let mainElems = document.getElementsByClassName("apply__content");
    let applyCont = document.getElementsByClassName("apply_free_comm");
    let appHeight = document.getElementsByClassName("app");
    let bodyTag = document.getElementsByTagName("body");
    let modalHeight = document.getElementsByClassName("dta-modal");

    const isFreeComOpen = sessionStorage.getItem("openFreeComm");
    var contentHeight = 0;
    var applyfreeCommHeight = 0;
    var modalPopupHeight = 0;

    if (bodyTag) {
      for (let elems of bodyTag) {
        if (elems.style) {
          elems.style.height = "auto";
        }
      }
    }

    if (isFreeComOpen === "false") {
      let applyCont = document.getElementsByClassName("apply_free_comm");
      let appHeight = document.getElementsByClassName("app");
      var applyfreeCommHeight = 0;

      if (applyCont) {
        for (let elems of applyCont) {
          applyfreeCommHeight += elems.scrollHeight;
        }
      }
      if (appHeight) {
        for (let elems of appHeight) {
          if (elems.style) {
            elems.style.height = `${applyfreeCommHeight}px`;
          }
        }
      }
    } else {
      if (mainElems) {
        for (let elems of mainElems) {
          contentHeight = elems.scrollHeight;
        }
      }
      if (applyCont) {
        for (let elems of applyCont) {
          applyfreeCommHeight += elems.scrollHeight;
        }
      }
      if (modalHeight) {
        for (let elems of modalHeight) {
          modalPopupHeight += elems.scrollHeight;
        }
      }
      if (appHeight) {
        for (let elems of appHeight) {
          if (elems.style) {
            elems.style.height = `${
              contentHeight + applyfreeCommHeight + modalPopupHeight
            }px`;
          }
        }
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("mousemove", this.updateDimensions);
    window.addEventListener("click", this.updateDimensions);
  }
  // In this lifecycle hook, use the ref of the application container to reset
  // the scroll when the page changes. Not intended for production
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeSectionId !== prevState.activeSectionId) {
      helperFunction._animatedScroll();
      window.scrollTo(0, 0);
    }
  }

  changeNavigation = () => {
    return new Promise((resolve, reject) => {
  
      setTimeout(() => {
        if (
          this.props.interimReport.responseObject &&
          this.props.interimReport.responseObject.additionalProperties
        ) {
          if (
            this.props.interimReport.additionalProperties &&
            this.props.interimReport.additionalProperties.activeId
          ) {
  
            const nextSection = this._getSection(this.props.interimReport.additionalProperties.activeId);
            this.onSectionChange(nextSection);
          }
        }
        resolve(true)

      }, 100);
    });
  }

  preLoadData = async() =>{
    let finalSummary = this.props.interimReport.summaryData ? this.props.interimReport.summaryData : [];
    if(this.props.interimReport.responseObject){
    this.props.setHouseholdMembers({
      membersListUpdated: _.cloneDeep(this.props.interimReport.responseObject.householdMembers).filter(item => item.dataChangeCode !== "NOCHNG").length > 0 ? true : false,
      addHouseholdMember: TextProps.VALUE_FALSE,
      membersList: _.cloneDeep(
        this.props.interimReport.responseObject.householdMembers
      ),
    });

    await this.setContactInitialData();
    await  this.onIncomeSubSectionClean();
    await this.onUnearnedIncomeSubSectionClean();
      await this.onUnearnedIncomeSubsectionRender();
    await this.onChildSupportSubSectionClean();
      await this.setChildSupportInitialData();
    await this.onIncomeSubsectionRender();
    await this.shelterSubsectionRender();
      await this.markCompletedSections(this.props.interimReport.sections,finalSummary);
      await this.changeNavigation();
      await this.enableNavigation(this.props.interimReport.sections);
      await this.setRadioButtonFlags();  
      // await this.props.enableNavigationForSections(this.props.interimReport.sections);
    }
  }

  changeNavigation = async() =>{
      return new Promise((resolve,reject)=>{
    setTimeout(() => {
      if (
        this.props.interimReport.responseObject &&
        this.props.interimReport.responseObject.additionalProperties
      ) {
        if (
          this.props.interimReport.additionalProperties &&
          this.props.interimReport.additionalProperties.activeId
        ) {

          const nextSection = this._getSection(this.props.interimReport.additionalProperties.activeId);
          this.onSectionChange(nextSection,TextProps.VALUE_TRUE);
        }
      }
          resolve();
        }, 500);
    })
  }

  setRadioButtonFlags = async() =>{
    this.props.setRadioButtonFlags(this.props.interimReport.responseObject.additionalProperties)
  }

  markCompletedSections = async(sections,finalSummary) => {
    let discontinuedFound = false;
    const processSection = async (section) => {
      if(!section) return
      const currentSectionData = this.getSectionDataForSummary(section.id);
      const summaryData = this.getSectionWithBindAnswers(section.id, currentSectionData);
      section.completed = TextProps.VALUE_TRUE;
      this.applySectionDataChangeStatus(section.id);
      //bypass logic for save and exit flow
      if(section.id === "shelter-utility-summary"){
        section.selectionMade = TextProps.VALUE_TRUE;
      }
      if(summaryData !== undefined) {
        finalSummary.push(summaryData);
        this.props.appendInterimDataForSummary(finalSummary);
      }
      if (section.id === this.state.discontinuedSection) {
        discontinuedFound = true; 
        return discontinuedFound;
        }
      if (section.subsections && section.subsections.length > 0 && !discontinuedFound) {
        for (const subsection of section.subsections) {
          const foundInSection = await processSection(subsection);
          if (foundInSection) return true;
      }
    }
      return discontinuedFound;
    };
    for (const section of sections) {
      if (!section || discontinuedFound) break; // Exit loop if discontinued section is found or section is null/undefined
      discontinuedFound = await processSection(section);
  }
  };
  
  
  enableNavigation = async(sections) => {
    const { additionalProperties } = this.props.interimReport.responseObject;

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].id === this.state.discontinuedSection) {
        let activeId = sections[i].id;
        var activeIdSplit = sections[i].id.split("-");
        var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
        var activeIdSubString = activeIdSplit.join("-");
        if(activeIdSubString === "combined-income-subsection") {
          this.incomefilterQuestionsBySelection(activeSplitMemberIndex);
        }
        if(activeIdSubString === "unearned-combined-income-subsection") {
          this.incomeUnearnedfilterQuestionsBySelection(
            activeSplitMemberIndex
          );
        }
        if(activeIdSubString === "shelter-expense") {
          this.shelterQuestionsBySelection();
        }

        if (additionalProperties && additionalProperties.hasOwnProperty('sectionData') && additionalProperties.sectionData.hasOwnProperty(activeId)) {
          sections[i].questions[0].Answer = additionalProperties.sectionData[activeId];
          sections[i].questions[0].targetValue = additionalProperties.sectionData[activeId];
        }
      if(sections[i] !== null && sections[i].hasOwnProperty('selectionMade')){
        sections[i].selectionMade = true;
          this.props.enableNavigationForSections(sections);
      }
        break;
      } else {
        if (sections[i].subsections && sections[i].subsections.length > 0) {
          this.enableNavigation(sections[i].subsections);
          if(sections[i].hasOwnProperty('selectionMade')){
            sections[i].selectionMade = true;
          }
        }
      }
    }
    }
  

  setChildSupportInitialData = async() => {
    let childExpMembersNames = [];
    let tempExistList = [];
    let tempNonExistList = [];
    let childExpResponseObj = _.cloneDeep(
      this.props.interimReport.responseObject.childSupportExpense
    );
    if (childExpResponseObj && childExpResponseObj.length > 0) {
      childExpResponseObj.map((child) => {
        child.dataChangeCode = child.dataChangeCode;
        child.isOpen = TextProps.VALUE_FALSE;
        child.originalAmount = child.amount + "";
        child.originalFrequency = child.frequency;
        child.isComplete = TextProps.VALUE_TRUE;
        child.clientId= child.firstName+ " "+ child.lastName+child.dateOfBirth;
        tempExistList.push(child);
        childExpMembersNames.push(
          child.firstName + " " + child.lastName + child.dateOfBirth
        );
      });

      this.props.interimReport.householdMembers.membersList.map((member) => {
        let memName =
          member.firstName + " " + member.lastName + member.dateOfBirth;

        if (memName.indexOf(childExpMembersNames) === -1) {
          let child = {
            id: 0,
            setID: 0,
            agencyID: member.agencyID,
            firstName: member.firstName,
            lastName: member.lastName,
            dateOfBirth: member.dateOfBirth,
            typeCode: "CHDSPT",
            frequencyCode: "",
            amount: "",
            dataChangeCode: null,
            isOpen: TextProps.VALUE_FALSE,
            isComplete: TextProps.VALUE_FALSE,
          };
          tempNonExistList.push(child);
        }
      });

      let tempChildExpArray = _.concat(tempExistList, tempNonExistList);

      this.setState(
        {
          tempChildExpArray: tempChildExpArray,
        },
        () => {
          this.props.setChildSupportData({
            childExpChanged: this.state.dataQuestionChanged,
            tempChildExpArray: this.state.tempChildExpArray,
          });
        }
      );
      } else {
      this.props.interimReport.householdMembers.membersList.map((member) => {
        if (member.dataChangeCode === "removed") return;

        let child = {
          id: 0,
          setID: 0,
          agencyID: member.agencyID,
          firstName: member.firstName,
          lastName: member.lastName,
          dateOfBirth: member.dateOfBirth,
          typeCode: "CHDSPT",
          frequencyCode: "",
          amount: "",
          dataChangeCode: null,
          isOpen: TextProps.VALUE_FALSE,
          isComplete: TextProps.VALUE_FALSE,
        };
        tempNonExistList.push(child);
      });

      let tempChildExpArray = tempNonExistList;

      this.setState(
        {
          childExpChanged: this.state.dataQuestionChanged,
          tempChildExpArray: tempChildExpArray,
        },
        () => {
          this.props.setChildSupportData({
            childExpChanged: this.state.dataQuestionChanged,
            tempChildExpArray: this.state.tempChildExpArray,
          });
        }
      );
      }
  };

  //Updating current sections
  _updateCurrentSection(sectionId) {
    let tickCurrentSection = {};
    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        tickCurrentSection = found;
        if (sectionObj.subsections) {
          const numSub = sectionObj.subsections.length;
          for (let i = 0; i < numSub; ++i) {
            if (sectionObj.subsections[i] && sectionObj.subsections[i].id === sectionId) {
              tickCurrentSection.completed = TextProps.VALUE_TRUE;
            }
          }
          if (sectionObj.id === sectionId) {
            tickCurrentSection.completed = TextProps.VALUE_TRUE;
            return tickCurrentSection;
          }
        } else {
          tickCurrentSection.completed = TextProps.VALUE_TRUE;
          return tickCurrentSection;
        }
      }
    });
  }


  onSectionChange = (
    section,
    primaryButtonClick = TextProps.VALUE_FALSE,
    callback = null
  ) => {
  
    if (primaryButtonClick === TextProps.VALUE_TRUE) {
      //Check if user has changed any info on any section
      let dataChange = this.checkForDataChange(this.state.activeSectionId);
      //If the user has not changed any info then simply return TextProps.VALUE_FALSE to stay on the same section
      if (dataChange === TextProps.VALUE_FALSE) {
        this.forceUpdate();
        return;
      }
    }
    if (section.disabled === TextProps.VALUE_TRUE) return;
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    const incomingSection = this._getSection(section.id);
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: section.id,
          rights: 0,
          signature: "",
          agreeError: TextProps.VALUE_FALSE,
          signatureError: TextProps.VALUE_FALSE,
        });

        if(callback && typeof callback === 'function'){
          callback();
        }
      }
    );
  };


  //Get Language and Appened to Answer

  setLanguageInScreen = () => {
    let currentLanguageCode = this.props.selectedLanguage;
    let currentLanguage;
    let selectedAnswer =
      this.props.interimReport.sections[1].subsections[2].questions[1].Answer;
    if (selectedAnswer === undefined) {
      switch (currentLanguageCode) {
        case types.ENGLISH:
          currentLanguage = "English";
          break;
        case types.SPANISH:
          currentLanguage = "Spanish";
          break;
        case types.PORTUGUESE:
          currentLanguage = "Portuguese";
          break;
        case types.CHINESE:
          currentLanguage = "Chinese";
          break;
        case types.VIETNAMESE:
          currentLanguage = "Vietnamese";
          break;
        case HI:
          currentLanguage = "Haitian Creole";
          break;
        default:
          currentLanguage = "English";
          break;
      }

      this.props.interimReport.sections[1].subsections[2].questions[1].Answer =
        currentLanguage;
      this.props.interimReport.sections[1].subsections[2].questions[1].targetValue =
        currentLanguage;
    }
  };

  redirectConsumerHome = () => {
    this.props.gotoConsumer();
  };

  _handleApplyLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.authToken = this.props.consumer.mfaDetails.authToken;
    this.props.onLogOut(paramsLogout);
    this.props.gotoHome();
  };

  _handleApplyGoBack = () => {
    this.setstate({ sections: [] });
  };

  _dynamicSectionIdMatch = (activeId) => {
    switch (activeId) {
      case "my-household":
        return TextProps.VALUE_TRUE;

      case "income":
        return TextProps.VALUE_TRUE;
      case "unearned-income":
        return TextProps.VALUE_TRUE;
      default:
        return TextProps.VALUE_FALSE;
    }
  };

  // Section Link click continue
  onSectionLinkClick = (event) => {
    if (event) {
      event.preventDefault();
    }
   const activeId = this.state.activeSectionId;
    const { sections } = this.props.interimReport;
    const nextSectionID = getNextSection(activeId, sections);
    if (nextSectionID) {
      const nextSection = this._getSection(nextSectionID);
      this.setState(
        {
          directionClass: "forward",
        },
        () => {
          this.onSectionChange(nextSection);
        }
      );
    }
  };

  returnMemberId(member, incomeSub) {
    let membId;
    if (member.code === "new") {
      membId = 0;
    } else {
      if (incomeSub.fullIncomeObj !== undefined && incomeSub.fullIncomeObj.id) {
        membId = incomeSub.fullIncomeObj.id;
      } else {
        membId = 0;
      }
    }
    return membId;
  }

  returnAgencyID(memberObj, incomeSub) {
    let agencyID;
    if (
      incomeSub.fullIncomeObj !== undefined &&
      incomeSub.fullIncomeObj.agencyID
    ) {
      agencyID = incomeSub.fullIncomeObj.agencyID;
    } else {
      if (memberObj !== undefined && memberObj.agencyID) {
        agencyID = memberObj.agencyID;
      } else {
        agencyID = 0;
      }
    }

    return agencyID;
  }

  checkForDataChange = (activeId) => {
    if (!this.props.interimReport.responseObject) return;
    const responseBody = _.cloneDeep(this.state.respData);
    const {additionalProperties} = this.props.interimReport.responseObject;
    let requestBody = _.cloneDeep(responseBody);

    //Get changes reported
    let reportedChanges = TextProps.VALUE_FALSE;
    //Get data change question selection
    let dataChangeSelection = TextProps.VALUE_FALSE;
    //Flag to identify what content to show in the warning popup
    let fullContentInPopup = TextProps.VALUE_FALSE;
    if (activeId === "contact-info") {
      if (this.props.interimReport.contactInfoScreen.contactInfoChanged) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        //address change
        let respAddress = responseBody.clientAddress;
        let respMailAddress = null;
        let respResAddress = null;

        for (let address of respAddress) {
          if (address.typeCode === "RES") respResAddress = address;
          else if (address.typeCode === "MAIL") respMailAddress = address;
        }

        let updatedAddress =
          this.props.interimReport.contactInfoScreen.addressList.address;
        let updatedMailAddress = null;
        let updatedResAddress = null;

        for (let newAddress of updatedAddress) {
          if (
            newAddress.typeCode === "RES" &&
            newAddress.city &&
            newAddress.line1 &&
            newAddress.zip
          )
            updatedResAddress = newAddress;
          else if (newAddress.typeCode === "MAIL")
            updatedMailAddress = newAddress;
        }

        let finalAddressArray = [];
        if (respResAddress !== null && updatedResAddress !== null) {
          if (
            this.checkForAddressChange(respResAddress, updatedResAddress) ===
            "changed" ||
            this.checkForAddressChange(respResAddress, updatedResAddress) ===
            "removed" || (additionalProperties && (additionalProperties.resAddressCode === "changedLabel" || additionalProperties.resAddressCode === "removedLabel"))
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
          //Homeless flag check
          if (
            this.props.interimReport.contactInfoScreen.addressList.homeless !==
            respResAddress.homelessFlag
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        } else if (respResAddress === null && updatedResAddress !== null) {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (respResAddress !== null && updatedResAddress === null) {
          reportedChanges = TextProps.VALUE_TRUE;
        }

        //Mail Addre check
        if (respMailAddress !== null && updatedMailAddress !== null) {
          if (
            this.checkForAddressChange(respMailAddress, updatedMailAddress) ===
            "changed" ||
            this.checkForAddressChange(respMailAddress, updatedMailAddress) ===
            "removed" || (additionalProperties && (additionalProperties.mailingAddressCode === "changedLabel" || additionalProperties.mailingAddressCode === "removedLabel"))
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
          //Homeless flag check
          if (
            this.props.interimReport.contactInfoScreen.addressList.homeless !==
            respMailAddress.homelessFlag || (additionalProperties && (additionalProperties.homelssFlagCode === "changedLabel" || additionalProperties.homelssFlagCode === "removedLabel"))
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        } else if (respMailAddress === null && updatedMailAddress !== null) {
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (respMailAddress !== null && updatedMailAddress === null) {
          reportedChanges = TextProps.VALUE_TRUE;
        }

        requestBody.clientAddress = finalAddressArray;

        //Email change
        let respEmail = responseBody.clientEmail;
        let updatedEmail = this.props.interimReport.contactInfoScreen;
        if (
          (!respEmail.emailAddress && updatedEmail.email) ||
          (respEmail.emailAddress && !updatedEmail.email) ||
          (respEmail.emailAddress &&
            respEmail.emailAddress !== updatedEmail.email) ||
          (respEmail.optInFlag !== undefined &&
            respEmail.optInFlag !== null &&
            respEmail.optInFlag !== updatedEmail.emailOptIn)
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }

        //Phone change
        let respPhone = responseBody.clientPhone;
        let updatedPhone = this.props.interimReport.contactInfoScreen;
        let phoneNumber = updatedPhone.phoneNumber
          ? updatedPhone.phoneNumber.replace(/\D/g, "")
          : "";
        if (
          (!respPhone.phoneNumber && phoneNumber) ||
          (respPhone.phoneNumber && !phoneNumber) ||
          (respPhone.phoneNumber && respPhone.phoneNumber !== phoneNumber) ||
          (respPhone.textOptInFlag !== undefined &&
            respPhone.textOptInFlag !== null &&
            respPhone.textOptInFlag !== updatedPhone.textOptIn)
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }
      this.setDataCodeChangeFlags();
    } else if (activeId === "my-household") {
      //HOUSEHOLD CHANGE
      if (this.props.interimReport.householdMembers.membersListUpdated) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let newHouseholdList = [];
        for (let memberList of this.props.interimReport.householdMembers
          .membersList) {
          if (
            memberList.allAnswers !== undefined ||
            (memberList.dataChangeCode &&
              memberList.dataChangeCode !== "NOCHNG")
          ) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        }

        requestBody.householdMembers = newHouseholdList;
      }
    }
     else if (activeId === "income-summary") {
      //EARNED INCOME CHANGE
      if (
        ((this.props.interimReport.earnedIncomeChanged !== undefined && this.props.interimReport.grossEarnedIncomeChanged !== undefined) &&
          (this.props.interimReport.earnedIncomeChanged === TextProps.VALUE_TRUE || this.props.interimReport.grossEarnedIncomeChanged === TextProps.VALUE_TRUE))
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let allIncome = _.cloneDeep(this.incomeSummary.incomeMembers);
        let incomeArrayExisting = _.cloneDeep(
          this.props.interimReport.responseObject.earnedIncome
        );

        let incomeArrayNew = [];
        allIncome.forEach((member) => {
          let memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            incomeArrayExisting
          );
          if (memberObj === null) {
            memberObj = this.getMemberDataObjectByNameAndDOB(
              member.firstName,
              this.props.interimReport.householdMembers.membersList
            );
          }
          if (member.incomes !== undefined) {
            member.incomes.forEach((income) => {
              let typeCode = null;
              if (
                income !== undefined &&
                income.Answer !== undefined &&
                income.Answer.length > 0
              ) {
                income.Answer.forEach((incomeSub) => {
                  let amountArray = [];
                  let amountIterator = 1;
                  if (incomeSub.amount) {
                    if (incomeSub.unit === "Weekly") {
                      amountIterator = 4;
                    } else if (
                      incomeSub.unit === "Biweekly (every two weeks)"
                    ) {
                      amountIterator = 2;
                    }

                    for (let i = 0; i < amountIterator; i++) {
                      amountArray.push({
                        amount: Number(incomeSub.amount[i]),
                      });
                    }
                  }

                  let changeCode = "NOCHNG";
                  if (incomeSub.code === "new" || incomeSub.code === "NEW") {
                    changeCode = "NEW";
                  } else if (incomeSub.code === "changed" || incomeSub.code === "CHANGE") {
                    changeCode = "CHANGE";
                  } else if (incomeSub.code === "removed" || incomeSub.code === "REMOVE") {
                    changeCode = "REMOVE";
                  }
                  
                  if (changeCode !== "NOCHNG")
                    reportedChanges = TextProps.VALUE_TRUE;

                  typeCode = income.typeCode;

                  let incomeObj = {
                    id: this.returnMemberId(member, incomeSub),
                    setID:
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.setID
                        ? incomeSub.fullIncomeObj.setID
                        : 0,
                    agencyID: this.returnAgencyID(memberObj, incomeSub),
                    firstName:
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.firstName
                        ? incomeSub.fullIncomeObj.firstName
                        : memberObj.firstName,
                    lastName:
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.lastName
                        ? incomeSub.fullIncomeObj.lastName
                        : memberObj.lastName,
                    dateOfBirth: this.convertDOBForDataPost(
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.dateOfBirth
                        ? incomeSub.fullIncomeObj.dateOfBirth
                        : memberObj.dateOfBirth
                    ),
                    typeCode: typeCode,
                    employerName: incomeSub.employer,
                    frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                    amounts: amountArray,
                    dataChangeCode: changeCode,
                  };

                  incomeArrayNew.push(incomeObj);
                }); // income sub ends
              }
            }); // income ends
          }
        }); // member ends

        incomeArrayExisting.forEach((existing) => {
          existing.dateOfBirth = this.convertDOBForDataPost(
            existing.dateOfBirth
          );

          let found = TextProps.VALUE_FALSE;
          incomeArrayNew.forEach((newIncome) => {
            if (
              newIncome.id === existing.id &&
              newIncome.agencyID === existing.agencyID &&
              newIncome.typeCode === existing.typeCode
            ) {
              found = TextProps.VALUE_TRUE;
            }
          });
          if (found === TextProps.VALUE_FALSE) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        });
      }
      if (reportedChanges === TextProps.VALUE_FALSE)
        fullContentInPopup = TextProps.VALUE_TRUE;
    } else if (activeId === "unearned-income-summary") {
      //UNEARNED INCOME CHANGE
      if (
        this.props.interimReport.sections.length > 4 &&
        this.props.interimReport.unearnedIncomeChanged !== undefined &&
        this.props.interimReport.unearnedIncomeChanged === TextProps.VALUE_TRUE
      ) {
        dataChangeSelection = TextProps.VALUE_TRUE;
        let allIncome = _.cloneDeep(this.incomeSummary.unearnedIncomeMembers);
        let incomeArrayExisting = _.cloneDeep(
          this.props.interimReport.responseObject.unearnedIncome
        );

        let incomeArrayNew = [];
        allIncome.map((member) => {
          let memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            incomeArrayExisting
          );
          if (memberObj === null) {
            memberObj = this.getMemberDataObjectByNameAndDOB(
              member.firstName,
              this.props.interimReport.householdMembers.membersList
            );
          }
          if (member.incomes !== undefined) {
            member.incomes.map((income, index) => {
              let typeCode = null;
              if (
                income !== undefined &&
                income.Answer !== undefined &&
                income.Answer.length > 0
              ) {
                income.Answer.map((incomeSub) => {
                  let amountArray = [];
                  let amountIterator = 1;
                  if (incomeSub.amount) {
                    if (incomeSub.unit === "Weekly") {
                      amountIterator = 4;
                    } else if (
                      incomeSub.unit === "Biweekly (every two weeks)"
                    ) {
                      amountIterator = 2;
                    }

                    for (let i = 0; i < amountIterator; i++) {
                      amountArray.push({
                        amount: Number(incomeSub.amount[i]),
                      });
                    }
                  }

                  let changeCode = "NOCHNG";
                  
                  if (incomeSub.code === "new" || incomeSub.code === "NEW") {
                    changeCode = "NEW";
                  } else if (incomeSub.code === "changed" || incomeSub.code === "CHANGE") {
                    changeCode = "CHANGE";
                  } else if (incomeSub.code === "removed" || incomeSub.code === "REMOVE") {
                    changeCode = "REMOVE";
                  }

                  if (changeCode !== "NOCHNG")
                    reportedChanges = TextProps.VALUE_TRUE;

                  typeCode = income.typeCode;

                  let incomeObj = {
                    id: this.returnMemberId(member, incomeSub),
                    setID:
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.setID
                        ? incomeSub.fullIncomeObj.setID
                        : 0,
                    agencyID: this.returnAgencyID(memberObj, incomeSub),
                    firstName:
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.firstName
                        ? incomeSub.fullIncomeObj.firstName
                        : memberObj.firstName,
                    lastName:
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.lastName
                        ? incomeSub.fullIncomeObj.lastName
                        : memberObj.lastName,
                    dateOfBirth: this.convertDOBForDataPost(
                      incomeSub.fullIncomeObj !== undefined &&
                        incomeSub.fullIncomeObj.dateOfBirth
                        ? incomeSub.fullIncomeObj.dateOfBirth
                        : memberObj.dateOfBirth
                    ),
                    typeCode: typeCode,
                    frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                    amounts: amountArray,
                    dataChangeCode: changeCode,
                  };

                  incomeArrayNew.push(incomeObj);
                }); // income sub ends
              }
            }); // income ends
          }
        }); // member ends

        incomeArrayExisting.map((existing) => {
          existing.dateOfBirth = this.convertDOBForDataPost(
            existing.dateOfBirth
          );
          let found = TextProps.VALUE_FALSE;
          incomeArrayNew.forEach((newIncome) => {
            if (
              newIncome.id === existing.id &&
              newIncome.agencyID === existing.agencyID &&
              newIncome.setID === existing.setID
            ) {
              found = TextProps.VALUE_TRUE;
            }
          });
          if (found === TextProps.VALUE_FALSE) {
            reportedChanges = TextProps.VALUE_TRUE;
          }
        });
      }
      if (reportedChanges === TextProps.VALUE_FALSE)
        fullContentInPopup = TextProps.VALUE_TRUE;

    } 
    else if( activeId === "childsupport-expenses"){
      if (this.props.interimReport.obligationChildSupportChanged!== undefined && this.props.interimReport.obligationChildSupportChanged===TextProps.VALUE_TRUE ){
        dataChangeSelection = TextProps.VALUE_TRUE;
        let childExpNew = _.cloneDeep(this.incomeSummary.childSupportIncomeMembers);
        let childArrayExisting = _.cloneDeep(
          this.props.interimReport.responseObject.childSupportExpense
        );
        childExpNew.forEach((member) => {
          let changeCode = "NOCHNG";
          member.incomes.forEach((exp)=>{
            if(exp.Answer){
            changeCode = this.returnDataChangeCode(exp.Answer.code)
          }
          })
          if (changeCode !== "NOCHNG")
          reportedChanges = TextProps.VALUE_TRUE;
        });
      }
    } 
    else if (activeId === "shelter-utility-summary"){
      let changeCode = "NOCHNG";
      if(this.props.interimReport.shelterUtilityConfirmationChanged){
        dataChangeSelection = TextProps.VALUE_TRUE;
        //Shelter Changes
        let shelterExpNew = _.cloneDeep(this.shelterSummary.shelterExpense[0].incomes);
        const shelterExpNewCodes = shelterExpNew.map(newExp => {
          return newExp.typeCode;
        });

        let shelterExpExisting = _.cloneDeep(
          this.props.interimReport.responseObject.shelterExpense
        );
        shelterExpExisting = shelterExpExisting || [];

        const removedShelterExp = shelterExpExisting.filter(oldExp => {
          if(!shelterExpNewCodes.includes(oldExp.typeCode)){
            return oldExp;
          }
        })

        const formRemovedShelterObj = removedShelterExp.map(removedExp => {
          const newObj = {};
          newObj["typeCode"] = removedExp.typeCode;
          newObj["Answer"] = [
            {
              amount: removedExp.amount,
              unit: this.getShelterExpenseFrequency(removedExp.frequencyCode),
              code: "REMOVE"
            }
          ]
          return newObj;
        });

       shelterExpNew = [...shelterExpNew, ...formRemovedShelterObj]
       this.shelterSummary.shelterExpense[0].incomes = shelterExpNew;

        if(shelterExpNew && shelterExpNew.length>0){
            shelterExpNew.forEach((newExp)=>{
              if(newExp.Answer)
              newExp.Answer.forEach((oneExp)=>{
                if(oneExp.code){
                  changeCode = this.returnDataChangeCode(oneExp.code)
                }
              })
            })
        }
        // Utility Expense
        let utilityExpNew = _.cloneDeep(this.shelterSummary.utilityExpenses);
        if(utilityExpNew && utilityExpNew.length>0){
          utilityExpNew.forEach((oneExp)=>{
              if(oneExp.code){
                changeCode = this.returnDataChangeCode(oneExp.code)
              }
          })
      }
        if (changeCode !== "NOCHNG")
        reportedChanges = TextProps.VALUE_TRUE;
      }
    }else if(activeId === "shelter-expense-subsection"){
      dataChangeSelection = TextProps.VALUE_TRUE;
      reportedChanges === TextProps.VALUE_TRUE
     return TextProps.VALUE_TRUE;
    }
    // warning popup if data not changed
    if (
      reportedChanges === TextProps.VALUE_FALSE &&
      dataChangeSelection === TextProps.VALUE_TRUE
    ) {
      this.showWarningModal(fullContentInPopup);
      return TextProps.VALUE_FALSE;
    }else if( reportedChanges === TextProps.VALUE_TRUE &&
      dataChangeSelection === TextProps.VALUE_TRUE){
        return TextProps.VALUE_TRUE;
      }
  };
  

  showWarningModal = (fullContentInPopup) => {
    this.setState({
      warningModalOpenStatus: TextProps.VALUE_TRUE,
      fullContentInPopup,
    });
  };

  closeWarningModal = () => {
    this.setState({ warningModalOpenStatus: TextProps.VALUE_FALSE });
  };

  autoSelectNo = () => {
    const activeId = this.state.activeSectionId;
    if (activeId === "contact-info") {
      let contactInfoObj = this.props.interimReport.contactInfoScreen;
      contactInfoObj.contactInfoChanged = TextProps.VALUE_FALSE;
      this.setContactInfoData(contactInfoObj);
    } else if (activeId === "my-household") {
      let stateObj = this.props.interimReport.householdMembers;
      stateObj.membersListUpdated = TextProps.VALUE_FALSE;
      this.props.setHouseholdMembers(stateObj);
    } else if (activeId === "income-summary") {
      this.props.setEarnedIncomeChangeStatus(TextProps.VALUE_FALSE);
      this.props.interimReport.setOldIncomeData = TextProps.VALUE_TRUE;
    } else if (activeId === "unearned-income-summary") {
      this.props.setUnearnedIncomeChangeStatus(TextProps.VALUE_FALSE);
      this.props.interimReport.setOldUIncomeData = TextProps.VALUE_TRUE;
    } else if (activeId === "childsupport-expenses") {
      this.props.setChildSupportOptions({
        courtOrderedChildSupportChanged:false,
        obligationChildSupportChanged:false
      })
      // let stateObj = this.props.interimReport.childExpScreen;
      // stateObj.childExpChanged = TextProps.VALUE_FALSE;
      // this.props.setChildSupportData(stateObj);
    }
    else if (activeId === "shelter-utility-summary") {
     this.props.setShelterOptions(TextProps.VALUE_FALSE)

    }

    this.closeWarningModal();
    return TextProps.VALUE_FALSE;
  };

  renderWarningPopup = () => {
    //Get interim due date
    if (this.props.interimReport.responseObject) {
      var caseManager = this.props.interimReport.responseObject.caseManager;
      var caseManagerNumber = caseManager
        ? caseManager.phoneNumber
        : "8773822363";
    }
    var showBenefitListInfo = [];
    var benefitListResult = JSON.parse(
      sessionStorage.getItem("loadConsumerUser")
    );
    if (
      !_.isEmpty(this.props.consumer.centrifyData) &&
      this.props.consumer.centrifyData.benefitInfo.length > 0
    ) {
      showBenefitListInfo = this.props.consumer.centrifyData.benefitInfo;
    } else if (benefitListResult) {
      showBenefitListInfo = benefitListResult.benefitInfo;
    }
    if (caseManagerNumber) {
      var caseWorkerPhone = formatters.phone(caseManagerNumber);
    }
    var _SNAP_DATE;
    //itrate to get alert messages data , if one avail the flag no need set
    if (showBenefitListInfo.length > 0) {
      for (let benefitList of showBenefitListInfo) {
        if (benefitList.alertMessages.SNAP_FLAG === TextProps.VALUE_YES) {
          _SNAP_DATE = benefitList.alertMessages.NEXT_RECERT_DUEDATE;
        }
      }
    }
    //Get interim due date ends

    let popupTexts = interimWarningPopupMLanguageLabels(
      language,
      helperFunction._convertDateToState(_SNAP_DATE),
      caseWorkerPhone
    ).popupBodyTexts;

    return modalHelperFunction.dtaHelperModal(
      null,
      this.state.warningModalOpenStatus,
      this.closeWarningModal,
      "save-progress-modal",
      "First pop-up",
      null,
      TextProps.VALUE_FALSE,
      TextProps.VALUE_FALSE,
      interimWarningPopupMLanguageLabels(language, null).title,
      <main className="dta-modal__body pad-all">
        <p>
          {popupTexts[0]}
          {/* {this.state.fullContentInPopup ? (
            <p>
              {" "}
              {popupTexts[1]} <br />
              <br /> {popupTexts[2]} <br />
              <br /> {popupTexts[3]}{" "}
            </p>
          ) : null} */}
        </p>
        <p>&nbsp;</p>
        <div className="apply__footer pad-all--double warning-modal-footer">
          <button
            align="center"
            className="dta-button dta-button--outline-primary"
            onClick={() => {
              this.autoSelectNo();
              
            }}
          >
            {
              interimReportPageLabels(language, languageConstants.aboutMe)
                .houseLessOptions[1]
            }
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            align="center"
            className="dta-button dta-button--primary"
            onClick={() => this.closeWarningModal()}
          >
            {
              interimReportPageLabels(language, languageConstants.aboutMe)
                .houseLessOptions[0]
            }
          </button>
        </div>
      </main>
    );
  };

  setDataCodeChangeFlags = async () => {
    if (
      this.props.interimReport.contactInfoScreen &&
      this.props.interimReport.contactInfoScreen.contactInfoChanged
    ) {
      const responseBody = _.cloneDeep(this.props.interimReport.responseObject);
      const {additionalProperties} = responseBody;

      let emailCode = null;
      let phoneCode = null;
      let mailingAddressCode = null;
      let resAddressCode = null;
      let homelssFlagCode = null;
      //address change
      let respAddress = responseBody.clientAddress;
      let respMailAddress = null;
      let respResAddress = null;

      for (let address of respAddress) {
        if (address.typeCode === "RES") respResAddress = address;
        else if (address.typeCode === "MAIL") respMailAddress = address;
      }

      let updatedAddress =
        this.props.interimReport.contactInfoScreen.addressList.address;
      let updatedMailAddress = null;
      let updatedResAddress = null;

      for (let newAddress of updatedAddress) {
        if (
          newAddress.typeCode === "RES" &&
          newAddress.city &&
          newAddress.line1 &&
          newAddress.zip
        )
          updatedResAddress = newAddress;
        else if (newAddress.typeCode === "MAIL")
          updatedMailAddress = newAddress;
      }

      if (respResAddress !== null && updatedResAddress !== null) {
        if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "changed"
        ) {
          resAddressCode = "changedLabel";
        } else if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "removed"
        ) {
          resAddressCode = "removedLabel";
        }
        //Homeless flag check
        if (
          this.props.interimReport.contactInfoScreen.addressList.homeless !==
          respResAddress.homelessFlag
        ) {
          homelssFlagCode = "changedLabel";
        }
      } else if (respResAddress === null && updatedResAddress !== null) {
        resAddressCode = "newLabel";
      } else if (respResAddress !== null && updatedResAddress === null) {
        resAddressCode = "removedLabel";
      }

      //Mail Addre check
      if (respMailAddress !== null && updatedMailAddress !== null) {
        if (
          this.checkForAddressChange(respMailAddress, updatedMailAddress) ===
          "changed"
        ) {
          mailingAddressCode = "changedLabel";
        }
        //Homeless flag check
        if (
          this.props.interimReport.contactInfoScreen.addressList.homeless !==
          respMailAddress.homelessFlag
        ) {
          homelssFlagCode = "changedLabel";
        }
      } else if (respMailAddress === null && updatedMailAddress !== null) {
        mailingAddressCode = "newLabel";
      } else if (respMailAddress !== null && updatedMailAddress === null) {
        mailingAddressCode = "changedLabel";
      }

      let respEmail = responseBody.clientEmail;
      let updatedEmail = this.props.interimReport.contactInfoScreen;
      if (!respEmail.emailAddress && updatedEmail.email) {
        emailCode = "newLabel";
      } else if (respEmail.emailAddress && !updatedEmail.email) {
        emailCode = "removedLabel";
      } else if (
        respEmail.emailAddress &&
        (respEmail.emailAddress !== updatedEmail.email ||
          respEmail.optInFlag !== updatedEmail.emailOptIn)
      ) {
        emailCode = "changedLabel";
        if (respEmail.dataChangeCode === "NEW") {
          emailCode = "newLabel";
        }
      }

      //Phone change
      let respPhone = responseBody.clientPhone;
      let updatedPhone = this.props.interimReport.contactInfoScreen;
      let phoneNumber = updatedPhone.phoneNumber
        ? updatedPhone.phoneNumber.replace(/\D/g, "")
        : "";
      if (!respPhone.phoneNumber && phoneNumber) {
        phoneCode = "newLabel";
      } else if (respPhone.phoneNumber && !phoneNumber) {
        phoneCode = "removedLabel";
      } else if (
        respPhone.phoneNumber &&
        (respPhone.phoneNumber !== phoneNumber ||
          respPhone.textOptInFlag !== updatedPhone.textOptIn)
      ) {
        phoneCode = "changedLabel";
        if (respPhone.dataChangeCode === "NEW") {
          phoneCode = "newLabel";
        }
      }

      //Set the codes to the state
      let contactInfoObj = this.props.interimReport.contactInfoScreen;
      contactInfoObj.changeFlags = {
        emailCode: emailCode,
        phoneCode: phoneCode,
        mailingAddressCode: mailingAddressCode,
        resAddressCode: resAddressCode,
        homelssFlagCode: homelssFlagCode,
      };
      this.props.setContactInfo(contactInfoObj);
    }
  };



  //prepare add contact info
  prepareAddContactInfoSection = (sections,activeId,answerDataSections) => {
    let datasection = answerDataSections;
    let subsection = sections[1].subsections[0];
        if (
          datasection.questions[0].Answer ===
          interimReportPageLabels(language, languageConstants.aboutMe)
            .optionYesNo[0] &&
          datasection.questions[2].Answer === TextProps.VALUE_STR_TRUE &&
          datasection.questions[1].Answer.street === "" &&
          datasection.questions[1].Answer.zipCode === "" &&
          datasection.questions[1].Answer.city === ""
        ) {
          if (subsection !== undefined) {
            subsection.questions[2].conditionals[0].conditionalQuestions[0].error =
              TextProps.VALUE_TRUE;
            subsection.questions[2].conditionals[0].conditionalQuestions[0].errorMessage =
              interimReportPageLabels(
                language,
                languageConstants.aboutMe
              ).addressErrmsg;
            subsection.questions[2].value = TextProps.VALUE_STR_FALSE;
            subsection.questions[2].Answer = TextProps.VALUE_STR_FALSE;
            subsection.questions[2].targetValue = TextProps.VALUE_STR_FALSE;

            let answer = this.state.AnswerObject;
            answer["add-contact-info"]["is-also-mailing"] =
              TextProps.VALUE_STR_FALSE;

            let mailingAddressSame =
              document.getElementsByName("is-also-mailing");
            mailingAddressSame[1].click();
            mailingAddressSame[0].checked = TextProps.VALUE_FALSE;
            mailingAddressSame[1].checked = TextProps.VALUE_TRUE;
            this.setState({
              activeSectionId: activeId,
              renderedSections: [datasection],
              AnswerObject: answer,
            });
            this.props.addContactInfoSubSection([subsection]);
            return;
          }
        }
        this.props.addContactInfoSubSection([subsection]);
  }


  _saveHandler = (activeId, actionButtonType) => {
    //Final save logic
    if (this.state.sectionDataUpdated) {
      this.setState({ sectionDataUpdated: TextProps.VALUE_FALSE });
      // this.props.onPrimaryActionSaveClickedAction(TextProps.VALUE_TRUE);
      // this.props.updateCurrentSectionData(this.props.apply);
    }
    if (
      actionButtonType === TextProps.SAVECONTINE ||
      actionButtonType === undefined || actionButtonType !== TextProps.SAVEEXIT
    ) {
      this._renderNextSection(activeId);
    }
    else if (actionButtonType === TextProps.BACK) {
      this.onSecondaryActionClick();
    }
  };

  setContactInfoData = (data) => {
    if (data.contactInfoChanged !== null) {
      this.setState({
        sectionDataUpdated: TextProps.VALUE_TRUE,
      });
    }
    if (
      data &&
      (data.emailTemp && (data.emailTemp !== "" && data.emailTemp !== data.email) ||
        data.phoneNumberTemp && (data.phoneNumberTemp !== "" && data.phoneNumber !== data.phoneNumberTemp) || 
        data.hasOwnProperty('textOptInTemp') && (data.textOptIn !== data.textOptInTemp) || 
        data.hasOwnProperty('emailOptInTemp') && (data.emailOptIn !== data.emailOptInTemp)
      )
    ) {
      this.isShowWarningModalFlag = true;
    } else {
      this.isShowWarningModalFlag = false;
    }
    if(!data.contactInfoChanged){
      data.editEmail = false;
      data.editPhone = false;
    }
    this.props.setContactInfo(data);
  };

  //Primary action for save and continue button click
  // onPrimaryActionClickSaveContinue = (event) => {
  //   event.preventDefault();
  //   console.log("++save and cont")
  //   // sessionStorage.setItem("saveExitShowModalEnabled", false);
  //   this.onPrimaryActionClick(event, TextProps.SAVECONTINE);
  // };

  handleRemove = (event) => {
    event.preventDefault();
    let childSupportArray = _.cloneDeep(this.props.interimReport.childExpScreen.tempChildExpArray);
    let isNewEntry = null
    const updatedChildSupportArray = childSupportArray.map(childSection => {
      if(childSection.clientId === event.target.id) {
        if(childSection.id === 0){
          childSection.dataChangeCode = null;
          childSection.isRemoved=false;
          delete childSection['originalAmount']
          delete childSection['originalFrequency']
          childSection.frequency = "";
          childSection.amount = "";
          isNewEntry = childSection;
        }else{
        childSection.dataChangeCode = "REMOVE";
        childSection.isRemoved=true;
      }
      }
      return childSection;
    });
    let childExpenseSection = this.incomeSummary.childSupportIncomeMembers;
    childExpenseSection.forEach((expSec,index,object)=>{ 
      if(expSec.clientId === event.target.id){
        if(expSec.incomes[0].Answer){
        expSec.incomes[0].Answer.isRemoved = true;
          expSec.incomes[0].Answer.code = 'removed';
        }
      }
      if(isNewEntry && isNewEntry.clientId === expSec.clientId){
        object.splice(index, 1);
        this.props.interimReport.sections[5].subsections.splice(index, 1);
      }
    });
    this.props.appendChildSupportAnswer(updatedChildSupportArray)
    this.processChildSupportSectionData();
    this.gotoChildSupportSummary();
  }

  handleDone = (event) => {
    event.preventDefault();
    this.onPrimaryActionClick(event);
  }
  getSectionDataForSummary = (sectionId) => {
    let currentSectionData = {};
    switch(sectionId){
      case "contact-info": 
        currentSectionData = this.props.interimReport.contactInfoScreen;
        break;
      case "my-household":
        currentSectionData = this.props.interimReport.householdMembers;
        break;  
      case "unearned-income-summary": 
        currentSectionData = this.props.interimReport.responseObject.unearnedIncome;
        break;
      case "shelter-utility-summary": 
        currentSectionData = this.props.interimReport.shelterUtilityData && this.props.interimReport.shelterUtilityData.shelterData;
        break;  
      default :   
          currentSectionData = {} ;
        break; 
    }
    return currentSectionData;
  }

  getSectionWithBindAnswers = (sectionIdentifier, sectionData) => {
    let currentSectionTemplate;
  
    if(sectionIdentifier === "contact-info") {
      let contactInfoTemplate = _.cloneDeep(contactInfoSummaryTemplate);
      const headOfHousehold = this.props.interimReport.responseObject.householdMembers.filter(member => member.granteeFlag === true);
      const headOfHouseholdName = headOfHousehold && headOfHousehold.map(headMember => headMember.firstName + " " + headMember.lastName);
      const homeAddress = sectionData.addressList && sectionData.addressList.address && sectionData.addressList.address.filter(homeAddress => homeAddress.typeCode === "RES");
      let homeAddressObj = {};
      if(homeAddress && homeAddress.length){
         homeAddressObj = {
          addressLine1: homeAddress[0].line1,
          addressLine2: homeAddress[0].line2,
          city: homeAddress[0].city,
          county: homeAddress[0],
          state: homeAddress[0].state,
          zipCode: homeAddress[0].zip,
        }
      }
     
      const mailingAddress = sectionData.addressList && sectionData.addressList.address && sectionData.addressList.address.filter(mailAddress => mailAddress.typeCode === "MAIL" && mailAddress.resAddrMatchFlag === false);
      
      contactInfoTemplate.subsections[0].questions[0].Answer = headOfHouseholdName[0];
      contactInfoTemplate.subsections[0].questions[0].targetValue = headOfHouseholdName[0];
      contactInfoTemplate.subsections[0].questions[1].Answer = sectionData.addressList.homeless ? "Yes" : "No";
      contactInfoTemplate.subsections[0].questions[1].targetValue = sectionData.addressList.homeless ? "Yes" : "No";
      contactInfoTemplate.subsections[0].questions[2].Answer = homeAddressObj;
      contactInfoTemplate.subsections[0].questions[2].targetValue = homeAddressObj;
      contactInfoTemplate.subsections[0].questions[3].Answer = sectionData.sameMailingAddress ? "Yes" : "No";
      contactInfoTemplate.subsections[0].questions[3].targetValue = sectionData.sameMailingAddress ? "Yes" : "No";
      contactInfoTemplate.subsections[0].questions[3].conditionals[0].conditionalQuestions[0].Answer = mailingAddress ? mailingAddress[0] : "Same as resedential address";
      contactInfoTemplate.subsections[0].questions[3].conditionals[0].conditionalQuestions[0].targetValue =  mailingAddress ? mailingAddress[0] : "Same as resedential address";
      contactInfoTemplate.subsections[0].questions[4].Answer = sectionData.phoneNumber;
      contactInfoTemplate.subsections[0].questions[4].targetValue = sectionData.phoneNumber;
      contactInfoTemplate.subsections[0].questions[5].Answer = sectionData.textOptIn ? "Yes" : "No";
      contactInfoTemplate.subsections[0].questions[5].targetValue = sectionData.textOptIn ? "Yes" : "No";
      contactInfoTemplate.subsections[0].questions[6].Answer =  sectionData.email; 
      contactInfoTemplate.subsections[0].questions[6].targetValue =  sectionData.email;
      contactInfoTemplate.subsections[0].questions[7].Answer =  sectionData.emailOptIn ? "Yes" : "No"; 
      contactInfoTemplate.subsections[0].questions[7].targetValue =  sectionData.emailOptIn ? "Yes" : "No";

      currentSectionTemplate = contactInfoTemplate;
    } else if(sectionIdentifier === "my-household") {
      let householdDetailsTemplate = _.cloneDeep(householdSummaryTemplate);
   
      let memberDetails = sectionData.membersList && sectionData.membersList.map(household => {
        let householdDetailsSubsections = _.cloneDeep(householdDetailsTemplate.subsections[0]);
        console.log("Hosuehold",householdDetailsSubsections)
          householdDetailsSubsections.questions[0].Answer = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[0].Answer : household.firstName;
          householdDetailsSubsections.questions[0].targetValue = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[0].Answer : household.firstName;
          householdDetailsSubsections.questions[1].Answer = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[1].Answer : household.middleName;
          householdDetailsSubsections.questions[1].targetValue = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[1].Answer : household.middleName;
          householdDetailsSubsections.questions[2].Answer = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[2].Answer : household.lastName;
          householdDetailsSubsections.questions[2].targetValue = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[2].Answer : household.lastName;      
          householdDetailsSubsections.questions[3].Answer = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[3].Answer : household.suffix;
          householdDetailsSubsections.questions[3].targetValue = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[3].Answer : household.suffix;
          householdDetailsSubsections.questions[4].Answer = (household.allAnswers && household.allAnswers.length) ? relationshipCodeMapper[household.allAnswers[4].Answer] : household.relationshipCode;
          householdDetailsSubsections.questions[4].targetValue = (household.allAnswers && household.allAnswers.length) ? relationshipCodeMapper[household.allAnswers[4].Answer] : household.relationshipCode;
          householdDetailsSubsections.questions[5].Answer = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[5].Answer : household.dateOfBirth.slice(2, -1);
          householdDetailsSubsections.questions[5].targetValue = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[5].Answer : household.dateOfBirth.slice(2, -1);
          householdDetailsSubsections.questions[6].Answer = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[6].Answer :   genderCodeMapper[household.gender];
          householdDetailsSubsections.questions[6].targetValue = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[6].Answer :   genderCodeMapper[household.gender];
          householdDetailsSubsections.questions[7].Answer =   household.allAnswers && household.allAnswers.length
          ? household.allAnswers[7].Answer === interimReportPageLabels(language, languageConstants.householdStatic).declineToAnswerLabel ? household.allAnswers[7].Answer : ethinicityCodeMapper[household.allAnswers[7].Answer]
          : ethinicityCodeMapper[household.ethnicity];
          householdDetailsSubsections.questions[7].targetValue = (household.allAnswers && household.allAnswers.length) ? ethinicityCodeMapper[household.allAnswers[7].Answer] : ethinicityCodeMapper[household.ethnicity];
          householdDetailsSubsections.questions[8].Answer = (household.allAnswers && household.allAnswers.length) ? raceCodeMapper[household.allAnswers[8].Answer] : household.race && household.race[0] && household.race[0].raceCode;
          householdDetailsSubsections.questions[8].targetValue = (household.allAnswers && household.allAnswers.length) ? raceCodeMapper[household.allAnswers[8].Answer] : household.race && household.race[0] && household.race[0].raceCode;
          householdDetailsSubsections.questions[9].Answer = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[9].Answer : household.citizenFlag ? 'Yes' :"No";
          householdDetailsSubsections.questions[9].targetValue = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[9].Answer : household.citizenFlag ? 'Yes' :"No";
          householdDetailsSubsections.questions[10].Answer = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[10].Answer : household.SSN;
          householdDetailsSubsections.questions[10].targetValue = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[10].Answer : household.SSN;
          householdDetailsSubsections.questions[11].Answer = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[11].Answer : household['buy-food'];
          householdDetailsSubsections.questions[11].targetValue = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[11].Answer : household['buy-food'];
          householdDetailsSubsections.questions[12].Answer = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[12].Answer : household.incomeFlag  ? 'Yes' :"No";
          householdDetailsSubsections.questions[12].targetValue = (household.allAnswers && household.allAnswers.length) ? household.allAnswers[12].Answer : household.incomeFlag  ? 'Yes' :"No";
          return householdDetailsSubsections;
      });
      householdDetailsTemplate.subsections = memberDetails;
      currentSectionTemplate = householdDetailsTemplate;
      }
    // else if (sectionIdentifier === "unearned-income-summary") {
    //   let unearnedIncomeTemplate = _.cloneDeep(unearnedIncomeSummaryTemplate);
    //   let unearnedIncomeTemplateSubsections = _.cloneDeep(unearnedIncomeSummaryTemplate.subsections[0]);
    //   let unearnedIncomeAmountSection = _.cloneDeep(unearnedIncomeTemplateSubsections.subsections[0]);

    //   let answer = [];
    //   let unearnedIncomeFilteredQuestions = [];
    //   if (sectionData.length) {
    //     sectionData.map((income) => {
    //       answer.push(
    //         income.typeCode
    //       );
    //     });
    //   }
      
    //   if (answer.length > 0) {
    //     unearnedIncomeTemplateSubsections.questions[0].Answer = answer;
    //     unearnedIncomeTemplateSubsections.questions[0].targetValue = answer;
    //     let oldQuestions = unearnedIncomeAmountSection.questions;

    //     unearnedIncomeFilteredQuestions = filterQuestionsBySelection(
    //       answer,
    //       oldQuestions
    //     );
      
    //     for (let i = 0; i < unearnedIncomeFilteredQuestions.length; i++) {
    //       for (let j = 0; j < sectionData.length; j++) {
    //         if (unearnedIncomeFilteredQuestions[i].valueToMatch === sectionData[j].typeCode) {
    //           let ans = {
    //             "amount": sectionData[j].amounts[0].amount,
    //             "unit": sectionData[j].frequencyCode,
    //           }
    //           unearnedIncomeFilteredQuestions[i].Answer = [ans];
    //           unearnedIncomeFilteredQuestions[i].targetValue = [ans];
    //         }
    //       }
    //     }
    //   }
    //   unearnedIncomeTemplateSubsections.questions[0].Answer = answer;
    //   unearnedIncomeAmountSection.questions = unearnedIncomeFilteredQuestions;
    //   unearnedIncomeTemplate.subsections[0] = unearnedIncomeTemplateSubsections;
    //   unearnedIncomeTemplate.subsections[0].subsections[0] = unearnedIncomeAmountSection;
    //   currentSectionTemplate = unearnedIncomeTemplate;
    // } else if (sectionIdentifier === "shelter-utility-summary") {
    //   let shelterUtiltyTemplate = _.cloneDeep(shelterUtilitySectionTemplate);
    //   let shelterUtilitySubsections = _.cloneDeep(shelterUtiltyTemplate.subsections[0]);
    //   let shelterUtilityAmountSection = _.cloneDeep(shelterUtilitySubsections.subsections[0]);
    //   let shelterUtilityAnswer = [];
    //   let shelterUtilityFilteredQuestions = [];
    //   let shelterUtilityFiltered = [];

    //   if (sectionData && sectionData.length) {
    //     sectionData.map((income) => {
    //       shelterUtilityAnswer.push(
    //         income.typeCode
    //       );
    //     });
    //   }

    //   if (shelterUtilityAnswer.length > 0) {
    //     shelterUtilitySubsections.questions[0].Answer = shelterUtilityAnswer;
    //     shelterUtilitySubsections.questions[0].targetValue = shelterUtilityAnswer;
    //     let oldQuestions = shelterUtilityAmountSection.questions;

    //     shelterUtilityFiltered = filterQuestionsBySelection(
    //       shelterUtilityAnswer,
    //       oldQuestions
    //     )
    //     shelterUtilityFilteredQuestions = _.uniqBy(shelterUtilityFiltered, (arrVal) => arrVal.valueToMatch)
    //     for (let i = 0; i < shelterUtilityFilteredQuestions.length; i++) {
    //       let ansArr = [];
    //       if (this.shelterSummary.shelterExpense[0].incomes[i].Answer) {
    //         for (let j = 0; j < this.shelterSummary.shelterExpense[0].incomes[i].Answer.length; j++) {
    //           let ans = {
    //             "amount": this.shelterSummary.shelterExpense[0].incomes[i].Answer[j].amount,
    //             "unit": getShelterExpenseFrequency(this.shelterSummary.shelterExpense[0].incomes[i].Answer[j].unit),
    //             "id": "repeatable_q_" + i + "_" + j
    //           }
    //           ansArr.push(ans)
    //           if (this.shelterSummary.shelterExpense[0].incomes[i].valueToMatch === shelterUtilityFilteredQuestions[i].valueToMatch) {
    //             shelterUtilityFilteredQuestions[i].Answer = [...ansArr];
    //             shelterUtilityFilteredQuestions[i].targetValue = [...ansArr];
    //           }

    //         }
    //       }
    //     }
    //   }
    //   shelterUtilitySubsections.questions[0].Answer = shelterUtilityAnswer;
    //   shelterUtilityAmountSection.questions = shelterUtilityFilteredQuestions;
    //   shelterUtiltyTemplate.subsections[0] = shelterUtilitySubsections;
    //   shelterUtiltyTemplate.subsections[0].subsections[0] = shelterUtilityAmountSection;
    //   currentSectionTemplate = shelterUtiltyTemplate;
  
    // }

        
    return currentSectionTemplate;
  }
  formObjToValidate = (address) => {
    return {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      county: address.county,
      countyCode: address.countyCode,
      state: address.state,
      verified: address.verified,
      zipCode: address.zipCode,
    };
  };
  //Primary Continue Click
onPrimaryActionClick = async (event,actionButtonType,isFromModal) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const { sections } = this.props.interimReport;
    const activeId = this.state.activeSectionId;
    let sectionId = '';

    const incomingQuestions = this._getSection(activeId);
    let datasection = null;
    //Dal Reference
    let dalReference = this.props.interimReport.dalReference;

    if (this.state.AnswerObject[activeId] === undefined) {
      dalReference.status = "INSERT";
      dalReference.sectionId = activeId;

      this.state.AnswerObject[activeId] = {};
    } else if (this.state.AnswerObject[activeId].completed === TextProps.VALUE_TRUE) {
      dalReference.sectionId = activeId;
    }

    if (incomingQuestions !== undefined && incomingQuestions.questions !== undefined) {
      //Bind answer into the section object
      datasection = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );
      //Current section form validation
      let isValid = toValidateInputErros(datasection, activeId);

      if (isValid === TextProps.VALUE_FALSE || incomingQuestions.selectionMade === null) {
        if (activeId) {
          this.setState({
            renderedSections: [datasection],
          });
        }
        return;
      } else {
        //Dynamic Template Rendering Functions using current page
        switch (activeId) {
          //Clearing HouseHold Cache Template
  
          case "optional":
            // this.onHouseHoldSubSectionClean();
            break;
          //clearning Income Template
          case "household-details":
            this.onIncomeSubSectionClean();
            this.onUnearnedIncomeSubSectionClean();
            break;
          //clearning Medical Expense Tempalte
          case "child-support":
            this.onMedicalExpenseSectionClean();
            break;
          //Rendering Dynamic Household Members
          case "my-household":
            if (this.props.interimReport.sections.length > 4) {
              // this.onHouseHoldSubsectionRender(
              //   datasection.questions[0].Answer
              // );
              this.onIncomeSubSectionClean();
              this.onUnearnedIncomeSubSectionClean();
              this.onIncomeSubsectionRender();

            }
            break;
          case "income-summary":
            this.onIncomeSubSectionClean();
            this.onUnearnedIncomeSubSectionClean();
            this.onUnearnedIncomeSubsectionRender();
            break;
          case "unearned-income":
            this.onUnearnedIncomeSubsectionRender(
              datasection.questions[0].Answer
            );
            break;

          case "household-member":
            this.updateHouseHoldMemberSection(activeId, datasection);
            break;
          case "add-contact-info":
            if (config.doAddressValidate) {
              const address = datasection;
              let resAddrUpdated = TextProps.VALUE_FALSE;
              let isHomeless = address.questions.filter(
                (addr) => addr.name === "is-homeless"
              )[0];
              if (isHomeless && isHomeless.Answer === TextProps.VALUE_YES) {
                resAddrUpdated = TextProps.VALUE_FALSE;
              } else if (this.props.interimReport.residentailAddressStored) {
                resAddrUpdated = checkIfAddressIsUpdated(
                  this.formObjToValidate(
                    address.questions.filter(
                      (addr) => addr.name === "address"
                    )[0].Answer
                  ),
                  this.props.interimReport.residentailAddressStored
                );
              }
              let mailingAddrUpdated = TextProps.VALUE_FALSE;
              if (this.props.interimReport.mailingAddressStored) {
                let mailingaddr = address.questions.filter(
                  (addr) => addr.name === "is-also-mailing"
                )[0].conditionals[0].conditionalQuestions[0];
                if (mailingaddr.Answer && mailingaddr.Answer.addressLine1) {
                  mailingAddrUpdated = checkIfAddressIsUpdated(
                    this.formObjToValidate(mailingaddr.Answer),
                    this.props.interimReport.mailingAddressStored
                  );
                }
              }
              if (
                !this.props.interimReport.contactInfoScreen.melisaAddressValidated ||
                resAddrUpdated ||
                mailingAddrUpdated
              ) {
                let melissaResponse = this.validateAddressesCallingMellisa(
                  addressValidationObj(address.questions),
                  address
                );
                this.setState({
                  addressDatasections: address,
                });
                if (melissaResponse) {
                  return;
                }
              }
            } else {
            this.setAddressValidatedToContactInfo(datasection);
            }
            break;
          case "shelter-expense-subsection":
          case "utility-expenses-section":
            this.prepareShelterSummary(activeId);
            if(!isFromModal){
            this.updateAndSaveSection(activeId, actionButtonType,datasection);
            }
            break;

          default:
          break;

        }
        // rendering Dynamic Questions in subsections of dynamic sections
        if (activeId !== "" && activeId !== undefined && activeId !== null) {
          var activeIdSplit = activeId.split("-");
          var activeSplitMemberIndex = activeIdSplit.splice(-1, 1).join();
          var activeIdSubString = activeIdSplit.join("-");
        }
        switch (activeIdSubString) {
          // rendering Income Dynamic Questions.
          case "unearned-combined-income":
          if(actionButtonType === TextProps.SAVEEXIT){
            this.updateAndSaveSection(activeId,actionButtonType,datasection);
            return;
          }else{
            this.updateAndSaveSection(activeId,actionButtonType,datasection);
            this.incomeUnearnedfilterQuestionsBySelection(
              activeSplitMemberIndex
            );
          }
            break;
          case "shelter-expenses":
            if(actionButtonType === TextProps.SAVEEXIT){
              this.updateAndSaveSection(activeId,actionButtonType,datasection);
              return;
            }else{
            this.shelterQuestionsBySelection();
            }
            break;
            case "shelter-expense":
              if(actionButtonType === TextProps.SAVEEXIT && !isFromModal){
                this.updateAndSaveSection(activeId,actionButtonType);
                }
              break;
            case "utility-expenses":
              if(actionButtonType === TextProps.SAVEEXIT){
                this.updateAndSaveSection(activeId,actionButtonType,datasection);
                return;
              }
            break;
          case "unearned-combined-income-subsection":
            if(datasection.currentMember){
            datasection.currentMember =
              this.props.interimReport.sections[4].currentMember;
            }
            this.prepareUnearnedIncomeSummary();
            if(actionButtonType === TextProps.SAVEEXIT){
              this.updateAndSaveSection(activeId,actionButtonType);
              }else{
                this.gotoUnearnedIncomeSummary(activeSplitMemberIndex);
                this.updateAndSaveSection(activeId,actionButtonType);
              }
            return;
          case "combined-income":
            if(actionButtonType === TextProps.SAVEEXIT){
              this.updateAndSaveSection(activeId,actionButtonType,datasection);
              return;
            }else{
            this.incomefilterQuestionsBySelection(activeSplitMemberIndex);
              this.updateAndSaveSection(activeId,actionButtonType,datasection);
            }
            break;
          case "combined-income-subsection":
            datasection.currentMember =
              this.props.interimReport.sections[3].currentMember;
              if(actionButtonType === TextProps.SAVEEXIT){
                this.prepareIncomeSummary();
                this.updateAndSaveSection(activeId,actionButtonType);
                return;
                }else{
                  this.gotoIncomeSummary();
                  this.updateAndSaveSection(activeId,actionButtonType);
              return;
            }
          default:
            break;
        }
        if (
          activeId !== "income" &&
          activeIdSubString !== "combined-income" &&
          activeId !== "kids-and-adults" &&
          activeId !== "medical-expenses" &&
          activeId.indexOf("medical-expenses-") < 0
        ) {
          if (activeId.indexOf("medical-address-") >= 0) {
            if (
              this.props.interimReport.sections[4].currentExpSeq !==
              undefined &&
              this.props.interimReport.sections[4].currentExpSeq !== null
            ) {
              datasection.currentExpSeq =
                this.props.interimReport.sections[4].currentExpSeq;
            }
          }
        } else {
          if(actionButtonType !== TextProps.SAVEEXIT){
          this._renderNextSection(activeId);
          }
          let AnswerObjectCopy = this.state.AnswerObject;
          AnswerObjectCopy[activeId].completed = TextProps.VALUE_TRUE;
          this.setState({ AnswerObject: AnswerObjectCopy });
        }
        if(activeId.includes("child-support-amount-")){
          this.prepareChildSupportSummary();
          this.processChildSupportSectionData();
            this.updateAndSaveSection(activeId,actionButtonType);
          if (actionButtonType !== TextProps.SAVEEXIT) {
              this.gotoChildSupportSummary(activeId);
            }
          return;
        }
      }
    } else if (incomingQuestions.selectionMade === TextProps.VALUE_FALSE && !incomingQuestions.completed) {
      return;
    } else if (incomingQuestions.selectionMade === null) {
      // if(actionButtonType !== TextProps.SAVEEXIT){
        let reportChange = this.applySectionDataChangeStatus(activeId);
        // let reportChange = this.checkSectionChangeStatus(activeId);
        if(!reportChange){
          return
        }else{
          if(actionButtonType !== TextProps.SAVEEXIT){
          this._renderNextSection(activeId);
        }
        }
      // }
    } else if (activeId === "sign-and-submit") {

      this.sendApplicationSubmitAnalyticsEvent();

      //Loading Dynamic SubSections , HouseHold Details
      if (this.state.rights < 1 || this.state.signature.length < 1) {
        this.applySignAndSubmitSectionValidation();
      } else if(actionButtonType === TextProps.SAVEEXIT){
        this.saveAndContinue(activeId,actionButtonType,datasection);
      } else {
        this.prepareDataForSubmission(activeId);
      }
    }

    // Pre process logic for next section
    if (activeId === "household-member") {
      this.props.updateHouseholdTextChanges(
        this.props.interimReport.sections[2].subsections
      );
    } else if (activeId === "contact-info") {
      if(this.isShowWarningModalFlag){
        this.showWarningModal(true);
        return
      }
      let contactInfoObjTemp = _.cloneDeep(
        this.props.interimReport.contactInfoScreen
      );
      if (
        contactInfoObjTemp.emailErrorExists === TextProps.VALUE_TRUE ||
        contactInfoObjTemp.phoneErrorExists === TextProps.VALUE_TRUE
      ) {
        contactInfoObjTemp.continueClicked = TextProps.VALUE_TRUE;
        this.props.setContactInfo(contactInfoObjTemp, () => {
          this.setState({
            activeSectionId: activeId,
          });
          this.forceUpdate();
        });
        return;
      }
    } else if (activeId === "add-contact-info") {
      this.prepareAddContactInfoSection(sections, activeId, datasection);
    }

    // Dispatch the current section
    this.props.updateCurrentSectionData(this.props.interimReport);

  if (activeId !== "sign-and-submit") {
    // Answer based routing 
    if (activeId === "shelter-expenses-section") {
      if (!Array.isArray(datasection.questions[0].Answer) || datasection.questions[0].Answer.length === 0 || this.shelterDoNotPayOptions.includes(datasection.questions[0].Answer[0])) {
        this._renderNextSection('shelter-expense-subsection');
      } else {
        this._renderNextSection(activeId);
      }
      this.updateAndSaveSection(activeId, actionButtonType,datasection);
    } else {
      if (actionButtonType === TextProps.SAVEEXIT || actionButtonType === TextProps.ACES) {
        this.updateAndSaveSection(activeId, actionButtonType);
        return;
      }
      else {
        this._renderNextSection(activeId);
      }
    }
  }

    if (
      incomingQuestions.navigateToPrimaryScreen !== undefined &&
      incomingQuestions.navigateToPrimaryScreen === TextProps.VALUE_TRUE
    ) {
      this._goToPreviousSection(activeId, sections,'forward')
    } 
    

    if (
      incomingQuestions.skipsubSectionNavigation !== undefined &&
      incomingQuestions.skipsubSectionNavigation === TextProps.VALUE_TRUE
    ) {
      const activeQuestionId = incomingQuestions.id;

      switch (activeQuestionId) {
        case "contact-info":
          this.applyContactInfoSectionTempData();
          sectionId = "my-household";
          break;

        case "my-household":
          if (this.props.interimReport.sections.length > 4) {
            this.onIncomeSubSectionClean();
            sectionId = "income-summary";
          } else {
            sectionId = "sign-and-submit";
          }
          break;
        case "income-summary":
          this.onUnearnedIncomeSubSectionClean();
          sectionId = "unearned-income-summary";
          break;
        case "unearned-income-summary":
          this.onUnearnedIncomeSubSectionClean();
          this.prepareChildSupportSummary();
          this.onChildSupportSubsectionRender();
          sectionId = "childsupport-expenses";
          break;
        case "childsupport-expenses":
          this.prepareShelterSummary();
          this.shelterSubsectionRender();
          sectionId = "shelter-utility-summary";
          break;
        case "shelter-utility-summary":
          this.prepareShelterSummary();
          // sectionId = "sign-and-submit";
          sectionId = "summary"
          break;
        default:
          sectionId = getNextSection(activeQuestionId, sections)
          break;
      }

      if(activeId.includes("unearned-income-summary")){
        let childSupportSubsections = sections[5].subsections;
        childSupportSubsections.forEach(childSection => {
          if(childSection.id.includes("child-support-amount")){
            childSection.questions[0].enabledClickAction =  (event) => this.handleRemove(event);
            childSection.questions[0].enabledDoneAction =  (event) => this.handleDone(event);
          }
        });
      }
      const currentSectionData = this.getSectionDataForSummary(this.state.activeSectionId);
      const summaryData = this.getSectionWithBindAnswers(this.state.activeSectionId, currentSectionData);
      

      let finalSummary = this.props.interimReport.summaryData ? this.props.interimReport.summaryData : [];
      if(summaryData !== undefined) {
        finalSummary.push(summaryData)
      }
      
      this.props.appendInterimDataForSummary(finalSummary);

        if (sectionId && actionButtonType !== TextProps.SAVEEXIT) {
        const nextSection = this._getSection(sectionId);
        this.setState(
          {
            directionClass: "forward",
          },
          () => {
            // setTimeout(()=>{
              this.onSectionChange(nextSection, TextProps.VALUE_TRUE,this.updateAndSaveSection(this.state.activeSectionId,actionButtonType));
            // },1000)
          }
        );
      }
    }
    if(actionButtonType === TextProps.BACK) {
      this._goToPreviousSection(activeId, sections,'backward');
      this.setState({
        sectionDataUpdated: TextProps.VALUE_FALSE,
      });
    }
  
  };

  updateAndSaveSection = (activeId,actionButtonType,datasection) =>{
    this._updateCurrentSection(activeId);
    this.saveAndContinue(activeId,actionButtonType,datasection);
  }


  saveFinalSignAndSubmit = (activeId) => {
    sessionStorage.setItem(
      "householdMembers",
      JSON.stringify(this.state.requestBody.householdMembers)
    );
    sessionStorage.setItem(
      "programID",
      JSON.stringify(this.state.requestBody.programID)
    );
    var auditData = getAudit();
    let request = this.state.requestBody;
    request.status = "RECVED";
    delete request['additionalProperties'];
    this.props
      .submitInterimReportData(request, auditData)
      .then((data) => {
        if (data && data.referenceNumber) {
          this.setState(
            {
              isLoading: TextProps.VALUE_FALSE,
              referenceNumber: data.referenceNumber,
              docReferenceNumber: data.docReferenceNumber,
              agencyID: this.props.interimReport.responseObject.agencyID,
            },
            () => {
              this.disableInterimReportSections();
              this._renderNextSection(activeId);
            }
          );
        } else {
          this.setState({ isLoading: TextProps.VALUE_FALSE });
          this.props.displayError();
        }
      })
      .catch((error) => {
        this.setState({ isLoading: TextProps.VALUE_FALSE });
        this.props.displayError();
      });
  };

  sendApplicationSubmitAnalyticsEvent = () => {
    //start google analytics code for getting number of people applying application group by language
    let currentLanguageCode =
    helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
  if (currentLanguageCode) {
    switch (currentLanguageCode) {
      case types.SPANISH:
        visitor.pageview({
          dp: googleAnalyticsConstVar.DP_APPLY,
          dt: googleAnalyticsConstVar.DT_APPLY,
          dh:
            googleAnalyticsConstVar.URL_PATH +
            googleAnalyticsConstVar.SNAP_APPLY,
        });
        visitor
          .event(
            googleAnalyticsConstVar.DT_APPLY,
            "submitApplicationESAction",
            googleAnalyticsConstVar.EVENT_LABEL,
            42
          )
          .send();
        break;
      case types.PORTUGUESE:
        visitor.pageview({
          dp: googleAnalyticsConstVar.DP_APPLY,
          dt: googleAnalyticsConstVar.DT_APPLY,
          dh:
            googleAnalyticsConstVar.URL_PATH +
            googleAnalyticsConstVar.SNAP_APPLY,
        });
        visitor
          .event(
            googleAnalyticsConstVar.DT_APPLY,
            "submitApplicationBRAction",
            googleAnalyticsConstVar.EVENT_LABEL,
            42
          )
          .send();

        break;
      case types.CHINESE:
        visitor.pageview({
          dp: googleAnalyticsConstVar.DP_APPLY,
          dt: googleAnalyticsConstVar.DT_APPLY,
          dh:
            googleAnalyticsConstVar.URL_PATH +
            googleAnalyticsConstVar.SNAP_APPLY,
        });
        visitor
          .event(
            googleAnalyticsConstVar.DT_APPLY,
            "submitApplicationZHAction",
            googleAnalyticsConstVar.EVENT_LABEL,
            42
          )
          .send();
        break;
      case types.VIETNAMESE:
        visitor.pageview({
          dp: googleAnalyticsConstVar.DP_APPLY,
          dt: googleAnalyticsConstVar.DT_APPLY,
          dh:
            googleAnalyticsConstVar.URL_PATH +
            googleAnalyticsConstVar.SNAP_APPLY,
        });
        visitor
          .event(
            googleAnalyticsConstVar.DT_APPLY,
            "submitApplicationVIAction",
            googleAnalyticsConstVar.EVENT_LABEL,
            42
          )
          .send();
        break;
      case types.ENGLISH:
      default:
        visitor.pageview({
          dp: googleAnalyticsConstVar.DP_APPLY,
          dt: googleAnalyticsConstVar.DT_APPLY,
          dh:
            googleAnalyticsConstVar.URL_PATH +
            googleAnalyticsConstVar.SNAP_APPLY,
        });
        visitor
          .event(
            googleAnalyticsConstVar.DT_APPLY,
            "submitApplicationENAction",
            googleAnalyticsConstVar.EVENT_LABEL,
            42
          )
          .send();
        break;
    }
  }
  }

  applySignAndSubmitSectionValidation = () => {
    let HOH;
    let userName = "";
    if(this.props.interimReport.householdMembers.membersList){
    HOH = this.props.interimReport.householdMembers.membersList.filter(mem => mem.granteeFlag === TextProps.VALUE_TRUE)[0];
    userName = HOH.firstName + " " +  HOH.lastName;
    }
    if (this.state.rights < 1) {
      this.setState({ agreeError: TextProps.VALUE_TRUE });
      this.agrreErrorMsg = interimReportPageLabels(
        language,
        languageConstants.beforeYouStart
      ).agreeTermsErrorMsg;
    } else {
      this.setState({ agreeError: TextProps.VALUE_FALSE });
      this.agrreErrorMsg = "";
    }

    this.setState(
      { signature: this.refs.applicanSign.inputEl.value,
        signatureError: TextProps.VALUE_FALSE  },
      () => {
        if (this.state.signature.length < 1) {
          this.setState({ signatureError: TextProps.VALUE_TRUE });
          this.signatureErrorMsg = interimReportPageLabels(
            language,
            languageConstants.beforeYouStart
          ).signatureErrorMsg;
        }else if (this.props.interimReport.sections.length > 4 && this.state.signature.toLowerCase() !== userName.toLowerCase()){
          this.setState({ signatureError: TextProps.VALUE_TRUE });
          this.signatureErrorMsg = interimReportPageLabels(
            language,
            languageConstants.beforeYouStart
          ).signatureUserNameErrorMsg;
        } else {
          this.setState({ signatureError: TextProps.VALUE_FALSE });
          this.signatureErrorMsg = "";
        }
      }
    );
    return;
  }

  applyContactInfoSectionTempData = () => {
    let contactInfoObj = _.cloneDeep(
      this.props.interimReport.contactInfoScreen
    );

    if (contactInfoObj.emailTemp !== undefined) {
      contactInfoObj.email = contactInfoObj.emailTemp;
      delete contactInfoObj.emailTemp;
    }
    if (contactInfoObj.emailOptInTemp !== undefined) {
      contactInfoObj.emailOptIn = contactInfoObj.emailOptInTemp;
      delete contactInfoObj.emailOptInTemp;
    }

    if (contactInfoObj.phoneNumberTemp !== undefined) {
      contactInfoObj.phoneNumber = contactInfoObj.phoneNumberTemp;
      delete contactInfoObj.phoneNumberTemp;
    }
    if (contactInfoObj.textOptInTemp !== undefined) {
      contactInfoObj.textOptIn = contactInfoObj.textOptInTemp;
      delete contactInfoObj.textOptInTemp;
    }

    contactInfoObj.editEmail = TextProps.VALUE_FALSE;
    contactInfoObj.editPhone = TextProps.VALUE_FALSE;
    this.props.setContactInfo(contactInfoObj);
  }

  processChildSupportSectionData = () => {
    if (this.props.interimReport.obligationChildSupportChanged!== undefined && this.props.interimReport.obligationChildSupportChanged===TextProps.VALUE_TRUE ){
      let childExpExisting = _.cloneDeep(
        this.props.interimReport.responseObject.childSupportExpense
      );
      let childExpNew = this.incomeSummary.childSupportIncomeMembers;
      childExpNew.forEach((newExp)=>{
        if (newExp.firstName) {
          let filtered = _.findIndex(childExpExisting, {
            firstName: newExp.firstName.split(" ")[0],
            lastName: newExp.firstName.split(" ")[1],
            dateOfBirth: " " + newExp.firstName.split(" ")[2],
          });
          if (filtered < 0) {
            const income = newExp.incomes[0];
            if(income.Answer){
            if (income.Answer.isRemoved) {
              income.Answer.code = "removed";
            } else if (income.Answer.code === "changed") {
              income.Answer.code = "new";
            } else {
              income.Answer.code = "new";
            }
          }
            // newExp.incomes[0].Answer.code = newExp.incomes[0].Answer.isRemoved ? "removed":newExp.incomes[0].Answer.code === "changed" ? "changed" :"new"
          }
        }
        let newMem = newExp.firstName;
        childExpExisting.forEach((oldExp)=>{
          if(oldExp.firstName+ " " +oldExp.lastName+oldExp.dateOfBirth === newMem){
              if((newExp.incomes[0].Answer.amount !== oldExp.amount || newExp.incomes[0].Answer.unit !== getShelterandChildSupportFrequency(language)[oldExp.frequencyCode]) && newExp.incomes[0].Answer.isRemoved !== true){
                newExp.incomes[0].Answer.code = "changed"
              } else if(newExp.incomes[0].Answer.isRemoved === true){
                newExp.incomes[0].Answer.code = "removed"
              } else{
                newExp.incomes[0].Answer.code = oldExp.dataChangeCode
              }
          }else if(newExp.firstName){
            let filtered = _.findIndex(childExpExisting, {
              firstName: newExp.firstName.split(" ")[0],
              lastName: newExp.firstName.split(" ")[1],
              dateOfBirth: " "+newExp.firstName.split(" ")[2],
            });
            if(filtered < 0){
              const income = newExp.incomes[0];
              if(income.Answer){
              if (income.Answer.isRemoved) {
                income.Answer.code = "removed";
              } else if (income.Answer.code === "changed") {
                income.Answer.code = "new";
              } else {
                income.Answer.code = "new";
              }
            }
              // newExp.incomes[0].Answer.code = newExp.incomes[0].Answer.isRemoved? "removed":"new"
            }
          }
        })
      })
    }
  }

  checkSectionChangeStatus = (activeSectionId)=>{
    let {additionalProperties} = this.props.interimReport;
    let reportChanges = false;
    if(additionalProperties){
      switch (activeSectionId) {
        //Clearing HouseHold Cache Template
        case "contact-info":
          if(additionalProperties.contactChanged){
            reportChanges = true
          }
          break;
        case "income-summary":
          if(additionalProperties.grossEarnedIncomeChanged || additionalProperties.earnedIncomeChanged){
            reportChanges = true
          }
          break;
        case "unearned-income-summary":
          if(additionalProperties.unearnedIncomeChanged){
            reportChanges = true
          }
          break;
        case "childsupport-expenses":
          if(additionalProperties.courtOrderedChildSupportChanged || additionalProperties.obligationChildSupportChanged){
            reportChanges = true
          }
          break;
          case "shelter-utility-summary":
          if(additionalProperties.shelterChanged){
              reportChanges = true
            }
            break;
        default:
          //Nothing
          break;
      }
    }
    return reportChanges
  }

  applySectionDataChangeStatus = (activeSectionId, data) => {
    let {additionalProperties} = this.props.interimReport;
    let index = 0;
    let reportChanges = false;
      switch (activeSectionId) {
        //Clearing HouseHold Cache Template
        case "contact-info":
          index = 1;
          if(additionalProperties && additionalProperties.contactChanged){
            reportChanges = true
          }
          break;

        case "my-household":
          index = 2;
          break;

        case "income-summary":
          index = 3;
          if(additionalProperties && additionalProperties.grossEarnedIncomeChanged || additionalProperties && additionalProperties.earnedIncomeChanged){
            reportChanges = true
          }
          this.setState({
            earnedIncomeChangeFlag: !this.state.earnedIncomeChangeFlag,
          });
          break;

        case "unearned-income-summary":
          index = 4;
          if(additionalProperties && additionalProperties.unearnedIncomeChanged){
            reportChanges = true
          }
          this.setState({
            unearnedIncomeChanged: !this.state.unearnedIncomeChanged,
          });
          break;
        case "childsupport-expenses":
          index = 5;
          if(additionalProperties && additionalProperties.courtOrderedChildSupportChanged || additionalProperties && additionalProperties.obligationChildSupportChanged){
            reportChanges = true
          }
          break;

          case "shelter-utility-summary":
            index = 6;
            if(additionalProperties && additionalProperties.shelterChanged){
              reportChanges = true
            }
            break;
  

        default:
          //Nothing
          break;
      }
      this.props.setDataChangeStatus(reportChanges ? TextProps.VALUE_TRUE : TextProps.VALUE_FALSE, index);
      return reportChanges;
  }
  //Render DHR modal for address validation
  renderAddressValidationModal = () => {
    return (
      <Modal
        isOpen={this.state.addressValidationModalOpen}
        onClose={this.cancelAddressValidationAction}
        modalClass="address-validation-modal"
        titleText={
          beforeYoustartMlanguageLabels(language).addressValidHeaderText
        }
        headerText={
          beforeYoustartMlanguageLabels(language).addressValidHeaderText
        }
      >
        <div className="dta-modal__body pad-all">
          {
            <div className="pure-g margin-bottom-half is-mail-address">
              {this.state.resAddressFlag && (
                <div className="pure-u-1">
                  <label className="dta-form__label">
                    {
                      beforeYoustartMlanguageLabels(language)
                        .addressValidLabelText
                    }
                  </label>
                  <div className="address-row">
                    <label className="dta-form__label dta-form__label--size-small address-row-label ">
                      {
                        beforeYoustartMlanguageLabels(language)
                          .enteredHomeAddressLabelText
                      }
                    </label>
                    <Radio
                      name="contact-info-changed-1"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.enteredResAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.enteredResAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredResAddressValue:
                            this.state.addressObj.enteredResAddress[0],
                          selectedResAdressValue: "",
                        });
                        // this.setState({ selectedResAdressValue: "" });
                        this._handleAddressSelection(
                          "enteredResAddress",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="address-row">
                    <label
                      className="dta-form__label dta-form__label--size-small address-row-label"
                      //htmlFor={this.nextUniqueId()}
                    >
                      {
                        beforeYoustartMlanguageLabels(language)
                          .suggestedHomeAddressLabelText
                      }
                    </label>
                    <Radio
                      name="contact-info-changed-2"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.suggestedResAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.selectedResAdressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredResAddressValue: "",
                          selectedResAdressValue:
                            this.state.addressObj.suggestedResAddress[0],
                        });
                        this._handleAddressSelection(
                          "suggestedResAddress",
                          value
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              {this.state.mailingAddressFlag && (
                <div className="pure-u-1">
                  <div className="address-row">
                    <label className="dta-form__label dta-form__label--size-small address-row-label">
                      {
                        beforeYoustartMlanguageLabels(language)
                          .enteredMailingAddressLabelText
                      }
                    </label>
                    <Radio
                      name="contact-info-changed-3"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.enteredMailingAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.enteredMailingAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredMailingAddressValue:
                            this.state.addressObj.enteredMailingAddress[0],
                          selectedMailingAddressValue: "",
                        });
                        // this.setState({ selectedResAdressValue: "" });
                        this._handleAddressSelection(
                          "enteredMailingAddress",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="address-row">
                    <label
                      className="dta-form__label dta-form__label--size-small address-row-label"
                      //htmlFor={this.nextUniqueId()}
                    >
                      {
                        beforeYoustartMlanguageLabels(language)
                          .suggestedMailingAddressLabelText
                      }
                    </label>
                    <Radio
                      name="contact-info-changed-4"
                      className="dta-form__option-list address-row-radio"
                      optionClass="dta-form__option dta-form__option--width-1-of-2"
                      options={
                        this.state.addressObj
                          ? this.state.addressObj.suggestedMailingAddress
                          : []
                      }
                      required={TextProps.VALUE_TRUE}
                      value={this.state.selectedMailingAddressValue}
                      onChange={(value) => {
                        this.setState({
                          enteredMailingAddressValue: "",
                          selectedMailingAddressValue:
                            this.state.addressObj.suggestedMailingAddress[0],
                        });
                        this._handleAddressSelection(
                          "suggestedMailingAddress",
                          value
                        );
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="dta-modal__footer dta-modal__footer--inline-buttons address-row-footer pure-u-1">
                <button
                  className="dta-button dta-button--large dta-button--primary address-row-footer-buttons address-row-footer-buttons-confirm"
                  onClick={this.confirmAddressValidationAction}
                >
                  {beforeYoustartMlanguageLabels(language).confirmButtonLabel}
                </button>
                <button
                  className="dta-button dta-button--large dta-button--outline-primary address-row-footer-buttons address-row-footer-buttons-cancel"
                  onClick={this.cancelAddressValidationAction}
                >
                  {beforeYoustartMlanguageLabels(language).CancelButtonLabel}
                </button>
              </div>
            </div>
          }
        </div>
      </Modal>
    );
  };
  //Function to validate address by calling Mellisa API
  validateAddressesCallingMellisa(answerAddress, address) {
    let status = TextProps.VALUE_TRUE;
    this.props
      .addressValidation(answerAddress, language)
      .then((data) => {
        if (data.data.length) {
          status = TextProps.VALUE_TRUE;
          let suggestedResAddress = formAddressDisplayValue(
            data.data.filter((res) => res.type === TextProps.VALUE_RES)
          );
          let suggestedMailingAddress = formAddressDisplayValue(
            data.data.filter((res) => res.type === TextProps.VALUE_MAIL)
          );
          let enteredResAddress = formAddressDisplayValue(
            answerAddress.filter((res) => res.type === TextProps.VALUE_RES)
          );
          let enteredMailingAddress = formAddressDisplayValue(
            answerAddress.filter((res) => res.type === TextProps.VALUE_MAIL)
          );
          // const address = this.props.apply.sections[1].subsections[0];
          let resAddrUpdated = TextProps.VALUE_FALSE;
          if (
            this.props.interimReport.residentailAddressStored &&
            enteredResAddress.length
          ) {
            resAddrUpdated = checkIfAddressIsUpdated(
              this.formObjToValidate(
                address.questions.filter((addr) => addr.name === "address")[0]
                  .Answer
              ),
              this.props.interimReport.residentailAddressStored
            );
          } else if (enteredResAddress.length) {
            resAddrUpdated = TextProps.VALUE_TRUE;
          } else {
            resAddrUpdated = TextProps.VALUE_FALSE;
          }
          let mailingAddrUpdated = TextProps.VALUE_FALSE;
          if (
            this.props.interimReport.mailingAddressStored &&
            enteredMailingAddress.length
          ) {
            let mailingaddr = address.questions.filter(
              (addr) => addr.name === "is-also-mailing"
            )[0].conditionals[0].conditionalQuestions[0];
            if (mailingaddr.Answer) {
              mailingAddrUpdated = checkIfAddressIsUpdated(
                this.formObjToValidate(mailingaddr.Answer),
                this.props.interimReport.mailingAddressStored
              );
            }
          } else if (enteredMailingAddress.length) {
            mailingAddrUpdated = TextProps.VALUE_TRUE;
          } else {
            mailingAddrUpdated = TextProps.VALUE_FALSE;
          }
          this.setState(
            {
              addressObj: {
                originalEnteredResAddress: enteredResAddress,
                originalSuggestedResAddress: suggestedResAddress,
                originalEnteredMailingAddress: enteredMailingAddress,
                originalSuggestedMailingAddress: suggestedMailingAddress,
                enteredResAddress: formatAddressDisplay(enteredResAddress),
                suggestedResAddress: formatAddressDisplay(suggestedResAddress),
                enteredMailingAddress: formatAddressDisplay(
                  enteredMailingAddress
                ),
                suggestedMailingAddress: formatAddressDisplay(
                  suggestedMailingAddress
                ),
              },
              enteredResAddressValue: enteredResAddress.length
                ? enteredResAddress[0].formattedAddress
                : "",
              enteredMailingAddressValue: enteredMailingAddress.length
                ? enteredMailingAddress[0].formattedAddress
                : "",
              selectedResAdressValue: "",
              selectedMailingAddressValue: "",
              finalResValueSelected: enteredResAddress.length
                ? enteredResAddress[0]
                : "",
              finalMailingValueSelected: enteredMailingAddress.length
                ? enteredMailingAddress[0]
                : "",
              mailingAddressFlag: mailingAddrUpdated,
              resAddressFlag: resAddrUpdated,
              MelissaApiResponded: true,
            },
            () => {
              this.setState({
                addressValidationModalOpen: TextProps.VALUE_TRUE,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.setState({
          MelissaApiResponded: TextProps.VALUE_FALSE,
        });
        status = TextProps.VALUE_FALSE;
      });
    return status;
  }
  //Function to handle address validation popup actions(close/cancel)
  cancelAddressValidationAction = () => {
    this.confirmAddressValidationAction();
    this.setState({ addressValidationModalOpen: false });
  };
  confirmAddressValidationAction = () => {
    let selectedResAddress = this.state.finalResValueSelected
      ? this.state.finalResValueSelected
      : this.state.addressObj.originalEnteredResAddress[0];
    let selectedMailingAddress = this.state.finalMailingValueSelected
      ? this.state.finalMailingValueSelected
      : this.state.addressObj.originalEnteredMailingAddress[0];
    let addressDatasections = this.state.addressDatasections;
    addressDatasections.questions = appendUpdatedAdrress(
      addressDatasections.questions,
      selectedResAddress,
      selectedMailingAddress
    );
    this.setAddressValidatedToContactInfo(addressDatasections);
    // this.props.interimReport.melisaAddressValidated = TextProps.VALUE_TRUE;
    const renderedSectionsCopy = [];
    const incomingSection = this._getSection(this.state.activeSectionId);
    renderedSectionsCopy.push(incomingSection);
    this.setState({
      addressValidationModalOpen: TextProps.VALUE_FALSE,
    });
    let AnswerObjectCopy = this.state.AnswerObject;
    const address = addressDatasections;
    if (AnswerObjectCopy["add-contact-info"].address) {
      AnswerObjectCopy["add-contact-info"].address = address.questions.filter(
        (addr) => addr.name === "address"
      )[0].Answer;
    }
    if (AnswerObjectCopy["add-contact-info"].mailingAddress) {
      AnswerObjectCopy["add-contact-info"].mailingAddress =
        address.questions.filter(
          (addr) => addr.name === "is-also-mailing"
        )[0].conditionals[0].conditionalQuestions[0].Answer;
    }
    this.setState({ AnswerObject: AnswerObjectCopy });
    this.setState({ isLoading: TextProps.VALUE_TRUE });
    setTimeout(() => {
      this.setState({ renderedSections: renderedSectionsCopy });
      let storeAddrObj = this.updateStoreAddr();
      this.props.applyAddressStorageAPI(storeAddrObj);
      // this.props.updateCurrentSectionData(this.props.apply);
      this.setState({ isLoading: TextProps.VALUE_FALSE });
      // this.props.onPrimaryActionSaveClicked(TextProps.VALUE_TRUE);
    }, 100);
  };
  //Function to update addresses.
  updateStoreAddr() {
    const subsectionData = this.state.addressDatasections;
    let storeAddrObj = [];
    let residentialAddr = this._findTheObj(subsectionData.questions, "address");
    let mailingAddr = this._findTheObj(
      subsectionData.questions,
      "is-also-mailing"
    );
    storeAddrObj.push(
      residentialAddr.Answer
        ? this.formObjToValidate(residentialAddr.Answer)
        : {}
    );
    storeAddrObj.push(
      mailingAddr.conditionals[0].conditionalQuestions[0].Answer
        ? this.formObjToValidate(
            mailingAddr.conditionals[0].conditionalQuestions[0].Answer
          )
        : {}
    );
    return storeAddrObj;
  }
  _findTheObj = (array, find) => {
    return array.filter((value) => value.name === find)[0];
  };
  //set address validated to contact info
  setAddressValidatedToContactInfo = (dataSections) => {
    let contactInfoObj = this.props.interimReport.contactInfoScreen;
    contactInfoObj.melisaAddressValidated = TextProps.VALUE_TRUE;     
            contactInfoObj.addressList.homeless =
              dataSections.questions[0].Answer ===
                interimReportPageLabels(language, languageConstants.aboutMe)
                  .optionYesNo[0]
                ? TextProps.VALUE_TRUE
                : TextProps.VALUE_FALSE;
                let street = dataSections.questions[1].Answer.street
                ? dataSections.questions[1].Answer.street
                : dataSections.questions[1].Answer.addressLine1;
              let line2 = dataSections.questions[1].Answer.addressLine2
                ? dataSections.questions[1].Answer.addressLine2
                : "";
              contactInfoObj.addressList.address[0].line1 =
                dataSections.questions[1].Answer.addressLine1;
              contactInfoObj.addressList.address[0].line2 = line2;
              contactInfoObj.addressList.address[0].city =
                dataSections.questions[1].Answer.city;
              contactInfoObj.addressList.address[0].state =
                street &&
                dataSections.questions[1].Answer.city &&
                dataSections.questions[1].Answer.zipCode
                  ? dataSections.questions[1].Answer.state
                  : "";
              contactInfoObj.addressList.address[0].zip =
                dataSections.questions[1].Answer.zipCode;
              contactInfoObj.addressList.address[0].county =
                dataSections.questions[1].Answer.county;
              contactInfoObj.addressList.address[0].countyCode =
                dataSections.questions[1].Answer.countyCode;
              contactInfoObj.addressList.address[0].verified =
                dataSections.questions[1].Answer.verified;
              contactInfoObj.addressList.address[0].typeCode = "RES";
              contactInfoObj.addressList.address[0].resAddrMatchFlag = TextProps.VALUE_Y;
          
              contactInfoObj.sameMailingAddress = TextProps.VALUE_TRUE;
              if (dataSections.questions[2].Answer === TextProps.VALUE_STR_FALSE) {
                contactInfoObj.sameMailingAddress = TextProps.VALUE_FALSE;
                if (
                  this.props.interimReport.contactInfoScreen.addressList.address.length === 1
                ) {
                  let addressMailing = {};
                  let street = dataSections.questions[2].conditionals[0]
                    .conditionalQuestions[0].Answer.street
                    ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.street
                    : dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.addressLine1;
                  let line2 = dataSections.questions[2].conditionals[0]
                    .conditionalQuestions[0].Answer.addressLine2
                    ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.addressLine2
                    : "";
          
                  addressMailing.line1 =
                    dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.addressLine1;
                  addressMailing.line2 = line2;
                  addressMailing.city = dataSections.questions[2].conditionals[0]
                    .conditionalQuestions[0].Answer.city
                    ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.city
                    : "";
                  addressMailing.state = dataSections.questions[2].conditionals[0]
                    .conditionalQuestions[0].Answer.state
                    ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.state
                    : "";
                  addressMailing.zip = dataSections.questions[2].conditionals[0]
                    .conditionalQuestions[0].Answer.zipCode
                    ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.zipCode
                    : "";
                  addressMailing.county =
                    dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.county;
                  addressMailing.countyCode =
                    dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.countyCode;
                  addressMailing.verified =
                    dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.verified;
                  addressMailing.resAddrMatchFlag = TextProps.VALUE_FALSE;
                  addressMailing.typeCode = "MAIL";
                  contactInfoObj.addressList.address.push(addressMailing);
                  contactInfoObj.addressList.address[0].resAddrMatchFlag =
                    TextProps.VALUE_FALSE;
                } else {
                  let street = dataSections.questions[2].conditionals[0]
                    .conditionalQuestions[0].Answer.street
                    ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.street
                    : dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.addressLine1;
                  let line2 = contactInfoObj.addressList.address[1].addressLine2
                    ? contactInfoObj.addressList.address[1].addressLine2
                    : "";
                  let line02 = contactInfoObj.addressList.address[0].addressLine2
                    ? contactInfoObj.addressList.address[0].Answer.addressLine2
                    : "";
                  contactInfoObj.addressList.address[1].line1 =
                    dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.addressLine1;
                  contactInfoObj.addressList.address[1].city = dataSections.questions[2]
                    .conditionals[0].conditionalQuestions[0].Answer.city
                    ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.city
                    : "";
                  contactInfoObj.addressList.address[1].state = dataSections.questions[2]
                    .conditionals[0].conditionalQuestions[0].Answer.state
                    ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.state
                    : "";
                  contactInfoObj.addressList.address[1].zip = dataSections.questions[2]
                    .conditionals[0].conditionalQuestions[0].Answer.zipCode
                    ? dataSections.questions[2].conditionals[0].conditionalQuestions[0]
                        .Answer.zipCode
                    : "";
                  contactInfoObj.addressList.address[1].county =
                    dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.county;
                  contactInfoObj.addressList.address[1].countyCode =
                    dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.countyCode;
                  contactInfoObj.addressList.address[1].verified =
                    dataSections.questions[2].conditionals[0].conditionalQuestions[0].Answer.verified;
                  contactInfoObj.addressList.address[1].resAddrMatchFlag =
                    TextProps.VALUE_FALSE;
                  contactInfoObj.addressList.address[1].line2 = line2;
                  contactInfoObj.addressList.address[1].typeCode = "MAIL";
                  contactInfoObj.addressList.address[0].resAddrMatchFlag =
                    TextProps.VALUE_FALSE;
                  contactInfoObj.addressList.address[0].line2 = line02;
                }
              } else if (dataSections.questions[2].Answer === TextProps.VALUE_STR_TRUE) {
                contactInfoObj.addressList.address[0].resAddrMatchFlag =
                  TextProps.VALUE_TRUE;
                contactInfoObj.addressList.address.splice(1, 1);
              } else {
                contactInfoObj.addressList.address[0].resAddrMatchFlag =
                  TextProps.VALUE_FALSE;
              }
              this.props.setContactInfo(contactInfoObj);
  }
    //Function to handle address selection
    _handleAddressSelection = (option, value) => {
      let selectedResAddress = {};
      let selectedMailingAddress = {};
      if (option === "suggestedResAddress") {
        selectedResAddress = this.state.addressObj.originalSuggestedResAddress[0];
        this.setState({
          finalResValueSelected: selectedResAddress,
        });
      } else if (option === "enteredResAddress") {
        selectedResAddress = this.state.addressObj.originalEnteredResAddress[0];
        this.setState({
          finalResValueSelected: selectedResAddress,
        });
      } else if (option === "suggestedMailingAddress") {
        selectedMailingAddress =
          this.state.addressObj.originalSuggestedMailingAddress[0];
        this.setState({
          finalMailingValueSelected: selectedMailingAddress,
        });
      } else if (option === "enteredMailingAddress") {
        selectedMailingAddress =
          this.state.addressObj.originalEnteredMailingAddress[0];
        this.setState({
          finalMailingValueSelected: selectedMailingAddress,
        });
      }
    };

  updateHouseHoldMemberSection = (activeId, dataSections) => {
    let stateObj = this.props.interimReport.householdMembers;
            stateObj.addHouseholdMember = TextProps.VALUE_FALSE;
            let dateOfB = new Date(dataSections.questions[5].Answer);
            let dateNumber =
              dateOfB.getDate() < 10
                ? "0" + dateOfB.getDate()
                : dateOfB.getDate();
            dateOfB = " (" +
              (dateOfB.getMonth() < 10 ? "0"+(dateOfB.getMonth()+1) : dateOfB.getMonth()) +
              "/" +
              dateNumber +
              "/" +
              dateOfB.getFullYear() +
              ")";
            let newMember = {
              agencyID: 0, // need to check 0/1
              firstName: dataSections.questions[0].Answer,
              lastName: dataSections.questions[2].Answer,
              dateOfBirth: dateOfB,
              granteeFlag: TextProps.VALUE_FALSE,
              allAnswers: dataSections.questions,
            };
            let membersList = stateObj.membersList;
            membersList.push(newMember);
            stateObj.membersList = membersList;
            this.props.setHouseholdMembers(stateObj);
            let ansObjCopy = this.state.AnswerObject;
            ansObjCopy[activeId].firstName = "";
            ansObjCopy[activeId].lastName = "";
            ansObjCopy[activeId].dateOfBirth = "";
            ansObjCopy[activeId].ssn = "";
            ansObjCopy[activeId].relationship = "";
            ansObjCopy[activeId]["biological-sex"] = "";
            ansObjCopy[activeId].ethnicity = "";
            ansObjCopy[activeId]["is-citizen"] = "";
            ansObjCopy[activeId]["buy-food"] = "";
            ansObjCopy[activeId]["person-income"] = "";
            this.setState({
              AnswerObject: ansObjCopy,
            });
  }

  disableInterimReportSections = () => {
    let sections = this.props.interimReport.sections;
    if (sections) {
      sections.map((section) => {
        section.disabled = TextProps.VALUE_TRUE;
      });
      this.props.interimReport.sections = sections;
      delete this.props.interimReport.responseObject;
    }
  };

  //Function to Manipulate Details on Income page
  updateHouseHoldIncomeDetails = () => {
    var memberObject = {};
    var incomeNameList = [];
    var houseHoldMembersCopy =
      this.props.interimReport.householdMembers.membersList;
    for (let houseHoldMembers of houseHoldMembersCopy) {
      if (houseHoldMembers.dataChangeCode) continue;
      let childMemberObject = _.cloneDeep(memberObject);
      var incomeHouseHoldName = houseHoldMembers.firstName;
      var incomeCombinedName = "";
      if (incomeHouseHoldName !== "") {
        incomeCombinedName =
          incomeHouseHoldName + " " + houseHoldMembers.lastName;
        let incomeHouseHoldDOB = houseHoldMembers.dateOfBirth;
        if (houseHoldMembers.dateOfBirth !== "") {
          incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
        }
      } else if (incomeHouseHoldName === "") {
        incomeCombinedName = houseHoldMembers.firstName;
      }

      // binding the dependents clientIds
      childMemberObject[OptionsProps.OPTION_VALUE] = houseHoldMembers.id;
      childMemberObject[OptionsProps.OPTION_DISPLAY] = incomeCombinedName;
      childMemberObject.id = houseHoldMembers.id;
      childMemberObject.isGrantee = TextProps.VALUE_N;
      incomeNameList.push(incomeCombinedName);
    }
    this.setState({ houseMemberLabels: incomeNameList });
  };

  strToTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  onIncomeSubsectionRender = async (memberTemplate = null) => {
    let answer = [];
    this.props.interimReport.responseObject.earnedIncome.map((income) => {
      answer.push(
        income.firstName + " " + income.lastName + income.dateOfBirth
      );
    });
    let existingtypes = ["WAGES", "SELEMP", "BOARD"];
    var incomeMemberMultipleTemplate = [];
    var incomeMeTemplate = _.cloneDeep(IncomeSectionTemplate);
    if (memberTemplate !== null) incomeMeTemplate = memberTemplate;
    if (incomeMeTemplate && incomeMeTemplate.subsections === undefined && answer.length > 0) {
      incomeMeTemplate.subsections = IncomeSectionTemplate.subsections;
    }
    var incomeSummaryTemplate = this.state.incomeSummaryTemplate;
    var incomeCopy = this.props.interimReport.sections[3];
    if (answer[0] !== "") {
      let prevClient = "";
      let prevClientIndex = 0;
      for (let i = 0; i < answer.length; i++) {
        if (prevClient !== answer[i]) {
          prevClient = answer[i];
          if (i > 0) {
            prevClientIndex++;
          }
          incomeMeTemplate.id = "combined-income-" + prevClientIndex;
          incomeMeTemplate.header = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).heading;
          incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).mainQuestion;
          incomeMeTemplate.subsections[0].id =
            "combined-income-subsection-" + prevClientIndex;
          incomeMeTemplate.subsections[0].header = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          ).heading;
          incomeMeTemplate.title = answer[i];
          incomeMeTemplate.subsections[0].title = answer[i];
          incomeMeTemplate.clientId = answer[i];
          if (this.props.interimReport.sections[3].retainData ===TextProps.VALUE_TRUE ||this.props.interimReport.sections[3].retainData === null) {
            if (
              existingtypes.indexOf(
                this.props.interimReport.responseObject.earnedIncome[i].typeCode
              ) >= 0
            ) {
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
                this.props.interimReport.responseObject.earnedIncome[i]
                  .typeCode,
              ];
              incomeMeTemplate.questions[0].Answer = [
                this.props.interimReport.responseObject.earnedIncome[i]
                  .typeCode,
              ];
            } else {
              let optionsObj = {};
              optionsObj.optionValue =
                this.props.interimReport.responseObject.earnedIncome[
                  i
                ].typeCode;
              optionsObj.optionDisplay =
                earnedIncomeCodeMapper(language)[
                this.props.interimReport.responseObject.earnedIncome[
                  i
                ].typeCode
                ];
              incomeMeTemplate.questions[0][OptionsProps.OPTIONS].push(
                optionsObj
              );
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
                this.props.interimReport.responseObject.earnedIncome[i]
                  .typeCode,
              ];
              incomeMeTemplate.questions[0].Answer = [
                this.props.interimReport.responseObject.earnedIncome[i]
                  .typeCode,
              ];
              existingtypes.push(
                this.props.interimReport.responseObject.earnedIncome[i].typeCode
              );
              let newType = {
                [Props.NAME]:
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode, // ,incomeMultilanguageLabels(language).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  earnedIncomeCodeMapper(language)[
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode
                  ],
                [Props.TYPE]: types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]:
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "",
                    [Props.LABEL_OBJ_TRIGGER]: "",
                    [Props.LABEL_OBJ_AFTER]:
                      " " +
                      earnedIncomeCodeMapper(language)[
                      this.props.interimReport.responseObject.earnedIncome[i]
                        .typeCode
                      ],
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    " " +
                    earnedIncomeCodeMapper(language)[
                    this.props.interimReport.responseObject.earnedIncome[i]
                      .typeCode
                    ],
                  employerLabel:
                    "" +
                    earnedIncomeCodeMapper(language)[
                    this.props.interimReport.responseObject.earnedIncome[i]
                      .typeCode
                    ],
                  unitLabel:
                    " " +
                    earnedIncomeCodeMapper(language)[
                    this.props.interimReport.responseObject.earnedIncome[i]
                      .typeCode
                    ],
                  [Props.TYPE]: types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions,
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherLabel +
                  earnedIncomeCodeMapper(language)[
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode
                  ], //'Add another SSI'
              };
              incomeMeTemplate.subsections[0].questions.push(newType);
            }
          } else if (
            this.props.interimReport.sections[3].retainData ===
            TextProps.VALUE_FALSE ||
            this.props.interimReport.sections[3].retainData === null
          ) {
            if (
              incomeCopy.subsections[i] !== undefined &&
              incomeCopy.subsections[i].questions !== undefined &&
              incomeMeTemplate !== undefined
            ) {
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] =
                incomeCopy.subsections[i].questions[0].targetValue;
              incomeMeTemplate.questions[0].Answer =
                incomeCopy.subsections[i].questions[0].Answer;
            }
          }
          var incomeReturn = interimReportPageLabels(
            language,
            languageConstants.earnedIncomeDynamic,
            answer[i],
            null,
            null
          );
          let dynamicQuestionLabels = incomeDynamicMultilanguageLabels(
            answer[i],
            earnedIncomeCodeMapper(language)[
            this.props.interimReport.responseObject.earnedIncome[i].typeCode
            ],
            language
          );
          incomeReturn.subQuestions.push(dynamicQuestionLabels);
          for (
            let j = 0;
            j < incomeMeTemplate.subsections[0].questions.length;
            j++
          ) {
            incomeMeTemplate.subsections[0].questions[j].amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMeTemplate.subsections[0].questions[j].employerLabel =
              incomeReturn.subQuestions[j].employerLabel;
            incomeMeTemplate.subsections[0].questions[j].unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMeTemplate.subsections[0].questions[
              j
            ].repeatedQuestion.amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMeTemplate.subsections[0].questions[
              j
            ].repeatedQuestion.employerLabel =
              incomeReturn.subQuestions[j].employerLabel;
            incomeMeTemplate.subsections[0].questions[
              j
            ].repeatedQuestion.unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMeTemplate.subsections[0].questions[j].canBeDeleted =
              TextProps.VALUE_TRUE;
            let typeText =
              earnedIncomeCodeMapper(language)[
              incomeMeTemplate.subsections[0].questions[j].valueToMatch
              ];
            if (
              this.props.interimReport.sections[3].retainData ===
              TextProps.VALUE_TRUE ||
              this.props.interimReport.sections[3].retainData === null
            ) {
              if (
                incomeMeTemplate.subsections[0].questions[j].valueToMatch ===
                this.props.interimReport.responseObject.earnedIncome[i].typeCode
              ) {
                let amountObj = [];
                if (
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .amounts !== undefined
                ) {
                  this.props.interimReport.responseObject.earnedIncome[
                    i
                  ].amounts.map((amount, index) => {
                    amountObj.push(amount.amount + "");
                  });
                }
                incomeMeTemplate.subsections[0].questions[j].Answer = [
                  {
                    fullIncomeObj:
                      this.props.interimReport.responseObject.earnedIncome[i],
                    code: this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode ? this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode:"existing",
                    amount: amountObj,
                    unit: getFrequency(language)[
                      this.props.interimReport.responseObject.earnedIncome[i]
                        .frequencyCode
                    ],
                    employer:
                      this.props.interimReport.responseObject.earnedIncome[i]
                        .employerName,
                    employerLabel:
                      typeText +
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).fromMultiLang +
                      this.props.interimReport.responseObject.earnedIncome[i]
                        .employerName,
                    id: "repeatable_id_" + this.nextUniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] = [
                    {
                      fullIncomeObj:
                        this.props.interimReport.responseObject.earnedIncome[i],
                        code: this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode ? this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode:"existing",
                      amount: amountObj,
                      unit: getFrequency(language)[
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .frequencyCode
                      ],
                      employer:
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .employerName,
                      employerLabel:
                        typeText +
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .employerName,
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                incomeMeTemplate.subsections[0].questions[j].value = [
                  {
                    fullIncomeObj:
                      this.props.interimReport.responseObject.earnedIncome[i],
                      code: this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode ? this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode:"existing",
                    amount: amountObj,
                    unit: getFrequency(language)[
                      this.props.interimReport.responseObject.earnedIncome[i]
                        .frequencyCode
                    ],
                    employer:
                      this.props.interimReport.responseObject.earnedIncome[i]
                        .employerName,
                    employerLabel:
                      typeText +
                      interimReportPageLabels(
                        language,
                        languageConstants.beforeYouStart
                      ).fromMultiLang +
                      this.props.interimReport.responseObject.earnedIncome[i]
                        .employerName,
                    id: "repeatable_id_" + this.nextUniqueId(),
                  },
                ];
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              } else {
                incomeMeTemplate.subsections[0].questions[j].Answer = [];
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] = [];
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              }
            } else if (
              this.props.interimReport.sections[3].retainData ===
              TextProps.VALUE_FALSE ||
              this.props.interimReport.sections[3].retainData === null
            ) {
              if (
                incomeCopy.subsections[i] !== undefined &&
                incomeCopy.subsections[i].questions !== undefined &&
                incomeMeTemplate !== undefined
              ) {
                incomeMeTemplate.subsections[0].questions[j].Answer =
                  incomeCopy.subsections[i].subsections[j].questions[j].Answer;
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] =
                  incomeCopy.subsections[i].subsections[j].questions[
                    j
                  ].targetValue;
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              }
            }
          }
          incomeMemberMultipleTemplate.push(_.cloneDeep(incomeMeTemplate));
        } // if prevClient check ends
        else {
          if (
            this.props.interimReport.sections[3].retainData ===
            TextProps.VALUE_TRUE ||
            this.props.interimReport.sections[3].retainData === null
          ) {
            if (
              existingtypes.indexOf(
                this.props.interimReport.responseObject.earnedIncome[i].typeCode
              ) >= 0
            ) {
              if (
                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ].indexOf(
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode
                ) < 0
              ) {
                if (
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ].length > 0
                ) {
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ].push(
                    this.props.interimReport.responseObject.earnedIncome[i]
                      .typeCode
                  );
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].questions[0].Answer.push(
                    this.props.interimReport.responseObject.earnedIncome[i]
                      .typeCode
                  );
                } else {
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ] = [
                      this.props.interimReport.responseObject.earnedIncome[i]
                        .typeCode,
                    ];
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].questions[0].Answer = [
                      this.props.interimReport.responseObject.earnedIncome[i]
                        .typeCode,
                    ];
                }
              }
            } else {
              let optObj = {};
              optObj.optionValue =
                this.props.interimReport.responseObject.earnedIncome[
                  i
                ].typeCode;
              optObj.optionDisplay =
                earnedIncomeCodeMapper(language)[
                this.props.interimReport.responseObject.earnedIncome[
                  i
                ].typeCode
                ];
              incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                OptionsProps.OPTIONS
              ].push(optObj);
              incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                OptionsProps.TARGET_VALUE
              ].push(
                this.props.interimReport.responseObject.earnedIncome[i].typeCode
              );
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].questions[0].Answer.push(
                this.props.interimReport.responseObject.earnedIncome[i].typeCode
              );
              existingtypes.push(
                this.props.interimReport.responseObject.earnedIncome[i].typeCode
              );
              let newType = {
                [Props.NAME]:
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode,
                [OptionsProps.CONDITIONAL_TARGET_VALUE]:
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode, // ,incomeMultilanguageLabels(language).SSITargetValue,//'SSI (Supplemental Security Income)',
                [OptionsProps.DISPLAY_INCOME_TYPE_NAME]:
                  earnedIncomeCodeMapper(language)[
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode
                  ],
                [Props.TYPE]: types.REPEATABLE_QUESTION,
                [OptionsProps.TABLE_DSC]:
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode,
                [ControlConstants.REPEATABLE_QUESTION]: {
                  [Props.LABEL]: {
                    [Props.LABEL_OBJ_BEFORE]: "",
                    [Props.LABEL_OBJ_TRIGGER]: "",
                    [Props.LABEL_OBJ_AFTER]:
                      " " +
                      earnedIncomeCodeMapper(language)[
                      this.props.interimReport.responseObject.earnedIncome[i]
                        .typeCode
                      ],
                    [Props.LABEL_OBJ_HELP]: interimReportPageLabels(
                      language,
                      languageConstants.earnedIncomeStatic
                    ).grossIncomeHelp,
                    // 'Gross income is the total income before anything is taken out, such as taxes or health insurance.'
                  },
                  amountLabel:
                    " " +
                    earnedIncomeCodeMapper(language)[
                    this.props.interimReport.responseObject.earnedIncome[i]
                      .typeCode
                    ],
                  employerLabel:
                    "" +
                    earnedIncomeCodeMapper(language)[
                    this.props.interimReport.responseObject.earnedIncome[i]
                      .typeCode
                    ],
                  unitLabel:
                    " " +
                    earnedIncomeCodeMapper(language)[
                    this.props.interimReport.responseObject.earnedIncome[i]
                      .typeCode
                    ],
                  [Props.TYPE]: types.EARNED_INCOME,
                  [UnitsProps.IN_BETWEEN]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).costBetween,
                  [UnitsProps.UNITS]: interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).unitsOptions,
                  [UnitsProps.MONEY]: TextProps.VALUE_TRUE,
                },
                [ControlConstants.REPEATABLE_QUESTION_BUTTON_LABEL]:
                  interimReportPageLabels(
                    language,
                    languageConstants.earnedIncomeStatic
                  ).anotherLabel +
                  earnedIncomeCodeMapper(language)[
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode
                  ], //'Add another SSI'
              };
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions.push(newType);
            }
            let dynamicQuestionLabels = incomeDynamicMultilanguageLabels(
              answer[i],
              earnedIncomeCodeMapper(language)[
              this.props.interimReport.responseObject.earnedIncome[i].typeCode
              ],
              language
            );
            incomeReturn.subQuestions.push(dynamicQuestionLabels);
            for (
              let j = 0;
              j <
              incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                .questions.length;
              j++
            ) {
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].amountLabel =
                incomeReturn.subQuestions[j].amountLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].employerLabel =
                incomeReturn.subQuestions[j].employerLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].unitLabel =
                incomeReturn.subQuestions[j].unitLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion.amountLabel =
                incomeReturn.subQuestions[j].amountLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion.employerLabel =
                incomeReturn.subQuestions[j].employerLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion.unitLabel =
                incomeReturn.subQuestions[j].unitLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].canBeDeleted = TextProps.VALUE_TRUE;
              let typeText =
                earnedIncomeCodeMapper(language)[
                incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                  .questions[j].valueToMatch
                ];

              if (
                this.props.interimReport.sections[3].retainData ===
                TextProps.VALUE_TRUE ||
                this.props.interimReport.sections[3].retainData === null
              ) {
                if (
                  incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                    .questions[j].valueToMatch ===
                  this.props.interimReport.responseObject.earnedIncome[i]
                    .typeCode
                ) {
                  let amountObj = [];
                  if (
                    this.props.interimReport.responseObject.earnedIncome[i]
                      .amounts !== undefined
                  ) {
                    this.props.interimReport.responseObject.earnedIncome[
                      i
                    ].amounts.map((amount, index) => {
                      amountObj.push(amount.amount + "");
                    });
                  }
                  if (
                    incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                      .questions[j].Answer !== undefined &&
                    incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                      .questions[j].Answer.length > 0
                  ) {
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].Answer.push({
                      fullIncomeObj:
                        this.props.interimReport.responseObject.earnedIncome[i],
                        code: this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode ? this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode:"existing",
                      amount: amountObj,
                      unit: getFrequency(language)[
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .frequencyCode
                      ],
                      employer:
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .employerName,
                      employerLabel:
                        typeText +
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .employerName,
                      id: "repeatable_id_" + this.nextUniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j][
                      OptionsProps.TARGET_VALUE
                    ].push({
                      fullIncomeObj:
                        this.props.interimReport.responseObject.earnedIncome[i],
                        code: this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode ? this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode:"existing",
                      amount: amountObj,
                      unit: getFrequency(language)[
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .frequencyCode
                      ],
                      employer:
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .employerName,
                      employerLabel:
                        typeText +
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .employerName,
                      id: "repeatable_id_" + this.nextUniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].value.push({
                      fullIncomeObj:
                        this.props.interimReport.responseObject.earnedIncome[i],
                        code: this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode ? this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode:"existing",
                      amount: amountObj,
                      unit: getFrequency(language)[
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .frequencyCode
                      ],
                      employer:
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .employerName,
                      employerLabel:
                        typeText +
                        interimReportPageLabels(
                          language,
                          languageConstants.beforeYouStart
                        ).fromMultiLang +
                        this.props.interimReport.responseObject.earnedIncome[i]
                          .employerName,
                      id: "repeatable_id_" + this.nextUniqueId(),
                    });
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].completed =
                      TextProps.VALUE_FALSE;
                  } else {
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].Answer = [
                        {
                          fullIncomeObj:
                            this.props.interimReport.responseObject.earnedIncome[
                            i
                            ],
                            code: this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode ? this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode:"existing",
                          amount: amountObj,
                          unit: getFrequency(language)[
                            this.props.interimReport.responseObject.earnedIncome[
                              i
                            ].frequencyCode
                          ],
                          employer:
                            this.props.interimReport.responseObject.earnedIncome[
                              i
                            ].employerName,
                          employerLabel:
                            typeText +
                            interimReportPageLabels(
                              language,
                              languageConstants.beforeYouStart
                            ).fromMultiLang +
                            this.props.interimReport.responseObject.earnedIncome[
                              i
                            ].employerName,
                          id: "repeatable_id_" + this.nextUniqueId(),
                        },
                      ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j][OptionsProps.TARGET_VALUE] = [
                        {
                          fullIncomeObj:
                            this.props.interimReport.responseObject.earnedIncome[
                            i
                            ],
                            code: this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode ? this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode:"existing",
                          amount: amountObj,
                          unit: getFrequency(language)[
                            this.props.interimReport.responseObject.earnedIncome[
                              i
                            ].frequencyCode
                          ],
                          employer:
                            this.props.interimReport.responseObject.earnedIncome[
                              i
                            ].employerName,
                          employerLabel:
                            typeText +
                            interimReportPageLabels(
                              language,
                              languageConstants.beforeYouStart
                            ).fromMultiLang +
                            this.props.interimReport.responseObject.earnedIncome[
                              i
                            ].employerName,
                          id: "repeatable_id_" + this.nextUniqueId(),
                        },
                      ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].value = [
                        {
                          fullIncomeObj:
                            this.props.interimReport.responseObject.earnedIncome[
                            i
                            ],
                            code: this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode ? this.props.interimReport.responseObject.earnedIncome[i].dataChangeCode:"existing",
                          amount: amountObj,
                          unit: getFrequency(language)[
                            this.props.interimReport.responseObject.earnedIncome[
                              i
                            ].frequencyCode
                          ],
                          employer:
                            this.props.interimReport.responseObject.earnedIncome[
                              i
                            ].employerName,
                          employerLabel:
                            typeText +
                            interimReportPageLabels(
                              language,
                              languageConstants.beforeYouStart
                            ).fromMultiLang +
                            this.props.interimReport.responseObject.earnedIncome[
                              i
                            ].employerName,
                          id: "repeatable_id_" + this.nextUniqueId(),
                        },
                      ];
                    incomeMemberMultipleTemplate[
                      prevClientIndex
                    ].subsections[0].questions[j].completed =
                      TextProps.VALUE_FALSE;
                  }
                }
              }
            }
          } //If income populated check ends
        }
      }
    }
    incomeMemberMultipleTemplate.push(incomeSummaryTemplate);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeMemberMultipleTemplate;
    this.props.appendIRIncomeDetails(incomeCopy);
    this.setState({ incomeValuesPopulated: TextProps.VALUE_TRUE });
    this.prepareIncomeSummary();
  };

  onUnearnedIncomeSubsectionRender = async (memberTemplate = null) => {
    let answer = [];
    var existingtypes = [
      "TAFDCS",
      "RSDI",
      "SSI",
      "VETBEN",
      "UNEMPL",
      "WRKCMP",
      "INTINC",
      "MILPE",
      "RETFND",
      "RRRET",
      "LIHEAP",
      "RENTAL",
      "CHSDOR",
      "ALIMNY",
      "CONTR",
      "OTHER"
    ];
    this.props.interimReport.responseObject.unearnedIncome.map((income) => {
      answer.push(
        income.firstName + " " + income.lastName + income.dateOfBirth
      );
    });
    var incomeMemberMultipleTemplate = [];
    var incomeMeTemplate = unearnedIncomeSectionTemplate;
    if (memberTemplate !== null) incomeMeTemplate = memberTemplate;
    if (incomeMeTemplate && incomeMeTemplate.subsections === undefined && answer.length > 0) {
      incomeMeTemplate.subsections = unearnedIncomeSectionTemplate.subsections;
    }
    var unearnedIncomeSummaryTemplate =
      this.state.unearnedIncomeSummaryTemplate;
    var incomeCopy = this.props.interimReport.sections[4];

    if (answer[0] !== "") {
      let prevClient = "";
      let prevClientIndex = 0;
      for (let i = 0; i < answer.length; i++) {
        if (prevClient !== answer[i]) {
          prevClient = answer[i];
          if (i > 0) {
            prevClientIndex++;
          }
          incomeMeTemplate.id = "unearned-combined-income-" + prevClientIndex;
          incomeMeTemplate.header = interimReportPageLabels(
            language,
            languageConstants.unearnedIncomeDynamic,
            answer[i],
            null,
            null
          ).heading;
          incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
            language,
            languageConstants.unearnedIncomeDynamic,
            answer[i],
            null,
            null
          ).mainQuestion;
          incomeMeTemplate.subsections[0].id =
            "unearned-combined-income-subsection-" + prevClientIndex;
          incomeMeTemplate.subsections[0].header = interimReportPageLabels(
            language,
            languageConstants.unearnedIncomeDynamic,
            answer[i],
            null,
            null
          ).heading;
          incomeMeTemplate.title = answer[i];
          incomeMeTemplate.subsections[0].title = answer[i];
          incomeMeTemplate.clientId = answer[i];
          if (
            this.props.interimReport.sections[4].retainData ===
            TextProps.VALUE_TRUE ||
            this.props.interimReport.sections[4].retainData === null
          ) {
            if (
              existingtypes.indexOf(
                this.props.interimReport.responseObject.unearnedIncome[i]
                  .typeCode
              ) >= 0
            ) {
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] = [
                this.props.interimReport.responseObject.unearnedIncome[i]
                  .typeCode,
              ];
              incomeMeTemplate.questions[0].Answer = [
                this.props.interimReport.responseObject.unearnedIncome[i]
                  .typeCode,
              ];
            }
          } else if (
            this.props.interimReport.sections[4].retainData ===
            TextProps.VALUE_FALSE
          ) {
            if (
              incomeCopy.subsections[i] !== undefined &&
              incomeCopy.subsections[i].questions !== undefined &&
              incomeMeTemplate !== undefined
            ) {
              incomeMeTemplate.questions[0][OptionsProps.TARGET_VALUE] =
                incomeCopy.subsections[i].questions[0].targetValue;
              incomeMeTemplate.questions[0].Answer =
                incomeCopy.subsections[i].questions[0].Answer;
            }
          }
          var incomeReturn = interimReportPageLabels(
            language,
            languageConstants.unearnedIncomeDynamic,
            answer[i],
            null,
            null
          );
          let dynamicQuestionLabels = unearnedIncomeDynamicMultilanguageLabels(
            answer[i],
            unearnedIncomeCodeMapper(language)[
            this.props.interimReport.responseObject.unearnedIncome[i].typeCode
            ],
            language
          );
          incomeReturn.subQuestions.push(dynamicQuestionLabels);
          for (
            let j = 0;
            j < incomeMeTemplate.subsections[0].questions.length;
            j++
          ) {
            incomeMeTemplate.subsections[0].questions[j][Props.LABEL] =
              incomeReturn.subQuestions[j][Props.LABEL];
            incomeMeTemplate.subsections[0].questions[j].amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMeTemplate.subsections[0].questions[j].unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMeTemplate.subsections[0].questions[j].repeatedQuestion[
              Props.LABEL
            ] = incomeReturn.subQuestions[j][Props.LABEL];
            incomeMeTemplate.subsections[0].questions[
              j
            ].repeatedQuestion.amountLabel =
              incomeReturn.subQuestions[j].amountLabel;
            incomeMeTemplate.subsections[0].questions[
              j
            ].repeatedQuestion.unitLabel =
              incomeReturn.subQuestions[j].unitLabel;
            incomeMeTemplate.subsections[0].questions[j].canBeDeleted =
              TextProps.VALUE_TRUE;
            if (
              this.props.interimReport.sections[4].retainData ===
              TextProps.VALUE_TRUE ||
              this.props.interimReport.sections[4].retainData === null
            ) {
              if (
                existingtypes.indexOf(
                  this.props.interimReport.responseObject.unearnedIncome[i]
                    .typeCode
                ) >= 0
              ) {
                if (
                  incomeMeTemplate.subsections[0].questions[j].valueToMatch ===
                  this.props.interimReport.responseObject.unearnedIncome[i]
                    .typeCode
                ) {
                  let amountArray = [];
                  if (
                    this.props.interimReport.responseObject.unearnedIncome[i]
                      .amounts !== undefined
                  ) {
                    this.props.interimReport.responseObject.unearnedIncome[
                      i
                    ].amounts.map((amount) => {
                      amountArray.push(amount.amount);
                    });
                  }
                  incomeMeTemplate.subsections[0].questions[j].Answer = [
                    {
                      fullIncomeObj:
                        this.props.interimReport.responseObject.unearnedIncome[
                        i
                        ],
                      originalTypeCode:
                        this.props.interimReport.responseObject.unearnedIncome[
                          i
                        ].typeCode,
                      code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                      employer: "State",
                      amount: amountArray,
                      unit: getFrequency(language)[
                        this.props.interimReport.responseObject.unearnedIncome[
                          i
                        ].frequencyCode
                      ],
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                  incomeMeTemplate.subsections[0].questions[j][
                    OptionsProps.TARGET_VALUE
                  ] = [
                      {
                        fullIncomeObj:
                          this.props.interimReport.responseObject.unearnedIncome[
                          i
                          ],
                        originalTypeCode:
                          this.props.interimReport.responseObject.unearnedIncome[
                            i
                          ].typeCode,
                        code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.interimReport.responseObject.unearnedIncome[
                            i
                          ].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      },
                    ];
                  incomeMeTemplate.subsections[0].questions[j].value = [
                    {
                      fullIncomeObj:
                        this.props.interimReport.responseObject.unearnedIncome[
                        i
                        ],
                      originalTypeCode:
                        this.props.interimReport.responseObject.unearnedIncome[
                          i
                        ].typeCode,
                      code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                      employer: "State",
                      amount: amountArray,
                      unit: getFrequency(language)[
                        this.props.interimReport.responseObject.unearnedIncome[
                          i
                        ].frequencyCode
                      ],
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                  incomeMeTemplate.subsections[0].questions[j].completed =
                    TextProps.VALUE_FALSE;
                } else {
                  incomeMeTemplate.subsections[0].questions[j].Answer = [];
                  incomeMeTemplate.subsections[0].questions[j][
                    OptionsProps.TARGET_VALUE
                  ] = [];
                  incomeMeTemplate.subsections[0].questions[j].completed =
                    TextProps.VALUE_FALSE;
                }
              } else {
                if (
                  incomeMeTemplate.subsections[0].questions[j].valueToMatch ===
                  "OTHER" &&
                  this.props.interimReport.responseObject.unearnedIncome[i]
                    .typeCode === "OTHER"
                ) {
                  let amountArray = [];
                  if (
                    this.props.interimReport.responseObject.unearnedIncome[i]
                      .amounts !== undefined
                  ) {
                    this.props.interimReport.responseObject.unearnedIncome[
                      i
                    ].amounts.map((amount) => {
                      amountArray.push(amount.amount);
                    });
                  }
                  incomeMeTemplate.subsections[0].questions[j].Answer = [
                    {
                      fullIncomeObj:
                        this.props.interimReport.responseObject.unearnedIncome[
                        i
                        ],
                      originalTypeCode:
                        this.props.interimReport.responseObject.unearnedIncome[
                          i
                        ].typeCode,
                      code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                      employer: "State",
                      amount: amountArray,
                      unit: getFrequency(language)[
                        this.props.interimReport.responseObject.unearnedIncome[
                          i
                        ].frequencyCode
                      ],
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                  incomeMeTemplate.subsections[0].questions[j][
                    OptionsProps.TARGET_VALUE
                  ] = [
                      {
                        fullIncomeObj:
                          this.props.interimReport.responseObject.unearnedIncome[
                          i
                          ],
                        originalTypeCode:
                          this.props.interimReport.responseObject.unearnedIncome[
                            i
                          ].typeCode,
                        code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.interimReport.responseObject.unearnedIncome[
                            i
                          ].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      },
                    ];
                  incomeMeTemplate.subsections[0].questions[j].value = [
                    {
                      fullIncomeObj:
                        this.props.interimReport.responseObject.unearnedIncome[
                        i
                        ],
                      originalTypeCode:
                        this.props.interimReport.responseObject.unearnedIncome[
                          i
                        ].typeCode,
                      code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                      employer: "State",
                      amount: amountArray,
                      unit: getFrequency(language)[
                        this.props.interimReport.responseObject.unearnedIncome[
                          i
                        ].frequencyCode
                      ],
                      id: "repeatable_id_" + this.nextUniqueId(),
                    },
                  ];
                  incomeMeTemplate.subsections[0].questions[j].completed =
                    TextProps.VALUE_FALSE;
                } else {
                  incomeMeTemplate.subsections[0].questions[j].Answer = [];
                  incomeMeTemplate.subsections[0].questions[j][
                    OptionsProps.TARGET_VALUE
                  ] = [];
                  incomeMeTemplate.subsections[0].questions[j].completed =
                    TextProps.VALUE_FALSE;
                }
              }
            } else if (
              this.props.interimReport.sections[4].retainData ===
              TextProps.VALUE_FALSE
            ) {
              if (
                incomeCopy.subsections[i] !== undefined &&
                incomeCopy.subsections[i].questions !== undefined &&
                incomeMeTemplate !== undefined
              ) {
                incomeMeTemplate.subsections[0].questions[j].Answer =
                  incomeCopy.subsections[i].subsections[j].questions[j].Answer;
                incomeMeTemplate.subsections[0].questions[j][
                  OptionsProps.TARGET_VALUE
                ] =
                  incomeCopy.subsections[i].subsections[j].questions[
                    j
                  ].targetValue;
                incomeMeTemplate.subsections[0].questions[j].completed =
                  TextProps.VALUE_FALSE;
              }
            }
          }
          incomeMemberMultipleTemplate.push(_.cloneDeep(incomeMeTemplate));
        } else {
          if (
            this.props.interimReport.sections[4].retainData ===
            TextProps.VALUE_TRUE ||
            this.props.interimReport.sections[4].retainData === null
          ) {
            if (
              existingtypes.indexOf(
                this.props.interimReport.responseObject.unearnedIncome[i]
                  .typeCode
              ) >= 0
            ) {
              if (

                incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                  OptionsProps.TARGET_VALUE
                ].indexOf(
                  this.props.interimReport.responseObject.unearnedIncome[i]
                    .typeCode
                ) < 0
              ) {
                if (
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ].length > 0
                ) {
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ].push(
                    this.props.interimReport.responseObject.unearnedIncome[i]
                      .typeCode
                  );
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].questions[0].Answer.push(
                    this.props.interimReport.responseObject.unearnedIncome[i]
                      .typeCode
                  );
                } else {
                  incomeMemberMultipleTemplate[prevClientIndex].questions[0][
                    OptionsProps.TARGET_VALUE
                  ] = [
                      this.props.interimReport.responseObject.unearnedIncome[i]
                        .typeCode,
                    ];
                  incomeMemberMultipleTemplate[
                    prevClientIndex
                  ].questions[0].Answer = [
                      this.props.interimReport.responseObject.unearnedIncome[i]
                        .typeCode,
                    ];
                }
              }
            }
            let dynamicQuestionLabels =
              unearnedIncomeDynamicMultilanguageLabels(
                answer[i],
                unearnedIncomeCodeMapper(language)[
                this.props.interimReport.responseObject.unearnedIncome[i]
                  .typeCode
                ],
                language
              );
            incomeReturn.subQuestions.push(dynamicQuestionLabels);

            for (
              let j = 0;
              j <
              incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                .questions.length;
              j++
            ) {
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j][Props.LABEL] =
                incomeReturn.subQuestions[j][Props.LABEL];
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].amountLabel =
                incomeReturn.subQuestions[j].amountLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].unitLabel =
                incomeReturn.subQuestions[j].unitLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion[Props.LABEL] =
                incomeReturn.subQuestions[j][Props.LABEL];
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion.amountLabel =
                incomeReturn.subQuestions[j].amountLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].repeatedQuestion.unitLabel =
                incomeReturn.subQuestions[j].unitLabel;
              incomeMemberMultipleTemplate[
                prevClientIndex
              ].subsections[0].questions[j].canBeDeleted = TextProps.VALUE_TRUE;
              if (
                this.props.interimReport.sections[4].retainData ===
                TextProps.VALUE_TRUE ||
                this.props.interimReport.sections[4].retainData === null
              ) {
                if (
                  existingtypes.indexOf(
                    this.props.interimReport.responseObject.unearnedIncome[i]
                      .typeCode
                  ) >= 0
                ) {
                  if (
                    incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                      .questions[j].valueToMatch ===
                    this.props.interimReport.responseObject.unearnedIncome[i]
                      .typeCode
                  ) {
                    let amountArray = [];
                    if (
                      this.props.interimReport.responseObject.unearnedIncome[i]
                        .amounts !== undefined
                    ) {
                      this.props.interimReport.responseObject.unearnedIncome[
                        i
                      ].amounts.map((amount) => {
                        amountArray.push(amount.amount);
                      });
                    }
                    if (
                      incomeMemberMultipleTemplate[prevClientIndex]
                        .subsections[0].questions[j].Answer !== undefined &&
                      incomeMemberMultipleTemplate[prevClientIndex]
                        .subsections[0].questions[j].Answer.length > 0
                    ) {
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].Answer.push({
                        fullIncomeObj:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i],
                        originalTypeCode:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].typeCode,
                        code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j][
                        OptionsProps.TARGET_VALUE
                      ].push({
                        fullIncomeObj:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i],
                        originalTypeCode:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].typeCode,
                        code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].value.push({
                        fullIncomeObj:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i],
                        originalTypeCode:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].typeCode,
                        code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].completed =
                        TextProps.VALUE_FALSE;
                    } else {
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].Answer = [
                          {
                            fullIncomeObj:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i],
                            originalTypeCode:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].typeCode,
                            code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                            employer: "State",
                            amount: amountArray,
                            unit: getFrequency(language)[
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].frequencyCode
                            ],
                            id: "repeatable_id_" + this.nextUniqueId(),
                          },
                        ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j][OptionsProps.TARGET_VALUE] =
                        [
                          {
                            fullIncomeObj:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i],
                            originalTypeCode:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].typeCode,
                            code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                            employer: "State",
                            amount: amountArray,
                            unit: getFrequency(language)[
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].frequencyCode
                            ],
                            id: "repeatable_id_" + this.nextUniqueId(),
                          },
                        ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].value = [
                          {
                            fullIncomeObj:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i],
                            originalTypeCode:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].typeCode,
                            code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                            employer: "State",
                            amount: amountArray,
                            unit: getFrequency(language)[
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].frequencyCode
                            ],
                            id: "repeatable_id_" + this.nextUniqueId(),
                          },
                        ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].completed =
                        TextProps.VALUE_FALSE;
                    }
                  }
                } else {
                  if (
                    incomeMemberMultipleTemplate[prevClientIndex].subsections[0]
                      .questions[j].valueToMatch === "OTHER" &&
                    this.props.interimReport.responseObject.unearnedIncome[i]
                      .typeCode === "OTHER"
                  ) {
                    let amountArray = [];
                    if (
                      this.props.interimReport.responseObject.unearnedIncome[i]
                        .amounts !== undefined
                    ) {
                      this.props.interimReport.responseObject.unearnedIncome[
                        i
                      ].amounts.map((amount) => {
                        amountArray.push(amount.amount);
                      });
                    }
                    if (
                      incomeMemberMultipleTemplate[prevClientIndex]
                        .subsections[0].questions[j].Answer !== undefined &&
                      incomeMemberMultipleTemplate[prevClientIndex]
                        .subsections[0].questions[j].Answer.length > 0
                    ) {
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].Answer.push({
                        fullIncomeObj:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i],
                        originalTypeCode:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].typeCode,
                        code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j][
                        OptionsProps.TARGET_VALUE
                      ].push({
                        fullIncomeObj:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i],
                        originalTypeCode:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].typeCode,
                        code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].value.push({
                        fullIncomeObj:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i],
                        originalTypeCode:
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].typeCode,
                        code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                        employer: "State",
                        amount: amountArray,
                        unit: getFrequency(language)[
                          this.props.interimReport.responseObject
                            .unearnedIncome[i].frequencyCode
                        ],
                        id: "repeatable_id_" + this.nextUniqueId(),
                      });
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].completed =
                        TextProps.VALUE_FALSE;
                    } else {
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].Answer = [
                          {
                            fullIncomeObj:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i],
                            originalTypeCode:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].typeCode,
                            code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                            employer: "State",
                            amount: amountArray,
                            unit: getFrequency(language)[
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].frequencyCode
                            ],
                            id: "repeatable_id_" + this.nextUniqueId(),
                          },
                        ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j][OptionsProps.TARGET_VALUE] =
                        [
                          {
                            fullIncomeObj:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i],
                            originalTypeCode:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].typeCode,
                            code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                            employer: "State",
                            amount: amountArray,
                            unit: getFrequency(language)[
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].frequencyCode
                            ],
                            id: "repeatable_id_" + this.nextUniqueId(),
                          },
                        ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].value = [
                          {
                            fullIncomeObj:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i],
                            originalTypeCode:
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].typeCode,
                            code: this.props.interimReport.responseObject.unearnedIncome[i].dataChangeCode,
                            employer: "State",
                            amount: amountArray,
                            unit: getFrequency(language)[
                              this.props.interimReport.responseObject
                                .unearnedIncome[i].frequencyCode
                            ],
                            id: "repeatable_id_" + this.nextUniqueId(),
                          },
                        ];
                      incomeMemberMultipleTemplate[
                        prevClientIndex
                      ].subsections[0].questions[j].completed =
                        TextProps.VALUE_FALSE;
                    }
                  }
                }
              }
            }
          } // If income populated check ends
        }
      }
    }
    incomeMemberMultipleTemplate.push(unearnedIncomeSummaryTemplate);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeMemberMultipleTemplate;
    this.props.appendUIIncomeDetails(incomeCopy);
    this.setState({ incomeUIValuesPopulated: TextProps.VALUE_TRUE });
    this.prepareUnearnedIncomeSummary();
  };


  onChildSupportSubsectionRender = async(memberTemplate = null) => {
    let answer = [];
    this.props.interimReport.responseObject.childSupportExpense.map((support) => {
      answer.push(
        support.firstName + " " + support.lastName + support.dateOfBirth
      );
    });
    var childSupportMultipleTemplate = [];
    var childSupportTemplate = _.cloneDeep(expenseChildSupportTemplate);
    var childSupportCopy = this.props.interimReport.sections[5];

    var childSupportSummaryTemplate = _.cloneDeep(this.props.interimReport.sections[5].subsections[
      this.props.interimReport.sections[5].subsections.length - 1
      ]);

      this.setState({
        childSupportSummaryTemplate:childSupportSummaryTemplate
      })
    if (answer[0] !== "") {
      let prevClient = "";
      let prevClientIndex = 0;
      for (let i = 0; i < answer.length; i++) {
        if (prevClient !== answer[i]) {
          prevClient = answer[i];
          if (i > 0) {
            prevClientIndex++;
          }
          if(childSupportTemplate && childSupportTemplate.questions[0]){
            childSupportTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
            language,
            languageConstants.childSupportDynamic,
            answer[i],
            null,
            null
          ).mainQuestion;
          childSupportTemplate.title = answer[i];
          childSupportTemplate.clientId = answer[i];
          childSupportTemplate.id = "child-support-amount-"+i;
          childSupportTemplate.questions[0].id = "child-support-amount-"+i;
          childSupportTemplate.questions[0].clientId = answer[i];
          childSupportTemplate.questions[0].enabledClickAction = (event) => this.handleRemove(event);
          childSupportTemplate.questions[0].enabledDoneAction =(event) => this.handleDone(event);
          childSupportTemplate.questions[0].Answer = {
            "amount": this.props.interimReport.responseObject.childSupportExpense[i]
              .amount,
              "unit": getShelterandChildSupportFrequency(language)[
                this.props.interimReport.responseObject.childSupportExpense[i]
                .frequencyCode
              ],
              "code": this.props.interimReport.responseObject.childSupportExpense[i]
              .dataChangeCode
          };
          childSupportTemplate.questions[0].targetValue = {
            "amount": this.props.interimReport.responseObject.childSupportExpense[i]
              .amount,
              "unit": getShelterandChildSupportFrequency(language)[
                this.props.interimReport.responseObject.childSupportExpense[i]
                .frequencyCode
              ],
              "code": this.props.interimReport.responseObject.childSupportExpense[i]
              .dataChangeCode
          };
          }
          childSupportMultipleTemplate.push(_.cloneDeep(childSupportTemplate));
        }
        else{
            childSupportMultipleTemplate[prevClientIndex].questions[0][
              OptionsProps.TARGET_VALUE
            ]={
              "amount": this.props.interimReport.responseObject.childSupportExpense[i]
              .amount,
              "unit": getShelterandChildSupportFrequency(language)[
                this.props.interimReport.responseObject.childSupportExpense[i]
                .frequencyCode
              ],
              "code": this.props.interimReport.responseObject.childSupportExpense[i]
              .dataChangeCode
            }
            childSupportMultipleTemplate[
              prevClientIndex
            ].questions[0].Answer={
              "amount": this.props.interimReport.responseObject.childSupportExpense[i]
              .amount,
              "unit": getShelterandChildSupportFrequency(language)[
                this.props.interimReport.responseObject.childSupportExpense[i]
                .frequencyCode
              ],
              "code": this.props.interimReport.responseObject.childSupportExpense[i]
              .dataChangeCode
            }
          }
        }
      }
      childSupportMultipleTemplate.push(childSupportSummaryTemplate);
      childSupportCopy.subsections = [];
      childSupportCopy.subsections = childSupportMultipleTemplate;
      this.props.appendChildSupportDetails(childSupportCopy);
      this.prepareChildSupportSummary();
  }

  onIncomeSubSectionClean = async () => {
    if (this.state.incomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          incomeSubSectionCleared: TextProps.VALUE_TRUE,
          incomeMemberTemplate: _.cloneDeep(IncomeSectionTemplate),
          incomeSummaryTemplate:
            this.props.interimReport.sections[3].subsections[
            this.props.interimReport.sections[3].subsections.length - 1
            ],
        },
        () => {
          this.props.clearMemberIncome(
            this.state.incomeMemberTemplate,
            this.state.incomeSummaryTemplate,
            this.props.interimReport.sections[3]
          );
          this.onIncomeSubsectionRender();
        }
      );
    }
  };

  onUnearnedIncomeSubSectionClean = async() => {
    if (this.state.unearnedIncomeSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          unearnedIncomeSubSectionCleared: TextProps.VALUE_TRUE,
          unearnedIncomeMemberTemplate: _.cloneDeep(
            unearnedIncomeSectionTemplate
          ),
          unearnedIncomeSummaryTemplate:
            this.props.interimReport.sections[4].subsections[
            this.props.interimReport.sections[4].subsections.length - 1
            ],
        },
        () => {
          this.props.clearMemberUIIncome(
            this.state.unearnedIncomeMemberTemplate,
            this.state.unearnedIncomeSummaryTemplate,
            this.props.interimReport.sections[4]
          );
          this.onUnearnedIncomeSubsectionRender();
        }
      );
    }
  };


  onChildSupportSubSectionClean = async() => {
    if (this.state.childSupportSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          childSupportSubSectionCleared: TextProps.VALUE_TRUE,
          childSupportExpenseMemberTemplate: _.cloneDeep(
            this.props.interimReport.sections[5]
          ),
          childSupportSummaryTemplate:
            this.props.interimReport.sections[5].subsections[
            this.props.interimReport.sections[5].subsections.length - 1
            ],
        })
          this.onChildSupportSubsectionRender();
    }
  };

  prepareIncomeSummary = () => {
    let incomeSection = this.props.interimReport.sections[3];
    let houseMemberList = this.state.houseMemberLabels;

    this.incomeSummary.incomeMembers = [];
    this.incomeSummary.nonIncomeMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < incomeSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.incomeSummary.incomeMembers[memberIncomeIndex] = {};
      this.incomeSummary.incomeMembers[memberIncomeIndex].section =
        incomeSection.subsections[memberIncomeIndex];
      this.incomeSummary.incomeMembers[memberIncomeIndex].id =
        incomeSection.subsections[memberIncomeIndex].id;
      this.incomeSummary.incomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        incomeSection.subsections[memberIncomeIndex].title;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomeType =
        incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.incomeSummary.incomeMembers[memberIncomeIndex].incomes = [];
      if (
        incomeSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs =
          incomeSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs
            .length;
          questionIndex++
        ) {
          if (
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
              questionIndex
            ].name !== "rental-income-hours-worked-0"
          ) {
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].type =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].displayIncomeName;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].typeCode =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].valueToMatch;
            this.incomeSummary.incomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.incomeSummary.incomeMembers[memberIncomeIndex].incomeInputs[
                questionIndex
              ].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let memberList of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(memberList) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = memberList;
        this.incomeSummary.nonIncomeMembers.push(nonMember);
        nonMemberCount++;
      }
    }
  };

  prepareChildSupportSummary = () => {
    let incomeSection = this.props.interimReport.sections[5];
    let houseMemberList = this.state.houseMemberLabels;
    this.incomeSummary.childSupportIncomeMembers = [];
    this.incomeSummary.childSupportNonIncomeMembers = [];
    let currentIncomeMembers = [];
    if(incomeSection && incomeSection.subsections){
      for (
        var memberIncomeIndex = 0;
        memberIncomeIndex < incomeSection.subsections.length - 1;
        memberIncomeIndex++
      ) {
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex] = {};
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].section =
          incomeSection.subsections[memberIncomeIndex];
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].id =
          incomeSection.subsections[memberIncomeIndex].id;
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;  
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].clientId =
          incomeSection.subsections[memberIncomeIndex].title;
        currentIncomeMembers[memberIncomeIndex] =
          incomeSection.subsections[memberIncomeIndex].title;
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomeType =[];
          incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
        this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomes = [incomeSection.subsections[memberIncomeIndex].questions[0].Answer];
        if (
          incomeSection.subsections[memberIncomeIndex] !== undefined
        ) {
          this.incomeSummary.childSupportIncomeMembers[
            memberIncomeIndex
          ].incomeInputs =
            incomeSection.subsections[memberIncomeIndex].questions;
            for (
              var questionIndex = 0;
              questionIndex <
              this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomeInputs
                .length;
              questionIndex++
            ) {
                this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomes[
                  questionIndex
                ] = {};
                this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomes[
                  questionIndex
                ].id =
                  "childExpense-" + memberIncomeIndex + "-" + questionIndex;
                this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomes[
                  questionIndex
                ].Answer =
                  this.incomeSummary.childSupportIncomeMembers[memberIncomeIndex].incomeInputs[
                    questionIndex
                  ].Answer;
            }
        }  
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let memberList of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(memberList) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = memberList;
        this.incomeSummary.childSupportNonIncomeMembers.push(nonMember);
        nonMemberCount++;
      }
    }
  };

  prepareUnearnedIncomeSummary = () => {
    let incomeSection = this.props.interimReport.sections[4];
    let houseMemberList = this.state.houseMemberLabels;
    this.incomeSummary.unearnedIncomeMembers = [];
    this.incomeSummary.nonUnearnedIncomeMembers = [];
    let currentIncomeMembers = [];
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < incomeSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex] = {};
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].section =
        incomeSection.subsections[memberIncomeIndex];
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].id =
        incomeSection.subsections[memberIncomeIndex].id;
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].firstName =
        incomeSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
        incomeSection.subsections[memberIncomeIndex].title;
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomeType =
        incomeSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes = [];
      if (
        incomeSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.incomeSummary.unearnedIncomeMembers[
          memberIncomeIndex
        ].incomeInputs =
          incomeSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex]
            .incomeInputs.length;
          questionIndex++
        ) {
          if (
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex]
              .incomeInputs[questionIndex].name !==
            "rental-income-hours-worked-0"
          ) {
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "incomeSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].type =
              this.incomeSummary.unearnedIncomeMembers[
                memberIncomeIndex
              ].incomeInputs[questionIndex].displayIncomeName;
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].typeCode =
              this.incomeSummary.unearnedIncomeMembers[
                memberIncomeIndex
              ].incomeInputs[questionIndex].valueToMatch;
            this.incomeSummary.unearnedIncomeMembers[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.incomeSummary.unearnedIncomeMembers[
                memberIncomeIndex
              ].incomeInputs[questionIndex].Answer;
          }
        }
      }
    }
    let nonMemberCount = 0;
    // loop through previous array
    for (let memberList of houseMemberList) {
      // look for same thing in new array
      if (currentIncomeMembers.indexOf(memberList) === -1) {
        let nonMember = {};
        nonMember.id = nonMemberCount;
        nonMember.firstName = memberList;
        this.incomeSummary.nonUnearnedIncomeMembers.push(nonMember);
        nonMemberCount++;
      }
    }
  };

  setUtilityDataChangeCode = (newExp, utilityOldAnswers) => {
    let mappedData = {}
    switch (newExp) {
      case 'acFlag':
        mappedData = { type: newExp, 'code': utilityOldAnswers['acDataChangeCode'], flag: 'acDataChangeCode' }
        break;
      case 'electricOrGasFlag':
        mappedData = { type: newExp, 'code': utilityOldAnswers['electricOrGasDataChangeCode'], flag: 'electricOrGasDataChangeCode' }
        break;
      case 'phoneFlag':
        mappedData = { type: newExp, 'code': utilityOldAnswers['phoneDataChangeCode'], flag: 'phoneDataChangeCode' }
        break;
      case 'payGarbage':
        mappedData = { type: newExp, 'code': utilityOldAnswers['garbageDataChangeCode'], flag: 'garbageDataChangeCode' }
        break;
      case 'heatFlag':
        mappedData = { type: newExp, 'code': utilityOldAnswers['heatDataChangeCode'], flag: 'heatDataChangeCode' }
        break;
      case 'payWaterSewage':
        mappedData = { type: newExp, 'code': utilityOldAnswers['waterSewageDataChangeCode'], flag: 'waterSewageDataChangeCode' }
        break;
      case 'liheapFlag':
        mappedData = { type: newExp, 'code': utilityOldAnswers['liheapDataChangeCode'], flag: 'liheapDataChangeCode' }
        break;
      default:
    }
    return mappedData;
  }


  prepareShelterSummary = (id) => {
    let shelterSection = this.props.interimReport.sections[6];
    this.shelterSummary.shelterExpense = [];
    let currentIncomeMembers = [];
    let utilityOldAnswers = this.props.interimReport.responseObject.utilityExpense;
    for (
      var memberIncomeIndex = 0;
      memberIncomeIndex < shelterSection.subsections.length - 1;
      memberIncomeIndex++
    ) {
      this.shelterSummary.shelterExpense[memberIncomeIndex] = {};
      this.shelterSummary.shelterExpense[memberIncomeIndex].section =
      shelterSection.subsections[memberIncomeIndex];
      this.shelterSummary.shelterExpense[memberIncomeIndex].id =
      shelterSection.subsections[memberIncomeIndex].id;
      this.shelterSummary.shelterExpense[memberIncomeIndex].firstName =
      shelterSection.subsections[memberIncomeIndex].title;
      currentIncomeMembers[memberIncomeIndex] =
      shelterSection.subsections[memberIncomeIndex].title;
      this.shelterSummary.shelterExpense[memberIncomeIndex].incomeType =
      shelterSection.subsections[memberIncomeIndex].questions[0].Answer;
      this.shelterSummary.shelterExpense[memberIncomeIndex].incomes = [];
      if (
        shelterSection.subsections[memberIncomeIndex].subsections !== undefined
      ) {
        this.shelterSummary.shelterExpense[
          memberIncomeIndex
        ].incomeInputs =
        shelterSection.subsections[memberIncomeIndex].subsections[0].questions;
        for (
          var questionIndex = 0;
          questionIndex <
          this.shelterSummary.shelterExpense[memberIncomeIndex]
            .incomeInputs.length;
          questionIndex++
        ) {
            this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
              questionIndex
            ] = {};
            this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
              questionIndex
            ].id =
              "shelterSummaryItem-" + memberIncomeIndex + "-" + questionIndex;
            this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
              questionIndex
            ].typeCode =
            this.shelterSummary.shelterExpense[
                memberIncomeIndex
              ].incomeInputs[questionIndex].valueToMatch;
            this.shelterSummary.shelterExpense[memberIncomeIndex].incomes[
              questionIndex
            ].Answer =
              this.shelterSummary.shelterExpense[
                memberIncomeIndex
              ].incomeInputs[questionIndex].Answer;
        }
      }
    }

    let utilityNewAnswer = [];
    let utilityOld = [];
    let oldRemoved = []




    if(this.props.interimReport.sections && this.props.interimReport.sections[6].subsections && this.props.interimReport.sections[6].subsections[0] && this.props.interimReport.sections[6].subsections[0].subsections[1] && this.props.interimReport.sections[6].subsections[0].subsections[1].questions[0].Answer){
      utilityNewAnswer = this.props.interimReport.sections[6].subsections[0].subsections[1].questions[0].Answer.filter((key) => !key.toLowerCase().includes("datachangecode"));
    } 
    if (this.props.interimReport.responseObject && this.props.interimReport.responseObject.utilityExpense) {
      for (let key in utilityOldAnswers) {
        if (utilityOldAnswers.hasOwnProperty(key) && !key.toLowerCase().includes("datachangecode")) {
          let val = utilityOldAnswers[key];
          if (val) {
            utilityOld.push(key);
          }
        }
      }
    }
    if(this.props.interimReport.responseObject.utilityExpense && this.props.interimReport.responseObject.utilityExpense['liheapFlag'] &&  !utilityNewAnswer.includes('liheapFlag') && !this.props.interimReport.isLiheapDataUpdated){
      utilityNewAnswer.push('liheapFlag');
      this.shelterSummary.isLiheapDataUpdated = "Yes"
    }else if(this.props.interimReport.isLiheapDataUpdated === "Yes" ){
      this.shelterSummary.isLiheapDataUpdated = "Yes"
    }else if( this.props.interimReport.isLiheapDataUpdated === "No" ){
      this.shelterSummary.isLiheapDataUpdated = "No"
    }

    let dataChangeCodes = utilityOld.map((newExp) => {
      return this.setUtilityDataChangeCode(newExp, utilityOldAnswers)
    })

    let previousFlags = this.props.interimReport.sections[6].subsections[0].subsections[1].questions[0].Answer && this.props.interimReport.sections[6].subsections[0].subsections[1].questions[0].Answer.filter((el) => el.includes('DataChangeCode'));
    oldRemoved = previousFlags && previousFlags.filter(function (prevFlg) { return !this.has(prevFlg) }, new Set(dataChangeCodes.map((o) => o.flag)));

    var newUtilities = utilityNewAnswer.filter(function (utilityExp) { return !this.has(utilityExp) }, new Set(dataChangeCodes.map((o) => o.type)));
    let removedUtilities = [];
    utilityOldAnswers = !utilityOldAnswers && {};
    Object.keys(utilityOldAnswers).forEach(function(oldUtil){
      if(utilityOldAnswers[oldUtil]){
        if (utilityNewAnswer.indexOf(oldUtil) <= -1 && !oldUtil.toLowerCase().includes("datachangecode")) {
          removedUtilities.push(oldUtil)
        }
      } else {

      }
    })
    const finalUtilityObj = utilityNewAnswer.filter((util)=>util!=="acFeeFlag").map((elm)=>{
      if(newUtilities.indexOf(elm)>=0){
        return {
          type:elm,
          code:"new"
        }
      }else{
        return {
          type:elm,
          code: dataChangeCodes.length > 0 ? dataChangeCodes.filter((e) => e.type === elm)[0].code : null
        }
      }
    })
    const removedUtilityObj = removedUtilities.map((elm) => {
      return {
        type: elm,
        code: "removed"
      }
    }
    )

    let retainedOld = [];
    oldRemoved && oldRemoved.forEach((removedObj) => {
      let mappedData = {};
      switch (removedObj) {
        case 'acDataChangeCode':
          mappedData = { type: "acFlag", 'code': 'removed', flag: 'acDataChangeCode' }
          break;
        case 'electricOrGasDataChangeCode':
          mappedData = { type: "electricOrGasFlag", 'code': 'removed', flag: 'electricOrGasDataChangeCode' }
          break;
        case 'phoneDataChangeCode':
          mappedData = { type: "phoneFlag", 'code': 'removed', flag: 'phoneDataChangeCode' }
          break;
        case 'garbageDataChangeCode':
          mappedData = { type: "payGarbage", 'code': 'removed', flag: 'garbageDataChangeCode' }
          break;
        case 'heatDataChangeCode':
          mappedData = { type: "heatFlag", 'code': 'removed', flag: 'heatDataChangeCode' }
          break;
        case 'waterSewageDataChangeCode':
          mappedData = { type: "payWaterSewage", 'code': 'removed', flag: 'waterSewageDataChangeCode' }
          break;
        case 'liheapDataChangeCode':
          mappedData = { type: "liheapFlag", 'code': 'removed', flag: 'liheapDataChangeCode' }
          break;
        default:
      }
      retainedOld.push(mappedData)
    })
    retainedOld = _.uniqBy(retainedOld, (val) => val.type);
    let finalUtils = [...finalUtilityObj, ...removedUtilityObj, ...retainedOld];
    this.shelterSummary.utilityExpenses = finalUtils;
    this.checkForDataChange(id)
  }

  shelterSubsectionRender = async () => {
    let answer = [];
    let utilityAnswer = [];
    const { interimReport } = this.props;
    const question = shelterUtilitySectionTemplate.subsections[0].subsections[1].questions[1];
    this.setState({
      shelterDefaultQuestions:shelterUtilitySectionTemplate.subsections[0].subsections[0].questions
    })
    if (this.props.interimReport.responseObject && this.props.interimReport.responseObject.shelterExpense) {
      this.props.interimReport.responseObject.shelterExpense.map((income) => {
        if(!answer.includes(income.typeCode)){
          answer.push(
            income.typeCode
          );
        }
      });
    }
    if (this.props.interimReport.responseObject && this.props.interimReport.responseObject.utilityExpense) {
      utilityAnswer = Object.keys(this.props.interimReport.responseObject.utilityExpense).filter((key) => { if (this.props.interimReport.responseObject.utilityExpense[key]) { return key } });
    }
    if (utilityAnswer.length > 0) {
      shelterUtilitySectionTemplate.subsections[0].subsections[1].questions[0].Answer = utilityAnswer;
      shelterUtilitySectionTemplate.subsections[0].subsections[1].questions[0].targetValue = utilityAnswer;
    }
    if(interimReport.responseObject && interimReport.responseObject.utilityExpense['liheapFlag'] === null){
      question.Answer = "";
      question.targetValue = "";
    }else if (interimReport.isLiheapDataUpdated) {
      const isLiheapDataUpdated = interimReport.isLiheapDataUpdated === 'Yes';
      question.Answer = isLiheapDataUpdated ? 'Yes' : 'No';
      question.targetValue = isLiheapDataUpdated ? 'Yes' : 'No';
    } else if(utilityAnswer.includes('liheapFlag')){
      question.Answer = 'Yes';
      question.targetValue = 'Yes';
    }else {
      question.Answer = 'No';
      question.targetValue = 'No';
    }
    
    if (answer.length > 0) {
      shelterUtilitySectionTemplate.subsections[0].questions[0].Answer = answer;
      shelterUtilitySectionTemplate.subsections[0].questions[0].targetValue = answer;
    }
    
    for (let i = 0; i < shelterUtilitySectionTemplate.subsections[0].subsections[0].questions.length; i++) {
      let amountArr=[];
      if(this.props.interimReport.responseObject.shelterExpense !== undefined) {
        for (
          let j = 0;
          j < this.props.interimReport.responseObject.shelterExpense.length;
          j++
        ) {
          if (
            shelterUtilitySectionTemplate.subsections[0].subsections[0].questions[i].valueToMatch ===
            this.props.interimReport.responseObject.shelterExpense[j]
              .typeCode
          ) {
            if (
              this.props.interimReport.responseObject.shelterExpense[j]
                .amount !== undefined
            ) {
              amountArr.push({
                fullIncomeObj:
                  this.props.interimReport.responseObject.shelterExpense[j],
                originalTypeCode:
                  this.props.interimReport.responseObject.shelterExpense[j].typeCode,
                code: this.props.interimReport.responseObject.shelterExpense[j].dataChangeCode?this.props.interimReport.responseObject.shelterExpense[j].dataChangeCode:"existing",
                unit: getShelterandChildSupportFrequency(language)[
                  this.props.interimReport.responseObject.shelterExpense[j].frequencyCode
                ],
                amount:this.props.interimReport.responseObject.shelterExpense[j]
                .amount,
                id: "repeatable_id_" + this.nextUniqueId(),
              })
              shelterUtilitySectionTemplate.subsections[0].subsections[0].questions[i].Answer = amountArr;
              shelterUtilitySectionTemplate.subsections[0].subsections[0].questions[i][
                OptionsProps.TARGET_VALUE
              ] = amountArr;
              shelterUtilitySectionTemplate.subsections[0].subsections[0].questions[i].value = amountArr;
              shelterUtilitySectionTemplate.subsections[0].subsections[0].questions[i].completed =
                TextProps.VALUE_FALSE;
            } else {
              shelterUtilitySectionTemplate.subsections[0].subsections[0].questions[i].Answer = [];
              shelterUtilitySectionTemplate.subsections[0].subsections[0].questions[i][
                OptionsProps.TARGET_VALUE
              ] = [];
              shelterUtilitySectionTemplate.subsections[0].subsections[0].questions[i].completed =
                TextProps.VALUE_FALSE;
            }
          }
        }
      }
      
    }
    this.props.appendIRShelterQuestions(shelterUtilitySectionTemplate);
    this.prepareShelterSummary();
  }

  incomefilterQuestionsBySelection = (memberIndex) => {
    let incomeSection = this.props.interimReport.sections[3];
    let incomePageAnswers =
      incomeSection.subsections[memberIndex].questions[0].Answer;
    let incomeFilteredQuestions = [];
    if (incomeSection.subsections[memberIndex].subsections !== undefined) {
      var oldQuestions =
        incomeSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    if (this.incomeSubSections[memberIndex] === undefined) {
      this.incomeSubSections[memberIndex] = _.cloneDeep(
        incomeSection.subsections[memberIndex]
      );
    } else {
      incomeSection.subsections[memberIndex] = _.cloneDeep(
        this.incomeSubSections[memberIndex]
      );
      incomeSection.subsections[memberIndex].questions[0].Answer =
        incomePageAnswers;
      incomeSection.subsections[memberIndex].questions[0].targetValue =
        incomePageAnswers;
    }
    this.props.interimReport.sections[3].currentMember =
      this.incomeSubSections[memberIndex].title;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      incomePageAnswers[0] !== "" &&
      incomePageAnswers[0] !==
      interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
        .noneTargetValue &&
      incomePageAnswers[0] !== undefined &&
      incomePageAnswers[0] !==
      interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
        .noneTargetValue
    ) {
      incomeFilteredQuestions = filterQuestionsBySelection(
        incomePageAnswers,
        incomeSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (let filterQuestion of incomeFilteredQuestions) {
        if (oldQuestions !== undefined) {
          for (let oldQuestval of oldQuestions) {
            if (
              oldQuestval.valueToMatch === filterQuestion.valueToMatch &&
              oldQuestval.name === filterQuestion.name
            ) {
              filterQuestion.Answer = oldQuestval.Answer;
              filterQuestion.targetValue = oldQuestval.targetValue;
              let typeText =
                earnedIncomeCodeMapper(language)[oldQuestval.valueToMatch];
              if (
                filterQuestion.Answer !== undefined &&
                filterQuestion.Answer.length > 0
              ) {
                filterQuestion.Answer.map((answer, ansindex) => {
                  filterQuestion[Props.LABEL] = typeText;
                  filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
                });
              } else {
                filterQuestion[Props.LABEL] = typeText;
                filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              }
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            }
          }
        }
      }
      incomeSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(incomeFilteredQuestions);
      this.props.appendIRIncomeDetails(incomeSection);
    } else {
      delete incomeSection.subsections[memberIndex].subsections;
      this.prepareIncomeSummary(() => {
        this.props.appendIRIncomeDetails(incomeSection);
        this.gotoIncomeSummary();
      });
    }
  };

  incomeUnearnedfilterQuestionsBySelection = (memberIndex) => {
    let incomeSection = this.props.interimReport.sections[4];
    let incomePageAnswers =
      incomeSection.subsections[memberIndex].questions[0].Answer;
    let incomeFilteredQuestions = [];
    if (incomeSection.subsections[memberIndex].subsections !== undefined) {
      var oldQuestions =
        incomeSection.subsections[memberIndex].subsections[0].questions;
    }
    //Swap full section for first time and it should not be manipulated!
    if (this.unearnedIncomeSubSections[memberIndex] === undefined) {
      this.unearnedIncomeSubSections[memberIndex] = _.cloneDeep(
        incomeSection.subsections[memberIndex]
      );
    } else {
      incomeSection.subsections[memberIndex] = _.cloneDeep(
        this.unearnedIncomeSubSections[memberIndex]
      );
      incomeSection.subsections[memberIndex].questions[0].Answer =
        incomePageAnswers;
      incomeSection.subsections[memberIndex].questions[0].targetValue =
        incomePageAnswers;
    }
    this.props.interimReport.sections[4].currentMember =
      this.unearnedIncomeSubSections[memberIndex].title;
    incomeSection.subsections[memberIndex].subsections[0].header =
      interimReportPageLabels(
        language,
        languageConstants.unearnedIncomeDynamic,
        this.unearnedIncomeSubSections[memberIndex].title,
        null,
        null
      ).heading;
      incomeSection.subsections[memberIndex].subsections[0].title = this.unearnedIncomeSubSections[memberIndex].title;
    //Checking Answer is None/Not Selected - Delete Sub Section if None Selected
    if (
      incomePageAnswers[0] !== "" &&
      incomePageAnswers[0] !==
      interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
        .noneTargetValue &&
      incomePageAnswers[0] !== undefined &&
      incomePageAnswers[0] !==
      interimReportPageLabels(language, languageConstants.earnedIncomeStatic)
        .noneTargetValue
    ) {
      incomeFilteredQuestions = filterQuestionsBySelection(
        incomePageAnswers,
        incomeSection.subsections[memberIndex].subsections[0].questions
      );
      //Itrate the filtered question.
      for (let filterQuestion of incomeFilteredQuestions) {
        if (oldQuestions !== undefined) {
          for (let oldQuestval of oldQuestions) {
            if (
              oldQuestval.valueToMatch === filterQuestion.valueToMatch &&
              oldQuestval.name === filterQuestion.name
            ) {
              filterQuestion.Answer = oldQuestval.Answer;
              filterQuestion.targetValue = oldQuestval.targetValue;

              let typeText = unearnedIncomeCodeMapper(language)[filterQuestion.valueToMatch];
              if (
                filterQuestion.Answer !== undefined &&
                filterQuestion.Answer.length > 0
              ) {
                filterQuestion.Answer.map((answer, ansindex) => {
                  filterQuestion[Props.LABEL] = typeText;
                  filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
                });
              } else {
                filterQuestion[Props.LABEL] = typeText;
                filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              }
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            } else {
              let typeText = unearnedIncomeCodeMapper(language)[filterQuestion.valueToMatch];
              filterQuestion[Props.LABEL] = typeText;
              filterQuestion.repeatedQuestion[Props.LABEL] = typeText;
              filterQuestion.canBeDeleted = TextProps.VALUE_TRUE;
            }
          }
        }
      }
      incomeSection.subsections[memberIndex].subsections[0].questions =
        _.cloneDeep(incomeFilteredQuestions);
      this.props.appendUIIncomeDetails(incomeSection);
    } else {
      delete incomeSection.subsections[memberIndex].subsections;
      this.prepareUnearnedIncomeSummary(() => {
        this.props.appendUIIncomeDetails(incomeSection);
        this.gotoUnearnedIncomeSummary();
      });
    }
  };

  shelterQuestionsBySelection = (memberIndex) => {
    let shelterSection = this.props.interimReport.sections[6];
    let incomePageAnswers = shelterSection.subsections[0].questions[0].Answer;
    let old = _.uniqBy([...this.props.interimReport.sections[6].subsections[0].subsections[0].questions,...this.state.shelterDefaultQuestions],(q)=>q.name);
    let oldQuestions = _.cloneDeep(old);
    let shelterFilteredQuestions = [];
    if (shelterSection.subsections[0].subsections !== undefined) {
        shelterFilteredQuestions = filterQuestionsBySelection(
          incomePageAnswers,
          oldQuestions
        );
    }
    setTimeout(()=>{
    this.props.appendShelterFilteredQuestions(shelterFilteredQuestions);
    },0)
    
  };

  renderUnearnedIncomeToAddNewMember = (name) => {
    var incomeMeTemplate = _.cloneDeep(unearnedIncomeSectionTemplate);
    var incomeCopy = this.props.interimReport.sections[4];
    let incomeSubsectionCopy = incomeCopy.subsections;

    let incomeSummaryCopy = _.cloneDeep(
      incomeCopy.subsections[incomeCopy.subsections.length - 1]
    );
    incomeCopy.subsections.splice(incomeCopy.subsections.length - 1, 1);
    incomeMeTemplate.id =
      "unearned-combined-income-" + incomeCopy.subsections.length;
    incomeMeTemplate.header = interimReportPageLabels(
      language,
      languageConstants.unearnedIncomeDynamic,
      name,
      null,
      null
    ).heading;
    incomeMeTemplate.questions[0][Props.LABEL] = interimReportPageLabels(
      language,
      languageConstants.unearnedIncomeDynamic,
      name,
      null,
      null
    ).mainQuestion;
    incomeMeTemplate.questions[0].Answer = "";
    incomeMeTemplate.questions[0].value = "";
    incomeMeTemplate.questions[0].targetValue = "";
    incomeMeTemplate.subsections[0].id =
      "unearned-combined-income-subsection-" + incomeCopy.subsections.length;
    incomeMeTemplate.subsections[0].header = interimReportPageLabels(
      language,
      languageConstants.earnedIncomeDynamic,
      name,
      null,
      null
    ).heading;
    incomeMeTemplate.title = name;
    incomeMeTemplate.subsections[0].title = name;
    let incomeReturn = interimReportPageLabels(
      language,
      languageConstants.unearnedIncomeDynamic,
      name,
      null,
      null
    );
    for (let j = 0; j < incomeMeTemplate.subsections[0].questions.length; j++) {
      incomeMeTemplate.subsections[0].questions[j].amountLabel =
        incomeReturn.subQuestions[j].amountLabel;
      incomeMeTemplate.subsections[0].questions[j].unitLabel =
        incomeReturn.subQuestions[j].unitLabel;
      incomeMeTemplate.subsections[0].questions[
        j
      ].repeatedQuestion.amountLabel = incomeReturn.subQuestions[j].amountLabel;
      incomeMeTemplate.subsections[0].questions[j].repeatedQuestion.unitLabel =
        incomeReturn.subQuestions[j].unitLabel;
      incomeMeTemplate.subsections[0].questions[j].canBeDeleted =
        TextProps.VALUE_TRUE;
      incomeMeTemplate.subsections[0].questions[j].Answer = "";
      incomeMeTemplate.subsections[0].questions[j].value = "";
      incomeMeTemplate.subsections[0].questions[j].targetValue = "";
    }
    incomeSubsectionCopy.push(_.cloneDeep(incomeMeTemplate));
    incomeSubsectionCopy.push(incomeSummaryCopy);
    incomeCopy.subsections = [];
    incomeCopy.subsections = incomeSubsectionCopy;
    this.props.appendUIIncomeDetails(incomeCopy);
    this.prepareUnearnedIncomeSummary();
    this.onSectionChange(incomeMeTemplate);
  };

  _renderNextSection = (activeSectionId) => {
    const nextSectionId = getNextSection(activeSectionId, this.state.sections);

    if(!nextSectionId){
      throw new Error(`Next section ID was not found on given sections for the active section: ${activeSectionId}`);
    }

    //Function to Execute before Next page renders
    this.onBeforeSectionRender(nextSectionId);

    this._goToSection(nextSectionId,'forward', () => {
      this._updateCurrentSection(activeSectionId);
    })
  };

  onBeforeSectionRender = (sectionId) =>{
    switch (sectionId) {
      // rendering Income Dynamic Questions.
      case "income-summary":
      case "household-member":
        this.updateHouseHoldIncomeDetails();
        this.prepareIncomeSummary();
        this.prepareUnearnedIncomeSummary();
        break;
      case "unearned-income":
        this.prepareUnearnedIncomeSummary();
        this.prepareChildSupportSummary();
        break;
      //Function to Manipulate Details on HouseHoldPage page
      case "household-details":
        this.updateHouseHoldDetails();
        break;
      //Function to Manipulate Details on Income page
      case "income":
        this.updateHouseHoldIncomeDetails();
        break;
      //Function to Manipulate Details on Expense child support pag

      case "childsupport-expenses":
        this.prepareChildSupportSummary(); 
        break;

      //Function to Manipulate Details on Expense medical page
      case "medical-expenses":
        this.updateHouseHoldMedicalDetails();
        break;
      
        case "shelter-utility-summary":
          this.prepareShelterSummary();
          break;
      //Clearing HouseHold Cache Template
      case "more-info":
        this.setLanguageInScreen();
        break;
      case "Other information":
        this.renderOtherInformation();
        break;
      default:
        //Nothing
        break;
    }
  }

  _goToPreviousSection = (activeSectionId,sections, direction = 'backward', sectionChangedCallback) => {

    const previousSectionId = getPreviousSection(activeSectionId, sections);
    this._goToSection(previousSectionId,direction, sectionChangedCallback);
  }

  _goToSection = (sectionId, direction = 'forward', sectionChangedCallback) => {

    const sectionToGo = this._getSection(sectionId);
    this.setState(
      {
        directionClass: direction,
      },
      () => {
        this.onSectionChange(sectionToGo, TextProps.VALUE_TRUE, sectionChangedCallback);
      }
    );
  }


  // This function is related to simple, sandbox specific 'routing'
  // between sections. Not intended for production
  onSecondaryActionClick = (event,isExitModal) => {
    // event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const { sections } = this.props.interimReport;
    const shelterSections = sections[6];
    let previousSectionId = getPreviousSection(activeId, sections);
    if (previousSectionId) {
      const incomingQuestions = this._getSection(activeId);
      if (
        incomingQuestions.skipsubSectionNavigation !== undefined &&
        incomingQuestions.skipsubSectionNavigation === TextProps.VALUE_TRUE
      ) {
        const activeQuestionId = incomingQuestions.id;
        switch (activeQuestionId) {
          case "my-household":
            previousSectionId = "contact-info";
            break;

          case "contact-info":
            let contactInfoObj = this.props.interimReport.contactInfoScreen;

            if (contactInfoObj.emailTemp !== undefined) {
              delete contactInfoObj.emailTemp;
            }
            if (contactInfoObj.emailOptInTemp !== undefined) {
              delete contactInfoObj.emailOptInTemp;
            }
            if (contactInfoObj.phoneNumberTemp !== undefined) {
              delete contactInfoObj.phoneNumberTemp;
            }
            if (contactInfoObj.textOptInTemp !== undefined) {
              delete contactInfoObj.textOptInTemp;
            }

            contactInfoObj.editEmail = TextProps.VALUE_FALSE;
            contactInfoObj.editPhone = TextProps.VALUE_FALSE;
            contactInfoObj.emailErrorExists = TextProps.VALUE_FALSE;
            contactInfoObj.phoneErrorExists = TextProps.VALUE_FALSE;
            this.props.setContactInfo(contactInfoObj);
            break;

          case "income-summary":
            previousSectionId = "my-household";
            break;

          case "unearned-income-summary":
            previousSectionId = "income-summary";
            break;

          case "childsupport-expenses":
            previousSectionId = "unearned-income-summary";
            break;
          default:
            break;
        }
      }
      if(!isExitModal){
      if (
        activeId.indexOf("unearned-combined-income-") >= 0 &&
        activeId.indexOf("unearned-combined-income-subsection") < 0
      ) {
        previousSectionId = "unearned-income-summary";
      } else if (
        activeId.indexOf("combined-income-") >= 0 &&
        activeId.indexOf("combined-income-subsection") < 0
      ) {
        previousSectionId = "income-summary";
      }else if(activeId.includes("child-support-amount-")){
       previousSectionId = "childsupport-expenses"
      } else if(activeId.includes("utility-expenses-section")){
        if ((!Array.isArray(shelterSections.subsections[0].questions[0].Answer) || shelterSections.subsections[0].questions[0].Answer.length === 0) || this.shelterDoNotPayOptions.includes(shelterSections.subsections[0].questions[0].Answer[0])) {
          previousSectionId = "shelter-utility-summary";
        }
      }
    }
      
      if (this.state.sectionDataUpdated) {
        this.onSaveOrExitClick(event);
        return;
      }
      if (this.state.contactInfoSummaryChanged) {
        this.setState({warningModalOpenStatus: TextProps.VALUE_FALSE})
        setTimeout(() => {
          this.onSaveOrExitClick(event);
        }, 50);
      }
      // if(this.state.exitStatusValue) {
        if(isExitModal){
        if (activeId.indexOf("unearned-combined-income-") >= 0 && !activeId.includes("subsection")) {
          previousSectionId = "unearned-income-summary";
        }else if (activeId.indexOf("combined-income-") >= 0 && !activeId.includes("subsection")) {
          previousSectionId = "income-summary";
        }else if(activeId.includes("child-support-amount-")){
          previousSectionId = "childsupport-expenses"
        }
        console.log("++goback",previousSectionId)
        this._goToSection(previousSectionId,'backward');
        return
      }
        
      // }
      if(!this.state.contactInfoSummaryChanged) {
        this._goToSection(previousSectionId,'backward');
        return;
      }

      if(!this.state.sectionDataUpdated && !this.state.exitStateValue && !this.state.contactInfoSummaryChanged) {
        this._goToSection(previousSectionId,'backward');
      }
    }
    else {
      this.redirectConsumerHome();
    }
  };

  removeSection = (sectionId) => {
    let renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    renderedSectionsCopy = renderedSectionsCopy.filter(
      (section) => section.id !== sectionId
    );

    this.setState({
      renderedSections: renderedSectionsCopy,
    });
  };

  // This function is related to simple, apply specific 'routing'
  // between sections. Not intended for production
  getPrimaryActionLabel = (sectionId) => {
    var returnText = "";
    switch (sectionId) {
      case "before-you-start":
        if(this.props.interimReport.status === "DRAFT"){
          returnText = interimReportPageLabels(
            language,
            languageConstants.beforeYouStart
          ).continueLabel;

          return returnText;
        }else{
          returnText = interimReportPageLabels(
            language,
            languageConstants.beforeYouStart
          ).getStartButtonLabel;
          return returnText;
        }
      case "sign-and-submit":
        returnText = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).submitButtonLabel;
        return returnText;
      default:
        returnText = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).saveAndContinueLabel;
        return returnText;
    }
  };

  // Markup for the body of apply page when user is starting application.
  renderBeforeYouStart = () => {
    return (
      <StartForm
        pdfDownloader={this.downloadApplicationAsPdf}
        startFormData={this.props.interimReport}
        onNotificationItemClick={this.props.displayPDFData}
      ></StartForm>
    );
  };

  handleContactInformationChanged = (status) => {
    this.setState({contactInfoSummaryChanged: status})
  }

  renderContactInfo = () => {
    return (
      <ContactInfo
        onPrimaryActionClick={this.onSectionLinkClick}
        onDataReset={this.resetContactState}
        setDataCodeChangeFlags={this.setDataCodeChangeFlags}
        contactInfoData={this.props.interimReport}
        responseObj={this.props.interimReport.responseObject}
        // setContactInfo={this.props.setContactInfo}
        setContactInfo={this.setContactInfoData}
        addContactInfoSubSection={this.props.addContactInfoSubSection}
        setDataChangeStatus={this.props.setDataChangeStatus}
        contactInfoSummaryChanged={this.state.contactInfoSummaryChanged}
        setcontactInfoSummaryChanged={this.handleContactInformationChanged}
        onSecondaryActionClick={this.onSecondaryActionClick}
      />
    );
  };

  renderHouseholdInfo = () => {
    return (
      <HouseholdInfo
        onPrimaryActionClick={this.onSectionLinkClick}
        addHouseholdAddSectionToList={this.props.addHouseholdAddSectionToList}
        updateCurrentSectionData={this.props.updateCurrentSectionData}
        setHouseholdMembers={this.props.setHouseholdMembers}
        setDataChangeStatus={this.props.setDataChangeStatus}
        snapRefreshQuestionsSections={this.props.snapRefreshQuestionsSections}
        householdData={this.props.interimReport}
        convertStringTODate={this.convertDOBForDataPost}
      />
    );
  };

  //Eraned income screens
  renderChildSupportExpenses = () => {
    return (
      <ChildSupportExpenses
        getSummaryTitle={this.getSummaryTitle}
        prepareChildSupportSummary={this.prepareChildSupportSummary}
        incomeSummary={this.incomeSummary}
        onChildSupportSubsectionRender={this.onChildSupportSubsectionRender}
        onSectionChange={this.onSectionChange}
        handleRemove={(event)=>this.handleRemove(event)}
        handleDone={this.handleDone}
      />
      );
  };

  renderEarnedIncomeSummary = () => {
    return (
      <EarnedIncome
        getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareEarnedIncomeSummary={this.prepareIncomeSummary}
        incomeSummary={this.incomeSummary}
        onEarnedIncomeSubsectionRender={this.onIncomeSubsectionRender}
        onSectionChange={this.onSectionChange}
      />
    );
  };

  renderUnearnedIncomeSummary = () => {
    return (
      <UnearnedIncome
        getIncomeSummaryTitle={this.getIncomeSummaryTitle}
        prepareUnearnedIncomeSummary={this.prepareUnearnedIncomeSummary}
        incomeSummary={this.incomeSummary}
        onUnearnedIncomeSubsectionRender={this.onUnearnedIncomeSubsectionRender}
        onSectionChange={this.onSectionChange}
      />
    );
  };

  renderSummary = () => {
    return(
      <SnapSixMonthReportContainer />
    );
  } 
  renderSubmitSummary = () => {
    return (
      <SubmitSummary
        agencyID={this.state.agencyID}
        referenceNumber={this.state.referenceNumber}
        docReferenceNumber={this.state.docReferenceNumber}
      />
    );
  };

  redirectToHomePage = () => {
    window.location.assign("/consumer/myInfo");
  };

  closeSaveExitModal = (status, event) => {
    // sessionStorage.setItem("saveExitShowModalEnabled", false);
    this.setState({
      saveExitShowModal: TextProps.VALUE_FALSE,
    });
    if (status) {
      this.redirectToHomePage();
    }
  };

  closeDeleteWarningModal = (status) => {
    if (status) {
      let {interimReport} = this.props;
      this.props.deleteInterimApplication(interimReport.interimId, interimReport.createdTime).then((data)=>{
        this.redirectToHomePage();
      })
    }
    this.setState({
      deleteWarningShowModal: TextProps.VALUE_FALSE,
    });
  };

  setContactInitialData = async() => {
    let addressArray = [];

    this.props.interimReport.responseObject.clientAddress.map((address) => {
      addressArray.push({
        typeCode: address.typeCode,
        line1: address.line1,
        line2:
          address.line2 && address.line2 !== undefined ? address.line2 : "",
        city: address.city,
        state: address.state,
        zip: address.zip,
        resAddrMatchFlag: address.resAddrMatchFlag,
        homelessFlag: address.homelessFlag,
      });

      return addressArray;
    });

    let {additionalProperties} = this.props.interimReport.responseObject;

    let initialObj = {
      email: this.props.interimReport.responseObject.clientEmail.emailAddress
        ? this.props.interimReport.responseObject.clientEmail.emailAddress
        : null,
      phoneNumber: this.props.interimReport.responseObject.clientPhone
        .phoneNumber
        ? this.props.interimReport.responseObject.clientPhone.phoneNumber
        : null,
      emailOptIn:
      this.props.interimReport.responseObject.clientEmail.optInFlag,
      textOptIn:
      this.props.interimReport.responseObject.clientPhone.textOptInFlag,
      editPhone: TextProps.VALUE_FALSE,
      editEmail: TextProps.VALUE_FALSE,
      editAddress: TextProps.VALUE_FALSE,
      sameMailingAddress:
      this.props.interimReport.responseObject.clientAddress
          .resAddrMatchFlag,
      contactInfoChanged: additionalProperties.contactChanged,
      addressList: {
        homeless:
        this.props.interimReport.responseObject.clientAddress[0]
            .homelessFlag,
        address: addressArray,
      },
      changeFlags: {
        emailCode: additionalProperties &&  additionalProperties.emailCode ? additionalProperties.emailCode :null,
        phoneCode : additionalProperties &&  additionalProperties.phoneCode ? additionalProperties.phoneCode :null,
        mailingAddressCode: additionalProperties &&  additionalProperties.mailingAddressCode ? additionalProperties.mailingAddressCode :null,
        resAddressCode: additionalProperties && additionalProperties.resAddressCode ? additionalProperties.resAddressCode :null,
        homelssFlagCode: additionalProperties && additionalProperties.homelssFlagCode ? additionalProperties.homelssFlagCode :null,
      },
    };
    this.props.setContactInfo(_.cloneDeep(initialObj));
    // delete this.props.contactInfoData.sections[1].subsections;
    // this.props.contactInfoData.sections[1].subsections = null;
    // this.props.onDataReset();
  };

  renderDeleteWarningModal = () => {
    return (
      <Modal
        isOpen={this.state.deleteWarningShowModal}
        onClose={() => this.closeDeleteWarningModal(TextProps.VALUE_FALSE)}
        modalClass="save-exit-modal"
        titleText="delete Warning"
        headerText={interimWarningPopupMLanguageLabels(language).confirmDelete}
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {interimWarningPopupMLanguageLabels(language).deleteWarningLabel}
          </p>
          <p className="text--small"> {interimWarningPopupMLanguageLabels(language).deleteExitLabel} </p>
          <p className="text--small">
            {interimWarningPopupMLanguageLabels(language).deletePopUpLabel}
          </p>
          <br/>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={() =>
                this.closeDeleteWarningModal(TextProps.VALUE_FALSE)
              }
            >
              {interimWarningPopupMLanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={() => this.closeDeleteWarningModal(TextProps.VALUE_TRUE)}
            >
              {interimWarningPopupMLanguageLabels(language).deleteButtonLabel}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  renderSaveExitWarningModal = () => {
    return (
      <Modal
        isOpen={this.state.saveExitShowModal}
        onClose={() => this.closeSaveExitModal(TextProps.VALUE_FALSE)}
        modalClass="save-exit-modal"
        titleText="Save progress"
        headerText={interimWarningPopupMLanguageLabels(language).savedSuccessfully}
      >
        <div className="dta-modal__body pad-all">
          <p className="text--small">
            {interimWarningPopupMLanguageLabels(language).saveExitLabel1}{" "}
            {todaysDateOrTime(TextProps.VALUE_TRUE, this.props.interimReport.updatedDate)}{" "}
            {interimWarningPopupMLanguageLabels(language).saveExitLabel2}{" "}
            {todaysDateOrTime(TextProps.VALUE_FALSE, this.props.interimReport.updatedDate)}{" "}
            {interimWarningPopupMLanguageLabels(language).saveExitLabel3}
            {this.props.interimReport.pendingHours
            ? this.props.interimReport.pendingHours.toString().split(":")[0] 
            : this.props.interimReport.pendingHours}
            {interimWarningPopupMLanguageLabels(language).saveExitLabel4}
          </p>
          <p> </p>
          <div className="dta-modal__footer--inline-buttons pure-u-1">
            <button
              className="dta-button dta-button--outline-primary"
              onClick={(event) =>
                this.closeSaveExitModal(TextProps.VALUE_FALSE, event)
              }
            >
              {interimWarningPopupMLanguageLabels(language).CancelButtonLabel}
            </button>
            <button
              className="dta-button dta-button--primary"
              onClick={(event) =>
                this.closeSaveExitModal(TextProps.VALUE_TRUE, event)
              }
            >
              {interimWarningPopupMLanguageLabels(language).okLabel}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

    //Function to handle Save or Exit popup actions(close/cancel)
    closeSaveOrExitWarningModal = (status, isClose, event, isExitClicked) => {
      this.setState({
        saveOrExitWarningShowModal: TextProps.VALUE_FALSE,
      });
      if (status) {
                this.onPrimaryActionClick(event, TextProps.SAVEEXIT,true);
                this.isShowSaveSuccessModal= false;
                this.setState({
                  sectionDataUpdated:false
                },()=>{
                  this.onSecondaryActionClick(event);
                })
        }
        if(isExitClicked){
        let exitModal = true;
          this.setState({contactInfoSummaryChanged: TextProps.VALUE_FALSE,
            sectionDataUpdated: TextProps.VALUE_FALSE,
          },()=>{
          this.onSecondaryActionClick(event,exitModal);
        })
      }
    };

    handleSearch  = (e) => {
      this.setState({
      searchTerm: e.target.value
    },() => {
      // Do an API call with this.state.searchTerm
    });
  }
    //Function to handle Save&Exit popup actions(save)
    onSaveOrExitClick = (event) => {
      this.setState({ saveOrExitWarningShowModal: TextProps.VALUE_TRUE });
      // alert("onDeleteClick");
    };

        //Render A DHR modal for Save or Exit updated info
        renderSaveOrExitWarningModal = () => {
          return (
            <Modal
              isOpen={this.state.saveOrExitWarningShowModal}
              onClose={() =>
                this.closeSaveOrExitWarningModal(TextProps.VALUE_FALSE, true)
              }
              modalClass="save-exit-modal"
              titleText="save or exit Warning"
              headerText={
                beforeYoustartMlanguageLabels(language).areYouSureWarningLabel
              }
            >
              <div className="dta-modal__body pad-all">
                <p className="text--small">
                  {beforeYoustartMlanguageLabels(language).saveOrExitLabel}
                </p>
                <p> </p>
                <div className="dta-modal__footer--inline-buttons pure-u-1">
                  <button
                    className="dta-button dta-button--outline-primary"
                    onClick={(event) => {
                      this.closeSaveOrExitWarningModal(
                        TextProps.VALUE_FALSE,
                        false,
                        event,
                        true
                      );
                      // console.log("Exit button clicked")
                    }
                    }
                  >
                    {beforeYoustartMlanguageLabels(language).CancelButtonLabel}
                  </button>
                  <button
                    className="dta-button dta-button--primary"
                    onClick={(event) =>
                      this.closeSaveOrExitWarningModal(
                        TextProps.VALUE_TRUE,
                        true,
                        event
                      )
                    }
                  >
                    {beforeYoustartMlanguageLabels(language).saveLabel}
                  </button>
                </div>
              </div>
            </Modal>
          );
        };

  updateSignature = (event) => {
    // take the data from the form and create an object
    this.setState({ signature: this.refs.applicanSign.inputEl.value }, () => {
      // if (this.state.signature.length < 1) {
      //   this.signatureError = TextProps.VALUE_TRUE;
      // } else {
      //   this.signatureError = TextProps.VALUE_FALSE;
      // }
    });
  };

  acceptTermsConditions = (event) => {
    this.setState({ rights: event.length }, () => {
      if (this.state.rights < 1) {
        this.setState({ agreeError: TextProps.VALUE_TRUE });
        this.agrreErrorMsg = interimReportPageLabels(
          language,
          languageConstants.beforeYouStart
        ).agreeTermsErrorMsg;
      } else {
        this.setState({ agreeError: TextProps.VALUE_FALSE });
        this.agrreErrorMsg = "";
      }
    });
  };

  openModal = () => {
    let url = InterimApplicationEN;
    switch (language.toLowerCase()) {
      case "en":
        url = InterimApplicationEN;
        break;
      case "es":
        url = InterimApplicationES;
        break;
      // case "pt":
      //   url = rights_pt_url;
      //   break;
      // case "vi":
      //   url = rights_vi_url;
      //   break;
      // case "zh":
      //   url = rights_zh_url;
        // break;
    }
    window.open(url, "_blank");
  };

  // Markup for the body of apply page when at "Sign and Submit" section
  renderSignAndSubmit = () => {
    const { sections } = this.props.interimReport;
    if (!this.props.interimReport.responseObject) return;
    const responseBody = _.cloneDeep(this.state.respData);
    let requestBody = _.cloneDeep(responseBody);
    //Get changes reported
    let reportedChanges = TextProps.VALUE_FALSE;
    if (this.props.interimReport.contactInfoScreen && this.props.interimReport.contactInfoScreen.contactInfoChanged) {
      //address change
      let respAddress = responseBody.clientAddress;
      let respMailAddress = null;
      let respResAddress = null;

      for (let value of respAddress) {
        if (value.typeCode === "RES") respResAddress = value;
        else if (value.typeCode === "MAIL") respMailAddress = value;
      }

      let updatedAddress =
        this.props.interimReport.contactInfoScreen.addressList.address;
      let updatedMailAddress = null;
      let updatedResAddress = null;

      for (let value of updatedAddress) {
        if (value.typeCode === "RES" && value.city && value.line1 && value.zip)
          updatedResAddress = value;
        else if (value.typeCode === "MAIL") updatedMailAddress = value;
      }

      let finalAddressArray = [];
      if (respResAddress !== null && updatedResAddress !== null) {
        if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "changed" ||
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "removed"
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
        //Homeless flag check
        if (
          this.props.interimReport.contactInfoScreen.addressList.homeless !==
          respResAddress.homelessFlag
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      } else if (respResAddress === null && updatedResAddress !== null) {
        reportedChanges = TextProps.VALUE_TRUE;
      }

      //Mail Addre check
      if (respMailAddress !== null && updatedMailAddress !== null) {
        if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "changed" ||
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "removed"
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      } else if (respMailAddress === null && updatedMailAddress !== null) {
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (respMailAddress !== null && updatedMailAddress === null) {
        if (finalAddressArray.length > 0) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }
      requestBody.clientAddress = finalAddressArray;

      //Email change
      let respEmail = responseBody.clientEmail;
      let updatedEmail = this.props.interimReport.contactInfoScreen;
      let emailCode = null;
      if (
        (!respEmail.emailAddress && updatedEmail.email) ||
        (respEmail.emailAddress && !updatedEmail.email) ||
        (respEmail.emailAddress &&
          (respEmail.emailAddress !== updatedEmail.email ||
            respEmail.optInFlag !== updatedEmail.emailOptIn))
      ) {
        reportedChanges = TextProps.VALUE_TRUE;
      }

      requestBody.clientEmail.dataChangeCode = emailCode;
      requestBody.clientEmail.emailAddress = updatedEmail.email;
      requestBody.clientEmail.optInFlag =
        updatedEmail.emailOptIn === "Y" ||
          updatedEmail.emailOptIn === TextProps.VALUE_TRUE
          ? TextProps.VALUE_TRUE
          : TextProps.VALUE_FALSE;

      //Phone change
      let respPhone = responseBody.clientPhone;
      let updatedPhone = this.props.interimReport.contactInfoScreen;
      let phoneNumber = updatedPhone.phoneNumber
        ? updatedPhone.phoneNumber.replace(/\D/g, "")
        : "";
      if (
        (!respPhone.phoneNumber && phoneNumber) ||
        (respPhone.phoneNumber && !phoneNumber) ||
        (respPhone.phoneNumber &&
          (respPhone.phoneNumber !== phoneNumber ||
            respPhone.textOptInFlag !== updatedPhone.textOptIn))
      ) {
        reportedChanges = TextProps.VALUE_TRUE;
      }
    }

    //HOUSEHOLD CHANGE
    if (this.props.interimReport.householdMembers.membersListUpdated) {
      let newHouseholdList = [];

      for (let value of this.props.interimReport.householdMembers.membersList) {
        if (
          value.allAnswers !== undefined ||
          (value.dataChangeCode && value.dataChangeCode === "removed")
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }
      requestBody.householdMembers = newHouseholdList;
    }

    //EARNED INCOME CHANGE
    if (
      this.props.interimReport.sections.length > 4 &&
      this.props.interimReport.earnedIncomeChanged &&
      this.props.interimReport.earnedIncomeChanged === TextProps.VALUE_TRUE
    ) {
      let allIncome = _.cloneDeep(this.incomeSummary.incomeMembers);
      let incomeArrayExisting = _.cloneDeep(
        this.props.interimReport.responseObject.earnedIncome
      );

      let incomeArrayNew = [];
      allIncome.map((member) => {
        let memberObj = this.getMemberDataObjectByNameAndDOB(
          member.firstName,
          incomeArrayExisting
        );
        if (memberObj === null) {
          memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            this.props.interimReport.householdMembers.membersList
          );
        }
        if (member.incomes !== undefined) {
          member.incomes.map((income) => {
            let typeCode = null;
            if (
              income !== undefined &&
              income.Answer !== undefined &&
              income.Answer.length > 0
            ) {
              income.Answer.map((incomeSub) => {
                let amountArray = [];
                let amountIterator = 1;
                if (incomeSub.amount) {
                  if (incomeSub.unit === "Weekly") {
                    amountIterator = 4;
                  } else if (incomeSub.unit === "Biweekly (every two weeks)") {
                    amountIterator = 2;
                  }

                  for (let i = 0; i < amountIterator; i++) {
                    amountArray.push({
                      amount: incomeSub.amount[i],
                    });
                  }
                }
                let changeCode = this.returnDataChangeCode(incomeSub.code)
                if (changeCode !== "NOCHNG")
                  reportedChanges = TextProps.VALUE_TRUE;

                typeCode = income.typeCode;

                let incomeObj = {
                  id: this.returnMemberId(member, incomeSub),
                  setID:
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.setID
                      ? incomeSub.fullIncomeObj.setID
                      : 0,
                  agencyID: this.returnAgencyID(memberObj, incomeSub),
                  firstName:
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.firstName
                      ? incomeSub.fullIncomeObj.firstName
                      : memberObj.firstName,
                  lastName:
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.lastName
                      ? incomeSub.fullIncomeObj.lastName
                      : memberObj.lastName,
                  dateOfBirth: this.convertDOBForDataPost(
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.dateOfBirth
                      ? incomeSub.fullIncomeObj.dateOfBirth
                      : memberObj.dateOfBirth
                  ),
                  typeCode: typeCode,
                  employerName: incomeSub.employer,
                  frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                  amounts: amountArray,
                  dataChangeCode: changeCode,
                };
                incomeArrayNew.push(incomeObj);
              }); // income sub ends
            }
          }); // income ends
        }
      }); // member ends

      for (let existing of incomeArrayExisting) {
        existing.dateOfBirth = this.convertDOBForDataPost(existing.dateOfBirth);

        let found = TextProps.VALUE_FALSE;
        for (let newIncome of incomeArrayNew) {
          if (
            newIncome.id === existing.id &&
            newIncome.agencyID === existing.agencyID &&
            newIncome.typeCode === existing.typeCode
          ) {
            found = TextProps.VALUE_TRUE;
          }
        }
        if (found === TextProps.VALUE_FALSE) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }
    }

    //UNEARNED INCOME CHANGE
    if (
      this.props.interimReport.sections.length > 4 &&
      this.props.interimReport.unearnedIncomeChanged !== undefined &&
      this.props.interimReport.unearnedIncomeChanged === TextProps.VALUE_TRUE
    ) {
      let allIncome = _.cloneDeep(this.incomeSummary.unearnedIncomeMembers);
      let incomeArrayExisting = _.cloneDeep(
        this.props.interimReport.responseObject.unearnedIncome
      );

      let incomeArrayNew = [];
      allIncome.map((member) => {
        let memberObj = this.getMemberDataObjectByNameAndDOB(
          member.firstName,
          incomeArrayExisting
        );
        if (memberObj === null) {
          memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            this.props.interimReport.householdMembers.membersList
          );
        }
        if (member.incomes !== undefined) {
          member.incomes.map((income, index) => {
            let typeCode = null;
            if (
              income !== undefined &&
              income.Answer !== undefined &&
              income.Answer.length > 0
            ) {
              income.Answer.map((incomeSub) => {
                let amountArray = [];
                let amountIterator = 1;
                if (incomeSub.amount) {
                  if (incomeSub.unit === "Weekly") {
                    amountIterator = 4;
                  } else if (incomeSub.unit === "Biweekly (every two weeks)") {
                    amountIterator = 2;
                  }
                  for (let i = 0; i < amountIterator; i++) {
                    amountArray.push({
                      amount: incomeSub.amount[i],
                    });
                  }
                }

                let changeCode = this.returnDataChangeCode(incomeSub.code);

                if (changeCode !== "NOCHNG")
                  reportedChanges = TextProps.VALUE_TRUE;

                typeCode = income.typeCode;

                let incomeObj = {
                  id: this.returnMemberId(member, incomeSub),
                  setID:
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.setID
                      ? incomeSub.fullIncomeObj.setID
                      : 0,
                  agencyID: this.returnAgencyID(memberObj, incomeSub),
                  firstName:
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.firstName
                      ? incomeSub.fullIncomeObj.firstName
                      : memberObj.firstName,
                  lastName:
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.lastName
                      ? incomeSub.fullIncomeObj.lastName
                      : memberObj.lastName,
                  dateOfBirth: this.convertDOBForDataPost(
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.dateOfBirth
                      ? incomeSub.fullIncomeObj.dateOfBirth
                      : memberObj.dateOfBirth
                  ),
                  typeCode: typeCode,
                  frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                  amounts: amountArray,
                  dataChangeCode: changeCode,
                };
                incomeArrayNew.push(incomeObj);
              }); // income sub ends
            }
          }); // income ends
        }
      }); // member ends

      for (let existing of incomeArrayExisting) {
        existing.dateOfBirth = this.convertDOBForDataPost(existing.dateOfBirth);
        let found = TextProps.VALUE_FALSE;
        for (let newIncome of incomeArrayNew) {
          if (
            newIncome.id === existing.id &&
            newIncome.agencyID === existing.agencyID &&
            newIncome.setID === existing.setID
          ) {
            found = TextProps.VALUE_TRUE;
          }
        }
        if (found === TextProps.VALUE_FALSE) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }
    }

    //CHILE EXP CHANGE
    if (this.props.interimReport.obligationChildSupportChanged!== undefined && this.props.interimReport.obligationChildSupportChanged===TextProps.VALUE_TRUE ){
      let childExpNew = _.cloneDeep(
        this.props.interimReport.childExpScreen.tempChildExpArray
      );

      console.log("INCOME SUMMMARY", this.incomeSummary)

      if (childExpNew.length > 0) {
        childExpNew.map((member) => {
          if (
            member.dataChangeCode !== undefined &&
            (member.dataChangeCode === "new" ||
              member.dataChangeCode === "changed" ||
              member.dataChangeCode === "removed")
          )
            reportedChanges = TextProps.VALUE_TRUE;
        }); // member ends
      }
    }

    //Other information change
    if (
      this.props.interimReport.sections.length > 4 &&
      this.props.interimReport.sections[6].questions !== undefined &&
      this.props.interimReport.sections[6].questions.length > 0
    ) {
      this.props.interimReport.sections[6].questions.map((question, index) => {
        if (
          question.type === "radio" &&
          question.Answer === TextProps.VALUE_YES
        ) {
          reportedChanges = TextProps.VALUE_TRUE;
        }
      });
    }

    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {/* Clicking 'View your Application Summary' button will show Html page with all pages summary */}
        {modalHelperFunction.dtaHelperModal(
          null,
          this.state.viewApplicationDataPopupOpen,
          this.closeModal,
          "save-progress-modal summary-progress-modal",
          interimReportPageLabels(language, languageConstants.beforeYouStart)
            .applicationSummaryTitle,
          null,
          TextProps.VALUE_FALSE,
          TextProps.VALUE_FALSE,
          interimReportPageLabels(language, languageConstants.beforeYouStart)
            .applicationSummaryHeader,
          <div className="dta-modal__body pad-all">
            {getMainSections(sections, this.state.viewApplicationDataPopupOpen)}
          </div>
        )}
        {/* Clicking 'View your Application Summary' button will download PDF summary */}
        <b>
          {
            interimReportPageLabels(
              language,
              languageConstants.signAndSubmitSummary
            ).rightsText
          }
          &nbsp;
          <button
            className="dta-button dta-button--link"
            onClick={this.openModal}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).viewApplicationLinkLabel
            }
          </button>
        </b>
        <form id={this.state.submitFormId}>
           <br/>
          <div>
            <strong>{interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).signatureandPenalty}</strong>
            <br />

            <p>{interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).whenYourHouseholdReceivesSnap}</p>
            <ul>
              <li>
                {/* The Food and Nutrition Act of 2008 (7 U.S.C. 2011-2036) allows DHR to use my Social
          Security Number (SSN) and the SSN of each household member I apply for. DHR uses this
          information to determine my household’s eligibility for SNAP. DHR verifies this
          information through computer matching programs. I understand that DHR uses it to monitor
          compliance with program regulations. */}{
                  interimReportPageLabels(
                    language,
                    languageConstants.beforeYouStart
                  ).hideInformation
                }
              </li>
              <li>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).tradeOrSell}
              </li>
              <li>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).alterEbt}
              </li>
              <li>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).alcoholicDrinksOrTobacco}
              </li>
              <li>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).someoneElseSnapOrEbt}
              </li>
            </ul>
            <strong>
              {interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).federalLaws}
            </strong>
            <p>{interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).iUnderstandThePenalty}</p>
            <p>{interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).iCertifyThatTheStatement}</p>
            <br />
          </div>
          {reportedChanges
            ? interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).didReportChanges
            : interimReportPageLabels(
              language,
              languageConstants.beforeYouStart
            ).didReportChanges}
          <br />
          <br />
          <div>
            {this.state.agreeError ? (
              <b>
                <p className="error-text-clr">{this.agrreErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={[
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).iagreeTermsLabel,
            ]}
            ref="isAcceptTerms"
            onChange={this.acceptTermsConditions}
            required={TextProps.VALUE_TRUE}
            error={this.state.agreeError}
          />
          <hr className="divider" />
          <label className="dta-form__label" htmlFor="sign-app">
            <p >
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).signYourAppllication
              }
            </p>
          </label>
          {/* Clicking will submit the application and route user to follow up page */}
          <div>
            {this.state.signatureError ? (
              <b>
                <p className="error-text-clr">{this.signatureErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <DTAInputSubmit
            className="dta-form__control"
            type="specialTextType"
            id="sign-app"
            maxLength={60}
            ref="applicanSign"
            onChange={this.updateSignature}
            required={TextProps.VALUE_TRUE}
            error={this.state.signatureError}
          />
          <strong>
            {
              /*  Nondiscrimination Statement    */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).nonDiscriminationHeader
            }
          </strong>
          <p>
            {
              /*  In accordance with Federal civil rights law and U.S. Department of Agriculture (USDA) civil
            rights regulations and policies, the USDA, its Agencies, offices, and employees, and
            institutions participating in or administering USDA programs are prohibited from
            discriminating based on race, color, national origin, sex, religious creed, disability, age,
            political beliefs, or reprisal or retaliation for prior civil rights activity in any program
            or activity conducted or funded by USDA. */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).nonDiscriminationContent
            }
          </p>
          <p>
            {
              /*         Persons with disabilities who require alternative means of communication for program
            information (e.g. Braille, large print, audiotape, American Sign Language, etc.), should
            contact the Agency (State or local) where they applied for benefits. Individuals who are
            deaf, hard of hearing or have speech disabilities may contact USDA through the Federal Relay
            Service at */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).personDisabilities
            }
            <a href="tel:(800) 877-8339">(800) 877-8339</a>.
            {
              /*  Additionally, program
            information may be made available in languages other than English. */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).programInformation
            }
          </p>
          <p>
            {
              /*    To file a program complaint of discrimination, complete the USDA Program Discrimination
            Complaint Form, (AD-3027) found online at: */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).programComplaint
            }

            <a
              href=" https://w.w.w.usda.gov/sites/default/fles/documents/ad-3027.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="dta-link--wrap"
            >
              {`${appConfigConstants.programComplainLink},`}
            </a>
            {
              /* and at any USDA office, or write a letter addressed to USDA and provide in the letter
            all of the information requested in the form. To request a copy of the complaint form, call */
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).usdaOffice
            }
          </p>
          <ol>
            <li className="pad-vertical">
              <strong>{
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).mail}
              </strong>
              <br/>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).mailAddressArray[0]}

              <br />
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).mailAddressArray[1]}
                 <br />
                 {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).mailAddressArray[2]}
                 <br />
            </li>
            <li className="pad-vertical">
              <strong>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).fax}
              </strong>
              <br/>
              {"(833) 256-1665 or (202) 690-7442;"}
              {interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).orLabel}
            </li>
            <li>
              <strong>
                {interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).eamilTitle}
              </strong>
              <br/>
              <a href="FNSCIVILRIGHTSCOMPLAINTS@usda.gov">FNSCIVILRIGHTSCOMPLAINTS@usda.gov</a>.
            </li>
          </ol>
          <p>
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).thisInstution
            }
          </p>
        </form>
      </div>
    );
  };

  // Markup for the body of apply page when at "Sign and Submit" section
  renderSignAndSubmitPartial = () => {
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        <p>
          <b>
            {
              interimReportPageLabels(
                language,
                languageConstants.signAndSubmitSummary
              ).rightsHHRemovedText
            }
          </b>
          <br />
        </p>
        <form id={this.state.submitFormId}>
          <div>
            {this.state.agreeError ? (
              <b>
                <p style={{ color: "red" }}>{this.agrreErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <Checkbox
            className="dta-form__option-list"
            optionClass="dta-form__option"
            aria-labelledby="submit-1"
            options={[
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).iagreeTermsLabel,
            ]}
            ref="isAcceptTerms"
            onChange={this.acceptTermsConditions}
            required={TextProps.VALUE_TRUE}
            error={this.state.agreeError}
          />
          <hr className="divider" />
          <label className="dta-form__label" htmlFor="sign-app">
            <h3>
              {
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).signYourAppllication
              }
            </h3>
          </label>
          {/* Clicking will submit the application and route user to follow up page */}
          <div>
            {this.state.signatureError ? (
              <b>
                <p className="error-text-clr">{this.signatureErrorMsg}</p>
              </b>
            ) : (
              <p></p>
            )}
          </div>
          <DTAInputSubmit
            className="dta-form__control"
            type="specialTextType"
            id="sign-app"
            maxLength={60}
            ref="applicanSign"
            onChange={this.updateSignature}
            required={TextProps.VALUE_TRUE}
            error={this.state.signatureError}
          />
        </form>
      </div>
    );
  };

  // Markup for the body of the final section of the app, after submission
  renderSubmitted = () => {
    return null;
  };

  // download pdf files link
  downloadApplicationAsPdf() {
    window.open(
      "https://dta-connect-service.azurewebsites.net/snapapplication.pdf",
      "_blank"
    );
  }

  _bindAnswerObject = (answer) => {
    this.setState(
      {
        AnswerObject: answer,
        sectionDataUpdated: TextProps.VALUE_TRUE,
        addressValidationModalOpen: TextProps.VALUE_FALSE,
      },
      () => {
        if (
          answer !== undefined &&
          answer.accommodation !== undefined &&
          answer.accommodation.accommodationApplies !== undefined
        ) {
          this.setAccomadtion(answer);
        }
        if (
          answer !== undefined &&
          answer["housing-costs"] !== undefined &&
          answer["housing-costs"].type !== undefined
        ) {
          this.setShelterData(answer);
        }
      }
    );
    if (this.state.activeSectionId === "add-contact-info") {
      let contactAnswer = answer["add-contact-info"];
      if (
        contactAnswer.mailingAddress !== undefined &&
        contactAnswer["is-also-mailing"] === undefined
      ) {
        contactAnswer["is-also-mailing"] =
          this.props.interimReport.contactInfoScreen.addressList.address[0]
            .resAddrMatchFlag &&
            this.props.interimReport.contactInfoScreen.addressList.homeless !==
            TextProps.VALUE_TRUE
            ? TextProps.VALUE_STR_TRUE
            : TextProps.VALUE_STR_FALSE;
      }
      if (
        contactAnswer.mailingAddress === undefined &&
        contactAnswer["is-also-mailing"] !== undefined &&
        contactAnswer["is-also-mailing"] === TextProps.VALUE_STR_FALSE
      ) {
        let addressList =
          this.props.interimReport.contactInfoScreen.addressList;
        let mailingAddress = null;
        addressList.address.map((addr) => {
          if (addr.typeCode === "MAIL") mailingAddress = addr;
        });
        contactAnswer.mailingAddress = mailingAddress
          ? {
            street: mailingAddress.line1 + " " + mailingAddress.line2,
            zipCode: mailingAddress.zip,
            state: mailingAddress.state,
            city: mailingAddress.city,
          }
          : {
            street: "",
            zipCode: "",
            state: "",
            city: "",
          };
      }
      answer["add-contact-info"] = contactAnswer;
    }
    if(this.state.activeSectionId.includes('child-support-amount')){
      let filtered;
      const childArr = _.cloneDeep(this.props.interimReport.childExpScreen && this.props.interimReport.childExpScreen.tempChildExpArray ? this.props.interimReport.childExpScreen.tempChildExpArray :[]);
      this.props.interimReport.sections[5].subsections.forEach((sec)=>{
        if(sec.id === this.state.activeSectionId){
          if(sec && sec.clientId){
            filtered = _.findIndex(childArr, {
              firstName: sec.clientId.split(" ")[0],
              lastName: sec.clientId.split(" ")[1],
              dateOfBirth: " "+sec.clientId.split(" ")[2],
            });
          }else{
            filtered = _.findIndex(childArr, {
              firstName: sec.title.split(" ")[0],
              lastName: sec.title.split(" ")[1],
              dateOfBirth: " "+sec.title.split(" ")[2],
            });
          }
          if (answer[this.state.activeSectionId]) {
            if (childArr && childArr[filtered]) {
            if (answer[this.state.activeSectionId]['child-support-cost-1'].amount) {
              childArr[filtered].amount = Number(answer[this.state.activeSectionId]['child-support-cost-1'].amount);
              childArr[filtered].originalAmount = Number(answer[this.state.activeSectionId]['child-support-cost-1'].amount);
            }
            if (answer[this.state.activeSectionId]['child-support-cost-1'].unit) {
              childArr[filtered].frequency = answer[this.state.activeSectionId]['child-support-cost-1'].unit;
              childArr[filtered].originalFrequency = answer[this.state.activeSectionId]['child-support-cost-1'].unit;
            }
              if (childArr[filtered].id !== 0) {
              childArr[filtered].dataChangeCode = "changed";
            } else {
              childArr[filtered].dataChangeCode = "new";
            }
          }
        }
        }
      })
      let newChildArr = childArr.filter((oneChild) => {
        // return oneChild.id >= 0 && oneChild.dataChangeCode !== null
        return oneChild.id >= 0 
      })
      newChildArr = _.uniqBy(newChildArr,(q)=>q.firstName+" "+q.firstName+q.dateOfBirth);
      this.props.appendChildSupportAnswer(newChildArr)
    }
    this.setState({
      AnswerObject: answer,
      sectionDataUpdated: TextProps.VALUE_TRUE,
    });
  };

  //Reset contact info answer state object when user selects "No" to change question and tries to edit address again
  resetContactState = () => {
    if (this.props.interimReport.contactInfoScreen !== undefined) {
      let contactAnswer = this.state.AnswerObject["add-contact-info"];
      if (contactAnswer) {
        let contactObj = this.props.interimReport.contactInfoScreen.addressList;
        contactAnswer["is-also-mailing"] =
          contactObj.address[0].resAddrMatchFlag &&
            contactObj.homeless !== TextProps.VALUE_TRUE
            ? TextProps.VALUE_STR_TRUE
            : TextProps.VALUE_STR_FALSE;
        let mailAddressObj = null;
        if (contactObj.address[0].typeCode === "MAIL") {
          mailAddressObj = contactObj.address[0];
        } else if (
          contactObj.address[1] &&
          contactObj.address[1].typeCode === "MAIL"
        ) {
          mailAddressObj = contactObj.address[1];
        }
        contactAnswer.mailingAddress = mailAddressObj
          ? {
            street: mailAddressObj.line1 + " " + mailAddressObj.line2,
            zipCode: mailAddressObj.zip,
            state: mailAddressObj.state,
            city: mailAddressObj.city,
          }
          : {
            street: "",
            zipCode: "",
            state: "",
            city: "",
          };
        this.setState({
          AnswerObject: contactAnswer,
        });
      }
    }
  };
  // apply implementation for rendering apply section pages, particularly
  // pertaining to transitions.
  // This method is open to interpretation or modification, especially to aid
  // in optimization.
  renderSection = (section) => {
    return (
      <CSSTransition
        key={section.id}
        in={section.id === this.state.activeSectionId}
        classNames="slide-animation"
        timeout={500} // Should be roughly the same as CSS transition time
        onExited={(node) => {
          this.removeSection(section.id);
        }}
      >
        <div className={`slide-animation ${this.state.directionClass}`}>
          {/*
              apply component for apply section pages. Could be adapted to
              provide a more robust and optimized solution. No matter the implemenation
              for rendering sections, you will need to use the markup from this,
              component. You can also consult this component for examples of how to
              resolve and render questions from JSON.
          */}
          <InterimSection
            key={section.id}
            section={section}
            primaryActionLabel={this.getPrimaryActionLabel(section.id)}
            onPrimaryActionClick={this.onPrimaryActionClick}
            onSaveExitClick={this.onSaveExitClick}
            // onPrimaryActionClick={this.onPrimaryActionClickSaveContinue}
            onSecondaryActionClick={this.onSecondaryActionClick}
            redirectSign={this.redirectToSignAndSubmit}
            bindAnswerToState={this._bindAnswerObject}
            answerObject={this.state.AnswerObject}
            onWarningProgress={this.presentWarningProgressModal}
          >
           {!section.questions ? this.getSectionBody(section.id) : null}
          </InterimSection>
        </div>
      </CSSTransition>
    );
  };

  // redirectToSignAndSubmit intend to develop while clicking  - Submit your application now
  // here sending activeSectionId is 'sign-and-submit' link will call only no other section
  redirectToSignAndSubmit = (section, event = null) => {
    //start of google analytics related code added in client side for  partial submit
    visitor.pageview({
      dp: googleAnalyticsConstVar.DP_PARTIAL_APPLY,
      dt: googleAnalyticsConstVar.DT_PARTIAL_APPLY,
      dh: googleAnalyticsConstVar.URL_PATH + googleAnalyticsConstVar.SNAP_APPLY,
    });
    visitor
      .event(
        googleAnalyticsConstVar.DT_PARTIAL_APPLY,
        "submitApplicationPartially",
        googleAnalyticsConstVar.EVENT_LABEL,
        42
      )
      .send();
    //end of google analytics related code added in client side for  partial submit

    if (event) {
      event.preventDefault();
    }
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);

    const incomingSection = this._getSection("sign-and-submit");
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: "sign-and-submit",
        });
      }
    );
  };

  gotoIncomeSummary = () => {
    this.prepareIncomeSummary();
    this.setState({ incomeSummaryEdit: TextProps.VALUE_FALSE }, () => {
      this.onSectionChange(this.state.incomeSummaryTemplate);
    });
  };

  gotoUnearnedIncomeSummary = () => {
    // this.prepareUnearnedIncomeSummary();
    this.setState({ incomeUnearnedSummaryEdit: TextProps.VALUE_FALSE }, () => {
      this.onSectionChange(this.state.unearnedIncomeSummaryTemplate);
    });
  };

  gotoChildSupportSummary = (activeId) => {
    // this.prepareChildSupportSummary();
    this._updateCurrentSection(activeId);
    this.onSectionChange(this.state.childSupportSummaryTemplate);
  };

  getIncomeSummaryTitle = (name, before, after) => {
    switch (language) {
      case types.ENGLISH:
      case types.CHINESE:
        return {
          before: name + before,
          after: after,
        };
      case types.SPANISH:
      case types.PORTUGUESE:
      case types.VIETNAMESE:
      case HI:
        return {
          before: before,
          after: " " + name,
        };
    }
  };

  getSummaryTitle = (name, before, after) => {
    switch (language) {
      case types.ENGLISH:
      case types.CHINESE:
        return {
          before: name + before,
          after: after,
        };
      case types.SPANISH:
      case types.PORTUGUESE:
      case types.VIETNAMESE:
      case HI:
        return {
          before: name + before,
          after: " " + name,
        };
    }
  };

 

  // A very simple switch statement, which returns the appropriate
  // markup for the body of the apply page.
  // Meant strictly for apply display purposes.
  getSectionBody = (sectionId) => {
    switch (sectionId) {
      case "before-you-start":
      return this.renderBeforeYouStart();
      case "contact-info":
        return this.renderContactInfo();
      case "my-household":
        return this.renderHouseholdInfo();
      case "income-summary":
        return this.renderEarnedIncomeSummary();
      case "unearned-income-summary":
        return this.renderUnearnedIncomeSummary();
      case "childsupport-expenses":
        this.shelterSubsectionRender();
        return this.renderChildSupportExpenses();
      case "other-information":
        return this.renderOtherInformation();
      case "shelter-utility-summary":
        return this.renderShelterAndUtility();
      case "summary": 
        return this.renderSummary();  
      case "sign-and-submit":
        // if (this.props.interimReport.sections.length > 4)
          return this.renderSignAndSubmit();
        // else return this.renderSignAndSubmitPartial();
      case "submitted":
        return this.renderSubmitSummary();
      default:
        return <div>Something went wrong</div>;
    }
  };

  onDeleteClick(event){
    event.preventDefault();
    this.setState({ deleteWarningShowModal: TextProps.VALUE_TRUE });
  }

  renderApplyQuestions(questionsSections) {
    if (questionsSections !== undefined) {
      this.updateDimensions();
      const isFreeComOpen = sessionStorage.getItem("openFreeComm");
      const { common } = this.props.language.security;
      var userInfo;
      var loginUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        userInfo = loginUserInfo;
      } else {
        if (
          this.props.consumer.mfaDetails.userDetails !== undefined &&
          this.props.consumer.mfaDetails.userDetails !== null
        ) {
          userInfo = this.props.consumer.mfaDetails.userDetails;
        }
      }
      var loginFlag = TextProps.VALUE_FALSE;
      if (this.props.consumer.loginSuccessful === TextProps.VALUE_TRUE) {
        loginFlag = TextProps.VALUE_TRUE;
      } else {
        if (loginUserInfo !== null && loginUserInfo !== undefined) {
          if (loginUserInfo.firstName !== "") {
            loginFlag = TextProps.VALUE_TRUE;
          }
        }
      }
      var userObject = JSON.parse(sessionStorage.getItem("userInfo"));
      var userRole = userObject.roleList[1]
        ? userObject.roleList[1].roleName
        : userObject.roleList[0].roleName;
      const msalInstance = this.props.msalContext.instance;
      if (isFreeComOpen === "true") {
      return (
        <div style={{ height: "100%" }}>
        <div className="full-height apply ir-container" style={{ overflowY: "unset" }}>
        <div style={{ display: "contents" }}>
          <DocumentTitle
            title={
              this.props &&
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.title
                ? this.props.location.state.title
                : "Interim Report"
            }
          />
          {/* See applyNavigation.js for documentation on ApplyNavigation props */}
          <SidebarNavigation
            sections={questionsSections}
            activeSectionId={this.state.activeSectionId}
            numSectionsRemaining={4}
            onSectionClick={this.onSectionChange}
            skipToAnchor="#content"
            onDeleteClick={this.onDeleteClick}
          />
          <main
            className="apply__content-container pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24"
            id="content"
            tabIndex="-1"
            style={{ overflowY: "hidden" }}
          >
            {this.state.renderedSections.map((section) =>{
              return this.renderSection(section)
            }
            )}
            {loginFlag === TextProps.VALUE_TRUE ? (
              <div className="interim-recert__options">
                <LanguageSelect className="interim-recert__language-select" />
                {userRole !== roleProps.PROVIDER &&
                  userRole !== roleProps.PROVIDER_ROLE_SPVUSR &&
                  userRole !== roleProps.PROVIDER_ROLE_DCPVSG ? (
                  <div className="interim-recert__options-account">
                    <span className="interim-recert__headertext">
                      {common.hi}
                    </span>
                    &nbsp;
                    <DTADropdown
                      buttonText={userInfo.firstName}
                      buttonClass="dta-button dta-button--link"
                      menuClass="list--bordered"
                      caret={TextProps.VALUE_TRUE}
                      caretClass="color-primary"
                    >
                      <li className="pad-all--half">
                        <Link
                          to={routeProps.CONSUMER_MY_INFO_PATH}
                          className="display-block"
                          onClick={this._handleApplyGoBack.bind(this)}
                        >
                          {common.home}
                        </Link>
                      </li>
                      <li className="pad-all--half border-top">
                        <React.Fragment>
                          {config.azureLoginFlag ? (
                            <Link
                              to="#"
                              className="display-block"
                              onClick={() =>
                                this.props.onAzureLogOut(msalInstance)
                              }
                            >
                              {common.logout}
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              className="display-block"
                              onClick={this._handleApplyLogoutSession.bind(
                                this
                              )}
                            >
                              {common.logout}
                            </Link>
                          )}
                        </React.Fragment>
                      </li>
                    </DTADropdown>
                  </div>
                ) : (
                  <div className="interim-recert__options-account">
                    <div className="provider-spacer" />
                    <button
                      className="dta-button provider-return"
                      onClick={this.gotoProvier}
                    >
                      <span className="provider-return__text">
                        {common.provider}
                        <br />
                        <Icon name="home" />
                      </span>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <LanguageSelect className="language-select--dark" />
            )}
          </main>
          {this.renderWarningPopup()}
          {this.renderAddressValidationModal()}
          {this.renderSaveExitWarningModal()}
          {this.renderDeleteWarningModal()}
          {this.renderSaveOrExitWarningModal()}
          {this.renderACESButtonClickWarningModal()}
        {this.renderlanguageSwitchWarningModal()}
        </div>
        </div>
        </div>
      );
            }
    }
  }

  gotoProvier = () => {
    this.props.gotoProvider();
  };
  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

    _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj && sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj && sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  downloadIRPDFData() {
    this.props.downloadIRPDFData(this.props.interimReport.sections);
  }

  convertDOBForDataPost = (dobFrom) => {
    let retrunValue = null;
    if (dobFrom.indexOf("/") !== -1) {
      let dob = dobFrom.replace("(", "").replace(")", "").replace(" ", "");
      let dobArray = dob.split("/");

      let month = dobArray[0];
      // if (month < 10) {
      //   month = "0" + month;
      // }
      let date = dobArray[1];
      // if (date < 10) {
      //   date = "0" + date;
      // }
      retrunValue = dobArray[2] + "-" + month + "-" + date;
    } else {
      retrunValue = dobFrom;
    }

    return retrunValue;
  };

  onSaveExitClick = (e) => {
    e.preventDefault();
    this.onPrimaryActionClick(e,TextProps.SAVEEXIT);
  };

  saveAndContinue =  (activeId,actionButtonType,datasection) => {
    let dataChange =  this.checkForDataChange(this.state.activeSectionId);
    //If the user has not changed any info then simply return TextProps.VALUE_FALSE to stay on the same section
    if (dataChange === TextProps.VALUE_FALSE) {
      this.forceUpdate();
      return;
    } else if(actionButtonType === TextProps.SAVEEXIT){
       this.prepareDataForSubmission(activeId, TextProps.VALUE_TRUE,TextProps.VALUE_TRUE,datasection);
    }else{
      this.prepareDataForSubmission(activeId, TextProps.VALUE_TRUE,TextProps.VALUE_FALSE,datasection);
    }
  };

  getSummaryId = (id) => {
    let activeId = "";
    if(id === "add-contact-info"){
      activeId = "contact-info";
    }else if(id === "household-member"){
      activeId = "my-household";
    }else{
      activeId = id;
    }

    return activeId
  }

  prepareDataForSubmission = (activeId,saveAndContinue,saveAndExit,datasection=null) => {
    let reportedChanges = TextProps.VALUE_FALSE;
    let requestBody = null;
    let {additionalProperties} = this.props.interimReport.responseObject;
    let newEarnedIncomes = [];
    let newUnearnedIncomes = [];
    const responseBody = _.cloneDeep(this.state.respData);
    requestBody = _.cloneDeep(responseBody);
    requestBody.additionalProperties = {
      activeId:this.getSummaryId(activeId),
      emailCode: additionalProperties && additionalProperties.emailCode && additionalProperties.emailCode ? additionalProperties.emailCode :null,
      phoneCode: additionalProperties && additionalProperties.phoneCode && additionalProperties.phoneCode ? additionalProperties.phoneCode :null,
      mailingAddressCode: additionalProperties && additionalProperties.mailingAddressCode &&  additionalProperties.mailingAddressCode ? additionalProperties.mailingAddressCode :null,
      resAddressCode: additionalProperties && additionalProperties.resAddressCode && additionalProperties.resAddressCode ? additionalProperties.resAddressCode :null,
      homelssFlagCode: additionalProperties && additionalProperties.homelssFlagCode && additionalProperties.homelssFlagCode ? additionalProperties.homelssFlagCode :null,
      contactChanged: this.props.interimReport.contactInfoScreen.contactInfoChanged,
      // houseHoldChanged: additionalProperties && additionalProperties.houseHoldChanged || this.props.interimReport.householdMembers && this.props.interimReport.householdMembers.membersListUpdated === true ? true : false,
      earnedIncomeChanged: this.props.interimReport.earnedIncomeChanged,
      grossEarnedIncomeChanged:this.props.interimReport.grossEarnedIncomeChanged,
      unearnedIncomeChanged: this.props.interimReport.unearnedIncomeChanged,
      obligationChildSupportChanged: this.props.interimReport.obligationChildSupportChanged,
      courtOrderedChildSupportChanged: this.props.interimReport.courtOrderedChildSupportChanged,
      shelterChanged: additionalProperties && additionalProperties.shelterChanged || this.props.interimReport.shelterUtilityConfirmationChanged
    }
    if(saveAndContinue){
      requestBody.status = "DRAFT"
    }
      requestBody.interimID = this.props.interimReport.interimId ? this.props.interimReport.interimId :0
      requestBody.createdTime = this.props.interimReport.createdTime

    if (this.props.interimReport.contactInfoScreen && this.props.interimReport.contactInfoScreen.contactInfoChanged) {
      //address change
      let respAddress = responseBody.clientAddress;
      let respMailAddress = null;
      let respResAddress = null;

      for (let address of respAddress) {
        if (address.typeCode === "RES") respResAddress = address;
        else if (address.typeCode === "MAIL") respMailAddress = address;
      }

      let updatedAddress =
        this.props.interimReport.contactInfoScreen.addressList.address;
      let updatedMailAddress = null;
      let updatedResAddress = null;

      for (let newAddress of updatedAddress) {
        if (
          newAddress.typeCode === "RES" &&
          newAddress.city &&
          newAddress.line1 &&
          newAddress.zip
        )
          updatedResAddress = newAddress;
        else if (newAddress.typeCode === "MAIL")
          updatedMailAddress = newAddress;
      }

      let finalAddressArray = [];
      if (respResAddress !== null && updatedResAddress !== null) {
        let newAddress = {};
        newAddress.line1 = updatedResAddress.line1;
        newAddress.line2 = updatedResAddress.line2;
        newAddress.city = updatedResAddress.city;
        newAddress.state = updatedResAddress.state;
        newAddress.zip = updatedResAddress.zip;
        newAddress.homelessFlag =
          this.props.interimReport.contactInfoScreen.addressList.homeless;
        newAddress.resAddrMatchFlag = TextProps.VALUE_TRUE;
        newAddress.typeCode = "RES";
        if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "changed"
        ) {
          newAddress.dataChangeCode = "CHANGE";
          requestBody.additionalProperties['resAddressCode']="changedLabel";
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (
          this.checkForAddressChange(respResAddress, updatedResAddress) ===
          "removed"
        ) {
          newAddress.dataChangeCode = "REMOVE";
          requestBody.additionalProperties['resAddressCode']="removedLabel";
          reportedChanges = TextProps.VALUE_TRUE;
        } else {
          newAddress.dataChangeCode = "NOCHNG";
        }
        //Homeless flag check
        if (
          this.props.interimReport.contactInfoScreen.addressList.homeless !==
          respResAddress.homelessFlag
        ) {
          newAddress.dataChangeCode = "CHANGE";
          requestBody.additionalProperties['homelssFlagCode']="changedLabel";
          reportedChanges = TextProps.VALUE_TRUE;
        }
        finalAddressArray.push(newAddress);
      } else if (respResAddress === null && updatedResAddress !== null) {
        let newAddress = {};
        newAddress.line1 = updatedResAddress.line1;
        newAddress.line2 = updatedResAddress.line2;
        newAddress.city = updatedResAddress.city;
        newAddress.state = updatedResAddress.state;
        newAddress.zip = updatedResAddress.zip;
        newAddress.homelessFlag =
          this.props.interimReport.contactInfoScreen.addressList.homeless;
        newAddress.resAddrMatchFlag = TextProps.VALUE_TRUE;
        newAddress.typeCode = "RES";
        newAddress.dataChangeCode = "NEW";
        finalAddressArray.push(newAddress);
        reportedChanges = TextProps.VALUE_TRUE;
      }

      //Mail Addre check
      if (respMailAddress !== null && updatedMailAddress !== null) {
        let newAddress = {};
        newAddress.line1 = updatedMailAddress.line1;
        newAddress.line2 = updatedMailAddress.line2;
        newAddress.city = updatedMailAddress.city;
        newAddress.state = updatedMailAddress.state;
        newAddress.zip = updatedMailAddress.zip;
        newAddress.homelessFlag =
          this.props.interimReport.contactInfoScreen.addressList.homeless;
        newAddress.resAddrMatchFlag = TextProps.VALUE_FALSE;
        newAddress.typeCode = "MAIL";
        if (
          this.checkForAddressChange(respMailAddress, updatedMailAddress) ===
          "changed"
        ) {
          newAddress.dataChangeCode = "CHANGE";
          requestBody.additionalProperties['mailingAddressCode']="changedLabel";
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (
          this.checkForAddressChange(respMailAddress, updatedMailAddress) ===
          "removed"
        ) {
          newAddress.dataChangeCode = "REMOVE";
          requestBody.additionalProperties['mailingAddressCode']="changedLabel";
          reportedChanges = TextProps.VALUE_TRUE;
        } else {
          newAddress.dataChangeCode = "NOCHNG";
        }
        if (
          this.props.interimReport.contactInfoScreen.addressList.homeless !==
          respMailAddress.homelessFlag
        ) {
          newAddress.dataChangeCode = "CHANGE";
          reportedChanges = TextProps.VALUE_TRUE;
        }
        if (finalAddressArray.length > 0)
          finalAddressArray[0].resAddrMatchFlag = TextProps.VALUE_FALSE;
        finalAddressArray.push(newAddress);
      } else if (respMailAddress === null && updatedMailAddress !== null) {
        let newAddress = {};
        newAddress.line1 = updatedMailAddress.line1;
        newAddress.line2 = updatedMailAddress.line2;
        newAddress.city = updatedMailAddress.city;
        newAddress.state = updatedMailAddress.state;
        newAddress.zip = updatedMailAddress.zip;
        newAddress.homelessFlag =
          this.props.interimReport.contactInfoScreen.addressList.homeless;
        newAddress.resAddrMatchFlag = TextProps.VALUE_FALSE;
        newAddress.typeCode = "MAIL";
        newAddress.dataChangeCode = "NEW";
        reportedChanges = TextProps.VALUE_TRUE;
        if (finalAddressArray.length > 0)
          finalAddressArray[0].resAddrMatchFlag = TextProps.VALUE_FALSE;
        finalAddressArray.push(newAddress);
      } else if (respMailAddress !== null && updatedMailAddress === null) {
        if (finalAddressArray.length > 0) {
          finalAddressArray[0].dataChangeCode = "CHANGE";
          reportedChanges = TextProps.VALUE_TRUE;
        }
      }

      requestBody.clientAddress = finalAddressArray;

      //Email change
      let respEmail = responseBody.clientEmail;
      let updatedEmail = this.props.interimReport.contactInfoScreen;
      let emailCode = null;
      if (!respEmail.emailAddress && updatedEmail.email) {
        emailCode = "NEW";
        requestBody.additionalProperties['emailCode']="newLabel";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (respEmail.emailAddress && !updatedEmail.email) {
        emailCode = "REMOVE";
        requestBody.additionalProperties['emailCode']="removedlabel";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (
        respEmail.emailAddress &&
        (respEmail.emailAddress !== updatedEmail.email ||
          respEmail.optInFlag !== updatedEmail.emailOptIn)
      ) {
        emailCode = "CHANGE";
        if (respEmail.dataChangeCode === "NEW") {
          emailCode = "NEW";
        }
        reportedChanges = TextProps.VALUE_TRUE;
        requestBody.additionalProperties['emailCode']="changedLabel";
      } else {
        emailCode = "NOCHNG";
      }

      requestBody.clientEmail.dataChangeCode = emailCode;
      requestBody.clientEmail.emailAddress = updatedEmail.email;
      requestBody.clientEmail.optInFlag =
        updatedEmail.emailOptIn === "Y" ||
        updatedEmail.emailOptIn === TextProps.VALUE_TRUE
          ? TextProps.VALUE_TRUE
          : TextProps.VALUE_FALSE;

      //Phone change
      let respPhone = responseBody.clientPhone;
      let updatedPhone = this.props.interimReport.contactInfoScreen;
      let phoneNumber = updatedPhone.phoneNumber
        ? updatedPhone.phoneNumber.replace(/\D/g, "")
        : "";
      let phoneCode = null;
      if (!respPhone.phoneNumber && phoneNumber) {
        phoneCode = "NEW";
        requestBody.additionalProperties['phoneCode']="newLabel";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (respPhone.phoneNumber && !phoneNumber) {
        phoneCode = "REMOVE";
        requestBody.additionalProperties['phoneCode']="removedLabel";
        reportedChanges = TextProps.VALUE_TRUE;
      } else if (
        respPhone.phoneNumber &&
        (respPhone.phoneNumber !== phoneNumber ||
          respPhone.textOptInFlag !== updatedPhone.textOptIn)
      ) {
        phoneCode = "CHANGE";
        if (respPhone.dataChangeCode === "NEW") {
          phoneCode = "NEW";
        }
        requestBody.additionalProperties['phoneCode']="changedLabel";
        reportedChanges = TextProps.VALUE_TRUE;
      } else {
        phoneCode = "NOCHNG";
      }

      requestBody.clientPhone.dataChangeCode = phoneCode;
      requestBody.clientPhone.phoneNumber = phoneNumber;
      requestBody.clientPhone.textOptInFlag =
        updatedPhone.textOptIn === "Y" ||
        updatedPhone.textOptIn === TextProps.VALUE_TRUE
          ? TextProps.VALUE_TRUE
          : TextProps.VALUE_FALSE;
    }

    if (requestBody.clientAddress.length > 0) {
      let newAddressArray = [];
      requestBody.clientAddress.map((address) => {
        if (address.dataChangeCode === undefined) {
          address.dataChangeCode = "NOCHNG";
        }
        newAddressArray.push(address);
      });
      requestBody.clientAddress = newAddressArray;
    }

    if (requestBody.clientPhone.dataChangeCode === undefined) {
      requestBody.clientPhone.dataChangeCode = "NOCHNG";
    }

    if (requestBody.clientEmail.dataChangeCode === undefined) {
      requestBody.clientEmail.dataChangeCode = "NOCHNG";
    }

    //HOUSEHOLD CHANGE
    if (this.props.interimReport.householdMembers && this.props.interimReport.householdMembers.membersListUpdated) {
      let newHouseholdList = [];
      for (let memberList of _.cloneDeep(this.props.interimReport.householdMembers.membersList)) {
        if (memberList.allAnswers !== undefined) {
          let genderCodeVal = "";
          if (
            memberList.allAnswers[6].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .genderOptios[0]
          ) {
            genderCodeVal = "MALE";
          } else if (
            memberList.allAnswers[6].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .genderOptios[1]
          ) {
            genderCodeVal = "FEMALE";
          }

          let citizenFlagVal = null;
          if (
            memberList.allAnswers[9].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .citizenOptions[0]
          ) {
            citizenFlagVal = TextProps.VALUE_TRUE;
          } else if (
            memberList.allAnswers[9].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .citizenOptions[1]
          ) {
            citizenFlagVal = TextProps.VALUE_FALSE;
          }

          let buyFoodFlagVal = null;
          if (
            memberList.allAnswers[11].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .buyFoodOptions[0]
          ) {
            buyFoodFlagVal = TextProps.VALUE_TRUE;
          } else if (
            memberList.allAnswers[11].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .buyFoodOptions[1]
          ) {
            buyFoodFlagVal = TextProps.VALUE_FALSE;
          }

          let incomeFlagVal = null;
          if (
            memberList.allAnswers[12].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .personIncomeOptions[0]
          ) {
            incomeFlagVal = TextProps.VALUE_TRUE;
          } else if (
            memberList.allAnswers[12].Answer ===
            interimReportPageLabels(language, languageConstants.householdStatic)
              .personIncomeOptions[1]
          ) {
            incomeFlagVal = TextProps.VALUE_FALSE;
          }
          const raceAnswers = memberList.allAnswers[8].Answer && memberList.allAnswers[8].Answer.length && memberList.allAnswers[8].Answer.map(raceAns => {
            let raceObj = {};
            if (
              raceAns === "Decline to answer" ||
              raceAns === "declineToAnswer" ||
              raceAns === "DCL" ||
              raceAns === "Me niego a responder"
            ) {
              raceObj["raceCode"] ="DCL";
            } else {
              raceObj["raceCode"] = raceAns;
            }
            return raceObj;
          })
          let ethnicityCode = memberList.allAnswers[7].Answer ? (memberList.allAnswers[7].Answer  === "Decline to answer" ||memberList.allAnswers[7].Answer === "Negarse a contestar") ? "DCL" : memberList.allAnswers[7].Answer : "";
          let newMemberObj = {
            agencyID: 0,
            granteeFlag: TextProps.VALUE_FALSE,
            firstName: memberList.allAnswers[0].Answer,
            middleName: memberList.allAnswers[1].Answer,
            lastName: memberList.allAnswers[2].Answer,
            suffix: this.getSuffixValue(memberList.allAnswers[3].Answer),
            relationshipCode: memberList.allAnswers[4].Answer.toUpperCase(),
            dateOfBirth: this.convertDOBForDataPost(memberList.dateOfBirth),
            // dateOfBirth: this.convertDOBForDataPost(formattedDate),
            
            genderCode : genderCodeVal,
            ethnicity: ethnicityCode,
            race: raceAnswers ? raceAnswers : [],
            citizenFlag : citizenFlagVal,
            SSN: memberList.allAnswers[10].Answer,
            // buyFoodFlag: buyFoodFlagVal,
            incomeFlag: incomeFlagVal,
            dataChangeCode: memberList.dataChangeCode ? memberList.dataChangeCode :"NEW",
            allAnswers: memberList.allAnswers
          };
          newHouseholdList.push(newMemberObj);
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (
          memberList.dataChangeCode &&
          memberList.dataChangeCode === "removed"
        ) {
          let removedMember = memberList;
          removedMember.dataChangeCode = "REMOVE";
          if (
            removedMember.granteeFlag === TextProps.VALUE_Y ||
            removedMember.granteeFlag === TextProps.VALUE_TRUE
          ) {
            removedMember.granteeFlag = TextProps.VALUE_TRUE;
          } else {
            removedMember.granteeFlag = TextProps.VALUE_FALSE;
          }
          removedMember.dateOfBirth = this.convertDOBForDataPost(
            removedMember.dateOfBirth
          );
          newHouseholdList.push(removedMember);
          reportedChanges = TextProps.VALUE_TRUE;
        } else if (memberList.agencyID > 1) {
          let existingMember = memberList;
          if (
            existingMember.granteeFlag === TextProps.VALUE_Y ||
            existingMember.granteeFlag === TextProps.VALUE_TRUE
          ) {
            existingMember.granteeFlag = TextProps.VALUE_TRUE;
          } else {
            existingMember.granteeFlag = TextProps.VALUE_FALSE;
          }
          existingMember.dateOfBirth = this.convertDOBForDataPost(
            existingMember.dateOfBirth
          );
          newHouseholdList.push(existingMember);
        }
      }
      requestBody.householdMembers = newHouseholdList;
    }

    let householdMembers = []
    if (requestBody.householdMembers.length > 0) {
      let newHouseholdArray = [];
      requestBody.householdMembers.map((member) => {
        member.dateOfBirth = this.convertDOBForDataPost(member.dateOfBirth);
        if (member.granteeFlag === TextProps.VALUE_Y) {
          member.granteeFlag = TextProps.VALUE_TRUE;
        } else if (member.granteeFlag === TextProps.VALUE_N) {
          member.granteeFlag = TextProps.VALUE_FALSE;
        }

        if (member.dataChangeCode === undefined) {
          member.dataChangeCode = "NOCHNG";
        }
        householdMembers.push(_.cloneDeep(member));
        if("allAnswers" in member){
          delete member.allAnswers;
        }
        newHouseholdArray.push(member);
      });
      requestBody.householdMembers = newHouseholdArray;
      if (this.props.interimReport.householdMembers) {
        requestBody.additionalProperties.householdMembers = {
          membersListUpdated: this.props.interimReport.householdMembers.membersListUpdated,
          addHouseholdMember: this.props.interimReport.householdMembers.addHouseholdMember,
          membersList: householdMembers,
        };
      }
    }

    //EARNED INCOME CHANGE
    if (
      (this.props.interimReport.sections.length > 4 &&
      this.props.interimReport.earnedIncomeChanged &&
      this.props.interimReport.earnedIncomeChanged === TextProps.VALUE_TRUE) || ( this.props.interimReport.grossEarnedIncomeChanged && this.props.interimReport.grossEarnedIncomeChanged === TextProps.VALUE_TRUE)
    ) {
      let allIncome = _.cloneDeep(this.incomeSummary.incomeMembers);
      let incomeArrayExisting = _.cloneDeep(
        this.props.interimReport.responseObject.earnedIncome
      );

      let incomeArrayNew = [];
      let newArray = [];
      allIncome.map((member) => {
        let memberObj = this.getMemberDataObjectByNameAndDOB(
          member.firstName,
          incomeArrayExisting
        );
        if (memberObj === null) {
          memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            this.props.interimReport.householdMembers.membersList
          );

          if(datasection && datasection.questions[0] && datasection.questions[0].Answer){
            newArray = datasection.questions[0].Answer.map((answer)=>{
              let incomeObj = {
                id: memberObj.id ? memberObj.id:0,
                setID:memberObj.setID ? memberObj.setID:0,
                agencyID: memberObj.agencyID ? memberObj.agencyID:0,
              firstName: memberObj.firstName,
              lastName: memberObj.lastName,
              dateOfBirth: this.convertDOBForDataPost(memberObj.dateOfBirth),
                typeCode: answer,
                employerName: '',
                frequencyCode: '',
              amounts: [{amount:""}],
              dataChangeCode: "NEW",
            };

              return incomeObj
            })
          }

        }
        if (member.incomes !== undefined) {
          member.incomes.map((income) => {
            let typeCode = null;
            if (
              income !== undefined &&
              income.Answer !== undefined &&
              income.Answer.length > 0
            ) {
              income.Answer.map((incomeSub) => {
                let amountArray = [];
                let amountIterator = 1;
                if (incomeSub.amount) {
                  if (getFrequencyCode(language)[incomeSub.unit] === "WEEK") {
                    amountIterator = 4;
                  } else if (
                    getFrequencyCode(language)[incomeSub.unit] === "BIWEEK"
                  ) {
                    amountIterator = 2;
                  }
                  for (let i = 0; i < amountIterator; i++) {
                    amountArray.push({
                      amount: incomeSub.amount[i],
                    });
                  }
                }

                let changeCode = this.returnDataChangeCode(incomeSub.code)

                typeCode = income.typeCode;

                let incomeObj = {
                  id: this.returnMemberId(member, incomeSub),
                  setID:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.setID
                      ? incomeSub.fullIncomeObj.setID
                      : 0,
                  agencyID: this.returnAgencyID(memberObj, incomeSub),
                  firstName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.firstName
                      ? incomeSub.fullIncomeObj.firstName
                      : memberObj.firstName,
                  lastName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.lastName
                      ? incomeSub.fullIncomeObj.lastName
                      : memberObj.lastName,
                  dateOfBirth: this.convertDOBForDataPost(
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.dateOfBirth
                      ? incomeSub.fullIncomeObj.dateOfBirth
                      : memberObj.dateOfBirth
                  ),
                  typeCode: typeCode,
                  employerName: incomeSub.employer,
                  frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                  amounts: amountArray,
                  dataChangeCode: changeCode,
                };

                incomeArrayNew.push(incomeObj);
              }); // income sub ends
            }
          }); // income ends
        }
      }); // member ends
      if(activeId.includes("combined-income-") && !activeId.includes('subsection')){
        if(datasection){
          requestBody.additionalProperties['sectionData'] = {
            ...requestBody.additionalProperties['sectionData'],
            [activeId]:datasection.questions[0].Answer
          }
        }
        if(newEarnedIncomes.length>0){
         let newArray =  datasection.questions[0].Answer.map((code)=>{
                let newIncome = {...newEarnedIncomes[0]}
                newIncome['typeCode'] = code
              return newIncome
         })
        incomeArrayNew = [...incomeArrayNew,...newArray]
        }
      }

      incomeArrayNew = [...incomeArrayNew,...newArray]

      let finalArray = _.cloneDeep(incomeArrayNew);
      incomeArrayExisting.map((existing) => {
        existing.dateOfBirth = this.convertDOBForDataPost(existing.dateOfBirth);

        let found = TextProps.VALUE_FALSE;
        for (let newIncome of incomeArrayNew) {
          if (
            newIncome.id === existing.id &&
            newIncome.agencyID === existing.agencyID &&
            newIncome.typeCode === existing.typeCode
          ) {
            found = TextProps.VALUE_TRUE;
          }
        }
        if (found === TextProps.VALUE_FALSE) {
          existing.dataChangeCode = "REMOVE";
          finalArray.push(existing);
        }
      });
      requestBody.earnedIncome = finalArray;
    }

    if (requestBody.earnedIncome.length > 0) {
      let newHouseholdArray = [];
      requestBody.earnedIncome.map((member) => {
        member.dateOfBirth = this.convertDOBForDataPost(member.dateOfBirth);
        if (member.dataChangeCode === undefined) {
          member.dataChangeCode = "NOCHNG";
        } else if (member.dataChangeCode !== "NOCHNG") {
          reportedChanges = TextProps.VALUE_TRUE;
        }
        if (member.id <= 1) {
          member.dataChangeCode = "NEW";
        }
        newHouseholdArray.push(member);
      });
      requestBody.earnedIncome = newHouseholdArray;
    }

    //UNEARNED INCOME CHANGE
    if (
      this.props.interimReport.sections.length > 4 &&
      this.props.interimReport.unearnedIncomeChanged !== undefined &&
      this.props.interimReport.unearnedIncomeChanged === TextProps.VALUE_TRUE
    ) {
      let allIncome = _.cloneDeep(this.incomeSummary.unearnedIncomeMembers);
      let incomeArrayExisting = _.cloneDeep(
        this.props.interimReport.responseObject.unearnedIncome
      );

      let incomeArrayNew = [];
      let newArray = [];
      allIncome.map((member) => {
        let memberObj = this.getMemberDataObjectByNameAndDOB(
          member.firstName,
          incomeArrayExisting
        );
        if (memberObj === null) {
          memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            this.props.interimReport.householdMembers.membersList
          );

          if(datasection && datasection.questions[0] && datasection.questions[0].Answer){
            newArray = datasection.questions[0].Answer.map((answer)=>{
              let incomeObj = {
                id: memberObj.id ? memberObj.id:0,
                setID:memberObj.setID ? memberObj.setID:0,
                agencyID: memberObj.agencyID ? memberObj.agencyID:0,
              firstName: memberObj.firstName,
              lastName: memberObj.lastName,
              dateOfBirth: this.convertDOBForDataPost(memberObj.dateOfBirth),
                typeCode: answer,
                frequencyCode: '',
              amounts: [{amount:""}],
              dataChangeCode: "NEW",
            };
              return incomeObj
            })
          }

        }
        if (member.incomes !== undefined) {
          member.incomes.map((income, index) => {
            let typeCode = null;
            if (
              income !== undefined &&
              income.Answer !== undefined &&
              income.Answer.length > 0
            ) {
              income.Answer.map((incomeSub) => {
                let amountArray = [];
                let amountIterator = 1;
                if (incomeSub.amount) {
                  if (getFrequencyCode(language)[incomeSub.unit] === "WEEK") {
                    amountIterator = 4;
                  } else if (
                    getFrequencyCode(language)[incomeSub.unit] === "BIWEEK"
                  ) {
                    amountIterator = 2;
                  }

                  for (let i = 0; i < amountIterator; i++) {
                    amountArray.push({
                      amount: incomeSub.amount[i]?incomeSub.amount[i]:'',
                    });
                  }
                }
                let changeCode = this.returnDataChangeCode(incomeSub.code)
                typeCode = income.typeCode;
                let incomeObj = {
                  id: this.returnMemberId(member, incomeSub),
                  setID:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.setID
                      ? incomeSub.fullIncomeObj.setID
                      : 0,
                  agencyID: this.returnAgencyID(memberObj, incomeSub),
                  firstName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.firstName
                      ? incomeSub.fullIncomeObj.firstName
                      : memberObj.firstName,
                  lastName:
                    incomeSub.fullIncomeObj !== undefined &&
                    incomeSub.fullIncomeObj.lastName
                      ? incomeSub.fullIncomeObj.lastName
                      : memberObj.lastName,
                  dateOfBirth: this.convertDOBForDataPost(
                    incomeSub.fullIncomeObj !== undefined &&
                      incomeSub.fullIncomeObj.dateOfBirth
                      ? incomeSub.fullIncomeObj.dateOfBirth
                      : memberObj.dateOfBirth
                  ),
                  typeCode: typeCode,
                  frequencyCode: getFrequencyCode(language)[incomeSub.unit],
                  amounts: amountArray,
                  dataChangeCode: changeCode,
                };
                incomeArrayNew.push(incomeObj);
              }); // income sub ends
            }
          }); // income ends
        }
      }); // member ends

      if(activeId.includes("unearned-combined-income-") && !activeId.includes('subsection')){
        if(datasection){
          requestBody.additionalProperties['sectionData'] = {
            ...requestBody.additionalProperties['sectionData'],
            [activeId]:datasection.questions[0].Answer
          }
        }
        if(newUnearnedIncomes.length>0){
          let newArray =  datasection.questions[0].Answer.map((code)=>{
                 let newIncome = {...newUnearnedIncomes[0]}
                 newIncome['typeCode'] = code;
               return newIncome
          })
         incomeArrayNew = [...incomeArrayNew,...newArray]
         }
      }

      incomeArrayNew = [...incomeArrayNew,...newArray]
      let finalArray = _.cloneDeep(incomeArrayNew);
      incomeArrayExisting.map((existing) => {
        existing.dateOfBirth = this.convertDOBForDataPost(existing.dateOfBirth);
        let found = TextProps.VALUE_FALSE;
        for (let newIncome of incomeArrayNew) {
          if (
            newIncome.id === existing.id &&
            newIncome.agencyID === existing.agencyID &&
            newIncome.setID === existing.setID
          ) {
            found = TextProps.VALUE_TRUE;
          }
        }
        if (found === TextProps.VALUE_FALSE) {
          existing.dataChangeCode = "REMOVE";
          finalArray.push(existing);
        }
      });
      requestBody.unearnedIncome = finalArray;
    }

    if (requestBody.unearnedIncome.length > 0) {
      let newHouseholdArray = [];
      for (let member of requestBody.unearnedIncome) {
        member.dateOfBirth = this.convertDOBForDataPost(member.dateOfBirth);
        if (member.dataChangeCode === undefined) {
          member.dataChangeCode = "NOCHNG";
        } else if (member.dataChangeCode !== "NOCHNG") {
          reportedChanges = TextProps.VALUE_TRUE;
        }
        if (member.id <= 1) {
          member.dataChangeCode = "NEW";
        }
        newHouseholdArray.push(member);
      }
      requestBody.unearnedIncome = newHouseholdArray;
    }

    //CHILE EXP CHANGE
    if (this.props.interimReport.obligationChildSupportChanged!== undefined  ) {
      let childExpenseNew = [] ;
      if(this.props.interimReport.childExpScreen && this.props.interimReport.childExpScreen.tempChildExpArray){
         childExpenseNew =_.cloneDeep(
        this.props.interimReport.childExpScreen.tempChildExpArray
      );
      }
      let childExpenseExisting = _.cloneDeep(this.props.interimReport.responseObject.childSupportExpense);
      let ChildArrayNew = [];
      childExpenseNew.filter((supportAmt)=>supportAmt.dataChangeCode!==null).map((member) => {
        let memberObj = this.getMemberDataObjectByNameAndDOB(
          member.firstName,
          childExpenseExisting
        );
        if (memberObj === null) {
          memberObj = this.getMemberDataObjectByNameAndDOB(
            member.firstName,
            this.props.interimReport.householdMembers.membersList
          );
        }
        let changeCode = "NOCHNG";
        changeCode = this.returnDataChangeCode(member.dataChangeCode)
        if (changeCode !== "NOCHNG"){
          reportedChanges = TextProps.VALUE_TRUE;
        }
        let childObj = {
          id: member.id,
          setID:member.setID? member.setID : 0,
          agencyID: member.agencyID,
          firstName:member.firstName,
          lastName:member.lastName,
          dateOfBirth: this.convertDOBForDataPost(member.dateOfBirth),
          typeCode: member.typeCode,
          frequencyCode: member.frequency ? getFrequencyCode(language)[member.frequency] : member.frequencyCode , 
          amount: member.amount,
          dataChangeCode: member.id === 0  ? "NEW" : changeCode,
          // dataChangeCode: member.id === 0 && member.isRemoved ? "REMOVE" : changeCode,
        };
        ChildArrayNew.push(childObj)
      })


      let finalArray = _.cloneDeep(ChildArrayNew);
      childExpenseExisting.forEach((existing,index) => {
        existing.dateOfBirth = this.convertDOBForDataPost(existing.dateOfBirth);
        let found = TextProps.VALUE_FALSE;
        for (let newChildExp of ChildArrayNew) {
          if (
            newChildExp.id === existing.id &&
            newChildExp.agencyID === existing.agencyID &&
            newChildExp.setID === existing.setID
          ) {
            found = TextProps.VALUE_TRUE;
          }
        }
        if (found === TextProps.VALUE_FALSE) {
          if(existing.id === 0){
            childExpenseExisting.splice(index, 1);
          }else{
          existing.dataChangeCode = "REMOVE";
          finalArray.push(existing);
          }
        }
      });
      requestBody.childSupportExpense = finalArray;
    }

    if (requestBody.childSupportExpense.length > 0) {
      let newHouseholdArray = [];
      requestBody.childSupportExpense.map((member) => {
        member.dateOfBirth = this.convertDOBForDataPost(member.dateOfBirth);
        if (member.dataChangeCode === undefined) {
          member.dataChangeCode = "NOCHNG";
        } else if (member.dataChangeCode !== "NOCHNG") {
          reportedChanges = TextProps.VALUE_TRUE;
        }
        newHouseholdArray.push(member);
      });
      requestBody.childSupportExpense = newHouseholdArray;
    }
    


     //Shelter Expenses
     if(this.props.interimReport.shelterUtilityConfirmationChanged ||  (this.props.interimReport.responseObject && this.props.interimReport.responseObject.additionalProperties && this.props.interimReport.responseObject.additionalProperties.shelterChanged)){
         let shelterExpNew = _.cloneDeep(this.shelterSummary.shelterExpense[0].incomes);
         let shelterExpExisting = _.cloneDeep(
           this.props.interimReport.responseObject.shelterExpense
         );

         let oldShelterCodes = this.props.interimReport.responseObject.shelterExpense.map((exp)=>{
          return exp.typeCode
        })
        let newShelterCodes = shelterExpNew.map((exp)=>{
          return exp.typeCode
        })

        var removed = oldShelterCodes.filter( function(shelterExp) { return !this.has(shelterExp) }, new Set(newShelterCodes) );

         let finalShelterArray = [];
         shelterExpNew.forEach((exp) => {
           if(exp.Answer && exp.Answer.length){
             let shelterObj = {};
             exp.Answer.forEach((oneExp=>{
               let changeCode = this.returnDataChangeCode(oneExp.code)
               if (changeCode !== "NOCHNG")
               reportedChanges = TextProps.VALUE_TRUE;
                 shelterObj = {
                   "typeCode":exp.typeCode,
                   "frequencyCode": getFrequencyCode(language)[oneExp.unit],
                   "amount":oneExp.amount,
                   "dataChangeCode":changeCode,
                 }
                 finalShelterArray.push(shelterObj);
             }))
           }
         })

         if(activeId.includes("shelter-expenses-section")){
          if(datasection && datasection.questions[0].Answer.length>0){
            const oldExpense = this.shelterSummary.shelterExpense[0].incomeInputs.filter((income)=>income.hasOwnProperty('Answer'));
             let newExpense = datasection.questions[0].Answer.filter(value => !oldExpense.some(item => item.valueToMatch === value));
             newExpense = newExpense.map((exp)=>{
              return {
                "typeCode": exp,
                "amount": "",
                "frequencyCode": "MONTH",
                "dataChangeCode": "NEW"
            }
             })
             finalShelterArray = [...finalShelterArray,...newExpense]
          }
        }
         requestBody.shelterExpense = finalShelterArray;

         //Utility Expense

         let liheapFlagValue = null;
          if (this.shelterSummary && this.shelterSummary.hasOwnProperty('isLiheapDataUpdated')) {
            if (this.shelterSummary.isLiheapDataUpdated === 'Yes') {
              liheapFlagValue = true;
            } else if (this.shelterSummary.isLiheapDataUpdated === 'No') {
              liheapFlagValue = false;
            }
          }

         let utilityExpense = {
          "heatFlag": false,
          "acFlag": false,
          "electricOrGasFlag": false,
          "phoneFlag": false,
          "noUtilityFlag": false,
          "payWaterSewage":false,
          "payGarbage":false,
          "liheapFlag":liheapFlagValue,
        // "dataChangeCode": ""
         }
        //  let removedutility = [];
        //  let newUtility = [];
         let utilityExpNew = _.cloneDeep(this.shelterSummary.utilityExpenses);
      let dataChangeCodes = [];
      let combined = {};
      let finalUtility = {};
         Object.keys(utilityExpense).forEach((exp)=>{
            utilityExpNew.forEach((newExp)=>{
          if (!saveAndContinue) {
              utilityExpense["dataChangeCode"] = (newExp.code !== "removed" || newExp.code !== "existing") ? "CHANGE" : "NOCHNG";
          } else {
            switch (newExp.type) {
              case 'acFlag':
                dataChangeCodes.push({ type: newExp.type, 'acDataChangeCode': this.returnDataChangeCode(newExp.code) })
                break;
              case 'electricOrGasFlag':
                dataChangeCodes.push({ type: newExp.type, 'electricOrGasDataChangeCode': this.returnDataChangeCode(newExp.code) })
                break;
              case 'phoneFlag':
                dataChangeCodes.push({ type: newExp.type, 'phoneDataChangeCode': this.returnDataChangeCode(newExp.code) })
                break;
              case 'payGarbage':
                dataChangeCodes.push({ type: newExp.type, 'garbageDataChangeCode': this.returnDataChangeCode(newExp.code)})
                break;
              case 'heatFlag':
                dataChangeCodes.push({ type: newExp.type, 'heatDataChangeCode': this.returnDataChangeCode(newExp.code)})
                break;
              case 'payWaterSewage':
                dataChangeCodes.push({ type: newExp.type, 'waterSewageDataChangeCode': this.returnDataChangeCode(newExp.code) })
                break;
              case 'liheapFlag':
                dataChangeCodes.push({ type: newExp.type, 'liheapDataChangeCode': this.returnDataChangeCode(newExp.code) })
                break;
              default:
            }
          }
                if(newExp.type === exp && newExp.code !== "removed"){
                  utilityExpense[exp] = true;
                }
            })
         })
      dataChangeCodes = _.uniqBy(dataChangeCodes, (val) => val.type);
      let indicators = dataChangeCodes.map((oneObj) => {
        delete oneObj['type'];
        return oneObj
      })
      indicators.forEach((indicator) => {
        combined = { ...combined, ...indicator }
      })
      finalUtility = { ...combined, ...utilityExpense }
      requestBody.utilityExpense = finalUtility;
       }
      

       if(activeId.includes("utility-expenses-section")){
        if(datasection){
          requestBody.additionalProperties['sectionData'] = {
            ...requestBody.additionalProperties['sectionData'],
            [activeId]:datasection.questions[0].Answer
          }
        }
      }

     console.log("REQUESTTT",requestBody);

    //Other information change
    let otherInfo = [];
    if (
      this.props.interimReport.sections.length > 4 &&
      this.props.interimReport.sections[6].questions !== undefined &&
      this.props.interimReport.sections[6].questions.length > 0
    ) {
      let indexOther = 0;
      this.props.interimReport.sections[6].questions.map((question, index) => {
        if (
          question.type === "radio" &&
          question.Answer ===
            interimReportPageLabels(language, languageConstants.aboutMe)
              .optionYesNo[0]
        ) {
          let otherInfoItem =
            responseBody.otherInformation.workRequirement[indexOther];
          otherInfoItem["dataChangeCode"] = "CHANGE";
          otherInfo.push(otherInfoItem);
          reportedChanges = TextProps.VALUE_TRUE;
          indexOther++;
        } else if (question.type === "radio") {
          let otherInfoItem =
            responseBody.otherInformation.workRequirement[indexOther];
          otherInfoItem["dataChangeCode"] = "NOCHNG";
          otherInfo.push(otherInfoItem);
          indexOther++;
        }
      });
    }
    requestBody.otherInformation.workRequirement = otherInfo;
    if (requestBody.otherInformation.workRequirement.length > 0) {
      let newHouseholdArray = [];
      requestBody.otherInformation.workRequirement.map((member) => {
        if (
          member.dateOfBirth !== undefined &&
          member.dateOfBirth.indexOf("-") >= 0
        )
          member.dateOfBirth = this.convertDOBForDataPost(member.dateOfBirth);
        if (member.dataChangeCode === undefined) {
          member.dataChangeCode = "NOCHNG";
        }
        newHouseholdArray.push(member);
      });
      requestBody.otherInformation.workRequirement = newHouseholdArray;
    }

    if (reportedChanges) {
      requestBody.dataChangeCode = "CHANGE";
      requestBody.languageCode = language.split("-")[0];
      if(!saveAndContinue){
      requestBody.signedName = this.refs.applicanSign.inputEl.value;
      }
      requestBody.webAppTypeCode = "SNAPIR";
    } else {
      requestBody.dataChangeCode = "NOCHNG";
      requestBody.languageCode = language.split("-")[0];
      if(!saveAndContinue){
      requestBody.signedName = this.refs.applicanSign.inputEl.value;
      }
      requestBody.webAppTypeCode = "SNAPIR";
    }
    this.setState(
      {
        reportedChanges: reportedChanges,
        requestBody: requestBody,
      },
      () => {
        if(!saveAndContinue && !saveAndExit){
          this.saveFinalSignAndSubmit(activeId);
        }else{
          this.saveInprogressApp(this.state.requestBody,saveAndExit);
        }
        }
    );
  };

 saveInprogressApp = (data,isSaveAndExit) =>{
   this.props.saveInterimApplication(data,language).then((result)=>{
    if(isSaveAndExit && this.isShowSaveSuccessModal){
      setTimeout(
        () =>
          this.setState({
            saveExitShowModal: TextProps.VALUE_TRUE,
            isLoading: TextProps.VALUE_FALSE,
          }),
        300
      );
    }
    this.isShowSaveSuccessModal = true;
    if(isSaveAndExit){
      if (sessionStorage.getItem("acesButtonClickedFlag") === "true") {
        sessionStorage.setItem("acesButtonClickedFlag", false);
        this.redirectToHomePage();
      }
    }
  }).catch((error=>{
    this.setState({
      isLoading: TextProps.VALUE_FALSE,
    });
    console.log("Interim Save and COntinue Error",error)
  }))
 }


  getSuffixValue = (answer) => {
    switch (language) {
      case types.ENGLISH:
        return answer;
      case types.SPANISH:
        return answer;
      case types.PORTUGUESE:
        return answer;
      case types.CHINESE: //chinees
        if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[0]
        )
          return "I";
        else if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[10]
        )
          return "Jr.";
        else if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[11]
        )
          return "Sr.";
        else return answer;
      case types.VIETNAMESE:
        return answer;
      case HI:
        if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[3]
        )
          return "IV";
        else if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[4]
        )
          return "V";
        else if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[10]
        )
          return "Jr.";
        else if (
          answer ===
          interimReportPageLabels(language, languageConstants.householdStatic)
            .SuffixOptions[11]
        )
          return "Sr.";
        else return answer;
      default:
        return answer;
    }
  };

  checkForAddressChange = (source, dest) => {
    let sourceLine2 = "";
    if (source.line2 !== undefined) {
      sourceLine2 = source.line2;
    }
    let streetSource = source
      ? (source.line1 + sourceLine2).replace(/ /g, "")
      : null;
    let streetDest = dest ? (dest.line1 + dest.line2).replace(/ /g, "") : null;
    if (
      source !== null &&
      dest !== null &&
      ((source.zip !== "" && dest.zip === "") ||
        (source.city !== "" && dest.city === "") ||
        (streetSource !== "" && streetDest === ""))
    ) {
      return "removed";
    } else if (streetSource !== streetDest) {
      return "changed";
    } else if (source !== null && dest !== null && source.city !== dest.city) {
      return "changed";
    } else if (source !== null && dest !== null && source.zip !== dest.zip) {
      return "changed";
    }
  };

  getMemberDataObjectByNameAndDOB = (name, listToCheck) => {
    let returnMember = null;
    listToCheck.map((member) => {
      let dob = member.dateOfBirth;
      if (dob.indexOf("-") > 0) {
        dob = formatters.formatDate(dob);
      }
      if (
        name.trim() ===
        member.firstName.trim() + " " + member.lastName.trim() + dob
      ) {
        returnMember = member;
      }
    });
    return returnMember;
  };

  renderOtherInformation = () => {
    let section = this.props.interimReport.sections[6];
    if (!this.state.otherInfoPopulated) {
      let newQuestions = [];
      let otherInfo =
        this.props.interimReport.responseObject.otherInformation
          .workRequirement;
      otherInfo.map((info) => {
        let name =
          info.firstName +
          " " +
          info.lastName +
          formatters.formatDate(info.dateOfBirth);
        let questionText = {};
        let questionValue = {};
        let questionDivider = {};
        questionText.type = "text";
        questionText.value = otherInfoCodeMapper(language, name)[
          info.excemptReasonCode
        ];

        newQuestions.push(questionText);

        questionValue.type = "radio";
        questionValue.options = [
          {
            optionDisplay: interimReportPageLabels(
              language,
              languageConstants.aboutMe
            ).optionYesNo[0],
            optionValue: interimReportPageLabels(
              language,
              languageConstants.aboutMe
            ).optionYesNo[0],
          },
          {
            optionDisplay: interimReportPageLabels(
              language,
              languageConstants.aboutMe
            ).optionYesNo[1],
            optionValue: interimReportPageLabels(
              language,
              languageConstants.aboutMe
            ).optionYesNo[1],
          },
        ];
        questionValue.name = info.programID + "_" + info.excemptReasonCode;
        questionValue.optionsPerRow = 2;
        questionValue.label = interimReportPageLabels(
          language,
          languageConstants.otherInfoPage,
          name,
          null,
          null
        ).hasChangedQuestionLabel;
        questionValue.isRequired = TextProps.VALUE_TRUE;
        questionValue.error = TextProps.VALUE_FALSE;
        questionValue.errorMessage = interimReportPageLabels(
          language,
          languageConstants.contactInfo
        ).requiredErrorMessage;
        newQuestions.push(questionValue);
        questionDivider.type = "divider";
        newQuestions.push(questionDivider);
      });
      section.questions = newQuestions;
      this.setState({
        otherInfoPopulated: TextProps.VALUE_TRUE,
      });
    }
  };

  renderShelterAndUtility = () => {
    return (
      <ShelterExpenses
        getIncomeSummaryTitle={this.getSummaryTitle}
        prepareShelterSummary={this.prepareShelterSummary}
        shelterSummary={this.shelterSummary}
        shelterSubsectionRender={this.shelterSubsectionRender}
        onSectionChange={this.onSectionChange}
        setShelterData={this.props.setShelterData}
        shelterResponseData={this.props.interimReport.responseObject}
      />
    );
   
  };

  getShelterExpenseFrequency = (frequencyCode) => {
    let shelterExpenseFrequency;
    switch (frequencyCode) {
      case 'Week':
      case'WEEK':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).week;
        return shelterExpenseFrequency;
      case 'Month':
      case 'MONTH':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).month;
        return shelterExpenseFrequency;
      case 'Year':
      case 'ANNUAL':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).year;
        return shelterExpenseFrequency;
      case 'BIWEEK':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).biweek;
        return shelterExpenseFrequency;
      case 'SEMANL':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).semanl;
        return shelterExpenseFrequency;
      case 'ONETME':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).onetime;
        return shelterExpenseFrequency;
      case 'BIMON':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).bimonthly;
        return shelterExpenseFrequency;
      case 'SEMMON':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).semimonthly;
        return shelterExpenseFrequency;
      case 'Monthly':
        shelterExpenseFrequency = interimReportPageLabels(
          language,
          languageConstants.shelterAndutility
        ).monthly;
        return shelterExpenseFrequency;
      default: break;
    }
  }

  returnDataChangeCode=(code)=>{
    let changeCode = ""
    switch(code){
      case "existing":
        changeCode = "NOCHNG";
        break;
      case "new":
      case "NEW":
        changeCode = "NEW";
        break;
      case "changed":
      case "CHANGE":
        changeCode = "CHANGE"
        break;
      case "removed":
      case "REMOVE":
        changeCode = "REMOVE";
      default:
        changeCode =code;
        break;
    }
    return changeCode;
  }

  // This is the main markup for the apply layout
  render() {
    const { sections } = this.props.interimReport;

    if (this.state.isLoading)
      return helperFunction.isLoading(this.state.isLoading);

    if (
      sections.length > 0 &&
      sections[0].isSubmitInitiated === sections[0].isSubmitted
    ) {
      return this.renderApplyQuestions(sections);
    } else {
      return <Loading />;
    }
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      applyIRSanpQuestionsSections: applyIRSanpQuestionsSections,
      appendIRHouseHoldMembers: appendIRHouseHoldMembers,
      clearHouseHoldMembers: clearHouseHoldMembers,
      appendIRIncomeDetails: appendIRIncomeDetails,
      appendUIIncomeDetails: appendUIIncomeDetails,
      appendIRExpenseDetails: appendIRExpenseDetails,
      clearMemberIncome: clearMemberIncome,
      clearMemberUIIncome: clearMemberUIIncome,
      updateResolveQuestionsData: updateResolveQuestionsData,
      downloadIRPDFData: downloadIRPDFData,
      updateCurrentSectionData: updateCurrentSectionData,
      addStudentToList: addStudentToList,
      setDataChangeStatus: setDataChangeStatus,
      setHouseholdMembers: setHouseholdMembers,
      setContactInfo: setContactInfo,
      setEarnedIncomeChangeStatus: setEarnedIncomeChangeStatus,
      setUnearnedIncomeChangeStatus: setUnearnedIncomeChangeStatus,
      setInterimReportResponseObj: setInterimReportResponseObj,
      submitInterimReportData: postInterimReportData,
      updateHouseholdTextChanges: updateHouseholdTextChanges,
      addContactInfoSubSection: addContactInfoSubSection,
      setChildSupportData: setChildSupportData,
      snapRefreshQuestionsSections: snapRefreshQuestionsSections,
      addHouseholdAddSectionToList: addHouseholdAddSectionToList,
      displayPDFData: displayPDFData,
      onConsumerLogin: () => push(routeProps.CONSUMER_HOME_PATH),
      gotoConsumer: () => push(routeProps.CONSUMER_PATH),
      gotoHome: () => push(routeProps.HOME_PATH),
      gotoProvider: () => push(routeProps.PROVIDER_PATH),
      onLogOut: onLogOut,
      onAzureLogOut: onAzureLogOut,
      displayError: () => push(routeProps.API_ERR_PATH),
      appendShelterSections:appendShelterSections,
      appendShelterFilteredQuestions:appendShelterFilteredQuestions,
      setShelterData:setShelterData,
      setShelterOptions:setShelterOptions,
      saveInterimApplication:saveInterimApplication,
      onPrimaryActionSaveClickedAction:onPrimaryActionSaveClickedAction,
      // getInterimSavedDataForUser:getInterimSavedDataForUser,
      appendChildSupportDetails:appendChildSupportDetails,
      appendIRShelterQuestions:appendIRShelterQuestions,
      appendChildSupportAnswer:appendChildSupportAnswer,
      setChildSupportOptions:setChildSupportOptions,
      deleteInterimApplication:deleteInterimApplication,
      appendInterimDataForSummary: appendInterimDataForSummary,
      appendIRShelterQuestions: appendIRShelterQuestions,
      enableNavigationForSections:enableNavigationForSections,
      applyAddressStorageAPI:applyAddressStorageAPI,
      addressValidation:addressValidation,
      acesButtonClicked: acesButtonClicked,
      setRadioButtonFlags:setRadioButtonFlags,
      languageChanged: languageChanged,
    },
    dispatch
  );
}

export default withMsal(
  connect(mapStateToProps, mapDispatchToProps)(InterimReport)
);
