import "babel-polyfill";
import React, { Component, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import axios from "axios";
import Bowser from "bowser";
import { LiveChatWidget } from "@microsoft/omnichannel-chat-widget";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import "./css/app.css";
import Router from "./pages/user/router";
import appConfig from "./utils/constants/appConfigConstants";
import * as TextProps from "./utils/constants/text";
import { getCookie } from "./utils/helper/cookie";
import { MsalProvider, withMsal } from "@azure/msal-react";
import { idleTimer } from "./pages/user/idleTimer";
import * as modalHelperFunction from "./utils/components/helperFunctions/modalHelperFunction";
import { communicationAssistanceData } from "./utils/appText/communicationAssistanceConstants";
import * as routeProps from "./utils/constants/routesConstants";
import * as logos from "./utils/constants/constants";
import { Link } from "react-router-dom";
import LanguageSelect from "./redux/language/languageSelect";
import DTADropdown from "./utils/components/dtaDropdown";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "./redux/rootReducer";
import { onLogOut } from "./utils/helper/accountNavigationActions";
import { onAzureLogOut } from "./utils/helper/accountNavigationActions";
import { azureLogin } from "./redux/user/loginActions";
import { acesButtonClicked } from "./redux/applySnap/applySnapActions";
import LiveChat from "./utils/components/chatBox/LiveChat";
import Icon from "./utils/components/dtaIcon";
import { loginRequest } from "config/appConfig";
var config = require("./utils/constants/appConfigConstants");
const EN = "en-US";
const ES = "es-ES";
//Main App Class
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Height: 0,
      chatboxKey: 0,
      modalVisible: false,
      isModalMinimized: false,
      sessionExpiredModel: TextProps.VALUE_FALSE,
      modalError: TextProps.VALUE_TRUE,
      hideCloseIcon: TextProps.VALUE_FALSE,
      msalInstance: this.props.msalContext.instance,
      activeLang: {},
      freeCommunication: false,
      selectedLang: "",
      showDefaultSection: true
    };
  }
 
  componentDidMount() {
    localStorage.getItem("selectedLanguage") != null
      ? localStorage.getItem("selectedLanguage")
      : getCookie("selectedLanguage")
      ? localStorage.setItem("selectedLanguage", getCookie("selectedLanguage"))
      : localStorage.setItem("selectedLanguage", "en-US");
    this.updateScrollHeight();
    const msalInstance = this.props.msalContext.instance;
    idleTimer(msalInstance);

  }
  componentWillUnmount() {
    this.updateScrollHeight();
  }
  // updating scroll height on pebt module
  updateScrollHeight = () => {
    setTimeout(() => {
      let mainElems = document.getElementsByTagName("main");
      let pebtBannerElems = document.getElementsByClassName("custom-banner");
      let mainTagHeight = 0;
      let pebtBannerHeight = 0;
      if (mainElems) {
        for (let elems of mainElems) {
          mainTagHeight = elems.scrollHeight;
        }
      }
      if (pebtBannerElems) {
        for (let elems of pebtBannerElems) {
          pebtBannerHeight = elems.scrollHeight;
        }
      }
      this.setState({ Height: pebtBannerHeight + mainTagHeight });
    }, 0.5);
  };

  componentWillMount = () => {
    let systemInfo = {
      ip: "",
      source: appConfig.source,
      deviceId: "",
    };
    var browser = Bowser.getParser(navigator.userAgent);
    if (browser) {
      systemInfo.deviceId =
        browser.getPlatform().type +
          " - " +
          browser.getBrowser().name +
          " " +
          browser.getBrowser().version +
          " - " +
          browser.getOS().name +
          " " +
          browser.getOS().version || "";
    }
    axios
      .get("https://api.ipify.org", appConfig.HttpOptions)
      .then((response) => {
        systemInfo.ip = response.data;
        sessionStorage.setItem("systemInfo", JSON.stringify(systemInfo));
      })
      .catch((error) => {
        systemInfo.ip = "";
        sessionStorage.setItem("systemInfo", JSON.stringify(systemInfo));
      });
  };

  renderPebtMealsBanner = () => {
    let pebtAlert = [];
    if (sessionStorage.getItem("PEBTBanner")) {
      pebtAlert = JSON.parse(sessionStorage.getItem("PEBTBanner"));
    }
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    let language;
    let bannerItems = ["dtaconnect.eohhs.mass.gov/PEBT PEBT Meals Banner"];
    if (selectedLanguage === "en-US") {
      language = "EN";
    } else if (selectedLanguage === "es-ES") {
      language = "ES";
    } else if (selectedLanguage === "pt-BR") {
      language = "PT";
    } else if (selectedLanguage === "zh-Hans") {
      language = "ZH";
    } else if (selectedLanguage === "vi-VI") {
      language = "VI";
    } else if (selectedLanguage === "ht") {
      language = "HT";
    } else {
      language = "EN";
    }
    return (
      pebtAlert &&
      pebtAlert.map((alert, index) => {
        if (
          alert.languageCD === language &&
          bannerItems.includes(alert.subject)
        ) {
          return (
            <header>
              <div className="covid-banner-outer alert-collapsible custom-banner">
                <div className="covid-banner-inner max-width">
                  <p>
                    <p
                      className="pebt-header"
                      key={index}
                      dangerouslySetInnerHTML={{ __html: alert.desc }}
                    ></p>
                  </p>
                </div>
              </div>
            </header>
          );
        }
      })
    );
  };
  handleClick = () => {
    this.setState({ sessionExpiredModel: TextProps.VALUE_TRUE });
  };
  closeModal = () => {
    this.setState({ sessionExpiredModel: TextProps.VALUE_FALSE });
    sessionStorage.clear();
    window.location.href = "/";
  };
  stayModal = () => {
    this.setState({ sessionExpiredModel: TextProps.VALUE_FALSE });
  };
  showModal = () => {
    return (
      <div className="dta-modal__body pad-all">
       <p>
          You will be logged out due to inactivity in 5 minutes. Any unsaved information will be lost. Please choose to stay signed in or log-off.
        </p>
        <p> </p>
        <div className="apply__footer pad-all--double">
          <button
            className="dta-button dta-button--outline-primary"
            onClick={() => this.closeModal()}
          >
            Log Off
          </button>
          <button
            className="dta-button dta-button--primary"
            onClick={() => this.stayModal()}
          >
            Stay Logged In
          </button>
        </div>
      </div>
    );
  };
 
  handleActiveLangChange = (lang) => {
    if (lang === this.state.selectedLang) {
      this.setState({
        selectedLang: "",
        showDefaultSection: true,
        freeCommunication: false,
      });
    } else if (lang === "freeComm") {
      this.setState({
        freeCommunication: true,
        selectedLang: lang,
        showDefaultSection: false,
      });
    } else {
      this.setState({ selectedLang: lang, showDefaultSection: false });
    }
  };
 
  handleChatIconBox = (e,onclose, onMinimized = false) => {
    if(onMinimized)
      {
        this.setState({ isModalMinimized: true, modalVisible: false })
      }
    else if(onclose){
        this.setState({ modalVisible: false, isModalMinimized: false })
    }
    this.setState({ modalVisible: !this.state.modalVisible });
  };
  _handleApplyGoBack = () => {
    this.setstate({ sections: [] });
  };

  _handleApplyLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.Authorization = this.props.consumer.mfaDetails.userAuth;
    this.props.onLogOut(paramsLogout);
  };

  handleApplyDsnap = (event) => {
    event.preventDefault();
    window.location.assign(routeProps.HOME_PATH);
  };
    //azure
  getUserSelectedLanguage = () => {
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    let language = "en";
    if (selectedLanguage === EN) {
      language = "en";
    } else if (selectedLanguage === ES) {
      language = "es";
    }
    else {
       language = "en";
    }
    return language;
   };
  _handleAzureLogin = (msalIns) => {
    let language = this.getUserSelectedLanguage();
    const loginReq = {
      ...loginRequest,
      extraQueryParameters: {
        ...loginRequest.extraQueryParameters,
        ui_locales: language,
      },
    };
    msalIns
      .loginRedirect(loginReq)
      .then()
      .catch(() => {
      });
  };
  render() {
    let pebtSubmitButton = document.getElementById("pebt");
    let path = window.location.pathname;
    let pebtAlert = [];
    if (sessionStorage.getItem("PEBTBanner")) {
      pebtAlert = JSON.parse(sessionStorage.getItem("PEBTBanner"));
    }
    let pebtSubmitBtnClickCount = sessionStorage.getItem("submitCounter");
    let pebtAlertArr;
    let bannerSubjects = ["dtaconnect.eohhs.mass.gov/PEBT PEBT Meals Banner"];
    if (pebtAlert) {
      pebtAlertArr = pebtAlert.find((obj) =>
        bannerSubjects.includes(obj.subject)
      );
    }
    if (pebtSubmitButton) {
      pebtSubmitButton.addEventListener("click", this.updateScrollHeight);
    }
    const { LocalCountryOffices } = communicationAssistanceData;
    let home, chatBot;
    if (this.props && this.props.language) {
      home = this.props.language.home;
      chatBot = this.props.language.chatBot;
    }
    let common;
    if (this.props && this.props.language && this.props.language.security) {
      common = this.props.language.security.common;
    }
    const msalInstance = this.props.msalContext.instance;
    var userInfo;
    var loginUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    var loginCheck = TextProps.VALUE_FALSE;
    if (loginUserInfo !== null && loginUserInfo !== undefined) {
      userInfo = loginUserInfo;
      loginCheck = TextProps.VALUE_TRUE;
    }
    if (this.state.showDefaultSection) {
      sessionStorage.setItem("openFreeComm", true);
    } else {
      sessionStorage.setItem("openFreeComm", false);
    }

    var arrayPath = [
      routeProps.SNAP_SIX_MONTH_REPORT_PATH,
      routeProps.COVID_RECERT_PATH,
      routeProps.RECERTIFICATION_PATH,
      routeProps.TAFDC_PATH,
      routeProps.DECLARE_EXPENSES_PATH,
      routeProps.APPLY_PATH,
      routeProps.REPORTCHANGEPATH
    ];
    var greeterPath = [routeProps.GREETER_ROLE];
    var toPath;
    if (arrayPath.includes(window.location.pathname)) {
      toPath = routeProps.CONSUMER_PATH;
    } else if (greeterPath.includes(window.location.pathname)) {
      toPath = routeProps.GREETER_ROLE;
    } else {
      toPath = routeProps.HOME_PATH;
    }

    // let acesButton = document.getElementById("acesbutton");
    // acesButton.addEventListener('click', (event) => {
    //   event.preventDefault();

    //   console.log("aces button clicked")
    // })    
    return (
      <div
        className="app"
        style={{
          height:
            path === "/PEBT" && pebtAlertArr !== undefined
              ? `${pebtSubmitBtnClickCount < 3 && this.state.Height}px`
              : null,
        }}
      >
        {/* <div className={`chatBox_Wrapper ${this.state.modalVisible ? 'chatBoxModalOpen': 'chatBoxModalClosed'}`}>         
          <div className="modal_wrapper">
            <div
              className={
                this.state.modalVisible
                  ? "modal_header_visible"
                  : "modal_header"
              }
            >
              <div
                style={{
                  display: "flex",
                  columnGap: "20px",
                  alignItems: "center",
                }}
              >
                <div className="header_logo_wrap"></div>
                <div style={{marginLeft:'-40px'}}>{chatBot && chatBot.askAce}</div>
              </div>
              <div>
                <span onClick={(e) => this.handleChatIconBox(e, false, true)}><Icon name="minus" label="Minimize modal" /></span>
                <span style={{ margin: "15px" }} onClick={(e) => this.handleChatIconBox(e,true)}><Icon name="close" label="Close modal" /></span>
              </div>
            </div>
            <div className={this.state.modalVisible ? "modal_body_visible" : "modal_body"}>
              <ChatBox 
                chatboxKey={this.state.chatboxKey} 
                isModalMinimized={this.state.isModalMinimized} 
                modalVisible={this.state.modalVisible} 
                rerenderChatWindow={this.handleChatIconBox} 
                sendBoxPlaceholder={chatBot && chatBot.sendBoxPlaceholder}
                hiAceText={chatBot && chatBot.hiAce}
                aceDetailsText={chatBot && chatBot.aceDetails}
               aceSubDetailsText={chatBot && chatBot.aceSubDetailsText}
              />
               {this.state.chatBox && <LiveChatWidget {...this.state.chatBox} />}
            </div>
          </div>
          
        </div>
        //chat header
        <div
            className={this.state.modalVisible ? "modal_wrapper_visible" : "chatBox_Wrapper chatBoxModalClosed"}
            onClick={(e) => this.handleChatIconBox(e)}
          >
            <div className="chatBox_button">
              <div className="chatBox_button_icon"></div>
              <span>{chatBot && chatBot.needHelp}</span>
            </div>
        </div> */}
        
        {(path === "/apply" || path === "/recertification" || path === "/snapSixMonthReport" || path ==="/reportchange"  || path ==="/tafdc" || path === "/dSnap") && (
          <div className="apply_free_comm">
            <div className="free-comm-banner-main">
              <div className="free-comm-banner-inner max-width">
                <h3
                  onClick={() => this.handleActiveLangChange("freeComm")}
                  style={{ cursor: "pointer" }}
                >
                  {home.whiteBannerText}
                </h3>
                <div>
                  {communicationAssistanceData.LangData.map((lang, i) => {
                    return (
                      <a
                        key={i}
                        style={{ fontSize: "14px" }}
                        onClick={() => this.handleActiveLangChange(lang)}
                      >
                        {lang.LangId !== 0 && lang.LangTye}
                      </a>
                    );
                  })}
                  <a
                    onClick={() => this.handleActiveLangChange("freeComm")}
                    style={{ cursor: "pointer" }}
                  >
                    {home.DeafText}
                  </a>
                </div>
              </div>
              {this.state.freeCommunication &&
              this.state.selectedLang === "freeComm" ? (
                <div className="fca max-width">
                  <h1 className="fca_heading">Free Communication Assistance</h1>
                  <div className="fca_contents">
                    {communicationAssistanceData.LangData.map((item) => {
                      return (
                        <div className="fca_content">
                          <h3>{item.LangLabel}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.LangContent,
                            }}
                          ></p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="ca_content">
                    <p
                      className="ca_description"
                      dangerouslySetInnerHTML={{
                        __html: this.state.activeLang.LangContent,
                      }}
                    ></p>
                    <div className="ca_contacts" id="contacts">
                      <h3 className="ca_contacts_heading">
                        DHR Local County Offices
                      </h3>
                      <div className="ca_contacts_wrapper">
                        {LocalCountryOffices.map((office) => {
                          return (
                            <div className="ca_contact">
                              <span>{office.LocalCountryName}</span>
                              <span>{office.LocalCountryPhone}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                Object.keys(this.state.selectedLang).length > 0 && (
                  <div className="ca_content">
                    <p
                      className="ca_description"
                      dangerouslySetInnerHTML={{
                        __html: this.state.selectedLang.LangContent,
                      }}
                    ></p>
                    <div className="ca_contacts" id="contacts">
                      <h3 className="ca_contacts_heading">
                        DHR Local County Offices
                      </h3>
                      <div className="ca_contacts_wrapper">
                        {LocalCountryOffices.map((office) => {
                          return (
                            <div className="ca_contact">
                              <span>{office.LocalCountryName}</span>
                              <span>{office.LocalCountryPhone}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            {this.state.showDefaultSection && (
              <nav className="nav homepage__nav">
                <div className="navbar banner-blue">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                  {path !== "/dSnap" ? (
                    <a
                      href="#"
                      id="acesbutton"
                      onClick={(event) => {
                        event.preventDefault();
                        this.props.acesButtonClicked({
                          value: true,
                          path: toPath,
                        });
                        console.log("acesbutton clicked", toPath);
                      }}
                      className="navbar__logo text--center display-block"
                    >
                      <img src={logos.alabama} alt="ACES DHR Self-Service" />
                      <span className="sr-only">ACES DHR Self-Service</span>
                    </a>
                  ) : (
                    <a
                      id="acesbutton"
                      onClick={this.handleApplyDsnap}
                      className="navbar__logo text--center display-block"
                    >
                      <img src={logos.alabama} alt="ACES DHR Self-Service" />
                      <span className="sr-only">ACES DHR Self-Service</span>
                    </a>
                  )}
                    <ul className="nav-list navbar__nav-list">
                      <li className="nav-list__item1 text-color-white">{home.translatePageText}</li>
                      <li className="nav-list__item">
                        <LanguageSelect allowLanguageChange={true}/>
                      </li>
                      {path !== "/dSnap" && (
                      <li className="nav-list__item">
                        {/* This should link to log in page */}
                        {loginCheck === TextProps.VALUE_TRUE ? (
                          <div className="dta-nav__options-account height">
                            <div className="dta-nav__options-account">
                              <div className="nav__dropdown_home">
                                <DTADropdown
                                  buttonText={`Hi! ${userInfo.firstName}`}
                                  buttonClass="dta-button white-btn-link"
                                  menuClass="list--bordered"
                                  caret={TextProps.VALUE_TRUE}
                                  caretClass="color-primary"
                                >
                                  <li className="pad-all--half border-top">
                                    <React.Fragment>
                                      {config.azureLoginFlag ? (
                                        <Link
                                          to="#"
                                          className="display-block"
                                          onClick={() =>
                                            this.props.onAzureLogOut(
                                              msalInstance
                                            )
                                          }
                                        >
                                          {common.logout}
                                        </Link>
                                      ) : (
                                        <Link
                                          to="#"
                                          className="display-block"
                                          onClick={this._handleApplyLogoutSession.bind(
                                            this
                                          )}
                                        >
                                          {common.logout}
                                        </Link>
                                      )}
                                    </React.Fragment>
                                  </li>
                                </DTADropdown>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <React.Fragment>
                            {config.azureLoginFlag ? (
                              <Link
                                to="#"
                                onClick={() =>
                                  this._handleAzureLogin(msalInstance)
                                }
                                className="custom-login-btn dta-button dta-button--primary"
                              >
                                {home.loginText}
                              </Link>
                            ) : (
                              <Link
                                to={routeProps.LOGIN_PATH}
                                className="custom-login-btn dta-button dta-button--outline-secondary"
                              >
                                {home.loginText}
                              </Link>
                            )}
                          </React.Fragment>
                        )}
                      </li>
                      )}
                    </ul>
                  </div>
                </div>
              </nav>
            )}
          </div>
        )}
        {(path === "/pebtCaseInfo" || path === "/replacepebt") &&
        pebtAlertArr !== undefined
          ? this.renderPebtMealsBanner()
          : null}
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={TextProps.VALUE_FALSE}
          newestOnTop={TextProps.VALUE_TRUE}
          closeOnClick
          pauseOnHover
        />
        <Router />
        <div
          className="session-expired-arial-modal"
          onClick={this.handleClick.bind(this)}
        ></div>
        {modalHelperFunction.dtaHelperModal(
          null,
          this.state.sessionExpiredModel,
          this.stayModal,
          "rivl-modal req-income-container",
          "Session Timeout",
          null,
          null,
          TextProps.VALUE_FALSE,
          "Session Timeout",
          this.showModal()
        )}
        <div className="liveChatContainer" style={{position: 'fixed', right: '0px', bottom:'10px'}}>
          <LiveChat />
        </div>
      </div>
    );
  }
}
 
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onLogOut: onLogOut,
      azureLogin: azureLogin,
      onAzureLogOut: onAzureLogOut,
      acesButtonClicked: acesButtonClicked,
    },
    dispatch
  );
}

export default withMsal(connect(mapStateToProps, mapDispatchToProps)(App));