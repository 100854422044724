import { isNone } from "../../utils/components/utilities/controls";
import { aboutMeMultilanguageLabels, resourcesSectionMultilanguageLabels } from "./applySnapText";
import * as TextProps from "../../utils/constants/text";
import * as Props from "../../utils/components/shared";
import { PREFERRED_DAYS_WITH_SAME_DAY, PREFERRED_TIME_MERIDIANS, contactInfo, langOptSel, PREFERRED_SPANISHDAYS_WITH_SAME_DAY } from "../../utils/constants/constants";
import { getDaysFlagObject, getTimeMeridienFlagObject,getInterviewType } from "../../utils/helper/DateConversion";
import {filterData} from "./applySnapHelper";
import _ from 'lodash'
import { houseHoldInformationTemplate } from "./applySnapMapData";
import { convertDOBForDataPost } from "../recertification/recertificationHelper";
import * as formatters from "../../utils/components/utilities/formatters";

var frequencyCode = require("../../utils/constants/sanpApplyConstant");

export function convertToDTOObj(sections, userSignature, inProgressObj) {
  let selectedLanguage = localStorage.getItem("selectedLanguage");
  let applicationType = sessionStorage.getItem("applicationType");
  
  if (selectedLanguage === null || selectedLanguage === langOptSel) {
    selectedLanguage = "en-US";
  }

  let application = new Application();

  //from UI answers and questions
  let aboutMe = filterData(sections, "about-me").questions;

  let applicant = new Person();

  applicant.isGrantee = TextProps.VALUE_Y;
  applicant.personId = filterData(sections, "before-you-start").webClientId;
  applicant.firstName = aboutMe[0].Answer;
  applicant.middleName = aboutMe[1].Answer;
  applicant.lastName = aboutMe[2].Answer;

  if (
    aboutMe[3].targetValue ===
    aboutMeMultilanguageLabels(selectedLanguage).suffixOption[0]
  ) {
    applicant.nameSuffix = "I";
  } else if (
    aboutMe[3].targetValue ===
    aboutMeMultilanguageLabels(selectedLanguage).suffixOption[10]
  ) {
    applicant.nameSuffix = "Jr.";
  } else if (
    aboutMe[3].targetValue ===
    aboutMeMultilanguageLabels(selectedLanguage).suffixOption[11]
  ) {
    applicant.nameSuffix = "Sr.";
  } else {
    applicant.nameSuffix = aboutMe[3].targetValue;
  }

  if (
    aboutMe[4].Answer !== "" &&
    aboutMe[4].Answer !== undefined &&
    aboutMe[4].Answer !== null
  ) {
    applicant.birthDate = new Date(aboutMe[4].Answer);
  }
  applicant.SSN = aboutMe[5].Answer;

  /** which program this application */
  var program = new Program();
  program.programCode = TextProps.MATCH_SNAP;
  application.programs.push(program);

  /** status of the application */
  if (
    inProgressObj !== undefined &&
    inProgressObj !== "" &&
    inProgressObj !== null
  ) {
    application.status = inProgressObj.status;
  } else {
    application.status = "RECVED";
  }

  /** sign name as first_name of application */
  application.signedName = userSignature;

  /** my contactInfo data sections */
  let contactInfo = filterData(sections, "about-me").subsections[0].questions;
  if (
    applicationType === TextProps.MATCH_SNAP ||
    applicationType === TextProps.MATCH_COMBO
  ) {
    if (
      contactInfo[2].Answer !== null &&
      contactInfo[2].Answer !== undefined &&
      contactInfo[2].Answer !== ""
    ) {
      if (
        contactInfo[2].Answer === "Yes" ||
        contactInfo[2].Answer === "Sí" ||
        contactInfo[2].Answer === "Sim" ||
        contactInfo[2].Answer === "是" ||
        contactInfo[2].Answer === "Có" ||
        contactInfo[2].Answer === "Wi"
      ) {
        applicant.isHomeless = TextProps.VALUE_Y;
      } else {
        applicant.isHomeless = TextProps.VALUE_N;
      }
    }
  }

  if (
    applicationType === TextProps.MATCH_SNAP ||
    applicationType === TextProps.MATCH_TANF ||
    applicationType === TextProps.MATCH_COMBO
  ){

    const appointmentObj = contactInfo.reduce((accumulator,currentQuestionItem) => {
      if(currentQuestionItem[Props.NAME] === 'interviewType'){
        const interviewTypeQuestion = getInterviewType(currentQuestionItem.Answer ? currentQuestionItem.Answer : "",selectedLanguage);
        return { ...accumulator, ...{ interviewType: interviewTypeQuestion } };
      }


        if(currentQuestionItem[Props.NAME] === 'preferred-phone-number'){
          return { ...accumulator, ...{ preferredPhoneNumber: currentQuestionItem.Answer }}
        }

        if(currentQuestionItem[Props.NAME] === 'preferred-day'){
        if(selectedLanguage === "es-ES") {
          const { mondayFlag, tuesdayFlag, wednesdayFlag, thursdayFlag, fridayFlag, samedayFlag } = getDaysFlagObject(PREFERRED_SPANISHDAYS_WITH_SAME_DAY,currentQuestionItem.Answer)
          return { ...accumulator, ...{ mondayFlag, tuesdayFlag, wednesdayFlag, thursdayFlag, fridayFlag, samedayFlag } }
        }
        else {
            const { mondayFlag, tuesdayFlag, wednesdayFlag, thursdayFlag, fridayFlag, samedayFlag } = getDaysFlagObject(PREFERRED_DAYS_WITH_SAME_DAY,currentQuestionItem.Answer)
            return { ...accumulator, ...{ mondayFlag, tuesdayFlag, wednesdayFlag, thursdayFlag, fridayFlag, samedayFlag } }
          }
        }

        if(currentQuestionItem[Props.NAME] === 'preferred-time-option'){
          const { amFlag, pmFlag } = getTimeMeridienFlagObject(PREFERRED_TIME_MERIDIANS, currentQuestionItem.Answer);
          return { ...accumulator, ...{ amFlag, pmFlag } }
        }

        return accumulator;
    }, {})

    applicant.appointmentPreference = appointmentObj;

  }
  // applicant residence address
  let resAddress = new Address();
  if (
    applicationType === TextProps.MATCH_SNAP ||
    applicationType === TextProps.MATCH_COMBO
  ) {
    if (contactInfo[3].Answer !== undefined) {
      if (
        contactInfo[3].Answer.addressLine1 &&
        contactInfo[3].Answer.zipCode
      ) {
        resAddress.addressType = "RES";
        resAddress.street =
          contactInfo[3].Answer.addressLine1;
          resAddress.aptOrUnit = contactInfo[3].Answer.addressLine2; //contactInfo[3].Answer.street;
        resAddress.city = contactInfo[3].Answer.city;
        resAddress.postal = contactInfo[3].Answer.zipCode;
        resAddress.state = contactInfo[3].Answer.state;
        resAddress.countyCode = contactInfo[3].Answer.countyCode;
        resAddress.verified = contactInfo[3].Answer.verified;

        if (
          contactInfo[4].Answer !== undefined ||
          contactInfo[4].Answer !== null ||
          contactInfo[4].Answer !== ""
        ) {
          resAddress.isSameMailAndPhysical =
            contactInfo[4].Answer === "" ||
            contactInfo[4].Answer === TextProps.VALUE_STR_TRUE
              ? TextProps.VALUE_Y
              : TextProps.VALUE_N;
        }
        applicant.addresses.push(resAddress);
      }
    }
  } else if (applicationType === TextProps.MATCH_TANF) {
    if (contactInfo[0].Answer !== undefined) {
      if (
        contactInfo[0].Answer.addressLine1 &&
        contactInfo[0].Answer.zipCode
      ) {
        resAddress.addressType = "RES";
        resAddress.street =
          contactInfo[0].Answer.addressLine1;
          
          resAddress.aptOrUnit = contactInfo[0].Answer.addressLine2; 
        resAddress.city = contactInfo[0].Answer.city;
        resAddress.postal = contactInfo[0].Answer.zipCode;
        resAddress.state = contactInfo[0].Answer.state;

        resAddress.countyCode = contactInfo[0].Answer.countyCode;
        resAddress.verified = contactInfo[0].Answer.verified;

        if (
          contactInfo[1].Answer !== undefined ||
          contactInfo[1].Answer !== null ||
          contactInfo[1].Answer !== ""
        ) {
          resAddress.isSameMailAndPhysical =
            contactInfo[1].Answer === "" ||
            contactInfo[1].Answer === TextProps.VALUE_STR_TRUE
              ? TextProps.VALUE_Y
              : TextProps.VALUE_N;
        }
        applicant.addresses.push(resAddress);
      }
    }
  }
  // checking applicant having two address like mail and res address
  if (
    applicationType === TextProps.MATCH_SNAP ||
    applicationType === TextProps.MATCH_COMBO
  ) {
    if (contactInfo[4].Answer !== undefined && contactInfo[4].Answer !== "") {
      if (contactInfo[4].Answer === TextProps.VALUE_STR_FALSE) {
        let mailAddress = new Address();
        if (
          contactInfo[4].conditionals[0].conditionalQuestions[0].Answer !==
          undefined
        ) {
          if (
            contactInfo[4].conditionals[0].conditionalQuestions[0].Answer
              .addressLine1
          ) {
            mailAddress.addressType = "MAIL";
            mailAddress.street =
              contactInfo[4].conditionals[0].conditionalQuestions[0].Answer
                .addressLine1;
             
              mailAddress.aptOrUnit = contactInfo[4].conditionals[0].conditionalQuestions[0].Answer
                .addressLine2; //contactInfo[4].conditionals[0].conditionalQuestions[0].Answer.street;
            mailAddress.city =
              contactInfo[4].conditionals[0].conditionalQuestions[0].Answer.city;
            mailAddress.postal =
              contactInfo[4].conditionals[0].conditionalQuestions[0].Answer.zipCode;
            mailAddress.state =
              contactInfo[4].conditionals[0].conditionalQuestions[0].Answer.state;
            mailAddress.isSameMailAndPhysical =
              contactInfo[4].Answer === TextProps.VALUE_STR_TRUE
                ? TextProps.VALUE_Y
                : TextProps.VALUE_N;

            mailAddress.countyCode =
              contactInfo[4].conditionals[0].conditionalQuestions[0].Answer.countyCode;
            mailAddress.verified =
              contactInfo[4].conditionals[0].conditionalQuestions[0].Answer.verified;
            applicant.addresses.push(mailAddress);
          }
        }
      }
    }
  } else if (applicationType === TextProps.MATCH_TANF) {
    if (contactInfo[1].Answer !== undefined && contactInfo[1].Answer !== "") {
      if (contactInfo[1].Answer === TextProps.VALUE_STR_FALSE) {
        let mailAddress = new Address();
        if (
          contactInfo[1].conditionals[0].conditionalQuestions[0].Answer !==
          undefined
        ) {
          if (
            contactInfo[1].conditionals[0].conditionalQuestions[0].Answer
              .addressLine1
          ) {
            mailAddress.addressType = "MAIL";
            mailAddress.street =
              contactInfo[1].conditionals[0].conditionalQuestions[0].Answer
              .addressLine1 
              mailAddress.aptOrUnit = contactInfo[1].conditionals[0].conditionalQuestions[0].Answer
              .addressLine2;
            //contactInfo[1].conditionals[0].conditionalQuestions[0].Answer.street;

            mailAddress.city =
              contactInfo[1].conditionals[0].conditionalQuestions[0].Answer.city;
            mailAddress.postal =
              contactInfo[1].conditionals[0].conditionalQuestions[0].Answer.zipCode;
            mailAddress.state =
              contactInfo[1].conditionals[0].conditionalQuestions[0].Answer.state;
            mailAddress.isSameMailAndPhysical =
              contactInfo[1].Answer === TextProps.VALUE_STR_TRUE
                ? TextProps.VALUE_Y
                : TextProps.VALUE_N;

            mailAddress.countyCode =
              contactInfo[1].conditionals[0].conditionalQuestions[0].Answer.countyCode;
            mailAddress.verified =
              contactInfo[1].conditionals[0].conditionalQuestions[0].Answer.verified;
            applicant.addresses.push(mailAddress);
          }
        }
      }
    }
  }
  // spliting to 3 parts of phone no
  if (
    applicationType === TextProps.MATCH_SNAP ||
    applicationType === TextProps.MATCH_COMBO
  ) {
    if (
      contactInfo[5].Answer !== undefined &&
      contactInfo[5].Answer !== null
    ) {
      let phoneNo = contactInfo[5].Answer.replace(/[^0-9]/g, "");
      applicant.areaCode = phoneNo.substring(0, 3);
      applicant.exchangeNumber = phoneNo.substring(3, 6);
      applicant.lineNumber = phoneNo.substring(6, 10);
    }
    if (contactInfo[6].Answer !== undefined) {
      applicant.emailAddress = contactInfo[6].Answer;
    }
  } else if (applicationType === TextProps.MATCH_TANF) {
    if (
      contactInfo[2].Answer !== undefined &&
      contactInfo[2].Answer !== null
    ) {
      let phoneNo = contactInfo[2].Answer.replace(/[^0-9]/g, "");
      applicant.areaCode = phoneNo.substring(0, 3);
      applicant.exchangeNumber = phoneNo.substring(3, 6);
      applicant.lineNumber = phoneNo.substring(6, 10);
    }
    if (contactInfo[3].Answer !== undefined) {
      applicant.emailAddress = contactInfo[3].Answer;
    }
  }
  /** imm needs sections data */
  let immediateNeeds = filterData(sections, "about-me").subsections[1].questions;

  let needsHousing = new ImmediateNeeds();

  needsHousing.needTypeCode = immediateNeeds[0].pgrmCode;
  if (
    immediateNeeds[0].Answer !== null &&
    immediateNeeds[0].Answer !== undefined &&
    immediateNeeds[0].Answer !== ""
  ) {
    if (
      immediateNeeds[0].Answer === "Yes" ||
      immediateNeeds[0].Answer === "Sí" ||
      immediateNeeds[0].Answer === "Sim" ||
      immediateNeeds[0].Answer === "是" ||
      immediateNeeds[0].Answer === "Có" ||
      immediateNeeds[0].Answer === "Wi"
    ) {
      needsHousing.answer = TextProps.VALUE_Y;
    } else {
      needsHousing.answer = TextProps.VALUE_N;
    }
  }
  application.needs.push(needsHousing);

  let needsIncome = new ImmediateNeeds();
  needsIncome.needTypeCode = immediateNeeds[1].pgrmCode;
  if (
    immediateNeeds[1].Answer !== null &&
    immediateNeeds[1].Answer !== undefined &&
    immediateNeeds[1].Answer !== ""
  ) {
    if (
      immediateNeeds[1].Answer === "Yes" ||
      immediateNeeds[1].Answer === "Sí" ||
      immediateNeeds[1].Answer === "Sim" ||
      immediateNeeds[1].Answer === "是" ||
      immediateNeeds[1].Answer === "Có" ||
      immediateNeeds[1].Answer === "Wi"
    ) {
      needsIncome.answer = TextProps.VALUE_Y;
    } else {
      needsIncome.answer = TextProps.VALUE_N;
    }
  }
  application.needs.push(needsIncome);

  let needsWorker = new ImmediateNeeds();
  needsWorker.needTypeCode = immediateNeeds[2].pgrmCode;
  if (
    immediateNeeds[2].Answer !== null &&
    immediateNeeds[2].Answer !== undefined &&
    immediateNeeds[2].Answer !== ""
  ) {
    if (
      immediateNeeds[2].Answer === "Yes" ||
      immediateNeeds[2].Answer === "Sí" ||
      immediateNeeds[2].Answer === "Sim" ||
      immediateNeeds[2].Answer === "是" ||
      immediateNeeds[2].Answer === "Có" ||
      immediateNeeds[2].Answer === "Wi"
    ) {
      needsWorker.answer = TextProps.VALUE_Y;
    } else {
      needsWorker.answer = TextProps.VALUE_N;
    }
  }
  application.needs.push(needsWorker);

  //set all accommodation questiona and answers based on application type into Json on submit
  let accommodationInfo = "";
  if (
    applicationType === TextProps.MATCH_SNAP ||
    applicationType === TextProps.MATCH_COMBO
  ) {
    accommodationInfo = filterData(sections, "about-me").subsections[2].questions.filter(
      (data) => data.name === "is-accommondation"
    );
    if (
      accommodationInfo[0].Answer === "Yes" ||
      accommodationInfo[0].Answer === "Sí" ||
      accommodationInfo[0].Answer === "Sim" ||
      accommodationInfo[0].Answer === "是" ||
      accommodationInfo[0].Answer === "Có" ||
      accommodationInfo[0].Answer === "Wi"
    ) {
      application.needAccommotation = TextProps.VALUE_Y;
    } else {
      application.needAccommotation = TextProps.VALUE_N;
    }
    let conditionalQuestion1 =
      accommodationInfo[0].conditionals[0].conditionalQuestions[0];
    let conditionalQuestion2 =
      accommodationInfo[0].conditionals[0].conditionalQuestions[1];
    if (conditionalQuestion1.Answer !== undefined) {
      for (
        let applies = 0;
        applies < conditionalQuestion1.Answer.length;
        applies++
      ) {
        let web_applies = new accommodation();
        if (conditionalQuestion1.Answer.length > 0) {
          if (conditionalQuestion1.Answer[applies] === "7") {
            web_applies.typeCode = conditionalQuestion1.Answer[applies];
            if (
              conditionalQuestion2 &&
              conditionalQuestion2.Answer[applies] !== undefined
            ) {
              web_applies.otherDesc = conditionalQuestion2.Answer;
            }
          } else {
            web_applies.typeCode = conditionalQuestion1.Answer[applies];
          }
          application.accommodation.push(web_applies);
        }
      }
    }
  }
  /** More info sections data */
  let moreInfo = "";
  if (applicationType === TextProps.MATCH_SNAP) {
    moreInfo = filterData(sections, "about-me").subsections[3].questions;
    if (
      moreInfo[0].Answer !== null &&
      moreInfo[0].Answer !== undefined &&
      moreInfo[0].Answer !== ""
    ) {
      if (
        moreInfo[0].Answer === "Yes" ||
        moreInfo[0].Answer === "Sí" ||
        moreInfo[0].Answer === "Sim" ||
        moreInfo[0].Answer === "是" ||
        moreInfo[0].Answer === "Có" ||
        moreInfo[0].Answer === "Wi"
      ) {
        applicant.isUSCitizen = TextProps.VALUE_Y;
      } else {
        applicant.isUSCitizen = TextProps.VALUE_N;
      }
    }
    applicant.languageCodeSpoken = inProgressObj && inProgressObj.status === "INPROG" ? moreInfo[1].Answer : aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[moreInfo[1].Answer];

    /** aboutMe optional sections data*/
    let optionalInfo = filterData(sections, "about-me").subsections[4].questions;
    // let optionalInfo = sections[1] !== undefined?sections[1]. subsections[4] !== undefined ? sections [1]. subsections [4]. questions: null : null

    if (
      optionalInfo[0].Answer !== null &&
      optionalInfo[0].Answer !== undefined &&
      optionalInfo[0].Answer !== ""
    ) {
      let genederOptions = optionalInfo[0].options;
      if (genederOptions[0] === optionalInfo[0].targetValue) {
        applicant.gender = "MALE";
      } else {
        applicant.gender = "FEMALE";
      }
    }
    if (
      optionalInfo[1].Answer === "Decline to answer" ||
      optionalInfo[1].Answer === "DCL" ||
      optionalInfo[1].Answer === "Me niego a responder" ||
      optionalInfo[1].Answer === "Não quero responder" ||
      optionalInfo[1].Answer === "拒绝回答" ||
      optionalInfo[1].Answer === "Từ chối trả lời" ||
      optionalInfo[1].Answer === "Refize reponn"
    ) {
      applicant.ethnicity = "DCL";
    } else {
      applicant.ethnicity = optionalInfo[1].Answer;
    }

    /** race table having multiple types can choose user  */
    if (optionalInfo[2].Answer !== undefined) {
      for (let race = 0; race < optionalInfo[2].Answer.length; race++) {
        let web_race = new Race();
        if (optionalInfo[2].Answer.length > 0) {
          if (
            optionalInfo[2].Answer[race] === "Decline to answer" ||
            optionalInfo[2].Answer[race] === "DCL" ||
            optionalInfo[2].Answer[race] === "Me niego a responder" ||
            optionalInfo[2].Answer[race] === "Não quero responder" ||
            optionalInfo[2].Answer[race] === "拒绝回答" ||
            optionalInfo[2].Answer[race] === "Từ chối trả lời" ||
            optionalInfo[2].Answer[race] === "Refize reponn"
          ) {
            web_race.raceCode = "DCL";
          } else {
            if(optionalInfo[2].Answer[race] === 'OTHER') {
              web_race.otherDesc = optionalInfo[2].conditionals[0].conditionalQuestions[0].Answer;
            }
            web_race.raceCode = optionalInfo[2].Answer[race];
          }
          applicant.race.push(web_race);
        }
      }
    }
  } else if (
    applicationType === TextProps.MATCH_TANF ||
    applicationType === TextProps.MATCH_COMBO
  ) {
    moreInfo = filterData(sections, "about-me").subsections.filter(
      (sub) => sub.id === "more-info"
    )[0].questions;

    /** aboutMe optional sections data*/
    // let optionalInfo = sections[1].subsections[1].questions;
    let optionalInfo = filterData(sections, "about-me").subsections.filter(
      (sub) => sub.id === "more-info"
    )[0].questions;

    let isCitizen = optionalInfo.filter(
      (info) => info.name === "is-citizen"
    )[0];
    if (
      isCitizen.Answer !== null &&
      isCitizen.Answer !== undefined &&
      isCitizen.Answer !== ""
    ) {
      if (
        isCitizen.Answer === "Yes" ||
        isCitizen.Answer === "Sí" ||
        isCitizen.Answer === "Sim" ||
        isCitizen.Answer === "是" ||
        isCitizen.Answer === "Có" ||
        isCitizen.Answer === "Wi"
      ) {
        applicant.isUSCitizen = TextProps.VALUE_Y;
      } else {
        applicant.isUSCitizen = TextProps.VALUE_N;
      }
    }
    let immigrationStatus = isCitizen.conditionals[0].conditionalQuestions.filter(
      (question) => question.name === 'immigration-status'
    )[0]
      
    if (
      immigrationStatus.Answer !== null &&
      immigrationStatus.Answer !== undefined &&
      immigrationStatus.Answer !== ""
    ) {
      if (
        immigrationStatus.Answer === "Yes" ||
        immigrationStatus.Answer === "Sí" ||
        immigrationStatus.Answer === "Sim" ||
        immigrationStatus.Answer === "是" ||
        immigrationStatus.Answer === "Có" ||
        immigrationStatus.Answer === "Wi"
      ) {
        applicant.immigrationStatus = TextProps.VALUE_Y;
      } else {
        applicant.immigrationStatus = TextProps.VALUE_N;
      }
    }

    let languagePreference = moreInfo.filter(
      (info) => info.name === "language-preference"
    );
    if (languagePreference.length) {
      if (
        languagePreference[0].Answer !== null &&
        languagePreference[0].Answer !== undefined &&
        languagePreference[0].Answer !== ""
      ) {
        applicant.languageCodeSpoken = inProgressObj && inProgressObj.status === "INPROG" ? languagePreference[0].Answer : aboutMeMultilanguageLabels(selectedLanguage).LANGUAGES[languagePreference[0].Answer];
      }
    }

    let biologicalSex = moreInfo.filter(
      (info) => info.name === "biological-sex"
    )[0];
    if (
      biologicalSex.Answer !== null &&
      biologicalSex.Answer !== undefined &&
      biologicalSex.Answer !== ""
    ) {
      let genederOptions = biologicalSex.options;
      if (genederOptions[0] === biologicalSex.targetValue) {
        applicant.gender = "MALE";
      } else if(genederOptions[1] === biologicalSex.targetValue){
        applicant.gender = "FEMALE";
      }
    }

    let maritalStatus = moreInfo.filter(
      (info) => info.name === "marital-status"
    )[0];
    if (
      maritalStatus.Answer !== null &&
      maritalStatus.Answer !== undefined &&
      maritalStatus.Answer !== ""
    ) {
      applicant.maritalStatus = maritalStatus.Answer;
      if (maritalStatus.Answer !== maritalStatus.options[0]) {
        let conditionalAnswer = maritalStatus.conditionals.filter(
          (cond) => cond.valueToMatch === maritalStatus.Answer
        )[0];
        if (
          conditionalAnswer && conditionalAnswer.conditionalQuestions[0] &&
          conditionalAnswer.conditionalQuestions[0].Answer
        ) {
          applicant.maritalStatDt = convertDOBObjectToString(
            conditionalAnswer.conditionalQuestions[0].Answer
          );
        }
      }
    }

    let ethnicity = moreInfo.filter((info) => info.name === "ethnicity")[0];
    if (
      ethnicity.Answer === "Decline to answer" ||
      ethnicity.Answer === "DCL" ||
      ethnicity.Answer === "Me niego a responder" ||
      ethnicity.Answer === "Não quero responder" ||
      ethnicity.Answer === "拒绝回答" ||
      ethnicity.Answer === "Từ chối trả lời" ||
      ethnicity.Answer === "Refize reponn"
    ) {
      applicant.ethnicity = "DCL";
    } else {
      applicant.ethnicity = ethnicity.Answer;
    }

    /** race table having multiple types can choose user  */
    let raceInfo = moreInfo.filter((info) => info.name === "race")[0];
    if (
      raceInfo.Answer !== undefined &&
      raceInfo.Answer !== undefined &&
      raceInfo.Answer !== ""
    ) {
      for (let race = 0; race < raceInfo.Answer.length; race++) {
        let web_race = new Race();
        if (raceInfo.Answer.length > 0) {
          if (
            raceInfo.Answer[race] === "Decline to answer" ||
            raceInfo.Answer[race] === "DCL" ||
            raceInfo.Answer[race] === "Me niego a responder" ||
            raceInfo.Answer[race] === "Não quero responder" ||
            raceInfo.Answer[race] === "拒绝回答" ||
            raceInfo.Answer[race] === "Từ chối trả lời" ||
            raceInfo.Answer[race] === "Refize reponn"
          ) {
            web_race.raceCode = "DCL";
          } else {
            let raceIndex =  applicationType === TextProps.MATCH_TANF ? 4: 5;
            if(moreInfo[raceIndex].Answer[race] === 'OTHER') {
              web_race.otherDesc = moreInfo[raceIndex].conditionals[0].conditionalQuestions[0].Answer;
            }
            web_race.raceCode = raceInfo.Answer[race];
          }
          applicant.race.push(web_race);
        }
      }
    }
  }

  /** here going to added no.of house-hold size */
  application.householdSize = filterData(sections, "my-household").questions[0].Answer;

  /** here going to set household members details */
  let houseHolds = filterData(sections, "my-household").subsections;
  if (houseHolds !== undefined) {
    for (let member = 0; member < houseHolds.length - 1; member++) {
      let dependentInfo = houseHolds[member].questions;
      /** creating obj for dependent */
      let dependent = new Person();
      dependent.isGrantee = TextProps.VALUE_N;
      // setting clientId from UI
      dependent.personId = houseHolds[member].clientId;
      /** if user's first, last, middle names is empty than should be taken by title */
      if (
        dependentInfo[0].Answer !== "" &&
        dependentInfo[0].Answer !== undefined
      ) {
        dependent.firstName = dependentInfo[0].Answer;
      } else {
        if (houseHolds[member].title) {
          var fname = houseHolds[member].title;
          fname = fname.split("(");
        }
        dependent.firstName = fname[0];
      }
      dependent.middleName = dependentInfo[1].Answer;
      if (
        dependentInfo[2].Answer !== "" &&
        dependentInfo[2].Answer !== undefined
      ) {
        dependent.lastName = dependentInfo[2].Answer;
      } else {
        if (houseHolds[member].title) {
          var lname = houseHolds[member].title;
          lname = lname.split("(");
        }
        dependent.lastName = lname[0];
      }

      if (
        dependentInfo[3].targetValue ===
        aboutMeMultilanguageLabels(selectedLanguage).suffixOption[0]
      ) {
        dependent.nameSuffix = "I";
      } else if (
        dependentInfo[3].targetValue ===
        aboutMeMultilanguageLabels(selectedLanguage).suffixOption[10]
      ) {
        dependent.nameSuffix = "Jr.";
      } else if (
        dependentInfo[3].targetValue ===
        aboutMeMultilanguageLabels(selectedLanguage).suffixOption[11]
      ) {
        dependent.nameSuffix = "Sr.";
      } else {
        dependent.nameSuffix = dependentInfo[3].targetValue;
      }
      if (
        dependentInfo[5].Answer !== "" &&
        dependentInfo[5].Answer !== undefined &&
        dependentInfo[5].Answer !== null
      ) {
        dependent.birthDate = new Date(dependentInfo[5].Answer);
      }
      if (
        dependentInfo[6].Answer !== "" &&
        dependentInfo[6].Answer !== undefined
      ) {
        let genederOptions = dependentInfo[6].options;
        if (genederOptions[0] === dependentInfo[6].targetValue) {
          dependent.gender = "MALE";
        } else {
          dependent.gender = "FEMALE";
        }
      }
      if (
        dependentInfo[7].Answer !== null &&
        dependentInfo[7].Answer !== undefined &&
        dependentInfo[7].Answer !== "" &&
        dependentInfo[7].name === "is-citizen"
      ) {
        if (
          dependentInfo[7].Answer === "Yes" ||
          dependentInfo[7].Answer === "Sí" ||
          dependentInfo[7].Answer === "Sim" ||
          dependentInfo[7].Answer === "是" ||
          dependentInfo[7].Answer === "Có" ||
          dependentInfo[7].Answer === "Wi"
        ) {
          dependent.isUSCitizen = TextProps.VALUE_Y;
        } else {
          dependent.isUSCitizen = TextProps.VALUE_N;
        }
      }
      
      if(applicationType !=="SNAP") {
        let immigrationStatus =  dependentInfo[7].conditionals[0].conditionalQuestions.filter(
          (question) => question.name === 'immigration-status'
        )[0]
          
        if (
          immigrationStatus.Answer !== null &&
          immigrationStatus.Answer !== undefined &&
          immigrationStatus.Answer !== ""
        ) {
          if (
            immigrationStatus.Answer === "Yes" ||
            immigrationStatus.Answer === "Sí" ||
            immigrationStatus.Answer === "Sim" ||
            immigrationStatus.Answer === "是" ||
            immigrationStatus.Answer === "Có" ||
            immigrationStatus.Answer === "Wi"
          ) {
            dependent.immigrationStatus = TextProps.VALUE_Y;
          } else {
            dependent.immigrationStatus = TextProps.VALUE_N;
          }
        }
      }
      dependent.relationshipCode = dependentInfo[4].Answer;
      //SSN
      let ssnFeild = dependentInfo.filter((dep) => dep.name === "ssn")[0];
      if (ssnFeild) dependent.SSN = ssnFeild.Answer;
      //race
      let raceFeild = dependentInfo.filter((dep) => dep.name === "race")[0];
      if (raceFeild && raceFeild.Answer !== undefined) {
        for (let race = 0; race < raceFeild.Answer.length; race++) {
          let web_race = new Race();
          if (raceFeild.Answer.length > 0) {
            if (
              raceFeild.Answer[race] === "Decline to answer" ||
              raceFeild.Answer[race] === "DCL" ||
              raceFeild.Answer[race] === "Me niego a responder" ||
              raceFeild.Answer[race] === "Não quero responder"
            ) {
              web_race.raceCode = "DCL";
            } else {
              web_race.raceCode = raceFeild.Answer[race];
            }
            dependent.race.push(web_race);
          }
        }
      }
      //ethnicity
      let ethnicityFeild = dependentInfo.filter(
        (dep) => dep.name === "ethnicity"
      )[0];
      if (ethnicityFeild && (ethnicityFeild.Answer === "Decline to answer" ||
      ethnicityFeild.Answer === "Me niego a responder" ||
      ethnicityFeild.Answer === "Não quero responder" ||
      ethnicityFeild.Answer === "DCL" ||
      ethnicityFeild.Answer === "拒绝回答" ||
      ethnicityFeild.Answer === "Từ chối trả lời" ||
      ethnicityFeild.Answer === "Refize reponn")) {
        dependent.ethnicity = "DCL";
      } else {
        dependent.ethnicity = ethnicityFeild.Answer;
      }
      /** push / adding to no.of dependents details in applicant wise */
      applicant.household.push(dependent);
    }
  }

  /** checking how many members having disability */
  let houseHoldsDisabilities = filterData(sections, "my-household").subsections;
  if (houseHoldsDisabilities !== undefined) {
    let houseHoldDetails =
      houseHoldsDisabilities[houseHoldsDisabilities.length - 1].questions;
    // seeting disability to grantee
    if (houseHoldDetails !== undefined) {
      if (
        houseHoldDetails[0].options.length &&
        houseHoldDetails[0].options[0].optionValue === "GRANTEE"
      ) {
        if (
          houseHoldDetails[0].Answer !== undefined &&
          houseHoldDetails[0].Answer !== ""
        ) {
          if (houseHoldDetails[0].Answer[0] === applicant.personId) {
            applicant.hasDisability = TextProps.VALUE_Y;
          }
        }
      }
      if (
        applicant.household.length > 0 &&
        houseHoldDetails[0].Answer !== undefined
      ) {
        for (
          let applicantDep = 0;
          applicantDep < houseHoldDetails[0].Answer.length;
          applicantDep++
        ) {
          for (
            let houseHold = 0;
            houseHold < applicant.household.length;
            houseHold++
          ) {
            if (
              houseHoldDetails[0].Answer[applicantDep] ===
              applicant.household[houseHold].personId
            ) {
              applicant.household[houseHold].hasDisability = TextProps.VALUE_Y;
            }
          }
        }
      }
    }
  }
 
  /** Resources data binding */
  if (
    applicationType === TextProps.MATCH_SNAP ||
    applicationType === TextProps.MATCH_COMBO
  ) {
    let resourcesMembers = filterData(sections, "resources").subsections;
    const noResourcesMembers = filterData(sections, "resources");
    if (resourcesMembers !== undefined) {
      if (resourcesMembers.length > 1) {
        for (let resource = 0; resource < resourcesMembers.length - 1; resource++) {
          if (resourcesMembers.length >= 0) {
            if (
              resourcesMembers[resource].questions[0].Answer !== undefined &&
              resourcesMembers[resource].questions[0].Answer !== ""
            ) {
              let selectedBenefit = resourcesMembers[resource].questions[0].Answer;
              if (
                resourcesMembers[resource].subsections !== undefined &&
                resourcesMembers[resource].subsections.length > 0
              ) {
                let types = resourcesMembers[resource].subsections[0].questions;
                for (let incAns = 0; incAns < selectedBenefit.length; incAns++) {
                  let resourcesObj = new Resources();
                  for (
                    let incomeType = 0;
                    incomeType < types.length;
                    incomeType++
                  ) {
                    if (
                      types[incomeType].valueToMatch === selectedBenefit[incAns]
                    ) {
                      let typeOfWage = types[incomeType];
                      let whichTable = typeOfWage.valueToMatch;
                      let tableName = whichTable.split("-");
                      let actionName = typeOfWage.name;
                      let actionSplit = actionName.split("-");
                      let finalString = actionSplit.join("-");
                        if (typeOfWage.Answer !== undefined  ||
                          typeOfWage.Answer.length === 0) {
                          resourcesObj.resourceType = tableName[0];
                          for (
                            let repeatAnswer = 0;
                            repeatAnswer < typeOfWage.Answer.length;
                            repeatAnswer++
                          ) {
                            let resourceDetailsObj = new ResourceDetails();
                            switch(tableName[0]){
                              case "CASH": case "TRUST": case "PPDFUN": case "OTHER":
                                resourceDetailsObj.amount = parseInt(typeOfWage.Answer[repeatAnswer].amount);
                                resourceDetailsObj.additionalInfo = typeOfWage.Answer[repeatAnswer].additionalInformation;
                              break;
                              case "SAVING": case "CHECK":
                                resourceDetailsObj.amount = parseInt(typeOfWage.Answer[repeatAnswer].amount);
                                resourceDetailsObj.bankBrokerageName = typeOfWage.Answer[repeatAnswer].bankName;
                                resourceDetailsObj.accountNumber = typeOfWage.Answer[repeatAnswer].accountnumber;
                                if(typeOfWage.Answer[repeatAnswer].jointAccount === resourcesSectionMultilanguageLabels(selectedLanguage).optionYesNo[0]){
                                  resourceDetailsObj.isJointAccount = TextProps.VALUE_Y;
                                }else if(typeOfWage.Answer[repeatAnswer].jointAccount === resourcesSectionMultilanguageLabels(selectedLanguage).optionYesNo[1]){
                                  resourceDetailsObj.isJointAccount = TextProps.VALUE_N;
                                }else{
                                  resourceDetailsObj.isJointAccount = null;
                                }
                                resourceDetailsObj.jointAcntHolderName = typeOfWage.Answer[repeatAnswer].jointAccountHolder;
                              break;
                              case "STOCKS": case "BONDS": case "RETMNT":
                                resourceDetailsObj.amount = parseInt(typeOfWage.Answer[repeatAnswer].amount);
                                resourceDetailsObj.bankBrokerageName = typeOfWage.Answer[repeatAnswer].bankName;
                              break;
                              default:
                              break;
                            }
                            resourcesObj.resourceDetails.push(resourceDetailsObj);
                          }
                        }
                    }
                  }                          
                  if (
                    applicant.personId ===
                    resourcesMembers[resource].clientId
                  ) {
                    applicant.resources.push(resourcesObj);
                    applicant.hasResourcesFlg = TextProps.VALUE_Y;
                  } else {
                    for (
                      let houseMember = 0;
                      houseMember < applicant.household.length;
                      houseMember++
                    ) {
                      if (
                        applicant.household[houseMember].personId ===
                        resourcesMembers[resource].clientId
                      ) {
                        applicant.household[houseMember].hasResourcesFlg = TextProps.VALUE_Y;
                        applicant.household[houseMember].resources.push(
                          resourcesObj
                        );
                      }
                    }
                  }
                }
              } else if (
                resource === 0 &&
                resourcesMembers[resource].questions[0].Answer[resource] === "None"
              ) {
                applicant.resources = [
                  {
                    resourceType: "None",
                  },
                ];
              }
            }
          }
        }
      } else if (
        noResourcesMembers.questions[0].Answer &&
        ( noResourcesMembers.questions[0].Answer === "" ||
          noResourcesMembers.questions[0].Answer[0] === "No resources to report" ||
          noResourcesMembers.questions[0].Answer[0] === "No hay recursos para informar")
      ) {
        let noResources
        applicant.hasResourcesFlg = TextProps.VALUE_N;
        for (
          let houseMember = 0;
          houseMember < applicant.household.length;
          houseMember++
        ) {
            applicant.household[houseMember].hasResourcesFlg = TextProps.VALUE_N;
        }
      } else if (
        noResourcesMembers.questions[0].Answer &&
        ( noResourcesMembers.questions[0].Answer === "" ||
          noResourcesMembers.questions[0].Answer[0] === "Decline to answer" ||
          noResourcesMembers.questions[0].Answer[0] === "Negarse a contestar")
      ) {
        let noResources
        applicant.hasResourcesFlg = TextProps.VALUE_D;
        for (
          let houseMember = 0;
          houseMember < applicant.household.length;
          houseMember++
        ) {
            applicant.household[houseMember].hasResourcesFlg = TextProps.VALUE_D;
        }
      }
    }
  }
  /** Here income releated data binding */
  let incomeMembers = filterData(sections, "income").subsections;
  const noIncomeMembers = filterData(sections, "income");
  if (incomeMembers !== undefined) {
    if (incomeMembers.length > 1) {
      let hhNum;
      for (let income = 0; income < incomeMembers.length - 1; income++) {
        if (incomeMembers.length >= 0) {
          // hasIncome flag is set to TextProps.VALUE_TRUE when any member receive's income or benefits
          applicant.hasIncome = TextProps.VALUE_Y;
          if (
            incomeMembers[income].questions[0].Answer !== undefined &&
            incomeMembers[income].questions[0].Answer !== ""
          ) {
            let selectedBenefit = incomeMembers[income].questions[0].Answer;
            if (
              incomeMembers[income].subsections !== undefined &&
              incomeMembers[income].subsections.length > 0
            ) {
              let types = incomeMembers[income].subsections[0].questions;
              for (let incAns = 0; incAns < selectedBenefit.length; incAns++) {
                for (
                  let incomeType = 0;
                  incomeType < types.length;
                  incomeType++
                ) {
                  if (
                    types[incomeType].valueToMatch === selectedBenefit[incAns]
                  ) {
                    let typeOfWage = types[incomeType];
                    let whichTable = typeOfWage.valueToMatch;
                    let tableName = whichTable.split("-");
                    let actionName = typeOfWage.name;
                    var actionSplit = actionName.split("-");

                    let removeIndex = actionSplit.splice(-1, 1).join();
                    var finalString = actionSplit.join("-");
                    if ("rental-income-hours-worked" === finalString) {
                      /** no need create object for rental income hours
                                         re-using applicant's income line item obj and find line item setting to rental hours */
                      if (
                        applicant.income.length > 0 &&
                        applicant.income !== undefined
                      ) {
                        for (
                          let rental = 0;
                          rental < applicant.income.length;
                          rental++
                        ) {
                          if (
                            applicant.income[rental].otherIncomeTypeCode ===
                            tableName[1]
                          ) {
                            let isRentalIncome;
                            if (
                              applicant.personId ===
                              incomeMembers[income].clientId
                            ) {
                              isRentalIncome = applicant.income;
                            }

                            if (
                              isRentalIncome !== undefined &&
                              isRentalIncome.length > 0
                            ) {
                              if (
                                isRentalIncome[rental].key !== "" &&
                                isRentalIncome[rental].key !== undefined
                              ) {
                                /** by passing key value setting to rental hours each rental line item */
                                if (
                                  isRentalIncome[rental].key <
                                  typeOfWage.Answer.length
                                ) {
                                  isRentalIncome[rental].hours =
                                    typeOfWage.Answer[
                                      isRentalIncome[rental].key
                                    ].amount;
                                  var freqCode =
                                    typeOfWage.Answer[
                                      isRentalIncome[rental].key
                                    ].unit;
                                  if (/\s/g.test(freqCode)) {
                                    freqCode = freqCode.replace(/ /g, "");
                                  }
                                  isRentalIncome[rental].hoursfrequencyCode =
                                    frequencyCode.getfrequencyCode[freqCode];
                                }
                              }
                            }
                          }
                        }
                      }
                      if (applicant.household.length > 0) {
                        if (
                          applicant.household[hhNum] !== undefined &&
                          applicant.household[hhNum].income !== undefined &&
                          applicant.household[hhNum].income.length > 0
                        ) {
                          for (
                            let incm = 0;
                            incm < applicant.household[hhNum].income.length;
                            incm++
                          ) {
                            if (
                              applicant.household[hhNum].income[incm]
                                .otherIncomeTypeCode === tableName[1]
                            ) {
                              let isHoseHoldRentalIncome;
                              if (
                                applicant.household[hhNum].income !==
                                  undefined &&
                                applicant.household[hhNum].income.length > 0
                              ) {
                                isHoseHoldRentalIncome =
                                  applicant.household[hhNum].income;
                              }

                              if (
                                isHoseHoldRentalIncome !== undefined &&
                                isHoseHoldRentalIncome.length > 0
                              ) {
                                if (
                                  isHoseHoldRentalIncome[incm].key !== "" &&
                                  isHoseHoldRentalIncome[incm].key !== undefined
                                ) {
                                  if (
                                    isHoseHoldRentalIncome[incm].key <
                                    typeOfWage.Answer.length
                                  ) {
                                    isHoseHoldRentalIncome[incm].hours =
                                      typeOfWage.Answer[
                                        isHoseHoldRentalIncome[incm].key
                                      ].amount;
                                    let freqCode =
                                      typeOfWage.Answer[
                                        isHoseHoldRentalIncome[incm].key
                                      ].unit;
                                    if (/\s/g.test(freqCode)) {
                                      freqCode = freqCode.replace(/ /g, "");
                                    }
                                    isHoseHoldRentalIncome[
                                      incm
                                    ].hoursfrequencyCode =
                                      frequencyCode.getfrequencyCode[freqCode];
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    } else {
                      let incomeObj = new Income();
                      if (
                        typeOfWage.Answer === undefined ||
                        typeOfWage.Answer.length === 0
                      ) {
                        if (tableName[0] === "EI") {
                          incomeObj.wageTypeCode = tableName[1];
                        } else if (
                          tableName[0] === "UI" &&
                          (tableName[1] === "RENTAL" || tableName[0] === "UI")
                        ) {
                          incomeObj.otherIncomeTypeCode = tableName[1];
                        }
                        if (
                          applicant.personId === incomeMembers[income].clientId
                        ) {
                          applicant.income.push(incomeObj);
                        } else {
                          for (
                            let houseMember = 0;
                            houseMember < applicant.household.length;
                            houseMember++
                          ) {
                            if (
                              applicant.household[houseMember].personId ===
                              incomeMembers[income].clientId
                            ) {
                              applicant.household[houseMember].income.push(
                                incomeObj
                              );
                            }
                          }
                        }
                      }
                      if (typeOfWage.Answer !== undefined) {
                        for (
                          let repeatAnswer = 0;
                          repeatAnswer < typeOfWage.Answer.length;
                          repeatAnswer++
                        ) {
                          let incomeObj = new Income();
                          if (
                            tableName[0] === "EI" &&
                            typeOfWage.Answer !== undefined
                          ) {
                            if (isNone(typeOfWage.Answer)) {
                              incomeObj.grossIncome = "";
                              incomeObj.frequencyCode = "";
                              incomeObj.wageTypeCode = tableName[1];
                            } else {
                              incomeObj.grossIncome =
                                typeOfWage.Answer[repeatAnswer].amount;
                              let freqCode =
                                typeOfWage.Answer[repeatAnswer].unit;
                              if (/\s/g.test(freqCode)) {
                                freqCode = freqCode.replace(/ /g, "");
                              }
                              incomeObj.frequencyCode =
                                frequencyCode.getfrequencyCode[freqCode];
                              incomeObj.wageTypeCode = tableName[1];
                            }
                          } else if (
                            tableName[0] === "UI" &&
                            tableName[1] === "RENTAL" &&
                            typeOfWage.Answer !== undefined
                          ) {
                            if (isNone(typeOfWage.Answer)) {
                              incomeObj.grossIncome = "";
                              incomeObj.frequencyCode = "";
                              incomeObj.otherIncomeTypeCode = tableName[1];
                              incomeObj.hours = "";
                            } else {
                              if (
                                typeOfWage.repeatedQuestion.isMoney ===
                                TextProps.VALUE_TRUE
                              ) {
                                incomeObj.grossIncome =
                                  typeOfWage.Answer[repeatAnswer].amount;
                                let freqCode =
                                  typeOfWage.Answer[repeatAnswer].unit;
                                if (/\s/g.test(freqCode)) {
                                  freqCode = freqCode.replace(/ /g, "");
                                }
                                incomeObj.frequencyCode =
                                  frequencyCode.getfrequencyCode[freqCode];
                                incomeObj.otherIncomeTypeCode = tableName[1];
                                /** setting key as a which rental income for mapping hours */
                                incomeObj.key = repeatAnswer;
                              } else {
                                // nothing do here for rental income if hours working
                              }
                            }
                          } else if (
                            tableName[0] === "UI" &&
                            typeOfWage.Answer !== undefined
                          ) {
                            if (isNone(typeOfWage.Answer)) {
                              incomeObj.grossIncome = "";
                              incomeObj.frequencyCode = "";
                              incomeObj.otherIncomeTypeCode = tableName[1];
                            } else {
                              incomeObj.grossIncome =
                                typeOfWage.Answer[repeatAnswer].amount;
                              let freqCode =
                                typeOfWage.Answer[repeatAnswer].unit;
                              if (/\s/g.test(freqCode)) {
                                freqCode = freqCode.replace(/ /g, "");
                              }
                              incomeObj.frequencyCode =
                                frequencyCode.getfrequencyCode[freqCode];
                              incomeObj.otherIncomeTypeCode = tableName[1];
                            }
                          }
                          if (
                            applicant.personId ===
                            incomeMembers[income].clientId
                          ) {
                            applicant.income.push(incomeObj);
                            hhNum = "";
                          } else {
                            for (
                              let houseMember = 0;
                              houseMember < applicant.household.length;
                              houseMember++
                            ) {
                              if (
                                applicant.household[houseMember].personId ===
                                incomeMembers[income].clientId
                              ) {
                                applicant.household[houseMember].income.push(
                                  incomeObj
                                );
                                hhNum = houseMember;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else if (
              income === 0 &&
              incomeMembers[income].questions[0].Answer[income] === "None"
            ) {
              applicant.income = [
                {
                  otherIncomeTypeCode: "None",
                  wageTypeCode: "",
                },
              ];
            }
          }
        }
      }
      for (
        let householdMembersIncome = 0;
        householdMembersIncome < applicant.household.length;
        householdMembersIncome++
      ) {
        if (applicant.household[householdMembersIncome].income.length === 0) {
          applicant.household[householdMembersIncome].income = [
            {
              otherIncomeTypeCode: "None",
              wageTypeCode: "",
            },
          ];
        }
      }
    } else if (
      noIncomeMembers.questions[0].Answer &&
      (noIncomeMembers.questions[0].Answer === "" ||
        noIncomeMembers.questions[0].Answer[0] === "No one" ||
        noIncomeMembers.questions[0].Answer[0] === "Pesonn")
    ) {
      applicant.hasIncome = TextProps.VALUE_N;
    }
  }
  if (
    applicationType === TextProps.MATCH_SNAP ||
    applicationType === TextProps.MATCH_COMBO
  ) {
    /** here going to expenses  */

    //Household Expense Section
    let householdExpenseSection = filterData(sections, "expenses").subsections[0];

    let utilityAnswer = householdExpenseSection.questions[0].Answer;
    application.payHeat = "N";
    application.payAC = "N";
    application.payACFee = "N";
    application.elgas = "N";
    application.hasPhone = "N";
    application.waterSwgFlg = "N";
    application.garbageFlg = "N";

    if (utilityAnswer !== undefined) {
      if (typeof utilityAnswer !== "string") {
        utilityAnswer.forEach((exp) => {
          switch (exp) {
            case "payHeat":
              application.payHeat = TextProps.VALUE_Y;
              break;
            case "payAC":
              application.payAC = TextProps.VALUE_Y;
              break;
            case "payACFee":
              application.payACFee = TextProps.VALUE_Y;
              break;
            case "elgas":
              application.elgas = TextProps.VALUE_Y;
              break;
            case "hasPhone":
              application.hasPhone = TextProps.VALUE_Y;
              break;
            case "waterSwgFlg":
              application.waterSwgFlg = TextProps.VALUE_Y;
              break;
            case "garbageFlg":
              application.garbageFlg = TextProps.VALUE_Y;
              break;
            case "None":
              application.utilCost = "None";
              break;
            default:
              break;
          }
        });
      }
    }
    let utlOtherPayerAnswer = householdExpenseSection.questions[1];
    let shelterOtherPayerAnswer = householdExpenseSection.questions[3];
    if(utlOtherPayerAnswer && utlOtherPayerAnswer.Answer && utlOtherPayerAnswer.Answer === TextProps.VALUE_YES){
      application.utlOtherPayer = TextProps.VALUE_Y;
      application.utlPayerName = utlOtherPayerAnswer.conditionals[0].conditionalQuestions[0].Answer;
    }
    if(shelterOtherPayerAnswer && shelterOtherPayerAnswer.Answer && shelterOtherPayerAnswer.Answer === TextProps.VALUE_YES){
      application.shelterOtherPayer = TextProps.VALUE_Y;
      application.shelterPayerName = shelterOtherPayerAnswer.conditionals[0].conditionalQuestions[0].Answer;
    }
    if (householdExpenseSection.subsections !== undefined) {
      let houseTypeQuestions = householdExpenseSection.subsections[0].questions;
      let typeQuestionsAnswers = householdExpenseSection.questions[2].Answer;
      let s_expense = [];
      for (let shltr = 0; shltr < houseTypeQuestions.length; shltr++) {
        if (typeQuestionsAnswers !== undefined) {
          for (let isAns = 0; isAns < typeQuestionsAnswers.length; isAns++) {
            if (
              typeQuestionsAnswers[isAns] !== undefined &&
              typeQuestionsAnswers[isAns] !== ""
            ) {
              if (
                houseTypeQuestions[shltr].valueToMatch ===
                typeQuestionsAnswers[isAns]
              ) {
                if (
                  houseTypeQuestions[shltr].Answer === undefined ||
                  houseTypeQuestions[shltr].Answer === ""
                ) {
                  var s_exp = new ShelterExpense();
                  s_exp.householdExpenseTypeCode =
                    houseTypeQuestions[shltr].valueToMatch;
                  s_expense.push(s_exp);
                } else {
                  if (houseTypeQuestions[shltr].Answer !== undefined) {
                    let answer = houseTypeQuestions[shltr].Answer;

                    for (
                      var multAmount = 0;
                      multAmount < answer.length;
                      multAmount++
                    ) {
                      var hs_exp = new ShelterExpense();
                      hs_exp.householdExpenseTypeCode =
                        houseTypeQuestions[shltr].valueToMatch;
                      if (isNone(answer)) {
                        hs_exp.amount = "";
                        hs_exp.frequencyCode = "";
                      } else {
                        hs_exp.amount = answer[multAmount].amount;
                        let freqCode = answer[multAmount].unit;
                        if (/\s/g.test(freqCode)) {
                          freqCode = freqCode.replace(/ /g, "");
                        }
                        hs_exp.frequencyCode =
                          frequencyCode.getfrequencyCode[freqCode];
                      }
                      s_expense.push(hs_exp);
                    }
                  }
                }
              }
            }
          }
        }
      }
      application.shelterExpenses = s_expense;
    } else if (householdExpenseSection.questions[1].Answer && householdExpenseSection.questions[1].Answer[0] === "None") {
      application.shelterExpenses = [{ householdExpenseTypeCode: "None" }];
    }

    //Child Dependent Care Expenses***
    let childDependentCare = filterData(sections, "expenses").subsections[1];

    let childDependentCareAnswer = childDependentCare.questions[0];
    let childDepAnswers = childDependentCareAnswer.Answer;
    let depsubsec = 0;
    var inc = 0;

    if (childDependentCareAnswer !== undefined) {
      if (childDepAnswers !== undefined) {
        let childDepCond = childDependentCareAnswer.conditionals;
        for (
          let childDepCondQues = 0;
          childDepCondQues < childDepCond.length;
          childDepCondQues++
        ) {
          let childDepCondQuestions =
            childDepCond[childDepCondQues].conditionalQuestions;
          if (
            childDepCondQuestions[0] !== "" &&
            childDepCondQuestions[0] !== undefined
          ) {
            let childDepCondAnswer = childDepCondQuestions[0].Answer;
            if (childDepCondAnswer !== undefined && childDepCondAnswer !== "") {
              for (
                let childCond = 0;
                childCond < childDepCondAnswer.length;
                childCond++
              ) {
                let childDepSubSections = childDependentCare.subsections;
                let dep_care = new CareExpense();
                let transportExpense = [];

                // outside household memeber name
                dep_care.outHHName = childDependentCare.questions[1].Answer;
                dep_care.clientId = childDepCond[childDepCondQues].valueToMatch;
                dep_care.dependentId = childDepCondAnswer[childCond];
                if (
                  childDepSubSections !== undefined &&
                  childDepSubSections.length > 0
                ) {
                  if (childDepSubSections[depsubsec] !== undefined) {
                    if (
                      childDepSubSections[depsubsec].questions !== undefined
                    ) {
                      let questionValues =
                        childDepSubSections[depsubsec].questions;

                      for (let ques = 0; ques < questionValues.length; ques++) {
                        let questionAnswer = questionValues[ques].Answer;
                        if (
                          questionAnswer !== undefined &&
                          questionAnswer !== ""
                        ) {
                          dep_care.providerCareAmount = questionAnswer.amount;
                          if (isNone(questionAnswer.unit)) {
                            dep_care.providerCareFrequencyCode = "";
                          } else {
                            let freqCode = questionAnswer.unit;
                            if (/\s/g.test(freqCode)) {
                              freqCode = freqCode.replace(/ /g, "");
                            }
                            dep_care.providerCareFrequencyCode =
                              frequencyCode.getfrequencyCode[freqCode];
                          }
                        }
                        ques = ques + 1;
                        questionAnswer = questionValues[ques].Answer;
                        if (
                          questionAnswer === "Yes" ||
                          questionAnswer === "Sí" ||
                          questionAnswer === "Sim" ||
                          questionAnswer === "是" ||
                          questionAnswer === "Có" ||
                          questionAnswer === "Wi"
                        ) {
                          dep_care.transportationPayFlag = TextProps.VALUE_Y;
                          let transportDetails =
                            questionValues[ques].conditionals[0]
                              .conditionalQuestions[0].Answer;
                          dep_care.transportationAmount =
                            transportDetails.amount;
                          if (isNone(transportDetails.unit)) {
                            dep_care.transportationFrequencyCode = "";
                          } else {
                            let freqCode = transportDetails.unit;
                            if (/\s/g.test(freqCode)) {
                              freqCode = freqCode.replace(/ /g, "");
                            }
                            dep_care.transportationFrequencyCode =
                              frequencyCode.getfrequencyCode[freqCode];
                          }
                        } else
                          dep_care.transportationPayFlag = TextProps.VALUE_N;
                        ques = ques + 1;
                        questionAnswer = questionValues[ques].Answer;
                        if (
                          questionAnswer === "Yes" ||
                          questionAnswer === "Sí" ||
                          questionAnswer === "Sim" ||
                          questionAnswer === "是" ||
                          questionAnswer === "Có" ||
                          questionAnswer === "Wi"
                        ) {
                          if (
                            childDepSubSections[depsubsec].subsections !==
                            undefined
                          ) {
                            let subQues =
                              childDepSubSections[depsubsec].subsections;

                            for (
                              let addObj = 0;
                              addObj < subQues.length;
                              addObj++
                            ) {
                              let addressObj = new Address();
                              let dep_care_transport =
                                new CareTransportExpense();
                              let providerValues = subQues[addObj].questions[0];
                              let providerVal = providerValues.Answer
                              let addressValues = subQues[addObj].questions[1];
                              let addressObjVal = addressValues.Answer;
                              let providerAmount = subQues[addObj].questions[2];
                              providerAmount.Answer.unit = frequencyCode.getfrequencyCode[providerAmount.Answer.unit];
                              let providerAmountVal = providerAmount.Answer;
                              addressObj.addressType = "CARE";
                              if (
                                addressObjVal !== undefined &&
                                addressObjVal !== ""
                              ) {
                                addressObj.city = addressObjVal.city;
                                addressObj.street =
                                  addressObjVal.addressLine1;
                                  addressObj.aptOrUnit = addressObjVal.addressLine2;
                                addressObj.state = addressObjVal.state;
                                addressObj.postal = addressObjVal.zipCode;
                                addressObj.isSameMailAndPhysical = "N";
                              }

                              let transportValues =
                                subQues[addObj].questions[1];
                              let transportAns = transportValues.Answer;
                              if (
                                transportAns !== undefined &&
                                transportAns !== ""
                              ) {
                                dep_care_transport.driveTripCount =
                                  transportAns.amount;

                                if (isNone(transportAns.unit)) {
                                  dep_care_transport.driveFrequencyCode = "";
                                } else {
                                  let freqCode = transportAns.unit;
                                  if (/\s/g.test(freqCode)) {
                                    freqCode = freqCode.replace(/ /g, "");
                                  }
                                  dep_care_transport.driveFrequencyCode =
                                    frequencyCode.getfrequencyCode[freqCode];
                                }
                              }
                              dep_care_transport.providerName = providerVal;
                              dep_care_transport.providerAmountVal = providerAmountVal
                              dep_care_transport.address = addressObj;                             
                              transportExpense.push(dep_care_transport);
                              dep_care.transportExpenses = transportExpense;
                            }
                          }
                        }
                        depsubsec = depsubsec + 1;
                      }
                    }
                  }
                }
                if (applicant.personId == dep_care.clientId) {
                  applicant.dependentCareExpense.push(dep_care);
                } else {
                  applicant.household[inc].dependentCareExpense.push(dep_care);
                }
              }
            }
          }
          if (
            !(
              applicant.personId === childDepCond[childDepCondQues].valueToMatch
            )
          ) {
            inc = inc + 1;
          }
        }
        // }
      }
    }

    //Child Support Expenses
    let childSupportExpenses = filterData(sections, "expenses").subsections[2];
    let childSupportAnswer = childSupportExpenses.questions[0].Answer;
    let supportConditionalQuestions =
      childSupportExpenses.questions[0].conditionals;
    let ChildSupport = [];

    if (
      typeof childSupportAnswer !== "string" &&
      childSupportAnswer !== undefined
    ) {
      //Itrating Answers
      childSupportAnswer.forEach((answer) => {
        //Itrating All Questions
        supportConditionalQuestions.forEach((question) => {
          let conAnswer = question.conditionalQuestions[0].Answer;
          let forWhomPaid = question.conditionalQuestions[1].Answer;
          let toWhomPaid = question.conditionalQuestions[2].Answer;
          if (question.valueToMatch === answer) {
            let sup_exp = new SupportExpense();
            sup_exp.supportExpenseTypeCode = "CHDSPT";
            sup_exp.amount = conAnswer.amount;
            if (isNone(conAnswer.unit)) {
              sup_exp.amount = "";
              sup_exp.frequencyCode = "";
            } else {
              var freqCode = conAnswer.unit;
              if (/\s/g.test(freqCode)) {
                freqCode = freqCode.replace(/ /g, "");
              }
              sup_exp.frequencyCode = frequencyCode.getfrequencyCode[freqCode];
            }
            sup_exp.clientId = question.valueToMatch;
            sup_exp.toWhomPaid = toWhomPaid;
            sup_exp.forWhomPaid = forWhomPaid;

            ChildSupport.push(sup_exp);
            if (applicant.personId == sup_exp.clientId) {
              applicant.supportExpenses.push(sup_exp);
            } else {
              for (
                let medMember = 0;
                medMember < applicant.household.length;
                medMember++
              ) {
                if (
                  applicant.household[medMember].personId ===
                    sup_exp.clientId &&
                  applicant.household[medMember].isGrantee === "N"
                ) {
                  applicant.household[medMember].supportExpenses.push(sup_exp);
                }
              }
            }
          }
        });
      });
    }

    /** here medical costs expense binding data */
    let medicalCosts = filterData(sections, "expenses").subsections[3];
    if (medicalCosts !== undefined) {
      applicant.medicalExpenses={};
      
      let medExpKeys = _.cloneDeep(medicalExpenseKeys);
      let medicalMembersCount = medicalCosts.questions;
      if (medicalMembersCount[0].Answer !== undefined) {
        let medicalMembers = medicalMembersCount[0].Answer;
        for (
          let medicalMember = 0;
          medicalMember < medicalMembers.length;
          medicalMember++
        ) {
          let transportDetails=[];
          let medicalExpObject = _.cloneDeep(medicalExpenseObj);
          let med_exp = new MedicalExpense();
          let med_transport_details = new MedicalTransportDetails();
          med_exp.clientId = medicalMembers[medicalMember];
          let medicalExpenseTypeSubsections = medicalCosts.subsections[medicalMember].subsections[0];
          let medicalExpenseTypeQuestions = medicalCosts.subsections[medicalMember].subsections[0].questions;

          let MedicalExpenseOtherQuestion = medicalCosts.subsections[medicalMember].questions && medicalCosts.subsections[medicalMember].questions.length>1 && medicalCosts.subsections[medicalMember].questions[1];

          let medicalSubsections = medicalCosts.subsections[medicalMember].subsections[0].subsections[0];
          let medicalSubsectionsAns = medicalSubsections.questions[0].Answer;
          let MedicalSubsectionDriveAnswer = medicalSubsections.questions[0].conditionals[0].conditionalQuestions[0].Answer;

          if(medicalExpenseTypeSubsections !== undefined){
            medicalExpenseTypeQuestions && medicalExpenseTypeQuestions.length>0 && medicalExpenseTypeQuestions.forEach((oneQuestion)=>{
                let flag = medExpKeys[oneQuestion.name].flag;
                let amount = medExpKeys[oneQuestion.name].amount;
                let unit = medExpKeys[oneQuestion.name].unit;
                if(oneQuestion.name === "OTHER" && MedicalExpenseOtherQuestion){
                  medicalExpObject['otherDesc'] = MedicalExpenseOtherQuestion.Answer;
                }
                medicalExpObject[flag]="Y";
                medicalExpObject[amount]=Number(oneQuestion.Answer.amount);
                medicalExpObject[unit]=frequencyCode.getfrequencyCode[oneQuestion.Answer.unit];
            })
          }
          med_exp.medicalExpDtls = medicalExpObject;
          if (medicalSubsectionsAns !== undefined) {
            let answer = medicalSubsectionsAns;
            if (
              answer === TextProps.VALUE_YES ||
              answer === "Sí" ||
              answer === "Sim" ||
              answer === "是" ||
              answer === "Có" ||
              answer === "Wi"
            ) {
              //Medical Appointments  Answer Binding
              med_exp.drivetoApptPharmFlag = "Y";
              med_exp.drivetoApptPharmCount = MedicalSubsectionDriveAnswer ? MedicalSubsectionDriveAnswer : 0;
              // Medical Expense Transport Yes Answer Binding
              if (medicalSubsections !== undefined) {
            for (
              let med_sub_address = 0;
                  med_sub_address < medicalSubsections.subsections.length;
              med_sub_address++
            ) {
              let membersQuestions =
                    medicalSubsections.subsections[med_sub_address].questions;
              if (
                membersQuestions[0].Answer !== undefined &&
                membersQuestions[0].Answer !== ""
              ) {
                if (isNone(membersQuestions[0].Answer)) {
                  // no need to create address object
                } else {
                  let addressObj = new Address();
                  addressObj.addressType = "PHAR";
                  addressObj.street =
                    membersQuestions[0].Answer.addressLine1;
                    addressObj.aptOrUnit = membersQuestions[0].Answer.addressLine2;
                  addressObj.city = membersQuestions[0].Answer.city;
                  addressObj.state = membersQuestions[0].Answer.state;
                  addressObj.postal = membersQuestions[0].Answer.zipCode;
                  addressObj.countyCode = membersQuestions[0].Answer.countyCode;
                  addressObj.isSameMailAndPhysical = "N";
                      med_transport_details.address = addressObj;
                }
              }
              /** drive trip counts - counted as two trips */
              if (
                membersQuestions[1].Answer !== undefined &&
                membersQuestions[1].Answer !== ""
              ) {
                if (isNone(membersQuestions[1].Answer)) {
                  // setting amount, unit as a empty for drive
                      med_transport_details.driveTripCount = "";
                      med_transport_details.driveFrequencyCode = "";
                } else {
                      med_transport_details.driveTripCount =
                    membersQuestions[1].Answer.amount;
                  let freqCode = membersQuestions[1].Answer.unit;
                  if (/\s/g.test(freqCode)) {
                    freqCode = freqCode.replace(/ /g, "");
                  }
                      med_transport_details.driveFrequencyCode =
                    frequencyCode.getfrequencyCode[freqCode];
                }
              }
              /** drive trip counts - transportation pharmacy expense amounts */
              if (
                membersQuestions[2].Answer !== undefined &&
                membersQuestions[2].Answer !== ""
              ) {
                if (isNone(membersQuestions[2].Answer)) {
                  // setting amount, unit as a empty for transportation
                      med_transport_details.transportationAmount = "";
                      med_transport_details.transportationFrequencyCode = "";
                } else {
                      med_transport_details.transportationAmount =
                    membersQuestions[2].Answer.amount;
                  let freqCode = membersQuestions[2].Answer.unit;
                  if (/\s/g.test(freqCode)) {
                    freqCode = freqCode.replace(/ /g, "");
                  }
                      med_transport_details.transportationFrequencyCode =
                    frequencyCode.getfrequencyCode[freqCode];
                }
              }
                  transportDetails.push({...med_transport_details});
                }
              }
            }else{
              // Transportation No Answer Binding
              med_exp.drivetoApptPharmFlag = "N";
              med_exp.drivetoApptPharmCount = 0;
            }

            if (applicant.personId == med_exp.clientId) {
              applicant.medicalExpenses={
                ...med_exp
              }
              applicant.medicalExpenses['transportDtls'] = transportDetails;
              } else {
                for (
                  let medMember = 0;
                  medMember < applicant.household.length;
                  medMember++
                ) {
                  if (
                    applicant.household[medMember].personId ===
                      medicalMembers[medicalMember] &&
                    applicant.household[medMember].isGrantee === "N"
                  ) {
                  applicant.household[medMember].medicalExpenses={
                    ...med_exp
                  }
                  applicant.household[medMember].medicalExpenses['transportDtls'] = transportDetails;
                }
              }
            }
          }
        }
      }
    }
  }


// remove clientId key from medicalExpenses object
function removeClientIdFromMedicalExpenses(obj) {
  if (obj.hasOwnProperty('medicalExpenses') && obj.medicalExpenses.hasOwnProperty('clientId')) {
    delete obj.medicalExpenses.clientId;
  }
  
  // Checking household array for medicalExpenses and removing clientId if exists
  if (obj.hasOwnProperty('household') && Array.isArray(obj.household)) {
    obj.household.forEach(person => {
      if (person.hasOwnProperty('medicalExpenses') && person.medicalExpenses.hasOwnProperty('clientId')) {
        delete person.medicalExpenses.clientId;
      }
    });
  }
  return obj;
}


  applicant = removeClientIdFromMedicalExpenses(applicant);

  // console.log("++Final Medical Payload",applicant)


  /** here EBT card request binding data */
  let ebtCardRequest;
  if (applicationType === TextProps.MATCH_TANF) {
    ebtCardRequest = filterData(sections, "ebt-card-request");
  } else {
    ebtCardRequest = filterData(sections, "ebt-card-request");
  }
  if (ebtCardRequest !== undefined) {
    if (
      ebtCardRequest.questions[0].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[0]
    ) {
      applicant.requestEBTCard = "Y";
    } else if (
      ebtCardRequest.questions[0].Answer ===
      aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[1]
    ) {
      applicant.requestEBTCard = "N";
    }
  }

  let householdInformationResponse = filterData(sections, "household-information");
  if (householdInformationResponse !== undefined) {
    let webHouseholdInfo = [];
    householdInformationResponse.questions.map((section) => {
      const questionsObj = section.conditionals[0].conditionalQuestions[0];
      const radioQuestionObj = section.Answer;
      if (
        radioQuestionObj ===
        aboutMeMultilanguageLabels(selectedLanguage).houseLessOptions[0]
      ) {
        if (
          questionsObj.Answer !== undefined ||
          questionsObj.Answer !== "" ||
          questionsObj.Answer.length > 1
        ) {
          questionsObj &&
            questionsObj.Answer &&
            questionsObj.Answer.map((ans) => {
              let ansObj = _.cloneDeep(houseHoldInformationTemplate);
              let selectedMem = questionsObj.options.filter(
                (opt) => opt.optionValue == ans
              )[0];
              let existingMembers = webHouseholdInfo && webHouseholdInfo.filter((list) => (list.firstName + " " + list.lastName + formatters.formatDate(list.dateOfBirth)) === selectedMem.optionDisplay)
              if (webHouseholdInfo.length && existingMembers.length) {
                  existingMembers[0][questionsObj.id] = TextProps.VALUE_Y;
              } else {
                ansObj.firstName = selectedMem.firstName;
                ansObj.lastName = selectedMem.lastName;
                ansObj.dateOfBirth = selectedMem.dateOfBirth ? convertDOBForDataPost(
                  selectedMem.dateOfBirth
                ) : null;
                ansObj[questionsObj.id] = TextProps.VALUE_Y;
                webHouseholdInfo.push(ansObj);
              }
            });
        }
      }
    });
    if (webHouseholdInfo.length > 0) {
      application.webHouseholdInfo = webHouseholdInfo;
    } else {
      application.webHouseholdInfo = [];
    }
  }



  /** setting all applicant's details in application */
  application.applicant = applicant;
  if (
    inProgressObj !== undefined &&
    inProgressObj !== "" &&
    inProgressObj !== null
  ) {
    application.user_id = inProgressObj.userId;
  } else {
    application.user_id = "GUEST";
  }

  /** creating object for apply with user_name 'GUEST' and sending data to service - save function */
  var applicationData = {
    SNAP_APPLY: {
      userName: "GUEST",
      timestamp: new Date(),
      application: application,
    },
  };

  return applicationData;
}

function Person() {
  this.isGrantee = "";
  this.firstName = "";
  this.middleName = "";
  this.lastName = "";
  this.nameSuffix = "";
  this.SSN = "";
  this.birthDate = null;
  this.gender = "";
  this.isUSCitizen = "";
  this.languageCodeSpoken = "";
  this.isHomeless = "";
  this.hasDisability = "";
  this.immigrationStatus = ""

  //Properties For Read
  this.id = 0;
  this.applicationId = 0;
  this.clientId = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();

  //List of Address
  this.addresses = [];
  //List of Person
  this.household = [];

  //web_contact_info
  this.areaCode = "";
  this.exchangeNumber = "";
  this.lineNumber = "";
  this.emailAddress = "";
  //web_ethnicity
  this.ethnicity = "";
  //web_race
  this.race = [];
  //List of CareExpense
  this.dependentCareExpense = [];
  //List of Income
  this.resources = [];
  //List of Income
  this.income = [];
  //List of MedicalExpense
  this.medicalExpenses = {};
  //List of SupportExpense
  this.supportExpenses = [];
  this.requestEBTCard = "";
  //web_prsnl_rel
  this.relationshipCode = "";
  this.personId = "";
  //maritalStatDt and maritalStatus
  this.maritalStatus = null;
  this.maritalStatDt = null;
  this.hasResourcesFlg = null;
}
//web_addr
function Address() {
  this.addressType = "";
  this.street = "";
  this.aptOrUnit = "";
  this.city = "";
  this.state = "";
  this.postal = "";
  this.isSameMailAndPhysical = "";
  this.countyCode = "";
  this.verified = "N";

  //Properties For Read
  this.id = 0;
  this.applicationId = 0;
  this.clientId = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();
}
//web_dpcare_exp
function CareExpense() {
  this.providerCareAmount = "";
  this.providerCareFrequencyCode = "";
  this.transportationPayFlag = "";
  this.transportationAmount = "";
  this.transportationFrequencyCode = "";

  //Properties For Read
  this.id = 0;
  this.applicationId = 0;
  this.clientId = 0;
  this.dependentId = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();
  this.outHHName = "";

  //List of CareTransportExpense
  this.transportExpenses = [];
}
//web_dpcare_transport_exp
function CareTransportExpense() {
  this.address = new Address();
  this.driveTripCount = "";
  this.driveFrequencyCode = "";

  //Properties For Read
  this.id = 0;
  this.expenseId = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();
}
//web_ei || web_ui
function Income() {
  //common
  this.grossIncome = "";
  this.frequencyCode = "";
  //web_ui
  this.otherIncomeTypeCode = "";
  this.hours = "";
  this.hoursfrequencyCode = "";
  //web_ei
  this.wageTypeCode = "";
  //Properties For Read
  this.id = 0;
  this.applicationId = 0;
  this.clientId = 0;
  this.lastUpdateUser = "";
  this.key = "";
  this.lastUpdateTime = new Date();
}
function Resources() {
  this.resourceDetails = [];
}
function ResourceDetails() {
  this.amount = 0;
  this.additionalInfo = "";
  this.bankBrokerageName = "";
  this.accountNumber = "";
  this.isJointAccount = "";
  this.jointAcntHolderName = "";
}
//web_support_exp
function SupportExpense() {
  this.supportExpenseTypeCode = "";
  this.amount = 0;
  this.frequencyCode = "";
  //Properties For Read
  this.applicationId = 0;
  this.clientId = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();
}

function MedicalTransportDetails(){
  this.address = new Address();
  this.driveTripCount = "";
  this.driveFrequencyCode = "";
  this.transportationAmount = "";
  this.transportationFrequencyCode = "";
  this.id = 0;
  this.applicationId = 0;
  this.clientId = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();
}
//web_medical_exp
function MedicalExpense() {
  this.medicalExpDtls = {}
  this.drivetoApptPharmFlag="";
  this.drivetoApptPharmCount="";
  this.transportDtls=[];
}

// Medical New Payload Helpers
 const medicalExpenseKeys = {
  MDENT: {
    flag:"medicalDentalFlag",
    amount:"medicalDentalAmt",
    unit:"medicalDentalFreqCd",
  },
  HSPTL:{
    flag:"hospitalFlag",
    amount:"hospitalAmt",
    unit:"hospitalFreqCd",
  },
  MEDCTN:{
    flag:"medicationFlag",
    amount:"medicationAmt",
    unit:"medicationFreqCd",
  },
  OTCMED:{
    flag:"otcmedFlag",
    amount:"otcmedAmt",
    unit:"otcmedFreqCd",
  },
  HINSPR:{
    flag:"healthInsuranceFlag",
    amount:"hlthInsPremAmt",
    unit:"hlthInsPremFreqCd",
  },
  MSUPPL:{
    flag:"medsupplyFlag",
    amount:"medsupplyAmt",
    unit:"medsupplyFreqCd",
  },
  MDCOSH:{
    flag:"medCoShareFlag",
    amount:"medCoShareAmt",
    unit:"medCoShareFreqCd",
  },
  DHP:{
    flag:"dentureHearingAidFlag",
    amount:"dentureHearingAidAmt",
    unit:"dentureHearingAidFreqCd",
  },
  GLSSES:{
    flag:"glassesFlag",
    amount:"glassesAmt",
    unit:"glassesFreqCd",
  },
  TRSPRT:{
    flag:"transportFlag",
    amount:"transportAmt",
    unit:"transportFreqCd",
  },
  HHACC:{
    flag:"hhaccFlag",
    amount:"hhaccAmt",
    unit:"hhaccFreqCd",
  },
  DOG:{
    flag:"dogFlag",
    amount:"dogAmt",
    unit:"dogFreqCd",
  },
  OTHER:{
    flag:"otherFlag",
    amount:"otherAmt",
    unit:"otherFreqCd",
  }
}

 const medicalExpenseObj = {
    "medicalDentalFlag": "N",
    "hospitalFlag": "N",
    "medicationFlag": "N",
    "otherFlag": "N",
    "otcmedFlag": "N",
    "healthInsuranceFlag": "N",
    "medsupplyFlag": "N",
    "medCoShareFlag": "N",
    "dentureHearingAidFlag": "N",
    "glassesFlag": "N",
    "transportFlag": "N",
    "hhaccFlag": "N",
    "dogFlag": "N",
    "otcmedAmt": null,
    "hlthInsPremAmt": null,
    "medsupplyAmt": null,
    "medCoShareAmt": null,
    "dentureHearingAidAmt": null,
    "glassesAmt": null,
    "transportAmt": null,
    "hhaccAmt": null,
    "dogAmt": null,
    "medicalDentalAmt": null,
    "hospitalAmt": null,
    "otherAmt": null,
    "otcmedFreqCd": null,
    "hlthInsPremFreqCd": null,
    "medsupplyFreqCd": null,
    "medCoShareFreqCd": null,
    "dentureHearingAidFreqCd": null,
    "glassesFreqCd": null,
    "transportFreqCd": null,
    "hhaccFreqCd": null,
    "dogFreqCd": null,
    "medicationFreqCd": null,
    "medicalDentalFreqCd": null,
    "hospitalFreqCd": null,
    "otherFreqCd": null,
    "medicationAmt":null,
    "otherDesc":""
}

//web_pgm_app
function Program() {
  this.programCode = "";
  //Properties For Read
  this.id = 0;
  this.applicationId = 0;
  this.createTime = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();
}
//web_imm_needs
function ImmediateNeeds() {
  this.needTypeCode = "";
  this.answer = "";
}
//web_shelter_Exp
function ShelterExpense() {
  this.householdExpenseTypeCode = "";
  this.amount = "";
  this.frequencyCode = "";
  //Properties For Read
  this.applicationId = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();
}
//web_application
function Application() {
  this.householdSize = " ";
  this.receivedDate = new Date();
  this.status = "";
  this.signedName = "";
  this.userId = "";

  //Properties For Read
  this.id = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();

  //List of ShelterExpense
  this.shelterExpenses = [];
  //List of ImmediateNeeds
  this.needs = [];
  //List of Programs
  this.programs = [];
  //web_standard_allowance
  this.payHeat = "";
  this.payAC = "";
  this.payACFee = "";
  this.elgas = "";
  this.hasPhone = "";
  this.waterSwgFlg = "";
  this.garbageFlg = "";
  this.utlOtherPayer = TextProps.VALUE_N;
  this.utlPayerName = "";
  this.shelterOtherPayer = TextProps.VALUE_N;
  this.shelterPayerName = "";
  this.needAccommotation = "";
  this.accommodation = [];
  this.webHouseholdInfo = [];
  this.applicant = new Person();
}

//accommodation Applies
function accommodation() {
  this.typeCode = "";
  this.otherDesc = "";
}

//web_race
function Race() {
  this.raceCode = "";
  this.otherDesc = "";
  this.id = 0;
  this.applicationId = 0;
  this.clientId = 0;
  this.lastUpdateUser = "";
  this.lastUpdateTime = new Date();
}
function convertDOBObjectToString(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}