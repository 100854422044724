import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapStateToProps } from "../../redux/rootReducer";
import DTAInput from "./dtaInput";
import DTATextArea from "./dtaTextArea";
import { CommonTypes, CommonDefaults } from "./propTypes";
import { tryCall } from "./utilities/controls";
import _ from "lodash";
import { resourcesSectionMultilanguageLabels } from "../../pages/applySnap/applySnapText";
import { INPUT_TEXT_AREA, INPUT_TYPE_MONEY } from "../constants/controls";
import * as helperFunction from "./helperFunctions/helperFunctions";
import * as TextProps from "../../utils/constants/text";
import * as languageConsts from "../../utils/constants/types";

import { langOptSel } from "../constants/constants";
import Label from "../../utils/components/controlWrappers/label";
let selectedLanguage =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
if (selectedLanguage === null || selectedLanguage === langOptSel) {
  selectedLanguage = "en-US";
}

class ResourcesTemplateOne extends Component {
  static propTypes = {
    ...CommonTypes,
    // handlers
    onChange: PropTypes.func,
    onZipCodeChange: PropTypes.func,
    // one-way data bindings
    value: PropTypes.shape({
      amount: PropTypes.string,
      additionalInformation: PropTypes.string,
      required: PropTypes.bool,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
      type: PropTypes.string,
    }),
  };
  static defaultProps = {
    ...CommonDefaults,
  };

  constructor(props) {
    super(...arguments);
    enableUniqueIds(this);
    let copyProps = _.cloneDeep(props);
    this.state = this._buildStateFromProps(copyProps);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState(this._buildStateFromProps(nextProps));
    }
  }

  render() {
    const { error, disabled, required, name, className, data } = this.props,
      ariaLabels = this.props["aria-labelledby"];
    return (
      <div className={className}>
        {this._buildFirstLine(
          name,
          ariaLabels,
          error,
          disabled,
          required,
          data
        )}
      </div>
    );
  }

  // Rendering
  _buildFirstLine(name, ariaLabels, error, disabled, required, data) {
    return (
      <div className="pure-g margin-bottom-half">
        {
          <div className="pure-u-1-1">
            {/* <div>
             <b>{data.subHeader}</b>
            </div>
            <br /> */}
            <div>
              <label
                className="dta-form__label dta-form__label--size-small"
                htmlFor={this.nextUniqueId()}
              >
                {
                  resourcesSectionMultilanguageLabels(
                    selectedLanguage,
                    data.subLabel
                  ).templateOneLabel1
                }
              </label>
              {this.state._value.error && (
                <div className="pure-g">
                  <div className="pure-u-1 dta-form__error-message">
                    {this.state._value.errorMessage}
                  </div>
                </div>
              )}
              <DTAInput
                name={name}
                type={INPUT_TYPE_MONEY}
                className="dta-input--expand-width"
                id={this.lastUniqueId()}
                aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                onChange={this._handleChangeForAmount}
                value={this.state._value.amount}
                error={this.state._value.error}
                disabled={disabled}
                required={this.state._value.required}
                maxLength={13}
              />
            </div>
            <br />
            <div>
              <label
                className="dta-form__label dta-form__label--size-small"
                htmlFor={this.nextUniqueId()}
              >
                {
                  resourcesSectionMultilanguageLabels(selectedLanguage)
                    .templateOneLabel2
                }
              </label>
              <DTATextArea
                name={name}
                type={INPUT_TEXT_AREA}
                placeholder={
                  resourcesSectionMultilanguageLabels(selectedLanguage)
                    .templateOneLabel3
                }
                className="dta-input--expand-width"
                id={this.lastUniqueId()}
                aria-labelledby={ariaLabels + " " + this.lastUniqueId()}
                onChange={this._handleChangeForAdditionalInformation}
                value={this.state._value.additionalInformation}
                maxLength={250}
              />
            </div>
          </div>
        }
      </div>
    );
  }

  _handleChangeForAmount = (amountValue) => {
    let newValue = {};
    if(window.location.pathname === "/reportchange"){
      if(this.state._value.amount !== amountValue && this.state._value.hasOwnProperty('datachangeCode') && this.state._value.datachangeCode !== "NEW"){
        newValue = {
          ...this.state._value,
          amount: Number(amountValue),
          datachangeCode: "CHANGE"
        }
      } else if(!this.state._value.hasOwnProperty('datachangeCode') || this.state._value.datachangeCode === "NEW"){
        newValue = {
          ...this.state._value,
          amount: Number(amountValue),
          datachangeCode: "NEW"
        } 
      } else {
        newValue = {
          ...this.state._value,
          amount: Number(amountValue),
          datachangeCode: "NOCHNG"
        } 
      }
    } else {
      newValue =  {...this.state._value, amount: amountValue};
    }
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  _handleChangeForAdditionalInformation = (additionalInformationValue) => {
    let newValue = {};
    if(window.location.pathname === "/reportchange"){
      if(this.state._value.additionalInformation !== additionalInformationValue && this.state._value.hasOwnProperty('datachangeCode') && this.state._value.datachangeCode !== "NEW"){
        newValue = {
      ...this.state._value,
      additionalInformation: additionalInformationValue,
          datachangeCode: "CHANGE"
        }
      } else if(!this.state._value.hasOwnProperty('datachangeCode') || this.state._value.datachangeCode === "NEW"){
        newValue = {
          ...this.state._value,
          additionalInformation: additionalInformationValue,
          datachangeCode: "NEW"
        } 
      } else {
        newValue = {
          ...this.state._value,
          additionalInformation: additionalInformationValue,
          datachangeCode: "NOCHNG"
        } 
      }
    } else {
      newValue =  {...this.state._value, additionalInformation: additionalInformationValue};
    }
    this.setState({ _value: newValue });
    tryCall(this.props.onChange, newValue);
  };

  // Helpers
  _buildStateFromProps(props) {
    if (props.value) {
      return {
        _value: props.value,
      };
    } else {
      return {
        _value: {
          amount: "",
          additionalInformation: "",
          required: TextProps.VALUE_FALSE,
          error: TextProps.VALUE_FALSE,
          errorMessage: "",
          type: "resources",
          templateType: languageConsts.RESOURCES_TEMP_TWO,
        },
      };
    }
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesTemplateOne);