import React from "react";
import PropTypes from "prop-types";

import * as SharedProps from "../../components/shared";
import * as Types from "../../constants/types";
import ControlWrapper from "../controlWrappers/controlWrapper";
import Label from "../controlWrappers/label";
import { addStringIf } from "../../components/utilities/controls";
import { DEFAULT_CHECKBOX_CLASSES } from "../../constants/controls";

/*
  Filter
  ------

  # Purpose:
  Simple wrapper component for the `ControlWrapper` class handling styling of the resulting
  rendered control to fit the appearance of controls used as filters

  # Props:
  All other props passed through to the underlying `ControlWrapper`
  className: Optional, string classes to render on the top-level element

  # Example:
  <Filter className="pure-u-1-4" data={FiltersData.FILTER_DOCUMENT_TYPE} />
 */

Filter.propTypes = {
  className: PropTypes.string,
};

Filter.defaultProps = {
  className: "",
};

export default function Filter(props) {
  const type = props.data[SharedProps.TYPE],
    additionalProps = type === Types.CHECKBOX ? DEFAULT_CHECKBOX_CLASSES : {};
  return (
    <div className={addStringIf(props.className, "dta-filter")}>
      <Label
        data={props.data[SharedProps.LABEL]}
        className="dta-filter__question"
        tagName="div"
      />
      <ControlWrapper
        {...props}
        type={type}
        className="dta-filter__container"
        controlContainerClass="dta-filter__control"
        labelHidden
        filterAppointmentByDate={props.filterAppointmentByDate}
        {...additionalProps}
      />
    </div>
  );
}
