import React, { Component } from "react";
import _ from "lodash";
import { enableUniqueIds } from "react-html-id";
import Collapse from "react-collapse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/rootReducer";
import * as Props from "../../../utils/components/shared";
import HelpTip from "../../../utils/components/helpTip";
import Icon from "../../../utils/components/dtaIcon";
import DataChangeIndicator from "../../../utils/components/utilities/dataChangeIndicator";
import {
  getFrequencyCode,
  getFrequency,
} from "../../../utils/components/utilities/utils";
import { formatDate, formatMoney } from "../../../utils/components/utilities/formatters";
import { setDataChangeStatus, appendResourcesDetails, clearMemberResources, setHouseholdResourcesChangedStatus } from "../reportChangeActions";
// import { ResourcesSectionTemplate } from "../recertInterimReportMapData";
import * as languageConstants from "../../../utils/constants/constants";
import * as TextProps from "../../../utils/constants/text";
import { interimReportPageLabels } from "../../recertInterimReport/recertInterimReportText";
import * as helperFunction from "../../../utils/components/helperFunctions/helperFunctions";
import { ResourcesSectionTemplate } from "../reportChangeMapData";
import {resourcesSectionMultilanguageLabels,beforeYoustartMlanguageLabels} from "../../applySnap/applySnapText";
import { ReportChangePageLabels } from "../reportChangeText";

const language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");

class ResourcesSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderedSections: [],
      resourcesSummaryEdit: TextProps.VALUE_FALSE,
      isLoading: TextProps.VALUE_FALSE,
      addResourcesMembersOpen: TextProps.VALUE_FALSE,
      isHouseholdResourcesChanged: TextProps.VALUE_FALSE,
      sections: null,
      respData: null,
      submitFormId: null,
    };
    enableUniqueIds(this);
  }

  componentWillReceiveProps() {
    this.setState(
      {
        sections: this.props.consumer.reportChangeSections,
        respData: this.props.consumer,
      },
      () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(this.props.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );
    if (this.props.recert.setOldIncomeData === TextProps.VALUE_TRUE) {
      this.onHouseholdResourcesChanged(
        interimReportPageLabels(
          language,
          languageConstants.aboutMe,
          null,
          null,
          null
        ).optionYesNo[1]
      );
    }
  }

  filterData = (section, id) => {
    return section.filter((sec) => sec.id === id)[0];
  };

  resourcesSummaryEditClick = (section) => {
    this.setState({ resourcesSummaryEdit: TextProps.VALUE_TRUE });
    this.props.onSectionChange(section);
  };

  toggleAddIncomeMembers = () => {
    this.setState({ addResourcesMembersOpen: !this.state.addResourcesMembersOpen });
  };

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};

    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  onResourcesSubSectionRetain = () => {
    if (this.state.resourcesSubSectionCleared === TextProps.VALUE_FALSE) {
      this.setState(
        {
          resourcesSubSectionCleared: TextProps.VALUE_TRUE,
          resourcesMemberTemplate: _.cloneDeep(ResourcesSectionTemplate),
          resourcesSummaryTemplate:
          this.filterData( this.filterData(this.props.recert.sections, "resources-summary").subsections, "combined-resources-subsection")
        },
        () => {
          this.props.clearMemberResources(
            this.state.resourcesMemberTemplate,
            this.state.resourcesSummaryTemplate,
            this.filterData(this.props.recert.sections,"resources-summary"),
            TextProps.VALUE_FALSE
          );
          this.props.onResourcesSubsectionRender(
            this.state.resourcesMemberTemplate
          );
        }
      );
    }
  };

  onHouseholdResourcesChanged = (value) => {
    this.setState({isHouseholdResourcesChanged: value});
    if (
      value ===
      interimReportPageLabels(
        language,
        languageConstants.aboutMe,
        null,
        null,
        null
      ).optionYesNo[1]
    ) {
      this.setState(
        {
          resourcesValuesPopulated: TextProps.VALUE_FALSE,
          resourcesSubSectionCleared: TextProps.VALUE_FALSE,
          resourcesChangeFlag: !this.state.resourcesChangeFlag,
        },
        () => {
          this.onResourcesSubSectionRetain();
          this.props.setHouseholdResourcesChangedStatus(TextProps.VALUE_FALSE);
          this.props.recert.householdResourcesChanged = TextProps.VALUE_FALSE;
          if(this.props.recert.householdResourcesChanged != undefined )
            this.props.setRecertDataChangeStatus(TextProps.VALUE_TRUE, this.props.recert.sections.indexOf(
                this.filterData(this.props.recert.sections, "resources-summary")
              ));
            this.props.setSectionDataUpdated(TextProps.VALUE_FALSE);
        }
      );
    } else {
      this.setState(
        {
          resourcesChangeFlag: !this.state.resourcesChangeFlag,
        },
        () => {
          this.props.setHouseholdResourcesChangedStatus(TextProps.VALUE_TRUE);
          this.props.recert.householdResourcesChanged = TextProps.VALUE_TRUE;
          if(this.props.recert.householdResourcesChanged != undefined )
            this.props.setDataChangeStatus(TextProps.VALUE_TRUE, this.props.recert.sections.indexOf(
                this.filterData(this.props.recert.sections, "resources-summary")
              ));
            this.props.setSectionDataUpdated(TextProps.VALUE_TRUE);
        }
      );
    }
  };

  renderResourcesToAddNewMember = (name) => {
    let resourcesMeTemplate = _.cloneDeep(ResourcesSectionTemplate);
    if (resourcesMeTemplate.questions === undefined) {
      resourcesMeTemplate = ResourcesSectionTemplate;
    }
    let resourcesCopy = this.props.consumer.reportChangeSections[0];
    let resourceSubsectionCopy = resourcesCopy.subsections;

    let resourcesSummaryCopy = _.cloneDeep(
      resourcesCopy.subsections[resourcesCopy.subsections.length - 1]
    );
    resourcesCopy.subsections.splice(resourcesCopy.subsections.length - 1, 1);

    resourcesMeTemplate.id = "combined-resources-" + resourcesCopy.subsections.length;
    resourcesMeTemplate.completed = TextProps.VALUE_TRUE;
    resourcesMeTemplate.header = resourcesSectionMultilanguageLabels(language,name).resourcesDetailsHeader;
    resourcesMeTemplate.questions[0][Props.LABEL] = resourcesSectionMultilanguageLabels(language,name).resourcesDetailsLabel;
    resourcesMeTemplate.subsections[0].id =
      "combined-resources-subsection-" + resourcesCopy.subsections.length;
    resourcesMeTemplate.subsections[0].header = resourcesSectionMultilanguageLabels(language,name).resourcesDetailsHeader;
    resourcesMeTemplate.title = name;
    resourcesMeTemplate.subsections[0].title = name;
    let resourcesReturn = resourcesSectionMultilanguageLabels(language,name);

    for (var j = 0; j < resourcesMeTemplate.subsections[0].questions.length; j++) {
      let resourcesReturnObj = resourcesReturn.combineResourcesOptionsLabelsSrs[j];
      resourcesMeTemplate.subsections[0].questions[j].repeatedQuestion.subLabel = resourcesReturnObj.line1 + name + resourcesReturnObj.line2;
      resourcesMeTemplate.subsections[0].questions[j].repeatedQuestion.subHeader = resourcesReturn.combineResourcesoptions[j];
      resourcesMeTemplate.subsections[0].questions[j].canBeDeleted =
        TextProps.VALUE_TRUE;
    }
    resourceSubsectionCopy.push(_.cloneDeep(resourcesMeTemplate));
    resourceSubsectionCopy.push(resourcesSummaryCopy);
    resourcesCopy.subsections = [];
    resourcesCopy.subsections = resourceSubsectionCopy;
    this.props.appendResourcesDetails(resourcesCopy); //comment for stop repeating
    this.props.prepareResourcesSummary();
    setTimeout(()=>{
    this.props.onSectionChange(resourcesMeTemplate);
    });
  };

  renderResourcesSummaryItem = (summary) => {
    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >
          <HelpTip
            textBefore={
              this.props.getIncomeSummaryTitle(
                summary.firstName,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomebefore,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomeAfter
              ).before
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        <p className="interim-recert-heading apply__body-header help-text-fix">
          <HelpTip
            triggerText={
                resourcesSectionMultilanguageLabels(language).resourceTitle
            }
            helpText={
                resourcesSectionMultilanguageLabels(language).resourcesDescLabel
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        {/*
        The edit button should route the user to the appropriate apply section
        to edit income for this household member.
        Later, when the user clicks continue on that section, they are returned to the
        "Income Summary" section again.
      */}

          <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => this.resourcesSummaryEditClick(summary.section)}
          >
            {
              ReportChangePageLabels(
                language,
                languageConstants.beforeYouStart
              ).editButtonLabel
            }
          </button>
        <ul>
          {summary.resources.map((resourceItem) => {
              return resourceItem.Answer.map((answer,id) => {
                let label = "";
                // let label = resourceItem.datachangeCode;
                // if (resourceItem.datachangeCode !== undefined) {
                //   if (resourceItem.datachangeCode !== "NOCHNG") {
                //     label = resourceItem.datachangeCode;
                //   } 
                // }
                const isAmountExists = resourceItem.Answer.filter(ans => ans.amount);
                return (
                  <div className="pure-g">
                    <div className="pure-u-1-1">
                    {id === 0 && isAmountExists.length > 0 && <div className="pure-u-1-1 margin-top-half"><div className="pure-u-1-3 margin-bottom-half theme-colour">{resourceItem.type}</div>
                    <div className="pure-u-1-3 pad-left-half margin-bottom-half">
                    {resourceItem.datachangeCode !== "NOCHNG" ?  (
                        <DataChangeIndicator
                          text={
                            resourcesSectionMultilanguageLabels(language).dataChangeLabels[resourceItem.datachangeCode]
                          }
                        />
                      ) : null}
                    </div></div>}
                    {answer.amount && answer.datachangeCode !== "REMOVE" ? <div className="pure-u-1-1 margin-top-half"><div className="pure-u-2-3 margin-bottom-half">{beforeYoustartMlanguageLabels(language).amountLabel}</div>
                    <div className="pure-u-1-3 pad-left-half margin-bottom-half">${answer.amount}</div>
                    {answer.bankName && <div><div className="pure-u-2-3 margin-bottom-half">{beforeYoustartMlanguageLabels(language).bankNameLabel}</div>
                    <div className="pure-u-1-3 pad-left-half margin-bottom-half" style={{ wordBreak: "break-word" }}>{answer.bankName}</div></div> }</div> 
                    : null}
                    </div>
                  </div>
                );
              });
          })}
        </ul>
      </div>
    );
  };

  amountIteratorForIncome = (unit, amount) => {
    let amountIterator = 1;
    let classRowCount = 3;
    if (getFrequencyCode(language)[unit] === "WEEK") {
      amountIterator = 4;
      classRowCount = 24;
    } else if (getFrequencyCode(language)[unit] === "BIWEEK") {
      amountIterator = 2;
      classRowCount = 24;
    }

    let amountRows = [];
    for (let i = 0; i < amountIterator; i++) {
      if (amount[i]) {
        amountRows.push(
          <div className={"pure-u-" + classRowCount + "-24"}>
            <span>
              {amount !== undefined &&
              amount[i] !== undefined &&
              amount[i] !== ""
                ? formatMoney(amount[i])
                : ""}
            </span>
          </div>
        );
      }
    }

    return (
      <div className="max-width pure-u-1-1">
        <div className="pure-u-11-24 pure-u-sm-18-24 pure-u-md-18-24 pure-u-lg-18-24">
          <span className="label-text-clr" style={{ wordBreak: "break-word" }}>
            {getFrequency(language)[getFrequencyCode(language)[unit]]}{" "}
            {
              interimReportPageLabels(
                language,
                languageConstants.earnedIncomeStatic,
                null,
                null,
                null
              ).amountLabel
            }
          </span>
        </div>
        <div
          className={"pure-u-8-24 pure-u-sm-6-24 pure-u-md-6-24 pure-u-lg-6-24"}
        >
          {amountRows}
        </div>
        <hr className="divider" />
      </div>
    );
  };

  returnRsourcesLabel() {
    if (
      this.props.recert.householdResourcesChanged === TextProps.VALUE_TRUE
    ) {
      return interimReportPageLabels(language, languageConstants.aboutMe)
        .optionYesNo[0];
    } else {
      if (
        this.props.recert.householdResourcesChanged === TextProps.VALUE_FALSE
      ) {
        return interimReportPageLabels(language, languageConstants.aboutMe)
          .optionYesNo[1];
      } else {
        return null;
      }
    }
  }

  renderHouseHoldHeadSummary = () =>{
    let HOH=[];
    let summary={};
    
    if(this.props.consumer &&
      this.props.consumer.consumerData &&
      this.props.consumer.consumerData.householdMembers &&
       this.props.consumer.consumerData.householdMembers.length > 0){
      HOH =  this.props.consumer.consumerData.householdMembers.filter((mem)=>{
        return mem.granteeFlag
    })
    summary = HOH[0]
    }

    let summaryTitle = summary.firstName + " " + summary.lastName + formatDate(summary.dateOfBirth)

    return (
      <div className="dta-content-box apply__summary-item" key={summary.id}>
        <p
          className="interim-recert-heading apply__body-header pure-u-16-24 pure-u-sm-20-24 pure-u-md-20-24 pure-u-lg-20-24 pure-u-xl-20-24"
          style={{ wordBreak: "break-word" }}
        >
          <HelpTip
            textBefore={
              this.props.getIncomeSummaryTitle(
                summaryTitle,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomebefore,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomeAfter
              ).before
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>

        <p className="interim-recert-heading apply__body-header help-text-fix">
          <HelpTip
            triggerText={
                resourcesSectionMultilanguageLabels(language).resourceTitle
            }
            textAfter={
              this.props.getIncomeSummaryTitle(
                summary.firstName,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomebefore,
                interimReportPageLabels(
                  language,
                  languageConstants.beforeYouStart
                ).sIncomeAfter
              ).after
            }
            helpText={
                resourcesSectionMultilanguageLabels(language).resourceTitle
            }
            bypassMobileStyle={TextProps.VALUE_TRUE}
          />
        </p>
        <button
            className="dta-button dta-bordered-button pure-u-8-24 income-edit-btn"
            onClick={() => this.renderResourcesToAddNewMember(summaryTitle)}
          >
            {
              interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).editButtonLabel
            }
          </button>
        {/*
        The edit button should route the user to the appropriate apply section
        to edit income for this household member.
        Later, when the user clicks continue on that section, they are returned to the
        "Income Summary" section again.
      */}
  
       <p>
      { interimReportPageLabels(
                language,
                languageConstants.beforeYouStart
              ).noResourceText
       }
       </p>
      </div>
    );
  }

  render() {
    let { consumer } = this.props;
    var listOfUpdatedHHMembers = [];
    var existingIncomeMembers = [];
  
    let addIncomeMembersActive = "";
    if(this.props.consumer.consumerData && this.props.consumer.consumerData.householdMembers){
      this.props.consumer.consumerData.householdMembers.forEach((memberHH) => {
        // if (memberHH.dataChangeCode !== undefined) return;
        var incomeHouseHoldName = memberHH.firstName;
        var incomeCombinedName = "";
        if (incomeHouseHoldName !== "") {
          incomeCombinedName = incomeHouseHoldName + " " + memberHH.lastName;
          let incomeHouseHoldDOB = formatDate(memberHH.dateOfBirth);
          if (memberHH.dateOfBirth !== "") {
            incomeCombinedName = incomeCombinedName + incomeHouseHoldDOB;
          }
        } else if (incomeHouseHoldName === "") {
          incomeCombinedName = memberHH.firstName;
        }
        listOfUpdatedHHMembers.push(incomeCombinedName);
  
      if (this.props.resourcesSummary.resourcesMembers !== undefined) {
        this.props.resourcesSummary.resourcesMembers.map((mem) => {
          existingIncomeMembers.push(mem.firstName);
        });
      }
      });
    }
    if (this.state.addResourcesMembersOpen) {
      addIncomeMembersActive = "is-active";
    }
    return (
      <div key={this.nextUniqueId()} className="pad-all--double">
        {console.log("mineIncomeSummary", this.props.resourcesSummary)}
        {/* Get details of Income information from Pervious pages to render incomeSummaries*/}
        {this.props.resourcesSummary && this.props.resourcesSummary.resourcesMembers && this.props.resourcesSummary.resourcesMembers.length > 0 ? this.props.resourcesSummary.resourcesMembers.map((summary) =>
          this.renderResourcesSummaryItem(summary)
        ) : this.renderHouseHoldHeadSummary()}
          <div>
            <button
              className={`add-new-button dta-button dta-button--outline-secondary dta-button--block dta-button--text-with-icon text--left ${addIncomeMembersActive}`}
              onClick={this.toggleAddIncomeMembers}
              aria-expanded={this.state.addResourcesMembersOpen}
            >
              <Icon name="plus" ariaHidden={TextProps.VALUE_TRUE} />
              {
                ReportChangePageLabels(
                  language,
                  languageConstants.beforeYouStart,
                  null,
                  null,
                  null
                ).anotherHouseholdResourceLabel
              }
            </button>
            <Collapse isOpened={this.state.addResourcesMembersOpen}>
              <span className="add-income-summary__label" />
              <ul className="list--unstyled add-income-summary__list">
                {listOfUpdatedHHMembers.map((member) => {
                  if (existingIncomeMembers.indexOf(member) >= 0)
                    return TextProps.VALUE_FALSE;
                  return (
                    <li key={member.id}>
                      {/*
                      Clicking the following button routes the user to a
                      new child Income section to fill out income info for this
                      household member (e.g. "Listing John's Income"). When user
                      is finished and clicks 'Continue', they will be returned to Income Summary page
                    */}
                      <a
                        style={{ textDecoration: "none" }}
                        href="javascript:void(0);"
                        onClick={() => {
                          this.renderResourcesToAddNewMember(member);
                          return TextProps.VALUE_FALSE;
                        }}
                        className="dta-button dta-button--outline-secondary dta-button--block"
                      >
                        <span className="sr-only">Add resources for </span>
                        <span className="truncate-text">{member}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Collapse>
          </div>
      </div>
    );
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearMemberResources: clearMemberResources,
      appendResourcesDetails: appendResourcesDetails,
      setHouseholdResourcesChangedStatus: setHouseholdResourcesChangedStatus,
      setDataChangeStatus: setDataChangeStatus,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesSummary);