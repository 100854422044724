import React, { Component } from "react";
import { enableUniqueIds } from "react-html-id";
import _ from "lodash";
import { push } from "react-router-redux";
import CSSTransition from "react-transition-group/CSSTransition";
import SidebarNavigation from "../../utils/components/sidebar/sidebarNavigation";
import LanguageSelect from "../../redux/language/languageSelect";
// apply specific imports
import ApplySection from "./components/greeterScreening";
import { convertToDTOObj } from "./greeterScreeningConverter";
// Redux implementations
import { connect } from "react-redux";
import {
  applyGreeterScreeningQuestionsSections,
  updateCurrentSectionData,
  saveGreeterScreen,
} from "../../redux/greeterScreening/greeterScreeningActions";
import { bindActionCreators } from "redux";
import DTADropdown from "../../utils/components/dtaDropdown";
import { Link } from "react-router-dom";

//azure
import { withMsal } from "@azure/msal-react";

import { mapStateToProps } from "../../redux/rootReducer";
import { toValidateInputErros, bindAnswers } from "./greeterScreeningHelper";
import { beforeYoustartMlanguageLabels } from "./greeterScreeningText";
//start code for google analytics
import * as googleAnalyticsConstVar from "../../utils/constants/googleAnalyticsConst";
import {
  getConsumerLoginContents,
  onLogin,
  onMFASubmit,
} from "../../redux/user/loginActions";
import {
  onRegister,
  onBindEmail,
} from "../../redux/user/consumerSignUpActions";
import {
  removeMultilanguageSelectGreeter,
  multilanguageSelect,
} from "../../redux/provider/providerMyClientsActions";
import {
  onLogOut,
  onAzureLogOut,
} from "../../utils/helper/accountNavigationActions";
import * as roleProps from "../../utils/constants/rolesConstants";
import * as routeProps from "../../utils/constants/routesConstants";
import * as helperFunction from "../../utils/components/helperFunctions/helperFunctions";
import * as types from "../../utils/constants/types";
import * as TextProps from "../../utils/constants/text";
import * as modalHelperFunction from "../../utils/components/helperFunctions/modalHelperFunction";
import * as config from "../../utils/constants/appConfigConstants";

var ua = require("universal-analytics");
var visitor = ua(
  googleAnalyticsConstVar.TRACKING_ID,
  googleAnalyticsConstVar.CLIENT_ID,
  { strictCidFormat: TextProps.VALUE_FALSE }
);
//end code for google analytics

let language =
  helperFunction.selectedLanguageFromLocalStorage("selectedLanguage");
// greeter screening page has no translations as per bussiness requirement
if (language === null || language != types.ENGLISH) {
  language = types.ENGLISH;
}

// apply specific configuration
// ID's used in config correspond to section ID's in apply/data/applySections and apply/data/screeningSection
const pageConfig = {
  // Show screening section or not.
  screening: TextProps.VALUE_TRUE,
  // The ID of the section desired to be active initially.
  activeSectionId: "about-me",
};

/* NOTE
 * There are many 'key' properties used in this apply area. Iterated items need
 * unique key's compared to their siblings when using React. They don't necessarily
 * need to use the key approach we've chosen though, so long as keys are set and remain unique.
 * That being said, don't use index as a key, since index is reassigned with each render.
 * https://facebook.github.io/react/docs/reconciliation.html#keys
 */

class GreeterScreening extends Component {
  constructor(props) {
    super(props);
    enableUniqueIds(this);
    this.renderApplyQuestions = this.renderApplyQuestions.bind(this);
    this.onPrimaryActionClick = this.onPrimaryActionClick.bind(this);
    this.renderCounter = 0;
    this.nonRenderCounter = 0;
    this.state = {
      sections: [],
      renderedSections: [],
      activeSectionId: pageConfig.activeSectionId,
      previousSectionId: null,
      inProgressSectionId: pageConfig.inProgressSectionId,
      saveProgressModalOpen: TextProps.VALUE_FALSE,
      directionClass: "forward",
      rights: 0,
      isLoading: TextProps.VALUE_TRUE,
      signature: "",
      AnswerObject: [],
      lastKnownSection: "",
      isLoggedin: TextProps.VALUE_FALSE,
      agreeError: TextProps.VALUE_FALSE,
      signatureError: TextProps.VALUE_FALSE,
      dataSheetObj: { appId: "", clientId: "", documentId: "" },
      uploadDocumentModalOpen: TextProps.VALUE_FALSE,
      modalError: TextProps.VALUE_TRUE,
      hideCloseIcon: TextProps.VALUE_FALSE,
      submitErrorMessage: "",
    };
  }

  componentWillMount() {
    // Get the family members whom a document could pertain to and add them to the modal question.
    //QUERY FOR COMSUMER DOCUMENT LIST AND NOTIFICATION LIST ON PAGE LOAD / REFRESH.
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let userAuthToken = "";
    if (this.props.consumer.mfaDetails.userAuth !== undefined) {
      userAuthToken = this.props.consumer.mfaDetails.userAuth;
    } else if (userInfo !== null) {
      userAuthToken = userInfo.userAuth;
    } else {
      this.props.goToLogin();
    }
    this.setState({ loading: TextProps.VALUE_FALSE });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let check =
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState) ||
      this.props.match.params.webAppId !== undefined;
    if (check === TextProps.VALUE_TRUE) {
      this.renderCounter++;
    } else {
      this.nonRenderCounter++;
    }

    if (
      nextState.sections[0] !== undefined &&
      nextState.sections[0].isSubmitInitiated &&
      !nextState.sections[0].isSubmitted
    ) {
      return TextProps.VALUE_TRUE;
    }

    return check;
  }
  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        sections: this.props.greeterScreening.sections,
      },
      () => {
        if (this.state.renderedSections.length < 1) {
          this.setState({
            renderedSections: [this._getSection(pageConfig.activeSectionId)],
            submitFormId: this.nextUniqueId(),
          });
        }
      }
    );

    if (nextProps.greeterScreening.applicationSubmited !== undefined) {
      if (
        nextProps.greeterScreening.applicationSubmited === TextProps.VALUE_TRUE
      ) {
        this.setState({
          uploadDocumentModalOpen: TextProps.VALUE_TRUE,
          hideCloseIcon: TextProps.VALUE_TRUE,
          modalError: TextProps.VALUE_FALSE,
        });
        setTimeout(() => {
          this.greeterModal.showBlur(
            "success",
            <div>{`Thank you for completing the greeter screening.`}</div>
          );
        }, 5);
        setTimeout(() => {
          this.setState({ uploadDocumentModalOpen: TextProps.VALUE_FALSE });
        }, 5000);
        setTimeout(() => {
          window.location.reload();
        }, 5001);
      }
      if (
        nextProps.greeterScreening.applicationSubmited === TextProps.VALUE_FALSE
      ) {
        this.setState({
          uploadDocumentModalOpen: TextProps.VALUE_TRUE,
          modalError: TextProps.VALUE_TRUE,
          hideCloseIcon: TextProps.VALUE_FALSE,
          submitErrorMessage: "Error",
        });
      }
    }
  }

  // redux apply sections loading
  componentDidMount() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo !== undefined && userInfo !== null) {
      var roleList = userInfo.roleList;
      for (var i = 0; i <= roleList.length; i++) {
        let roleListObj = roleList[i];
        if (roleListObj !== undefined && roleListObj.roleName !== undefined) {
          if (roleListObj.roleName === roleProps.GREETER_ROLE) {
            this.props.removeMultilanguageSelectGreeter();
          } else {
            this.props.multilanguageSelect();
          }
        }
      }
    }
    if (this.props.match.params.webAppId === undefined) {
      this.props.applyGreeterScreeningQuestionsSections();
    }

    //Check Login Status
    if (Object.keys(this.props.consumer.consumerData).length > 0) {
      if (this.props.consumer.consumerData.user !== undefined) {
        if (
          this.props.consumer.consumerData.user.email !== undefined &&
          this.props.consumer.consumerData.user.email !== ""
        ) {
          this.setState({ isLoggedin: TextProps.VALUE_TRUE });
        } else {
          this.setState({ isLoggedin: TextProps.VALUE_FALSE });
        }
      }
    }
  }

  // In this lifecycle hook, use the ref of the application container to reset
  // the scroll when the page changes. Not intended for production
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeSectionId !== prevState.activeSectionId) {
      this.removeSection(prevState.activeSectionId);
      helperFunction._animatedScroll();
    }
  }

  onSectionChange = (section) => {
    const previousSectionId = this.state.activeSectionId;
    const renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    const incomingSection = this._getSection(section.id);
    renderedSectionsCopy.push(incomingSection);

    this.setState(
      {
        renderedSections: renderedSectionsCopy,
      },
      () => {
        this.setState({
          previousSectionId: previousSectionId,
          activeSectionId: section.id,
          rights: 0,
          signature: "",
          agreeError: TextProps.VALUE_FALSE,
          signatureError: TextProps.VALUE_FALSE,
        });
      }
    );
  };

  _handleApplyLogoutSession = () => {
    let paramsLogout = {};
    paramsLogout.authToken = this.props.consumer.mfaDetails.authToken;
    this.props.onLogOut(paramsLogout);
    this.props.gotoHome();
  };

  _handleApplyGoBack = () => {
    this.setstate({ sections: [] });
  };

  //Primary Continue Click
  onPrimaryActionClick = (event) => {
    event.preventDefault(); // allow react to take care of routing instead of browser
    const activeId = this.state.activeSectionId;
    const incomingQuestions = this._getSection(activeId);
    let dataSections = null;
    //Dal Reference
    let dalReference = this.props.greeterScreening.dalReference;
    if (
      activeId === "about-me" &&
      typeof this.state.AnswerObject !== "undefined"
    ) {
      if (
        typeof this.state.AnswerObject[activeId] !== "undefined" &&
        typeof this.state.AnswerObject[activeId].ssn !== "undefined"
      ) {
        if (this.state.AnswerObject[activeId].ssn === "") {
          this.props.greeterScreening.sections[1].questions[5].isChecked =
            TextProps.VALUE_TRUE;
        }
      }
    }
    if (this.state.AnswerObject[activeId] === undefined) {
      dalReference.status = "INSERT";
      dalReference.sectionId = activeId;
      let _this = this;
      _this.state.AnswerObject[activeId] = {};
    } else if (
      this.state.AnswerObject[activeId].completed === TextProps.VALUE_TRUE
    ) {
      dalReference.sectionId = activeId;
    }
    if (
      incomingQuestions !== undefined &&
      incomingQuestions.questions !== undefined
    ) {
      dataSections = bindAnswers(
        incomingQuestions,
        activeId,
        this.state.AnswerObject
      );

      //GREETER form validations
      let isValid = toValidateInputErros(dataSections, activeId);

      if (isValid === TextProps.VALUE_FALSE) {
        const presentSectionId = this._getSection(
          activeId,
          this.state.sections
        );
        if (presentSectionId) {
          this.setState({
            activeSectionId: activeId,
            renderedSections: [dataSections],
          });
        }
        this.props.applyGreeterScreeningQuestionsSections();
        return;
      }
    }
    document.getElementById("greeter_btn").disabled = TextProps.VALUE_TRUE;
    if (activeId === "about-me") {
      let application;
      let userDetails = sessionStorage.getItem("userInfo");
      if (
        userDetails === null ||
        userDetails === "" ||
        userDetails === undefined
      ) {
        application = convertToDTOObj(this.state.sections, null);
      } else {
        let userDetailsObj = JSON.parse(userDetails);
        let inProgressObj = {};
        inProgressObj.status = "RECVED";
        inProgressObj.userId = userDetailsObj.email;
        application = convertToDTOObj(this.state.sections, inProgressObj);
      }
      this.saveToDatabase(application, activeId, dalReference);
    }

    // Dispatch the current section
    this.props.updateCurrentSectionData(this.props.greeterScreening);
  };

  saveToDatabase = (dataSections, activeId, dalReference) => {
    this.props.greeterScreening.sections[0].isSubmitInitiated =
      TextProps.VALUE_TRUE;
    this.setState({ isLoading: TextProps.VALUE_FALSE });
    this.props.saveGreeterScreen(dataSections, language).then((data) => {
      // This is intentional
    });
  };

  removeSection = (sectionId) => {
    let renderedSectionsCopy = _.cloneDeep(this.state.renderedSections);
    renderedSectionsCopy = renderedSectionsCopy.filter(
      (section) => section.id !== sectionId
    );
    this.setState({
      renderedSections: renderedSectionsCopy,
    });
  };

  // This function is related to simple, apply specific 'routing'
  // between sections. Not intended for production
  getPrimaryActionLabel = (sectionId) => {
    if (sectionId === "about-me")
      return beforeYoustartMlanguageLabels(language).submitButtonLabel;
    return beforeYoustartMlanguageLabels(language).continueLabel;
  };

  // Functions for setting state for if save progress modal is open or not
  presentSaveProgressModal = (e) => {
    visitor.pageview({
      dp: googleAnalyticsConstVar.DP_PARTIAL_APPLY,
      dt: googleAnalyticsConstVar.DT_PARTIAL_APPLY,
      dh: googleAnalyticsConstVar.URL_PATH + googleAnalyticsConstVar.SNAP_APPLY,
    });
    visitor
      .event(
        googleAnalyticsConstVar.EVENT_CATEGORY_PARTIAL,
        googleAnalyticsConstVar.EVENT_ACTION_PARTIAL,
        googleAnalyticsConstVar.EVENT_LABEL,
        42
      )
      .send();

    e.preventDefault();
    this.setState({ saveProgressModalOpen: TextProps.VALUE_TRUE });
    // saving the json for GUEST User on clicking save my progress later
  };

  // Functions for setting state for if warning progress modal is open or not
  presentWarningProgressModal = () => {
    this.setState({ warningProgressModalOpen: TextProps.VALUE_TRUE });
  };

  closeWarningProgressModal = () => {
    this.setState({ warningProgressModalOpen: TextProps.VALUE_FALSE });
  };

  _bindAnswerObject = (answer) => {
    this.setState({
      AnswerObject: answer,
    });
  };
  // apply implementation for rendering apply section pages, particularly
  // pertaining to transitions.
  // This method is open to interpretation or modification, especially to aid
  // in optimization.

  renderSection = (section) => {
    return (
      <CSSTransition
        key={section.id}
        in={section.id === this.state.activeSectionId}
        classNames="slide-animation"
        timeout={500} // Should be roughly the same as CSS transition time
        onExited={(node) => {
          this.removeSection(section.id);
        }}
      >
        <div className={`slide-animation ${this.state.directionClass}`}>
          {/*
              apply component for apply section pages. Could be adapted to
              provide a more robust and optimized solution. No matter the implemenation
              for rendering sections, you will need to use the markup from this,
              component. You can also consult this component for examples of how to
              resolve and render questions from JSON.
          */}
          <ApplySection
            key={section.id}
            section={section}
            primaryActionLabel={this.getPrimaryActionLabel(section.id)}
            onPrimaryActionClick={this.onPrimaryActionClick}
            bindAnswerToState={this._bindAnswerObject}
            answerObject={this.state.AnswerObject}
            onWarningProgress={this.presentWarningProgressModal}
          >
            {!section.questions ? this.getSectionBody(section.id) : null}
          </ApplySection>
        </div>
      </CSSTransition>
    );
  };

  disableApplySections = () => {
    let sections = this.props.greeterScreening.sections;
    if (sections) {
      sections.map((section) => {
        section.disabled = TextProps.VALUE_TRUE;
      });
      this.props.greeterScreening.sections = sections;
    }
  };

  // This is the main markup for the apply layout
  render() {
    const { sections } = this.props.greeterScreening;
    if (
      sections.length > 0 &&
      sections[0].isSubmitInitiated === sections[0].isSubmitted
    ) {
      return this.renderApplyQuestions(sections);
    } else {
      if (sections.length > 0 && sections[0].errorRedirect) {
        return (
          <div>
            <h1>
              Something went wrong. Please contact dhrapphelpdesk@aldhr.gov
            </h1>
          </div>
        );
      }
      if (this.state.isLoading)
        return helperFunction.isLoading(this.state.isLoading);
    }
    if (sections.length > 0) {
      return this.renderApplyQuestions(sections);
    }
  }

  closeSubmitModal = () => {
    this.setState({
      uploadDocumentModalOpen: TextProps.VALUE_FALSE,
    });
  };

  renderApplyQuestions(questionsSections) {
    const { common } = this.props.language.security;
    if (questionsSections !== undefined) {
      var userInfo;
      var loginUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        userInfo = loginUserInfo;
      } else {
        if (
          this.props.consumer.mfaDetails.userDetails !== undefined &&
          this.props.consumer.mfaDetails.userDetails !== null
        ) {
          userInfo = this.props.consumer.mfaDetails.userDetails;
        }
      }
    }
    var loginFlag = TextProps.VALUE_FALSE;
    if (this.props.consumer.loginSuccessful === TextProps.VALUE_TRUE) {
      loginFlag = TextProps.VALUE_TRUE;
    } else {
      if (loginUserInfo !== null && loginUserInfo !== undefined) {
        if (loginUserInfo.firstName !== "") {
          loginFlag = TextProps.VALUE_TRUE;
        }
      }
      const openModal = this.state.uploadDocumentModalOpen
        ? this.state.uploadDocumentModalOpen
        : TextProps.VALUE_FALSE;
      const msalInstance = this.props.msalContext.instance;

      return (
        <div className="full-height apply selfdeclare-container">
          {/* See applyNavigation.js for documentation on ApplyNavigation props */}
          <SidebarNavigation
            sections={questionsSections}
            activeSectionId={this.state.activeSectionId}
            numSectionsRemaining={2}
            onSectionClick={this.onSectionChange}
            skipToAnchor="#content"
          />
          <main
            className="apply__content-container greeter-screen pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24"
            id="content"
            tabIndex="-1"
          >
            {this.state.renderedSections.map((section) =>
              this.renderSection(section)
            )}
            {loginFlag === TextProps.VALUE_TRUE ? (
              <div className="interim-recert__options">
                <LanguageSelect className="interim-recert__language-select" />
                <div className="interim-recert__options-account">
                  <span className="interim-recert__headertext">Hi</span>&nbsp;
                  <DTADropdown
                    buttonText={userInfo.firstName}
                    buttonClass="dta-button dta-button--link"
                    menuClass="list--bordered"
                    caret={TextProps.VALUE_TRUE}
                    caretClass="color-primary"
                  >
                    <li className="pad-all--half border-top">
                      <React.Fragment>
                        {config.azureLoginFlag ? (
                          <Link
                            to="#"
                            className="display-block"
                            onClick={() =>
                              this.props.onAzureLogOut(msalInstance)
                            }
                          >
                            {common.logout}
                          </Link>
                        ) : (
                          <Link
                            to="#"
                            className="display-block"
                            onClick={this._handleApplyLogoutSession.bind(this)}
                          >
                            {common.logout}
                          </Link>
                        )}
                      </React.Fragment>
                    </li>
                  </DTADropdown>
                </div>
                {modalHelperFunction.dtaHelperModal(
                  (instance) => (this.greeterModal = instance),
                  openModal,
                  this.closeSubmitModal,
                  "rivl-modal replace-pebt-modal",
                  "mh-provider",
                  this.state.modalError,
                  this.state.hideCloseIcon
                )}
              </div>
            ) : (
              <LanguageSelect className="language-select--dark" />
            )}
          </main>
        </div>
      );
    }
  }

  // This function is related to simple, specific 'routing'between sections.
  _getSection(sectionId) {
    let targetSection = {};
    let section;
    if (this.state.sections.length > 0) {
      section = this.state.sections;
    } else {
      section = this.props.greeterScreening.sections;
    }

    _.some(section, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        targetSection = found;
        return TextProps.VALUE_TRUE; // short circuit
      } else {
        return TextProps.VALUE_FALSE; // keep on searching
      }
    });
    return targetSection;
  }

  _updateCurrentSection(sectionId) {
    let tickCurrentSection = {};
    _.some(this.state.sections, (sectionObj) => {
      const found = this._getSectionHelper(sectionId, sectionObj);
      if (found) {
        tickCurrentSection = found;
        if (sectionObj.subsections) {
          const numSub = sectionObj.subsections.length;
          for (let i = 0; i < numSub; ++i) {
            if (sectionObj.subsections[i].id === sectionId) {
              tickCurrentSection.completed = TextProps.VALUE_TRUE;
            }
          }
          if (sectionObj.id === sectionId) {
            tickCurrentSection.completed = TextProps.VALUE_TRUE;
            return tickCurrentSection;
          }
        } else {
          tickCurrentSection.completed = TextProps.VALUE_TRUE;
          return tickCurrentSection;
        }
      }
    });
  }

  _getSectionHelper(sectionId, sectionObj) {
    if (sectionObj.id === sectionId) {
      return sectionObj;
    } else if (sectionObj.subsections) {
      const numSub = sectionObj.subsections.length;
      for (let i = 0; i < numSub; ++i) {
        const found = this._getSectionHelper(
          sectionId,
          sectionObj.subsections[i]
        );
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  goHome = () => {
    window.location.assign(routeProps.HOME_PATH);
  };

  closeRegisterModal = () => {
    this.setState({ signupSectionContents: TextProps.VALUE_FALSE });
  };

  closeRegisterSuccessfulModal = () => {
    this.setState({
      registrationSuccessful: TextProps.VALUE_FALSE,
      isLoggedin: TextProps.VALUE_TRUE,
    });
    this.props.onBindEmail(this.state.email);
  };

  onRegister(e) {
    e.preventDefault();
    let registerDetails = {};
    registerDetails.grant_type = "client_credentials";
    registerDetails.scope = "UserReg";
    registerDetails.email = this.state.email;
    registerDetails.password = this.state.password;
    registerDetails.firstname = this.state.firstName;
    registerDetails.lastname = this.state.lastName;
    registerDetails.dob = this.state.dob.toLocaleDateString();
    this.props.onRegister(registerDetails);
  }

  goToLogin() {
    this.setState({
      signupSectionContents: TextProps.VALUE_FALSE,
      loginSectionContents: TextProps.VALUE_TRUE,
    });
  }
}

//mapping Action with dispatch
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveGreeterScreen: saveGreeterScreen,
      applyGreeterScreeningQuestionsSections:
        applyGreeterScreeningQuestionsSections,
      updateCurrentSectionData: updateCurrentSectionData,
      removeMultilanguageSelectGreeter: removeMultilanguageSelectGreeter,
      multilanguageSelect: multilanguageSelect,

      //login
      onGetConsumerLogin: getConsumerLoginContents,
      onConsumerLogin: () => push(routeProps.CONSUMER_HOME_PATH),
      onConsumerSignup: () => push(routeProps.SIGNUP_PATH),
      gotoConsumer: () => push(routeProps.CONSUMER_PATH),
      gotoProvider: () => push(routeProps.PROVIDER_PATH),
      onLogin: onLogin,
      onMFASubmit: onMFASubmit,
      onRegister: onRegister,
      onBindEmail: onBindEmail,
      goToLogin: () => push(routeProps.HOME_PATH),
      onLogOut: onLogOut,
      onAzureLogOut: onAzureLogOut,
      gotoHome: () => push(routeProps.HOME_PATH),
    },
    dispatch
  );
}
export default withMsal(
  connect(mapStateToProps, mapDispatchToProps)(GreeterScreening)
);
