import React, { Component } from "react";
import PropTypes from "prop-types";
import { enableUniqueIds } from "react-html-id";

class DataChangeIndicator extends Component {
  static propTypes = {
    text: PropTypes.string,
  };

  constructor() {
    super();

    enableUniqueIds(this);
  }

  render() {
    return <span className="badge">{this.props.text}</span>;
  }
}

export default DataChangeIndicator;
