function summaryConst() {
  this.getraceDesc = {
    AMIALN: "American Indian",
    NTHPCI: "Pacific Islander",
    WHITE: "White",
    BLCAFR: "Black/African American",
    ASIAN: "Asian",
    Declinetoanswer: "",
  };
  this.geEthnicityDesc = {
    HSPLTN: "Hispanic/Latino",
    NNHSPL: "Non-Hispanic/Latino",
    DCL: "Decline to answer",
    Declinetoanswer: "Decline to answer",
   "Negarse a contestar":"Negarse a contestar"
  };
  this.getRelationDesc = {
    SPOUSE: "Spouse",
    MO: "Mother",
    FA: "Father",
    DAUGH: "Daughter",
    SON: "Son",
    BROTH: "Brother",
    SIST: "Sister",
    GRMOT: "GrandMother",
    GRFATH: "Grandfather",
    GRDAU: "Granddaughter",
    GRSON: "Grandson",
    OTHREL: "Other",
  };
  this.getIncomeTypeDesc = {
    EIWAGES: "Wages",
    EISELEMP: "Self-Employment",
    EIFEDWRK: "Work Study",
    UISSI: "SSI (Supplemental Security Income)",
    UIRSDI: "RSDI (Retirement, Survivors, and Disability Insurance)",
    UIUNEMPL: "Unemployment",
    UICHSDOR: "Child Support",
    UIEMPPEN: "Pension",
    UIVETBPR: "Veterans Benefits",
    EIRENTAL: "Rental Income",
    UIWRKCMP: "Workers Compensation",
    UIOTHER: "Other",
  };
  this.getUtilityDesc = {
    payHeat: "Heat (oil, gas, electricity or propane, etc.)",
    payAC: "Electricity for an air conditioner",
    payACFee: "A fee to use an air conditioner",
    elgas: "Electricity and/or gas",
    hasPhone: "Phone or cell phone service",
    None: "",
  };
  this.getHouseCostDesc = {
    RENT: "Rent",
    MORTGAGE: "Mortgage",
    PROPERTY_TAX: "Property taxes",
    HOME_INSURANCE: "Home insurance",
    CONDO_FEE: "Condo fee",
    None: "",
  };
}

module.exports = new summaryConst();
